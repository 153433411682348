<template>
  <span
    v-resize="checkMobile"
    :style="isMobile ? 'padding: 5px !important;' : ''"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
        <v-spacer></v-spacer>
        <v-btn
          v-if="messages && messages.length > 0 && !get_all"
          @click="checkViewedAll()"
          small
          color="success"
        >
          {{ $t("messages.read_all") }}
          <v-icon class="ml-2">mdi-eye-circle-outline</v-icon>
        </v-btn>
        <v-btn
          v-if="!get_all"
          @click="getAllMessages()"
          class="ml-2"
          small
          color="secondary"
        >
          {{ $t("messages.load_all_messages") }}
          <v-icon class="ml-2">mdi-all-inclusive</v-icon>
        </v-btn>
        <v-btn
          v-if="get_all"
          @click="getUnreaded()"
          class="ml-2"
          small
          color="primary"
        >
          {{ $t("messages.load_new_messages") }}
          <v-icon class="ml-2">mdi-new-box</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- Unreaded messages -->
      <v-card-text v-if="!get_all">
        <v-list three-line v-if="messages && messages.length > 0">
          <template v-for="item in groupMessagesByBookingId()">
            <v-list-item :key="item[0].id">
              <v-list-item-icon :rounded="false" class="ml-0">
                <v-flex>
                  <!-- Sender User, Admin, Robot -->
                  <v-flex v-if="item[0].new">
                    <v-icon color="primary">mdi-new-box</v-icon>
                  </v-flex>
                  <v-flex>
                    <v-icon v-if="item[0].sender == 'User'" color="warning"
                      >mdi-account-alert</v-icon
                    >
                    <v-icon v-else-if="item[0].sender == 'Host'" color="info"
                      >mdi-home-account</v-icon
                    >
                    <v-icon
                      v-else-if="item[0].sender == 'Admin'"
                      color="secondary"
                      >mdi-account-cog-outline</v-icon
                    >
                    <v-icon v-else color="secondary">mdi-robot-outline</v-icon>
                  </v-flex>
                </v-flex>
              </v-list-item-icon>
              <v-list-item-content
                @click="
                  show_message = true;
                  selected_message_id = item[0].id;
                "
                style="cursor: pointer"
              >
                <v-list-item-title
                  ><div v-html="item[0].title"></div
                ></v-list-item-title>
                <v-list-item-subtitle
                  ><timeago
                    :datetime="item[0].created_at"
                    :locale="$i18n.locale"
                  ></timeago
                ></v-list-item-subtitle>
                <v-list-item-subtitle>
                  <div v-html="item[0].text"></div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="item[0].created_at"></v-divider>
          </template>
        </v-list>
        <v-card-text v-else-if="messages.length == 0 && !isLoading">
          <v-alert type="info" border="left" prominent>
            {{ $t("messages.no_new_messages") }}
          </v-alert>
        </v-card-text>
        <v-card-text v-else-if="isLoading">
          <v-alert type="info" border="left" prominent>
            {{ $t("custom.loading_table") }}
          </v-alert>
        </v-card-text>
      </v-card-text>
      <!-- All messages -->
      <!-- Sender User, Admin, Robot -->
      <v-card-text v-else>
        <v-list three-line v-if="all_messages && all_messages.length > 0">
          <template v-for="item in all_messages">
            <v-list-item :key="item[0].id">
              <v-list-item-icon :rounded="false" class="ml-0">
                <v-flex>
                  <v-flex v-if="item[0].new">
                    <v-icon color="primary">mdi-new-box</v-icon>
                  </v-flex>
                  <v-flex>
                    <v-icon v-if="item[0].sender == 'User'" color="warning"
                      >mdi-account-alert</v-icon
                    >
                    <v-icon v-else-if="item[0].sender == 'Host'" color="info"
                      >mdi-home-account</v-icon
                    >
                    <v-icon
                      v-else-if="item[0].sender == 'Admin'"
                      color="secondary"
                      >mdi-account-cog-outline</v-icon
                    >
                    <v-icon v-else color="secondary">mdi-robot-outline</v-icon>
                  </v-flex>
                </v-flex>
              </v-list-item-icon>
              <v-list-item-content
                @click="
                  selected_message_id = item[0].id;
                  show_message = true;
                "
                style="cursor: pointer"
              >
                <v-list-item-title
                  ><div v-html="item[0].title"></div
                ></v-list-item-title>
                <v-list-item-subtitle
                  ><timeago
                    :datetime="item[0].created_at"
                    :locale="$i18n.locale"
                  ></timeago
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  ><div v-html="item[0].text"></div
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="item[0].created_at"></v-divider>
          </template>
        </v-list>
        <v-card-text v-else-if="all_messages.length == 0 && !isLoading">
          <v-alert type="info" border="left" prominent>
            {{ $t("messages.no_messages") }}
          </v-alert>
        </v-card-text>
        <v-card-text v-else-if="isLoading">
          <v-alert type="info" border="left" prominent>
            {{ $t("custom.loading_table") }}
          </v-alert>
        </v-card-text>
      </v-card-text>
      <div class="text-center">
        <v-pagination v-model="page" :length="pages"></v-pagination>
      </div>
    </v-card>
    <v-dialog
      v-model="show_message"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          {{
            messagesFromBooking(selected_message_id) &&
            messagesFromBooking(selected_message_id)[0] &&
            messagesFromBooking(selected_message_id)[0].title
              ? messagesFromBooking(selected_message_id)[0].title
              : $t("custom.messages")
          }}
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="checkViewed(messagesFromBooking(selected_message_id))"
          >
            {{ $t("messages.make_readed") }}
          </v-btn>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="(show_message = false), (selected_message_id = null)"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-divider class="my-2"></v-divider>
        <v-card-text>
          <v-flex
            :key="item.id"
            v-for="item in messagesFromBooking(selected_message_id)"
          >
            <v-alert
              class="mb-1"
              border="left"
              :color="item && item.sender == 'User' ? 'info' : 'blue-grey'"
              dark
              dense
              :icon="selectIcon(item.sender)"
            >
              <v-flex>
                <div v-html="item.text"></div>
              </v-flex>
            </v-alert>
            <v-flex class="caption text-right">{{
              formatCreatedAt(item.created_at)
            }}</v-flex>
          </v-flex>
          <v-flex
            v-if="
              messagesFromBooking(selected_message_id) &&
              messagesFromBooking(selected_message_id)[0] &&
              messagesFromBooking(selected_message_id)[0].booking_id
            "
            class="d-flex"
          >
            <v-textarea
              v-model.trim="new_message"
              @keypress="
                checkEnter(
                  $event,
                  messagesFromBooking(selected_message_id)[0].booking_id
                )
              "
              class="mx-2"
              :label="$t('messages.text')"
              rows="1"
              auto-grow
              :counter="500"
            ></v-textarea>
            <v-btn
              class="ma-2"
              @click="
                sendMessage(
                  messagesFromBooking(selected_message_id)[0].booking_id
                )
              "
              icon
            >
              <v-icon v-if="!send_message_loading" large color="secondary"
                >mdi-send-circle-outline</v-icon
              >
              <v-progress-circular
                class="my-auto"
                v-else
                indeterminate
                color="secondary"
                size="20"
              ></v-progress-circular>
            </v-btn>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { Capacitor } from "@capacitor/core";
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      all_messages: [],
      selected_message_id: null,
      new_message: null,
      show_message: false,
      send_message_loading: false,
      search: "",
      pages: 0,
      page: 1,
      get_all: false,
      headers: [
        {
          text: this.$t("custom.number"),
          align: "center",
          value: "id",
        },
        { text: this.$t("custom.date"), value: "created_at", align: "center" },
        { text: this.$t("custom.title"), sortable: false, align: "center" },
        { text: this.$t("custom.text"), sortable: false, align: "center" },
      ],
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.getUnreaded();
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    page() {
      if (this.get_all) {
        this.getAllMessages();
      } else {
        this.getUnreaded();
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    rand() {
      return Math.floor(Math.random() * 1000000);
    },
    messages() {
      return this.$store.getters.getMessages;
    },
  },
  methods: {
    sendMessage(booking_id) {
      if (
        this.new_message &&
        this.new_message.length > 0 &&
        this.new_message != " " &&
        this.new_message.length <= 500 &&
        !this.send_message_loading &&
        booking_id
      ) {
        if (booking_id) {
          this.send_message_loading = true;
          this.axios
            .post("/api/v1/vseprokaty_send_message", {
              booking_id: booking_id,
              body: this.new_message,
            })
            .then((response) => {
              this.new_message = null;
              let oldMessages = this.messages;
              oldMessages.push(response.data);
              this.$store.commit("setMessages", oldMessages);
              this.send_message_loading = false;
            })
            .catch((error) => {
              this.send_message_loading = false;
              this.setError(error, this.$t("messages.error_send_message"));
              console.log(error);
            });
        } else {
          this.setError(null, this.$t("messages.error_send_message"));
        }
      }
    },
    groupAllMessagesByBookingId() {
      // Объединяем сообщения по booking_id в один объект, если booking_id есть, иначе возвращаем объекты без изменений
      let groupedMessages = [];
      let bookingIds = [];
      this.all_messages.forEach((message) => {
        if (message.booking_id) {
          if (!bookingIds.includes(message.booking_id)) {
            bookingIds.push(message.booking_id);
            let messages = this.all_messages.filter(
              (m) => m.booking_id == message.booking_id
            );
            groupedMessages.push(messages);
          }
        } else {
          groupedMessages.push([message]);
        }
      });
      return groupedMessages;
    },
    groupMessagesByBookingId() {
      // Объединяем сообщения по booking_id в один объект, если booking_id есть, иначе возвращаем объекты без изменений
      let groupedMessages = [];
      let bookingIds = [];
      this.messages.forEach((message) => {
        if (message.booking_id) {
          if (!bookingIds.includes(message.booking_id)) {
            bookingIds.push(message.booking_id);
            let messages = this.messages.filter(
              (m) => m.booking_id == message.booking_id
            );
            groupedMessages.push(messages);
          }
        } else {
          groupedMessages.push([message]);
        }
      });
      return groupedMessages;
    },
    messagesFromBooking(message_id) {
      if (this.get_all) {
        let message = this.all_messages.find((m) => m[0].id == message_id);
        if (message && message[0] && message[0].booking_id) {
          // Ищем индекс массива сообщений по booking_id
          let index = this.all_messages.findIndex(
            (m) => m[0].booking_id == message[0].booking_id
          );
          return this.all_messages[index];
        } else if (message && message[0] && message[0].booking_id == null) {
          // return this.all_messages.filter((m) => m[0].id == message_id);
          return [message[0]];
        } else {
          return [];
        }
        // let messages = [];
        // // this.all_messages.filter((m) => {
        // //   if (m.booking_id == message_id) {
        // //     messages.push(m);
        // //   }
        // // });
        // // ищем в массиве сообщений по booking_id
        // let filtered = this.all_messages.filter((m) => {
        //   return m.find((message) => {
        //     return message.id == message_id;
        //     // if (message.id == message_id) {
        //     //   return true;
        //     // } else {
        //     //   return false;
        //     // }
        //   });

        // });
        // console.log("filtered", this.all_messages);
      } else {
        let message = this.messages.find((m) => m.id == message_id);
        if (message && message.booking_id) {
          // Возвращает массив сообщений по booking_id
          return this.messages.filter(
            (m) => m.booking_id == message.booking_id
          );
        } else if (message && message.booking_id == null) {
          return this.messages.filter((m) => m.id == message_id);
        } else {
          return [];
        }
      }
    },
    selectIcon(sender) {
      if (sender == "User") {
        return "mdi-account-alert";
      } else if (sender == "Host") {
        return "mdi-home-account";
      } else if (sender == "Admin") {
        return "mdi-account-cog-outline";
      } else {
        return "mdi-robot-outline";
      }
    },
    getAllMessages() {
      this.isLoading = true;
      // let page = this.$router.currentRoute.query.page
      //   ? this.$router.currentRoute.query.page
      //   : 1;
      this.axios
        .get(`/api/v1/all_messages?page=${this.page}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.get_all = true;
          // сортируем сообщения по дате
          this.all_messages = response.data.messages.sort((a, b) => {
            return new Date(b[0].created_at) - new Date(a[0].created_at);
          });
          this.pages = response.data.pages;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("messages.error_viewed_message"));
          console.log(error);
        });
    },
    getUnreaded() {
      this.isLoading = true;
      // let page = this.$router.currentRoute.query.page
      //   ? this.$router.currentRoute.query.page
      //   : 1;
      this.axios
        .get(`/api/v1/messages?page=${this.page}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.get_all = false;
          this.$store.commit("setMessages", response.data.messages);
          this.pages = response.data.pages;
        })
        .catch((error) => {
          this.setError(error, this.$t("messages.error_get_messages"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    checkViewed(messages) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/messages/${messages[0].id}/viewed`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.show_message = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: this.$t("custom.success"),
            text: this.$t("messages.message_viewed"),
          });
          let messages_indexes = [];
          messages.forEach((message) => {
            messages_indexes.push(message.id);
          });
          let filtered_messages = this.messages.filter(
            (item) => !messages_indexes.includes(item.id)
          );
          this.$store.commit("setMessages", filtered_messages);
          this.selected_message_id = null;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("messages.error_viewed_message"));
          console.log(error);
        });
    },
    checkViewedAll() {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/messages_viewed_all`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: this.$t("custom.success"),
            text: this.$t("messages.messages_viewed"),
          });
          this.$store.commit("setMessages", []);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("messages.error_viewed_message"));
          console.log(error);
        });
    },
    checkEnter(event, booking_id) {
      // Проверяем что нажат только Enter и отправляем сообщение
      // Если нажат Shift + Enter, то переносим строку
      if (event.keyCode == 13 && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage(booking_id);
      }
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    formatCreatedAt(date) {
      if (date) {
        // return moment.parseZone(date).format("DD MMM H:mm");
        return moment(date).format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
