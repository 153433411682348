var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.checkMobile),expression:"checkMobile"}],style:(_vm.isMobile ? 'padding: 5px !important;' : '')},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":"#1976d2","background-color":_vm.$vuetify.theme.dark ? '#000' : ''},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('v-card',[(!_vm.isMobile)?_c('v-toolbar',{attrs:{"text":"","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('v-flex',{staticClass:"d-flex align-center justify-space-between"},[_c('v-flex',{staticClass:"d-flex align-center justify-start mt-5"},[_c('v-switch',{staticClass:"mr-2 my-auto",attrs:{"label":_vm.$t('custom.entity_clients')},model:{value:(_vm.showEntity),callback:function ($$v) {_vm.showEntity=$$v},expression:"showEntity"}}),_c('v-switch',{attrs:{"label":_vm.$t('custom.debts_clients')},model:{value:(_vm.showDebtors),callback:function ($$v) {_vm.showDebtors=$$v},expression:"showDebtors"}})],1),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('custom.search'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"success","to":{ name: 'NewClient' }}},[_vm._v(_vm._s(_vm.$t("custom.add_client")))]),(
            _vm.filtered_clients &&
            _vm.filtered_clients.length > 0 &&
            (_vm.user_role == 'superadmin' || _vm.user_role == 'admin')
          )?_c('v-icon',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success"},on:{"click":_vm.toXlsx}},[_vm._v(" mdi-file-excel-outline ")]):_vm._e(),(!_vm.isIos)?_c('v-btn',{attrs:{"icon":"","href":("https://rentprog." + (_vm.$root.$i18n.locale == 'ru' ? 'ru' : 'com') + "/categories/4/guides/14"),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-help-circle")])],1):_vm._e()],1)],1):_c('v-toolbar',{attrs:{"height":"150px","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('v-flex',{staticClass:"text-center",attrs:{"md12":""}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('custom.search'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","color":"success","to":{ name: 'NewClient' }}},[_vm._v(_vm._s(_vm.$t("custom.add_client")))]),(
            _vm.filtered_clients &&
            _vm.filtered_clients.length > 0 &&
            (_vm.user_role == 'superadmin' || _vm.user_role == 'admin')
          )?_c('v-icon',{staticClass:"mt-2 ml-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success"},on:{"click":_vm.toXlsx}},[_vm._v(" mdi-file-excel-outline ")]):_vm._e(),_c('v-flex',{staticClass:"d-flex align-center justify-center mt-3"},[_c('v-switch',{staticClass:"mr-2 my-auto",attrs:{"label":_vm.$t('custom.entity_clients'),"hide-details":"","dense":""},model:{value:(_vm.showEntity),callback:function ($$v) {_vm.showEntity=$$v},expression:"showEntity"}}),_c('v-switch',{attrs:{"label":_vm.$t('custom.debts_clients'),"hide-details":"","dense":""},model:{value:(_vm.showDebtors),callback:function ($$v) {_vm.showDebtors=$$v},expression:"showDebtors"}})],1)],1)],1),(!_vm.showEntity)?_c('v-data-table',{class:!_vm.isMobile ? '' : 'is-mobile',attrs:{"headers":_vm.headers,"items":_vm.filtered_clients,"search":_vm.search,"loading":_vm.isLoading,"loading-text":_vm.$t('custom.loading_table'),"items-per-page":50,"mobile-breakpoint":"100","server-items-length":_vm.total,"options":_vm.tableOptions,"page":_vm.page,"sort-by":"id","sort-desc":"","dense":"","footer-props":{
        pageText: ("{0} " + (_vm.$t('custom.of')) + " " + _vm.total),
        itemsPerPageText: _vm.$t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [50, 100, 200],
      }},on:{"update:serverItemsLength":function($event){_vm.total=$event},"update:server-items-length":function($event){_vm.total=$event},"update:options":function($event){_vm.tableOptions=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:_vm.checkClientCategory(props.item),staticStyle:{"cursor":"pointer"},on:{"dblclick":function($event){return _vm.replaceToClient(props.item)}}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"text-center nowrap"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('clients.info')},on:{"click":function($event){return _vm.replaceToClient(props.item)}}},[_vm._v(" mdi-eye-outline ")]),(_vm.user_role == 'superadmin' || _vm.user_role == 'admin')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(props.item)}}},[_vm._v(" mdi-delete-forever ")]):_vm._e()],1),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.lastname)+" ")]),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.name)+" ")]),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.middlename)+" ")]),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.phone)+" ")]),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.email)+" ")]),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.birthday && props.item.birthday != "Invalid date" ? _vm.birthdayFormat(props.item.birthday) : "")+" ")]),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.inn)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.source))]),_c('td',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.branchName(props.item.company_id),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }),expression:"{\n              content: branchName(props.item.company_id),\n              placement: 'bottom-center',\n              classes: ['info'],\n              targetClasses: ['it-has-a-tooltip'],\n              delay: {\n                show: 500,\n                hide: 500,\n              },\n            }"}],staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.branchCity(props.item.company_id))+" ")])])]}}],null,false,2805091771)},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("custom.no_data_in_table"))+" ")]),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("tables.no_search_result", { msg: _vm.search }))+" ")])],2):_c('v-data-table',{class:!_vm.isMobile ? '' : 'is-mobile',attrs:{"headers":_vm.headers_entity,"items":_vm.filtered_entity_clients,"loading":_vm.isLoading,"loading-text":_vm.$t('custom.loading_table'),"search":_vm.search,"sort-by":['created_at'],"sort-desc":[true],"items-per-page":20,"server-items-length":_vm.total,"options":_vm.tableOptions,"page":_vm.page,"footer-props":{
        pageText: ("{0} " + (_vm.$t('custom.of')) + " {1}"),
        itemsPerPageText: _vm.$t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100],
      }},on:{"update:serverItemsLength":function($event){_vm.total=$event},"update:server-items-length":function($event){_vm.total=$event},"update:options":function($event){_vm.tableOptions=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(props){return [_c('tr',{class:_vm.checkClientCategory(props.item),staticStyle:{"cursor":"pointer"},on:{"dblclick":function($event){return _vm.replaceToClient(props.item)}}},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.id))]),_c('td',{staticClass:"justify-center layout px-0 py-1"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('clients.info')},on:{"click":function($event){return _vm.replaceToClient(props.item)}}},[_vm._v(" mdi-eye-outline ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(props.item)}}},[_vm._v(" mdi-delete-forever ")])],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.entity_name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.short_entity_name))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.reg_form))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.entity_phone))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.email))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.inn))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.ogrn))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.source))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.branchCity(props.item.company_id)))])])]}}])},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("custom.no_data_in_table"))+" ")]),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("tables.no_search_result", { msg: _vm.search }))+" ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }