<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title>
        <h2>{{ $t("check_lists.common_task_list") }}</h2>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/9/guides/24`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-flex md12>
          <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('custom.search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              v-if="checkAdminManager()"
              small
              color="success"
              class="ml-2 mt-2"
              @click="showNewTodo = true"
              >{{ $t("custom.add") }}<v-icon>mdi-plus</v-icon></v-btn
            >
          </v-toolbar>
        </v-flex>
        <v-flex xs12 sm12 md12>
          <v-data-table
            :headers="headers"
            :items="todos"
            :search="search"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :class="!isMobile ? 'elevation-1' : 'elevation-1 is-mobile'"
            sort-by="done"
            :items-per-page="20"
            mobile-breakpoint="100"
            dense
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [20, 50, 100, -1],
            }"
          >
            <template v-slot:item="props">
              <tr>
                <td class="text-center" nowrap>
                  {{ formattedMomentjs(props.item.created_at) }}
                </td>
                <td nowrap>
                  <v-icon
                    v-if="props.item.done == false"
                    @click="doneTodo(props.item)"
                    color="green"
                    >mdi-check</v-icon
                  >
                  <v-icon
                    v-if="checkAdminManager()"
                    @click="editTodo(props.item)"
                    :disabled="props.item.done"
                    >mdi-pencil-outline</v-icon
                  >
                  <v-icon
                    v-if="checkAdminManager()"
                    @click="removeTodo(props.item)"
                    >mdi-delete-forever</v-icon
                  >
                </td>
                <td class="text-center">{{ props.item.title }}</td>
                <td class="text-center">
                  <read-more
                    v-if="
                      props.item.description &&
                      props.item.description.length > 0
                    "
                    :more-str="$t('custom.read')"
                    :text="props.item.description"
                    link="#"
                    :less-str="$t('custom.less')"
                    :max-chars="20"
                  ></read-more>
                </td>
                <td class="text-center">{{ props.item.responsible }}</td>
                <td class="text-center">
                  {{ setWorker(props.item.user_id) }}
                </td>
                <td
                  :class="{
                    red: checkLate(props.item.end_date) && !props.item.done,
                    'text-center': true,
                  }"
                >
                  {{ formattedDateMomentjs(props.item.end_date) }}
                </td>
                <td class="text-center" v-if="props.item.done">
                  <v-icon color="green">mdi-check-all</v-icon>
                </td>
                <td class="text-center" v-else>
                  <v-icon color="grey">mdi-timer-sand-empty</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showNewTodo"
      :retain-focus="false"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <h3>{{ $t("check_lists.task_list") }}</h3>
          <v-btn
            v-if="!isIos"
            icon
            :href="`https://rentprog.${
              $root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/categories/9/guides/24`"
            target="_blank"
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row wrap>
              <v-flex md12 mb2>
                <v-textarea
                  v-model="newTodo"
                  :label="$t('check_lists.what_make')"
                  auto-grow
                  rows="1"
                  :placeholder="$t('check_lists.what_make')"
                  hide-details
                ></v-textarea>
                <v-textarea
                  v-model="description"
                  auto-grow
                  rows="1"
                  :label="$t('custom.description')"
                  :placeholder="$t('custom.description')"
                  hide-details
                ></v-textarea>
              </v-flex>
              <v-flex md6 sm12 xs12>
                <v-select
                  v-model="responsible"
                  :items="users_names"
                  :label="$t('check_lists.responsible')"
                ></v-select>
              </v-flex>
              <v-flex md6 sm12 xs12>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjs"
                      clearable
                      :label="$t('check_lists.do_before')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @change="menu2 = false"
                    :locale="$i18n.locale"
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex md12 class="text-center align-center">
                <v-btn color="success" class="mt-2" @click="addTodo()">{{
                  $t("custom.add")
                }}</v-btn>
                <v-btn text class="mt-2 ml-2" @click="showNewTodo = false">{{
                  $t("custom.close")
                }}</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showEdit"
      :retain-focus="false"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <h3>{{ $t("check_lists.edit_task") }}</h3>
          <v-btn
            v-if="!isIos"
            icon
            :href="`https://rentprog.${
              $root.$i18n.locale == 'ru' ? 'ru' : 'com'
            }/categories/9/guides/24`"
            target="_blank"
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row wrap>
              <v-flex md12 mb2>
                <v-textarea
                  v-model="todo.title"
                  :label="$t('check_lists.what_make')"
                  auto-grow
                  rows="1"
                  :placeholder="$t('check_lists.what_make')"
                  hide-details
                ></v-textarea>
                <v-textarea
                  v-model="todo.description"
                  auto-grow
                  rows="1"
                  :label="$t('custom.description')"
                  :placeholder="$t('custom.description')"
                  hide-details
                ></v-textarea>
              </v-flex>
              <v-flex md6 sm12 xs12>
                <v-select
                  v-model="todo.responsible"
                  :items="users_names"
                  :label="$t('check_lists.responsible')"
                ></v-select>
              </v-flex>
              <v-flex md6 sm12 xs12>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedMomentjsEdit"
                      clearable
                      :label="$t('check_lists.do_before')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="todo.end_date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="todo.end_date"
                    @change="menu2 = false"
                    :locale="$i18n.locale"
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex md12 class="text-center align-center">
                <v-btn color="success" class="mt-2" @click="updateTodo()">{{
                  $t("custom.save")
                }}</v-btn>
                <v-btn text class="mt-2 ml-2" @click="showEdit = false">{{
                  $t("custom.close")
                }}</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
export default {
  name: "List",
  data() {
    return {
      todos: [],
      todo: {},
      users: [],
      users_names: [],
      newTodo: [],
      search: null,
      error: "",
      editedTodo: "",
      description: "",
      showNewTodo: false,
      showEdit: false,
      responsible: "",
      date: null,
      menu2: false,
      headers: [
        {
          text: this.$t("custom.created_at"),
          sortable: true,
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("check_lists.what_make_2"),
          sortable: false,
          value: "title",
          align: "center",
        },
        {
          text: this.$t("custom.description"),
          sortable: false,
          value: "description",
          align: "center",
        },
        {
          text: this.$t("check_lists.responsible"),
          value: "responsible",
          align: "center",
        },
        { text: this.$t("custom.creator"), value: "user_id", align: "center" },
        {
          text: this.$t("check_lists.do_before"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("custom.state"), value: "done", align: "center" },
      ],
      rowsPerPageItems: [
        10,
        25,
        50,
        { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
      ],
      pagination: {
        sortBy: "done",
        rowsPerPage: 10,
      },
      isMobile: false,
      isLoading: true,
    };
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.axios
        .get("/todos", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          if (this.checkAdminManager()) {
            this.todos = response.data;
          } else {
            response.data.forEach((todo) => {
              if (todo.responsible === this.currentUser().email && !todo.done) {
                this.todos.push(todo);
              }
            });
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.todos_load"));
        });

      this.axios
        .get(`/api/v1/get_workers_names_with_ids`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.workers = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("workers.employers_load"));
          console.log(error);
        });

      this.axios
        .get("/admin/users", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.users = response.data;
          let names = [];
          response.data.forEach((user) => {
            if (user.active) {
              names.push(user.name ? user.name : user.email);
            }
          });
          this.users_names = names;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.workers_load"));
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date ? moment(this.date).format("Do MMMM YYYY") : "";
    },
    computedDateFormattedMomentjsEdit() {
      return this.todo.end_date
        ? moment(this.todo.end_date).format("Do MMMM YYYY")
        : "";
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    addTodo() {
      if (this.user_role != "guest") {
        if (this.newTodo.length == 0) {
          return;
        } else {
          const value = this.newTodo && this.newTodo.trim();
          if (!value) {
            return;
          }
        }
        this.isLoading = true;
        this.axios
          .post(
            "/todos",
            {
              todo: {
                title: this.newTodo,
                responsible: this.responsible,
                end_date: this.date,
                description: this.description,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.todos.push(response.data);
            this.date = null;
            this.responsible = null;
            this.description = null;
            this.showNewTodo = false;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("custom.saved"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.todos_creating"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.todos_creating"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    removeTodo(todo) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        this.axios
          .delete(`/todos/${todo.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then(() => {
            this.todos.splice(this.todos.indexOf(todo), 1);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("custom.deleted"),
            });
          })
          .catch((error) =>
            this.setError(error, this.$t("errors.todos_deleting"))
          )
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editTodo(todo) {
      this.todo = todo;
      this.showEdit = true;
    },
    updateTodo() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let todo = this.todo;
        this.axios
          .patch(
            `/todos/${todo.id}`,
            {
              todo: {
                title: todo.title,
                responsible: todo.responsible,
                end_date: todo.end_date,
                description: todo.description,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then(() => {
            this.showEdit = false;
            this.todo = {};
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("custom.updated"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.task_update")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    doneTodo(todo) {
      if (this.user_role != "guest") {
        let self = this;
        this.axios
          .patch(
            `/todos/${todo.id}`,
            { todo: { done: true } },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.todos.findIndex(
              (element) => element.id === todo.id
            );
            this.todos[foundIndex].done = true;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("custom.saved"),
            });
          })
          .catch((error) =>
            this.setError(error, this.$t("errors.task_update"))
          );
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setWorker(id) {
      if (id != null) {
        if (this.workers && this.workers.find((worker) => worker.id == id)) {
          return this.workers.find((worker) => worker.id == id).name;
        }
      } else {
        return "-";
      }
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkLate(date) {
      return moment().isAfter(date, "day");
    },
    formattedMomentjs(date) {
      return date ? moment(date).format("lll") : "";
    },
    formattedDateMomentjs(date) {
      return date ? moment(date).format("DD-MM-YYYY") : "";
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  directives: {
    "todo-focus": function (el) {
      el.focus();
    },
  },
};
</script>

<style lang="css"></style>
