import Vue from 'vue'
import Router from 'vue-router'
// import firebase from 'firebase/app'
// import "firebase/auth";
import Home from '@/components/Home'
import Signin from '@/components/Signin'
import Signup from '@/components/Signup'
import AllTodosList from '@/components/todos/ListAll'
import UsersTodosList from '@/components/todos/ListUser'
import CheckList from '@/components/todos/CheckList'
import UsersList from '@/components/admin/users/List'
import UserEdit from '@/components/admin/users/Edit'
import Salary from '@/components/dashboard/users/Salary'
import ForgotPassword from '@/components/ForgotPassword'
import ResetPassword from '@/components/ResetPassword'
import Cars from '@/components/dashboard/cars/Cars.vue'
import Gps from '@/components/dashboard/gps/Map.vue'
import StarLine from '@/components/dashboard/gps/StarLine.vue'
import Bookings from '@/components/dashboard/bookings/Bookings.vue'
import ArchiveBookings from '@/components/dashboard/bookings/ArchiveBookings.vue'
import NewCars from '@/components/dashboard/cars/New.vue'
import CarCard from '@/components/dashboard/cars/CarCard.vue'
import ArchiveCars from '@/components/dashboard/cars/ArchiveCars.vue'
import NewBooking from '@/components/dashboard/bookings/NewBooking.vue'
import BookingCard from '@/components/dashboard/bookings/BookingCard.vue'
import Clients from '@/components/dashboard/clients/Clients.vue'
import ClientCard from '@/components/dashboard/clients/ClientCard.vue'
import WhatsAppChat from '@/components/dashboard/clients/WhatsAppChat.vue'
import WhatsAppClient from '@/components/dashboard/clients/WhatsAppClient.vue'
import NewClient from '@/components/dashboard/clients/NewClient.vue'
import Invoices from '@/components/dashboard/clients/Invoices.vue'
import BookingsCalendar from '@/components/dashboard/calendars/BookingsCalendar.vue'
import NewCalendar from '@/components/dashboard/calendars/NewCalendar.vue'
import TimelineCalendar from '@/components/dashboard/calendars/TimelineCalendar.vue'
import ScheduleCalendar from '@/components/dashboard/calendars/ScheduleCalendar.vue'
import FrappeCalendar from '@/components/dashboard/calendars/FrappeCalendar.vue'
import Counts from '@/components/dashboard/counts/Counts.vue'
import Fines from '@/components/dashboard/clients/Fines.vue'
import NewFine from '@/components/dashboard/clients/NewFine.vue'
import Debts from '@/components/dashboard/clients/Debts.vue'
import CompanyCounts from '@/components/dashboard/counts/CompanyCounts.vue'
import NewCount from '@/components/dashboard/counts/NewCount.vue'
import ServiceTech from '@/components/dashboard/services/ServiceTech.vue'
import ServiceInsurance from '@/components/dashboard/services/ServiceInsurance.vue'
import Profile from '@/components/dashboard/users/Profile.vue'
import Analitics from '@/components/dashboard/analitics/Analitics.vue'
import CompanyReports from '@/components/dashboard/analitics/CompanyReports.vue'
import ClientsReports from '@/components/dashboard/analitics/ClientsReports.vue'
import CarsReports from '@/components/dashboard/analitics/CarsReports.vue'
import WorkersReports from '@/components/dashboard/analitics/WorkersReports.vue'
import BookingsReports from '@/components/dashboard/analitics/BookingsReports.vue'
import CompanyProfile from '@/components/dashboard/users/CompanyProfile.vue'
import CreateEmployee from '@/components/dashboard/users/CreateEmployee.vue'
// Transactions
import Transactions from '@/components/dashboard/transactions/Transactions.vue'
import ResultSuccess from '@/components/dashboard/transactions/ResultSuccess.vue'
import ResultBad from '@/components/dashboard/transactions/ResultBad.vue'
// Склад
import Store from '@/components/dashboard/store/Store.vue'
// Тарифы
import Tariffs from '@/components/dashboard/tariffs/Tariffs.vue'
// Инвесторы
import NewInvestor from '@/components/dashboard/investors/NewInvestor.vue'
import Investors from '@/components/dashboard/investors/Investors.vue'
import Investor from '@/components/dashboard/investors/Investor.vue'
// Филиалы
import Branches from '@/components/dashboard/branches/Branches.vue'
// Шаблоны
import DocTemplate from '@/components/dashboard/templates/DocTemplate.vue'
// Help
import QuickStart from '@/components/dashboard/help/QuickStart.vue'
import DocCreation from '@/components/dashboard/help/DocCreation.vue'
import AddCar from '@/components/dashboard/help/AddCar.vue'
import Widget from '@/components/dashboard/help/Widget.vue'
import UsersRoles from '@/components/dashboard/help/UsersRoles.vue'
import FunctionsDesc from '@/components/dashboard/help/FunctionsDesc.vue'
import Problems from '@/components/dashboard/help/Problems.vue'
import Faqs from '@/components/dashboard/help/Faqs.vue'
import DocVariables from '@/components/dashboard/help/DocVariables.vue'
// Agregator
import BookingSettings from '@/components/dashboard/agregator/BookingSettings.vue'
// Clietn Page
import ClientPage from '@/components/dashboard/clients/ClientPage.vue'
// Messages
import Messages from '@/components/dashboard/messages/Messages.vue'
import http404 from '@/components/http404.vue'

Vue.use(Router)

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        meta: { requiresAuth: true }
    },
    {
        path: '/signin',
        name: 'Signin',
        component: Signin,
        meta: { requiresAuth: false }
    },
    {
        path: '/client_page',
        name: 'ClientPage',
        component: ClientPage,
        meta: { requiresAuth: false }
    },
    {
        path: '/dashboard',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        meta: { requiresAuth: false }
    },
    {
        path: '/forgot_password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: { requiresAuth: false }
    },
    {
        path: '/reset_password/:token',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: { requiresAuth: false }
    },
    {
        path: '/todos_all',
        name: 'AllTodosList',
        component: AllTodosList,
        meta: { requiresAuth: true }
    },
    {
        path: '/todos_user',
        name: 'UsersTodosList',
        component: UsersTodosList,
        meta: { requiresAuth: true }
    },
    {
        path: '/check_lists',
        name: 'CheckList',
        component: CheckList,
        meta: { requiresAuth: true }
    },
    {
        path: '/cars',
        name: 'Cars',
        component: Cars,
        meta: { requiresAuth: true }
    },
    {
        path: '/cars/new',
        name: 'newCars',
        component: NewCars,
        meta: { requiresAuth: true }
    },
    {
        path: '/cars/:id',
        name: 'CarCard',
        component: CarCard,
        meta: { requiresAuth: true }
    },
    {
        path: '/archive_cars',
        name: 'ArchiveCars',
        component: ArchiveCars,
        meta: { requiresAuth: true }
    },
    {
        path: '/map',
        name: 'Gps',
        component: Gps,
        meta: { requiresAuth: true }
    },
    {
        path: '/starline',
        name: 'StarLine',
        component: StarLine,
        meta: { requiresAuth: true }
    },
    {
        path: '/bookings',
        name: 'Bookings',
        component: Bookings,
        meta: { requiresAuth: true }
    },
    {
        path: '/archive_bookings',
        name: 'ArchiveBookings',
        component: ArchiveBookings,
        meta: { requiresAuth: true }
    },
    {
        path: '/bookings/:id',
        name: 'BookingCard',
        component: BookingCard,
        meta: { requiresAuth: true }
    },
    {
        path: '/booking/new',
        name: 'NewBooking',
        component: NewBooking,
        meta: { requiresAuth: true }
    },
    {
        path: '/investors/new',
        name: 'NewInvestor',
        component: NewInvestor,
        meta: { requiresAuth: true }
    },
    {
        path: '/investors',
        name: 'Investors',
        component: Investors,
        meta: { requiresAuth: true }
    },
    {
        path: '/branches',
        name: 'Branches',
        component: Branches,
        meta: { requiresAuth: true }
    },
    {
        path: '/investors/:id',
        name: 'Investor',
        component: Investor,
        meta: { requiresAuth: true }
    },
    {
        path: '/tariffs',
        name: 'Tariffs',
        component: Tariffs,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/users',
        name: 'UsersList',
        component: UsersList,
        meta: { requiresAuth: true }
    },
    {
        path: '/admin/users/:id',
        name: 'UserEdit',
        component: UserEdit,
        meta: { requiresAuth: true }
    },
    {
        path: '/clients',
        name: 'Clients',
        component: Clients,
        meta: { requiresAuth: true }
    },
    {
        path: '/clients/:id',
        name: 'ClientCard',
        component: ClientCard,
        meta: { requiresAuth: true }
    },
    {
        path: '/whatsapp_chat',
        name: 'WhatsAppChat',
        component: WhatsAppChat,
        meta: { requiresAuth: true }
    },
    {
        path: '/whatsapp_client/:id',
        name: 'WhatsAppClient',
        component: WhatsAppClient,
        meta: { requiresAuth: true }
    },
    {
        path: '/client/new',
        name: 'NewClient',
        component: NewClient,
        meta: { requiresAuth: true }
    },
    {
        path: '/invoices',
        name: 'Invoices',
        component: Invoices,
        meta: { requiresAuth: true }
    },
    {
        path: '/invoices/:query',
        name: 'InvoicesWithQuery',
        component: Invoices,
        meta: { requiresAuth: true }
    },
    {
        path: '/calendars/bookings',
        name: 'BookingsCalendar',
        component: BookingsCalendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/calendars/new',
        name: 'NewCalendar',
        component: NewCalendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/calendars/timeline',
        name: 'TimelineCalendar',
        component: TimelineCalendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/counts',
        name: 'Counts',
        component: Counts,
        meta: { requiresAuth: true }
    },
    {
        path: '/company_counts',
        name: 'CompanyCounts',
        component: CompanyCounts,
        meta: { requiresAuth: true }
    },
    {
        path: '/counts/new',
        name: 'NewCount',
        component: NewCount,
        meta: { requiresAuth: true }
    },
    {
        path: '/fines',
        name: 'Fines',
        component: Fines,
        meta: { requiresAuth: true }
    },
    {
        path: '/fines/:query',
        name: 'FinesWithQuery',
        component: Fines,
        meta: { requiresAuth: true }
    },
    {
        path: '/new_fine',
        name: 'NewFine',
        component: NewFine,
        meta: { requiresAuth: true }
    },
    {
        path: '/debts',
        name: 'Debts',
        component: Debts,
        meta: { requiresAuth: true }
    },
    {
        path: '/debts/:query',
        name: 'DebtsWithQuery',
        component: Debts,
        meta: { requiresAuth: true }
    },
    {
        path: '/service_insurance',
        name: 'ServiceInsurance',
        component: ServiceInsurance,
        meta: { requiresAuth: true }
    },
    {
        path: '/service_tech',
        name: 'ServiceTech',
        component: ServiceTech,
        meta: { requiresAuth: true }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: { requiresAuth: true }
    },
    {
        path: '/salary',
        name: 'Salary',
        component: Salary,
        meta: { requiresAuth: true }
    },
    {
        path: '/company_profile',
        name: 'CompanyProfile',
        component: CompanyProfile,
        meta: { requiresAuth: true }
    },
    {
        path: '/analitics',
        name: 'Analitics',
        component: Analitics,
        meta: { requiresAuth: true }
    },
    {
        path: '/company_reports/:query',
        name: 'CompanyReports',
        component: CompanyReports,
        meta: { requiresAuth: true }
    },
    {
        path: '/clients_reports/:query',
        name: 'ClientsReports',
        component: ClientsReports,
        meta: { requiresAuth: true }
    },
    {
        path: '/cars_reports/:query',
        name: 'CarsReports',
        component: CarsReports,
        meta: { requiresAuth: true }
    },
    {
        path: '/workers_reports/:query',
        name: 'WorkersReports',
        component: WorkersReports,
        meta: { requiresAuth: true }
    },
    {
        path: '/bookings_reports/:query',
        name: 'BookingsReports',
        component: BookingsReports,
        meta: { requiresAuth: true }
    },
    {
        path: '/create_employee',
        name: 'CreateEmployee',
        component: CreateEmployee,
        meta: { requiresAuth: true }
    },
    {
        path: '/schedule_calendar',
        name: 'ScheduleCalendar',
        component: ScheduleCalendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/frappe_calendar',
        name: 'FrappeCalendar',
        component: FrappeCalendar,
        meta: { requiresAuth: true }
    },
    {
        path: '/store',
        name: 'Store',
        component: Store,
        meta: { requiresAuth: true }
    },
    {
        path: '/messages',
        name: 'Messages',
        component: Messages,
        meta: { requiresAuth: true }
    },
    {
        path: '/doc_template',
        name: 'DocTemplate',
        component: DocTemplate,
        meta: { requiresAuth: true }
    },
    {
        path: '/transactions',
        name: 'Transactions',
        component: Transactions,
        meta: { requiresAuth: true }
    },
    {
        path: '/result_success',
        name: 'ResultSuccess',
        component: ResultSuccess,
        props: true,
        meta: { requiresAuth: false }
    },
    {
        path: '/result_bad',
        name: 'ResultBad',
        component: ResultBad,
        props: true,
        meta: { requiresAuth: false }
    },
    {
        path: '/help/quick_start',
        name: 'QuickStart',
        component: QuickStart,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/doc_creation',
        name: 'DocCreation',
        component: DocCreation,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/add_car',
        name: 'AddCar',
        component: AddCar,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/widget',
        name: 'Widget',
        component: Widget,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/problems',
        name: 'Problems',
        component: Problems,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/roles',
        name: 'UsersRoles',
        component: UsersRoles,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/functions_desc',
        name: 'FunctionsDesc',
        component: FunctionsDesc,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/faqs',
        name: 'Faqs',
        component: Faqs,
        meta: { requiresAuth: true }
    },
    {
        path: '/help/doc_variables',
        name: 'DocVariables',
        component: DocVariables,
        meta: { requiresAuth: true }
    },
    {
        path: '/agregator/settings',
        name: 'BookingSettings',
        component: BookingSettings,
        meta: { requiresAuth: true }
    },
    {
        path: "/404",
        name: "http404",
        component: http404,
        meta: { requiresAuth: false }
    },
    {
        path: '*',
        redirect: '/404'
    }
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
})
router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // const isAuthenticated = firebase.auth().currentUser;
    const isAuthenticated = localStorage.getItem('auth_token');
    //   console.log("isauthenticated", isAuthenticated);
    if (requiresAuth && !isAuthenticated) {
        next("/signin");
    } else {
        next();
    }
});
export default router
