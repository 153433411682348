<template>
  <v-footer padless>
    <v-row justify="center" no-gutters>
      <v-col class="py-4 text-center" cols="12">
        <p>
          <v-icon @click="changeTheme()" class="mx-2">
            mdi-theme-light-dark
          </v-icon>
          <select
            v-model="select_locale"
            item-text="text"
            item-value="value"
            :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
          >
            <option v-for="(lang, i) in locales" :key="`Lang${i}`" :value="lang.value" >
              {{ lang.text }}
            </option>
          </select>
          <span class="ml-1 mr-2">|</span>
          <a :href="`https://rentprog.${$root.$i18n.locale == 'ru' ? 'ru' : 'com'}/agreement?lang=${$root.$i18n.locale}`" target="_blank" :style="$vuetify.theme.dark ? 'color: white; text-decoration: none;' : 'color: black; text-decoration: none;'"
            >{{ $t('custom.user_agreement') }}</a
          >
          <span class="ml-2 mr-1">|</span>
          {{ new Date().getFullYear() }} — <strong>RentProg</strong>
        </p>
      </v-col>
    </v-row>
    <vue-cookie-accept-decline
      :debug="false"
      :disableDecline="false"
      :showPostponeButton="false"
      @clicked-decline="cookieClickedDecline"
      elementId="cookiePanel"
      position="bottom-left"
      ref="cookiePanel"
      transitionName="slideFromBottom"
      type="floating"
    >
      <!-- Optional -->
      <template #postponeContent>&times;</template>

      <!-- Optional -->
      <template #message>
        <p :class="$vuetify.theme.dark ? 'black--text' : ''">{{ $t('gdrp.text') }}
          <a href="https://rentprog.com/privacy" target="_blank">{{ $t('gdrp.more') }}...</a>
        </p>
      </template>

      <!-- Optional -->
      <template #declineContent>{{ $t('gdrp.decline') }}</template>

      <!-- Optional -->
      <template #acceptContent>{{ $t('gdrp.accept') }}</template>
    </vue-cookie-accept-decline>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      locales: [
        { text: "English", value: "en" },
        { text: "Русский", value: "ru" },
        { text: "Português", value: "pt" },
        { text: "Српски", value: "sr" },
        { text: "Thai", value: "th" },
        { text: "Hindi", value: "hi" },
        { text: "Turkish", value: "tr" },
        { text: "French", value: "fr" },
        { text: "German", value: "de" },
        { text: "Spanish", value: "es" },
        { text: "Italian", value: "it" },
        { text: "Indonesian", value: "id" },
      ],
      select_locale: this.$i18n.locale
    }
  },
  watch: {
    select_locale() {
      this.$i18n.locale = this.select_locale
      this.$root.$i18n.locale = this.select_locale
      localStorage.setItem("lang", this.select_locale)
    }
  },
  methods: {
    cookieClickedDecline() {
      this.$refs.cookiePanel.removeCookie()
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  }
};
</script>
