<template>
  <span
    v-resize="checkMobile"
    :style="isMobile ? 'padding: 5px !important;' : ''"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card v-if="client">
      <v-card-text ref="chatContainer" style="scroll-margin-bottom: 150px;">
        <v-row class="space-around d-flex flex-column">
          <v-card v-for="message in messages" :key="message.timestamp" flat>
            <v-list-item :key="message.timestamp" v-if="message.type != 'outgoing'" class="">
              <v-list-item-avatar class="align-self-start mr-2">
                <v-avatar
                  size="40"
                  color="success"
                  @click="replaceToClientCard()"
                  v-tooltip="{
                    content: `${client.lastname} ${client.name} ${client.middlename} ${client.phone}`,
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  {{ client.name ? client.name[0] : '' }} {{ client.lastname ? client.lastname[0] : '' }}
                  <!-- <v-img :src="getAvatar()"></v-img> -->
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content class="received-message">
                <v-card v-if="message.typeMessage == 'textMessage'" color="success" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption"></span>
                    <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'imageMessage'" color="success" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption">{{ message.textMessage }}</span>
                    <v-img
                      :lazy-src="'data:image/png;base64,' + message.jpegThumbnail"
                      max-height="150"
                      max-width="250"
                      :src="message.downloadUrl"
                      @click="saveFile(message.downloadUrl, message.fileName)"
                    ></v-img>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'audioMessage'" color="success" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption">{{ message.textMessage }}</span>
                    <span class="align-self-start text-subtitle-1">
                      <audio controls>
                        <source :src="message.downloadUrl" type="audio/ogg">
                        <source :src="message.downloadUrl" type="audio/mpeg">
                        Your browser does not support the audio element.
                      </audio>
                    </span>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'documentMessage'" color="success" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption">{{ message.textMessage }}</span>
                    <v-btn
                      color="primary"
                      text
                      @click="saveFile(message.downloadUrl, message.fileName)"
                    >
                      {{ message.fileName }}
                    </v-btn>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'videoMessage'" color="success" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column" style="cursor: pointer;">
                    <span class="text-caption">{{ message.textMessage }}</span>
                      <v-img
                        :lazy-src="'data:image/png;base64,' + message.jpegThumbnail"
                        max-height="150"
                        max-width="250"
                        :src="message.downloadUrl"
                        @click="saveFile(message.downloadUrl, message.fileName)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-icon
                              color="grey lighten-1"
                              dark
                              size="64"
                              @click="saveFile(message.downloadUrl, message.fileName)"
                            >
                              mdi-youtube
                            </v-icon>
                          </v-row>
                        </template>
                      </v-img>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'extendedTextMessage'" color="success" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption"></span>
                    <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                    <!-- <span class="align-self-start text-subtitle-1">{{ message.extendedTextMessage }}</span> -->
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else :key="message.timestamp">
              <v-list-item-content class="sent-message justify-end">
                <v-card v-if="message.typeMessage == 'textMessage'" color="primary" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption"></span>
                    <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'extendedTextMessage'" color="primary" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption"></span>
                    <span class="align-self-start text-subtitle-1">{{ message.textMessage }}</span>
                    <!-- <span class="align-self-start text-subtitle-1">{{ message.extendedTextMessage }}</span> -->
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'imageMessage'" color="primary" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption">{{ message.textMessage }}</span>
                    <v-img
                      :lazy-src="'data:image/png;base64,' + message.jpegThumbnail"
                      max-height="150"
                      max-width="250"
                      :src="message.downloadUrl"
                      @click="saveFile(message.downloadUrl, message.fileName)"
                    ></v-img>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'audioMessage'" color="primary" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption">{{ message.textMessage }}</span>
                    <span class="align-self-start text-subtitle-1">
                      <audio controls>
                        <source :src="message.downloadUrl" type="audio/ogg">
                        <source :src="message.downloadUrl" type="audio/mpeg">
                        Your browser does not support the audio element.
                      </audio>
                    </span>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'documentMessage'" color="primary" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column">
                    <span class="text-caption">{{ message.textMessage }}</span>
                    <v-btn
                      color="secondary"
                      text
                      @click="saveFile(message.downloadUrl, message.fileName)"
                    >
                      {{ message.fileName ? message.fileName : 'Download' }}
                    </v-btn>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
                <v-card v-if="message.typeMessage == 'videoMessage'" color="primary" class="flex-none">
                  <v-card-text class="white--text pa-2 d-flex flex-column" style="cursor: pointer;">
                    <span class="text-caption">{{ message.textMessage }}</span>
                      <v-img
                        :lazy-src="'data:image/png;base64,' + message.jpegThumbnail"
                        max-height="150"
                        max-width="250"
                        :src="message.downloadUrl"
                        @click="saveFile(message.downloadUrl, message.fileName)"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-icon
                              color="grey lighten-1"
                              dark
                              size="64"
                              @click="saveFile(message.downloadUrl, message.fileName)"
                            >
                              mdi-youtube
                            </v-icon>
                          </v-row>
                        </template>
                      </v-img>
                    <span class="text-caption font-italic align-self-end">
                      <timeago :datetime="formatTime(message.timestamp)" :auto-update="60" :locale="$i18n.locale"></timeago>
                    </span>
                  </v-card-text>
                </v-card>
              </v-list-item-content>
              <v-list-item-avatar class="align-self-start ml-2" @click="replaceToClientCard()">
                <v-avatar
                  size="40"
                  color="primary"
                >
                  {{ $t('custom.you') }}
                </v-avatar>
                <!-- <v-img :src="getAvatar()"></v-img> -->
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </v-row>
        <v-footer fixed>
          <v-container class="ma-0 pa-0">
            <v-row no-gutters>
              <v-col>
                <div class="d-flex flex-row align-center">
                  <v-textarea rows="1" autofocus auto-grow v-model="message" :placeholder="$t('companies.type_something')" @keypress.enter="sendMesssage()"></v-textarea>
                  <v-btn icon class="ml-4" @click="sendMesssage()"><v-icon>mdi-send</v-icon></v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-card-text>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      client: null,
      client_profile: null,
      bookings: [],
      messages: [],
      message: null,
      isMobile: false,
      isLoading: false,
    };
  },
  mounted() {
    let self = this;
    if (this.$store.getters.isLoggedIn) {
      this.$cable.subscribe({
        channel: "WhatsappChannel",
        room: this.$store.getters.getCompany.company.id,
      });
    }
  },
  updated() {
    // Только тут работает
    this.scrollToBottom();
    // выключаем crisp
    if (this.$crisp && window.$crisp) {
      this.$crisp.push(["do", "chat:hide"]);
    }
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      // выключаем crisp
      if (this.$crisp && window.$crisp) {
        this.$crisp.push(["do", "chat:hide"]);
      }
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/clients/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.client = response.data.client;
          // this.bookings = response.data.bookings;
          if (this.client && this.client.phone) {
            let phone = this.client.phone.replace(/[^\d]/g, "");
            this.$cable.subscribe({
              channel: "WhatsappChannel",
              room: phone,
            });
          }
        })
        .then((response) => {
          this.loadMessages();
          this.scrollToBottom();
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.client_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  channels: {
    WhatsappChannel: {
      connected() {
        console.log("Connected to WhatsappChannel");
      },
      // rejected() {
      //   console.log("rejected");
      // },
      received(data) {
        if (data) {
          if (data.client && data.client.id && data.client.id == this.client.id) {
            this.messages.push(data);
            // scroll to bottom
            this.scrollToBottom();
          }
        }
      },
      // disconnected() {
      //   console.log("disconnected");
      // }
    },
  },
  computed: {
    clients_sources() {
      if (this.$store.getters.getCompany.company.clients_sources) {
        return this.$store.getters.getCompany.company.clients_sources;
      } else {
        return [];
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      if (this.$store.getters.getCompany) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
  },
  methods: {
    loadMessages() {
      if (this.company && (this.company.chat_api_instance && this.company.chat_api_instance.length > 0 && this.company.chat_api_token && this.company.chat_api_token.length > 0)) {
        let phone = this.client.phone.replace(/[^\d]/g, "");
        let self = this;
        this.isLoading = true;
        this.axios({
          url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/getChatHistory/${this.company.chat_api_token}`,
          method: "post",
          data: {
            "chatId": `${phone}@c.us`,
            "count": 100
          },
        })
          .then((response) => {
            this.messages = response.data.reverse();
            // console.log(this.messages);
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          title: this.$t("errors.error"),
          text: this.$t("errors.error"),
          icon: "error",
          showCancelButton: false,
        });
      }
    },
    getAvatar() {
      if (this.client_profile == null) {
        let self = this;
        // this.isLoading = true;
        let phone = this.client.phone.replace(/[^\d]/g, "");
        let avatar_url = "https://via.placeholder.com/50";
        this.axios({
          url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/getAvatar/${this.company.chat_api_token}`,
          method: "post",
          data: {
            "chatId": `${phone}@c.us`
          },
        })
          .then((response) => {
            this.client_profile = {
              avatar: response.data.avatar,
              name: response.data.name}
            console.log(response.data);
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));

        if(this.client_profile && this.client_profile.avatar) {
          return this.client_profile.avatar;
        } else {
          return avatar_url;
        }
      } else if (this.client_profile && this.client_profile.avatar) {
        return this.client_profile.avatar;
      }
    },
    sendMesssage() {
      if (this.message && this.message != "" && this.message != " " && this.message.length > 0) {
        let phone = this.client.phone.replace(/[^\d]/g, "");
        let self = this;
        this.isLoading = true;
        this.axios({
          url: `https://api.green-api.com/waInstance${this.company.chat_api_instance}/sendMessage/${this.company.chat_api_token}`,
          method: "post",
          data: {
            "chatId": `${phone}@c.us`,
            "message": this.message
          },
        })
          .then((response) => {
            this.scrollToBottom();
            this.messages.push({
              "type": 'outgoing',
              "textMessage": this.message,
              "timestamp": Math.floor(Date.now() / 1000)
            });
            this.message = "";
            console.log(response.data);
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          title: this.$t("errors.error"),
          text: this.$t("errors.error"),
          icon: "error",
          showCancelButton: false,
        });
      }
    },
    saveFile(downloadUrl, fileName) {
      saveAs(downloadUrl, fileName);
    },
    scrollToBottom() {
      // <v-card-text ref="chatContainer" style="scroll-margin-bottom: 150px;">
      var container = this.$refs.chatContainer;
      this.$nextTick(function() {
        if (container) {
          container.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest' });
        }
      });
    },
    formatTime(time) {
      return moment.unix(time).toDate();
    },
    replaceToClientCard() {
      this.$router.push({
        path: `/clients/${this.client.id}`,
      });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
        this.paymentsPanel = [];
      } else {
        this.paymentsPanel = 0;
        this.isMobile = false;
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
<style scoped>
  .chat-message {
    display: unset !important;
    white-space: break-spaces;
  }
  .chat-screen {
    max-height: 320px;
    overflow-y: auto;
  }
  .flex-none {
    flex: unset;
  }
  .received-message::after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 54px;
    right: auto;
    top: 12px;
    bottom: auto;
    border: 12px solid;
    border-color: #4caf50 transparent transparent transparent;
  }
  .sent-message::after {
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      left: auto;
      right: 54px;
      top: 12px;
      bottom: auto;
      border: 12px solid;
      border-color: #1976d2 transparent transparent transparent;
  }
</style>
