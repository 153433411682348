<template>
  <span>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <!-- <v-flex md12>
          <h2>{{ $t('custom.analitics') }}</h2>
        </v-flex> -->
    <v-container fluid fill-height>
      <!-- <v-layout> -->

      <v-toolbar
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="align-center"
      >
        <v-toolbar-title
          >{{ $t("analitics.common_clients") }} {{ $t("custom.number")
          }}{{ report.id }} {{ start_date_formatted }} -
          {{ end_date_formatted }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/8/guides/25`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- По компании -->
      <v-flex v-if="report.state == 'created'" md12 class="d-inline-flex">
        <v-flex md12 class="mt-2">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-center">
                    {{ $t("analitics.new_clients") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.new_active_clients") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.active_clients") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.convertion") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.ads_expences") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.ads_expences_one") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.ads_expences_one_active") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.revenue") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.average_mileage") }}
                  </th>
                  <th class="text-center">
                    {{ $t("analitics.average_mileage_day") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">
                    {{ report.new_clients_count }}
                  </td>
                  <td class="text-center">
                    {{ report.new_active_clients }}
                  </td>
                  <td class="text-center">
                    {{ report.active_clients }}
                  </td>
                  <td class="text-center">
                    {{ parseFloat(report.convertion).toFixed(2) }}%
                  </td>
                  <td class="text-center">
                    {{ parseInt(report.ads_expences) }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{ parseFloat(report.attract_client_cost).toFixed(2)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{ parseFloat(report.attract_active_client_cost).toFixed(2)
                    }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{ parseFloat(report.revenue).toFixed(2) }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{ parseInt(report.mileage_per_active_client) }}
                    {{ $t("custom.km") }}
                  </td>
                  <td class="text-center">
                    {{ parseInt(report.daily_mileage_for_client) }}
                    {{ $t("custom.km") }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-flex>
      <v-flex v-else md12 class="text-center mt-10">
        {{ $t("analitics.report_in_progress") }}
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <br />
        <v-btn @click="$router.go()">{{ $t("custom.update") }}</v-btn>
      </v-flex>
    </v-container>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { GChart } from "vue-google-charts";
import { Capacitor } from "@capacitor/core";
export default {
  data() {
    return {
      report: {},
      property: false,
      pie_chart_data: [],
      revenue_for_period_data: [],
      pie_chart_income_data: [],
      pie_chart_expences_data: [],
      pieIncomeOptions: {
        title: this.$t("custom.income"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      pieExpencesOptions: {
        title: this.$t("custom.expences"),
        pieHole: 0.4,
        sliceVisibilityThreshold: 0.00014,
      },
      showExcel: false,
      showRevenueGraf: false,
      showRevenueColumn: false,
      showRevenueTable: false,
      showClientsData: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isGuest)
    ) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/clients_reports/${this.$route.params.query}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.report = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_report_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$router.push({ path: `/dashboard` }).catch((err) => {});
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.only_admins"),
        text: this.error,
      });
    }
  },
  computed: {
    start_date_formatted() {
      return moment(this.report.start_date).format("DD.MM.YYYY");
    },
    end_date_formatted() {
      return moment(this.report.end_date).format("DD.MM.YYYY");
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    setError(error, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    GChart,
  },
};
</script>
