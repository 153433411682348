<template>
  <v-app>
    <!-- <drawer v-if="showMenu()"></drawer> -->
    <app-header v-if="showMenu()"></app-header>
    <v-main v-resize="checkMobile">
      <!-- <v-banner v-if="company && appVersion && appVersion != 0 && app_version && appVersion != app_version" color="primary" dark class="text-center">
      {{ $t('app.new_version_message') }}
      <v-btn color="info" @click="reloadPage()">{{ $t('app.new_version_btn') }}</v-btn>
    </v-banner> -->
      <v-container fluid>
        <v-banner
          v-if="company && company.time_zone == 'UTC'"
          color="primary"
          dark
          class="text-center"
        >
          {{ $t("app.time_zone_message") }}
          <v-btn
            color="success"
            @click="
              $router.push({ path: `/company_profile` }).catch((err) => {})
            "
            >{{ $t("custom.settings") }}</v-btn
          >
        </v-banner>
        <!-- <banner v-if="showMenu()"></banner> -->
        <!-- <v-banner v-if="showMenu() && !$store.getters.getCurrentUser.registration_tour" color="info" dark class="text-left"> -->
        <!-- <v-banner v-if="false" color="info" dark class="text-left">
          {{ $t("app.first_lesson") }}

          <template v-slot:actions>
            <v-btn
              color="success"
              @click="
                $router.push({ path: `/dashboard` }).catch((err) => {}),
                  $tours['registrationTour'].start()
              "
              >{{ $t("custom.yes") }}</v-btn
            >
            <v-btn color="grey" @click="registrationTourFalse()">{{
              $t("custom.no")
            }}</v-btn>
          </template>
        </v-banner> -->
        <!-- Пополните счет -->
        <v-banner
          v-if="main_company && payer_state == 2"
          color="warning"
          dark
          class="text-left"
        >
          {{ $t("app.system_balance") }}:
          {{ main_company ? Math.round(balance) : 0
          }}<span>{{
            $store.getters.getMainCompany &&
            $store.getters.getMainCompany.currency == "RUB"
              ? "₽"
              : "€"
          }}</span
          >. {{ $t("app.save_enter") }}
          <template v-slot:actions>
            <v-btn
              color="info"
              @click="$router.push({ path: `transactions` }).catch((err) => {})"
              >{{ $t("custom.add_transfer") }}</v-btn
            >
          </template>
        </v-banner>
        <!-- Срочно Пополните счет -->
        <v-banner
          v-if="main_company && payer_state == 3"
          color="error"
          dark
          class="text-left"
        >
          {{ $t("app.system_balance") }}:
          {{ main_company ? Math.round(balance) : 0
          }}<span>{{
            $store.getters.getMainCompany &&
            $store.getters.getMainCompany.currency == "RUB"
              ? "₽"
              : "€"
          }}</span
          >. {{ $t("app.save_enter_last") }}
          <template v-slot:actions>
            <v-btn
              color="info"
              @click="$router.push({ path: `transactions` }).catch((err) => {})"
              >{{ $t("custom.add_transfer") }}</v-btn
            >
          </template>
        </v-banner>
        <v-banner single-line v-if="company && showRemoveDemo">
          <v-icon slot="icon" color="warning" size="36">
            mdi-information-outline
          </v-icon>
          {{ $t("demo_content.remove_demo_content_text_2") }}
          <template v-slot:actions>
            <v-btn color="warning" @click="removeDemoContent()">
              {{ $t("demo_content.remove") }}
            </v-btn>
          </template>
        </v-banner>
        <v-banner
          single-line
          @click:icon="replaceToTelegram()"
          v-if="
            company &&
            showBanner &&
            showTelegram &&
            !isMobile &&
            !showRemoveDemo
          "
        >
          <v-icon slot="icon" color="warning" size="36">
            mdi-information-outline
          </v-icon>
          {{ $t("telegram.banner_text") }}
          <v-btn
            color="primary"
            @click="closeBanner"
            :href="
              $i18n.locale == 'ru'
                ? 'https://t.me/rentprogcrm'
                : 'https://t.me/rentprog_world'
            "
            target="_blank"
            text
          >
            {{ $t("custom.join") }}
          </v-btn>
          <template v-slot:actions>
            <v-btn @click="closeBanner" icon>
              <v-icon color="secondery"> mdi-window-close </v-icon>
            </v-btn>
          </template>
        </v-banner>
        <v-banner
          single-line
          v-if="
            company && showBanner && showTelegram && isMobile && !showRemoveDemo
          "
          @click:icon="replaceToTelegram()"
        >
          <v-icon slot="icon" color="warning" size="36">
            mdi-information-outline
          </v-icon>
          <v-btn
            color="primary"
            @click="closeBanner"
            :href="
              $i18n.locale == 'ru'
                ? 'https://t.me/rentprogcrm'
                : 'https://t.me/rentprog_world'
            "
            target="_blank"
            text
          >
            <!-- {{ $t('custom.join') }} -->
            {{ $t("telegram.banner_text_mobile") }}
          </v-btn>
          <template v-slot:actions>
            <v-btn @click="closeBanner" icon>
              <v-icon color="secondery"> mdi-window-close </v-icon>
            </v-btn>
          </template>
        </v-banner>
        <router-view></router-view>
        <tour></tour>
      </v-container>
      <!-- Удалить демо контент -->
      <v-overlay :value="showOverlayRemoveDemo">
        <v-card>
          <v-card-text>
            {{ $t("demo_content.removing_demo_content") }}
            <v-progress-linear
              class="mt-2"
              color="error accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-overlay>
    </v-main>
    <app-footer v-if="!isNative"></app-footer>
  </v-app>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
// import Banner from "./components/Banner.vue";
import Drawer from "./components/Drawer.vue";
import AppFooter from "./components/Footer.vue";
import Tour from "./components/Tour.vue";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import PullToRefresh from "pulltorefreshjs";
import { PushNotifications } from "@capacitor/push-notifications";
import { StatusBar, Style } from "@capacitor/status-bar";

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$t("custom.title_meta_1"),
      meta: [
        { name: "description", content: this.$t("custom.description_meta_1") },
        { property: "og:title", content: this.$t("custom.title_meta_1") },
        { property: "og:site_name", content: "CRM RentProg" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  beforeMount() {
    if (this.isNative) {
      const setStatusBarStyleLight = async () => {
        await StatusBar.setStyle({ style: Style.Light });
      };
      setStatusBarStyleLight();
    }
  },
  mounted() {
    let self = this;
    const ptr = PullToRefresh.init({
      mainElement: "body",
      distIgnore: 100,
      instructionsPullToRefresh: this.$t("custom.pull_to_refresh"),
      instructionsReleaseToRefresh: this.$t("custom.release_to_refresh"),
      instructionsRefreshing: this.$t("custom.refreshing"),
      onRefresh() {
        // window.location.reload();
        if (self.$route.name != "NewCalendar") {
          self.$router.go(0);
        }
      },
    });
    if (
      this.$store.getters.getCurrentUser &&
      this.$store.getters.getCurrentUser != {} &&
      this.$store.getters.getSignedIn &&
      this.$route.name == "Home" &&
      !this.$store.getters.getCurrentUser.registration_tour &&
      this.$tours["registrationTour"]
    ) {
      this.$tours["registrationTour"].start();
    }
  },
  components: {
    AppHeader,
    Drawer,
    AppFooter,
    // Banner,
    Tour,
  },
  created() {
    // create script tag for newrelic
    // if (process.env.NODE_ENV == "production" && !this.isNative) {
    //   const script = document.createElement("script");
    //   script.src = `/newrelic.js`;
    //   script.async = true;
    //   document.head.appendChild(script);
    // }
  },
  updated() {
    if (
      this.$store.getters.getCurrentUser &&
      this.$store.getters.getCurrentUser != {} &&
      this.$store.getters.getSignedIn &&
      this.$route.name == "Home" &&
      !this.$store.getters.getCurrentUser.registration_tour &&
      this.$tours["registrationTour"]
    ) {
      this.$tours["registrationTour"].start();
    }
  },
  destroyed() {
    PullToRefresh.destroyAll();
    // ptr.destroy();
  },
  data() {
    return {
      deferredPrompt: null,
      isMobile: false,
      app_version: process.env.VUE_APP_VERSION,
      showOverlayRemoveDemo: false,
    };
  },
  watch: {},
  computed: {
    company() {
      return this.$store.getters.getCompany.company;
    },
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    payer_state() {
      return this.$store.getters.getMainCompany.payer_state;
    },
    balance() {
      return this.$store.getters.getMainCompany.balance;
    },
    appVersion() {
      return this.$store.getters.appVersion;
    },
    showBanner() {
      return this.$store.getters.getShowBanner;
    },
    showTelegram() {
      return this.$store.getters.getCurrentUser.show_telegram;
    },
    showRemoveDemo() {
      return (
        this.$store.getters.getCompany.company.demo_content &&
        this.$store.getters.getBannerRemoveDemoContent
      );
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    isPushNotificationsAvailable() {
      return Capacitor.isPluginAvailable("PushNotifications");
    },
  },
  methods: {
    showMenu() {
      if (
        this.$route.path == "/signin" ||
        this.$route.path == "/signup" ||
        this.$route.path == "/result_success" ||
        this.$route.path == "/result_bad" ||
        this.$route.path == "/forgot_password" ||
        this.$route.name == "ResetPassword" ||
        this.$route.name == "ClientPage" ||
        this.$route.path == "/404"
      ) {
        return false;
      } else {
        return true;
      }
    },
    closeBanner() {
      let self = this;
      this.$store.commit("setShowBanner", false);
      this.axios
        .patch(
          `/api/v1/users/${self.$store.getters.getCurrentUser.id}`,
          {
            users: {
              show_telegram: false,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .catch((error) => {
          self.setError(error, self.$t("errors.profile_update"));
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: self.$t("errors.profile_update"),
            text: self.error,
          });
        })
        .finally(() => (self.isLoading = false));
    },
    removeDemoContent() {
      let self = this;
      this.$swal({
        title: this.$t("custom.you_sure_title"),
        text: this.$t("demo_content.remove_demo_content_text"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("custom.you_sure_yes"),
        cancelButtonText: this.$t("custom.you_sure_no"),
        showCloseButton: true,
      }).then((result) => {
        if (result.value) {
          this.showOverlayRemoveDemo = true;
          this.axios
            .post(`/api/v1/remove_demo_content`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              self.$store.commit("setBannerRemoveDemoContent");
              setTimeout(() => {
                self.showOverlayRemoveDemo = false;
                self.$swal(
                  self.$t("custom.deleted"),
                  self.$t("demo_content.remove_demo_content_success"),
                  "success"
                );
              }, 2000);
            })
            .catch((error) => {
              self.showOverlayRemoveDemo = false;
              self.setError(error, self.$t("errors.error"));
              console.log(error);
            });
        }
      });
    },
    reloadPage() {
      window.location.reload(true);
    },
    replaceToTelegram() {
      if(this.$i18n.locale == 'ru') {
        window.open("https://t.me/rentprogcrm", "_blank");
      } else {
        window.open("https://t.me/rentprog_world", "_blank");
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>

<style lang="less">
.tooltip {
  display: block !important;
  z-index: 10000;
  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }
  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }
  &[x-placement^="top"] {
    margin-bottom: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="bottom"] {
    margin-top: 5px;
    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  &[x-placement^="right"] {
    margin-left: 5px;
    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[x-placement^="left"] {
    margin-right: 5px;
    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }
  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }
  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
  &.info {
    .tooltip-inner {
      background: rgba(#004499, 0.9);
      color: white;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }
    .tooltip-arrow {
      border-color: rgba(#004499, 0.9);
    }
  }
  &.popover {
    .popover-inner {
      background: #f9f9f9;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }
    .popover-arrow {
      border-color: #f9f9f9;
    }
  }
}
</style>
