<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <!-- <v-row justify="center" align="center"> -->
    <v-flex xs12 sm6 class="mx-auto">
      <v-card>
        <!-- <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">
                {{ $t("custom.editing") }}: {{ user.email }}
              </h3>
            </div>
          </v-card-title> -->
        <v-card-text>
          <v-text-field
            :label="$t('custom.email')"
            v-model.trim="user.email"
            :disabled="true"
          ></v-text-field>
          <v-text-field
            v-model="user.name"
            :placeholder="$t('employers.nickname')"
            :label="$t('employers.nickname')"
            :disabled="!(user.id == current_user_id) || !checkAdminManager()"
          ></v-text-field>
          <v-text-field
            v-model="user.first_name"
            :placeholder="$t('employers.first_name')"
            :label="$t('employers.name')"
            :disabled="!(user.id == current_user_id) || !checkAdminManager()"
          ></v-text-field>
          <v-text-field
            v-model="user.middle_name"
            :placeholder="$t('employers.middle_name')"
            :label="$t('employers.middle_name')"
            :disabled="!(user.id == current_user_id) || !checkAdminManager()"
          ></v-text-field>
          <v-text-field
            v-model="user.last_name"
            :placeholder="$t('employers.last_name')"
            :label="$t('employers.last_name')"
            :disabled="!(user.id == current_user_id) || !checkAdminManager()"
          ></v-text-field>
          <v-select
            :items="
              user_role == 'superadmin' || user_role == 'guest'
                ? super_roles
                : roles
            "
            v-model="user.role"
            :disabled="!checkAdmin()"
            :label="$t('other.role')"
          ></v-select>
          <v-text-field
            v-model.trim="user.partner_id"
            :placeholder="$t('partners.partner_id')"
            :label="$t('partners.partner_id')"
            v-if="user.role == 'partner'"
            :disabled="!checkAdminManager()"
          ></v-text-field>
          <v-switch
            v-if="checkSuperAdmin()"
            @change="update"
            v-model="user.can_change_branch"
            :label="$t('users.can_change_branch')"
            class="mr-2 my-auto"
            hide-details
            dense
          ></v-switch>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-flex md12 :class="isMobile ? 'justify-center' : 'd-flex'">
            <v-flex md12 class="justify-center text-center">
              <v-btn
                v-if="checkAdminManager()"
                text
                @click="update"
                color="success"
                >{{ $t("custom.save") }}
              </v-btn>
              <v-btn
                text
                v-if="(checkAdmin() || user_role == 'guest') && user.active"
                color="warning"
                @click="saveUserActive(false)"
                >{{ $t("employers.dismiss") }}
              </v-btn>
            </v-flex>
            <v-flex md12 class="justify-center text-center">
              <v-btn
                text
                v-if="(checkAdmin() || user_role == 'guest') && !user.active"
                color="success"
                @click="saveUserActive(true)"
              >
                {{ $t("custom.activate") }}
              </v-btn>
            </v-flex>
            <v-flex md12 class="justify-center text-center">
              <v-btn class="mx-auto" text to="/admin/users">{{
                $t("employers.all")
              }}</v-btn>
            </v-flex>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-flex>
    <!-- </v-row> -->
  </span>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  name: "UserEdit",
  data() {
    return {
      error: "",
      notice: "",
      user: {
        role: null,
        email: null,
        name: null,
        active: true,
        can_change_branch: false,
      },
      roles: [
        { text: this.$t("employers.employee"), value: "user" },
        { text: this.$t("employers.manager"), value: "manager" },
        { text: this.$t("employers.admin"), value: "admin" },
        { text: this.$t("employers.partner"), value: "partner" },
        { text: this.$t("employers.guest"), value: "guest" },
      ],
      super_roles: [
        { text: this.$t("employers.employee"), value: "user" },
        { text: this.$t("employers.manager"), value: "manager" },
        { text: this.$t("employers.admin"), value: "admin" },
        { text: this.$t("employers.superadmin"), value: "superadmin" },
        { text: this.$t("employers.partner"), value: "partner" },
        { text: this.$t("employers.guest"), value: "guest" },
      ],
      isLoading: false,
      isMobile: false,
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/admin/users/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.load_user"),
          });
          this.setError(error, this.$t("errors.load_user"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    current_user_id() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.id;
      } else {
        return "";
      }
    },
  },
  methods: {
    update() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        this.axios
          .patch(
            `/admin/users/${this.$route.params.id}`,
            {
              user: {
                role: this.user.role,
                name: this.user.name,
                last_name: this.user.last_name,
                middle_name: this.user.middle_name,
                first_name: this.user.first_name,
                partner_id: this.user.partner_id,
                can_change_branch: this.user.can_change_branch,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("users.user_update_success_title"),
              text: this.$t("users.user_update_success_text"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status == 406) {
              this.setError(error, this.$t("errors.must_be_one_superadmin"));
            } else {
              this.setError(error, this.$t("errors.error"));
            }
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    saveUserActive(state) {
      if (this.checkAdmin()) {
        this.isLoading = true;
        this.axios
          .patch(
            `/admin/users/${this.$route.params.id}`,
            {
              user: {
                active: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.user.active = state;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("users.user_update_success_title"),
              text: this.$t("users.user_update_success_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.only_admins"),
        });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
      this.notice = "";
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },
  },
};
</script>
