<template>
  <v-row>
    <v-col cols="12" sm="6" offset-sm="3">
      <v-card>
        <v-container fluid>
          <v-row v-if="photos.length > 0">
            <v-col
              v-for="item in sortedPhotos"
              :key="item.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-card flat tile class="d-flex flex-column">
                <v-img
                  :src="item.url"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="screenFullImage(item)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-row
                  justify="space-between"
                  class=" mt-1 mr-3 ml-3 align-center"
                >
                  {{ $t('custom.number') }}{{ item.sort }}
                  <v-icon color="success" @click="sortUp(item)">
                    mdi-thumb-up-outline
                  </v-icon>
                  <v-icon color="grey" @click="sortDown(item)">
                    mdi-thumb-down-outline
                  </v-icon>
                  <v-icon color="warning" @click="deleteItem(item)">
                    mdi-delete-forever
                  </v-icon>
                  <v-icon color="info" @click="downloadItem(item)">
                    mdi-content-save-outline
                  </v-icon>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="d-flex text-center justify-center">
            <v-card flat tile class="d-flex text-center justify-center">
              {{ $t('other.no_files') }}
            </v-card>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <v-dialog v-model="dialog" :retain-focus="false" persistent max-width="1200">
      <v-card class="mt-2">
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-img :src="link_full_image" class="grey lighten-2"> </v-img>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="(dialog = false), (link_full_image = '')"
          >
            {{ $t('custom.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      photos: [],
      link_full_image: "",
      dialog: false
    };
  },
  beforeCreate() {
    this.axios
      .get(`api/v1/cars_files/${this.$route.params.id}`, {
        headers: {
          Authorization: this.$store.getters.getAuthToken
        }
      })
      .then(response => {
        this.photos = response.data;
      })
      .catch(error => {
        this.setError(error, this.$t('errors.load_file'));
        console.log(error);
      });
  },
  computed: {
    sortedPhotos: function() {
      function compare(a, b) {
        if (a.sort < b.sort) return 1;
        if (a.sort > b.sort) return -1;
        return 0;
      }

      return (this.photos = this.photos.sort(compare));
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    }
  },
  methods: {
    screenFullImage(item) {
      this.link_full_image = item.url;
      this.dialog = true;
    },
    async downloadItem(item) {
      saveAs(`${item.url}`);
    },
    deleteItem(item) {
      if (this.user_role != "guest") {
        this.$swal({
          title: this.$t('custom.you_sure_title'),
          text: this.$t('custom.you_sure_text'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t('custom.you_sure_yes'),
          cancelButtonText: this.$t('custom.you_sure_no'),
          showCloseButton: true
        }).then(result => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/cars_files/${item.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken
                }
              })
              .then(response => {
                this.photos.splice(this.photos.indexOf(item), 1);
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t('custom.deleted'),
                  text: this.$t('other.delete_file')
                });
              })
              .catch(error => this.setError(error, this.$t('errors.delete_file')));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    sortUp(item) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/cars_files/sort_up/${item.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken
            }
          })
          .then(() => {
            item.sort += 1;
            let foundIndex = self.photos.findIndex(
              element => element.id === item.id
            );
            this.photos.splice(foundIndex, 1, item);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t('other.sort_files_title'),
              text: this.$t('other.sort_files_text')
            });
          })
          .catch(error =>
            this.setError(error, this.$t('errors.sort_file'))
          )
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    sortDown(item) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/cars_files/sort_down/${item.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken
            }
          })
          .then(() => {
            item.sort -= 1;
            let foundIndex = self.photos.findIndex(
              element => element.id === item.id
            );
            this.photos.splice(foundIndex, 1, item);
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t('other.sort_files_title'),
              text: this.$t('other.sort_files_text')
            });
          })
          .catch(error =>
            this.setError(error, this.$t('errors.sort_file'))
          )
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  }
};
</script>
