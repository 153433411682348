var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.checkMobile),expression:"checkMobile"}]},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":"#1976d2","background-color":_vm.$vuetify.theme.dark ? '#000' : ''},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('v-card',[(!_vm.isMobile)?_c('v-toolbar',{staticClass:"mb-4 start-tour-cars-1",attrs:{"text":"","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.$t('cars.free_on_period_tooltip'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }),expression:"{\n              content: $t('cars.free_on_period_tooltip'),\n              placement: 'bottom-center',\n              classes: ['info'],\n              targetClasses: ['it-has-a-tooltip'],\n              delay: {\n                show: 500,\n                hide: 500,\n              },\n            }"}],attrs:{"readonly":"","dense":_vm.isMobile,"value":_vm.sort_date_range,"attributes":_vm.attrs_calendar,"prepend-icon":"mdi-calendar-clock","label":_vm.$t('cars.free_on_period'),"single-line":"","hide-details":""}},'v-text-field',attrs,false),on))]}}],null,false,3865430089)},[_c('vc-date-picker',{attrs:{"mode":"dateTime","attributes":_vm.attrs_calendar,"is24hr":"","is-range":"","locale":_vm.$i18n.locale},model:{value:(_vm.sort_date_range_not_formatted),callback:function ($$v) {_vm.sort_date_range_not_formatted=$$v},expression:"sort_date_range_not_formatted"}})],1),(_vm.sort_date_range_not_formatted.end)?_c('v-icon',{staticClass:"mr-2 mt-2 text-left",on:{"click":function($event){(_vm.sort_date_range_not_formatted = {
            start: new Date(),
            end: null,
          }),
            (_vm.sort_date_range = null)}}},[_vm._v(" mdi-close ")]):_vm._e(),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.econom')},model:{value:(_vm.showEconom),callback:function ($$v) {_vm.showEconom=$$v},expression:"showEconom"}}),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.middle')},model:{value:(_vm.showMiddle),callback:function ($$v) {_vm.showMiddle=$$v},expression:"showMiddle"}}),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.business')},model:{value:(_vm.showBusiness),callback:function ($$v) {_vm.showBusiness=$$v},expression:"showBusiness"}}),_c('v-spacer'),_c('v-checkbox',{staticClass:"mt-5",attrs:{"label":_vm.$t('cars.commercial')},model:{value:(_vm.showCommercial),callback:function ($$v) {_vm.showCommercial=$$v},expression:"showCommercial"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('custom.search'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"success","to":"/cars/new"}},[_vm._v(_vm._s(_vm.$t("custom.add_car")))]),_c('v-btn',{attrs:{"small":"","color":"secondary","to":"/archive_cars"}},[_vm._v(_vm._s(_vm.$t("custom.archive")))]),(
          _vm.cars &&
          _vm.cars.length > 0 &&
          (_vm.user_role == 'superadmin' || _vm.user_role == 'admin')
        )?_c('download-excel',{attrs:{"data":_vm.cars}},[_c('v-icon',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success"}},[_vm._v(" mdi-file-excel-outline ")])],1):_vm._e(),(!_vm.isIos)?_c('v-btn',{attrs:{"icon":"","href":("https://rentprog." + (_vm.$root.$i18n.locale == 'ru' ? 'ru' : 'com') + "/categories/7"),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-help-circle")])],1):_vm._e()],1):_c('v-toolbar',{staticClass:"mb-2 start-tour-cars-1",attrs:{"height":"230px","text":"","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('v-flex',{staticClass:"text-center",attrs:{"md12":"","justify-center":""}},[_c('v-flex',{staticClass:"mt-2 d-flex",staticStyle:{"max-width":"250px"},attrs:{"md12":""}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"min-width":"300px"},attrs:{"readonly":"","value":_vm.sort_date_range,"attributes":_vm.attrs_calendar,"prepend-icon":"mdi-calendar-clock","label":_vm.$t('custom.sort_for_date'),"single-line":"","hide-details":""}},'v-text-field',attrs,false),on))]}}])},[_c('vc-date-picker',{staticClass:"flex d-flex md12 p-0 m-0",attrs:{"mode":"dateTime","attributes":_vm.attrs_calendar,"is24hr":"","is-range":"","locale":_vm.$i18n.locale},model:{value:(_vm.sort_date_range_not_formatted),callback:function ($$v) {_vm.sort_date_range_not_formatted=$$v},expression:"sort_date_range_not_formatted"}})],1),(_vm.sort_date_range_not_formatted.end)?_c('v-icon',{staticClass:"mr-2 mt-2 text-left",on:{"click":function($event){(_vm.sort_date_range_not_formatted = {
                start: new Date(),
                end: null,
              }),
                (_vm.sort_date_range = null)}}},[_vm._v(" mdi-close ")]):_vm._e()],1),_c('v-flex',{staticClass:"mt-2",attrs:{"md12":""}},[_c('v-flex',{staticClass:"d-flex justify-space-between",attrs:{"md6":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('cars.econom')},model:{value:(_vm.showEconom),callback:function ($$v) {_vm.showEconom=$$v},expression:"showEconom"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('cars.middle')},model:{value:(_vm.showMiddle),callback:function ($$v) {_vm.showMiddle=$$v},expression:"showMiddle"}})],1),_c('v-flex',{staticClass:"d-flex justify-space-between",attrs:{"md6":""}},[_c('v-checkbox',{attrs:{"label":_vm.$t('cars.business')},model:{value:(_vm.showBusiness),callback:function ($$v) {_vm.showBusiness=$$v},expression:"showBusiness"}}),_c('v-checkbox',{attrs:{"label":_vm.$t('cars.commercial')},model:{value:(_vm.showCommercial),callback:function ($$v) {_vm.showCommercial=$$v},expression:"showCommercial"}})],1)],1),_c('v-flex',{staticClass:"mt-0",attrs:{"md12":""}},[_c('v-text-field',{staticClass:"mb-1",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('custom.search'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-flex',{staticClass:"d-flex justify-center align-center",attrs:{"md12":""}},[_c('v-btn',{staticClass:"mt-2",attrs:{"small":"","color":"success","to":"/cars/new"}},[_vm._v(_vm._s(_vm.$t("custom.add_car")))]),_c('v-btn',{staticClass:"mt-2 ml-2",attrs:{"small":"","color":"secondary","to":"/archive_cars"}},[_vm._v(_vm._s(_vm.$t("custom.archive")))]),(
                _vm.cars &&
                _vm.cars.length > 0 &&
                (_vm.user_role == 'superadmin' || _vm.user_role == 'admin')
              )?_c('download-excel',{attrs:{"data":_vm.cars}},[_c('v-icon',{staticClass:"ml-2 mt-3",staticStyle:{"cursor":"pointer"},attrs:{"color":"success"}},[_vm._v(" mdi-file-excel-outline ")])],1):_vm._e()],1)],1)],1)],1),_c('v-data-table',{class:!_vm.isMobile ? '' : 'is-mobile',attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"loading-text":_vm.$t('custom.loading_table'),"items":_vm.cars,"search":_vm.search,"sort-by":"sort","sort-desc":false,"item-key":"id","mobile-breakpoint":"100","dense":"","items-per-page":50,"footer-props":{
        pageText: ("{0} " + (_vm.$t('custom.of')) + " {1}"),
        itemsPerPageText: _vm.$t('custom.elements_table'),
        showFirstLastPage: true,
        itemsPerPageOptions: [20, 50, 100, -1],
      },"id":"registration-step-37"},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
              'column sortable',
              _vm.pagination.descending ? 'desc' : 'asc',
              header.value === _vm.pagination.sortBy ? 'active' : '' ],on:{"click":function($event){return _vm.changeSort(header.value)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("arrow_upward")]),_vm._v(" "+_vm._s(header.text)+" ")],1)}),0)]}},{key:"item",fn:function(props){return [_c('tr',{class:props.item.active ? '' : 'grey--text text--lighten-1',on:{"dblclick":function($event){return _vm.replaceToCar(props.item)}}},[_c('td',{key:props.item.id,staticClass:"text-center"},[_c('router-link',{attrs:{"id":"car_id","to":{ path: ("/cars/" + (props.item.id)) }}},[_vm._v(_vm._s(props.item.id))])],1),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"id":"car_id","to":{ path: ("/cars/" + (props.item.id)) }}},[_vm._v(_vm._s(props.item.car_name))])],1),_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{ path: ("/cars/" + (props.item.id)) }}},[_vm._v(_vm._s(props.item.code))])],1),_c('td',{staticClass:"text-center align-center"},[(props.item.state == 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('states.normal'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('states.normal'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-thumb-up ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateCarState(props.item.id, state.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.state)+" ")])],1)}),1)],1):_vm._e(),(props.item.state == 2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('states.in_service'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('states.in_service'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"secondary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-tools ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateCarState(props.item.id, state.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.state)+" ")])],1)}),1)],1):_vm._e(),(props.item.state == 3)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('states.critical'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('states.critical'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-box ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateCarState(props.item.id, state.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.state)+" ")])],1)}),1)],1):_vm._e(),(props.item.state == 4)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('states.long_rent'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('states.long_rent'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"pink"}},'v-icon',attrs,false),on),[_vm._v(" mdi-all-inclusive ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateCarState(props.item.id, state.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.state)+" ")])],1)}),1)],1):_vm._e(),(props.item.state == 5)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('states.no_rent'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('states.no_rent'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-airplane-off ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateCarState(props.item.id, state.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.state)+" ")])],1)}),1)],1):_vm._e(),(props.item.state == 6)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('states.no_rent'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('states.no_rent'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"orange"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alarm-light ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateCarState(props.item.id, state.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.state)+" ")])],1)}),1)],1):_vm._e()],1),_c('td',{staticClass:"text-center",style:(_vm.user_role == 'guest' || _vm.user_role == 'partner'
                ? 'display: none;'
                : '')},[_vm._v(" "+_vm._s(props.item.number)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.color))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.year))]),_vm._l((_vm.periods),function(period,index){return _c('td',{key:period.index,staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.returnPrice(props.item.id, index))+" ")])}),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.returnPrice(props.item.id, _vm.periods.length))+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.price_hour))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(props.item.deposit))]),_c('td',{staticClass:"text-center",attrs:{"nowrap":""}},[_vm._v(" "+_vm._s(_vm.returnDate(props.item.last_inspection))+" ")]),_c('td',{staticClass:"text-center"},[(props.item.tire_type == 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('wheels.summer'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('wheels.summer'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"yellow"}},'v-icon',attrs,false),on),[_vm._v(" mdi-weather-sunny ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.tire_types),function(tire_type,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateTireType(props.item.id, tire_type.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(tire_type.tire_type)+" ")])],1)}),1)],1):_vm._e(),(props.item.tire_type == 2)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('wheels.winter'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('wheels.winter'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-snowflake ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.tire_types),function(tire_type,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateTireType(props.item.id, tire_type.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(tire_type.tire_type)+" ")])],1)}),1)],1):_vm._e(),(props.item.tire_type == 3)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('wheels.all_seasons'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('wheels.all_seasons'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-theme-light-dark ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.tire_types),function(tire_type,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateTireType(props.item.id, tire_type.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(tire_type.tire_type)+" ")])],1)}),1)],1):_vm._e(),(props.item.tire_type == 4)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('wheels.both'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('wheels.both'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"info"}},'v-icon',attrs,false),on),[_vm._v(" mdi-circle-half-full ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.tire_types),function(tire_type,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateTireType(props.item.id, tire_type.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(tire_type.tire_type)+" ")])],1)}),1)],1):_vm._e(),(props.item.tire_type == 5)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('wheels.spike'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('wheels.spike'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],attrs:{"color":"light-green"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pine-tree ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.tire_types),function(tire_type,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateTireType(props.item.id, tire_type.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(tire_type.tire_type)+" ")])],1)}),1)],1):_vm._e()],1)],2)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("custom.no_data_in_table"))+" ")]),_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v(" "+_vm._s(_vm.$t("tables.no_search_result", { msg: _vm.search }))+" ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }