<template>
  <!-- <v-container v-resize="checkMobile" grid-list-md text-xs-center :style="isMobile ? 'padding: 2px !important;' : ''"> -->
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <v-card>
      <v-card-title>
        <v-flex
          md12
          :class="
            isMobile ? 'align-center text-center' : 'd-inline-flex align-center'
          "
        >
          <v-flex
            md12
            :class="
              isMobile ? 'align-center text-center' : 'd-flex align-center'
            "
          >
            <v-flex>
              <h4
                v-if="isMobile"
                class="text-center mb-2"
                :style="user_role == 'guest' ? 'display: none;' : ''"
              >
                {{ car.car_name }} {{ car.code }}<br />{{ car.number
                }}{{ car.archive ? $t("cars.archive") : " "
                }}{{ car.active ? $t("cars.active") : $t("cars.unactive") }}
              </h4>
              <h2 v-else :style="user_role == 'guest' ? 'display: none;' : ''">
                {{ car.car_name }} {{ car.code }} {{ car.number
                }}{{ car.archive ? $t("cars.archive") : " "
                }}{{ car.active ? $t("cars.active") : $t("cars.unactive") }}
              </h2>
            </v-flex>
            <v-flex class="start-tour-car-3">
              <!-- <v-icon
                :class="isMobile ? '' : 'ml-4'"
                @click="showEdit = true"
                id="registration-step-40"
                size="28"
              >
                mdi-pencil-outline
              </v-icon> -->
              <v-icon class="ml-4 green--text" @click="editSave()">
                mdi-content-save-all-outline
              </v-icon>
              <v-icon
                v-if="!car.archive"
                class="ml-4 red--text"
                @click="repair_dialog = true"
                size="28"
              >
                mdi-car-brake-alert
              </v-icon>
              <v-icon
                v-if="!car.active && checkAdmin()"
                class="ml-4 red--text"
                size="28"
                @click="deleteItem(car)"
                v-tooltip="{
                  content: $t('tooltips.remove_car'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-delete-forever
              </v-icon>
              <v-icon
                v-if="!car.archive"
                class="ml-4 info--text"
                size="28"
                @click="inspection_dialog = true"
                v-tooltip="{
                  content: $t('tooltips.view_car'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-cog-sync-outline
              </v-icon>
              <v-icon
                v-if="!car.archive"
                class="ml-4 secondary--text"
                size="28"
                @click="
                  (store_place_dialog = true), (store_place = car.store_place)
                "
                v-tooltip="{
                  content: $t('tooltips.place_car'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-home-search-outline
              </v-icon>
              <v-icon
                v-if="car.archive"
                class="ml-4 green--text"
                size="28"
                @click="unarchiveCar()"
              >
                mdi-archive-arrow-up
              </v-icon>
              <v-icon
                v-if="!car.active && !car.archive"
                class="ml-4 warning--text"
                size="28"
                @click="archiveCar()"
              >
                mdi-archive-arrow-down
              </v-icon>
              <v-menu offset-y v-if="car.state == 1 || car.state == null">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    size="28"
                    v-tooltip="{
                      content: $t('states.normal'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                  >
                    mdi-thumb-up
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(car.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="car.state == 2">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    size="28"
                    v-tooltip="{
                      content: $t('states.in_service'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                  >
                    mdi-tools
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(car.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="car.state == 3">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    size="28"
                    v-tooltip="{
                      content: $t('states.critical'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="error"
                  >
                    mdi-alert-box
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(car.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="car.state == 4">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    size="28"
                    v-tooltip="{
                      content: $t('states.long_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="pink"
                  >
                    mdi-all-inclusive
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(car.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="car.state == 5">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    size="28"
                    v-tooltip="{
                      content: $t('states.no_rent'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="info"
                  >
                    mdi-airplane-off
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(car.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu offset-y v-if="car.state == 6">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    size="28"
                    v-tooltip="{
                      content: $t('states.service_needed_2'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    v-bind="attrs"
                    v-on="on"
                    color="orange"
                  >
                    mdi-alarm-light
                  </v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(state, index) in states"
                    :key="index"
                    @click="updateCarState(car.id, state.id)"
                  >
                    <v-list-item-title>
                      {{ state.state }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                class="ml-4 green--text"
                size="28"
                @click="sell_dialog = true"
              >
                mdi-cash-multiple
              </v-icon>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!car.active"
              :small="isMobile ? true : false"
              color="success"
              class="ma-2 mt-4 white--text"
              @click="saveCarActive()"
              id="registration-step-471"
              v-tooltip="{
                content: $t('tooltips.activate_car'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 1000,
                },
              }"
            >
              {{ $t("custom.activate") }}
              <v-icon right dark> mdi-alpha-a-circle-outline </v-icon>
            </v-btn>
            <v-btn
              v-else
              color="grey"
              :small="isMobile ? true : false"
              class="ma-2 mt-4 white--text"
              @click="disactivateCar()"
              id="registration-step-471"
              v-tooltip="{
                content: $t('tooltips.deactivate_car'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 1000,
                },
              }"
            >
              {{ $t("custom.deactivate") }}
              <v-icon right dark> mdi-car-off </v-icon>
            </v-btn>
          </v-flex>
        </v-flex>
        <v-flex md12 v-if="car.store_place">
          <h5 class="grey--text text--lighten-1">
            {{ $t("home.location") }}: {{ car.store_place }}
          </h5>
        </v-flex>
      </v-card-title>
      <v-tabs
        light
        v-model="tabs"
        :fixed-tabs="!isMobile"
        :centered="!isMobile"
        class="start-tour-car-2"
      >
        <v-tab>
          {{ $t("custom.bookings") }}
        </v-tab>
        <v-tab>
          {{ $t("custom.characteristics") }}
        </v-tab>
        <v-tab>
          {{ $t("custom.prices") }}
        </v-tab>
        <v-tab>
          {{ $t("custom.service") }}
        </v-tab>
        <v-tab>
          {{ $t("custom.files") }}
        </v-tab>
        <v-tab
          v-if="
            user_role == 'superadmin' ||
            user_role == 'admin' ||
            user_role == 'partner' ||
            user_role == 'guest'
          "
        >
          {{ $t("custom.analitics") }}
        </v-tab>
        <v-tab v-else>
          {{ $t("custom.expences") }}
        </v-tab>
        <v-tab>
          {{ $t("to.to") }}
        </v-tab>
        <v-tab>
          {{ $t("store.store") }}
        </v-tab>
        <v-tab>
          {{ $t("custom.aggregator") }}
        </v-tab>
        <v-tabs-items v-model="tabs" touchless>
          <!-- Брони 0-->
          <v-tab-item>
            <v-card flat>
              <v-card-text :style="isMobile ? 'padding: 2px !important;' : ''">
                <v-container
                  :style="isMobile ? 'padding: 2px !important;' : ''"
                >
                  <v-toolbar
                    text
                    :color="$vuetify.theme.dark ? '' : 'white'"
                    class="start-tour-car-1"
                  >
                    <v-spacer></v-spacer>
                    <v-row align="center" class="ml-4">
                      <v-text-field
                        :dense="isMobile"
                        v-model="search_bookings"
                        prepend-icon="mdi-magnify"
                        :label="$t('custom.search')"
                        single-line
                        clearable
                        hide-details
                      ></v-text-field>
                      <!-- <v-spacer></v-spacer>
                    <v-btn v-if="!isMobile" small color="success" :to="{ name: 'NewBooking' }"
                      >Новая бронь</v-btn
                    > -->
                    </v-row>
                  </v-toolbar>
                  <v-card-text>
                    <v-container fill-height>
                      <v-row justify="center" align="center">
                        <v-col cols="12">
                          <vc-date-picker
                            :value="new Date()"
                            :attributes="calendar_attributes"
                            title-position="left"
                            :columns="$screens({ default: 1, md: 2, lg: 4 })"
                            :is-expanded="true"
                            :locale="$i18n.locale"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-divider class="mt-3 mb-3"></v-divider>

                    <v-data-table
                      :headers="bookings_headers"
                      :items="bookings"
                      :search="search_bookings"
                      :loading="isLoading"
                      :loading-text="$t('custom.loading_table')"
                      sort-by="sort"
                      :sort-desc="true"
                      item-key="id"
                      :items-per-page="10"
                      mobile-breakpoint="100"
                      :class="!isMobile ? '' : 'is-mobile'"
                      dense
                      :footer-props="{
                        pageText: `{0} ${$t('custom.of')} {1}`,
                        itemsPerPageText: $t('custom.elements_table'),
                        showFirstLastPage: true,
                        itemsPerPageOptions: [20, 50, 100, -1],
                      }"
                    >
                      <template slot="item" slot-scope="props">
                        <tr
                          @dblclick="replaceToBooking(props.item)"
                          style="cursor: pointer"
                          :class="
                            !props.item.technical ? '' : 'blue-grey lighten-4'
                          "
                        >
                          <td class="text-center" :key="props.item.id">
                            <router-link
                              :to="{
                                name: 'BookingCard',
                                params: { id: props.item.id },
                              }"
                              >{{ props.item.id }}</router-link
                            >
                          </td>
                          <td class="text-center">
                            {{ returnDate(props.item.created_at) }}
                          </td>
                          <td class="text-center">
                            <v-flex class="d-flex text-center justify-center">
                              <v-icon
                                class="mr-2"
                                v-if="props.item.in_rent"
                                color="primary"
                                v-tooltip="{
                                  content: $t('bookings.in_rent'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                              >
                                mdi-car
                              </v-icon>
                              <v-icon
                                v-if="props.item.active && !props.item.in_rent"
                                class="mr-2"
                                style="z-index: 1"
                                color="secondary"
                                v-tooltip="{
                                  content: $t('bookings.booked'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                              >
                                mdi-clock-outline
                              </v-icon>
                              <v-icon
                                v-if="props.item.failure"
                                v-tooltip="{
                                  content: $t('bookings.decline'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                              >
                                mdi-close-circle-outline
                              </v-icon>
                            </v-flex>
                          </td>
                          <td class="text-center">
                            <v-flex class="d-flex text-center justify-center">
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Новая'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="primary"
                                      v-tooltip="{
                                        content: $t('bookings.new'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-new-box
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Подтверждена'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="success"
                                      v-tooltip="{
                                        content: $t('bookings.approved'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-check-all
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Не подтверждена'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="warning"
                                      v-tooltip="{
                                        content: $t('bookings.not_approved'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-cellphone-basic
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'В обработке'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="primary"
                                      v-tooltip="{
                                        content: $t('bookings.in_work'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-autorenew
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Отказ клиента'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="danger"
                                      v-tooltip="{
                                        content: $t('bookings.declined'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-account-remove-outline
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Нет машин'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="secondary"
                                      v-tooltip="{
                                        content: $t(
                                          'bookings.declined_free_cars'
                                        ),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-car-off
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Недозвон'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    v-bind="attrs"
                                    v-on="on"
                                    class="warning--text"
                                  >
                                    <v-icon
                                      color="warning"
                                      v-tooltip="{
                                        content: $t('bookings.not_called'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-phone-off-outline
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="
                                  props.item.state == 'Ожидает ответа клиента'
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="pink"
                                      v-tooltip="{
                                        content: $t(
                                          'bookings.waiting_for_answer'
                                        ),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-account-question-outline
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Ожидает оплаты'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="lime"
                                      v-tooltip="{
                                        content: $t('bookings.waiting_for_pay'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-currency-usd-off
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Активная'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="light-blue"
                                      v-tooltip="{
                                        content: $t('bookings.active_state'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-alpha-a-circle-outline
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Отмена'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="brown"
                                      v-tooltip="{
                                        content: $t('bookings.canceled_state'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-cancel
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="props.item.state == 'Отъездила'"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="light-green"
                                      v-tooltip="{
                                        content: $t('bookings.ride_true'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-garage-variant
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              <v-menu
                                offset-y
                                v-if="
                                  props.item.state == 'Другое' ||
                                  props.item.state == null
                                "
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <span v-bind="attrs" v-on="on">
                                    <v-icon
                                      color="secondary"
                                      v-tooltip="{
                                        content: $t('bookings.other_state'),
                                        placement: 'bottom-center',
                                        classes: ['info'],
                                        targetClasses: ['it-has-a-tooltip'],
                                        delay: {
                                          show: 500,
                                          hide: 500,
                                        },
                                      }"
                                    >
                                      mdi-head-question-outline
                                    </v-icon>
                                  </span>
                                </template>
                                <v-list>
                                  <v-list-item
                                    v-for="(state, index) in bookings_states"
                                    :key="index"
                                    @click="
                                      saveBookingState(
                                        state.value,
                                        props.item.id
                                      )
                                    "
                                  >
                                    <v-list-item-title>
                                      {{ state.text }}
                                    </v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-flex>
                          </td>
                          <td
                            class="text-center"
                            nowrap
                            v-if="!props.item.entity"
                            :style="
                              user_role == 'guest' ? 'display: none;' : ''
                            "
                          >
                            <router-link
                              :to="{
                                name: 'ClientCard',
                                params: { id: props.item.client_id },
                              }"
                            >
                              {{
                                props.item.last_name ? props.item.last_name : ""
                              }}
                              {{
                                props.item.first_name
                                  ? props.item.first_name[0]
                                  : ""
                              }}.
                              {{
                                props.item.middle_name
                                  ? props.item.middle_name[0]
                                  : ""
                              }}.
                            </router-link>
                          </td>
                          <td
                            class="text-center"
                            v-else
                            :style="
                              user_role == 'guest' ? 'display: none;' : ''
                            "
                          >
                            <router-link
                              :to="{
                                name: 'ClientCard',
                                params: { id: props.item.client_id },
                              }"
                              >{{ props.item.entity_name }}</router-link
                            >
                          </td>
                          <td v-if="!isMobile" nowrap class="text-center">
                            {{ formatDateBookingDates(props.item.start_date) }}
                            <br />
                            {{ formatTimeBookingDates(props.item.start_date) }}
                          </td>
                          <td v-else nowrap class="text-center">
                            {{ shortFormatBookingDates(props.item.start_date) }}
                          </td>
                          <td
                            class="text-center"
                            nowrap
                            v-if="!isMobile && !props.item.active"
                          >
                            {{ formatDateBookingDates(props.item.end_date) }}
                            <br />
                            {{ formatTimeBookingDates(props.item.end_date) }}
                          </td>
                          <td
                            class="text-center"
                            nowrap
                            v-if="isMobile && !props.item.active"
                          >
                            {{ shortFormatBookingDates(props.item.end_date) }}
                          </td>
                          <td
                            class="text-center"
                            nowrap
                            v-if="!isMobile && props.item.active"
                            :style="checkLateDate(props.item.end_date)"
                          >
                            {{ formatDateBookingDates(props.item.end_date) }}
                            <br />
                            {{ formatTimeBookingDates(props.item.end_date) }}
                          </td>
                          <td
                            class="text-center"
                            nowrap
                            v-if="isMobile && props.item.active"
                            :style="checkLateDate(props.item.end_date)"
                          >
                            {{ shortFormatBookingDates(props.item.end_date) }}
                          </td>
                          <td class="text-center">{{ props.item.days }}</td>
                          <td class="text-center">
                            {{ props.item.location_start }}
                          </td>
                          <td class="text-center">
                            {{ props.item.location_end }}
                          </td>
                          <td
                            class="text-truncate"
                            style="max-width: 200px"
                            @click="
                              (showDescriptionEditId = props.item.id),
                                (descriptionEdit = props.item.description),
                                (showDescriptionEditDialog = true)
                            "
                            v-tooltip="{
                              content: `${props.item.description}`,
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            {{ props.item.description }}
                          </td>
                        </tr>
                      </template>
                      <template slot="no-data">
                        {{ $t("custom.no_data_in_table") }}
                      </template>
                      <v-alert
                        slot="no-results"
                        :value="true"
                        color="error"
                        icon="mdi-alert"
                      >
                        {{
                          $t("tables.no_search_result", {
                            msg: search_inspections,
                          })
                        }}
                      </v-alert>
                    </v-data-table>
                    <!-- </v-layout> -->
                  </v-card-text>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Характеристики 1-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-layout row wrap>
                    <v-flex xs12 sm6 md4>
                      <v-autocomplete
                        v-model="car_mark"
                        class="mx-1 mb-2"
                        item-text="name"
                        item-value="id"
                        :loading="isLoadingCarMarks"
                        :search-input="car_mark_search"
                        dense
                        hide-details
                        outlined
                        clearable
                        flat
                        hide-no-data
                        hide-details
                        :label="$t('car_base.select_mark')"
                        :items="car_marks"
                        return-object
                      >
                    </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 md4>
                      <v-autocomplete
                        v-model="car_model"
                        class="mx-1 mb-2"
                        item-text="name"
                        item-value="id"
                        :loading="isLoadingCarModels"
                        :search-input="car_model_search"
                        dense
                        hide-details
                        outlined
                        clearable
                        flat
                        hide-no-data
                        hide-details
                        :label="$t('car_base.select_model')"
                        :items="car_models"
                        return-object
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 md4>
                      <v-autocomplete
                      v-model="car_generation"
                      class="mx-1 mb-2"
                      item-value="id"
                      :loading="isLoadingCarGenerations"
                      :search-input="car_generation_search"
                      dense
                      hide-details
                      clearable
                      outlined
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('car_base.select_generation')"
                      :items="car_generations"
                      return-object
                    >
                      <template slot="selection" slot-scope="data">
                        {{data.item.name ? data.item.name : '' }} {{ data.item.year_start }} - {{ data.item.year_end ? data.item.year_end : $t('car_base.now_time_short') }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{data.item.name ? data.item.name : '' }} {{ data.item.year_start }} - {{ data.item.year_end ? data.item.year_end : $t('car_base.now_time_short') }}
                      </template>
                    </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-autocomplete
                      v-model="car_configuration"
                      class="mx-1 mb-2"
                      item-value="id"
                      :loading="isLoadingCarConfigurations"
                      :search-input="car_configuration_search"
                      dense
                      hide-details
                      outlined
                      clearable
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('car_base.select_configuration')"
                      :items="car_configurations"
                      return-object
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.body_type }} {{ data.item.base_id }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.body_type }} {{ data.item.base_id }}
                      </template>
                    </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-autocomplete
                      v-model="car_modification"
                      class="mx-1 mb-2"
                      item-value="id"
                      :search-input="car_modification_search"
                      dense
                      hide-details
                      outlined
                      clearable
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('car_base.select_modification')"
                      :items="car_modifications"
                      return-object
                    >
                      <template slot="selection" slot-scope="data">
                        {{ data.item.specifications['volume-litres'] }}{{$t('car_base.liters_short')}} {{ data.item.specifications['transmission'] }} {{ data.item.specifications['horse-power'] }}{{$t('car_base.horsepower')}} {{ data.item.specifications['engine-type'] }}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{ data.item.specifications['volume-litres'] }}{{$t('car_base.liters_short')}} {{ data.item.specifications['transmission'] }} {{ data.item.specifications['horse-power'] }}{{$t('car_base.horsepower')}} {{ data.item.specifications['engine-type'] }}
                      </template>
                    </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.trim="car.car_name"
                        class="mx-1 mb-2"
                        :label="$t('cars.name')"
                        dense
                        required
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.trim="car.code"
                        class="mx-1 mb-2"
                        required
                        :label="$t('cars.code')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.sort"
                        class="mx-1 mb-2"
                        :label="$t('cars.sort_number')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.trim="car.number"
                        class="mx-1 mb-2"
                        :label="$t('cars.gov_number')"
                        dense
                        hide-details
                        outlined
                        :style="user_role == 'guest' ? 'display: none;' : ''"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.trim="car.vin"
                        class="mx-1 mb-2"
                        :label="$t('cars.vin')"
                        dense
                        hide-details
                        outlined
                        :style="user_role == 'guest' ? 'display: none;' : ''"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.trim="car.body_number"
                        class="mx-1 mb-2"
                        :label="$t('cars.body_number')"
                        dense
                        hide-details
                        outlined
                        :style="user_role == 'guest' ? 'display: none;' : ''"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.trim="car.pts"
                        class="mx-1 mb-2"
                        :label="$t('cars.reg_number')"
                        dense
                        hide-details
                        outlined
                        :style="user_role == 'guest' ? 'display: none;' : ''"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.trim="car.registration_certificate"
                        class="mx-1 mb-2"
                        :label="$t('cars.sts_number')"
                        dense
                        hide-details
                        outlined
                        :style="user_role == 'guest' ? 'display: none;' : ''"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-select
                        v-model="car.car_type"
                        class="mx-1 mb-2"
                        :items="car_types"
                        item-text="text"
                        item-value="value"
                        dense
                        hide-details
                        :label="$t('cars.body_type')"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-select
                        v-model="car.car_class"
                        class="mx-1 mb-2"
                        :items="car_classes"
                        item-text="text"
                        item-value="value"
                        dense
                        hide-details
                        :label="$t('cars.class')"
                        outlined
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.color"
                        class="mx-1 mb-2"
                        :label="$t('cars.color')"
                        hide-details
                        dense
                        outlined
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.year"
                        class="mx-1 mb-2"
                        :label="$t('cars.year')"
                        type="number"
                        hide-details
                        dense
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-select
                        v-model="car.transmission"
                        class="mx-1 mb-2"
                        :items="gears"
                        dense
                        hide-details
                        item-text="text"
                        item-value="value"
                        :label="$t('cars.gear')"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-select
                        v-model="car.drive_unit"
                        class="mx-1 mb-2"
                        :items="drive_units"
                        dense
                        hide-details
                        item-text="text"
                        item-value="value"
                        :label="$t('cars.drive_unit')"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-select
                        v-model="car.fuel"
                        class="mx-1 mb-2"
                        :items="fuels"
                        dense
                        hide-details
                        item-text="text"
                        item-value="value"
                        :label="$t('cars.fuel')"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.tank_value"
                        class="mx-1 mb-2"
                        :label="$t('cars.tank_value')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.gas_mileage"
                        class="mx-1 mb-2"
                        :label="$t('cars.gas_mileage')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-select
                        v-model="car.steering_side"
                        class="mx-1 mb-2"
                        :items="steering_side"
                        item-text="text"
                        item-value="value"
                        dense
                        hide-details
                        :label="$t('cars.steering_side')"
                        outlined
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.number_doors"
                        class="mx-1 mb-2"
                        :label="$t('cars.number_doors')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.number_seats"
                        class="mx-1 mb-2"
                        :label="$t('cars.number_seats')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.engine_capacity"
                        class="mx-1 mb-2"
                        :label="$t('cars.engine_capacity')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.engine_power"
                        class="mx-1 mb-2"
                        :label="$t('cars.engine_power')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.tire_size"
                        class="mx-1 mb-2"
                        :label="$t('cars.tire_size')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.purchase_date"
                        class="mx-1 mb-2"
                        type="date"
                        :label="$t('cars.purchase_date')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model.number="car.purchase_price"
                        class="mx-1 mb-2"
                        :label="$t('cars.purchase_price')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3 v-if="car.sale_date">
                      <v-text-field
                        v-model="car.sale_date"
                        class="mx-1 mb-2"
                        type="date"
                        :label="$t('cars.sale_date')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3 v-if="car.sale_price">
                      <v-text-field
                        v-model.number="car.sale_price"
                        class="mx-1 mb-2"
                        :label="$t('cars.sale_price')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.franchise"
                        class="mx-1 mb-2"
                        :label="$t('cars.franchise')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.max_fine"
                        class="mx-1 mb-2"
                        :label="$t('cars.max_fine')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.repair_cost"
                        class="mx-1 mb-2"
                        :label="$t('cars.repair_cost')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.beacon_emai"
                        class="mx-1 mb-2"
                        :label="$t('cars.beacon_emai')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.beacon_phone"
                        class="mx-1 mb-2"
                        :label="$t('cars.beacon_phone')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-text-field
                        v-model="car.beacon_pin"
                        class="mx-1 mb-2"
                        :label="$t('cars.beacon_pin')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md3>
                      <v-autocomplete
                        v-model="car.company_id"
                        class="mx-1 mb-2"
                        item-value="id"
                        dense
                        hide-details
                        outlined
                        :label="$t('custom.branche')"
                        :items="branches"
                        :disabled="branches.length > 0 ? false : true"
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.name }} - {{ data.item.city }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.name }} - {{ data.item.city }}
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex col-12> </v-flex>
                    <v-flex xs12 sm6 md2>
                      <v-checkbox
                        v-model="car.is_electropackage"
                        :label="$t('cars.is_electropackage')"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm6 md2>
                      <v-checkbox
                        :label="$t('cars.is_air')"
                        v-model="car.is_air"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex xs12 sm6 md2>
                      <v-checkbox
                        :label="$t('cars.climate_control')"
                        v-model="car.climate_control"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex md12 class="mt-3">
                      <v-textarea
                        v-model="car.description"
                        :label="$t('cars.description')"
                        rows="2"
                        outlined
                      ></v-textarea>
                    </v-flex>
                    <v-flex md12 class="mt-3">
                      <v-expansion-panels v-model="showDopPanel">
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <h2 class="mb-0">
                              {{ $t("cars.extra_characteristics") }}
                            </h2>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-container>
                              <v-layout row wrap>
                                <v-flex xs12 sm6 md3>
                                  <v-select
                                    v-model="car.interior"
                                    class="mx-1 mb-2"
                                    :items="interiors"
                                    dense
                                    hide-details
                                    item-text="text"
                                    item-value="value"
                                    :label="$t('cars.interior')"
                                    outlined
                                  ></v-select>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-select
                                    v-model="car.roof"
                                    class="mx-1 mb-2"
                                    :items="roofs"
                                    item-text="text"
                                    item-value="value"
                                    dense
                                    hide-details
                                    :label="$t('cars.roof')"
                                    outlined
                                  ></v-select>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-text-field
                                    v-model.number="car.trunk_volume"
                                    class="mx-1 mb-2"
                                    :label="$t('cars.trunk_volume')"
                                    dense
                                    hide-details
                                    outlined
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-text-field
                                    v-model.number="car.airbags"
                                    class="mx-1 mb-2"
                                    :label="$t('cars.airbags')"
                                    dense
                                    hide-details
                                    outlined
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-text-field
                                    v-model.number="car.window_lifters"
                                    class="mx-1 mb-2"
                                    :label="$t('cars.window_lifters')"
                                    dense
                                    hide-details
                                    outlined
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-text-field
                                    v-model.number="car.custom_field_1"
                                    class="mx-1 mb-2"
                                    :label="$t('cars.custom_field_1')"
                                    dense
                                    hide-details
                                    outlined
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-text-field
                                    v-model.number="car.custom_field_2"
                                    class="mx-1 mb-2"
                                    :label="$t('cars.custom_field_2')"
                                    dense
                                    hide-details
                                    outlined
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 md3>
                                  <v-text-field
                                    v-model.number="car.custom_field_3"
                                    class="mx-1 mb-2"
                                    :label="$t('cars.custom_field_3')"
                                    dense
                                    hide-details
                                    outlined
                                  ></v-text-field>
                                </v-flex>
                                <v-flex col-12> </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.rain_sensor"
                                    :label="$t('cars.rain_sensor')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.heated_windshield"
                                    :label="$t('cars.heated_windshield')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.folding_seats"
                                    :label="$t('cars.folding_seats')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.video_system"
                                    :label="$t('cars.video_system')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.tv_system"
                                    :label="$t('cars.tv_system')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.cd_system"
                                    :label="$t('cars.cd_system')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.usb_system"
                                    :label="$t('cars.usb_system')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.audio_system"
                                    :label="$t('cars.audio_system')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.wheel_adjustment"
                                    :label="$t('cars.wheel_adjustment')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.wheel_adjustment_full"
                                    :label="$t('cars.wheel_adjustment_full')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.parktronic_camera"
                                    :label="$t('cars.parktronic_camera')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.parktronic_back"
                                    :label="$t('cars.parktronic_back')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.parktronic"
                                    :label="$t('cars.parktronic')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.heated_seats_front"
                                    :label="$t('cars.heated_seats_front')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.heated_seats"
                                    :label="$t('cars.heated_seats')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    v-model="car.abs"
                                    :label="$t('cars.abs')"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    :label="$t('cars.ebd')"
                                    v-model="car.ebd"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                                <v-flex xs12 sm6 md2>
                                  <v-checkbox
                                    :label="$t('cars.esp')"
                                    v-model="car.esp"
                                    dense
                                    hide-details
                                  ></v-checkbox>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="editSave()"
                  >{{ $t("custom.save")
                  }}<v-icon class="ml-1">
                    mdi-content-save-check-outline
                  </v-icon></v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <!-- Цены 2-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-layout row wrap class="mt-2">
                    <v-flex md12>
                      <v-simple-table>
                        <thead>
                          <tr>
                            <th>{{ $t("cars.season") }}</th>
                            <th
                              v-for="period in periods"
                              :key="period.index"
                              class="text-left"
                            >
                              {{ period }} {{ $t("bookings.days_low") }}
                            </th>
                            <th>
                              +
                              {{
                                periods[periods.length - 1]
                                  ? periods[periods.length - 1].split(" - ")[1]
                                  : ""
                              }}
                              {{ $t("bookings.days_low") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(price, index) in car.prices"
                            :key="price.index"
                            id="registration-step-43"
                          >
                            <td v-if="price.id" class="text-no-wrap">
                              <v-text-field
                                readonly
                                dense
                                :value="getSeasonValue(price.season_id)"
                                :label="$t('cars.select_season')"
                                hide-details
                              ></v-text-field>
                            </td>
                            <td
                              v-else
                              class="text-no-wrap"
                              id="registration-step-42"
                            >
                              <v-select
                                :items="select_seasons"
                                item-text="value"
                                item-value="season_id"
                                :label="$t('cars.select_season')"
                                v-model="createPricesInput.season_id"
                                hide-details
                                dense
                              ></v-select>
                            </td>
                            <td
                              v-for="(value, value_index) in car.prices[index]
                                .values"
                              :key="value.index"
                            >
                              <v-text-field
                                v-if="value > 0"
                                readonly
                                dense
                                type="number"
                                :value="value"
                                hide-details
                                @click="showNewPrice(price, value_index)"
                              ></v-text-field>
                              <v-text-field
                                v-else
                                v-model.number="createPricesInput[value_index]"
                                type="number"
                                :value="value"
                                dense
                                hide-details
                              ></v-text-field>
                            </td>
                            <td>
                              <v-icon
                                class=""
                                @click="removeSeasonPrices(price)"
                              >
                                mdi-close
                              </v-icon>
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                      <v-flex
                        v-if="car && car.prices.length <= seasons.length + 1"
                        md12
                        class="text-center"
                        id="registration-step-41"
                      >
                        <v-btn
                          v-if="showAddPriceBtn"
                          color="info"
                          class="my-2"
                          @click="addPrice()"
                          >{{ $t("custom.add") }}
                          <v-icon class="ml-1">
                            mdi-plus-circle-outline
                          </v-icon>
                        </v-btn>
                        <v-btn
                          v-if="createPricesInput.hasOwnProperty('season_id')"
                          :disabled="checkPriceInfo()"
                          color="warning"
                          @click="saveNewPrices()"
                          class="mt-2 my-2"
                          >{{ $t("cars.save_prices")
                          }}<v-icon class="ml-1">
                            mdi-content-save-check-outline
                          </v-icon>
                        </v-btn>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-divider class="mt-2 mb-4"></v-divider>
                    </v-flex>
                    <v-flex xs12 sm6 md6 id="registration-step-45">
                      <v-text-field
                        v-model.number="car.price_hour"
                        :label="$t('cars.price_for_hour')"
                        class="mr-2 my-2"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6 id="registration-step-46">
                      <v-text-field
                        v-model.number="car.deposit"
                        :label="$t('cars.deposit')"
                        class="my-2"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model.number="car.extra_mileage_km"
                        :label="$t('cars.extra_mileage_km')"
                        class="mr-2 my-2"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model.number="car.extra_mileage_price"
                        :label="$t('cars.extra_mileage_price')"
                        class="my-2"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model.number="car.age_limit"
                        :label="$t('cars.age_limit')"
                        class="mr-2 my-2"
                        dense
                        hide-details
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model.number="car.driver_year_limit"
                        :label="$t('cars.driver_year_limit')"
                        class="my-2"
                        dense
                        hide-details
                        outlined
                        type="number"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="registration-step-47"
                  color="success"
                  @click="editSave()"
                  >{{ $t("custom.save")
                  }}<v-icon class="ml-1">
                    mdi-content-save-check-outline
                  </v-icon></v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <!-- Обслуживание 3-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-card-text>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          v-model.number="car.start_mileage"
                          class="mx-1 mb-2"
                          :label="$t('cars.start_mileage')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          v-model.number="car.mileage"
                          class="mx-1 mb-2"
                          :label="$t('cars.mileage')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          v-model="car.insurance"
                          class="mx-1 mb-2"
                          :label="$t('cars.insurance')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm6 md6>
                        <v-text-field
                          v-model="car.diagnostic_card"
                          class="mx-1 mb-2"
                          :label="$t('cars.diagnostic_card')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.oil_engine"
                          class="mx-1 mb-2"
                          :label="$t('cars.oil_engine')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.oil_engine_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.oil_engine_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.oil_engine_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.oil_transmission"
                          class="mx-1 mb-2"
                          :label="$t('cars.oil_transmission')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.oil_transmission_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.oil_transmission_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.oil_transmission_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.grm"
                          class="mx-1 mb-2"
                          :label="$t('cars.grm')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.grm_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.grm_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.grm_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.antifreeze"
                          class="mx-1 mb-2"
                          :label="$t('cars.antifreeze')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.antifreeze_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.antifreeze_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.antifreeze_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.brake_fluid"
                          class="mx-1 mb-2"
                          :label="$t('cars.brake_fluid')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.brake_fluid_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.brake_fluid_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.brake_fluid_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.candle"
                          class="mx-1 mb-2"
                          :label="$t('cars.candle')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.candle_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.candle_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.candle_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.power_steering"
                          class="mx-1 mb-2"
                          :label="$t('cars.power_steering')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.power_steering_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.power_steering_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.power_steering_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.salon_filter_renew"
                          class="mx-1 mb-2"
                          :label="$t('cars.salon_filter_renew')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.salon_filter_renew_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.salon_filter_renew_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.salon_filter_renew_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.akb_renew"
                          class="mx-1 mb-2"
                          :label="$t('cars.akb_renew')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.akb_renew_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.akb_renew_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.akb_renew_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.fuel_filter_renew"
                          class="mx-1 mb-2"
                          :label="$t('cars.fuel_filter_renew')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model.number="car.fuel_filter_renew_km"
                          class="mx-1 mb-2"
                          :label="$t('cars.fuel_filter_renew_km')"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm4 md4>
                        <v-text-field
                          v-model="car.fuel_filter_renew_date"
                          class="mx-1 mb-2"
                          :label="$t('cars.date_km')"
                          type="date"
                          dense
                          hide-details
                          outlined
                        ></v-text-field>
                      </v-flex>
                      <v-divider></v-divider>
                    </v-layout>
                  </v-card-text>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="editSave()"
                  >{{ $t("custom.save")
                  }}<v-icon class="ml-1">
                    mdi-content-save-check-outline
                  </v-icon></v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <!-- Файлы 4-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container
                  :style="user_role == 'guest' ? 'display: none;' : ''"
                >
                  <v-card-text>
                    <v-layout
                      row
                      wrap
                      centered
                      class="d-flex text-center justify-center"
                    >
                      <v-btn
                        small
                        v-if="cars_doc_template_id"
                        text
                        :href="cars_doc_template_url"
                        target="_blank"
                      >
                        {{ $t("other.download_car_doc_fiz") }}
                      </v-btn>
                      <v-icon
                        v-if="cars_doc_template_id"
                        @click="deleteCarDoc(cars_doc_template_id)"
                        color="red darken-2"
                        v-tooltip="{
                          content: $t('tooltips.remove_car_doc_fiz'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                        >mdi-delete-forever</v-icon
                      >
                      <h4 v-else>
                        {{ $t("cars.no_uniq_doc_fiz") }}
                      </h4>
                    </v-layout>
                    <v-layout
                      row
                      wrap
                      centered
                      class="d-flex text-center justify-center"
                    >
                      <v-btn
                        small
                        v-if="cars_entity_doc_template_id"
                        text
                        :href="cars_entity_doc_template_url"
                        target="_blank"
                      >
                        {{ $t("cars.download_uniq_doc_ur") }}
                      </v-btn>
                      <v-icon
                        v-if="cars_entity_doc_template_id"
                        @click="deleteCarEntityDoc(cars_entity_doc_template_id)"
                        color="red darken-2"
                        v-tooltip="{
                          content: this.$t('tooltips.remove_car_doc_ur'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                        >mdi-delete-forever</v-icon
                      >
                      <h4 v-else>
                        {{ $t("cars.no_uniq_doc_ur") }}
                      </h4>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex xs12 sm12 md12 class="my-2">
                        <gallery></gallery>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-text v-if="!uploadPhotos">
                    <v-layout
                      row
                      wrap
                      centered
                      class="d-flex text-center justify-center"
                    >
                      <v-flex xs12 sm12 md12 class="d-flex justify-center">
                        <v-checkbox
                          :label="$t('custom.upload_files')"
                          v-model="uploadPhotos"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                  <v-card-text v-if="uploadPhotos">
                    <v-layout
                      row
                      wrap
                      centered
                      class="d-flex text-center justify-center"
                    >
                      <h3 mb-3>
                        {{ $t("cars.upload_uniq_doc_fiz") }}
                      </h3>
                      <v-flex
                        xs12
                        sm12
                        md12
                        class="d-flex text-center justify-center"
                      >
                        <uppy-cars-doc
                          :max-file-size-in-bytes="10000000"
                          :width="isMobile ? 300 : 750"
                          :height="isMobile ? 250 : 250"
                          :theme="$vuetify.theme.dark ? 'dark' : 'light'"
                        ></uppy-cars-doc>
                      </v-flex>
                      <h3 mb-3>
                        {{ $t("cars.upload_uniq_doc_ur") }}
                      </h3>
                      <v-flex
                        xs12
                        sm12
                        md12
                        class="d-flex text-center justify-center"
                      >
                        <uppy-cars-entity-doc
                          :max-file-size-in-bytes="10000000"
                          :width="isMobile ? 300 : 750"
                          :height="isMobile ? 250 : 250"
                          :theme="$vuetify.theme.dark ? 'dark' : 'light'"
                        ></uppy-cars-entity-doc>
                      </v-flex>
                      <h3 mb-3 mt-2>{{ $t("custom.uploading_files") }}</h3>
                      <v-flex xs12 sm12 md12 class="d-flex justify-center">
                        <uppy
                          :max-file-size-in-bytes="10000000"
                          :width="isMobile ? 300 : 750"
                          :height="isMobile ? 250 : 250"
                          :theme="$vuetify.theme.dark ? 'dark' : 'light'"
                        ></uppy>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Аналитика 5-->
          <v-tab-item
            v-if="
              user_role == 'superadmin' ||
              user_role == 'admin' ||
              user_role == 'partner' ||
              user_role == 'guest'
            "
          >
            <v-card flat>
              <v-toolbar
                v-if="!isMobile"
                text
                :color="$vuetify.theme.dark ? '' : 'white'"
              >
                <v-spacer></v-spacer>
                <v-menu
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="start_date_format"
                      :label="$t('custom.start_period')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="mt-5"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    :locale="$i18n.locale"
                    @input="start_date_menu = false"
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="end_date_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="end_date_format"
                      :label="$t('custom.end_period')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="mt-5 ml-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    :locale="$i18n.locale"
                    @input="end_date_menu = false"
                    first-day-of-week="1"
                  ></v-date-picker>
                </v-menu>
                <v-icon
                  class="text-left"
                  v-if="start_date || end_date"
                  @click="
                    (start_date = null),
                      (end_date = null),
                      (start_date_format = null),
                      (end_date_format = null),
                      getAnaliticsDataForRange()
                  "
                >
                  mdi-close
                </v-icon>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-layout row wrap>
                    <v-flex md12>
                      <h3>{{ $t("custom.income_expences") }}</h3>
                      <GChart
                        style="width: 100%; min-height: 300px"
                        type="PieChart"
                        :data="income_expences_chart_data"
                      />
                    </v-flex>
                    <v-flex
                      md12
                      v-if="analitics_data.loading_percentage > 0"
                      class="mb-5"
                    >
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                {{ $t("custom.item") }}
                              </th>
                              <th class="text-left">
                                {{ $t("custom.value") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-left">
                                {{ $t("analitics.profit") }}
                              </td>
                              <td class="text-left">
                                {{ analitics_data.profit }}{{ currency }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.average_profit") }}</td>
                              <td>
                                {{ analitics_data.average_profit
                                }}{{ currency }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {{ $t("analitics.average_month_profit") }}
                              </td>
                              <td v-if="analitics_data.work_days > 0">
                                {{
                                  parseInt(
                                    analitics_data.average_profit /
                                      (analitics_data.work_days / 30)
                                  )
                                }}
                                {{ currency }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.investment_100k") }}</td>
                              <td
                                v-if="
                                  analitics_data.work_days > 0 &&
                                  car.purchase_price > 0
                                "
                              >
                                {{
                                  parseInt(
                                    (analitics_data.average_profit /
                                      (analitics_data.work_days / 30) /
                                      car.purchase_price) *
                                      100000
                                  )
                                }}
                                {{ currency }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.work_days") }}</td>
                              <td>{{ analitics_data.work_days }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.days_in_rent") }}</td>
                              <td>{{ analitics_data.bookings_days }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.percent_loading") }}</td>
                              <td>{{ analitics_data.loading_percentage }}%</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.bookings_mileage") }}</td>
                              <td>
                                {{ analitics_data.bookings_mileage }}
                                {{ $t("custom.km") }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {{ $t("analitics.average_month_mileage") }}
                              </td>
                              <td v-if="analitics_data.work_days > 0">
                                {{
                                  parseInt(
                                    analitics_data.bookings_mileage /
                                      (analitics_data.work_days / 30)
                                  )
                                }}
                                {{ $t("custom.km") }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.km_cost") }}</td>
                              <td
                                v-if="
                                  analitics_data.work_days > 0 &&
                                  analitics_data.bookings_mileage > 0
                                "
                              >
                                {{
                                  Math.floor(
                                    analitics_data.average_profit /
                                      (analitics_data.work_days / 30) /
                                      analitics_data.bookings_mileage
                                  )
                                }}
                                {{ currency }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.km_income") }}</td>
                              <td v-if="analitics_data.bookings_mileage > 0">
                                {{
                                  Math.floor(
                                    analitics_data.average_profit /
                                      analitics_data.bookings_mileage
                                  )
                                }}
                                {{ currency }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.average_count") }}</td>
                              <td>
                                {{
                                  Math.floor(
                                    analitics_data.bookings_average_cost
                                  )
                                }}
                                {{ currency }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.with_car") }}</td>
                              <td
                                v-if="
                                  car.purchase_price > 0 && car.sale_price > 0
                                "
                              >
                                {{
                                  analitics_data.profit -
                                  car.purchase_price +
                                  car.sale_price
                                }}
                                {{ currency }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                            <tr>
                              <td>{{ $t("analitics.with_car_km") }}</td>
                              <td
                                v-if="
                                  car.purchase_price > 0 &&
                                  car.sale_price > 0 &&
                                  analitics_data.bookings_mileage > 0
                                "
                              >
                                {{
                                  (analitics_data.profit -
                                    car.purchase_price +
                                    car.sale_price) /
                                  analitics_data.bookings_mileage
                                }}
                                {{ currency }}
                              </td>
                              <td v-else>-</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h2>
                            {{ $t("custom.income") }}
                            <span
                              v-if="
                                analitics_data.income_rub &&
                                analitics_data.income_rub > 0
                              "
                              >: {{ Math.floor(analitics_data.income_rub)
                              }}{{ currency }}</span
                            >
                          </h2>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-flex md12>
                            <v-card>
                              <v-toolbar
                                text
                                :color="$vuetify.theme.dark ? '' : 'white'"
                              >
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="search_income"
                                  append-icon="mdi-magnify"
                                  :label="$t('custom.search')"
                                  single-line
                                  clearable
                                  hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn small color="success" to="/counts/new">{{
                                  $t("counts.add_count")
                                }}</v-btn>
                              </v-toolbar>
                              <v-data-table
                                :headers="headers_counts"
                                :items="analitics_data.income_counts"
                                :loading="isLoading"
                                :loading-text="$t('custom.loading_table')"
                                :search="search_income"
                                :items-per-page="20"
                                :sort-by="['created_at']"
                                :sort-desc="[true]"
                                :class="!isMobile ? '' : 'is-mobile'"
                                :footer-props="{
                                  pageText: `{0} ${$t('custom.of')} {1}`,
                                  itemsPerPageText: $t('custom.elements_table'),
                                  showFirstLastPage: true,
                                  itemsPerPageOptions: [20, 50, 100, -1],
                                }"
                                mobile-breakpoint="100"
                                dense
                              >
                                <template slot="item" slot-scope="props">
                                  <tr
                                    :class="
                                      props.item.operation && props.item.sum < 0
                                        ? 'red--text'
                                        : ''
                                    "
                                  >
                                    <td class="text-center">
                                      {{ props.item.id }}
                                    </td>
                                    <td class="text-center">
                                      {{ returnDate(props.item.created_at) }}
                                    </td>
                                    <td class="text-center">
                                      {{ props.item.group }}
                                    </td>
                                    <td class="text-center">
                                      {{ props.item.description }}
                                    </td>
                                    <td class="text-center">
                                      {{ props.item.sum }}{{ currency }}
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.operation"
                                    >
                                      <v-icon
                                        class="text-center"
                                        style="color: green"
                                      >
                                        mdi-plus-circle-outline
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="!props.item.operation"
                                    >
                                      <v-icon
                                        class="text-center"
                                        style="color: tomato"
                                      >
                                        mdi-minus-circle-outline
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.cash"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="success"
                                      >
                                        mdi-cash
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.cashless"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="warning"
                                      >
                                        mdi-contactless-payment-circle-outline
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.entity"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="secondery"
                                      >
                                        mdi-bank
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.cash_card"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="success"
                                      >
                                        mdi-credit-card-check-outline
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                                <template slot="no-data">
                                  {{ $t("custom.no_data_in_table") }}
                                </template>
                                <v-alert
                                  slot="no-results"
                                  :value="true"
                                  color="error"
                                  icon="mdi-alert"
                                >
                                  {{
                                    $t("tables.no_search_result", {
                                      msg: search_income,
                                    })
                                  }}
                                </v-alert>
                              </v-data-table>
                            </v-card>
                          </v-flex>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h2>
                            {{ $t("custom.expences") }}
                            <span
                              v-if="
                                analitics_data.expences_rub &&
                                analitics_data.expences_rub > 0
                              "
                              >: {{ Math.floor(analitics_data.expences_rub)
                              }}{{ currency }}</span
                            >
                          </h2>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-flex md12>
                            <v-card>
                              <v-toolbar
                                text
                                :color="$vuetify.theme.dark ? '' : 'white'"
                              >
                                <v-spacer></v-spacer>
                                <v-text-field
                                  v-model="search_expences"
                                  append-icon="mdi-magnify"
                                  :label="$t('custom.search')"
                                  single-line
                                  clearable
                                  hide-details
                                ></v-text-field>
                                <v-spacer></v-spacer>
                                <v-btn small color="success" to="/counts/new">{{
                                  $t("counts.add_count")
                                }}</v-btn>
                              </v-toolbar>
                              <v-data-table
                                :headers="headers_counts"
                                :items="analitics_data.expences_counts"
                                :loading="isLoading"
                                :loading-text="$t('custom.loading_table')"
                                :search="search_expences"
                                :items-per-page="20"
                                :sort-by="['created_at']"
                                :sort-desc="[true]"
                                :class="!isMobile ? '' : 'is-mobile'"
                                :footer-props="{
                                  pageText: `{0} ${$t('custom.of')} {1}`,
                                  itemsPerPageText: $t('custom.elements_table'),
                                  showFirstLastPage: true,
                                  itemsPerPageOptions: [20, 50, 100, -1],
                                }"
                                mobile-breakpoint="100"
                                dense
                              >
                                <template slot="item" slot-scope="props">
                                  <tr
                                    :class="
                                      props.item.operation && props.item.sum < 0
                                        ? 'red--text'
                                        : ''
                                    "
                                  >
                                    <td class="text-center">
                                      {{ props.item.id }}
                                    </td>
                                    <td class="text-center">
                                      {{ returnDate(props.item.created_at) }}
                                    </td>
                                    <td class="text-center">
                                      {{ props.item.group }}
                                    </td>
                                    <td class="text-center">
                                      {{ props.item.description }}
                                    </td>
                                    <td class="text-center">
                                      {{ props.item.sum }}{{ currency }}
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.operation"
                                    >
                                      <v-icon
                                        class="text-center"
                                        style="color: green"
                                      >
                                        mdi-plus-circle-outline
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="!props.item.operation"
                                    >
                                      <v-icon
                                        class="text-center"
                                        style="color: tomato"
                                      >
                                        mdi-minus-circle-outline
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.cash"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="success"
                                      >
                                        mdi-cash
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.cashless"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="warning"
                                      >
                                        mdi-contactless-payment-circle-outline
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.entity"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="secondery"
                                      >
                                        mdi-bank
                                      </v-icon>
                                    </td>
                                    <td
                                      class="text-center"
                                      v-if="props.item.cash_card"
                                    >
                                      <v-icon
                                        class="text-center"
                                        color="success"
                                      >
                                        mdi-credit-card-check-outline
                                      </v-icon>
                                    </td>
                                  </tr>
                                </template>
                                <template slot="no-data">
                                  {{ $t("custom.no_data_in_table") }}
                                </template>
                                <v-alert
                                  slot="no-results"
                                  :value="true"
                                  color="error"
                                  icon="mdi-alert"
                                >
                                  {{
                                    $t("tables.no_search_result", {
                                      msg: search_expences,
                                    })
                                  }}
                                </v-alert>
                              </v-data-table>
                            </v-card>
                          </v-flex>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h2>{{ $t("counts.charts") }}</h2>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-flex md12 class="d-inline-flex align-center">
                            <v-select
                              v-model="selected_chart_type"
                              clearable
                              :items="chart_types"
                              :label="$t('counts.chart_type')"
                            ></v-select>
                            <v-spacer></v-spacer>
                            <v-select
                              v-model="selected_group"
                              clearable
                              :items="counts_groups"
                              item-text="text"
                              item-value="value"
                              multiple
                              :label="$t('counts.groups')"
                            ></v-select>
                            <v-btn
                              small
                              text
                              @click="
                                selected_group = [
                                  'Зарплата',
                                  'Залог',
                                  'Ремонт',
                                  'Обслуживание',
                                  'Реклама',
                                  'Внутренние переводы',
                                  'Оплата аренды',
                                  'Запчасти',
                                  'Страховка',
                                  'Накладные расходы',
                                  'Доплата за доставку',
                                  'Доплата за приём',
                                  'Доплата за топливо',
                                  'Доплата за мойку',
                                  'Транспортные расходы',
                                  'Доплата за дополнительное время',
                                  'Доплата за другое',
                                  'Мойка автомобилей',
                                  'Топливо',
                                  'Прочие доходы',
                                  'Доплата за страховку',
                                  'Доплата за доп водителей',
                                  'Доплата за оборудование',
                                  'Покупка имущества',
                                  'Доплата за повреждения',
                                  'Доплата за штрафы',
                                  'Аренда имущества',
                                  'Налоги',
                                  'Выплаты партнёрам',
                                  'Штрафы',
                                  'Продажа имущества',
                                  'Прочие расходы',
                                  'Доплата за превышение пробега',
                                ]
                              "
                              >{{ $t("counts.select_all_groups") }}</v-btn
                            >
                          </v-flex>
                          <v-flex md12>
                            <h3>{{ $t("counts.common") }}</h3>
                            <GChart
                              style="width: 100%; min-height: 300px"
                              :type="selected_chart_type"
                              :data="revenue_for_period_data"
                            />
                          </v-flex>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-else>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-card-text>
                    <v-flex md12>
                      <v-card>
                        <v-toolbar
                          text
                          :color="$vuetify.theme.dark ? '' : 'white'"
                        >
                          <v-spacer></v-spacer>
                          <v-text-field
                            v-model="search_expences"
                            append-icon="mdi-magnify"
                            :label="$t('custom.search')"
                            single-line
                            clearable
                            hide-details
                          ></v-text-field>
                          <v-spacer></v-spacer>
                          <v-btn small color="success" to="/counts/new">{{
                            $t("counts.add_count")
                          }}</v-btn>
                        </v-toolbar>
                        <v-data-table
                          :headers="headers_counts"
                          :items="expences_counts"
                          :search="search_expences"
                          :loading="isLoading"
                          :loading-text="$t('custom.loading_table')"
                          :items-per-page="20"
                          :sort-by="['created_at']"
                          :sort-desc="[true]"
                          :class="!isMobile ? '' : 'is-mobile'"
                          :footer-props="{
                            pageText: `{0} ${$t('custom.of')} {1}`,
                            itemsPerPageText: $t('custom.elements_table'),
                            showFirstLastPage: true,
                            itemsPerPageOptions: [20, 50, 100, -1],
                          }"
                          mobile-breakpoint="100"
                          dense
                        >
                          <template slot="item" slot-scope="props">
                            <tr
                              :class="
                                props.item.operation && props.item.sum < 0
                                  ? 'red--text'
                                  : ''
                              "
                            >
                              <td class="text-center">{{ props.item.id }}</td>
                              <td class="text-center">
                                {{ returnDate(props.item.created_at) }}
                              </td>
                              <td class="text-center">
                                {{ props.item.group }}
                              </td>
                              <td class="text-center">
                                {{ props.item.description }}
                              </td>
                              <td class="text-center">
                                {{ props.item.sum }}{{ currency }}
                              </td>
                              <td
                                class="text-center"
                                v-if="props.item.operation"
                              >
                                <v-icon
                                  class="text-center"
                                  style="color: green"
                                >
                                  mdi-plus-circle-outline
                                </v-icon>
                              </td>
                              <td
                                class="text-center"
                                v-if="!props.item.operation"
                              >
                                <v-icon
                                  class="text-center"
                                  style="color: tomato"
                                >
                                  mdi-minus-circle-outline
                                </v-icon>
                              </td>
                              <td class="text-center" v-if="props.item.cash">
                                <v-icon class="text-center" color="success">
                                  mdi-cash
                                </v-icon>
                              </td>
                              <td
                                class="text-center"
                                v-if="props.item.cashless"
                              >
                                <v-icon class="text-center" color="warning">
                                  mdi-contactless-payment-circle-outline
                                </v-icon>
                              </td>
                              <td class="text-center" v-if="props.item.entity">
                                <v-icon class="text-center" color="secondery">
                                  mdi-bank
                                </v-icon>
                              </td>
                              <td
                                class="text-center"
                                v-if="props.item.cash_card"
                              >
                                <v-icon class="text-center" color="success">
                                  mdi-credit-card-check-outline
                                </v-icon>
                              </td>
                            </tr>
                          </template>
                          <template slot="no-data">
                            {{ $t("custom.no_data_in_table") }}
                          </template>
                          <v-alert
                            slot="no-results"
                            :value="true"
                            color="error"
                            icon="mdi-alert"
                          >
                            {{
                              $t("tables.no_search_result", {
                                msg: search_expences,
                              })
                            }}
                          </v-alert>
                        </v-data-table>
                      </v-card>
                    </v-flex>
                  </v-card-text>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- ТО 6-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-card-text>
                    <v-layout justify-center>
                      <v-data-table
                        :headers="inspections_headers"
                        :items="inspections"
                        :search="search_inspections"
                        :loading="isLoading"
                        :loading-text="$t('custom.loading_table')"
                        sort-by="id"
                        :sort-desc="true"
                        item-key="id"
                        :items-per-page="10"
                        mobile-breakpoint="100"
                        :class="!isMobile ? '' : 'is-mobile'"
                        dense
                        :footer-props="{
                          pageText: `{0} ${$t('custom.of')} {1}`,
                          itemsPerPageText: $t('custom.elements_table'),
                          showFirstLastPage: true,
                          itemsPerPageOptions: [20, 50, 100, -1],
                        }"
                      >
                        <template slot="item" slot-scope="props">
                          <tr>
                            <td class="text-center" :key="props.item.id" nowrap>
                              {{ returnDate(props.item.date) }}
                            </td>
                            <td class="text-center">
                              {{ setWorker(props.item.worker) }}
                            </td>
                            <td class="text-center">
                              {{ props.item.description }}
                            </td>
                          </tr>
                        </template>
                        <template slot="no-data">
                          {{ $t("custom.no_data_in_table") }}
                        </template>
                        <v-alert
                          slot="no-results"
                          :value="true"
                          color="error"
                          icon="mdi-alert"
                        >
                          {{
                            $t("tables.no_search_result", {
                              msg: search_inspections,
                            })
                          }}
                        </v-alert>
                      </v-data-table>
                    </v-layout>
                    <gallery-checkin v-if="car" :car_id="car.id"></gallery-checkin>
                  </v-card-text>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Склад 7-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-toolbar flat>
                  <v-toolbar-title>{{ $t("store.names") }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-flex
                      md12
                      :class="
                        isMobile
                          ? 'd-flex align-center justify-space-between '
                          : 'd-flex align-center justify-space-start'
                      "
                    >
                      <!-- <v-switch
                        v-model="show_only_in_stock"
                        :class="isMobile ? 'mt-0 ' : 'mt-0 ml-3'"
                        :label="$t('store.in_stock')"
                        dense
                        hide-details
                      ></v-switch> -->
                      <v-text-field
                        v-model="search_store_products"
                        append-icon="mdi-magnify"
                        :label="$t('custom.search')"
                        class="mx-2"
                        single-line
                        clearable
                        hide-details
                      ></v-text-field>
                      <v-autocomplete
                        v-model="filter_store_product_group"
                        hide-no-data
                        hide-details
                        :label="$t('store.group_filter')"
                        :items="products_groups"
                        clearable
                      >
                      </v-autocomplete>
                    </v-flex>
                  </v-toolbar-items>
                </v-toolbar>
                <v-data-table
                  :headers="store_products_headers"
                  :items="filter_products_groups()"
                  :search="search_store_products"
                  :loading="isLoading"
                  :loading-text="$t('custom.loading_table')"
                  sort-by="id"
                  :sort-desc="true"
                  item-key="id"
                  :items-per-page="10"
                  mobile-breakpoint="100"
                  :class="!isMobile ? '' : 'is-mobile'"
                  dense
                  :footer-props="{
                    pageText: `{0} ${$t('custom.of')} {1}`,
                    itemsPerPageText: $t('custom.elements_table'),
                    showFirstLastPage: true,
                    itemsPerPageOptions: [20, 50, 100, -1],
                  }"
                >
                  <template slot="item" slot-scope="props">
                    <tr
                      :class="
                        props.item.stock_balance > 0
                          ? ''
                          : 'grey--text lighten-5'
                      "
                    >
                      <td class="text-center" :key="props.item.id">
                        {{ props.item.id }}
                      </td>
                      <td class="text-center">
                        {{ returnDate(props.item.created_at) }}
                      </td>
                      <td class="text-center">
                        {{ props.item.name }}
                      </td>
                      <td class="text-center">
                        {{ props.item.group }}
                      </td>
                      <td class="text-center">
                        {{ props.item.stock_balance }}
                      </td>
                      <td class="text-center">
                        {{ props.item.avarege_price }}
                      </td>
                      <td class="text-center">
                        {{ props.item.sku }}
                      </td>
                      <td class="text-center">
                        {{ props.item.article }}
                      </td>
                      <td class="text-center">
                        {{ props.item.store_place }}
                      </td>
                    </tr>

                    <tr />
                  </template>
                  <template slot="no-data">
                    {{ $t("custom.no_data_in_table") }}
                  </template>
                  <v-alert
                    slot="no-results"
                    :value="true"
                    color="error"
                    icon="mdi-alert"
                  >
                    {{
                      $t("tables.no_search_result", {
                        msg: search_store_products,
                      })
                    }}
                  </v-alert>
                </v-data-table>
                <v-toolbar flat>
                  <v-toolbar-title>{{ $t("store.movements") }}</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center"></th>
                        <th class="text-center">{{ $t("custom.id") }}</th>
                        <th class="text-center">{{ $t("store.name") }}</th>
                        <th class="text-center">{{ $t("store.article") }}</th>
                        <th class="text-center">{{ $t("store.sku") }}</th>
                        <th class="text-center">{{ $t("custom.date") }}</th>
                        <th class="text-center">{{ $t("store.store") }}</th>
                        <th class="text-center">
                          {{ $t("employers.employee") }}
                        </th>
                        <th class="text-center">{{ $t("store.price") }}</th>
                        <th class="text-center">{{ $t("store.qty") }}</th>
                        <th class="text-center">
                          {{ $t("store.description") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="movement in movements" :key="movement.id">
                        <td class="text-center">
                          <v-icon class="mx-1" color="primary">
                            mdi-arrow-up-bold-box-outline
                          </v-icon>
                        </td>
                        <td class="text-center">
                          {{ movement.id }}
                        </td>
                        <td class="text-center">
                          {{ findStoreProduct(movement.store_product_id) }}
                        </td>
                        <td class="text-center">
                          {{ findProductArticle(movement.store_product_id) }}
                        </td>
                        <td class="text-center">
                          {{ findProductSku(movement.store_product_id) }}
                        </td>
                        <td class="text-center">
                          {{ returnDateTime(movement.created_at) }}
                        </td>
                        <td class="text-center">
                          {{ findStoreLocation(movement.store_location_id) }}
                        </td>
                        <td class="text-center">
                          {{ setWorker(movement.user_id) }}
                        </td>
                        <td class="text-center">
                          {{ movement.price ? movement.price : 0 }}
                        </td>
                        <td class="text-center">
                          {{ movement.quantity }}
                        </td>
                        <td class="text-center">
                          {{ movement.description }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Букинг 8 -->
          <v-tab-item>
            <v-card flat v-if="$store.getters.getCompany.company && $store.getters.getCompany.company.in_booking && $store.getters.getCompany.company.agregator_state == 2">
              <v-card-text>
                <v-container
                  :style="isMobile ? 'padding: 2px !important;' : ''"
                >
                  <v-layout row wrap>
                    <v-flex
                      v-if="!car.in_agregator && !car.ready_for_agregator"
                      xs12
                      sm12
                      md12
                      class="my-2"
                    >
                      <v-alert prominent type="info">
                        <v-row align="center">
                          <v-col class="grow">
                            {{ $t("agregator.ready_for_agregator") }}
                          </v-col>
                          <v-col class="shrink">
                            <v-btn color="warning" @click="sendToModeration()"
                              >{{ $t("agregator.send_to_moderation")
                              }}<v-icon class="my-auto">
                                mdi-car-search-outline
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-flex>
                    <v-flex
                      v-if="!car.in_agregator && car.ready_for_agregator"
                      xs12
                      sm12
                      md12
                      class="my-2"
                    >
                      <v-alert
                        prominent
                        color="blue-grey"
                        dark
                        icon="mdi-car-search-outline"
                      >
                        <v-row align="center">
                          <v-col class="grow">
                            {{ $t("agregator.send_to_moderation_success") }}
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-flex>
                    <v-flex
                      v-if="car.in_agregator && !car.ready_for_agregator"
                      xs12
                      sm12
                      md12
                      class="my-2"
                    >
                      <v-alert prominent type="success">
                        <v-row align="center">
                          <v-col class="grow">
                            {{ $t("agregator.ready_for_agregator") }}
                          </v-col>
                          <v-col class="shrink">
                            <v-btn
                              color="error"
                              @click="withdrawFromPublication()"
                              >{{ $t("agregator.withdraw_from_publication")
                              }}<v-icon class="my-auto"> mdi-car-off </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-alert>
                    </v-flex>
                    <v-flex xs12 sm12 md12 class="my-2">
                      <gallery-booking></gallery-booking>
                    </v-flex>
                    <v-flex xs12 sm12 md12 class="my-2 d-flex justify-center">
                      <uppy-booking
                        :max-file-size-in-bytes="10000000"
                        :width="isMobile ? 300 : 750"
                        :height="isMobile ? 250 : 250"
                        :theme="$vuetify.theme.dark ? 'dark' : 'light'"
                      ></uppy-booking>
                    </v-flex>
                    <v-flex md12 class="my-3 text-center">
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex class="mt-2 text-center">
                      <h3 class="text-center">
                        {{ $t("agregator.insurance") }}
                      </h3>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      md12
                      :class="isMobile ? 'my-2' : 'my-2 d-flex'"
                    >
                      <v-flex md4 class="">
                        <v-checkbox
                          v-model="booking_insurance.full.on"
                          :label="$t('agregator.full_insurance')"
                          class="mt-5 mr-2"
                        ></v-checkbox>
                        <v-text-field
                          class="mr-2 my-2"
                          v-model="booking_insurance.full.price"
                          :label="$t('agregator.full_insurance_price')"
                          type="number"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 class="">
                        <v-checkbox
                          v-model="booking_insurance.super_kasko.on"
                          :label="$t('agregator.super_kasko')"
                          class="mt-5 mr-2"
                        ></v-checkbox>
                        <v-text-field
                          class="mr-2 my-2"
                          v-model="booking_insurance.super_kasko.price"
                          :label="$t('agregator.super_kasko_price')"
                          type="number"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 class="">
                        <v-checkbox
                          v-model="booking_insurance.kasko.on"
                          :label="$t('agregator.kasko')"
                          class="mt-5 mr-2"
                        ></v-checkbox>
                        <v-text-field
                          class="mr-2 my-2"
                          v-model="booking_insurance.kasko.price"
                          :label="$t('agregator.kasko_price')"
                          type="number"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 class="">
                        <v-checkbox
                          v-model="booking_insurance.theft.on"
                          :label="$t('agregator.theft')"
                          class="mt-5 mr-2"
                        ></v-checkbox>
                        <v-text-field
                          class="mr-2 my-2"
                          v-model="booking_insurance.theft.price"
                          :label="$t('agregator.theft_price')"
                          type="number"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 class="">
                        <v-checkbox
                          v-model="booking_insurance.osago.on"
                          :label="$t('agregator.osago')"
                          class="mt-5 mr-2"
                        ></v-checkbox>
                        <v-text-field
                          class="mr-2 my-2"
                          value="0"
                          :label="$t('agregator.osago_price')"
                          type="number"
                          readonly
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-flex class="mt-2 text-center">
                        <v-btn
                          color="success"
                          @click="saveCarBookingSettingInsurance()"
                          >{{ $t("agregator.save_car_insurance")
                          }}<v-icon v-if="!isMobile" class="my-auto">
                            mdi-content-save-check-outline
                          </v-icon>
                        </v-btn>
                      </v-flex>
                    </v-flex>
                    <v-flex md12 class="my-3 text-center">
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex md12 class="mt-2 text-center">
                      <h3 class="text-center">
                        {{ $t("agregator.services") }}
                      </h3>
                    </v-flex>
                    <v-flex
                      :class="isMobile ? 'text-center' : 'd-flex mx-auto'"
                      md12
                      size="12"
                      v-for="(service, index) in booking_services"
                      :key="index"
                    >
                      <v-flex md3>
                        <v-text-field
                          class="mr-2 my-2"
                          :value="findServiceName(service.id)"
                          readonly
                          :label="$t('agregator.service_name')"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex md3>
                        <v-text-field
                          class="mr-2 my-2"
                          v-model="service.price"
                          :label="$t('agregator.service_price')"
                          type="number"
                          outlined
                          dense
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6 class="d-flex justify-between align-center">
                        <v-switch
                          :label="
                            service.daily
                              ? $t('agregator.paid_daily_service')
                              : $t('agregator.paid_once_service')
                          "
                          dense
                          hide-details
                          v-model="service.daily"
                          class="mx-auto my-auto"
                        ></v-switch>
                        <v-switch
                          :label="
                            service.available
                              ? $t('agregator.available')
                              : $t('agregator.unavailable')
                          "
                          dense
                          hide-details
                          v-model="service.available"
                          class="mx-auto my-auto"
                        ></v-switch>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-flex class="mt-2 text-center">
                        <v-btn
                          color="success"
                          @click="saveCarBookingSettingServices()"
                          >{{ $t("agregator.save_car_services")
                          }}<v-icon class="my-auto">
                            mdi-content-save-check-outline
                          </v-icon>
                        </v-btn>
                      </v-flex>
                    </v-flex>
                    <v-flex v-if="!isMobile" md12 class="my-3 text-center">
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex md12 class="mt-4 text-center">
                      <h3 class="text-center">
                        {{ $t("agregator.fast_booking") }}
                      </h3>
                    </v-flex>
                    <v-flex md12 size="12">
                      <v-flex md12>
                        <v-switch
                          :label="$t('agregator.fast_booking')"
                          dense
                          hide-details
                          v-model="fast_booking"
                          @change="saveCarBookingSettingFastBooking()"
                        ></v-switch>
                      </v-flex>
                      <v-flex md12>
                        {{ $t("agregator.fast_booking_info") }}
                      </v-flex>
                      <v-flex md12>
                        {{ $t("agregator.fast_booking_pros") }}
                      </v-flex>
                    </v-flex>
                    <v-flex md12 class="my-3 text-center">
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex md12 class="mt-2 text-center">
                      <h3 class="text-center">
                        {{ $t("agregator.description") }}
                      </h3>
                    </v-flex>
                    <v-flex md12 size="12">
                      <v-flex xs12 sm12 md12>
                        <v-textarea
                          v-model="booking_description"
                          :label="$t('agregator.description')"
                          :placeholder="$t('agregator.description_info')"
                        ></v-textarea>
                      </v-flex>
                    </v-flex>
                    <v-flex md12>
                      <v-flex class="mt-2 text-center">
                        <v-btn
                          color="success"
                          @click="saveCarBookingSettingDescription()"
                          >{{ $t("agregator.save_description")
                          }}<v-icon class="my-auto">
                            mdi-content-save-check-outline
                          </v-icon>
                        </v-btn>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
            <v-card flat v-else>
              <v-card-text>
                <v-container
                  :style="isMobile ? 'padding: 2px !important;' : ''"
                >
                  <v-layout row wrap>
                    <v-flex
                      xs12
                      sm12
                      md12
                      class="my-2"
                    >
                      <v-alert prominent type="info">
                        <v-row align="center">
                          <v-col v-if="$store.getters.getCompany.company && $store.getters.getCompany.company.agregator_state == 0" class="grow">
                            {{ $t("agregator.ready_for_agregator") }}
                          </v-col>
                          <v-col v-if="$store.getters.getCompany.company && $store.getters.getCompany.company.agregator_state == 1" class="grow">
                            {{ $t("agregator.send_application_to_agregator_success_text") }}
                          </v-col>
                          <v-col v-if="$store.getters.getCompany.company && $store.getters.getCompany.company.agregator_state == 0" class="shrink">
                            <v-btn color="success" @click="showApplicationToBooking()"
                              >{{ $t("agregator.send_application_to_agregator")
                              }}<v-icon class="my-auto">
                                mdi-car-search-outline
                              </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-alert>
                      <v-flex class="text-left">
                        <h3>{{$t("agregator.short_agreement_title")}}</h3>
                        <p v-html="$t('agregator.short_agreement')">
                        </p>
                      </v-flex>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
    <v-dialog
      v-model="repair_dialog"
      :retain-focus="false"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("custom.repair") }}</span>
          <v-icon class="ml-2 green--text" @click="repairSave">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="repair_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-form>
              <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
                <v-layout row wrap>
                  <v-flex
                    xs12
                    sm12
                    md12
                    :class="isMobile ? 'align-center' : 'd-flex align-center'"
                    justify="space-around"
                  >
                    <p>{{ $t("cars.repair_what") }}</p>
                    <v-textarea
                      v-model="repair.description"
                      clearable
                      outlined
                      :label="$t('cars.repair_what_desc')"
                    ></v-textarea>
                    <v-switch
                      color="red"
                      v-model="repair.warning"
                      :label="$t('cars.repair_warning')"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="repair_dialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="repairSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="inspection_dialog"
      :retain-focus="false"
      persistent
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("to.to") }}</span>
          <v-icon class="ml-2 green--text" @click="inspectionSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (inspection_dialog = false),
                (inspection_date = null),
                (inspection_date_menu = false),
                (inspection_description = null)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-form>
              <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 justify="space-around">
                    <v-menu offset-y :close-on-click="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          :dense="isMobile"
                          v-model="inspection_date_format"
                          prepend-icon="mdi-calendar-clock"
                          style="max-width: 240px"
                          :label="$t('to.date')"
                          single-line
                          hide-details
                        ></v-text-field>
                      </template>
                      <vc-date-picker
                        v-model="inspection_date"
                        mode="date"
                        :attributes="attrs_calendar"
                        :masks="{ input: 'DD-MM-YYYY' }"
                        :locale="$i18n.locale"
                      >
                      </vc-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="inspection_description"
                      clearable
                      rows="2"
                      auto-grow
                      outlined
                      :label="$t('to.total')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              (inspection_dialog = false),
                (inspection_date = null),
                (inspection_date_menu = false),
                (inspection_description = null)
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="success" @click="inspectionSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="store_place_dialog"
      :retain-focus="false"
      max-width="500px"
      transition="dialog-bottom-transition"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("home.location") }}</span>
          <v-icon class="ml-2 green--text" @click="storePlaceSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="(store_place_dialog = false), (store_place = null)"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-form>
              <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 justify="space-around">
                    <v-text-field
                      v-model="store_place"
                      clearable
                      :label="$t('home.location')"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(store_place_dialog = false), (store_place = null)"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="success" @click="storePlaceSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="sell_dialog"
      :retain-focus="false"
      persistent
      max-width="800px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("cars.sell") }}</span>
          <v-icon class="ml-2 green--text" @click="sellSave">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (sell_dialog = false),
                (isLoading = false),
                (sell_info.price = 0),
                (sell_info.date = null),
                (sell_info.cash = false),
                (sell_info.cash_card = false),
                (sell_info.cashless = false),
                (sell_info.entity = false)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-container>
              <v-layout row wrap>
                <v-flex
                  xs6
                  sm6
                  md6
                  class="d-flex d-inline-flex align-center"
                  justify="space-around"
                >
                  <v-text-field
                    v-model.number="sell_info.price"
                    clearable
                    outlined
                    :label="$t('cars.sell_price')"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs6
                  sm6
                  md6
                  class="d-flex d-inline-flex align-center"
                  justify="space-around"
                >
                  <v-text-field
                    v-model="sell_info.date"
                    type="date"
                    clearable
                    outlined
                    :label="$t('cars.sale_date')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 :class="isMobile ? '' : 'd-inline-flex'">
                  <v-switch
                    v-model="sell_info.cash"
                    :label="$t('money.cash')"
                    :disabled="
                      sell_info.cashless ||
                      sell_info.cash_card ||
                      sell_info.entity
                    "
                    class="mr-2"
                  ></v-switch>
                  <v-switch
                    v-model="sell_info.cashless"
                    :label="$t('money.terminal_short')"
                    :disabled="
                      sell_info.cash || sell_info.cash_card || sell_info.entity
                    "
                    class="mr-2"
                  ></v-switch>
                  <v-switch
                    v-model="sell_info.cash_card"
                    :label="$t('money.card_to_card')"
                    :disabled="
                      sell_info.cash || sell_info.cashless || sell_info.entity
                    "
                    class="mr-2"
                  ></v-switch>
                  <v-switch
                    v-model="sell_info.entity"
                    :label="$t('money.to_entity_short')"
                    :disabled="
                      sell_info.cashless ||
                      sell_info.cash_card ||
                      sell_info.cash
                    "
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              (sell_dialog = false),
                (isLoading = false),
                (sell_info.price = 0),
                (sell_info.date = null),
                (sell_info.cash = false),
                (sell_info.cash_card = false),
                (sell_info.cashless = false),
                (sell_info.entity = false)
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="success" @click="sellSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="edit_price_dialog"
      :retain-focus="false"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("cars.change_price") }}</span>
          <v-icon class="ml-2 green--text" @click="saveEditedPrice()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="(editedPrice = {}), (edit_price_dialog = false)"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text
          class="mx-auto"
          :style="isMobile ? 'padding: 5px !important;' : ''"
        >
          <v-flex md12 class="mx-auto">
            <v-text-field
              v-model.number="editedPrice.value"
              clearable
              outlined
              :label="$t('bookings.price')"
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(editedPrice = {}), (edit_price_dialog = false)"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="success" @click="saveEditedPrice()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import Gallery from "./Gallary";
import GalleryBooking from "./GallaryBooking";
import GalleryCheckin from "./GallaryCheckin";
import Uppy from "../../../plugins/uppy_cars";
import UppyBooking from "../../../plugins/uppy_booking";
import UppyCarsDoc from "../../../plugins/uppy_cars_doc";
import UppyCarsEntityDoc from "../../../plugins/uppy_cars_entity_doc";
import { GChart } from "vue-google-charts";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      car: "",
      periods: [],
      seasons: [],
      analitics_data: [],
      expences_counts: [],
      search_income: null,
      search_expences: null,
      // tabs: 0,
      set_tabs: 0,
      calendar_attributes: [],
      select_seasons: [{ season_id: null, value: this.$t("cars.main_prices") }],
      editedPrice: {},
      createPricesInput: {},
      movements: [],
      store_locations: [],
      store_products: [],
      search_store_products: null,
      filter_store_product_group: null,
      store_products_headers: [
        {
          text: this.$t("custom.id"),
          align: "center",
          value: "id",
        },
        {
          text: this.$t("custom.created_at"),
          align: "center",
          value: "created_at",
        },
        {
          text: this.$t("store.name"),
          align: "center",
          value: "name",
        },
        {
          text: this.$t("store.group"),
          align: "center",
          value: "group",
        },
        {
          text: this.$t("store.stock_balance"),
          align: "center",
          value: "stock_balance",
        },
        {
          text: this.$t("store.average_price"),
          align: "center",
          value: "average_price",
        },
        {
          text: this.$t("store.sku"),
          align: "center",
          value: "sku",
        },
        {
          text: this.$t("store.article"),
          align: "center",
          value: "article",
        },
        {
          text: this.$t("store.store_place"),
          align: "center",
          value: "store_place",
        },
      ],
      show_only_in_stock: false,
      inspections_headers: [
        { text: this.$t("to.date"), align: "center", value: "date" },
        { text: this.$t("to.worker"), value: "worker", align: "center" },
        {
          text: this.$t("to.total"),
          value: "description",
          sortable: false,
          align: "center",
        },
      ],
      bookings_states: [
        { text: this.$t("bookings_states.new"), value: "Новая" },
        { text: this.$t("bookings_states.in_work"), value: "В обработке" },
        { text: this.$t("bookings_states.abandoned"), value: "Недозвон" },
        { text: this.$t("bookings_states.decline"), value: "Отказ клиента" },
        { text: this.$t("bookings_states.no_cars"), value: "Нет машин" },
        {
          text: this.$t("bookings_states.wait_answer"),
          value: "Ожидает ответа клиента",
        },
        { text: this.$t("bookings_states.wait_pay"), value: "Ожидает оплаты" },
        { text: this.$t("bookings_states.active"), value: "Активная" },
        { text: this.$t("bookings_states.cancel"), value: "Отмена" },
        { text: this.$t("bookings_states.rided"), value: "Отъездила" },
        {
          text: this.$t("bookings_states.return_deposit"),
          value: "Вернуть залог",
        },
        { text: this.$t("bookings_states.accepted"), value: "Подтверждена" },
        {
          text: this.$t("bookings_states.not_accepted"),
          value: "Не подтверждена",
        },
        { text: this.$t("bookings_states.other"), value: "Другое" },
      ],
      bookings: [],
      inspections: [],
      search_bookings: null,
      bookings_headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created"),
          value: "created_at",
          align: "center",
        },
        {
          text: this.$t("custom.state_short"),
          value: "in_rent",
          align: "center",
        },
        {
          text: this.$t("custom.state_short_2"),
          value: "state",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "last_name", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        {
          text: this.$t("custom.days"),
          sortable: false,
          value: "days",
          align: "center",
        },
        {
          text: this.$t("custom.checkin"),
          sortable: false,
          value: "location_start",
          align: "center",
        },
        {
          text: this.$t("custom.checkout"),
          sortable: false,
          value: "location_end",
          align: "center",
        },
        {
          text: this.$t("custom.description_2"),
          sortable: false,
          align: "center",
        },
      ],
      inspection_date: null,
      search_inspections: null,
      inspection_date: null,
      inspection_date_format: null,
      inspection_date_menu: false,
      inspection_description: null,
      cars_doc_template_url: null,
      cars_doc_template_id: null,
      cars_entity_doc_template_url: null,
      cars_entity_doc_template_id: null,
      workers: [],
      repair_dialog: false,
      sell_dialog: false,
      edit_price_dialog: false,
      inspection_dialog: false,
      showAddPriceBtn: true,
      repair: {
        description: "",
        warning: false,
      },
      sell_info: {
        price: 0,
        date: null,
        cash: false,
        cashless: false,
        cash_card: false,
        entity: false,
      },
      editedCount: [],
      selected_car: [],
      selected_count: [],
      states: [
        { id: 1, state: this.$t("states.normal") },
        { id: 2, state: this.$t("states.in_service") },
        { id: 3, state: this.$t("states.critical") },
        { id: 4, state: this.$t("states.long_rent") },
        { id: 5, state: this.$t("states.no_rent") },
        { id: 6, state: this.$t("states.service_needed") },
      ],
      store_place_dialog: false,
      store_place: null,
      start_date: null,
      end_date: null,
      start_date_format: null,
      end_date_format: null,
      start_date_menu: false,
      end_date_menu: false,
      counts_groups: [
        { text: this.$t("groups_counts.repair"), value: "Ремонт" },
        { text: this.$t("groups_counts.service"), value: "Обслуживание" },
        { text: this.$t("groups_counts.parts"), value: "Запчасти" },
        { text: this.$t("groups_counts.insurance_main"), value: "Страховка" },
        {
          text: this.$t("groups_counts.common_expences"),
          value: "Накладные расходы",
        },
        {
          text: this.$t("groups_counts.transport_expences"),
          value: "Транспортные расходы",
        },
        { text: this.$t("groups_counts.tax"), value: "Налоги" },
        { text: this.$t("groups_counts.fines_main"), value: "Штрафы" },
        {
          text: this.$t("groups_counts.rent_property"),
          value: "Аренда имущества",
        },
        {
          text: this.$t("groups_counts.clean_main"),
          value: "Мойка автомобилей",
        },
        { text: this.$t("groups_counts.fuel_main"), value: "Топливо" },
        { text: this.$t("groups_counts.ads"), value: "Реклама" },
        {
          text: this.$t("groups_counts.sell_property"),
          value: "Продажа имущества",
        },
        {
          text: this.$t("groups_counts.buy_property"),
          value: "Покупка имущества",
        },
        {
          text: this.$t("groups_counts.other_expences"),
          value: "Прочие расходы",
        },
        {
          text: this.$t("groups_counts.other_incomes"),
          value: "Прочие доходы",
        },
        { text: this.$t("groups_counts.rent"), value: "Оплата аренды" },
        {
          text: this.$t("groups_counts.delivery"),
          value: "Доплата за доставку",
        },
        { text: this.$t("groups_counts.checkout"), value: "Доплата за приём" },
        { text: this.$t("groups_counts.fuel"), value: "Доплата за топливо" },
        { text: this.$t("groups_counts.clean"), value: "Доплата за мойку" },
        {
          text: this.$t("groups_counts.equipment"),
          value: "Доплата за оборудование",
        },
        {
          text: this.$t("groups_counts.add_time"),
          value: "Доплата за дополнительное время",
        },
        {
          text: this.$t("groups_counts.damages"),
          value: "Доплата за повреждения",
        },
        { text: this.$t("groups_counts.fines"), value: "Доплата за штрафы" },
        { text: this.$t("groups_counts.other"), value: "Доплата за другое" },
        {
          text: this.$t("groups_counts.mileage"),
          value: "Доплата за превышение пробега",
        },
        {
          text: this.$t("groups_counts.insurance"),
          value: "Доплата за страховку",
        },
        {
          text: this.$t("groups_counts.add_drivers"),
          value: "Доплата за доп водителей",
        },
        {
          text: this.$t("groups_counts.internal"),
          value: "Внутренние переводы",
        },
        { text: this.$t("groups_counts.deposit"), value: "Залог" },
        { text: this.$t("groups_counts.salary"), value: "Зарплата" },
        { text: this.$t("groups_counts.partners_payments"), value: "Выплаты партнёрам" },
      ],
      headers_counts: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.group"), sortable: false, align: "center" },
        {
          text: this.$t("custom.description"),
          sortable: false,
          value: "description",
          align: "center",
        },
        {
          text: this.$t("custom.amount"),
          sortable: false,
          value: "sum",
          align: "center",
        },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
      ],
      counts: [],
      branches: [],
      revenue_for_period_data: [
        [
          this.$t("custom.group"),
          this.$t("custom.income"),
          this.$t("custom.expences"),
        ],
      ],
      income_expences_chart_data: [
        [this.$t("custom.income"), this.$t("custom.expences")],
      ],
      chart_types: ["BarChart", "ColumnChart", "PieChart", "LineChart"],
      selected_chart_type: "ColumnChart",
      selected_group: [],
      showEdit: false,
      editDialog: false,
      isLoading: false,
      drive_units: [
        { text: this.$t("drive_units.front"), value: "Передний" },
        { text: this.$t("drive_units.back"), value: "Задний" },
        { text: this.$t("drive_units.full"), value: "Полный" },
      ],
      car_classes: [
        { text: this.$t("car_classes.economy"), value: "Эконом" },
        { text: this.$t("car_classes.middle"), value: "Средний" },
        { text: this.$t("car_classes.business"), value: "Бизнес" },
        { text: this.$t("car_classes.commercial"), value: "Коммерческий" },
      ],
      car_types: [
        { text: this.$t("car_types.micro"), value: "Микро" },
        { text: this.$t("car_types.hatchback"), value: "Хэтчбек" },
        { text: this.$t("car_types.sedan"), value: "Седан" },
        { text: this.$t("car_types.universal"), value: "Универсал" },
        { text: this.$t("car_types.minivan"), value: "Минивен" },
        { text: this.$t("car_types.coupe"), value: "Купе" },
        { text: this.$t("car_types.crossover"), value: "Кроссовер" },
        { text: this.$t("car_types.suv"), value: "Внедорожник" },
        { text: this.$t("car_types.pickup"), value: "Пикап" },
        { text: this.$t("car_types.limousine"), value: "Лимузин" },
        { text: this.$t("car_types.van"), value: "Фургон" },
        { text: this.$t("car_types.cabriolet"), value: "Кабриолет" },
        { text: this.$t("car_types.bus"), value: "Микроавтобус" },
        { text: this.$t("car_types.campervan"), value: "Автодом" },
        { text: this.$t("car_types.light_van"), value: "Легковой фургон" },
        { text: this.$t("car_types.rodster"), value: "Родстер" },
        { text: this.$t("car_types.sportcar"), value: "Спорткар" },
      ],
      fuels: [
        { text: this.$t("fuels.gas"), value: "Бензин" },
        { text: this.$t("fuels.gas_92"), value: "Бензин 92" },
        { text: this.$t("fuels.gas_95"), value: "Бензин 95" },
        { text: this.$t("fuels.gas_98"), value: "Бензин 98" },
        { text: this.$t("fuels.disel"), value: "Дизель" },
        { text: this.$t("fuels.gas_natural"), value: "Газ" },
        { text: this.$t("fuels.gas_gasoline"), value: "Бензин/Газ" },
        { text: this.$t("fuels.electricity"), value: "Электричество" },
        { text: this.$t("fuels.hybrid"), value: "Гибрид" },
        { text: this.$t("fuels.hydrogen"), value: "Водород" },
      ],
      gears: [
        { text: this.$t("transmissions.automatic"), value: "Автомат" },
        { text: this.$t("transmissions.manual"), value: "Механика" },
        { text: this.$t("transmissions.variator"), value: "Вариатор" },
        { text: this.$t("transmissions.robot"), value: "Робот" },
      ],
      roofs: [
        { text: this.$t("roofs.ordinary"), value: "Обычная" },
        { text: this.$t("roofs.glass"), value: "Стеклянная" },
        { text: this.$t("roofs.folding"), value: "Складывающаяся" },
      ],
      steering_side: [
        { text: this.$t("steering_side.left"), value: "Левая" },
        { text: this.$t("steering_side.right"), value: "Правая" },
      ],
      interiors: [
        { text: this.$t("interiors.textile"), value: "Ткань" },
        { text: this.$t("interiors.leather"), value: "Кожа" },
      ],
      attrs_calendar: [
        {
          key: "today",
          highlight: {
            color: "blue",
            fillMode: "outline",
            contentClass: "italic",
          },
          dates: new Date(),
        },
      ],
      booking_insurance: {
        full: {
          price: 0,
        },
        super_kasko: {
          price: 0,
        },
        kasko: {
          price: 0,
        },
        theft: {
          price: 0,
        },
        osago: {
          price: 0,
        },
      },
      car_marks: [],
      car_mark: null,
      isLoadingCarMarks: false,
      car_mark_search: null,
      car_models: [],
      car_model: null,
      isLoadingCarModels: false,
      car_model_search: null,
      car_generations: [],
      car_generation: null,
      isLoadingCarGenerations: false,
      car_generation_search: null,
      car_configurations: [],
      car_configuration: null,
      isLoadingCarConfigurations: false,
      car_configuration_search: null,
      car_modifications: [],
      car_modification: null,
      isLoadingCarModifications: false,
      car_modification_search: null,
      booking_services: [],
      booking_description: null,
      fast_booking: false,
      uploadPhotos: false,
      showDopPanel: false,
      isMobile: false,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/cars/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.car = response.data;
        })
        .then(() => {
          this.axios
            .get(`/api/v1/seasons`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              self.seasons = response.data;
              self.seasons.forEach((season) => {
                self.car.prices.forEach((price) => {
                  if (price.season_id != season.id) {
                    self.select_seasons.push({
                      season_id: season.id,
                      value: `${season.start_date} - ${season.end_date}`,
                    });
                  }
                });
              });
            })
            .catch((error) => {
              self.setError(error, self.$t("errors.company_load"));
              console.log(error);
            });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.car_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.periods = response.data.periods;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/bookings_in_cars/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.bookings = response.data;
          if (this.bookings.length == 0) {
            this.tabs = 2;
          }
          function compare(a, b) {
            if (
              moment(a.end_date, "DD-MM-YYYY H:mm").isBefore(
                moment(b.end_date, "DD-MM-YYYY H:mm")
              )
            ) {
              return 1;
            }
            if (
              moment(a.end_date, "DD-MM-YYYY H:mm").isSameOrAfter(
                moment(b.end_date, "DD-MM-YYYY H:mm")
              )
            ) {
              return -1;
            }
            return 0;
          }
          this.bookings = this.bookings.sort(compare);
          function selectHighlight(booking) {
            if (booking.active) {
              return booking.in_rent ? "green" : "blue";
            } else {
              return "gray";
            }
          }
          this.bookings.forEach((booking) => {
            if (booking.active || booking.in_rent || booking.ride) {
              self.calendar_attributes.push({
                highlight: {
                  start: {
                    fillMode: "outline",
                    color: selectHighlight(booking),
                  },
                  base: { fillMode: "light", color: selectHighlight(booking) },
                  end: { fillMode: "outline", color: selectHighlight(booking) },
                },
                content: "black",
                dates: {
                  start: moment(booking.start_date, "DD-MM-YYYY H:mm").toDate(),
                  end: moment(booking.end_date, "DD-MM-YYYY H:mm").toDate(),
                },
                popover: {
                  label: `${self.$t("custom.number")}${booking.id} ${
                    booking.start_date
                  } ${booking.location_start} - ${booking.end_date} ${
                    booking.location_end
                  } | ${booking.last_name} ${booking.first_name} ${
                    booking.middle_name
                  }`,
                  visibility: "hover",
                  hideIndicator: true,
                },
              });
            }
          });
          // Подтягиваем данные при первом заходе
          this.updateTabsData();
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.bookings_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted: function () {
    if (
      this.$tours["registrationTour"] &&
      this.$store.getters.getTourNextStep
    ) {
      this.$tours["registrationTour"].nextStep();
    }
  },
  watch: {
    set_tabs: function () {
      this.$store.commit("setCarTab", this.set_tabs);
    },
    tabs() {
      this.updateTabsData();
    },
    start_date() {
      if (this.start_date) {
        this.start_date_format = moment(this.start_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
        if (this.start_date && this.end_date) {
          this.getAnaliticsDataForRange();
        }
      }
    },
    end_date() {
      if (this.end_date) {
        this.end_date_format = moment(this.end_date, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );

        this.getAnaliticsDataForRange();
      }
    },
    selected_group() {
      this.revenue_for_period_data = [
        [
          this.$t("custom.group"),
          this.$t("custom.income"),
          this.$t("custom.expences"),
        ],
      ];
      this.selected_group.forEach((group) => {
        if (
          this.analitics_data.income_counts &&
          this.analitics_data.expences_counts
        ) {
          let true_counts = this.analitics_data.income_counts.filter(
            (count) => count.group == group
          );
          let false_counts = this.analitics_data.expences_counts.filter(
            (count) => count.group == group
          );
          // console.log(true_counts,false_counts)
          let revenue = 0;
          let expences = 0;
          if (true_counts.length > 0) {
            revenue = true_counts.map((a) => a.sum).reduce((v, i) => v + i);
          }
          if (false_counts.length > 0) {
            expences = false_counts.map((a) => a.sum).reduce((v, i) => v + i);
          }
          // let income = revenue - expences

          this.revenue_for_period_data.push([this.translateCategory(group), revenue, expences * -1]);
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.not_enough_data"),
            text: this.$t("errors.not_enough_car_data"),
          });
        }
      });
    },
    inspection_date() {
      if (this.inspection_date) {
        this.inspection_date_format = moment(this.inspection_date).format(
          "DD-MM-YYYY"
        );
      }
    },
    car_mark() {
      if (this.car_mark) {
        this.car.car_mark_id = this.car_mark.id;
        this.isLoading = true;
        this.isLoadingCarModels = true;
        this.axios
          .get(`/api/v1/car_models?car_mark_id=${this.car_mark.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.car_models = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarModels = false;
            this.isLoading = false;
          });
      } else {
        this.car_models = [];
        this.car_generations = [];
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_model() {
      if (this.car_model) {
        this.car.car_model_id = this.car_model.id;
        this.isLoading = true;
        this.isLoadingCarGenerations = true;
        if (!this.car.car_name || this.car.car_name == "") {
          this.car.car_name = `${this.car_mark.name} ${this.car_model.name}`;
        }
        if (!this.car.code || this.car.code == "") {
          this.car.code = `${this.car_mark.name[0]}${this.car_model.name[0]}1`;
        }
        this.axios
          .get(`/api/v1/car_generations?car_model_id=${this.car_model.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.car_generations = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarGenerations = false;
            this.isLoading = false;
          });
      } else {
        this.car_generations = [];
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_generation() {
      if (this.car_generation) {
        this.car.car_generation_id = this.car_generation.id;
        this.isLoadingCarConfigurations = true;
        this.isLoading = true;
        this.axios
          .get(
            `/api/v1/car_configurations?car_generation_id=${this.car_generation.id}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.car_configurations = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarConfigurations = false;
            this.isLoading = false;
          });
      } else {
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_configuration() {
      if (this.car_configuration) {
        this.car.car_configuration_id = this.car_configuration.id;
        this.car_modifications = this.car_configuration.modifications
        if (this.car_configuration["body_type"] && (this.car.car_type == "" || this.car.car_type == null)) {
          switch (this.car_configuration["body_type"]) {
            case 'купе': case 'купе-хардтоп':
              this.car.car_type = "Купе"
              break;
            case 'родстер': case 'тарга': case 'спидстер':
              this.car.car_type = "Родстер"
              break;
            case 'седан': case 'седан 2 дв.': case 'лифтбек': case 'седан-хардтоп':
              this.car.car_type = "Седан"
              break;
            case 'хэтчбек 3 дв.': case 'хэтчбек 5 дв.': case 'хэтчбек 4 дв.':
              this.car.car_type = "Хэтчбек"
              break;
            case 'внедорожник 5 дв.': case 'внедорожник 3 дв.': case 'внедорожник открытый':
              this.car.car_type = "Внедорожник"
              break;
            case 'универсал': case 'универсал 5 дв.': case 'универсал 3 дв.':
              this.car.car_type = "Универсал"
              break;
            case 'кабриолет':
              this.car.car_type = "Кабриолет"
              break;
            case 'минивэн': case 'микровэн': case 'компактвэн':
              this.car.car_type = "Минивен"
              break;
            case 'фургон':
              this.car.car_type = "Фургон"
              break;
            case 'пикап': case 'пикап одинарная кабина': case 'пикап полуторная кабина': case 'пикап двойная кабина':
              this.car.car_type = "Пикап"
              break;
            case 'лимузин':
              this.car.car_type = "Лимузин"
              break;
            default:
              break;
          }
        }
      } else {
        this.car_modifications = [];
      }
    },
    car_modification() {
      if (this.car_modification) {
        console.log(this.car_modification.specifications)
        this.car.car_complectation_id = this.car_modification["complectation-id"];
        if (this.car_modification.specifications.transmission && (this.car.transmission == "" || this.car.transmission == null)) {
          let self = this
          switch (this.car_modification.specifications.transmission) {
            case 'механическая':
              self.car.transmission = "Механика"
              break;
            case 'автоматическая':
              self.car.transmission = "Автомат"
              break;
            case 'робот':
              self.car.transmission = "Робот"
              break;
            case 'вариатор':
              self.car.transmission = "Вариатор"
              break;
            default:
              break;
          }
        }
        if (this.car_modification.specifications["doors-count"] && (this.car.doors_count == "" || this.car.doors_count == null || this.car.doors_count == 0)) {
          this.car.doors_count = this.car_modification.specifications["doors-count"]
        }
        if (this.car_modification.specifications["fuel-tank-capacity"] && (this.car.tank_value == "" || this.car.tank_value == null || this.car.tank_value == 0)) {
          this.car.tank_value = this.car_modification.specifications["fuel-tank-capacity"]
        }
        if (this.car_modification.specifications["horse-power"] && (this.car.engine_power == "" || this.car.engine_power == null || this.car.engine_power == 0)) {
          this.car.engine_power = this.car_modification.specifications["horse-power"]
        }
        if (this.car_modification.specifications["consumption-mixed"] && (this.car.gas_mileage == "" || this.car.gas_mileage == null || this.car.gas_mileage == 0)) {
          this.car.gas_mileage = this.car_modification.specifications["consumption-mixed"]
        }
        if (this.car_modification.specifications["volume-litres"] && (this.car.engine_capacity == "" || this.car.engine_capacity == null || this.car.engine_capacity == 0)) {
          this.car.engine_capacity = this.car_modification.specifications["volume-litres"]
        }
        if (this.car_modification.specifications["wheel-size"] && (this.car.tire_size == "" || this.car.tire_size == null || this.car.tire_size == 0)) {
          this.car.tire_size = this.car_modification.specifications["wheel-size"][0]
        }
        if (this.car_modification.specifications["drive"] && (this.car.drive_unit == "" || this.car.drive_unit == null)) {
          switch (this.car_modification.specifications["drive"]) {
            case 'задний':
              this.car.drive_unit = "Задний"
              break;
            case 'передний':
              this.car.drive_unit = "Передний"
              break;
            case 'полный':
              this.car.drive_unit = "Полный"
              break;
            default:
              break;
          }
        }
        if (this.car_modification.specifications["fuel"] && (this.car.drive_unit == "" || this.car.drive_unit == null)) {
          switch (this.car_modification.specifications["fuel"]) {
            case 'АИ-95':
              this.car.drive_unit = "Бензин 95"
              break;
            case 'АИ-92':
              this.car.drive_unit = "Бензин 92"
              break;
            case 'АИ-98':
              this.car.drive_unit = "Бензин 98"
              break;
            case 'газ':
              this.car.drive_unit = "Газ"
              break;
            case 'ДТ':
              this.car.drive_unit = "Дизель"
              break;
            case 'водород':
              this.car.drive_unit = "Водород"
              break;
            default:
              break;
          }
        }
      }
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    tabs: {
      // getter
      get: function () {
        return this.$store.getters.getCarTab;
      },
      // setter
      set: function (newValue) {
        this.$store.commit("setCarTab", newValue);
      },
    },
    store_products_for_car() {
      if (this.store_products && this.store_products.length > 0) {
        return this.store_products.filter((product) => {
          return product.cars_ids.includes(this.car.id);
        });
      } else {
        return [];
      }
    },
    products_groups() {
      let groups = [];
      this.store_products.forEach((product) => {
        if (!groups.includes(product.group)) {
          groups.push(product.group);
        }
      });
      return groups;
    },
  },
  methods: {
    removeSeasonPrices(price) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/prices/${price.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.car.prices.splice(self.car.prices.indexOf(price), 1);
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_prices"))
              )
              .finally(() => {
                this.isLoading = false;
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("tooltips.remove_prices_success"),
                  "success"
                );
              });
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    repairSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .post(
            `/api/v1/cars/${this.car.id}/repairs`,
            {
              repairs: {
                description: this.repair.description,
                warning: this.repair.warning,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("cars.add_repair"),
              text: this.$t("cars.add_repair_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.add_repair"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.add_repair"),
              text: this.error,
            });
          })
          .finally(
            () => (
              (this.repair_dialog = false),
              (this.isLoading = false),
              (this.repair.description = ""),
              (this.repair.warning = false)
            )
          );
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    sellSave() {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        if (
          this.car.id &&
          this.sell_info.price > 0 &&
          this.sell_info.date &&
          (this.sell_info.cash ||
            this.sell_info.cash_card ||
            this.sell_info.cashless ||
            this.sell_info.entity)
        ) {
          this.isLoading = true;
          var self = this;
          this.axios
            .post(
              `/api/v1/sell_car`,
              {
                car_id: this.car.id,
                sale_price: this.sell_info.price,
                sale_date: this.sell_info.date,
                archive: true,
                cash: this.sell_info.cash,
                cashless: this.sell_info.cashless,
                cash_card: this.sell_info.cash_card,
                entity: this.sell_info.entity,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("cars.sell_car_title"),
                text: this.$t("cars.sell_car_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.sell_car"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.sell_car"),
                text: this.error,
              });
            })
            .finally(
              () => (
                (this.car.archive = true),
                (this.sell_dialog = false),
                (this.isLoading = false),
                (this.sell_info.price = 0),
                (this.sell_info.date = null),
                (this.sell_info.cash = false),
                (this.sell_info.cash_card = false),
                (this.sell_info.cashless = false),
                (this.sell_info.entity = false)
              )
            );
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("custom.no_data"),
            text: this.$t("errors.sell_car_data"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    inspectionSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .post(
            `/api/v1/cars/${this.car.id}/inspections`,
            {
              inspections: {
                description: this.inspection_description,
                date: this.inspection_date,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.inspection_dialog = false;
            this.inspection_date = null;
            this.inspection_date_menu = false;
            this.inspection_description = null;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("to.success_title"),
              text: this.$t("to.success_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_to"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.update_to"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    storePlaceSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .patch(
            `/api/v1/cars/${this.car.id}`,
            {
              cars: {
                store_place: this.store_place,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.car.store_place = this.store_place;
            this.store_place = null;
            this.store_place_dialog = false;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("cars.location_car_updated"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.location_car"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.location_car"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    unarchiveCar() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .patch(
            `/api/v1/cars/${this.car.id}`,
            {
              cars: {
                archive: false,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then(() => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("cars.car_unarchive"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.unarchive_car"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.unarchive_car"),
              text: this.error,
            });
          })
          .finally(
            () => ((this.car.archive = false), (this.isLoading = false))
          );
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    archiveCar() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/cars/${this.car.id}`,
            {
              cars: {
                archive: true,
                active: false,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("cars.car_archivate"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.archivate_car"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.archivate_car"),
              text: this.error,
            });
          })
          .finally(() => ((this.car.archive = true), (this.isLoading = false)));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveCarActive() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.car.car_name &&
          this.car.code &&
          this.car.number &&
          (this.car.price_hour || this.car.price_hour == 0) &&
          (this.car.deposit || this.car.deposit == 0) &&
          this.car.prices.length > 0
        ) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/active_update_car/${this.car.id}`,
              {
                cars: {
                  active: !this.car.active,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.car.active = !this.car.active;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("cars.car_active"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.activate_car"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.activate_car"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.car.active = false;
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.activate_car_title"),
            text: this.$t("errors.activate_car_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    disactivateCar() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .patch(
            `/api/v1/active_update_car/${this.car.id}`,
            {
              cars: {
                active: false,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.car.active = false;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("cars.car_deactive"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.deactivate_car"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.deactivate_car"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let car = this.car;
        let self = this;
        if (car.car_name == "" || car.car_name == null) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.no_car_name_title"),
            text: this.$t("errors.no_car_name_text"),
          });
          this.isLoading = false;
          return;
        }
        this.axios
          .patch(
            `/api/v1/cars/${car.id}`,
            {
              cars: {
                active: car.active,
                car_name: car.car_name,
                company_id: car.company_id,
                code: car.code,
                sort: car.sort,
                branch_id: car.branch_id,
                number: car.number,
                vin: car.vin,
                body_number: car.body_number,
                pts: car.pts,
                registration_certificate: car.registration_certificate,
                year: car.year,
                transmission: car.transmission,
                fuel: car.fuel,
                tank_value: car.tank_value,
                number_doors: car.number_doors,
                engine_capacity: car.engine_capacity,
                is_electropackage: car.is_electropackage,
                car_type: car.car_type,
                car_class: car.car_class,
                color: car.color,
                is_air: car.is_air,
                price_hour: car.price_hour,
                deposit: car.deposit,
                insurance: car.insurance,
                oil_engine: car.oil_engine,
                oil_engine_date: car.oil_engine_date,
                oil_transmission: car.oil_transmission,
                oil_engine_km: car.oil_engine_km,
                oil_transmission_km: car.oil_transmission_km,
                oil_transmission_date: car.oil_transmission_date,
                mileage: car.mileage,
                start_mileage: car.start_mileage,
                grm: car.grm,
                grm_km: car.grm_km,
                grm_date: car.grm_date,
                antifreeze: car.antifreeze,
                antifreeze_km: car.antifreeze_km,
                antifreeze_date: car.antifreeze_date,
                brake_fluid: car.brake_fluid,
                brake_fluid_km: car.brake_fluid_km,
                brake_fluid_date: car.brake_fluid_date,
                candle: car.candle,
                candle_km: car.candle_km,
                candle_date: car.candle_date,
                power_steering: car.power_steering,
                power_steering_km: car.power_steering_km,
                power_steering_date: car.power_steering_date,
                diagnostic_card: car.diagnostic_card,
                salon_filter_renew: car.salon_filter_renew,
                salon_filter_renew_km: car.salon_filter_renew_km,
                salon_filter_renew_date: car.salon_filter_renew_date,
                akb_renew: car.akb_renew,
                akb_renew_km: car.akb_renew_km,
                akb_renew_date: car.akb_renew_date,
                fuel_filter_renew: car.fuel_filter_renew,
                fuel_filter_renew_km: car.fuel_filter_renew_km,
                fuel_filter_renew_date: car.fuel_filter_renew_date,
                purchase_price: car.purchase_price,
                purchase_date: car.purchase_date,
                sale_price: car.sale_price,
                sale_date: car.sale_date,
                tire_size: car.tire_size,
                number_seats: car.number_seats,
                drive_unit: car.drive_unit,
                engine_power: car.engine_power,
                airbags: car.airbags,
                roof: car.roof,
                gas_mileage: car.gas_mileage,
                steering_side: car.steering_side,
                interior: car.interior,
                abs: car.abs,
                ebd: car.ebd,
                esp: car.esp,
                window_lifters: car.window_lifters,
                trunk_volume: car.trunk_volume,
                description: car.description,
                franchise: car.franchise,
                max_fine: car.max_fine,
                repair_cost: car.repair_cost,
                heated_seats: car.heated_seats,
                heated_seats_front: car.heated_seats_front,
                parktronic: car.parktronic,
                parktronic_back: car.parktronic_back,
                parktronic_camera: car.parktronic_camera,
                wheel_adjustment: car.wheel_adjustment,
                wheel_adjustment_full: car.wheel_adjustment_full,
                audio_system: car.audio_system,
                video_system: car.video_system,
                tv_system: car.tv_system,
                cd_system: car.cd_system,
                usb_system: car.usb_system,
                climate_control: car.climate_control,
                folding_seats: car.folding_seats,
                heated_windshield: car.heated_windshield,
                rain_sensor: car.rain_sensor,
                beacon_emai: car.beacon_emai,
                beacon_phone: car.beacon_phone,
                beacon_pin: car.beacon_pin,
                extra_mileage_km: car.extra_mileage_km,
                extra_mileage_price: car.extra_mileage_price,
                age_limit: car.age_limit,
                driver_year_limit: car.driver_year_limit,
                custom_field_1: car.custom_field_1,
                custom_field_2: car.custom_field_2,
                custom_field_3: car.custom_field_3,
                car_mark_id: car.car_mark_id,
                car_model_id: car.car_model_id,
                car_generation_id: car.car_generation_id,
                car_configuration_id: car.car_configuration_id,
                car_modification_id: car.car_modification_id,
                car_complectation_id: car.car_complectation_id,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then(() => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("cars.car_update_title"),
              text: this.$t("cars.car_update_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.update_car")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveBookingState(state, id) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if ((state, id)) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${id}`,
              {
                bookings: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.bookings.findIndex(
                (element) => element.id === id
              );
              this.bookings[foundIndex].state = state;
              let foundIndex1 = this.active_bookings.findIndex(
                (element) => element.id === id
              );
              if (foundIndex1 >= 0) {
                this.active_bookings[foundIndex1].state = state;
              }
              let foundIndex2 = this.inactive_bookings.findIndex(
                (element) => element.id === id
              );
              if (foundIndex2 >= 0) {
                this.inactive_bookings[foundIndex2].state = state;
              }
              this.showSetBookingState = false;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.update_booking_state"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.update_booking_state"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.select_state_2"),
            text: this.error,
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteCarDoc(name) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/cars_doc_templates/${name}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                if (this.$i18n.locale == "ru") {
                  this.cars_doc_template_url =
                    "https://web.rentprog.ru/doc_example.docx";
                } else {
                  this.cars_doc_template_url =
                    "https://web.rentprog.com/doc_example_en.docx";
                }
                this.cars_doc_template_id = null;
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t("custom.deleted"),
                  text: this.$t("other.delete_file"),
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_file"))
              );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteCarEntityDoc(name) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/cars_entity_doc_templates/${name}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                if (this.$i18n.locale == "ru") {
                  this.cars_entity_doc_template_url =
                    "https://web.rentprog.ru/entity_doc_example.docx";
                } else {
                  this.cars_entity_doc_template_url =
                    "https://web.rentprog.com/entity_doc_example_en.docx";
                }
                this.cars_entity_doc_template_id = null;
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t("custom.deleted"),
                  text: this.$t("other.delete_file"),
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_file"))
              );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateCarState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if ((id, state)) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/cars/${id}`,
              {
                cars: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.car.state = state;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("cars.state_update"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.car_state"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addPrice() {
      let values = [];
      this.showAddPriceBtn = false;
      this.periods.forEach((period) => {
        values.push(0);
      });
      // для дефолтных цен
      values.push(0);
      let seasons_ids = [];
      this.car.prices.forEach((price) => {
        price.season_id;
      });
      this.car.prices.push({
        car_id: this.car.id,
        season_id: null,
        values: values,
      });
    },
    saveNewPrices() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        //  Check if all prices are filled
        let isFilled = true;
        Object.values(this.createPricesInput).forEach((value, index) => {
          // в последнем индексе всегда season_id поэтому без null
          if (value == 0 || value == "") {
            console.log("not filled", value);
            isFilled = false;
          }
        });
        if(isFilled) {
          this.isLoading = true;
          let index_periods = [];
          let prices_values = [];
          Object.keys(this.createPricesInput).forEach((key) => {
            if (key != "season_id") {
              index_periods.push(key);
            }
          });
          index_periods.forEach((index) => {
            prices_values.push(this.createPricesInput[index]);
          });

          this.axios
            .post(
              "/api/v1/prices",
              {
                prices: {
                  car_id: this.car.id,
                  season_id: this.createPricesInput.season_id
                    ? this.createPricesInput.season_id
                    : "",
                  values: prices_values,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.car.prices.pop();
              this.car.prices.push(response.data);
              this.createPricesInput = {};
              this.showAddPriceBtn = true;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("cars.create_prices_title"),
                text: this.$t("cars.create_prices_text"),
              });
            })
            .catch((error) => this.setError(error, this.$t("errors.save_prices")))
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "error",
            title: this.$t("errors.save_prices"),
            text: this.$t("errors.fill_all_prices"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getSeasonValue(season_id) {
      if (this.seasons.find((season) => season.id == season_id)) {
        let season = this.seasons.find((season) => season.id == season_id);
        return `${season.start_date} - ${season.end_date}`;
      } else if (season_id == null) {
        return this.$t("cars.main_prices");
      }
    },
    showNewPrice(price, index) {
      this.editedPrice.season_id = price.season_id;
      this.editedPrice.price_id = price.id;
      this.editedPrice.period = index;
      this.editedPrice.values = price.values;
      this.editedPrice.value = price.values[index];
      console.log(this.editedPrice);
      this.edit_price_dialog = true;
    },
    saveEditedPrice() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.editedPrice.value && this.editedPrice.value != "" && this.editedPrice.value != null && this.editedPrice.value != " " && typeof(this.editedPrice.value) == "number" && !isNaN(this.editedPrice.value)) {
          let self = this;
          this.isLoading = true;
          this.editedPrice.values.splice(
            this.editedPrice.period,
            1,
            this.editedPrice.value
          );
          this.axios
            .patch(
              `/api/v1/prices/${self.editedPrice.price_id}`,
              {
                prices: {
                  values: self.editedPrice.values,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.car.prices
                .find((price) => price.id == this.editedPrice.price_id)
                .values.splice(
                  self.editedPrice.period,
                  1,
                  self.editedPrice.value
                );
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("cars.update_price"),
                text: this.$t("cars.update_price_success"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.update_prices"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.update_prices"),
                text: this.error,
              });
            })
            .finally(
              () => (
                (this.editedPrice = {}),
                (this.edit_price_dialog = false),
                (this.isLoading = false)
              )
            );
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.add_price_value"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteItem(car) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("cars.delete_warning"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/cars/${car.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.$router.push({ path: `/cars` }).catch((err) => {});
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("cars.delete_success"),
                  "success"
                );
              })
              .catch((error) => {
                // check if error is 406
                if(error.response.status == 406) {
                  this.$swal({
                    showConfirmButton: true,
                    icon: "error",
                    title: this.$t("errors.delete_car"),
                    text: this.$t("errors.delete_car_has_bookings"),
                  });
                } else {
                  this.setError(error, this.$t("errors.delete_car"));
                }
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getAnaliticsDataForRange() {
      if (this.start_date && this.end_date) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(
            `/api/v1/car_analitics?id=${this.$route.params.id}&start_date=${this.start_date}&end_date=${this.end_date}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            if (response.data.income_rub || response.data.expences_rub) {
              this.analitics_data = response.data;
              (this.income_expences_chart_data = [
                [this.$t("custom.income"), this.$t("custom.expences")],
              ]),
                this.income_expences_chart_data.push([
                  this.$t("custom.income"),
                  this.analitics_data.income_rub,
                ]);
              this.income_expences_chart_data.push([
                this.$t("custom.expences"),
                this.analitics_data.expences_rub,
              ]);
              this.selected_group = [
                "Оплата аренды",
                "Ремонт",
                "Обслуживание",
                "Запчасти",
                "Страховка",
              ];
            } else {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "warning",
                title: this.$t("errors.not_enough_data"),
                text: this.$t("errors.not_enough_car_data"),
              });
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.load_counts"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`/api/v1/car_analitics?id=${this.$route.params.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data.income_rub || response.data.expences_rub) {
              this.analitics_data = response.data;
              (this.income_expences_chart_data = [
                [this.$t("custom.income"), this.$t("custom.expences")],
              ]),
                this.income_expences_chart_data.push([
                  this.$t("custom.income"),
                  this.analitics_data.income_rub,
                ]);
              this.income_expences_chart_data.push([
                this.$t("custom.expences"),
                this.analitics_data.expences_rub,
              ]);
              this.selected_group = [
                "Оплата аренды",
                "Ремонт",
                "Обслуживание",
                "Запчасти",
                "Страховка",
              ];
            } else {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "warning",
                title: this.$t("errors.not_enough_data"),
                text: this.$t("errors.not_enough_car_data"),
              });
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.load_counts"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
      }
      return group_text;
    },
    showApplicationToBooking() {
      if(this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.country == "Russia") {

        if (this.user_role != "guest" && this.user_role != "partner") {
          this.$swal({
            title: this.$t("agregator.send_application_to_agregator_title"),
            html: this.$t("agregator.send_application_to_agregator_text"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: this.$t("agregator.agreement_true"),
            cancelButtonText: this.$t("custom.no"),
            showCloseButton: true,
          }).then((result) => {
            if (result.value) {
              this.isLoading = true;
              this.axios
                .post(
                  `/api/v1/request_to_vseprokaty`,
                  {
                    company_id: this.$store.getters.getCompany.company.id,
                  },
                  {
                    headers: {
                      Authorization: this.$store.getters.getAuthToken,
                    },
                  }
                )
                .then((response) => {
                  this.isLoading = false;
                  this.$store.commit("setCompany", { company: response.data });
                  this.$swal(
                    this.$t("agregator.send_application_to_agregator_success"),
                    this.$t("agregator.send_application_to_agregator_success_text"),
                    "success"
                  );
                })
                .catch((error) =>
                  this.setError(
                    error,
                    this.$t("agregator.send_application_to_agregator_error")
                  )
                )
                .finally(() => (this.isLoading = false));
            }
          });
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.no_access"),
          });
        }
      } else {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          html: this.$t("agregator.only_russia"),
        });
      }
    },
    sendToModeration() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("agregator.send_to_moderation_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/vseprokaty_to_moderation`,
                {
                  car_id: this.car.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then(() => {
                this.car.ready_to_moderation = true;
                this.isLoading = false;
                this.$swal(
                  this.$t("custom.success"),
                  this.$t("agregator.send_to_moderation_success"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(
                  error,
                  this.$t("agregator.send_to_moderation_error")
                )
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    withdrawFromPublication() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("agregator.unpublish_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/vseprokaty_unpublish`,
                {
                  car_id: this.car.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then(() => {
                this.car.ready_to_moderation = false;
                this.car.in_agregator = false;
                this.isLoading = false;
                this.$swal(
                  this.$t("agregator.unpublished"),
                  this.$t("agregator.unpublished_text"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("agregator.unpublished_error"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveCarBookingSettingInsurance() {
      this.isLoading = true;
      this.axios
        .patch(
          `/api/v1/car_booking_setting/${this.$route.params.id}/insurance`,
          {
            insurance: this.booking_insurance,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.save_car"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    saveCarBookingSettingServices() {
      this.isLoading = true;
      this.axios
        .patch(
          `/api/v1/car_booking_setting/${this.$route.params.id}/services`,
          {
            services: this.booking_services,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.save_car"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    saveCarBookingSettingFastBooking() {
      this.isLoading = true;
      this.axios
        .patch(
          `/api/v1/car_booking_setting/${this.$route.params.id}/fast_booking`,
          {
            fast_booking: this.fast_booking,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.save_car"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    saveCarBookingSettingDescription() {
      this.isLoading = true;
      this.axios
        .patch(
          `/api/v1/car_booking_setting/${this.$route.params.id}/description`,
          {
            description: this.booking_description,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then(() => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: this.$t("custom.saved"),
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.save_car"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    updateTabsData() {
      let self = this;
      if (
        this.tabs == 5 &&
        (this.user_role == "superadmin" ||
          this.user_role == "admin" ||
          this.user_role == "partner" ||
          this.user_role == "guest")
      ) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`/api/v1/car_analitics?id=${this.$route.params.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data.income_rub || response.data.expences_rub) {
              this.analitics_data = response.data;
              (this.income_expences_chart_data = [
                [this.$t("custom.income"), this.$t("custom.expences")],
              ]),
                this.income_expences_chart_data.push([
                  this.$t("custom.income"),
                  this.analitics_data.income_rub,
                ]);
              this.income_expences_chart_data.push([
                this.$t("custom.expences"),
                this.analitics_data.expences_rub,
              ]);
              this.selected_group = [
                "Оплата аренды",
                "Ремонт",
                "Обслуживание",
                "Запчасти",
                "Страховка",
              ];
            } else {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "warning",
                title: this.$t("errors.not_enough_data"),
                text: this.$t("errors.not_enough_car_data"),
              });
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.load_counts"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.tabs == 6) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`/api/v1/cars/${this.car.id}/inspections`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.inspections = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.inspections_load"));
            console.log(error);
          });
        this.axios
          .get(`/api/v1/get_workers_names_with_ids`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.workers = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.employers_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.tabs == 4) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`/api/v1/cars_doc_templates/${this.$route.params.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data) {
              this.cars_doc_template_url = response.data.url;
              this.cars_doc_template_id = response.data.id;
            } else {
              if (this.$i18n.locale == "ru") {
                this.cars_doc_template_url =
                  "https://web.rentprog.ru/doc_example.docx";
              } else {
                this.cars_doc_template_url =
                  "https://web.rentprog.com/doc_example_en.docx";
              }
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.doc_template_load"));
            console.log(error);
          });

        this.axios
          .get(`/api/v1/cars_entity_doc_templates/${this.$route.params.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data) {
              this.cars_entity_doc_template_url = response.data.url;
              this.cars_entity_doc_template_id = response.data.id;
            } else {
              if (this.$i18n.locale == "ru") {
                this.cars_doc_template_url =
                  "https://web.rentprog.ru/entity_doc_example.docx";
              } else {
                this.cars_doc_template_url =
                  "https://web.rentprog.com/entity_doc_example_en.docx";
              }
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.doc_ur_template_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.tabs == 0) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/bookings_in_cars/${this.$route.params.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.bookings = response.data;
            function compare(a, b) {
              if (
                moment(a.end_date, "DD-MM-YYYY H:mm").isBefore(
                  moment(b.end_date, "DD-MM-YYYY H:mm")
                )
              ) {
                return 1;
              }
              if (
                moment(a.end_date, "DD-MM-YYYY H:mm").isSameOrAfter(
                  moment(b.end_date, "DD-MM-YYYY H:mm")
                )
              ) {
                return -1;
              }
              return 0;
            }
            this.bookings = this.bookings.sort(compare);
            function selectHighlight(booking) {
              if (booking.active) {
                return booking.in_rent ? "green" : "blue";
              } else {
                return "gray";
              }
            }
            this.bookings.forEach((booking) => {
              if (booking.active || booking.in_rent || booking.ride) {
                self.calendar_attributes.push({
                  highlight: {
                    start: {
                      fillMode: "outline",
                      color: selectHighlight(booking),
                    },
                    base: {
                      fillMode: "light",
                      color: selectHighlight(booking),
                    },
                    end: {
                      fillMode: "outline",
                      color: selectHighlight(booking),
                    },
                  },
                  content: "black",
                  dates: {
                    start: moment(
                      booking.start_date,
                      "DD-MM-YYYY H:mm"
                    ).toDate(),
                    end: moment(booking.end_date, "DD-MM-YYYY H:mm").toDate(),
                  },
                  popover: {
                    label: `${self.$t("custom.number")}${booking.id} ${
                      booking.start_date
                    } ${booking.location_start} - ${booking.end_date} ${
                      booking.location_end
                    } | ${booking.last_name} ${booking.first_name} ${
                      booking.middle_name
                    }`,
                    visibility: "hover",
                    hideIndicator: true,
                  },
                });
              }
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.bookings_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.tabs == 7) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/store_movements_in_car/${this.car.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.movements = response.data.movements;
            this.store_locations = response.data.locations;
            this.store_products = response.data.products;
            this.axios
              .get(`/api/v1/get_workers_names_with_ids`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.workers = response.data;
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.employers_load"));
                console.log(error);
              });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.store_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.tabs == 1) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/companies`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.branches = response.data;
            this.axios
            .get(`/api/v1/car_base_for_car/${this.car.id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.car_marks = response.data.car_marks;
              this.car_mark = response.data.car_mark;
              this.car_model = response.data.car_model;
              this.car_generation = response.data.car_generation;
              this.car_configuration = response.data.car_configuration;
              this.car_modification = response.data.car_modification;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.error"));
              console.log(error);
            })
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.branch_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.tabs == 5 && !this.checkAdmin()) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`/api/v1/cars_minus_counts?car_id=${this.$route.params.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.counts = [];
            response.data.data.forEach((count) => {
              self.expences_counts.push(count.attributes);
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.load_counts"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else if (this.tabs == 8) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/car_booking_setting/${this.$route.params.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.booking_insurance = response.data.insurance;
            this.booking_services = response.data.services;
            this.booking_description = response.data.description;
            this.fast_booking = response.data.fast_booking;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    removeService(index) {
      this.booking_services.splice(index, 1);
    },
    addService() {
      this.booking_services.push({
        id: this.booking_services.length + 1,
        name: null,
        price: null,
      });
    },
    findServiceName(id) {
      let service = this.booking_services.find((service) => service.id == id);
      if (service) {
        return service.name;
      } else {
        return "-";
      }
    },
    checkPriceInfo() {
      if (
        Object.keys(this.createPricesInput).length >
        this.periods.length + 1
      ) {
        return false;
      } else {
        return true;
      }
    },
    findStoreLocation(id) {
      let location = this.store_locations.find((location) => location.id == id);
      if (location) {
        return location.name;
      } else {
        return "-";
      }
    },
    findStoreProduct(id) {
      let product = this.store_products.find((product) => product.id == id);
      if (product) {
        return product.name;
      } else {
        return "-";
      }
    },
    findProductArticle(id) {
      let product = this.store_products.find((product) => product.id == id);
      if (product) {
        return product.article;
      } else {
        return "-";
      }
    },
    findProductSku(id) {
      let product = this.store_products.find((product) => product.id == id);
      if (product) {
        return product.sku;
      } else {
        return "-";
      }
    },
    filter_products_groups() {
      let filtered_products = this.store_products;
      // this.products.forEach((product) => {
      //   filtered_products.push(product);
      // });
      if (this.filter_store_product_group && !this.show_only_in_stock) {
        filtered_products = this.store_products.filter((product) => {
          return product.group
            .toLowerCase()
            .includes(this.filter_store_product_group.toLowerCase());
        });
      } else if (!this.filter_store_product_group && this.show_only_in_stock) {
        filtered_products = this.store_products.filter((product) => {
          return product.stock_balance > 0;
        });
      } else if (this.filter_store_product_group && this.show_only_in_stock) {
        filtered_products = this.store_products
          .filter((product) => {
            return product.stock_balance > 0;
          })
          .filter((product) => {
            return product.group
              .toLowerCase()
              .includes(this.filter_store_product_group.toLowerCase());
          });
      }
      if (this.filter_cars && this.filter_cars.length > 0) {
        return filtered_products.filter((product) => {
          return product.cars_ids.find((car_id) => {
            return this.filter_cars.includes(car_id);
          });
        });
      }
      return filtered_products;
    },
    formatDateBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM");
    },
    formatTimeBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("H:mm");
    },
    shortFormatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD-MM H:mm");
    },
    checkLateDate(date) {
      if (!moment().isSameOrBefore(moment(date, "DD-MM-YYYY H:mm"), "day")) {
        return "color: tomato;";
      }
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    setWorker(id) {
      if (id != null) {
        if (this.workers.find((worker) => worker.id == id)) {
          return this.workers.find((worker) => worker.id == id).name;
        }
      } else {
        return "-";
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    returnDate(item) {
      return moment(item, "YYYY-MM-DD").format("ll");
    },
    returnDateTime(item) {
      return moment(item).format("lll");
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    Gallery,
    GalleryBooking,
    GalleryCheckin,
    Uppy,
    UppyBooking,
    UppyCarsDoc,
    UppyCarsEntityDoc,
    GChart,
  },
};
</script>
<style lang="css">
  table.v-table thead td:not(:nth-child(1)),
  table.v-table tbody td:not(:nth-child(1)),
  table.v-table thead th:not(:nth-child(1)),
  table.v-table tbody th:not(:nth-child(1)),
  table.v-table thead td:first-child,
  table.v-table tbody td:first-child,
  table.v-table thead th:first-child,
  table.v-table tbody th:first-child {
    padding: 0 5px;
  }
</style>
