<template>
  <v-app id="inspire">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{
                  $t("users.forgot_password")
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model.trim="email"
                  label="Email"
                  required
                ></v-text-field>
                <v-flex class="text-center">
                  <v-btn @click="submit()" color="primary">{{
                    $t("users.recover_password")
                  }}</v-btn>
                  <div class="mt-2">
                    <router-link to="/signin">{{
                      $t("users.enter")
                    }}</router-link>
                    <br v-if="!isNative" />
                    <router-link v-if="!isNative" to="/signup">{{
                      $t("users.registration")
                    }}</router-link>
                  </div>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Capacitor } from "@capacitor/core";
export default {
  data() {
    return {
      email: null,
      error: "",
      notice: "",
      show1: false,
      isLoading: false,
    };
  },
  computed: {
    isNative() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ||
        Capacitor.getPlatform().toLowerCase() == "android"
        ? true
        : false;
    },
  },
  methods: {
    submit() {
      let self = this;
      this.isLoading = true;
      if (this.email) {
        this.axios
          .post("/api/v1/restore_password", {
            email: this.email,
          })
          .then(() => {
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: self.$t("users.send_request"),
              text: self.$t("users.send_instructions"),
            });
            self.isLoading = false;
            self.$router.push({ path: `/signin` }).catch((err) => {});
          })
          .catch((error) => {
            self.isLoading = false;
            if (
              error.response &&
              error.response.status &&
              error.response.status == 404
            ) {
              self.setError(error, self.$t("errors.pass_recover"));
              self.$swal({
                showConfirmButton: true,
                icon: "error",
                title: self.$t("errors.no_find_email"),
                text: error,
              });
              console.log(error);
            } else {
              self.setError(error, self.$t("errors.pass_recover"));
              self.$swal({
                showConfirmButton: true,
                icon: "error",
                title: self.$t("errors.pass_recover_long"),
                text: error,
              });
              console.log(error);
            }
          })
          .finally(() => (this.isLoading = false));
      } else {
        self.$swal({
          icon: "warning",
          title: this.$t("errors.enter_email"),
        });
      }
    },
    setError(error, text) {
      this.$rollbar.error(text, error);
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
