<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title>
        <h2>{{ $t("check_lists.check_lists") }}</h2>
      </v-card-title>
      <v-card-text>
        <v-flex md12>
          <v-card>
            <v-toolbar
              text
              :color="$vuetify.theme.dark ? '' : 'white'"
              :height="isMobile ? 160 : ''"
            >
              <v-flex
                :class="
                  isMobile
                    ? 'text-center'
                    : 'd-flex align-center justify-center'
                "
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('custom.search')"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-select
                  v-model="selected_workers"
                  clearable
                  :items="workers"
                  item-text="name"
                  item-value="id"
                  multiple
                  :label="$t('check_lists.workers')"
                  class="mt-5"
                ></v-select>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  color="success"
                  class="mr-2"
                  @click="showNewList = true"
                  >{{ $t("check_lists.new_task") }}</v-btn
                >
                <v-icon
                  color="warning"
                  @click="showCopyList = true"
                  v-tooltip="{
                    content: $t('check_lists.copies'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                >
                  mdi-content-copy
                </v-icon>
                <v-btn
                  v-if="!isIos"
                  icon
                  :href="`https://rentprog.${
                    $root.$i18n.locale == 'ru' ? 'ru' : 'com'
                  }/categories/9/guides/24`"
                  target="_blank"
                >
                  <v-icon>mdi-help-circle</v-icon>
                </v-btn>
              </v-flex>
            </v-toolbar>
            <v-data-table
              :headers="headers"
              :items="filtred_check_lists"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :search="search"
              :items-per-page="20"
              :sort-by="['sort']"
              mobile-breakpoint="100"
              :class="!isMobile ? '' : 'is-mobile'"
              :sort-desc="[true]"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center" nowrap>
                    <v-icon class="mr-2" @click="editItem(props.item)">
                      mdi-pencil-outline
                    </v-icon>
                    <v-icon @click="deleteItem(props.item)">
                      mdi-delete-forever
                    </v-icon>
                  </td>
                  <td class="text-center">
                    <v-icon @click="updateSort(props.item, 'up')">
                      mdi-chevron-up
                    </v-icon>
                    {{ props.item.sort }}
                    <v-icon @click="updateSort(props.item, 'down')">
                      mdi-chevron-down
                    </v-icon>
                  </td>
                  <td class="text-center">
                    {{ props.item.description }}
                  </td>
                  <td class="text-center">
                    {{ setUser(props.item.worker_id) }}
                  </td>
                  <td class="text-center">
                    <v-icon v-if="props.item.state" color="green"
                      >mdi-check</v-icon
                    >
                    <v-icon v-else color="grey">mdi-minus</v-icon>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showNewList"
      :retain-focus="false"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("check_lists.new_task") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-container>
              <v-layout row wrap>
                <v-flex xs12 md3>
                  <v-autocomplete
                    v-model="check_list.worker_id"
                    :return-object="false"
                    item-text="name"
                    item-value="id"
                    :items="workers"
                    :label="$t('check_lists.select_worker')"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md9>
                  <v-textarea
                    v-model.number="check_list.description"
                    clearable
                    auto-grow
                    rows="1"
                    :label="$t('custom.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (showNewList = false),
                (check_list = { worker_id: null, description: '' })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCheckList()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showEdit"
      :retain-focus="false"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("check_lists.edit_task") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-container>
              <v-layout row wrap>
                <v-flex xs12 md3>
                  <v-autocomplete
                    v-model="check_list.worker_id"
                    :return-object="false"
                    item-text="name"
                    item-value="id"
                    :items="workers"
                    :label="$t('check_lists.select_worker')"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md9>
                  <v-textarea
                    v-model.number="check_list.description"
                    clearable
                    auto-grow
                    rows="1"
                    :label="$t('custom.description')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (showEdit = false),
                (check_list = { worker_id: null, description: '' })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="updateCheckList()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCopyList"
      :retain-focus="false"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("check_lists.copy_task") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-container>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-autocomplete
                    v-model="copy_lists.donor"
                    :return-object="false"
                    item-text="name"
                    item-value="id"
                    :items="workers"
                    :label="$t('check_lists.copy_from')"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 md6>
                  <v-autocomplete
                    v-model="copy_lists.recipient"
                    :return-object="false"
                    item-text="name"
                    item-value="id"
                    :items="workers"
                    :label="$t('check_lists.copy_to')"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (showCopyList = false),
                (copy_lists = { donor: null, recipient: null })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="copyCheckList()">{{
            $t("check_lists.copy")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
export default {
  data() {
    return {
      check_lists: [],
      filtred_check_lists: [],
      check_list: {
        worker_id: null,
        description: "",
      },
      copy_lists: {
        donor: null,
        recipient: null,
      },
      workers: [],
      selected_workers: [],
      search: "",
      headers: [
        {
          text: this.$t("check_lists.control"),
          align: "center",
          sortable: false,
        },
        { text: this.$t("check_lists.sort"), align: "center", sortable: false },
        {
          text: this.$t("check_lists.task"),
          align: "center",
          value: "description",
        },
        {
          text: this.$t("check_lists.worker"),
          align: "center",
          value: "worker_id",
        },
        { text: this.$t("custom.state"), align: "center", value: "state" },
      ],
      showNewList: false,
      showEdit: false,
      showCopyList: false,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/all_lists", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.check_lists = response.data;
          this.filtred_check_lists = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.check_lists_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/get_workers_names_with_ids`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.workers = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("workers.employers_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    selected_workers() {
      if (this.selected_workers && this.selected_workers.length > 0) {
        this.filtred_check_lists = this.check_lists.filter((list) => {
          return this.selected_workers.includes(list.worker_id);
        });
      } else {
        this.filtred_check_lists = this.check_lists;
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    saveCheckList() {
      if (this.user_role != "guest") {
        let self = this;
        if (self.check_list.worker_id && self.check_list.description != "") {
          this.isLoading = true;
          this.axios
            .post(
              `/api/v1/check_lists`,
              {
                check_lists: {
                  worker_id: self.check_list.worker_id,
                  user_id: self.check_list.worker_id,
                  description: self.check_list.description,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.check_lists.unshift(response.data);
              this.showNewList = false;
              this.check_list = {
                worker_id: null,
                description: "",
              };
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("check_lists.task_added"),
                text: this.$t("check_lists.task_added_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.task_add"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.task_add"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
            text: this.$t("check_lists.task_added_warn"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editItem(check_list) {
      this.check_list = check_list;
      this.showEdit = true;
    },
    updateCheckList() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/check_lists/${self.check_list.id}`,
            {
              check_lists: {
                worker_id: self.check_list.worker_id,
                description: self.check_list.description,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.check_lists.findIndex(
              (element) => element.id === response.data.id
            );
            this.check_lists.splice(foundIndex, 1, response.data);
            this.showEdit = false;
            this.check_list = {
              worker_id: null,
              description: "",
            };
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("check_lists.task_updated"),
              text: this.$t("check_lists.task_updated_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.task_update"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.task_update"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    copyCheckList() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/copy_check_lists`,
            {
              copy_lists: this.copy_lists,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            location.reload();
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.task_add"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.task_add"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteItem(check_list) {
      if (this.user_role != "guest") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/check_lists/${check_list.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.check_lists.splice(
                  this.check_lists.indexOf(check_list),
                  1
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.check_list_remove"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("check_lists.task_deleted"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateSort(item, chevron) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        let sort = item.sort;
        if (chevron == "up") {
          sort += 1;
        } else {
          sort -= 1;
        }

        this.axios
          .patch(
            `/api/v1/check_lists/${item.id}`,
            {
              check_lists: {
                sort: sort,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.filtred_check_lists.findIndex(
              (element) => element.id === response.data.id
            );
            this.filtred_check_lists.splice(foundIndex, 1, response.data);
            let foundIndex2 = this.check_lists.findIndex(
              (element) => element.id === response.data.id
            );
            this.check_lists.splice(foundIndex2, 1, response.data);
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.check_list_sort"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.check_list_sort"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setUser(id) {
      if (id != null) {
        if (this.workers.find((worker) => worker.id == id)) {
          return this.workers.find((worker) => worker.id == id).name;
        }
      } else {
        return "-";
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
