<template>
  <div v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <v-card class="text-center">
      <v-card-title v-if="!isMobile" justify="center">
        <h2>{{ $t("custom.schedule") }}</h2>
        <!-- <v-spacer></v-spacer>
                        <v-btn class="mb-2 mr-4" color="success" :to="{ name: 'NewBooking'}">Новая бронь</v-btn> -->
        <!-- <v-spacer></v-spacer> -->
        <v-btn class="mb-1" text color="success" @click="days_count += 1"
          >+1</v-btn
        >
        <v-btn class="mb-1" text color="success" @click="days_count += 7"
          >+7</v-btn
        >
        <v-btn class="mb-1" text color="success" @click="days_count += 30"
          >+30</v-btn
        >
        <v-btn class="mb-1" text color="success" @click="days_count -= 1"
          >-1</v-btn
        >
        <v-btn class="mb-1" text color="success" @click="days_count -= 7"
          >-7</v-btn
        >
        <v-btn class="mb-1" text color="success" @click="days_count -= 30"
          >-30</v-btn
        >
      </v-card-title>
      <v-card-title v-else justify="center">
        <v-flex md12
          ><h3>{{ $t("custom.schedule") }}</h3></v-flex
        >
        <v-flex>
          <v-btn
            small
            class="mb-1"
            text
            color="success"
            @click="days_count += 1"
            >+1</v-btn
          >
          <v-btn
            small
            class="mb-1"
            text
            color="success"
            @click="days_count += 7"
            >+7</v-btn
          >
          <v-btn
            small
            class="mb-1"
            text
            color="success"
            @click="days_count += 30"
            >+30</v-btn
          >
          <v-btn
            small
            class="mb-1"
            text
            color="success"
            @click="days_count -= 1"
            >-1</v-btn
          >
          <v-btn
            small
            class="mb-1"
            text
            color="success"
            @click="days_count -= 7"
            >-7</v-btn
          >
          <v-btn
            small
            class="mb-1"
            text
            color="success"
            @click="days_count -= 30"
            >-30</v-btn
          >
        </v-flex>
      </v-card-title>
      <v-card-text class="text-center">
        <v-flex md12 class="text-center align-center my-auto">
          <v-simple-table class="calendarTable mx-auto">
            <thead>
              <tr class="sticky-th">
                <th :class="$vuetify.theme.dark ? 'border_white black white--text' : 'grey--text border_grey'"></th>
                <th :class="$vuetify.theme.dark ? 'border_white black white--text' : 'grey--text border_grey'"></th>
                <th
                  :class="$vuetify.theme.dark ? 'border_white black white--text' : 'grey--text border_grey'"
                  v-for="n in number_days"
                  :key="n.index"
                >
                  {{ month(n + days_count) }}
                </th>
              </tr>
              <tr class="sticky-th">
                <th :class="$vuetify.theme.dark ? 'border_white black white--text' : 'grey--text border_grey'">#</th>
                <th :class="$vuetify.theme.dark ? 'border_white black white--text' : 'black--text border_grey'">
                  {{ $t("employers.employee") }}
                </th>
                <th
                  :class="$vuetify.theme.dark ? 'px-1 border_white black white--text' : 'px-1 black--text border_grey'"
                  v-for="n in number_days"
                  :key="n.index"
                >
                  {{ dates(n + days_count) }}
                </th>
              </tr>
            </thead>
            <tr
              v-for="user in users"
              :key="user.index"
              :class="user.active ? '' : 'grey--text'"
            >
              <td
                style="
                  border-color: grey;
                  border-style: solid;
                  margin: 2px;
                  border-width: thin;
                "
                class="px-1"
              >
                {{ user.id }}
              </td>
              <td style="border: 1px solid grey" class="px-1" nowrap>
                {{ user.name ? user.name : user.email }}
              </td>
              <td
                v-for="n in number_days"
                :key="n.index"
                style="border: 1px solid grey"
                :class="
                  findSchedules(user.id, n - 2 + days_count)
                    ? selectColor(
                        findSchedules(user.id, n - 2 + days_count).category
                      )
                    : ''
                "
              >
                <v-menu absolute offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="background-color: transparent !important"
                      block
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          findSchedules(user.id, n - 2 + days_count).id,
                          'work',
                          n - 2 + days_count
                        )
                      "
                    >
                      <v-list-item-title
                        ><span class="success--text">{{
                          $t("schedules.work")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          findSchedules(user.id, n - 2 + days_count).id,
                          'duty',
                          n - 2 + days_count
                        )
                      "
                    >
                      <v-list-item-title
                        @click="
                          changeSchedule(
                            user.id,
                            findSchedules(user.id, n - 2 + days_count).id,
                            'duty',
                            n - 2 + days_count
                          )
                        "
                        ><span class="warning--text">{{
                          $t("schedules.duty")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          findSchedules(user.id, n - 2 + days_count).id,
                          'duty_night',
                          n - 2 + days_count
                        )
                      "
                    >
                      <v-list-item-title
                        ><span class="deep-purple--text">{{
                          $t("schedules.duty_night")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          findSchedules(user.id, n - 2 + days_count).id,
                          'vacation',
                          n - 2 + days_count
                        )
                      "
                    >
                      <v-list-item-title
                        ><span class="info--text">{{
                          $t("schedules.vacation")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          findSchedules(user.id, n - 2 + days_count).id,
                          'vacation_no_salary',
                          n - 2 + days_count
                        )
                      "
                    >
                      <v-list-item-title
                        ><span class="yellow--text darken-1">{{
                          $t("schedules.vacation_no_salary")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          findSchedules(user.id, n - 2 + days_count).id,
                          'sick',
                          n - 2 + days_count
                        )
                      "
                    >
                      <v-list-item-title>{{
                        $t("schedules.sick_day")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title
                        @click="
                          removeSchedule(
                            user.id,
                            findSchedules(user.id, n - 2 + days_count).id
                          )
                        "
                        >{{ $t("schedules.free_day") }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <!-- <td
                style="border: 1px solid grey"
                v-for="n in number_days"
                :key="n.index"
                :class="datesCheckColor(n - 2 + days_count, user.schedules)"
              >
                <v-menu absolute offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="background-color: transparent !important"
                      block
                      text
                      v-bind="attrs"
                      v-on="on"
                    >
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(user.id, n - 2 + days_count, 'work')
                      "
                    >
                      <v-list-item-title
                        ><span class="success--text">{{
                          $t("schedules.work")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(user.id, n - 2 + days_count, 'duty')
                      "
                    >
                      <v-list-item-title
                        @click="
                          changeSchedule(user.id, n - 2 + days_count, 'duty')
                        "
                        ><span class="warning--text">{{
                          $t("schedules.duty")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          n - 2 + days_count,
                          'duty_night'
                        )
                      "
                    >
                      <v-list-item-title
                        ><span class="deep-purple--text">{{
                          $t("schedules.duty_night")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(user.id, n - 2 + days_count, 'vacation')
                      "
                    >
                      <v-list-item-title
                        ><span class="info--text">{{
                          $t("schedules.vacation")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(
                          user.id,
                          n - 2 + days_count,
                          'vacation_no_salary'
                        )
                      "
                    >
                      <v-list-item-title
                        ><span class="yellow--text darken-1">{{
                          $t("schedules.vacation_no_salary")
                        }}</span></v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      @click="
                        changeSchedule(user.id, n - 2 + days_count, 'sick')
                      "
                    >
                      <v-list-item-title>{{
                        $t("schedules.sick_day")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item link>
                      <v-list-item-title
                        @click="
                          removeSchedule(user.id, n - 2 + days_count, 'output')
                        "
                        >{{ $t("schedules.free_day") }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td> -->
            </tr>
          </v-simple-table>
          <v-flex class="d-md-flex">
            <v-flex
              md4
              v-if="!isLoading"
              class="greenCell my-2 pt-1 pt-1"
              style="height: 36px"
              ><span class="white--text">{{
                $t("schedules.work")
              }}</span></v-flex
            >
            <v-flex
              md4
              v-if="!isLoading"
              class="warnCell my-2 pt-1"
              style="height: 36px"
              ><span class="white--text">{{
                $t("schedules.duty")
              }}</span></v-flex
            >
            <v-flex
              md4
              v-if="!isLoading"
              class="purpleCell my-2 pt-1"
              style="height: 36px"
              ><span class="white--text">{{
                $t("schedules.duty_night")
              }}</span></v-flex
            >
            <v-flex
              md4
              sm12
              xs12
              v-if="!isLoading"
              class="blueCell my-2 pt-1"
              style="height: 36px"
              ><span class="white--text">{{
                $t("schedules.vacation")
              }}</span></v-flex
            >
            <v-flex
              md4
              v-if="!isLoading"
              class="yellowCell my-2 pt-1"
              style="height: 36px"
              ><span class="white--text">{{
                $t("schedules.vacation_no_salary")
              }}</span></v-flex
            >
            <v-flex
              md4
              v-if="!isLoading"
              class="grayCell my-2 pt-1"
              style="height: 36px"
              ><span class="white--text">{{
                $t("schedules.sick_day")
              }}</span></v-flex
            >
            <v-flex md4 v-if="!isLoading" class="my-2 pt-1" style="height: 36px"
              ><span class="secondery--text">{{
                $t("schedules.free_day")
              }}</span></v-flex
            >
          </v-flex>
        </v-flex>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
// moment.locale("ru");
/* eslint-disable */
export default {
  data() {
    return {
      users: [],
      schedules: [],
      events: [],
      days: "",
      dialog: false,
      days_count: -2,
      number_days: 30,
      headers: [],
      items: [],
      dialog: false,
      isLoading: false,
      error: "",
      sort_class: null,
      sort_cars: null,
      select: [],
      filtered_bookings: [],
      showMenu: false,
      x: 0,
      y: 0,
      isMobile: false,
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/users_for_schedule", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.users = response.data.users;
          this.schedules = response.data.schedules;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.workers_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    newSchedule(user_id, day, category) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.checkAdminManager()) {
          this.isLoading = true;
          let self = this;
          this.axios
            .post(
              `/api/v1/work_schedules`,
              {
                work_schedules: {
                  user_id: user_id,
                  date: moment()
                    .add(day + 2, "days")
                    .format("DD-MM-YYYY"),
                  category: category,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.schedules.push(response.data);
              // this.schedules.push({
              //   user_id: user_id,
              //   date: moment()
              //     .add(day + 2, "days")
              //     .format("DD-MM-YYYY"),
              //   category: category,
              //   id: response.data.id,
              // });
              // this.$swal({
              //   toast: true,
              //   position: "top-end",
              //   showConfirmButton: false,
              //   timer: 5000,
              //   icon: "success",
              //   title: this.$t('schedules.updated_title'),
              //   text: this.$t('schedules.updated_text')
              // });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.edit_schedule"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.edit_schedule"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.not_permitted"),
            text: this.$t("errors.not_permitted_schedule"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    changeSchedule(user_id, schedule_id, category, day) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.checkAdminManager()) {
          if (schedule_id) {
            this.isLoading = true;
            this.axios
              .patch(
                `/api/v1/work_schedules/${schedule_id}`,
                {
                  work_schedules: {
                    category: category,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                this.isLoading = false;
                let foundIndex = this.schedules.findIndex(
                  (element) => element.id === response.data.id
                );
                this.schedules.splice(foundIndex, 1, response.data);
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.edit_schedule"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "error",
                  title: this.$t("errors.edit_schedule"),
                  text: this.error,
                });
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.newSchedule(user_id, day, category);
          }
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.not_permitted"),
            text: this.$t("errors.not_permitted_schedule"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    removeSchedule(user_id, schedule_id) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (this.checkAdminManager()) {
          let user = this.users.find((user) => user.id == user_id);
          if (user) {
            if (schedule_id) {
              this.isLoading = true;
              this.axios
                .delete(`/api/v1/work_schedules/${schedule_id}`, {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                })
                .then((response) => {
                  this.isLoading = false;
                  let foundIndex = this.schedules.findIndex(
                    (element) => element.id === response.data.id
                  );
                  this.schedules.splice(foundIndex, 1);
                  // this.$swal({
                  //   toast: true,
                  //   position: "top-end",
                  //   showConfirmButton: false,
                  //   timer: 5000,
                  //   icon: "success",
                  //   title: this.$t('schedules.updated_title'),
                  //   text: this.$t('schedules.updated_text')
                  // });
                })
                .catch((error) => {
                  this.setError(error, this.$t("errors.edit_schedule"));
                  this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 5000,
                    icon: "error",
                    title: this.$t("errors.edit_schedule"),
                    text: this.error,
                  });
                })
                .finally(() => (this.isLoading = false));
            }
          }
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.not_permitted"),
            text: this.$t("errors.not_permitted_schedule"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    findSchedules(user_id, day) {
      let user = this.users.find((user) => user.id == user_id);
      if (user) {
        let find_schedule = this.schedules.find((schedule) => {
          return (
            schedule.user_id == user_id &&
            schedule.date ==
              moment()
                .add(day + 2, "days")
                .format("DD-MM-YYYY")
          );
        });
        if (find_schedule != undefined) {
          return { category: find_schedule.category, id: find_schedule.id };
        } else {
          return { category: null, id: null };
        }
      }
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    dates(day) {
      return moment().add(day, "days").format("DD dd");
    },
    month(day) {
      return moment().add(day, "days").format("MM");
    },
    datesCheck(day, schedules) {
      let result = false;
      let now = moment().format("DD-MM-YYYY");
      let check_date = moment(now, "DD-MM-YYYY").add(day, "days");
      schedules.forEach((schedule) => {
        if (
          check_date.isSame(
            moment(schedule.date, "DD-MM-YYYY").subtract(2, "days"),
            "day"
          )
        ) {
          result = true;
        }
      });
      return result;
    },
    datesCheckColor(day, schedules) {
      let result;
      let now = moment().format("DD-MM-YYYY");
      let check_date = moment(now, "DD-MM-YYYY").add(day, "days");
      schedules.forEach((schedule) => {
        if (
          check_date.isSame(
            moment(schedule.date, "DD-MM-YYYY").subtract(2, "days"),
            "day"
          )
        ) {
          if (schedule.category == "work") {
            result = "greenCell";
          } else if (schedule.category == "duty") {
            result = "warnCell";
          } else if (schedule.category == "duty_night") {
            result = "purpleCell";
          } else if (schedule.category == "vacation") {
            result = "blueCell";
          } else if (schedule.category == "vacation_no_salary") {
            result = "yellowCell";
          } else if (schedule.category == "sick") {
            result = "grayCell";
          } else {
            result = "";
          }
        }
      });
      return result;
    },
    selectColor(category) {
      if (category == "work") {
        return "greenCell";
      } else if (category == "duty") {
        return "warnCell";
      } else if (category == "duty_night") {
        return "purpleCell";
      } else if (category == "vacation") {
        return "blueCell";
      } else if (category == "vacation_no_salary") {
        return "yellowCell";
      } else if (category == "sick") {
        return "grayCell";
      } else {
        return "";
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("LL H:mm");
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    // VueCal
  },
};
</script>
<style>
.calendarTable {
  border-collapse: collapse; /*убираем пустые промежутки между ячейками*/
  position: relative;
  border: 1px solid grey; /*устанавливаем для таблицы внешнюю границу серого цвета толщиной 1px*/
}

.calendarTable tr:hover {
  background-color: lightgray;
}
/* td, th {
  position: relative;
}
td:hover::after,
th:hover::after {
  content: "";
  position: absolute;
  background-color: lightgray;
  left: 0;
  top: -1000px;
  height: 2000px;
  width: 100%;
  /* z-index: -2; */
/* }  */

.sticky-th th {
  /* background: white; */
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.greenCell {
  background-color: #4caf50;
  justify-content: center;
  align-items: center;
}
.warnCell {
  background-color: #e2850a;
  justify-content: center;
  align-items: center;
}
.purpleCell {
  background-color: #673ab7;
  justify-content: center;
  align-items: center;
}
.redCell {
  background-color: #ee2e0c;
  justify-content: center;
  align-items: center;
}
.blueCell {
  background-color: #1976d2;
  justify-content: center;
  align-items: center;
}
.yellowCell {
  background-color: #d2c619;
  justify-content: center;
  align-items: center;
}
.grayCell {
  background-color: #b7b8b4;
  justify-content: center;
  align-items: center;
}
.vuecal__event.booking .vuecal__event-time {
  display: none;
  align-items: center;
}
.vuecal__no-event {
  display: none;
}
.border_grey {
  border: 1px solid grey !important;
}
.border_white {
  border: 1px solid white !important;
  border-color: white !important;
}
</style>
