<template>
  <v-app id="inspire" v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-main v-if="booking == null" class="mt-auto">
      <v-alert v-if="error" :value="true" color="error">
        {{ error }}
      </v-alert>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t("users.enter") }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent="recaptcha()">
                  <v-text-field
                    v-model.trim="last_name"
                    :label="$t('bookings.lastname')"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model.trim="booking_id"
                    :label="$t('bookings.booking_number')"
                    required
                  ></v-text-field>
                  <v-flex class="text-center">
                    <v-btn
                      type="submit"
                      block
                      color="primary"
                      >{{ $t("users.enter") }}</v-btn
                    >
                  </v-flex>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-card v-else>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
        <v-toolbar-title class="text-h5">
          {{ $t("bookings.booking_number") }} {{ booking.id }} - {{ translateBookingState(booking.state) }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-flex md12 :class="isMobile ? 'mx-3' : 'd-flex mx-3'">
          <v-flex xs12 md4 class="start-tour-booking-2">
            <div v-if="booking.vseprokaty_id">
              {{ $t("agregator.agregator_id") }}: {{ booking.vseprokaty_id }}
            </div>
            <div v-if="booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: +
            </div>
            <div v-if="booking.vseprokaty_id && !booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: -
            </div>
            <div
              v-else
              v-tooltip="{
                content: $t('tooltips.creator'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("custom.created") }}:
              {{ formatCreatedAt(booking.created_at) }}
            </div>
            <div>
              {{ $t("custom.car_long") }}: {{ booking.car_name }}
            </div>
            <div>{{ $t("bookings.start_date") }}: {{ booking.start_date }}</div>
            <div>{{ $t("bookings.end_date") }}: {{ booking.end_date }}</div>
            <div>{{ $t("custom.days") }}: {{ booking.days }}</div>
            <div v-if="booking.additional_hours > 0">
              {{ $t("bookings.add_hours") }}: {{ booking.additional_hours }}
            </div>
            <div>
              {{ $t("bookings.checkin") }}: {{ booking.location_start }}
            </div>
            <div>{{ $t("bookings.checkout") }}: {{ booking.location_end }}</div>
            <div v-if="!booking.technical">
              {{ $t("bookings.current_price") }}: {{ Math.round(booking.rental_cost / booking.days) }}
            </div>
            <div v-if="!booking.technical && booking.sale_cash">
              {{ $t("bookings.sale") }}: {{ booking.sale_cash }}
            </div>
            <div v-if="!booking.technical && booking.sale">
              {{ $t("bookings.sale") }}: {{ booking.sale }}%
            </div>
            <v-divider
              v-if="
                booking.chair ||
                booking.chair_less_1_year ||
                booking.booster ||
                booking.navigator ||
                booking.mp3 ||
                booking.charger ||
                booking.wifi_router
              "
              class="mt-2 mb-2"
            ></v-divider>
            <h3
              v-if="
                booking.chair ||
                booking.chair_less_1_year ||
                booking.booster ||
                booking.navigator ||
                booking.mp3 ||
                booking.charger ||
                booking.wifi_router
              "
            >
              {{ $t("bookings.add_equipment") }}
            </h3>
            <div v-if="booking.chair">
              {{ $t("bookings.baby_chair") }} - {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.chair_less_1_year">
              {{ $t("bookings.small_baby_chair") }} -
              {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.booster">
              {{ $t("bookings.booster") }} - {{ booking.boosters_quantity }}
            </div>
            <div v-if="booking.navigator">{{ $t("bookings.navigator") }}</div>
            <div v-if="booking.mp3">{{ $t("bookings.mp3") }}</div>
            <div v-if="booking.charger">{{ $t("bookings.charger") }}</div>
            <div v-if="booking.wifi_router">{{ $t("bookings.wifi") }}</div>
            <div v-if="booking.other_equipment">
              {{ booking.other_equipment }}
            </div>
            <h3
              v-if="
                booking.kasko ||
                booking.super_kasko ||
                booking.theft ||
                booking.no_franchise
              "
            >
              {{ $t("bookings.add_insurance") }}
            </h3>
            <div v-if="booking.kasko">{{ $t("bookings.kasko") }}</div>
            <div v-if="booking.super_kasko">
              {{ $t("bookings.super_kasko") }}
            </div>
            <div v-if="booking.theft">
              {{ $t("bookings.theft_insurance") }}
            </div>
            <div v-if="booking.no_franchise">
              {{ $t("bookings.no_franchise") }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3 v-if="!booking.technical">{{ $t("bookings.calculate") }}</h3>
            <div v-if="!booking.technical && booking.aggr_commission == 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ booking.rental_cost
              }}
            </div>
            <div v-if="!booking.technical && booking.aggr_commission > 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ booking.rental_cost
              }} - {{booking.aggr_commission}} = {{(booking.rental_cost - booking.aggr_commission)}}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.hours_cost > 0 && !booking.technical"
            >
              {{ $t("bookings.hours_cost") }}: {{ booking.hours_cost
              }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.hours_cost_end > 0 && !booking.technical"
            >
              {{ $t("bookings.hours_cost_end") }}: {{ booking.hours_cost_end
              }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.delivery > 0 && !booking.technical"
            >
              {{ $t("bookings.delivery") }}: {{ booking.delivery
              }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.delivery_end > 0 && !booking.technical"
            >
              {{ $t("bookings.checkout") }}: {{ booking.delivery_end
              }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.equipment > 0 && !booking.technical"
            >
              {{ $t("bookings.equipment") }}: {{ booking.equipment
              }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.clean_payment > 0 && !booking.technical"
            >
              {{ $t("bookings.clean") }}: {{ booking.clean_payment
              }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.damage > 0 && !booking.technical"
            >
              {{ $t("bookings.damages") }}: {{ booking.damage }}
            </div>
            <div class="font-weight-bold" v-if="booking.gas > 0">
              {{ $t("bookings.for_gas") }}: {{ booking.gas }}
            </div>
            <div class="font-weight-bold" v-if="booking.add_drivers_cost > 0">
              {{ $t("bookings.for_add_drivers") }}: {{ booking.add_drivers_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.insurance > 0">
              {{ $t("bookings.for_add_insurance") }}: {{ booking.insurance
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.fine > 0">
              {{ $t("bookings.for_fines") }}: {{ booking.fine }}
            </div>
            <div class="font-weight-bold" v-if="booking.other > 0">
              {{ $t("bookings.for_other") }}: {{ booking.other }}
            </div>
            <div class="font-weight-bold" v-if="booking.other_end > 0">
              {{ $t("bookings.for_other_end") }}: {{ booking.other_end
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.mileage_cost > 0">
              {{ $t("bookings.mileage_cost") }}: {{ booking.mileage_cost
              }}
            </div>
            <div class="font-weight-bold" v-if="booking.aggr_commission > 0">
              <!-- <v-icon>mdi-alpha-a-circle-outline</v-icon> -->
              {{ $t("bookings.aggr_commission") }}: {{ booking.aggr_commission }}
            </div>
            <div class="font-weight-bold" v-if="!booking.technical">
              {{ $t("bookings.deposit") }}: {{ booking.deposit }}
            </div>
            <div class="subtitle-1 font-weight-bold" v-if="!booking.technical">
              {{ $t("custom.total") }}:
              {{
                booking.rental_cost +
                booking.delivery +
                booking.delivery_end +
                booking.equipment +
                booking.insurance +
                booking.clean_payment +
                booking.add_drivers_cost +
                booking.hours_cost +
                booking.mileage_cost +
                booking.damage +
                booking.gas +
                booking.fine +
                booking.other +
                booking.other_end +
                booking.hours_cost_end +
                booking.deposit
              }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div
              :class="
                'subtitle-1 font-weight-bold ' +
                `${
                  calculatePayments(booking) == $t('bookings.paid')
                    ? 'success--text'
                    : ''
                }`
              "
              v-if="!booking.technical"
            >
              {{ $t("bookings.paid") }}: {{ calculatePaymentsSum(booking)
              }}
            </div>
            <v-divider
              v-if="
                calculateRentPaymentsSum(booking) > 0 &&
                booking.rental_cost > 0 &&
                booking.days > 0
              "
              class="mt-2 mb-2"
            ></v-divider>
            <v-progress-linear
              v-if="
                calculateRentPaymentsSum(booking) > 0 &&
                booking.rental_cost > 0 &&
                booking.days > 0
              "
              :value="progressPercent"
              :color="progressPercent == 100 ? 'success' : 'warning'"
              rounded
              height="20"
            >
              <strong class="white--text"
                >{{ paidDays }} {{ $t("other.from") }} {{ booking.days }}
                {{ $t("bookings.days_low") }}</strong
              >
            </v-progress-linear>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div
              v-if="
                booking.description &&
                booking.description != '' &&
                booking.description != ' '
              "
            >
              <h3>{{ $t("bookings.description") }}</h3>
              <p>{{ booking.description }}</p>
            </div>
            <div v-if="booking.in_rent || booking.ride">
              {{ $t("bookings.clean_start") }}:
              <v-icon color="green" v-if="booking.clean_start">
                mdi-plus
              </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div v-if="booking.ride">
              {{ $t("bookings.clean_end") }}:
              <v-icon color="green" v-if="booking.clean_end"> mdi-plus </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div
              v-if="
                (booking.in_rent || booking.ride) && !booking.gas_start_full
              "
            >
              {{ $t("bookings.gas_start") }}: {{ booking.gas_start }}
            </div>
            <div
              v-if="(booking.in_rent || booking.ride) && booking.gas_start_full"
            >
              {{ $t("bookings.gas_start_full") }}
            </div>
            <div v-if="!booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_short") }}: {{ booking.gas_end }}
            </div>
            <div v-if="booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_full") }}
            </div>
            <div
              v-if="
                (booking.in_rent || booking.ride) && booking.start_mileage > 0
              "
            >
              {{ $t("bookings.mileage_start") }}: {{ booking.start_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.end_mileage > 0">
              {{ $t("bookings.mileage_end") }}: {{ booking.end_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.booking_mileage") }}:
              {{ booking.booking_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.average_mileage") }}:
              {{ Math.trunc(booking.booking_mileage / booking.days) }}
              {{ $t("bookings.km_day") }}
            </div>
          </v-flex>
          <div xs12 md1 class="vl"></div>
          <v-flex xs12 md4>
            <h2
              v-if="booking.counts && booking.counts.length == 0"
              class="text-center"
            >
              {{ $t("bookings.no_counts") }}
            </h2>
            <v-expansion-panels
              v-if="booking.counts && booking.counts.length != 0"
              v-model="paymentsPanel"
              class="start-tour-booking-3"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("bookings.counts") }}
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("custom.number") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.date") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.sum") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.type") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.group") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="count in booking.counts"
                          :key="count.index"
                          :class="count.operation ? '' : 'red--text'"
                        >
                          <td class="text-center">
                            {{ count.id }}
                          </td>
                          <td class="text-center">
                            {{ formatCreatedAt(count.created_at) }}
                          </td>
                          <td class="text-center">
                            {{ count.operation ? "" : "-" }}{{ count.sum
                            }}
                          </td>
                          <td class="text-center" v-if="count.cash">
                            <v-icon
                              class="text-center"
                              color="success"
                              v-tooltip="{
                                content: $t('money.cash'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-cash
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.cashless">
                            <v-icon
                              class="text-center"
                              color="warning"
                              v-tooltip="{
                                content: $t('money.terminal'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-contactless-payment-circle-outline
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.entity">
                            <v-icon
                              class="text-center"
                              color="secondery"
                              v-tooltip="{
                                content: $t('money.to_entity'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-bank
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.cash_card">
                            <v-icon
                              class="text-center"
                              color="info"
                              v-tooltip="{
                                content: $t('money.card_to_card'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-credit-card-check-outline
                            </v-icon>
                          </td>
                          <td class="text-center">
                            {{ translateCategory(count.group) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- Продления -->
            <v-expansion-panels
              v-if="booking.prolongs && booking.prolongs.length != 0"
              v-model="prolongsPanel"
              class="mt-2"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("bookings.prolongs") }}
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ $t("bookings.date_prolong") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.days") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.price") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.total") }}
                          </th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(prolong, index) in booking.prolongs"
                          :key="prolong.index"
                        >
                          <td class="text-center">
                            {{ formatPrologDate(prolong.last_date) }}
                          </td>
                          <td class="text-center">
                            {{ prolong.prolong_days }}
                          </td>
                          <td class="text-center">
                            {{ prolong.new_price }}
                          </td>
                          <td class="text-center">
                            {{ prolong.new_price * prolong.prolong_days
                            }}
                          </td>
                          <td class="text-center">

                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- Подарки -->
            <v-expansion-panels
              v-if="booking.gifts && booking.gifts.length != 0"
              v-model="giftsPanel"
              class="mt-2"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("gifts.title") }}
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ $t("gifts.created") }}
                          </th>
                          <th class="text-center">
                            {{ $t("gifts.type") }}
                          </th>
                          <th class="text-center">
                            {{ $t("gifts.count_short") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.price") }}
                          </th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="gift in booking.gifts" :key="gift.index">
                          <td class="text-center">
                            {{ formatPrologDate(gift.created_at) }}
                          </td>
                          <td class="text-center">
                            {{
                              gift.gift_type == 0
                                ? $t("gifts.days")
                                : $t("gifts.hours")
                            }}
                          </td>
                          <td class="text-center">
                            {{ gift.count }}
                          </td>
                          <td class="text-center">
                            {{ gift.price }}
                          </td>
                          <td class="text-center">
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
          <div xs12 md1 class="vl"></div>
          <v-flex xs12 md3 class="mx-auto start-tour-booking-4">
            <v-row justify="space-around" class="mb-3 mt-2">
              <v-btn
                :small="isMobile ? true : false"
                outlined
                color="primary"
                v-if="
                  !booking.in_rent &&
                  booking.counts &&
                  booking.counts.length === 0 &&
                  !booking.technical
                "
                >{{ $t("bookings.pay") }}</v-btn
              >
              <v-btn
                :small="isMobile ? true : false"
                :color="selectColorPaid(booking)"
                v-if="
                  (booking.counts && booking.counts.length > 0) ||
                  (booking.in_rent && !booking.technical)
                "
                v-tooltip="{
                  content: `${
                    calculatePayments(booking) != $t('bookings.paid')
                      ? $t('bookings.part_paid')
                      : $t('bookings.full_paid')
                  }`,
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
                >{{ calculatePayments(booking) }}</v-btn
              >
            </v-row>
            <hr
              class="mb-2 mt-3"
              v-if="
                booking.booking_photos_data &&
                booking.booking_photos_data.length > 0
              "
            />
            <booking-photo-gallery :photos="booking.booking_photos_data"></booking-photo-gallery>
            <hr
              class="mb-2 mt-3"
              v-if="
                booking.generated_documents &&
                booking.generated_documents.length
              "
            />
            <h3
              class="headline text-center"
              v-if="
                booking.generated_documents &&
                booking.generated_documents.length
              "
            >
              {{ $t("bookings.signed_documents") }}
            </h3>
            <v-row
              class="mt-1"
              justify="space-around"
              v-if="!booking.technical"
            >
              <v-simple-table
                dense
                v-if="
                  booking.generated_documents &&
                  booking.generated_documents.length
                "
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t("custom.created") }}
                      </th>
                      <th class="text-center">
                        {{ $t("bookings.template") }}
                      </th>
                      <th class="text-center">
                        {{ $t("bookings.control") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="document in booking.generated_documents"
                      :key="document.index"
                    >
                      <td class="text-center">
                        {{ formatCreatedAt(document.created_at) }}
                      </td>
                      <td class="text-center">
                        {{ document.name }}
                      </td>
                      <td class="text-center">
                        <v-icon
                          color="secondery"
                          class="mr-2"
                          @click="downloadSignedDocBottom(document.link)"
                          >mdi-file-download</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-flex>
        </v-flex>
      </v-card-text>
    </v-card>
  </v-app>
</template>
<script>
import BookingPhotoGallery from "./BookingPhotoGallery";
import { Capacitor } from "@capacitor/core";
import { saveAs } from "file-saver";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      booking: null,
      last_name: null,
      booking_id: null,
      prolongsPanel: 0,
      paymentsPanel: 0,
      giftsPanel: 0,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$route.query.last_name && this.$route.query.booking_id) {
      this.last_name = this.$route.query.last_name;
      this.booking_id = this.$route.query.booking_id;
      this.recaptcha();
    }
  },
  computed: {
    progressPercent() {
      return (
        (this.calculateRentPaymentsSum(this.booking) /
          (this.booking.rental_cost / this.paidDays) /
          this.paidDays) *
        100
      );
    },
    paidDays() {
      let days =
        Math.round(
          this.calculateRentPaymentsSum(this.booking) /
            (this.booking.rental_cost / this.booking.days)
        ) || this.booking.days;
      if (this.gifts && this.gifts.length > 0) {
        let gift_days = 0;
        this.gifts.forEach((gift) => {
          if (gift.gift_type == 0) {
            gift_days += gift.count;
          }
        });
        let fact_days = days - gift_days;
        days =
          Math.round(
            (this.calculateRentPaymentsSum(this.booking) /
              (this.booking.rental_cost / fact_days)) *
              100
          ) / 100;
      }
      return days;
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    async recaptcha() {
      await this.$recaptchaLoaded()
        .then(() => this.findBooking())
        .catch((error) => {
          this.setError(error, this.$t("errors.auth"));
          console.log(error);
        });
    },
    async findBooking() {
      if(this.booking_id == '' || this.last_name == '' || this.booking_id == null || this.last_name == null || isNaN(this.booking_id)) {
        if (isNaN(this.booking_id)) {
          this.$swal({
            position: "center",
            showConfirmButton: true,
            icon: "error",
            title: this.$t("errors.error"),
            text: this.$t("custom.booking_number_is_not_number"),
          });
          return;
        }
        this.$swal({
          position: "center",
          showConfirmButton: true,
          icon: "error",
          title: this.$t("errors.error"),
          text: this.$t("custom.no_data"),
        });
        return;
      }
      this.isLoading = true;
      await this.axios
        .post(`/api/v1/find_booking_for_client`, {
          booking_id: this.booking_id,
          last_name: this.last_name,
        })
        .then((response) => {
          if (response.data.error) {
            this.isLoading = false;
            this.$swal({
              position: "center",
              showConfirmButton: true,
              icon: "error",
              title: this.$t("errors.error"),
              text: this.$t("custom.no_data_in_table"),
            });
            return;
          }
          if(this.last_name == null || this.last_name == '' || this.booking_id == null || this.booking_id == '') {
            this.$router.replace({query: { last_name: this.last_name, booking_id: this.booking_id } })
          }
          this.booking = response.data;
          console.log(this.booking)
          this.isLoading = false;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.auth"));
          console.log(error);
        });
    },
    translateBookingState(state) {
      switch (state) {
        case "Новая":
          return this.$t("bookings_states.new");
        case "В обработке":
          return this.$t("bookings_states.in_work");
        case "Недозвон":
          return this.$t("bookings_states.abandoned");
        case "Отказ клиента":
          return this.$t("bookings_states.decline");
        case "Нет машин":
          return this.$t("bookings_states.no_cars");
        case "Ожидает ответа клиента":
          return this.$t("bookings_states.wait_answer");
        case "Активная":
          return this.$t("bookings_states.active");
        case "Отмена":
          return this.$t("bookings_states.cancel");
        case "Отъездила":
          return this.$t("bookings_states.rided");
        case "Вернуть залог":
          return this.$t("bookings_states.return_deposit");
        case "Подтверждена":
          return this.$t("bookings_states.accepted");
        case "Не подтверждена":
          return this.$t("bookings_states.not_accepted");
        case "Другое":
          return this.$t("bookings_states.other");
        default:
          return this.$t("bookings_states.in_work");
      }
    },
    calculatePayments(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          if (count.operation) {
            paid += count.sum;
          } else {
            paid -= count.sum;
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return self.$t("bookings.paid");
        } else {
          return paid - booking_sum >= 0
            ? `+ ${paid - booking_sum}`
            : ` ${paid - booking_sum}`;
        }
      }
    },
    calculatePaymentsSum(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          if (count.operation) {
            paid += count.sum;
          } else {
            paid -= count.sum;
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        return paid;
      }
    },
    calculateRentPaymentsSum(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        booking.counts.forEach((count) => {
          if (count.group == "Оплата аренды") {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        return paid;
      }
    },
    selectColorPaid(booking) {
      if (booking != undefined) {
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          if (count.operation) {
            paid += count.sum;
          } else {
            paid -= count.sum;
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return "success";
        } else if (paid - booking_sum > 0) {
          return "warning";
        } else if (paid - booking_sum < 0) {
          return "error";
        }
      }
    },
    downloadSignedDocBottom(link) {
      saveAs(`https://storage.yandexcloud.net/rentprog/${link}`);
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
      }
      return group_text;
    },
    formatCreatedAt(date) {
      if (date) {
        return moment.parseZone(date).format("DD-MM-YY H:mm");
      } else {
        return "-";
      }
    },
    formatPrologDate(date) {
      return moment.utc(date).format("DD MMM H:mm");
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.isLoading = false;
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    BookingPhotoGallery,
  }
};
</script>
