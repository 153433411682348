<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <!-- <v-flex md12>
        <h2>{{ $t('custom.analitics') }}</h2>
      </v-flex> -->
    <v-container class="text-center">
      <!-- <v-layout> -->

      <v-toolbar
        text
        :color="$vuetify.theme.dark ? '' : 'white'"
        class="align-center"
      >
        <v-toolbar-title
          >{{ $t("analitics.common_workers") }} {{ $t("custom.number")
          }}{{ report.id }} {{ start_date_formatted }} -
          {{ end_date_formatted }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="with_not_active"
          :label="$t('analitics.show_unactive')"
          class="mt-5"
        ></v-checkbox>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/8/guides/25`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- По компании -->
      <v-flex v-if="report.state == 'created'" md12 class="d-inline-flex">
        <v-data-table
          :class="!isMobile ? 'mt-5' : 'mt-5 is-mobile'"
          dense
          :headers="headers"
          :items="filtered_report"
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          :search="search"
          :items-per-page="50"
          :sort-by="['created_at']"
          mobile-breakpoint="100"
          :sort-desc="[true]"
          :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100, -1],
          }"
        >
          <template slot="item" slot-scope="props">
            <tr
              :class="
                props.item.worker.active ? '' : 'grey--text text--lighten-1'
              "
            >
              <td nowrap class="text-center">{{ props.item.worker.name }}</td>
              <td nowrap class="text-center">{{ props.item.work_days }}</td>
              <td nowrap class="text-center">{{ props.item.holidays }}</td>
              <td nowrap class="text-center">{{ props.item.sick_days }}</td>
              <td nowrap class="text-center">{{ props.item.checkins }}</td>
              <td nowrap class="text-center">{{ props.item.checkouts }}</td>
              <td nowrap class="text-center">{{ props.item.salary }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex v-else md12 class="text-center mt-10">
        {{ $t("analitics.report_in_progress") }}
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <br />
        <v-btn @click="$router.go()">{{ $t("custom.update") }}</v-btn>
      </v-flex>
    </v-container>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { Capacitor } from "@capacitor/core";
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      report: {},
      headers: [
        { text: this.$t("check_lists.worker"), value: "name", align: "center" },
        {
          text: this.$t("analitics.work_days_for_worker"),
          value: "work_days",
          align: "center",
        },
        {
          text: this.$t("analitics.holidays_for_worker"),
          value: "holidays",
          align: "center",
        },
        {
          text: this.$t("analitics.sick_days_for_worker"),
          value: "sick_days",
          align: "center",
        },
        {
          text: this.$t("analitics.checkins"),
          value: "profit",
          align: "checkins",
        },
        {
          text: this.$t("analitics.checkouts"),
          value: "profit",
          align: "checkouts",
        },
        { text: this.$t("analitics.salary"), value: "profit", align: "salary" },
      ],
      search: "",
      with_not_active: false,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isGuest)
    ) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/workers_reports/${this.$route.params.query}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.report = response.data;
          if (this.report.state == "created") {
            this.report = response.data;
            // this.filtered_report.workers_data =
            //   response.data.workers_data.filter((item) => {
            //     return item.worker.active;
            //   });
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_report_load"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$router.push({ path: `/dashboard` }).catch((err) => {});
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.only_admins"),
        text: this.error,
      });
    }
  },
  computed: {
    filtered_report() {
      let filtered_data = this.report.workers_data.filter((item) => {
        return item.worker.active == true;
      });
      if (this.with_not_active) {
        filtered_data = this.report.workers_data;
      }
      return filtered_data;
    },
    start_date_formatted() {
      return moment(this.report.start_date).format("DD.MM.YYYY");
    },
    end_date_formatted() {
      return moment(this.report.end_date).format("DD.MM.YYYY");
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
