<template>
  <v-container grid-list-md text-xs-center>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title>
        <h2>{{ $t("cars.add_car") }}</h2>
      </v-card-title>
      <v-tabs v-model="tabs" fixed-tabs centered id="registration-step-28">
        <v-tab>
          {{ $t("custom.characteristics") }}
        </v-tab>
        <v-tab>
          {{ $t("custom.service") }}
        </v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tabs" touchless>
        <!-- Характеристики -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md4>
                    <v-autocomplete
                      v-model="car_mark"
                      item-text="name"
                      item-value="id"
                      :loading="isLoadingCarMarks"
                      :search-input="car_mark_search"
                      dense
                      hide-details
                      outlined
                      clearable
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('car_base.select_mark')"
                      :items="car_marks"
                      return-object
                    >
                  </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-autocomplete
                      v-model="car_model"
                      item-text="name"
                      item-value="id"
                      :loading="isLoadingCarModels"
                      :search-input="car_model_search"
                      dense
                      hide-details
                      outlined
                      clearable
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('car_base.select_model')"
                      :items="car_models"
                      return-object
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-autocomplete
                    v-model="car_generation"
                    item-value="id"
                    :loading="isLoadingCarGenerations"
                    :search-input="car_generation_search"
                    dense
                    hide-details
                    clearable
                    outlined
                    flat
                    hide-no-data
                    hide-details
                    :label="$t('car_base.select_generation')"
                    :items="car_generations"
                    return-object
                  >
                    <template slot="selection" slot-scope="data">
                      {{data.item.name ? data.item.name : '' }} {{ data.item.year_start }} - {{ data.item.year_end ? data.item.year_end : $t('car_base.now_time_short') }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{data.item.name ? data.item.name : '' }} {{ data.item.year_start }} - {{ data.item.year_end ? data.item.year_end : $t('car_base.now_time_short') }}
                    </template>
                  </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-autocomplete
                    v-model="car_configuration"
                    item-value="id"
                    :loading="isLoadingCarConfigurations"
                    :search-input="car_configuration_search"
                    dense
                    hide-details
                    outlined
                    clearable
                    flat
                    hide-no-data
                    hide-details
                    :label="$t('car_base.select_configuration')"
                    :items="car_configurations"
                    return-object
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.body_type }} {{ data.item.base_id }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.body_type }} {{ data.item.base_id }}
                    </template>
                  </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-autocomplete
                    v-model="car_modification"
                    item-value="id"
                    :search-input="car_modification_search"
                    dense
                    hide-details
                    outlined
                    clearable
                    flat
                    hide-no-data
                    hide-details
                    :label="$t('car_base.select_modification')"
                    :items="car_modifications"
                    return-object
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.specifications['volume-litres'] }}{{$t('car_base.liters_short')}} {{ data.item.specifications['transmission'] }} {{ data.item.specifications['horse-power'] }}{{$t('car_base.horsepower')}} {{ data.item.specifications['engine-type'] }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ data.item.specifications['volume-litres'] }}{{$t('car_base.liters_short')}} {{ data.item.specifications['transmission'] }} {{ data.item.specifications['horse-power'] }}{{$t('car_base.horsepower')}} {{ data.item.specifications['engine-type'] }}
                    </template>
                  </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm6 md3 id="registration-step-29">
                    <v-text-field
                      v-model="car.car_name"
                      :label="$t('cars.model')"
                      :placeholder="$t('cars.model_plc')"
                      dense
                      hide-details
                      outlined
                      required
                      v-tooltip="{
                        content: $t('tooltips.car_model'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3 id="registration-step-30">
                    <v-text-field
                      v-model="car.code"
                      :label="$t('cars.code')"
                      dense
                      hide-details
                      outlined
                      required
                      v-tooltip="{
                        content: $t('tooltips.car_code'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3 id="registration-step-31">
                    <v-text-field
                      v-model.number="car.sort"
                      :label="$t('cars.sort_number')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: this.$t('tooltips.car_sort'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3 id="registration-step-32">
                    <v-text-field
                      v-model="car.number"
                      :label="$t('cars.gov_number')"
                      :placeholder="$t('cars.gov_number_pcl')"
                      dense
                      hide-details
                      outlined
                      required
                      v-tooltip="{
                        content: $t('tooltips.car_number'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.vin"
                      :label="$t('cars.vin')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.vin'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.body_number"
                      :label="$t('cars.body_number')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.pts"
                      :label="$t('cars.reg_number')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.car_passport'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.registration_certificate"
                      :label="$t('cars.sts_number')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.car_sts'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      v-model="car.car_type"
                      :items="car_types"
                      dense
                      hide-details
                      item-text="text"
                      item-value="value"
                      :label="$t('cars.body_type')"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3 id="registration-step-33">
                    <v-select
                      v-model="car.car_class"
                      :items="car_classes"
                      item-text="text"
                      item-value="value"
                      dense
                      hide-details
                      :label="$t('cars.class')"
                      outlined
                    ></v-select>
                  </v-flex>

                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.color"
                      :label="$t('cars.color')"
                      hide-details
                      dense
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.car_color'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.number="car.year"
                      :label="$t('cars.year_created')"
                      type="number"
                      hide-details
                      dense
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      v-model="car.transmission"
                      :items="gears"
                      item-text="text"
                      item-value="value"
                      dense
                      hide-details
                      :label="$t('cars.gear_big')"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      v-model="car.drive_unit"
                      :items="drive_units"
                      item-text="text"
                      item-value="value"
                      dense
                      hide-details
                      :label="$t('cars.drive_unit')"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      v-model="car.fuel"
                      :items="fuels"
                      dense
                      hide-details
                      item-text="text"
                      item-value="value"
                      :label="$t('cars.fuel')"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.number="car.tank_value"
                      :label="$t('cars.tank_value')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.tank_value'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.number="car.gas_mileage"
                      :label="$t('cars.gas_mileage')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      v-model="car.steering_side"
                      :items="steering_side"
                      dense
                      hide-details
                      :label="$t('cars.steering_side')"
                      outlined
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.number="car.number_doors"
                      :label="$t('cars.number_doors')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.number="car.number_seats"
                      :label="$t('cars.number_seats')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.number_seats'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.number="car.engine_capacity"
                      :label="$t('cars.engine_capacity')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.number="car.engine_power"
                      :label="$t('cars.engine_power')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.tire_size"
                      :label="$t('cars.tire_size')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md3 id="registration-step-34">
                    <v-text-field
                      v-model="car.purchase_date"
                      type="date"
                      :label="$t('cars.purchase_date')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.purchase_date'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3 id="registration-step-35">
                    <v-text-field
                      v-model.number="car.purchase_price"
                      :label="$t('cars.purchase_price')"
                      dense
                      hide-details
                      outlined
                      v-tooltip="{
                        content: $t('tooltips.purchase_price'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.franchise"
                      :label="$t('cars.franchise')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.max_fine"
                      :label="$t('cars.max_fine')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.repair_cost"
                      :label="$t('cars.repair_cost')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.beacon_emai"
                      :label="$t('cars.beacon_emai')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.beacon_phone"
                      :label="$t('cars.beacon_phone')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="car.beacon_pin"
                      :label="$t('cars.beacon_pin')"
                      dense
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-flex>
                  <v-flex col-12> </v-flex>
                  <v-flex xs12 sm6 md2>
                    <v-checkbox
                      v-model="car.is_electropackage"
                      :label="$t('cars.is_electropackage')"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <v-checkbox
                      :label="$t('cars.is_air')"
                      v-model="car.is_air"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <v-checkbox
                      :label="$t('cars.climate_control')"
                      v-model="car.climate_control"
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md12 class="mt-3">
                    <v-textarea
                      v-model="car.description"
                      :label="$t('cars.description')"
                      rows="2"
                      outlined
                    ></v-textarea>
                  </v-flex>
                  <v-flex md12 class="mt-3">
                    <v-expansion-panels v-model="showDopPanel">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h2 class="mb-0">
                            {{ $t("cars.extra_characteristics") }}
                          </h2>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-container>
                            <v-layout row wrap>
                              <v-flex xs12 sm6 md3>
                                <v-select
                                  v-model="car.interior"
                                  :items="interiors"
                                  item-text="text"
                                  item-value="value"
                                  dense
                                  hide-details
                                  :label="$t('cars.interior')"
                                  outlined
                                ></v-select>
                              </v-flex>
                              <v-flex xs12 sm6 md3>
                                <v-select
                                  v-model="car.roof"
                                  :items="roofs"
                                  item-text="text"
                                  item-value="value"
                                  dense
                                  hide-details
                                  :label="$t('cars.roof')"
                                  outlined
                                ></v-select>
                              </v-flex>
                              <v-flex xs12 sm6 md3>
                                <v-text-field
                                  v-model.number="car.trunk_volume"
                                  :label="$t('cars.trunk_volume')"
                                  dense
                                  hide-details
                                  outlined
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 md3>
                                <v-text-field
                                  v-model.number="car.airbags"
                                  :label="$t('cars.airbags')"
                                  dense
                                  hide-details
                                  outlined
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 md3>
                                <v-text-field
                                  v-model.number="car.window_lifters"
                                  :label="$t('cars.window_lifters')"
                                  dense
                                  hide-details
                                  outlined
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 md3>
                                <v-text-field
                                  v-model.number="car.custom_field_1"
                                  :label="$t('cars.custom_field_1')"
                                  dense
                                  hide-details
                                  outlined
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 md3>
                                <v-text-field
                                  v-model.number="car.custom_field_2"
                                  :label="$t('cars.custom_field_2')"
                                  dense
                                  hide-details
                                  outlined
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 md3>
                                <v-text-field
                                  v-model.number="car.custom_field_3"
                                  :label="$t('cars.custom_field_3')"
                                  dense
                                  hide-details
                                  outlined
                                ></v-text-field>
                              </v-flex>
                              <v-flex col-12> </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.rain_sensor"
                                  :label="$t('cars.rain_sensor')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.heated_windshield"
                                  :label="$t('cars.heated_windshield')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.folding_seats"
                                  :label="$t('cars.folding_seats')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.video_system"
                                  :label="$t('cars.video_system')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.tv_system"
                                  :label="$t('cars.tv_system')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.cd_system"
                                  :label="$t('cars.cd_system')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.usb_system"
                                  :label="$t('cars.usb_system')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.audio_system"
                                  :label="$t('cars.audio_system')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.wheel_adjustment"
                                  :label="$t('cars.wheel_adjustment')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.wheel_adjustment_full"
                                  :label="$t('cars.wheel_adjustment_full')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.parktronic_camera"
                                  :label="$t('cars.parktronic_camera')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.parktronic_back"
                                  :label="$t('cars.parktronic_back')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.parktronic"
                                  :label="$t('cars.parktronic')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.heated_seats_front"
                                  :label="$t('cars.heated_seats_front')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.heated_seats"
                                  :label="$t('cars.heated_seats')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  v-model="car.abs"
                                  :label="$t('cars.abs')"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  :label="$t('cars.ebd')"
                                  v-model="car.ebd"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                              <v-flex xs12 sm6 md2>
                                <v-checkbox
                                  :label="$t('cars.esp')"
                                  v-model="car.esp"
                                  dense
                                  hide-details
                                ></v-checkbox>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions id="registration-step-36">
              <v-spacer></v-spacer>
              <v-btn color="success" @click="submit()">{{
                $t("custom.save")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <!-- Обслуживание -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-container>
                <v-card-text>
                  <v-layout row wrap>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model.number="car.start_mileage"
                        :label="$t('cars.start_mileage')"
                        dense
                        hide-details
                        outlined
                        v-tooltip="{
                          content: $t('tooltips.start_mileage'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model.number="car.mileage"
                        :label="$t('cars.mileage')"
                        dense
                        hide-details
                        outlined
                        v-tooltip="{
                          content: $t('tooltips.current_mileage'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="car.insurance"
                        :label="$t('cars.insurance_to')"
                        type="date"
                        dense
                        hide-details
                        outlined
                        v-tooltip="{
                          content: $t('tooltips.insurance_to'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm6 md6>
                      <v-text-field
                        v-model="car.diagnostic_card"
                        :label="$t('cars.diagnostic_card_to')"
                        type="date"
                        dense
                        hide-details
                        outlined
                        v-tooltip="{
                          content: $t('tooltips.diagnostic_card_to'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.oil_engine"
                        :label="$t('cars.oil_engine')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.oil_engine_km"
                        :label="$t('cars.oil_engine_km')"
                        dense
                        hide-details
                        outlined
                        v-tooltip="{
                          content: $t('tooltips.oil_engine_km'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.oil_engine_date"
                        :label="$t('cars.oil_date')"
                        type="date"
                        dense
                        hide-details
                        outlined
                        v-tooltip="{
                          content: $t('tooltips.oil_engine_date'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.oil_transmission"
                        :label="$t('cars.oil_transmission')"
                        dense
                        hide-details
                        outlined
                        v-tooltip="{
                          content: $t('tooltips.gear_oil_date'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.oil_transmission_km"
                        :label="$t('cars.oil_transmission_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.oil_transmission_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.grm"
                        :label="$t('cars.grm')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.grm_km"
                        :label="$t('cars.grm_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.grm_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.antifreeze"
                        :label="$t('cars.antifreeze')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.antifreeze_km"
                        :label="$t('cars.antifreeze_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.antifreeze_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.brake_fluid"
                        :label="$t('cars.brake_fluid')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.brake_fluid_km"
                        :label="$t('cars.brake_fluid_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.brake_fluid_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.candle"
                        :label="$t('cars.candle')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.candle_km"
                        :label="$t('cars.candle_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.candle_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.power_steering"
                        :label="$t('cars.power_steering')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.power_steering_km"
                        :label="$t('cars.power_steering_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.power_steering_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.salon_filter_renew"
                        :label="$t('cars.salon_filter_renew')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.salon_filter_renew_km"
                        :label="$t('cars.salon_filter_renew_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.salon_filter_renew_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.akb_renew"
                        :label="$t('cars.akb_renew')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.akb_renew_km"
                        :label="$t('cars.akb_renew_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.akb_renew_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.fuel_filter_renew"
                        :label="$t('cars.fuel_filter_renew')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model.number="car.fuel_filter_renew_km"
                        :label="$t('cars.fuel_filter_renew_km')"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-text-field
                        v-model="car.fuel_filter_renew_date"
                        :label="$t('cars.date_km')"
                        type="date"
                        dense
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-divider></v-divider>
                  </v-layout>
                </v-card-text>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="submit()">{{
                $t("custom.save")
              }}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      car: {
        car_name: null,
        code: null,
        sort: 0,
        number: null,
        vin: null,
        body_number: null,
        pts: null,
        registration_certificate: null,
        year: null,
        transmission: null,
        fuel: null,
        tank_value: 50,
        number_doors: 5,
        engine_capacity: null,
        is_electropackage: false,
        car_type: null,
        car_class: null,
        is_air: false,
        price_hour: null,
        deposit: null,
        insurance: null,
        oil_engine: null,
        oil_transmission: null,
        oil_engine_date: null,
        oil_engine_km: null,
        oil_transmission_km: null,
        oil_transmission_date: null,
        grm: null,
        grm_km: null,
        grm_date: null,
        antifreeze: null,
        antifreeze_km: null,
        antifreeze_date: null,
        brake_fluid: null,
        brake_fluid_km: null,
        brake_fluid_date: null,
        candle: null,
        candle_km: null,
        candle_date: null,
        power_steering: null,
        power_steering_km: null,
        power_steering_date: null,
        diagnostic_card: null,
        mileage: null,
        salon_filter_renew: null,
        salon_filter_renew_km: null,
        salon_filter_renew_date: null,
        akb_renew: null,
        akb_renew_km: null,
        akb_renew_date: null,
        fuel_filter_renew: null,
        fuel_filter_renew_km: null,
        fuel_filter_renew_date: null,
        purchase_price: null,
        purchase_date: null,
        sale_price: null,
        sale_date: null,
        tire_size: null,
        number_seats: 5,
        drive_unit: "Передний",
        engine_power: null,
        airbags: null,
        roof: "Обычная",
        gas_mileage: null,
        steering_side: "Левая",
        interior: "Ткань",
        abs: false,
        ebd: false,
        esp: false,
        window_lifters: 4,
        trunk_volume: 0,
        description: null,
      },
      car_marks: [],
      car_mark: null,
      isLoadingCarMarks: false,
      car_mark_search: null,
      car_models: [],
      car_model: null,
      isLoadingCarModels: false,
      car_model_search: null,
      car_generations: [],
      car_generation: null,
      isLoadingCarGenerations: false,
      car_generation_search: null,
      car_configurations: [],
      car_configuration: null,
      isLoadingCarConfigurations: false,
      car_configuration_search: null,
      car_modifications: [],
      car_modification: null,
      isLoadingCarModifications: false,
      car_modification_search: null,
      tabs: 0,
      periods: [],
      seasons: [],
      drive_units: [
        { text: this.$t("drive_units.front"), value: "Передний" },
        { text: this.$t("drive_units.back"), value: "Задний" },
        { text: this.$t("drive_units.full"), value: "Полный" },
      ],
      car_classes: [
        { text: this.$t("car_classes.economy"), value: "Эконом" },
        { text: this.$t("car_classes.middle"), value: "Средний" },
        { text: this.$t("car_classes.business"), value: "Бизнес" },
        { text: this.$t("car_classes.commercial"), value: "Коммерческий" },
      ],
      car_types: [
        { text: this.$t("car_types.micro"), value: "Микро" },
        { text: this.$t("car_types.hatchback"), value: "Хэтчбек" },
        { text: this.$t("car_types.sedan"), value: "Седан" },
        { text: this.$t("car_types.universal"), value: "Универсал" },
        { text: this.$t("car_types.minivan"), value: "Минивен" },
        { text: this.$t("car_types.coupe"), value: "Купе" },
        { text: this.$t("car_types.crossover"), value: "Кроссовер" },
        { text: this.$t("car_types.suv"), value: "Внедорожник" },
        { text: this.$t("car_types.pickup"), value: "Пикап" },
        { text: this.$t("car_types.limousine"), value: "Лимузин" },
        { text: this.$t("car_types.van"), value: "Фургон" },
        { text: this.$t("car_types.cabriolet"), value: "Кабриолет" },
        { text: this.$t("car_types.bus"), value: "Микроавтобус" },
        { text: this.$t("car_types.campervan"), value: "Автодом" },
        { text: this.$t("car_types.light_van"), value: "Легковой фургон" },
        { text: this.$t("car_types.rodster"), value: "Родстер" },
        { text: this.$t("car_types.sportcar"), value: "Спорткар" },
      ],
      fuels: [
        { text: this.$t("fuels.gas"), value: "Бензин" },
        { text: this.$t("fuels.gas_92"), value: "Бензин 92" },
        { text: this.$t("fuels.gas_95"), value: "Бензин 95" },
        { text: this.$t("fuels.gas_98"), value: "Бензин 98" },
        { text: this.$t("fuels.gas_100"), value: "Бензин 100" },
        { text: this.$t("fuels.disel"), value: "Дизель" },
        { text: this.$t("fuels.gas_natural"), value: "Газ" },
        { text: this.$t("fuels.gas_gasoline"), value: "Бензин/Газ" },
        { text: this.$t("fuels.electricity"), value: "Электричество" },
        { text: this.$t("fuels.hybrid"), value: "Гибрид" },
        { text: this.$t("fuels.hydrogen"), value: "Водород" }
      ],
      gears: [
        { text: this.$t("transmissions.automatic"), value: "Автомат" },
        { text: this.$t("transmissions.manual"), value: "Механика" },
        { text: this.$t("transmissions.variator"), value: "Вариатор" },
        { text: this.$t("transmissions.robot"), value: "Робот" },
      ],
      roofs: [
        { text: this.$t("roofs.ordinary"), value: "Обычная" },
        { text: this.$t("roofs.glass"), value: "Стеклянная" },
        { text: this.$t("roofs.folding"), value: "Складывающаяся" },
      ],
      steering_side: [
        { text: this.$t("steering_side.left"), value: "Левая" },
        { text: this.$t("steering_side.right"), value: "Правая" },
      ],
      interiors: [
        { text: this.$t("interiors.textile"), value: "Ткань" },
        { text: this.$t("interiors.leather"), value: "Кожа" },
      ],
      showDopPanel: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/car_marks`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.car_marks = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.error"));
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted: function () {
    if (this.$tours["registrationTour"]) {
      this.$tours["registrationTour"].nextStep();
    }
  },
  watch: {
    car_mark() {
      if (this.car_mark) {
        this.car.car_mark_id = this.car_mark.id;
        this.isLoading = true;
        this.isLoadingCarModels = true;
        this.axios
          .get(`/api/v1/car_models?car_mark_id=${this.car_mark.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.car_models = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarModels = false;
            this.isLoading = false;
          });
      } else {
        this.car_models = [];
        this.car_generations = [];
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_model() {
      if (this.car_model) {
        this.car.car_model_id = this.car_model.id;
        this.isLoading = true;
        this.isLoadingCarGenerations = true;
        this.car.car_name = `${this.car_mark.name} ${this.car_model.name}`;
        this.car.code = `${this.car_mark.name[0]}${this.car_model.name[0]}1`;
        this.axios
          .get(`/api/v1/car_generations?car_model_id=${this.car_model.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.car_generations = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarGenerations = false;
            this.isLoading = false;
          });
      } else {
        this.car_generations = [];
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_generation() {
      if (this.car_generation) {
        this.car.car_generation_id = this.car_generation.id;
        this.isLoadingCarConfigurations = true;
        this.isLoading = true;
        this.axios
          .get(
            `/api/v1/car_configurations?car_generation_id=${this.car_generation.id}`,
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.car_configurations = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.error"));
            console.log(error);
          })
          .finally(() => {
            this.isLoadingCarConfigurations = false;
            this.isLoading = false;
          });
      } else {
        this.car_configurations = [];
        this.car_modifications = [];
      }
    },
    car_configuration() {
      if (this.car_configuration) {
        this.car.car_configuration_id = this.car_configuration.id;
        this.car_modifications = this.car_configuration.modifications
        if (this.car_configuration["body_type"]) {
          switch (this.car_configuration["body_type"]) {
            case 'купе': case 'купе-хардтоп':
              this.car.car_type = "Купе"
              break;
            case 'родстер': case 'тарга': case 'спидстер':
              this.car.car_type = "Родстер"
              break;
            case 'седан': case 'седан 2 дв.': case 'лифтбек': case 'седан-хардтоп':
              this.car.car_type = "Седан"
              break;
            case 'хэтчбек 3 дв.': case 'хэтчбек 5 дв.': case 'хэтчбек 4 дв.':
              this.car.car_type = "Хэтчбек"
              break;
            case 'внедорожник 5 дв.': case 'внедорожник 3 дв.': case 'внедорожник открытый':
              this.car.car_type = "Внедорожник"
              break;
            case 'универсал': case 'универсал 5 дв.': case 'универсал 3 дв.':
              this.car.car_type = "Универсал"
              break;
            case 'кабриолет':
              this.car.car_type = "Кабриолет"
              break;
            case 'минивэн': case 'микровэн': case 'компактвэн':
              this.car.car_type = "Минивен"
              break;
            case 'фургон':
              this.car.car_type = "Фургон"
              break;
            case 'пикап': case 'пикап одинарная кабина': case 'пикап полуторная кабина': case 'пикап двойная кабина':
              this.car.car_type = "Пикап"
              break;
            case 'лимузин':
              this.car.car_type = "Лимузин"
              break;
            default:
              console.log(this.car_configuration["body_type"])
              break;
          }
        }
      } else {
        this.car_modifications = [];
      }
    },
    car_modification() {
      if (this.car_modification) {
        console.log(this.car_modification.specifications)
        this.car.car_complectation_id = this.car_modification["complectation-id"];
        if (this.car_modification.specifications.transmission) {
          let self = this
          switch (this.car_modification.specifications.transmission) {
            case 'механическая':
              self.car.transmission = "Механика"
              break;
            case 'автоматическая':
              self.car.transmission = "Автомат"
              break;
            case 'робот':
              self.car.transmission = "Робот"
              break;
            case 'вариатор':
              self.car.transmission = "Вариатор"
              break;
            default:
              break;
          }
        }
        if (this.car_modification.specifications["doors-count"]) {
          this.car.doors_count = this.car_modification.specifications["doors-count"]
        }
        if (this.car_modification.specifications["fuel-tank-capacity"]) {
          this.car.tank_value = this.car_modification.specifications["fuel-tank-capacity"]
        }
        if (this.car_modification.specifications["horse-power"]) {
          this.car.engine_power = this.car_modification.specifications["horse-power"]
        }
        if (this.car_modification.specifications["consumption-mixed"]) {
          this.car.gas_mileage = this.car_modification.specifications["consumption-mixed"]
        }
        if (this.car_modification.specifications["volume-litres"]) {
          this.car.engine_capacity = this.car_modification.specifications["volume-litres"]
        }
        if (this.car_modification.specifications["wheel-size"]) {
          this.car.tire_size = this.car_modification.specifications["wheel-size"][0]
        }
        if (this.car_modification.specifications["drive"]) {
          switch (this.car_modification.specifications["drive"]) {
            case 'задний':
              this.car.drive_unit = "Задний"
              break;
            case 'передний':
              this.car.drive_unit = "Передний"
              break;
            case 'полный':
              this.car.drive_unit = "Полный"
              break;
            default:
              break;
          }
        }
        if (this.car_modification.specifications["fuel"]) {
          switch (this.car_modification.specifications["fuel"]) {
            case 'АИ-95':
              this.car.drive_unit = "Бензин 95"
              break;
            case 'АИ-92':
              this.car.drive_unit = "Бензин 92"
              break;
            case 'АИ-98':
              this.car.drive_unit = "Бензин 98"
              break;
            case 'газ':
              this.car.drive_unit = "Газ"
              break;
            case 'ДТ':
              this.car.drive_unit = "Дизель"
              break;
            case 'водород':
              this.car.drive_unit = "Водород"
              break;
            default:
              break;
          }
        }
      }
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    submit() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (this.car.car_name && this.car.code && this.car.number) {
          this.isLoading = true;
          let car = this.car;
          this.axios
            .post(
              "/api/v1/cars",
              {
                cars: {
                  car_name: car.car_name,
                  code: car.code,
                  sort: car.sort,
                  number: car.number,
                  vin: car.vin,
                  body_number: car.body_number,
                  pts: car.pts,
                  registration_certificate: car.registration_certificate,
                  year: car.year,
                  transmission: car.transmission,
                  fuel: car.fuel,
                  tank_value: car.tank_value,
                  number_doors: car.number_doors,
                  engine_capacity: car.engine_capacity,
                  is_electropackage: car.is_electropackage,
                  car_type: car.car_type,
                  car_class: car.car_class,
                  color: car.color,
                  is_air: car.is_air,
                  price_hour: car.price_hour,
                  deposit: car.deposit,
                  insurance: car.insurance,
                  oil_engine: car.oil_engine,
                  oil_engine_date: car.oil_engine_date,
                  oil_transmission: car.oil_transmission,
                  oil_engine_km: car.oil_engine_km,
                  oil_transmission_km: car.oil_transmission_km,
                  oil_transmission_date: car.oil_transmission_date,
                  mileage: car.mileage,
                  start_mileage: car.start_mileage,
                  grm: car.grm,
                  grm_km: car.grm_km,
                  grm_date: car.grm_date,
                  antifreeze: car.antifreeze,
                  antifreeze_km: car.antifreeze_km,
                  antifreeze_date: car.antifreeze_date,
                  brake_fluid: car.brake_fluid,
                  brake_fluid_km: car.brake_fluid_km,
                  brake_fluid_date: car.brake_fluid_date,
                  candle: car.candle,
                  candle_km: car.candle_km,
                  candle_date: car.candle_date,
                  power_steering: car.power_steering,
                  power_steering_km: car.power_steering_km,
                  power_steering_date: car.power_steering_date,
                  diagnostic_card: car.diagnostic_card,
                  salon_filter_renew: car.salon_filter_renew,
                  salon_filter_renew_km: car.salon_filter_renew_km,
                  salon_filter_renew_date: car.salon_filter_renew_date,
                  akb_renew: car.akb_renew,
                  akb_renew_km: car.akb_renew_km,
                  akb_renew_date: car.akb_renew_date,
                  fuel_filter_renew: car.fuel_filter_renew,
                  fuel_filter_renew_km: car.fuel_filter_renew_km,
                  fuel_filter_renew_date: car.fuel_filter_renew_date,
                  purchase_price: car.purchase_price,
                  purchase_date: car.purchase_date,
                  sale_price: car.sale_price,
                  sale_date: car.sale_date,
                  tire_size: car.tire_size,
                  number_seats: car.number_seats,
                  drive_unit: car.drive_unit,
                  engine_power: car.engine_power,
                  airbags: car.airbags,
                  roof: car.roof,
                  gas_mileage: car.gas_mileage,
                  steering_side: car.steering_side,
                  interior: car.interior,
                  abs: car.abs,
                  ebd: car.ebd,
                  esp: car.esp,
                  window_lifters: car.window_lifters,
                  trunk_volume: car.trunk_volume,
                  description: car.description,
                  franchise: car.franchise,
                  max_fine: car.max_fine,
                  repair_cost: car.repair_cost,
                  heated_seats: car.heated_seats,
                  heated_seats_front: car.heated_seats_front,
                  parktronic: car.parktronic,
                  parktronic_back: car.parktronic_back,
                  parktronic_camera: car.parktronic_camera,
                  wheel_adjustment: car.wheel_adjustment,
                  wheel_adjustment_full: car.wheel_adjustment_full,
                  audio_system: car.audio_system,
                  video_system: car.video_system,
                  tv_system: car.tv_system,
                  cd_system: car.cd_system,
                  usb_system: car.usb_system,
                  climate_control: car.climate_control,
                  folding_seats: car.folding_seats,
                  heated_windshield: car.heated_windshield,
                  rain_sensor: car.rain_sensor,
                  beacon_emai: car.beacon_emai,
                  beacon_phone: car.beacon_phone,
                  beacon_pin: car.beacon_pin,
                  custom_field_1: car.custom_field_1,
                  custom_field_2: car.custom_field_2,
                  custom_field_3: car.custom_field_3,
                  car_mark_id: car.car_mark_id,
                  car_model_id: car.car_model_id,
                  car_generation_id: car.car_generation_id,
                  car_configuration_id: car.car_configuration_id,
                  car_modification_id: car.car_modification_id,
                  car_complectation_id: car.car_complectation_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$router.replace(`/cars/${response.data.id}`);

              this.$swal({
                showConfirmButton: true,
                icon: "success",
                title: this.$t("cars.create_car"),
                text: this.$t("cars.create_car_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.create_car"));
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.required_fields"),
            text: this.$t("errors.required_fields_car"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
