<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <!-- <v-card-title v-if="!isMobile">
        <h2>{{ $t("other.payments") }}</h2>
      </v-card-title> -->
      <v-card-text>
        <v-card>
          <v-toolbar
            v-if="!isMobile"
            height="80px"
            text
            :color="$vuetify.theme.dark ? '' : 'white'"
          >
            <h2>
              {{ $t("other.balance") }}:
              {{
                $store.getters.getMainCompany
                  ? Math.round($store.getters.getMainCompany.balance)
                  : 0
              }}
              <span>{{
                $store.getters.getMainCompany &&
                $store.getters.getMainCompany.currency == "RUB"
                  ? "₽"
                  : "€"
              }}</span>
              <br />
              <span class="text-caption"
                >{{ $t("tariffs.active_cars_count") }}:
                {{ $store.getters.getMainCompany.active_cars_count }}</span
              >
              <br />
              <span class="text-caption"
                >{{ $t("tariffs.payment_size") }}: ~{{
                  Math.round(
                    parseFloat(
                      $store.getters.getMainCompany.active_cars_count
                    ) * daily_payments
                  )
                }}{{
                  $store.getters.getMainCompany &&
                  $store.getters.getMainCompany.currency == "RUB"
                    ? "₽"
                    : "€"
                }}</span
              >
            </h2>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('custom.search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn
              small
              class="mr-2"
              text
              :href="`https://rentprog.${
                $root.$i18n.locale == 'ru' ? 'ru' : 'com'
              }/tariffs`"
              target="_blank"
            >
              {{ $t("tariffs.tariffs") }}
            </v-btn>
            <v-btn
              small
              class="mr-2"
              color="success"
              @click="transactionStart()"
            >
              {{ $t("other.add_balance") }}
              <v-icon class="ml-1"> mdi-plus </v-icon>
            </v-btn>
            <v-btn
              small
              class="ml-3"
              color="info"
              @click="showRebillDialog = true"
              v-if="
                $store.getters.getMainCompany.rebill_id &&
                $store.getters.getMainCompany.card_id
              "
            >
              {{ $t("transfers.edit_rebill") }}
              <v-icon class="ml-1"> mdi-reload </v-icon>
            </v-btn>
            <v-btn
              v-else-if="
                $store.getters.getMainCompany &&
                $store.getters.getMainCompany.currency == 'RUB'
              "
              small
              class="ml-3"
              color="info"
              @click="showRebillDialog = true"
            >
              {{ $t("transfers.start_recurrent") }}
              <v-icon class="ml-1"> mdi-credit-card-plus-outline </v-icon>
            </v-btn>
            <v-btn
              small
              class="ml-3"
              color="error"
              @click="removeRecurrent()"
              v-if="
                $store.getters.getMainCompany.rebill_id &&
                $store.getters.getMainCompany.card_id
              "
            >
              {{ $t("transfers.remove_recurrent") }}
              <v-icon class="ml-1"> mdi-minus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-toolbar
            v-else
            height="200px"
            text
            :color="$vuetify.theme.dark ? '' : 'white'"
          >
            <v-flex>
              <v-flex>
                <h2>
                  {{ $t("other.balance") }}:
                  {{
                    $store.getters.getMainCompany
                      ? Math.round($store.getters.getMainCompany.balance)
                      : 0
                  }}
                  <span>{{
                    $store.getters.getMainCompany &&
                    $store.getters.getMainCompany.currency == "RUB"
                      ? "₽"
                      : "€"
                  }}</span>
                </h2>
              </v-flex>
              <v-flex>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('custom.search')"
                  single-line
                  clearable
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex class="text-center justify-center align-middle">
                <v-btn
                  small
                  class="mt-2"
                  color="success"
                  @click="transactionStart()"
                >
                  {{ $t("other.add_balance") }}
                  <v-icon class="ml-1"> mdi-plus </v-icon>
                </v-btn>
              </v-flex>
              <v-flex class="text-center justify-center align-middle">
                <v-btn
                  small
                  class="mt-2"
                  color="info"
                  @click="showRebillDialog = true"
                  v-if="
                    $store.getters.getMainCompany.rebill_id &&
                    $store.getters.getMainCompany.card_id
                  "
                >
                  {{ $t("transfers.edit_rebill") }}
                  <v-icon class="ml-1"> mdi-reload </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  class="mt-2"
                  color="info"
                  @click="showRebillDialog = true"
                >
                  {{ $t("transfers.start_recurrent") }}
                  <v-icon class="ml-1"> mdi-credit-card-plus-outline </v-icon>
                </v-btn>
                <v-btn
                  small
                  class="mt-2"
                  color="error"
                  @click="removeRecurrent()"
                  v-if="
                    $store.getters.getMainCompany.rebill_id &&
                    $store.getters.getMainCompany.card_id
                  "
                >
                  {{ $t("transfers.remove_recurrent") }}
                  <v-icon class="ml-1"> mdi-minus </v-icon>
                </v-btn>
              </v-flex>
            </v-flex>
          </v-toolbar>
          <v-flex
            v-if="
              $store.getters.getMainCompany &&
              $store.getters.getMainCompany.currency == 'RUB'
            "
          >
            <v-flex :class="isMobile ? '' : 'd-flex justify-start'">
              <h2
                v-if="
                  card_list &&
                  card_list.length > 0 &&
                  $store.getters.getMainCompany.rebill_id &&
                  $store.getters.getMainCompany.card_id
                "
                class="ml-3 mt-3"
              >
                {{ $t("transfers.your_cards") }}
              </h2>
            </v-flex>
            <v-simple-table
              class="mx-5"
              v-if="
                card_list &&
                card_list.length > 0 &&
                $store.getters.getMainCompany.rebill_id &&
                $store.getters.getMainCompany.card_id
              "
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("custom.number_word") }}
                    </th>
                    <th class="text-center">
                      {{ $t("transfers.current_tooltip") }}
                    </th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :class="item.Status != 'A' ? 'red--text' : ''"
                    v-for="item in card_list"
                    :key="item.CardId"
                  >
                    <td>{{ item.Pan }}</td>
                    <td class="text-center">
                      <v-icon
                        v-if="
                          item.CardId == $store.getters.getMainCompany.card_id
                        "
                        v-tooltip="{
                          content: $t('transfers.current_tooltip'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                        >mdi-check</v-icon
                      >
                    </td>
                    <td class="text-right">
                      <v-icon
                        v-if="item.Status != 'D'"
                        size="30"
                        color="red darken-2"
                        @click="deleteCard(item.CardId)"
                        v-tooltip="{
                          content: $t('transfers.delete_card_tooltip'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }"
                        >mdi-delete-forever</v-icon
                      >
                      <span v-else>{{ $t("transfers.deleted") }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <hr class="mb-2 mt-2" />
          </v-flex>
          <v-flex>
            <!-- <h2 class="ml-3 mt-3">{{ $t("tariffs.transfers") }}</h2> -->
            <v-data-table
              :headers="headers"
              :items="transactions"
              :search="search"
              dense
              :loading-text="$t('custom.loading_table')"
              :items-per-page="20"
              mobile-breakpoint="100"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :class="!isMobile ? '' : 'is-mobile'"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr
                  :class="
                    props.item.sum > 0 ? 'success' : 'red'
                  "
                >
                  <td class="text-center">{{ props.item.id }}</td>
                  <td class="text-center">
                    {{ formatCreatedAt(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    {{ props.item.sum }}
                    {{
                      $store.getters.getMainCompany
                        ? $store.getters.getMainCompany.currency
                        : ""
                    }}
                  </td>
                  <td class="text-center">
                    {{ returnState(props.item.state) }}
                  </td>
                  <td class="text-center">
                    {{ props.item.description }}
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-flex>
        </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showTransactionDialog"
      :retain-focus="false"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-center">
          {{ $t("other.pay_info") }}
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-tabs
                    v-model="tab"
                    light
                    :fixed-tabs="!isMobile"
                    :centered="!isMobile"
                    class="mx-auto"
                  >
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab
                      v-if="
                        $store.getters.getMainCompany &&
                        $store.getters.getMainCompany.currency == 'RUB'
                      "
                    >
                      {{ $t("tariffs.rubles") }}
                    </v-tab>
                    <v-tab v-else> {{ $t("tariffs.euro") }} </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab" class="mx-auto" touchless>
                    <v-tab-item
                      v-if="
                        $store.getters.getMainCompany &&
                        $store.getters.getMainCompany.currency == 'RUB'
                      "
                      class="mx-auto"
                    >
                      <v-flex
                        xs12
                        md12
                        class="d-flex align-center justify-center text-center"
                      >
                        <v-text-field
                          v-model.trim="sum"
                          class="mt-5 mx-auto"
                          @click.once="sum = null"
                          :label="$t('other.sum')"
                        ></v-text-field>
                      </v-flex>
                      <!-- <v-flex xs12 md12 class="d-flex">
                        <v-checkbox
                          v-model="save_card"
                          hide-details
                          dense
                          label="Запомнить карту для дальнейших платежей"
                        ></v-checkbox>
                      </v-flex> -->
                      <!-- <v-flex xs12 md12 class="d-flex">
                        <v-checkbox
                          v-model="recurrent"
                          hide-details
                          dense
                          label="Подключить услугу автоплатежа (постоплата, списание раз в месяц)"
                        ></v-checkbox>
                      </v-flex> -->
                      <v-flex xs12 md12 class="text-center">
                        <v-btn
                          color="success"
                          class="mt-2"
                          @click="transactionCreate()"
                          >{{ $t("other.pay") }}</v-btn
                        >
                      </v-flex>
                      <v-flex xs12 md12>
                        <p>{{ $t("other.sum_warn_2") }}</p>
                      </v-flex>
                    </v-tab-item>
                    <v-tab-item v-else>
                      <v-flex
                        v-if="
                          $store.getters.getCompany.company.inn == null ||
                          $store.getters.getCompany.company.inn == ''
                        "
                        xs12
                        md12
                        mt-2
                        class="text-center"
                      >
                        <p>{{ $t("tariffs.inn_warn") }}</p>
                        <v-btn color="info" class="mt-2" to="/company_profile">
                          {{ $t("tariffs.inn_link") }} ->
                        </v-btn>
                      </v-flex>
                      <v-flex v-else xs12 md12 mt-2 class="text-center">
                        <v-text-field
                          v-model.trim="stripe_sum"
                          :readonly="showStripeForm"
                          @click.once="stripe_sum = null"
                          :label="$t('other.sum')"
                        ></v-text-field>
                        <v-spacer></v-spacer>
                      </v-flex>
                      <v-flex xs12 md12>
                        <stripe-element-payment
                          v-if="showStripeForm"
                          ref="paymentRef"
                          :locale="$root.$i18n.locale"
                          :pk="pk"
                          :testMode="true"
                          :elements-options="elementsOptions"
                          :confirm-params="confirmParams"
                        />
                      </v-flex>
                      <v-flex
                        v-if="
                          $store.getters.getCompany.company.inn != null &&
                          $store.getters.getCompany.company.inn != ''
                        "
                        xs12
                        md12
                        class="text-center"
                      >
                        <v-btn
                          v-if="!showStripeForm"
                          color="success"
                          class="mt-2"
                          @click="generatePaymentIntent"
                        >
                          {{ $t("other.to_pay") }}
                        </v-btn>
                        <v-btn v-else color="success" class="mt-2" @click="pay">
                          {{ $t("other.pay") }}
                        </v-btn>
                      </v-flex>
                      <v-flex
                        v-if="
                          $store.getters.getCompany.company.inn != null &&
                          $store.getters.getCompany.company.inn != ''
                        "
                        xs12
                        md12
                      >
                        <p>{{ $t("other.sum_warn_euro") }}</p>
                        <p>{{ $t("other.sum_warn_euro_2") }}</p>
                      </v-flex>
                    </v-tab-item>
                  </v-tabs-items>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(showTransactionDialog = false), (sum = 0)"
            >{{ $t("custom.close") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showRebillDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title class="text-center">
          {{ $t("transfers.start_rebill_title") }}
        </v-card-title>

        <v-card-text>
          <v-flex class="">
            <v-flex md12 class="pr-5">
              <v-select
                v-model="rebill_type"
                :items="rebill_types"
                item-text="text"
                item-value="value"
                :label="$t('transfers.rebill_type')"
              ></v-select>
              <v-flex class="d-flex">
                <v-text-field
                  v-if="rebill_type == 'Sum'"
                  v-model.trim="rebill_limit"
                  class="mt-5 mr-3"
                  @click.once="rebill_limit = null"
                  :label="$t('transfers.rebill_limit_title')"
                ></v-text-field>
                <v-text-field
                  v-if="rebill_type == 'Sum'"
                  v-model.trim="rebill_sum"
                  class="mt-5"
                  @click.once="rebill_sum = null"
                  :label="$t('transfers.rebill_sum_title')"
                ></v-text-field>
              </v-flex>
            </v-flex>
            <v-flex md12>
              <p class="mt-1">*{{ $t("transfers.add_card_info") }}</p>
              <!-- <p class="mt-1">
                {{ $t("transfers.rebill_monthly") }} -
                {{ $t("transfers.rebill_type_monthly") }}
              </p> -->
              <p class="mt-1">
                {{ $t("transfers.rebill_sum") }} -
                {{ $t("transfers.rebill_type_sum") }}
              </p>
              <p class="subtitle-1">
                <a href="https://rentprog.ru/tariffs?lang=ru" target="_blank"
                  >Тарифы на использование</a
                >,

                <a href="https://rentprog.ru/agreement?lang=ru" target="_blank"
                  >Пользовательское соглашение</a
                >,

                <a href="https://rentprog.ru/privacy?lang=ru" target="_blank"
                  >Политика конфиденициальности</a
                >
              </p>
            </v-flex>
          </v-flex>
          <p>
            Оплата производится за доступ к сервису RentProg. При отказе от
            оплаты, доступ к сервису будет закрыт, а неиспользованные средства
            будут возвращены на карту.
          </p>
          <p v-if="rebill_type == 'Monthly'">
            Сумма платежа расчитывается исходя из тарифа на использование
            согласно среднему количеству активных автомобилей за текущий период.
            Количество автомобилей фиксируется ежедневно в 00:00 UTC, при
            расчете берется среднее количество за текущий платежный месяц.
            Отменить автоплатеж можно на
            <a href="https://rentprog.ru/privacy?lang=ru" target="_blank"
              >текущей странице</a
            >
            нажав на кнопку "Отключить автоплатеж"
          </p>
          <p v-else>
            При достижении на балансе сервиса указанной суммы происходит
            списание с выбранной карты. Порог баланса и сумма платежа
            указывается пользователем вручную в поля выше, и фиксируется на всю
            длительность услуги. <br />
            Отменить автоплатеж можно на
            <a href="https://rentprog.ru/privacy?lang=ru" target="_blank"
              >текущей странице</a
            >
            нажав на кнопку "Отключить автоплатеж".
          </p>
          <p>
            При возникновении вопросов обращайтесь в чат поддержки или по почте
            <a
              href="mailto:
            admin@rentprog.ru"
              >admin@rentprog.ru</a
            >.
          </p>
          <v-checkbox
            v-model="accept_rebill_1"
            class="subtitle-1"
            hide-details
            dense
            label="Согласен с условиями использования сервиса и услуги Автоплатеж, а так же на обработку персональных данных"
          ></v-checkbox>
          <!-- <v-checkbox
            v-model="accept_rebill_2"
            class="subtitle-1"
            hide-details
            dense
            label="Согласен с условиями услуги Автоплатеж"
          ></v-checkbox> -->
          <!-- <v-checkbox
            v-model="accept_rebill_3"
            class="subtitle-1"
            hide-details
            dense
            label="Согласен автоматические оплату услуг сервиса RentProg согласно тарифам начиная со следующих суток"
          ></v-checkbox> -->
        </v-card-text>

        <v-card-actions class="text-center">
          <v-spacer></v-spacer>
          <v-flex :class="isMobile ? '' : 'd-flex'">
            <v-flex :class="isMobile ? 'mb-3' : ''">
              <v-btn
                color="success darken-1"
                :disabled="!accept_rebill"
                @click="addCardCreate()"
                >{{ $t("transfers.start_recurrent") }}</v-btn
              >
            </v-flex>
            <v-flex>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  (showRebillDialog = false),
                    ((sum = 0),
                    (rebill_type = 'Sum'),
                    (rebill_sum = 500),
                    (rebill_limit = 100))
                "
                >{{ $t("custom.close") }}</v-btn
              >
            </v-flex>
          </v-flex>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { StripeElementPayment } from "@vue-stripe/vue-stripe";
// moment.locale("ru");
// import sha256 from "js-sha256";
// import sha256 from "crypto-js/sha256"
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      transactions: [],
      card_list: [],
      save_card: true,
      recurrent: false,
      accept_rebill: false,
      accept_rebill_1: false,
      accept_rebill_2: false,
      accept_rebill_3: false,
      rebill_types: [
        // { text: this.$t("transfers.rebill_monthly"), value: "Monthly" },
        { text: this.$t("transfers.rebill_sum"), value: "Sum" },
      ],
      rebill_type: "Sum",
      rebill_limit: 100,
      rebill_sum: 500,
      showTransactionDialog: false,
      showRebillDialog: false,
      sum: 0,
      stripe_sum: 0,
      pk: "pk_live_51KtQkKFNgvJ2eyx925rNt6rX9DwYTt6TrcsGIjfIeE7gZWPNSgg4pEC3NKI4tJKiPqgMytdYPPHQ8WhYXtb9Ka9o00htarW7FH",
      elementsOptions: {
        clientSecret: "",
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: "https://web.rentprog.com/result_success", // success url
      },
      tab: 0,
      showStripeForm: false,
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("custom.status"), value: "state", align: "center" },
        { text: this.$t("custom.description"), value: "description", align: "center" },
      ],
      pagination: { sortBy: "created_at", descending: true },
      search: null,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.$store.commit("setCompany", { company: response.data });
          self.axios
            .get(`api/v1/main_companies/${response.data.main_company_id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response_main_company) => {
              this.$store.commit("setMainCompany", response_main_company.data);
            })
            .catch((error) => {
              self.isLoading = false;
              self.setError(error, self.$t("errors.main_company_data"));
              console.log(error);
            });
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.company_data"));
          console.log(error);
        });
      this.axios
        .get("/api/v1/transfers", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.transactions = response.data.transfers;
          this.card_list = response.data.card_list;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.counts_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    accept_rebill_1() {
      if (this.accept_rebill_1) {
        this.accept_rebill = true;
      } else {
        this.accept_rebill = false;
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    daily_payments() {
      if (
        this.$store.getters.getMainCompany &&
        this.$store.getters.getMainCompany.tariff != "Старт" &&
        this.$store.getters.getMainCompany.tariff != "Безлимит"
      ) {
        let daily_payment = 0;
        if (this.$store.getters.getMainCompany.currency == "RUB") {
          if (this.$store.getters.getMainCompany.tariff == "Стандарт") {
            daily_payment = 120.0;
          } else if (this.$store.getters.getMainCompany.tariff == "Средний") {
            daily_payment = 111.0;
          } else if (
            this.$store.getters.getMainCompany.tariff == "Профессионал"
          ) {
            daily_payment = 105.0;
          } else if (this.$store.getters.getMainCompany.tariff == "Эксперт") {
            daily_payment = 90.0;
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный"
          ) {
            daily_payment = 78.0;
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный+"
          ) {
            daily_payment =
              parseFloat(this.$store.getters.getMainCompany.manual_price) *
              30.0;
          }
        } else {
          if (this.$store.getters.getMainCompany.tariff == "Стандарт") {
            daily_payment = parseFloat(1.5).toFixed(2);
          } else if (this.$store.getters.getMainCompany.tariff == "Средний") {
            daily_payment = parseFloat(1.4).toFixed(2);
          } else if (
            this.$store.getters.getMainCompany.tariff == "Профессионал"
          ) {
            daily_payment = parseFloat(1.3).toFixed(3);
          } else if (this.$store.getters.getMainCompany.tariff == "Эксперт") {
            daily_payment = parseFloat(1.1).toFixed(3);
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный"
          ) {
            daily_payment = parseFloat(1.0).toFixed(3);
          } else if (
            this.$store.getters.getMainCompany.tariff == "Индивидуальный+"
          ) {
            daily_payment = parseFloat(
              this.$store.getters.getMainCompany.manual_price
            );
          }
        }
        return daily_payment;
      } else {
        return 0;
      }
    },
  },
  methods: {
    transactionStart() {
      if (
        this.$store.getters.getCurrentUser.email ||
        this.$store.getters.getCompany.company.email_for_bookings
      ) {
        this.showTransactionDialog = !this.showTransactionDialog;
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.no_email"),
          text: this.$t("errors.no_email_text"),
        });
      }
    },
    generatePaymentIntent() {
      if (this.stripe_sum && this.stripe_sum >= 15) {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            "/api/v1/stripe_payment_intent",
            {
              transfers: {
                sum: parseFloat(this.stripe_sum.replace(/,/, ".")).toFixed(2),
                // sum: 1000
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.elementsOptions.clientSecret = response.data.clientSecret;
            this.isLoading = false;
            this.showStripeForm = true;
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.company_data"));
            console.log(error);
          });
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.bad_sum"),
          text: this.$t("errors.bad_sum_text"),
        });
      }
    },
    pay() {
      this.$refs.paymentRef.submit();
    },
    transactionCreate() {
      if (this.user_role != "guest") {
        if (
          this.sum &&
          parseFloat(this.sum.replace(/,/, ".")) &&
          parseFloat(this.sum.replace(/,/, ".")) > 0
        ) {
          if (parseInt(this.sum) >= 500) {
            this.isLoading = true;
            let self = this;
            // Создаём транзакцию у нас
            this.axios
              .post(
                "/api/v1/create_transfer",
                {
                  transfers: {
                    sum: parseFloat(this.sum.replace(/,/, ".")).toFixed(2),
                    // save_card: this.save_card,
                    recurrent: this.recurrent,
                    turn_off: false,
                  },
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                if (response.data.Success == true && response.data.PaymentURL) {
                  window.location.replace(response.data.PaymentURL);
                  this.showTransactionDialog = false;
                  this.sum = 0;
                } else {
                  this.$swal({
                    icon: "error",
                    title: response.data.Message,
                    text: response.data.Details,
                  });
                }
              })
              .catch((error) => {
                this.isLoading = false;
                this.setError(error, this.$t("errors.payment_creating"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "error",
                  title: this.$t("errors.payment_creating"),
                  text: error,
                });
              })
              .finally(() => (this.isLoading = false));
          } else {
            this.$swal({
              icon: "warning",
              title: this.$t("errors.payment_min"),
            });
          }
        } else {
          this.$swal({
            icon: "warning",
            title: this.$t("errors.payment_sum"),
            text: this.$t("errors.payment_sum_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    addCardCreate() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        // Создаём транзакцию у нас
        this.axios
          .post(
            "/api/v1/create_transfer",
            {
              transfers: {
                sum: 10,
                recurrent: true,
                rebill_type: this.rebill_type,
                rebill_sum: this.rebill_sum,
                rebill_limit: this.rebill_limit,
                turn_off: false,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            if (response.data.Success == true && response.data.PaymentURL) {
              window.location.replace(response.data.PaymentURL);
              this.showTransactionDialog = false;
            } else {
              this.$swal({
                icon: "error",
                title: response.data.Message,
                text: response.data.Details,
              });
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.payment_creating"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.payment_creating"),
              text: error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    removeRecurrent() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        this.axios
          .post(
            "/api/v1/turn_off_rebill",
            {
              turn_off: true,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("transfers.removed_recurrent"),
            });
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.payment_creating"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.payment_creating"),
              text: error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    tryRebill() {
      this.isLoading = true;
      this.axios
        .post(
          "/api/v1/try_rebill",
          {
            transfers: {
              sum: 10,
            },
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: this.$t("transfers.removed_recurrent"),
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.payment_creating"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.payment_creating"),
            text: error,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    deleteCard(CardId) {
      this.isLoading = true;
      this.axios
        .post(
          "/api/v1/delete_card",
          {
            CardId: CardId,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.isLoading = false;
          let card = this.card_list.find((card) => card.CardId == CardId);
          card.Status = "D";
          this.card_list.splice(this.card_list.indexOf(card), 1, card);
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.card_delete"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    returnState(state) {
      if (state == 1) {
        return this.$t("other.success");
      } else if (state == 4) {
        return this.$t("other.returned");
      } else if (state == 3) {
        return this.$t("errors.error");
      } else {
        return "?";
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm:ss");
    },
    returnDate(item) {
      return moment.parseZone(item).format("LL");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    StripeElementPayment,
  },
};
</script>
