import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import axios from "axios";

const BASE_URL = "http://localhost:3000/";

import createPersistedState from "vuex-persistedstate";
import createMutationsSharer from "vuex-shared-mutations";
// import sessionManager from "./modules/session_manager";
// import SecureLS from "secure-ls";
// const ls = new SecureLS({ isCompression: false });
Vue.use(Vuex);

export const store = new Vuex.Store({
  state() {
    return {
      packageVersion: process.env.VUE_APP_VERSION || "0",
      currentUser: {},
      user: {},
      user_settings: {},
      auth_token: null,
      company: {
        mileage_limit: 0,
        mileage_limit_cost: 0,
      },
      main_company: {
        balance: 0,
        payer_state: 0,
        currency: "EUR",
      },
      doc_template_url: "https://web.rentprog.ru/doc_example.docx",
      entity_doc_template_url:
        "https://web.rentprog.ru/entity_doc_example.docx",
      invoice_template_url: "https://web.rentprog.ru/invoice_example.docx",
      prolong_template_url: "https://web.rentprog.ru/prolong_example.docx",
      login_users: [],
      messages: [],
      csrf: null,
      login_time: null,
      todos: [],
      drawer: false,
      show_active_bookings: true,
      show_ride_bookings: true,
      signedIn: false,
      snackbar: false,
      // servicesCheck: false,
      searchInBookings: null,
      sortDateInBookings: null,
      sortStateInBookings: null,
      new_booking: false,
      show_banner: true,
      show_store_kits: false,
      bannerRemoveDemoContent: false,
      tour_next_step: false,
      car_tab: 0,
      whatsapp_search_client: null,
    };
  },
  getters: {
    // !!!!!
    getAuthToken(state) {
      return state.auth_token;
    },
    getUserEmail(state) {
      return state.user ? state.user.email : null;
    },
    getUserID(state) {
      return state.user ? state.user.id : null;
    },
    isLoggedIn(state) {
      const loggedOut =
        state.auth_token == null || state.auth_token == JSON.stringify(null);
      return !loggedOut;
    },
    // !!!!
    appVersion: (state) => {
      return state.packageVersion;
    },
    isAdmin(state) {
      return state.currentUser.role === "admin";
    },
    isManager(state) {
      return state.currentUser.role === "manager";
    },
    isSuperAdmin(state) {
      return state.currentUser.role === "superadmin";
    },
    isPartner(state) {
      return state.currentUser.role === "partner";
    },
    isGuest(state) {
      return state.currentUser.role === "guest";
    },
    currentUserId(state) {
      return state.currentUser && state.currentUser.id;
    },
    getDrawer(state) {
      return state.drawer;
    },
    getShowActiveBookings(state) {
      return state.show_active_bookings;
    },
    getShowRideBookings(state) {
      return state.show_ride_bookings;
    },
    getCurrentUser(state) {
      return state.currentUser;
    },
    getCompany(state) {
      return state.company;
    },
    getMainCompany(state) {
      return state.main_company;
    },
    getCompanyId(state) {
      return state.company.company.id;
    },
    getDocUrl(state) {
      return state.doc_template_url;
    },
    getEntityDocUrl(state) {
      return state.entity_doc_template_url;
    },
    getInvoiceUrl(state) {
      return state.invoice_template_url;
    },
    getProlongTemplateUrl(state) {
      return state.prolong_template_url;
    },
    getServicesCheck(state) {
      return state.servicesCheck;
    },
    getMessages(state) {
      return state.messages;
    },
    getCSRF(state) {
      return state.csrf;
    },
    getSignedIn(state) {
      return state.signedIn;
    },
    getLoginUsers(state) {
      return state.login_users;
    },
    getLoginTime(state) {
      return state.login_time;
    },
    getSearchInBookings(state) {
      return state.searchInBookings;
    },
    getSortDateInBookings(state) {
      return state.sortDateInBookings;
    },
    getSortStateInBookings(state) {
      return state.sortStateInBookings;
    },
    getNewBookingState(state) {
      return state.new_booking;
    },
    getShowBanner(state) {
      return state.show_banner;
    },
    getStoreKits(state) {
      return state.show_store_kits;
    },
    getBannerRemoveDemoContent(state) {
      return state.bannerRemoveDemoContent;
    },
    getTourNextStep(state) {
      return state.tour_next_step;
    },
    getCarTab(state) {
      return state.car_tab;
    },
    getUserSettings(state) {
      return state.user_settings;
    },
    getWhatsappSearchClient(state) {
      return state.whatsapp_search_client;
    }
  },
  mutations: {
    setUserInfo(state, data) {
      state.user = data.data.user;
      state.auth_token = data.headers.authorization;
      axios.defaults.headers.common["Authorization"] =
        data.headers.authorization;
      localStorage.setItem("auth_token", data.headers.authorization);
      state.currentUser = data.data.user;
      state.signedIn = true;
      state.login_time = Date.now();
    },
    setUserSettings(state, data) {
      state.user_settings = data;
    },
    setUserInfoFromToken(state, data) {
      state.user = data.data.user;
      state.auth_token = localStorage.getItem("auth_token");
    },
    // !!!!
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
      state.signedIn = true;
      // state.csrf = csrf;
      state.login_time = Date.now();
    },
    unsetCurrentUser(state) {
      state.user = {};
      state.auth_token = null;
      state.signedIn = false;
      localStorage.removeItem("auth_token");
      axios.defaults.headers.common["Authorization"] = null;
      // В роутере уже есть редирект, но без Signin
      // router.push({ name: "Signin" }).catch(() => false);
      router.go();

      state.currentUser = {};
      state.signedIn = false;
      state.csrf = null;
      state.company = {};
      state.main_company = {};
      state.login_time = null;
      state.todos = [];
      state.drawer = false;
      state.show_active_bookings = true;
      state.show_ride_bookings = false;
      state.searchInBookings = null;
      state.sortDateInBookings = null;
      state.login_users = [];
      state.packageVersion = 0;
    },
    refresh(state, csrf) {
      state.signedIn = true;
      state.csrf = csrf;
    },
    setCompany(state, company) {
      state.company = company;
    },
    setMainCompany(state, main_company) {
      state.main_company = main_company;
    },
    setMessages(state, messages) {
      state.messages = messages;
    },
    addMessage(state, message) {
      let old_data = state.messages;
      old_data.unshift(message);
    },
    removeMessage(state, message) {
      let old_data = state.messages;
      let index = old_data.findIndex((item) => item.id == message.id);
      old_data.splice(index, 1);
    },
    setDocUrl(state, url) {
      state.doc_template_url = url;
    },
    setEntityDocUrl(state, url) {
      state.entity_doc_template_url = url;
    },
    setInvoiceUrl(state, url) {
      state.invoice_template_url = url;
    },
    setProlongTemplateUrl(state, url) {
      state.prolong_template_url = url;
    },
    setLoginUsers(state, user) {
      let arr_users = [];
      // Текущие
      state.login_users.forEach((item) => {
        arr_users.push(item);
      });

      // Чистим массив от дублей
      if (arr_users.findIndex((item) => item.id == user.id) >= 0) {
        function removeDuplicates(myArr, prop) {
          return myArr.filter((obj, pos, arr) => {
            return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
          });
        }
        arr_users = removeDuplicates(arr_users, "id");
      }

      if (user.login == true) {
        let index = arr_users.findIndex((item) => item.id == user.id);
        // Добавляем нового
        if (index == -1) {
          arr_users.push(user);
        } else {
          arr_users.splice(index, 1, { id: user.id, login: true });
        }
      }

      // console.log("user", user);
      if (user.login == false) {
        // console.log("user login false", user);
        let index = arr_users.findIndex((item) => item.id == user.id);
        if (index >= 0) {
          arr_users.splice(index, 1, { id: user.id, login: false });
        }
        // let index = arr_users.findIndex(item => item.id == user.id)
        // if (index >= 0) {
        //   let finded_user = arr_users.find(item => item.id == user.id)
        //   arr_users = arr_users.filter(item => item.id != finded_user.id)
        //   console.log("finded_user", finded_user)
        //   console.log("arr_users", arr_users)
        // }
      }

      // console.log(arr_users);

      return (state.login_users = arr_users);
    },
    show_active_bookings(state) {
      return (state.show_active_bookings = !state.show_active_bookings);
    },
    show_ride_bookings(state) {
      return (state.show_ride_bookings = !state.show_ride_bookings);
    },
    drawer(state) {
      return (state.drawer = !state.drawer);
    },
    servicesCheckTrue(state) {
      return (state.servicesCheck = true);
    },
    servicesCheckFalse(state) {
      return (state.servicesCheck = false);
    },
    setSearchInBookings(state, search) {
      return (state.searchInBookings = search);
    },
    setsortDateInBookings(state, range) {
      return (state.sortDateInBookings = range);
    },
    setSortStateInBookings(state, value) {
      return (state.sortStateInBookings = value);
    },
    setNewBooking(state, new_booking_exists) {
      state.new_booking = new_booking_exists;
    },
    setShowBanner(state, close_banner) {
      state.show_banner = close_banner;
    },
    setStoreKits(state) {
      state.show_store_kits = !state.show_store_kits;
    },
    setBannerRemoveDemoContent(state) {
      state.bannerRemoveDemoContent = !state.bannerRemoveDemoContent;
    },
    setTourNextStep(state, force) {
      state.tourNextStep = force;
    },
    setCarTab(state, tab) {
      state.car_tab = tab;
    },
    setWhatsappSearchClient(state, search) {
      state.whatsapp_search_client = search;
    }
  },
  actions: {
    loginUserWithToken({ commit }, payload) {
      const config = {
        headers: {
          Authorization: payload.auth_token,
        },
      };
      new Promise((resolve, reject) => {
        axios
          .get(`${BASE_URL}member-data`, config)
          .then((response) => {
            commit("setUserInfoFromToken", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {
    // sessionManager,
  },
  plugins: [
    createPersistedState(),
    // Сохраняет мутации в разных вкладках
    createMutationsSharer({
      predicate: [
        "refresh",
        "setCurrentUser",
        "unsetCurrentUser",
        "show_active_bookings",
        "show_ride_bookings",
        "servicesCheckTrue",
        "servicesCheckFalse",
        "setSearchInBookings",
        "setsortDateInBookings",
      ],
      // storage: {
      //   getItem: (key) => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: (key) => ls.remove(key)
      // }
    }),
  ],
});
