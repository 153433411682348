<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-flex xs12 md8 offset-sm2>
      <v-toolbar light :color="$vuetify.theme.dark ? '' : 'white'">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/9`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="employees"
        :search="search"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :class="!isMobile ? 'elevation-1 mt-2' : 'elevation-1 mt-2 is-mobile'"
        mobile-breakpoint="100"
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} {1}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100, -1],
        }"
      >
        <template v-slot:item="props">
          <tr v-if="props.item.active || showNotActive">
            <td class="text-center">
              <v-chip
                class="ma-2"
                :color="
                  checkLogin(props.item.id) || props.item.login
                    ? 'green'
                    : 'secondery'
                "
                text-color="white"
              >
                {{ props.item.id }}
              </v-chip>
            </td>
            <td class="text-center">
              {{ formatCreatedAt(props.item.last_login) }}
            </td>
            <td class="text-center">
              <router-link :to="`/admin/users/${props.item.id}`">
                {{ props.item.name }}
              </router-link>
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              <router-link :to="`/admin/users/${props.item.id}`">
                {{ props.item.email }}
              </router-link>
            </td>
            <td v-if="props.item.role == 'user'" class="text-center">
              {{ $t("employers.employee") }}
            </td>
            <td v-if="props.item.role == 'manager'" class="text-center">
              {{ $t("employers.manager") }}
            </td>
            <td v-if="props.item.role == 'admin'" class="text-center">
              {{ $t("employers.admin") }}
            </td>
            <td v-if="props.item.role == 'superadmin'" class="text-center">
              {{ $t("employers.superadmin") }}
            </td>
            <td v-if="props.item.role == 'partner'" class="text-center">
              {{ $t("employers.partner") }}
            </td>
            <td v-if="props.item.role == 'guest'" class="text-center">
              {{ $t("employers.guest") }}
            </td>
            <td class="text-center">
              {{ getUserAccount(props.item.id) }}{{ currency }}
            </td>
            <td>
              <v-btn
                small
                @click="setUser(props.item.id), (kassa_dialog = true)"
                class="text-center"
                color="info"
                >{{ $t("custom.counts") }}</v-btn
              >
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-btn
        v-if="checkAdminManager()"
        small
        color="success"
        :to="{ name: 'CreateEmployee' }"
        class="mt-2 mr-2 mb-2"
        >{{ $t("employers.add") }}</v-btn
      >
      <v-btn
        v-if="!showNotActive"
        small
        @click="showNotActive = true"
        class="mt-2 mb-2"
        >{{ $t("employers.show_dismiss") }}</v-btn
      >
      <v-btn
        v-if="showNotActive"
        small
        @click="showNotActive = false"
        class="mt-2 mb-2"
        >{{ $t("employers.remove_dismiss") }}</v-btn
      >
      <v-btn
        v-if="!showAllUsers"
        small
        @click="showAllUsers = true"
        class="mt-2 mb-2 ml-2"
        >{{ $t("employers.show_all_users") }}</v-btn
      >
      <v-btn
        v-if="showAllUsers"
        small
        @click="showAllUsers = false"
        class="mt-2 mb-2 ml-2"
        >{{ $t("employers.not_show_all_users") }}</v-btn
      >
    </v-flex>
    <v-dialog
      v-model="kassa_dialog"
      :retain-focus="false"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("custom.counts") }} - {{ user.name }}
          <v-icon class="ml-2 green--text" @click="updateUserKassa()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (kassa_dialog = false),
                (money_track = false),
                (user = { account: { cash: 0 } }),
                (user_cash = 0),
                (get_cash = 0),
                (give_cash = 0)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout row wrap>
              <v-flex md1 class="mr-2">
                <v-text-field
                  readonly
                  v-model.number="user.account.cash"
                  :label="$t('counts.in_count')"
                ></v-text-field>
              </v-flex>
              <v-flex md2 class="mr-2">
                <v-text-field
                  :disabled="give_cash > 0"
                  v-model.number="get_cash"
                  :label="$t('counts.get_cash')"
                ></v-text-field>
              </v-flex>
              <v-flex md2 class="mr-2">
                <v-text-field
                  :disabled="get_cash > 0"
                  v-model.number="give_cash"
                  :label="$t('counts.give_cash')"
                ></v-text-field>
              </v-flex>
              <v-flex md4 class="mr-2" v-if="checkAdmin()">
                <v-radio-group v-model="money_track" row>
                  <v-radio
                    :label="$t('counts.company_counts')"
                    :value="true"
                  ></v-radio>
                  <v-radio
                    :disabled="currentUserId == user.id"
                    :label="$t('counts.employee_count')"
                    :value="false"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
              <v-flex md2 class="mr-2 mb-2 align-center">
                <v-btn color="success" @click="updateUserKassa()">{{
                  $t("custom.save")
                }}</v-btn>
              </v-flex>
              <v-flex md12>
                <v-card>
                  <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('custom.search')"
                      single-line
                      clearable
                      hide-details
                    ></v-text-field>
                  </v-toolbar>
                  <v-data-table
                    :headers="headers_cashier"
                    :items="counts"
                    :search="search"
                    :loading="table_counts_loading"
                    :loading-text="$t('custom.loading_table')"
                    :class="!isMobile ? '' : 'is-mobile'"
                    :items-per-page="20"
                    mobile-breakpoint="100"
                    :sort-by="['created_at']"
                    :sort-desc="[true]"
                    :footer-props="{
                      pageText: `{0} ${$t('custom.of')} {1}`,
                      itemsPerPageText: $t('custom.elements_table'),
                      showFirstLastPage: true,
                      itemsPerPageOptions: [20, 50, 100, -1],
                    }"
                  >
                    <template slot="item" slot-scope="props">
                      <tr
                        :class="
                          props.item.operation && props.item.sum < 0
                            ? 'red--text'
                            : ''
                        "
                      >
                        <td class="text-center">{{ props.item.id }}</td>
                        <td class="text-center">
                          {{ returnDate(props.item.created_at) }}
                        </td>
                        <td class="text-center">
                          <v-icon @click="editCount(props.item)">
                            mdi-pencil-outline
                          </v-icon>
                        </td>
                        <td class="text-center">
                          {{ translateCategory(props.item.group) }}
                        </td>
                        <td class="text-center">
                          {{ props.item.description }}
                        </td>
                        <td class="text-center">
                          {{ props.item.sum }}{{ currency }}
                        </td>
                        <td class="text-center" v-if="props.item.operation">
                          <v-icon class="text-center" style="color: green">
                            mdi-plus-circle-outline
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="!props.item.operation">
                          <v-icon class="text-center" style="color: tomato">
                            mdi-minus-circle-outline
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.cash">
                          <v-icon
                            class="text-center"
                            color="success"
                            v-tooltip="{
                              content: $t('money.cash'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-cash
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.cashless">
                          <v-icon
                            class="text-center"
                            color="warning"
                            v-tooltip="{
                              content: $t('money.terminal'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-contactless-payment-circle-outline
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.entity">
                          <v-icon
                            class="text-center"
                            color="secondery"
                            v-tooltip="{
                              content: $t('money.from_entity'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-bank
                          </v-icon>
                        </td>
                        <td class="text-center" v-if="props.item.cash_card">
                          <v-icon
                            class="text-center"
                            color="success"
                            v-tooltip="{
                              content: $t('money.card_to_card'),
                              placement: 'bottom-center',
                              classes: ['info'],
                              targetClasses: ['it-has-a-tooltip'],
                              delay: {
                                show: 500,
                                hide: 500,
                              },
                            }"
                          >
                            mdi-credit-card-check-outline
                          </v-icon>
                        </td>
                        <td
                          class="text-left"
                          nowrap
                          v-if="
                            props.item.booking_id &&
                            props.item.car_id &&
                            props.item.car_code
                          "
                        >
                          <p>
                            {{ $t("bookings.booking") }}:
                            <router-link
                              :to="{
                                path: `/bookings/${props.item.booking_id}`,
                              }"
                            >
                              {{ props.item.booking_id }}
                            </router-link>
                          </p>
                          <p>
                            {{ $t("custom.car") }}:
                            <router-link
                              :to="{ path: `/cars/${props.item.car_id}` }"
                            >
                              {{ props.item.car_code }}
                            </router-link>
                          </p>
                        </td>
                        <td
                          class="text-left"
                          v-else-if="props.item.car_id && props.item.car_code"
                        >
                          {{ $t("custom.car") }}:
                          <router-link
                            :to="{ path: `/cars/${props.item.car_id}` }"
                            >{{ props.item.car_code }}</router-link
                          >
                        </td>
                        <td class="text-left" v-else-if="props.item.source">
                          {{ props.item.source }}
                        </td>
                        <td class="text-center" v-else-if="props.item.debt_id">
                          {{ $t("custom.deb") }} №{{ props.item.debt_id }}
                        </td>
                        <td class="text-center" v-else>-</td>
                        <td class="text-center">
                          <v-menu offset-y v-if="props.item.check == null">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                v-tooltip="{
                                  content: $t('tooltips.some_invoice'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                                v-bind="attrs"
                                v-on="on"
                                color="secondary"
                              >
                                mdi-map-marker-question-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(state, index) in countStates"
                                :key="index"
                                @click="
                                  updateСountCheck(props.item.id, state.state)
                                "
                              >
                                <v-list-item-title>
                                  {{ state.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <v-menu offset-y v-if="props.item.check == true">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                v-tooltip="{
                                  content: $t('tooltips.trust_invoice'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                                v-bind="attrs"
                                v-on="on"
                                color="success"
                              >
                                mdi-checkbox-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(state, index) in countStates"
                                :key="index"
                                @click="
                                  updateСountCheck(props.item.id, state.state)
                                "
                              >
                                <v-list-item-title>
                                  {{ state.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <v-menu offset-y v-if="props.item.check == false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                class="ml-2"
                                v-tooltip="{
                                  content: $t('tooltips.bad_invoice'),
                                  placement: 'bottom-center',
                                  classes: ['info'],
                                  targetClasses: ['it-has-a-tooltip'],
                                  delay: {
                                    show: 500,
                                    hide: 500,
                                  },
                                }"
                                v-bind="attrs"
                                v-on="on"
                                color="error"
                              >
                                mdi-bookmark-remove-outline
                              </v-icon>
                            </template>
                            <v-list>
                              <v-list-item
                                v-for="(state, index) in countStates"
                                :key="index"
                                @click="
                                  updateСountCheck(props.item.id, state.state)
                                "
                              >
                                <v-list-item-title>
                                  {{ state.text }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                    <template slot="no-data">
                      {{ $t("custom.no_data_in_table") }}
                    </template>
                    <v-alert
                      slot="no-results"
                      :value="true"
                      color="error"
                      icon="mdi-alert"
                    >
                      {{ $t("tables.no_search_result", { msg: search }) }}
                    </v-alert>
                  </v-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container>
            <v-layout row wrap>
              <v-flex md12>
                <h2>{{ $t("counts.daily") }}</h2>
                <v-card>
                  <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search_cashbox"
                      append-icon="mdi-magnify"
                      :label="$t('custom.search')"
                      single-line
                      clearable
                      hide-details
                    ></v-text-field>
                  </v-toolbar>
                  <v-data-table
                    :headers="headers_cashboxes"
                    :items="user_cashbox"
                    :search="search_cashbox"
                    :loading="table_counts_loading"
                    :loading-text="$t('custom.loading_table')"
                    :class="!isMobile ? '' : 'is-mobile'"
                    :items-per-page="7"
                    :sort-by="['created_at']"
                    mobile-breakpoint="100"
                    :sort-desc="[true]"
                    :footer-props="{
                      pageText: `{0} ${$t('custom.of')} {1}`,
                      itemsPerPageText: $t('custom.elements_table'),
                      showFirstLastPage: true,
                      itemsPerPageOptions: [7, 20, 50, 100, -1],
                    }"
                  >
                    <template slot="item" slot-scope="props">
                      <tr>
                        <td class="text-center">{{ props.item.id }}</td>
                        <td class="text-center">
                          {{ formatCreatedAt(props.item.created_at) }}
                        </td>
                        <td class="text-center">
                          {{ props.item.account }}{{ currency }}
                        </td>
                      </tr>
                    </template>
                    <template slot="no-data">
                      {{ $t("custom.no_data_in_table") }}
                    </template>
                    <v-alert
                      slot="no-results"
                      :value="true"
                      color="error"
                      icon="mdi-alert"
                    >
                      {{ $t("tables.no_search_result", { msg: search }) }}
                    </v-alert>
                  </v-data-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm1 md1>
                    <v-text-field
                      v-model.number="editedCount.sum"
                      :label="$t('custom.amount')"
                      readonly
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-combobox
                      v-model="selected_count.group"
                      :items="counts_groups"
                      item-text="text"
                      item-value="value"
                      :label="$t('custom.select_group')"
                    ></v-combobox>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-autocomplete
                      v-model="selected_car"
                      item-text="code"
                      item-value="id"
                      text
                      hide-no-data
                      hide-details
                      :label="$t('custom.select_car')"
                      :items="cars"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <v-radio-group v-model="editedCount.operation" row>
                      <v-radio
                        :label="$t('custom.income')"
                        :value="true"
                      ></v-radio>
                      <v-radio
                        :label="$t('custom.expences')"
                        :value="false"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <td
                    class="text-center"
                    v-if="!editedCount.cashless && !editedCount.cash_card"
                  >
                    <v-icon class="text-center" color="success">
                      mdi-cash
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="editedCount.cashless">
                    <v-icon class="text-center" color="warning">
                      mdi-contactless-payment-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="editedCount.cash_card">
                    <v-icon class="text-center" color="success">
                      mdi-credit-card-check-outline
                    </v-icon>
                  </td>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="editedCount.description"
                      :label="$t('counts.about')"
                      :placeholder="$t('counts.about_placeholder')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveCount()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
export default {
  name: "UsersList",
  data() {
    return {
      error: "",
      users: [],
      accounts: [],
      counts: [],
      editedCount: [],
      cars: [],
      selected_car: [],
      counts_groups: [
        { text: this.$t("groups_counts.repair"), value: "Ремонт" },
        { text: this.$t("groups_counts.service"), value: "Обслуживание" },
        { text: this.$t("groups_counts.parts"), value: "Запчасти" },
        { text: this.$t("groups_counts.insurance_main"), value: "Страховка" },
        {
          text: this.$t("groups_counts.common_expences"),
          value: "Накладные расходы",
        },
        {
          text: this.$t("groups_counts.transport_expences"),
          value: "Транспортные расходы",
        },
        { text: this.$t("groups_counts.tax"), value: "Налоги" },
        { text: this.$t("groups_counts.fines_main"), value: "Штрафы" },
        {
          text: this.$t("groups_counts.rent_property"),
          value: "Аренда имущества",
        },
        {
          text: this.$t("groups_counts.clean_main"),
          value: "Мойка автомобилей",
        },
        { text: this.$t("groups_counts.fuel_main"), value: "Топливо" },
        { text: this.$t("groups_counts.ads"), value: "Реклама" },
        {
          text: this.$t("groups_counts.sell_property"),
          value: "Продажа имущества",
        },
        {
          text: this.$t("groups_counts.buy_property"),
          value: "Покупка имущества",
        },
        {
          text: this.$t("groups_counts.partners_payments"),
          value: "Выплаты партнёрам",
        },
        {
          text: this.$t("groups_counts.other_expences"),
          value: "Прочие расходы",
        },
        {
          text: this.$t("groups_counts.other_incomes"),
          value: "Прочие доходы",
        },
      ],
      selected_count: [],
      cashboxes: [],
      user_cashbox: [],
      money_track: false,
      user: {
        account: {
          cash: 0,
        },
      },
      user_cash: 0,
      get_cash: 0,
      give_cash: 0,
      headers: [
        { text: this.$t("custom.id"), align: "center", value: "id" },
        {
          text: this.$t("other.last_active"),
          align: "center",
          value: "last_login",
        },
        { text: this.$t("check_lists.worker"), value: "name", align: "center" },
        { text: this.$t("custom.email"), value: "email", align: "center" },
        { text: this.$t("other.role"), value: "role", align: "center" },
        { text: this.$t("other.account"), sortable: true, align: "center" },
        { text: this.$t("check_lists.control"), align: "center" },
      ],
      headers_cashboxes: [
        { text: this.$t("custom.id"), align: "center", value: "id" },
        { text: this.$t("custom.date"), value: "created_at", align: "center" },
        { text: this.$t("custom.counts"), value: "account", align: "center" },
      ],
      headers_cashier: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("custom.group"),
          sortable: true,
          value: "group",
          align: "center",
        },
        {
          text: this.$t("custom.description"),
          sortable: false,
          value: "description",
          align: "center",
        },
        {
          text: this.$t("custom.amount"),
          sortable: false,
          value: "sum",
          align: "center",
        },
        {
          text: this.$t("custom.operation"),
          value: "operation",
          align: "center",
        },
        { text: this.$t("custom.type"), value: "cashless", align: "center" },
        { text: this.$t("custom.source"), sortable: false, align: "center" },
        { text: this.$t("custom.state"), value: "check", align: "center" },
      ],
      countStates: [
        { state: true, text: this.$t("counts.checked") },
        { state: false, text: this.$t("counts.not_approved") },
        { state: null, text: this.$t("counts.not_finded") },
      ],
      pagination: { sortBy: "created_at", descending: true },
      search: "",
      search_cashbox: "",
      disabledGet: false,
      disabledGive: false,
      kassa_dialog: false,
      editDialog: false,
      showNotActive: false,
      showAllUsers: false,
      table_counts_loading: false,
      pagination: {
        descending: true,
        sortBy: "id",
        rowsPerPage: 10,
      },
      rowsPerPageItems: [
        10,
        25,
        50,
        { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 },
      ],
      isLoading: true,
      isMobile: false,
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (
      this.$store.getters.isLoggedIn &&
      (this.user_role == "superadmin" ||
        this.user_role == "admin" ||
        this.user_role == "manager" ||
        this.user_role == "guest")
    ) {
      this.axios
        .get("api/v1/users", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.users = response.data;
          response.data.forEach((user) => {
            this.accounts.push(user.account);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.workers_load"));
        });

      this.axios
        .get("/api/v1/cars", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cars = [];
          response.data.data.forEach((element) => {
            this.cars.push(element.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_load"));
          console.log(error);
        });
      this.axios
        .get("/api/v1/cashboxes", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cashboxes = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cashboxes_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$router.replace("/");
    }
  },
  watch: {
    get_cash() {
      this.user.account.cash = this.user_cash;
      this.user.account.cash -= this.get_cash;
      this.user.account.operation = false;
    },
    give_cash() {
      this.user.account.cash = this.user_cash;
      this.user.account.cash += this.give_cash;
      this.user.account.operation = true;
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    currentUserId() {
      return this.$store.getters.currentUserId;
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    employees() {
      if (this.showAllUsers) {
        return this.users;
      } else {
        return this.users.filter(
          (user) => user.role != "partner" && user.role != "guest"
        );
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    checkLogin(id) {
      let user = this.$store.getters.getLoginUsers.find(
        (user) => user.id == id
      );
      if (user && user.login == true) {
        return true;
      } else {
        return false;
      }
    },
    getUserAccount(id) {
      return this.accounts.filter((account) => account.user_id == id)[0].cash;
    },
    setUser(id) {
      let self = this;
      this.isLoading = true;
      this.user = this.users.find((user) => user.id === id);
      this.user_cashbox = this.cashboxes.filter(
        (cashbox) => cashbox.user_id == id
      );
      this.user_cash = this.user.account.cash;
      if (this.$store.getters.currentUserId == id) {
        this.money_track = true;
      }
      this.table_counts_loading = true;
      this.axios
        .get(`/api/v1/workers_counts/${this.user.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.counts = [];
          response.data.data.forEach((count) => {
            self.counts.push(count.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cashboxes_worker_load"));
          console.log(error);
        })
        .finally(
          () => ((this.isLoading = false), (this.table_counts_loading = false))
        );
    },
    updateUserKassa() {
      if (this.user_role != "guest") {
        let user = this.user;
        let self = this;
        if (this.get_cash == 0 && this.give_cash == 0) {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.cashbox_null"),
            text: this.$t("errors.cashbox_null_text"),
          });
        } else {
          this.isLoading = true;
          // проверка если админ
          let money_track = false;
          if (this.checkAdmin()) {
            money_track = self.money_track;
          }
          this.axios
            .post(
              `/api/v1/manual_update_account`,
              {
                user_id: user.id,
                money_track: money_track,
                sum: self.get_cash + self.give_cash,
                get_cash: self.get_cash > 0 ? true : false,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              if (!self.money_track) {
                if (self.get_cash > 0) {
                  self.accounts.find(
                    (account) =>
                      account.user_id == self.$store.getters.currentUserId
                  ).cash += self.get_cash;
                } else {
                  self.accounts.find(
                    (account) =>
                      account.user_id == self.$store.getters.currentUserId
                  ).cash -= self.give_cash;
                }
              }
              self.kassa_dialog = false;
              self.user = { account: { cash: 0 } };
              self.user_cash = 0;
              self.get_cash = 0;
              self.give_cash = 0;
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: self.$t("counts.cashbox_updated"),
                text: self.$t("counts.cashbox_updated_text"),
              });
            })
            .catch((error) => {
              self.setError(error, self.$t("errors.workers_account_load"));
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: self.$t("errors.cashbox_update"),
                text: self.error,
              });
            })
            .finally(() => (self.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editCount(count) {
      this.isLoading = true;
      this.editDialog = true;
      this.axios
        .get(`/api/v1/counts/${count.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedCount = response.data;
          this.selected_count = response.data;
          this.selected_count.group = this.translateCategory(
            this.selected_count.group
          );
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.load_counts"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
      if (count.car_id) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/cars/${count.car_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.selected_car = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.load_cars"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    saveCount() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let count = this.editedCount;
        let self = this;
        this.axios
          .patch(
            `/api/v1/counts/${count.id}`,
            {
              counts: {
                sum: count.sum,
                operation: count.operation,
                description: count.description,
                // проблема в этом при обновлении
                group: this.selected_count.group.value,
                car_id: this.selected_car,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editDialog = false;
            let foundIndex = this.counts.findIndex(
              (element) => element.id === count.id
            );
            // count.group = response.data.group;
            this.counts.splice(foundIndex, 1, response.data);
            // console.log(count)
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("counts.update_count_title"),
              text: this.$t("counts.update_count_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_count"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.update_count"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateСountCheck(id, state) {
      if (this.user_role != "user" && this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        let count = this.counts.find((element) => element.id === id);
        this.axios
          .patch(
            `/api/v1/counts/${count.id}`,
            {
              counts: {
                check: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.counts.findIndex(
              (element) => element.id === id
            );
            count.check = state;
            this.counts.splice(foundIndex, 1, count);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("counts.state_updated"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.count_state"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.count_state"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    translateCategory(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
      }
      return group_text;
    },
    formatCreatedAt(date) {
      if (date) {
        return moment.parseZone(date).format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    returnDate(item) {
      if (item) {
        return moment(item, "YYYY-MM-DD H:mm").format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    setCar(id) {
      if (id != null) {
        return this.cars.find((car) => car.id == id).code;
      } else {
        return "-";
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    showTodosLink() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkAdminManager() {
      return (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      );
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkGuest() {
      return this.$store.getters.isGuest;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    showUsersLink(user) {
      return (
        (this.$store.getters.isSuperAdmin ||
          this.$store.getters.isAdmin ||
          this.$store.getters.isGuest) &&
        this.$store.getters.currentUserId !== user.id
      );
    },
  },
};
</script>
<style>
  .is-mobile .v-data-footer__select {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .is-mobile .v-data-footer__icons-before {
    margin: 0 auto !important;
  }
  .is-mobile .v-data-footer__icons-after {
    margin: 0 auto !important;
  }
</style>
