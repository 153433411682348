<template>
  <v-container
    grid-list-md
    text-xs-center
    v-resize="checkMobile"
    :style="isMobile ? 'padding: 5px !important;' : ''"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-layout row wrap>
      <v-flex xs12>
        <!-- <v-banner color="warning" dark class="text-left">
          Внимание! Сегодня, 06.05 в 18:00 будут проводиться технические работы на сервере, сервис будет недоступен около одного часа
        </v-banner> -->
        <!-- <v-card-text class="text-center">
          <h2>Главная</h2>
        </v-card-text> -->
      </v-flex>
      <v-flex xs12>
        <v-container fluid class="start-tour-0">
          <v-row>
            <v-col>
              <v-card>
                <v-img class="success white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-airplane</v-icon>
                  </v-flex>
                  <v-card-title
                    :class="
                      isMobile
                        ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                        : 'd-flex mt-0 pt-1 justify-center'
                    "
                  >
                    <span class="d-none d-xs-none d-sm-none d-md-none d-lg-flex"
                      >{{ $t("home.active_bookings") }}:&nbsp;</span
                    >
                    {{ active_bookings_count }}
                  </v-card-title>
                </v-img>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-img class="warning white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text"
                      >mdi-calendar-today-outline</v-icon
                    >
                  </v-flex>
                  <v-card-title
                    :class="
                      isMobile
                        ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                        : 'd-flex mt-0 pt-1 justify-center'
                    "
                    ><span
                      class="d-none d-xs-none d-sm-none d-md-none d-lg-flex"
                      >{{ $t("home.yesterday_bookings") }}:&nbsp;</span
                    >
                    {{ bookings_yesterday_count }}</v-card-title
                  >
                </v-img>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-img class="info white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text"
                      >mdi-car-brake-parking</v-icon
                    >
                  </v-flex>
                  <v-card-title
                    :class="
                      isMobile
                        ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                        : 'd-flex mt-0 pt-1 justify-center'
                    "
                    ><span
                      class="d-none d-xs-none d-sm-none d-md-none d-lg-flex"
                      >{{ $t("home.cars_on_parking") }}:&nbsp;</span
                    >
                    {{ free_cars_count }}</v-card-title
                  >
                </v-img>
              </v-card>
            </v-col>
            <v-col>
              <v-card>
                <v-img class="secondary white--text align-end" height="100px">
                  <v-flex class="d-flex justify-center">
                    <v-icon class="mx-auto white--text">mdi-face-man</v-icon>
                  </v-flex>
                  <v-card-title
                    :class="
                      isMobile
                        ? 'd-flex mt-0 px-1 pt-1 justify-center subtitle-2'
                        : 'd-flex mt-0 pt-1 justify-center'
                    "
                    ><span
                      class="d-none d-xs-none d-sm-none d-md-none d-lg-flex"
                      >{{ $t("home.all_clients") }}:&nbsp;</span
                    >
                    {{ clients_count }}</v-card-title
                  >
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
      <v-flex xs12>
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-toolbar flat :color="$vuetify.theme.dark ? '' : 'white'">
          <v-flex sm12 md6>
            <v-toolbar-title>{{ $t("home.cars_on_parking") }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-flex>
          <v-flex sm12 md6>
            <v-text-field
              v-model="searchInBookings"
              append-icon="mdi-magnify"
              :label="$t('custom.search')"
              single-line
              clearable
              hide-details
            ></v-text-field>
          </v-flex>
        </v-toolbar>
        <v-data-table
          :items="free_cars_start_dates"
          :headers="filteredCarsHeaders"
          :search="searchInBookings"
          dense
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          mobile-breakpoint="100"
          :class="!isMobile ? 'start-tour-1' : 'start-tour-1 is-mobile'"
          :footer-props="{
            itemsPerPage: 100,
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [50, 100, 200, -1],
          }"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center" :key="props.item.car_id">
                {{ props.item.car_id }}
              </td>
              <td v-if="isMobile" class="text-center">
                <router-link
                  data-cy="car_id"
                  id="car_id"
                  :to="{ path: `/cars/${props.item.car_id}` }"
                  >{{ props.item.car_code }}</router-link
                >
              </td>
              <td v-else class="text-center">
                <router-link
                  data-cy="car_id"
                  id="car_id"
                  :to="{ path: `/cars/${props.item.car_id}` }"
                  >{{ props.item.car_name }}({{
                    props.item.car_code
                  }})</router-link
                >
              </td>
              <!-- Состояние -->
              <td class="text-center" data-cy="car_state">
                <v-menu offset-y v-if="props.item.state == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.normal'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                    >
                      mdi-thumb-up
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(props.item.car_id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.in_service'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="secondary"
                    >
                      mdi-tools
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(props.item.car_id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.critical'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                    >
                      mdi-alert-box
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(props.item.car_id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.long_rent'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="pink"
                    >
                      mdi-all-inclusive
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(props.item.car_id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.no_rent'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                    >
                      mdi-airplane-off
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(props.item.car_id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.state == 6">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.service_needed'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="orange"
                    >
                      mdi-alarm-light
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(props.item.car_id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <!-- Мойка -->
              <td class="text-center">
                <v-menu offset-y v-if="props.item.clean_state">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.clean'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                    >
                      mdi-flare
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="updateCleanState(props.item.car_id, true)"
                    >
                      <v-list-item-title>
                        {{ $t("states.clean") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="updateCleanState(props.item.car_id, false)"
                    >
                      <v-list-item-title>
                        {{ $t("states.durty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.durty'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="brown"
                    >
                      mdi-spray-bottle
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="updateCleanState(props.item.car_id, true)"
                    >
                      <v-list-item-title>
                        {{ $t("states.clean") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="updateCleanState(props.item.car_id, false)"
                    >
                      <v-list-item-title>
                        {{ $t("states.durty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td class="text-center">
                <v-menu offset-y v-if="props.item.tank_state">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.gas_full'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="light-green"
                    >
                      mdi-battery
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="updateTankState(props.item.car_id, true)"
                    >
                      <v-list-item-title>
                        {{ $t("states.gas_full") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="updateTankState(props.item.car_id, false)"
                    >
                      <v-list-item-title>
                        {{ $t("states.gas_empty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('states.gas_not_full'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                    >
                      mdi-battery-10
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      @click="updateTankState(props.item.car_id, true)"
                    >
                      <v-list-item-title>
                        {{ $t("states.gas_full") }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      @click="updateTankState(props.item.car_id, false)"
                    >
                      <v-list-item-title>
                        {{ $t("states.gas_empty") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td
                :nowrap="isMobile ? true : false"
                class="text-center"
                v-if="props.item.start_date"
              >
                <router-link
                  :to="{ path: `/bookings/${props.item.booking_id}` }"
                >
                  {{ props.item.start_date }}
                </router-link>
              </td>
              <td class="text-center" v-else>
                {{ $t("bookings.no_bookings") }}
              </td>
              <!-- Ремонт -->
              <td v-if="show_repairs" class="text-center">
                <p
                  :class="
                    repair && repair.warning
                      ? 'black--text red accent-2 mb-1'
                      : 'black--text amber accent-2 mb-1'
                  "
                  style="cursor: pointer"
                  v-for="repair in props.item.repairs"
                  :key="repair.id"
                  @click="repairEnd(repair)"
                >
                  {{ repair.description }}
                </p>
                <p
                  v-if="checkOilEngine(props.item)"
                  :class="checkOilEngineColor(props.item)"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_oil_engine = true)"
                >
                  {{ $t("services.oil") }}
                </p>
                <p
                  v-if="checkOilTransmission(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_oil_transmission = true)"
                >
                  {{ $t("services.kpp") }}
                </p>
                <p
                  v-if="checkGrm(props.item)"
                  :class="checkGrmColor(props.item)"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_grm = true)"
                >
                  {{ $t("services.grm") }}
                </p>
                <p
                  v-if="checkAntifreeze(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_antifreeze = true)"
                >
                  {{ $t("services.antifreeze") }}
                </p>
                <p
                  v-if="checkBrake(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_brake = true)"
                >
                  {{ $t("services.brake_fluid") }}
                </p>
                <p
                  v-if="checkCandle(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_candle = true)"
                >
                  {{ $t("services.candles") }}
                </p>
                <p
                  v-if="checkPowerSteering(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_power_steering = true)"
                >
                  {{ $t("services.gur") }}
                </p>
                <p
                  v-if="checkSalonFilter(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_salon_filter = true)"
                >
                  {{ $t("services.cabin_filter") }}
                </p>
                <p
                  v-if="checkAkb(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_akb = true)"
                >
                  {{ $t("services.battery") }}
                </p>
                <p
                  v-if="checkFuelFilter(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_fuel_filter = true)"
                >
                  {{ $t("services.gas_filter") }}
                </p>
              </td>
              <!-- Местонахождение -->
              <td v-if="show_store_place" class="text-center">
                <p
                  :class="props.item.store_place"
                  style="cursor: pointer"
                  @click="
                    (store_place = props.item.store_place),
                      (car_id = props.item.car_id),
                      (updatePlaceDialog = true)
                  "
                >
                  {{ props.item.store_place ? props.item.store_place : "-" }}
                </p>
              </td>
              <!-- Последнее ТО -->
              <td
                v-if="show_to"
                class="text-center"
                :nowrap="isMobile ? true : false"
              >
                <p
                  @click="
                    (car_id = props.item.car_id), (inspection_dialog = true)
                  "
                  style="cursor: pointer"
                >
                  {{
                    props.item.last_inspection
                      ? formatedDatesLastInspection(props.item.last_inspection)
                      : "-"
                  }}
                </p>
              </td>
              <!-- Резина -->
              <td v-if="show_tire" class="text-center">
                <v-menu offset-y v-if="props.item.tire_type == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('wheels.summer'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="yellow"
                    >
                      mdi-weather-sunny
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(tire_type, index) in tire_types"
                      :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)"
                    >
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('wheels.winter'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                    >
                      mdi-snowflake
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(tire_type, index) in tire_types"
                      :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)"
                    >
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('wheels.all_seasons'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                    >
                      mdi-theme-light-dark
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(tire_type, index) in tire_types"
                      :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)"
                    >
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('wheels.both'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                    >
                      mdi-circle-half-full
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(tire_type, index) in tire_types"
                      :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)"
                    >
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="props.item.tire_type == 5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-tooltip="{
                        content: $t('wheels.spike'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="light-green"
                    >
                      mdi-pine-tree
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(tire_type, index) in tire_types"
                      :key="index"
                      @click="updateTireType(props.item.car_id, tire_type.id)"
                    >
                      <v-list-item-title>
                        {{ tire_type.tire_type }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
          >
            {{ $t("tables.no_search_result", { msg: searchInBookings }) }}
          </v-alert>
        </v-data-table>
      </v-flex>
      <!-- События -->
      <v-flex xs12 v-if="checkAdmin()">
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-toolbar flat :color="$vuetify.theme.dark ? '' : 'white'">
          <v-toolbar-title>{{ $t("home.events") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('custom.search')"
            single-line
            clearable
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-data-table
          :items="operations"
          :headers="headers"
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          :class="!isMobile ? '' : 'is-mobile'"
          :search="search"
          :footer-props="{
            pageText: `{0} ${$t('custom.of')} {1}`,
            itemsPerPageText: $t('custom.elements_table'),
            showFirstLastPage: true,
            itemsPerPageOptions: [20, 50, 100],
          }"
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-no-wrap">
                {{ formatCreatedAt(props.item.created_at) }}
              </td>
              <td>{{ props.item.description }}</td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
          >
            {{ $t("tables.no_search_result", { msg: search }) }}
          </v-alert>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog
      :retain-focus="false"
      v-model="updatePlaceDialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("home.car_location") }}</span>
          <v-icon class="ml-2 green--text" @click="storePlaceSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (updatePlaceDialog = false), (store_place = null), (car_id = null)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 justify="space-around">
                    <v-text-field
                      v-model="store_place"
                      clearable
                      :label="$t('home.location')"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              (updatePlaceDialog = false), (store_place = null), (car_id = null)
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="success" @click="storePlaceSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :retain-focus="false"
      v-model="inspection_dialog"
      max-width="500px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title id="registration-step-2">
          <span class="headline">{{ $t("to.to") }}</span>
          <v-icon class="ml-2 green--text" @click="inspectionSave()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (inspection_dialog = false),
                (car_id = null),
                (inspection_date = null),
                (inspection_date_menu = false),
                (inspection_description = null)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 justify="space-around">
                    <v-menu
                      v-model="inspection_date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="inspection_date_format"
                          :label="$t('to.date')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-5"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="inspection_date"
                        :locale="$i18n.locale"
                        @input="inspection_date_menu = false"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="inspection_description"
                      clearable
                      rows="2"
                      auto-grow
                      outlined
                      :label="$t('to.total')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="
              (inspection_dialog = false),
                (car_id = null),
                (inspection_date = null),
                (inspection_date_menu = false),
                (inspection_description = null)
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="success" @click="inspectionSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_oil_engine"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.oil_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.oil_engine_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.oil_engine"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_oil_engine = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_oil_transmission"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.kpp_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.oil_transmission_date"
                    label="Дата замены"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.oil_transmission"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_oil_transmission = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_grm"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.grm_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.grm_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.grm"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_grm = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_antifreeze"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.antifreeze_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.antifreeze_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.antifreeze"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_antifreeze = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_brake"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.brake_fluid_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.brake_fluid_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.brake_fluid"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_brake = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_candle"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.candles_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.candle_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.candle"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_candle = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_salon_filter"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.cabin_filter_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.salon_filter_renew_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.salon_filter_renew"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_salon_filter = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_fuel_filter"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.gas_filter_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.fuel_filter_renew_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.fuel_filter_renew"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_fuel_filter = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_akb"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.battery_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.akb_renew_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.akb_renew"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog_akb = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_power_steering"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("services.gur_renew") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.power_steering_date"
                    :label="$t('services.renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.power_steering"
                    :label="$t('services.renew_km')"
                    type="number"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_power_steering = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// // moment.locale("ru");
import { isMobile } from "mobile-device-detect";
export default {
  data: function () {
    return {
      operations: [],
      bookings_for_now: [],
      free_cars: [],
      free_cars_start_dates: [],
      active_bookings_count: 0,
      bookings_yesterday_count: 0,
      free_cars_count: 0,
      clients_count: 0,
      editedCar: {},
      search: "",
      searchInBookings: "",
      states: [
        { id: 1, state: this.$i18n.t("states.normal") },
        { id: 2, state: this.$i18n.t("states.in_service") },
        { id: 3, state: this.$i18n.t("states.critical") },
        { id: 4, state: this.$i18n.t("states.long_rent") },
        { id: 5, state: this.$i18n.t("states.no_rent") },
        { id: 6, state: this.$i18n.t("states.service_needed") },
      ],
      tire_types: [
        { id: 1, tire_type: this.$i18n.t("wheels.summer") },
        { id: 2, tire_type: this.$i18n.t("wheels.winter") },
        { id: 3, tire_type: this.$i18n.t("wheels.all_seasons") },
        { id: 4, tire_type: this.$i18n.t("wheels.both") },
        { id: 5, tire_type: this.$i18n.t("wheels.spike") },
      ],
      headers_for_cars: [
        { text: this.$i18n.t("custom.number"), value: "id", align: "center" },
        { text: this.$i18n.t("custom.car"), sortable: false, align: "center" },
        { text: this.$i18n.t("custom.state"), value: "state", align: "center" },
        {
          text: this.$i18n.t("custom.clean"),
          value: "clean_state",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.tank"),
          value: "tank_state",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.close_booking"),
          value: "start_date",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.repairs"),
          sortable: false,
          value: "repairs",
          align: "center",
        },
        {
          text: this.$i18n.t("custom.local"),
          sortable: false,
          value: "store_place",
          align: "center",
        },
        {
          text: this.$i18n.t("to.to_short"),
          value: "last_inspection",
          align: "center",
        },
        {
          text: this.$i18n.t("wheels.tire"),
          value: "tire_type",
          align: "center",
        },
      ],
      headers: [
        {
          text: this.$i18n.t("custom.date"),
          sortable: false,
          value: "created_at",
          class: "text-xs-center",
        },
        {
          text: this.$i18n.t("custom.description"),
          sortable: false,
          value: "description",
          class: "text-xs-center",
        },
      ],
      updatePlaceDialog: false,
      inspection_date: null,
      inspection_date_format: null,
      inspection_date_menu: false,
      inspection_description: null,
      inspection_dialog: false,
      car_id: null,
      store_place: null,
      dialog_oil_engine: false,
      dialog_oil_transmission: false,
      dialog_grm: false,
      dialog_antifreeze: false,
      dialog_brake: false,
      dialog_candle: false,
      dialog_power_steering: false,
      dialog_salon_filter: false,
      dialog_akb: false,
      dialog_fuel_filter: false,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      if (this.$i18n.locale == "ru") {
        moment.locale("ru");
      } else {
        moment.locale("en-gb");
      }
      this.axios
        .get("/api/v1/dashboard.json", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.bookings_for_now = response.data.bookings_for_now;
          this.operations = response.data.operations;
          this.active_bookings_count = response.data.active_bookings_count;
          this.free_cars_count = response.data.free_cars_count;
          this.free_cars_start_dates = response.data.free_cars_start_dates;
          this.bookings_yesterday_count =
            response.data.bookings_yesterday_count;
          this.clients_count = response.data.clients_count;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.dashboard"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
      console.info(
        `App version ${this.$store.getters.appVersion} | ${process.env.VUE_APP_VERSION} | ${process.env.VUE_APP_ENV_VERSION}`
      );
    } else {
      this.$store.commit("unsetCurrentUser");
      // this.$router.push({ path: `/signin` }).catch(err => {});
    }
  },
  watch: {
    inspection_date() {
      if (this.inspection_date) {
        this.inspection_date_format = moment(
          this.inspection_date,
          "YYYY-MM-DD"
        ).format("DD-MM-YYYY");
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    show_repairs() {
      return this.$store.getters.getUserSettings.show_repair_on_main;
    },
    show_store_place() {
      return this.$store.getters.getUserSettings.show_store_place_on_main;
    },
    show_tire() {
      return this.$store.getters.getUserSettings.show_tire_on_main;
    },
    show_to() {
      return this.$store.getters.getUserSettings.show_to_on_main;
    },
    filteredCarsHeaders() {
      return this.headers_for_cars.filter((header) => {
        if (header.value == "store_place") {
          return this.show_store_place;
        } else if (header.value == "repairs") {
          return this.show_repairs;
        } else if (header.value == "tire_type") {
          return this.show_tire;
        } else if (header.value == "last_inspection") {
          return this.show_to;
        } else {
          return true;
        }
      });
    },
  },
  methods: {
    inspectionSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .post(
            `/api/v1/cars/${this.car_id}/inspections`,
            {
              inspections: {
                description: this.inspection_description,
                date: this.inspection_date,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let index = this.free_cars_start_dates.findIndex(
              (car) => car.car_id == this.car_id
            );
            this.free_cars_start_dates[index].last_inspection =
              this.inspection_date;
            this.inspection_dialog = false;
            this.inspection_date = null;
            this.inspection_date_menu = false;
            this.inspection_description = null;
            this.car_id = null;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("to.success_title"),
              text: this.$t("to.success_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_to"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.update_to"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    storePlaceSave() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        var self = this;
        this.axios
          .patch(
            `/api/v1/cars/${this.car_id}`,
            {
              cars: {
                store_place: this.store_place,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let index = this.free_cars_start_dates.findIndex(
              (car) => car.car_id == this.car_id
            );
            this.free_cars_start_dates[index].store_place = this.store_place;
            this.car_id = null;
            this.store_place = null;
            this.updatePlaceDialog = false;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: "Местонахождение авто обновлено!",
            });
          })
          .catch((error) => {
            this.setError(error, "Ошибка при обновлении местонахождения авто!");
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: "Ошибка при обновлении местонахождения авто!",
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateCarState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let car = this.free_cars_start_dates.find((car) => car.car_id == id);
        if (car) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/cars/${id}`,
              {
                cars: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.free_cars_start_dates.find((car) => car.car_id == id).state =
                state;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("cars.change_state"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.car_state_update"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.car_state_update"),
            text: this.$t("errors.car_not_found"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateTireType(id, tire_type) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (id) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/cars/${id}`,
              {
                cars: {
                  tire_type: tire_type,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.free_cars_start_dates.find(
                (car) => car.car_id == id
              ).tire_type = tire_type;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.change_car_tire_type"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateCleanState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/cars/${id}`,
            {
              cars: {
                clean_state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.free_cars_start_dates.find(
              (car) => car.car_id == id
            ).clean_state = state;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.car_state_update"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateTankState(id, state) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/cars/${id}`,
            {
              cars: {
                tank_state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.free_cars_start_dates.find(
              (car) => car.car_id == id
            ).tank_state = state;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.car_state_update"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    repairEnd(repair) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("services.repair_ended"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("services.repair_ended_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/cars/${repair.car_id}/repairs/${repair.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                let repairs = self.free_cars_start_dates.find(
                  (car) => car.car_id === repair.car_id
                ).repairs;
                let index = repairs.indexOf(repair);
                if (index !== -1) {
                  repairs.splice(index, 1);
                }
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.repair_delete"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("services.repair_ended_title"),
              text: this.$t("services.repair_ended_text"),
            });
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getCar(car) {
      if (car) {
        this.isLoading = true;
        this.axios
          .get(`/api/v1/cars/${car.car_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.editedCar = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.load_cars"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    saveCar() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        let car = this.editedCar;
        this.axios
          .patch(
            `/api/v1/cars/${car.id}`,
            {
              cars: {
                oil_engine: car.oil_engine,
                oil_engine_date: car.oil_engine_date,
                oil_transmission: car.oil_transmission,
                oil_transmission_date: car.oil_transmission_date,
                grm: car.grm,
                grm_km: car.grm_km,
                grm_date: car.grm_date,
                antifreeze: car.antifreeze,
                antifreeze_km: car.antifreeze_km,
                antifreeze_date: car.antifreeze_date,
                brake_fluid: car.brake_fluid,
                brake_fluid_km: car.brake_fluid_km,
                brake_fluid_date: car.brake_fluid_date,
                candle: car.candle,
                candle_km: car.candle_km,
                candle_date: car.candle_date,
                power_steering: car.power_steering,
                power_steering_km: car.power_steering_km,
                power_steering_date: car.power_steering_date,
                salon_filter_renew: car.salon_filter_renew,
                salon_filter_renew_km: car.salon_filter_renew_km,
                salon_filter_renew_date: car.salon_filter_renew_date,
                akb_renew: car.akb_renew,
                akb_renew_km: car.akb_renew_km,
                akb_renew_date: car.akb_renew_date,
                fuel_filter_renew: car.fuel_filter_renew,
                fuel_filter_renew_km: car.fuel_filter_renew_km,
                fuel_filter_renew_date: car.fuel_filter_renew_date,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.dialog_oil_engine = false;
            this.dialog_oil_transmission = false;
            this.dialog_grm = false;
            this.dialog_antifreeze = false;
            this.dialog_brake = false;
            this.dialog_candle = false;
            this.dialog_power_steering = false;
            this.dialog_salon_filter = false;
            this.dialog_akb = false;
            this.dialog_fuel_filter = false;

            this.editedCar = {};

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("services.maked"),
              text: this.$t("services.maked_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.update_car")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkOilEngine(car) {
      if (car.mileage - car.oil_engine > car.oil_engine_km - 1000) {
        return true;
      }
    },
    checkOilEngineColor(car) {
      let diff = car.mileage - car.oil_engine;
      if (diff >= car.oil_engine_km - 1000 && diff < car.oil_engine_km) {
        return "black--text yellow accent-2";
      }
      if (diff >= car.oil_engine_km) {
        this.checkWarning = true;
        return "black--text red accent-2";
      }
    },
    checkOilTransmission(car) {
      if (car.mileage - car.oil_transmission > car.oil_transmission_km) {
        return true;
      }
    },
    checkGrm(car) {
      if (car.mileage - car.grm > car.grm_km - 5000) {
        return true;
      }
    },
    checkGrmColor(car) {
      let diff = car.mileage - car.grm;
      if (diff >= car.grm_km - 5000 && diff < car.grm_km) {
        return "black--text yellow accent-2";
      }
      if (diff >= car.grm_km) {
        this.checkWarning = true;
        return "black--text red accent-2";
      }
    },
    checkAntifreeze(car) {
      if (car.mileage - car.antifreeze > car.antifreeze_km) {
        return true;
      }
    },
    checkBrake(car) {
      if (car.mileage - car.brake_fluid > car.brake_fluid_km) {
        return true;
      }
    },
    checkCandle(car) {
      if (car.mileage - car.candle > car.candle_km) {
        return true;
      }
    },
    checkPowerSteering(car) {
      if (car.mileage - car.power_steering > car.power_steering_km) {
        return true;
      }
    },
    checkSalonFilter(car) {
      if (car.mileage - car.salon_filter_renew > car.salon_filter_renew_km) {
        return true;
      }
    },
    checkAkb(car) {
      if (car.mileage - car.akb_renew > car.akb_renew_km) {
        return true;
      }
    },
    checkFuelFilter(car) {
      if (car.mileage - car.fuel_filter_renew > car.fuel_filter_renew_km) {
        return true;
      }
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` }).catch((err) => {});
    },
    getCloseDate(id) {
      let start_date = this.free_cars_start_dates.find(
        (car) => car.car_id == id
      );
      if (start_date) {
        return start_date.start_date;
      }
    },
    formatedDates(date) {
      return moment(date, "YYYY-MM-DD H:mm").format("H:mm, DD.MM.YYYY");
    },
    formatedDatesLastInspection(date) {
      return moment(date, "YYYY-MM-DD").format("DD MMM");
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    formatBookingDates(date) {
      return moment(date, "DD-MM-YYYY H:mm").format("DD MMM H:mm");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {},
};
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
.toolbar {
  background-color: white;
}
.is-mobile .v-data-footer__select {
  margin-right: 0 !important;
}
.is-mobile .v-data-footer__icons-before {
  margin: 0 auto !important;
}
.is-mobile .v-data-footer__icons-after {
  margin: 0 auto !important;
}
</style>
