<template>
  <v-container
    grid-list-md
    text-center
    v-resize="checkMobile"
    :style="isMobile ? 'padding: 3px !important;' : ''"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card
      v-if="
        $store.getters.getMainCompany &&
        $store.getters.getMainCompany.payer_state != 3
      "
    >
      <v-card-title
        id="registration-step-48"
        style="padding-bottom: 0px !important"
      >
        <v-flex v-if="!booking.technical || !isMobile">
          <h2 v-if="!isMobile">{{ $t("bookings.new_booking") }}</h2>
          <h4 v-else>{{ $t("bookings.new_booking") }}</h4>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex
          :class="
            isMobile ? 'd-flex justify-space-between' : 'd-flex justify-end'
          "
        >
          <v-switch v-model="booking.technical" :label="$t('bookings.tech')">
          </v-switch>
          <v-switch
            v-if="booking.technical"
            v-model="booking.repair"
            class="ml-2"
            color="warning"
            :label="$t('custom.repair')"
          >
          </v-switch>
        </v-flex>
      </v-card-title>
      <v-card-text
        v-if="!booking.technical && set_car"
        style="padding-bottom: 0px !important"
      >
        <div
          v-if="set_car && booking.days && !booking.technical"
          class="registration-step-60"
          id="calculation1"
        >
          {{ $t("bookings.days_size") }}:
          <span id="days">{{ booking.days }}</span
          >.
          <span v-if="tariff"
            >{{ $t("bookings.tariff") }}: {{ tariff.name }}.</span
          >
          {{ $t("bookings.calc_cost") }}:
          <span id="price_no_sale">{{ booking.price_no_sale }}</span
          >{{ currency }}.
          <span v-if="booking.sale > 0 || booking.sale_cash > 0"
            >{{ $t("bookings.cost_sale") }}:
            <span id="selected_price">{{ booking.selected_price }}</span
            >{{ currency }}.
          </span>
          <span
            v-if="
              tariff &&
              (tariff.sum > 0 ||
                tariff.sum < 0 ||
                tariff.percent > 0 ||
                tariff.percent < 0)
            "
            >{{ $t("bookings.cost_tariff") }}:
            <span id="selected_price">{{ booking.selected_price }}</span
            >{{ currency }}</span
          >
        </div>
        <v-flex md12 v-if="!booking.technical">
          <p v-if="booking.total" class="font-weight-bold">
            {{ $t("bookings.for_pay_2") }}: {{ $t("custom.rent_low") }}
            {{ booking.rental_cost }}{{ currency }}
            <span v-if="booking.delivery > 0"
              >+ {{ $t("custom.delivery_low") }} {{ booking.delivery
              }}{{ currency }}
            </span>
            <span v-if="booking.delivery_end > 0"
              >+ {{ $t("custom.checkout_low") }} {{ booking.delivery_end
              }}{{ currency }}
            </span>
            <span v-if="booking.clean_payment > 0"
              >+ {{ $t("custom.clean_low") }} {{ booking.clean_payment
              }}{{ currency }}
            </span>
            <span v-if="booking.hours_cost > 0"
              >+ {{ $t("custom.add_hours_low") }} {{ booking.hours_cost
              }}{{ currency }}
            </span>
            <span v-if="booking.equipment > 0"
              >+ {{ $t("custom.equipment_low") }} {{ booking.equipment
              }}{{ currency }}
            </span>
            <span v-if="booking.insurance > 0"
              >+ {{ $t("custom.insurance_low") }} {{ booking.insurance
              }}{{ currency }}
            </span>
            <span v-if="booking.add_drivers_cost > 0"
              >+ {{ $t("custom.add_drivers_low") }} {{ booking.add_drivers_cost
              }}{{ currency }}
            </span>
            <span v-if="booking.other > 0"
              >+ другое {{ booking.other }}{{ currency }}
            </span>
            <span>
              + {{ $t("custom.deposit_low") }} {{ booking.deposit
              }}{{ currency }}
            </span>
            <span>
              = {{ $t("custom.total") }} {{ booking.total + booking.deposit
              }}{{ currency }}
            </span>
          </p>
        </v-flex>
        <div
          v-if="set_car && booking.additional_hours > 0 && !booking.technical"
        >
          {{ $t("custom.add_hours") }}:
          <span id="additional_hours">{{ booking.additional_hours }}</span
          >. {{ $t("custom.price_hour") }}: {{ booking.price_hour
          }}{{ currency }}. {{ $t("custom.for_add_hours") }}:
          <span id="hours_cost">{{ booking.hours_cost }}</span
          >{{ currency }}
        </div>
        <div
          v-if="warningAddOneDay && !booking.technical"
          class="red--text"
          id="warning_more_then_day"
        >
          {{ $t("bookings.warn_add_day") }}
        </div>
        <div v-if="warningLessOneDay" class="red--text">
          {{ $t("bookings.warn_less_day") }}
        </div>
        <v-flex
          xs12
          sm12
          md12
          v-if="
            !booking.technical &&
            selected_seasons &&
            selected_seasons.length > 0
          "
        >
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header style="padding: 2px 0 !important">
                {{ $t("companies.prices_seasons") }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="item in selected_seasons" :key="item.index">
                        <th class="text-left">
                          {{ item.season.start_date }} -
                          {{ item.season.end_date }}
                        </th>
                        <td class="text-left">
                          {{ item.days }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
      </v-card-text>
      <v-card-text style="padding-top: 0px !important">
        <v-form>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm6 md6 id="select_car" class="registration-step-49">
                <h4 class="mt-2">{{ $t("custom.car_long") }}</h4>
                <v-autocomplete
                  v-model="selected_car"
                  item-text="code"
                  item-value="id"
                  data-cy="select_car"
                  prepend-icon="mdi-car"
                  flat
                  @change="setCar"
                  hide-no-data
                  hide-details
                  :label="$t('custom.select_car')"
                  :items="cars"
                >
                  <template slot="selection" slot-scope="data">
                    <!-- HTML that describe how select should render selected items -->
                    {{ data.item.code }} - {{ data.item.number }}
                  </template>
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.code }} - {{ data.item.number }}
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <h4 class="mt-2">{{ $t("custom.client") }}</h4>
                <div class="d-flex" id="select_client" v-if="showClients">
                  <v-autocomplete
                    v-model="client"
                    item-text="fullname"
                    item-value="id"
                    :loading="isLoadingClients"
                    :search-input.sync="search"
                    data-cy="select_client"
                    clearable
                    flat
                    hide-no-data
                    hide-details
                    :label="$t('custom.select_client')"
                    :items="clients"
                    :prepend-icon="
                      isLoadingClients ? 'mdi-sync' : 'mdi-account-box'
                    "
                    return-object
                  >
                  </v-autocomplete>
                  <v-icon
                    id="registration-step-51"
                    class="mt-3"
                    @click="
                      (newClientDialog = true),
                        (client = { category: 'Новый', country: 'Россия' })
                    "
                    v-tooltip="{
                      content: $t('custom.add_new_client'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    >mdi-account-plus-outline</v-icon
                  >
                </div>
                <v-text-field
                  slot="activator"
                  v-model="client.fio"
                  :label="$t('custom.created_client')"
                  disabled
                  v-if="!showClients && client && !client.entity"
                  id="registration-step-57"
                ></v-text-field>
                <v-text-field
                  slot="activator"
                  v-model="client.entity_name"
                  :label="$t('custom.created_client')"
                  disabled
                  v-if="!showClients && client && client.entity"
                  id="registration-step-57"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-row wrap class="mt-4">
              <v-flex xs12 sm6 md6 id="registration-step-58">
                <h4 class="mt-2">{{ $t("custom.start_rent_date") }}</h4>
                <vc-date-picker
                  v-model="dateStart"
                  mode="dateTime"
                  :model-config="calendarConfig"
                  :attributes="calendar_attributes"
                  is24hr
                  title-position="left"
                  :columns="$screens({ default: 1, lg: 2 })"
                  class="flex d-flex md12 p-0"
                  :locale="$i18n.locale"
                >
                  <template
                    v-slot="{
                      inputValue,
                      inputEvents,
                      togglePopover,
                      isDragging,
                      showPopover,
                    }"
                  >
                    <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                      <v-text-field
                        @click="showPopover"
                        @input="togglePopover()"
                        :class="
                          isDragging
                            ? 'text-gray-600 mr-2 p-0'
                            : 'text-gray-900 mr-2 p-0'
                        "
                        :value="inputValue"
                        v-on="inputEvents"
                        :label="$t('custom.start_rent_datetime')"
                        prepend-icon="mdi-calendar-clock"
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </template>
                </vc-date-picker>
              </v-flex>
              <v-flex xs12 sm6 md6 id="registration-step-59">
                <h4 class="mt-2">{{ $t("custom.end_rent_date") }}</h4>
                <vc-date-picker
                  v-model="dateEnd"
                  mode="dateTime"
                  :model-config="calendarConfig"
                  :attributes="calendar_attributes"
                  :min-date="minDate"
                  is24hr
                  title-position="left"
                  :columns="$screens({ default: 1, lg: 2 })"
                  class="flex d-flex md12 p-0"
                  :locale="$i18n.locale"
                >
                  <template
                    v-slot="{
                      inputValue,
                      inputEvents,
                      togglePopover,
                      isDragging,
                      showPopover,
                    }"
                  >
                    <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                      <v-text-field
                        @click="showPopover"
                        @input="togglePopover()"
                        :class="
                          isDragging
                            ? 'text-gray-600 mr-2 p-0'
                            : 'text-gray-900 mr-2 p-0'
                        "
                        :value="inputValue"
                        v-on="inputEvents"
                        :label="$t('custom.end_rent_datetime')"
                        prepend-icon="mdi-calendar-clock"
                        readonly
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </template>
                </vc-date-picker>
              </v-flex>
            </v-row>
            <!-- <v-divider></v-divider> -->
            <v-row wrap class="mt-4" id="registration-step-61">
              <v-flex xs12 sm6 md6>
                <h4 class="mt-2">{{ $t("bookings.start_place") }}</h4>
                <v-text-field
                  @input="extra = true"
                  v-model="booking.location_start"
                  prepend-icon="mdi-map-marker"
                  :label="$t('bookings.start_place')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <h4 class="mt-2">{{ $t("bookings.end_place") }}</h4>
                <v-text-field
                  @input="extra = true"
                  v-model="booking.location_end"
                  prepend-icon="mdi-map-marker"
                  :label="$t('bookings.end_place')"
                ></v-text-field>
              </v-flex>
            </v-row>
            <v-layout
              v-if="!booking.technical"
              row
              wrap
              class="align-center"
              id="registration-step-62"
            >
              <v-flex xs12 md2>
                <v-switch
                  :label="$t('bookings.tariff')"
                  v-model="showTariff"
                  v-tooltip="{
                    content: $t('bookings.tariff_desc'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                ></v-switch>
              </v-flex>
              <v-flex xs12 md2>
                <v-switch
                  :label="$t('bookings.sale')"
                  v-model="sale"
                  v-tooltip="{
                    content: $t('bookings.sale_desc'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                ></v-switch>
              </v-flex>
              <v-flex xs12 md2>
                <v-switch
                  v-model="showManualEditing"
                  color="error"
                  @change="manual_editing = !manual_editing"
                  :label="$t('bookings.calc_manual')"
                  v-tooltip="{
                    content: $t('bookings.calc_manual_data'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                ></v-switch>
              </v-flex>
              <v-flex xs12 md2>
                <v-switch
                  :label="$t('bookings.add_services')"
                  v-model="extra"
                ></v-switch>
              </v-flex>
              <v-flex xs12 md2>
                <v-switch
                  v-model="showDescription"
                  :label="$t('bookings.add_info_short')"
                ></v-switch>
              </v-flex>
              <v-flex xs12 md2>
                <v-switch
                  v-model="showAddDrivers"
                  @change="extra = true"
                  :label="$t('bookings.add_drivers_2')"
                  v-tooltip="{
                    content: $t('bookings.add_drivers'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-space-around class="align-center">
              <v-layout
                row
                wrap
                v-if="showManualEditing || manual_editing"
                class="align-center"
              >
                <v-flex md12>
                  <p class="red--text">{{ $t("bookings.warn_manual_2") }}</p>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.days"
                    :value="booking.days"
                    :label="$t('bookings.days_count')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.selected_price"
                    :value="booking.selected_price"
                    :label="$t('bookings.day_price')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.rental_cost"
                    :value="booking.rental_cost"
                    :label="$t('bookings.cost_for_day')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.total"
                    :value="booking.total"
                    :label="$t('bookings.cost_without_deposit')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.deposit"
                    :value="booking.deposit"
                    :label="$t('bookings.deposit')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.additional_hours"
                    :value="booking.additional_hours"
                    :label="$t('bookings.add_hours_count')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.price_hour"
                    :value="booking.price_hour"
                    :label="$t('bookings.price_for_hour')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.hours_cost"
                    :value="booking.hours_cost"
                    :label="$t('custom.for_add_hours')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.delivery"
                    :value="booking.delivery"
                    :label="$t('bookings.delivery')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.delivery_end"
                    :value="booking.delivery_end"
                    :label="$t('bookings.checkout')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.equipment"
                    :value="booking.equipment"
                    :label="$t('bookings.add_equipment')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.insurance"
                    :value="booking.insurance"
                    :label="$t('bookings.add_insurance')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.clean_payment"
                    :value="booking.clean_payment"
                    :label="$t('bookings.clean')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.add_drivers_cost"
                    :value="booking.add_drivers_cost"
                    :label="$t('bookings.add_drivers_small')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.other"
                    :value="booking.other"
                    :label="$t('bookings.other')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                v-if="showManualEditing || manual_editing"
                class="align-center"
              >
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    v-model="booking.last_name"
                    :label="$t('bookings.client_lastname')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    v-model="booking.first_name"
                    :label="$t('bookings.client_name')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    v-model="booking.middle_name"
                    :label="$t('bookings.client_middlename')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-flex xs12 sm12 md12 v-if="showTariff">
                <h4 class="mt-2">{{ $t("bookings.tariff") }}</h4>
                <v-select
                  v-model="selected_tariff"
                  :items="tariffs"
                  item-text="fullname"
                  item-value="id"
                  :label="$t('bookings.tariff')"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md12 v-if="showAddDrivers" class="mb-3">
                <h3 class="mt-2">{{ $t("bookings.add_drivers") }}</h3>
                <div class="d-flex">
                  <v-autocomplete
                    v-model="booking.add_drivers"
                    multiple
                    item-text="fullname"
                    item-value="id"
                    :loading="isLoadingClientsAddDriver"
                    :search-input.sync="search_add_driver"
                    no-filter
                    flat
                    clearable
                    hide-no-data
                    hide-details
                    :label="$t('custom.select_client')"
                    :items="clients_add_drivers"
                    prepend-icon="mdi-account-box"
                  >
                  </v-autocomplete>
                  <v-icon
                    class="mt-3"
                    @click="(newClientDialog = true), (client = [])"
                    v-tooltip="{
                      content: $t('custom.add_new_client'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    >mdi-account-plus-outline</v-icon
                  >
                </div>
              </v-flex>
              <v-flex md12 v-if="extra && !booking.technical">
                <h3 class="mt-2">{{ $t("custom.add_services") }}</h3>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
                class="d-flex d-inline-flex justify-space-between align-center"
                v-if="extra && !booking.technical"
              >
                <h3>{{ $t("bookings.add_equipment") }}</h3>
                <v-checkbox
                  :label="$t('bookings.baby_chair')"
                  v-model="booking.chair"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.small_baby_chair')"
                  v-model="booking.chair_less_1_year"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.booster')"
                  v-model="booking.booster"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.navigator')"
                  v-model="booking.navigator"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.mp3')"
                  v-model="booking.mp3"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.charger')"
                  v-model="booking.charger"
                  v-tooltip="{
                    content: $t('bookings.charger_desc'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }"
                ></v-checkbox>
              </v-flex>
              <v-flex
                md12
                :class="isMobile ? 'align-center' : 'd-flex align-center'"
                justify-space-between
                v-if="
                  booking.chair || booking.chair_less_1_year || booking.booster
                "
              >
                <v-text-field
                  :disabled="!booking.chair && !booking.chair_less_1_year"
                  v-model="booking.chairs_quantity"
                  :label="$t('bookings.baby_chair_qnt')"
                  prepend-icon="mdi-map-marker"
                ></v-text-field>
                <v-text-field
                  :disabled="!booking.booster"
                  v-model="booking.boosters_quantity"
                  :label="$t('bookings.booster_qnt')"
                  prepend-icon="mdi-map-marker"
                ></v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
                class="d-flex d-inline-flex justify-space-between align-center"
                v-if="extra && !booking.technical"
              >
                <h3>{{ $t("bookings.extra_insurance") }}</h3>
                <v-checkbox
                  :label="$t('bookings.kasko')"
                  v-model="booking.kasko"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.super_kasko')"
                  v-model="booking.super_kasko"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.theft')"
                  v-model="booking.theft"
                ></v-checkbox>
                <v-checkbox
                  :label="$t('bookings.no_franchise')"
                  v-model="booking.no_franchise"
                ></v-checkbox>
              </v-flex>
              <v-flex md12 v-if="sale">
                <h3 class="mt-2">{{ $t("bookings.edit_sale_2") }}</h3>
              </v-flex>
              <v-flex xs12 sm6 md6 v-if="sale">
                <v-text-field
                  v-model.number="booking.sale"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.edit_sale_percent')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 v-if="sale">
                <v-text-field
                  v-model.number="booking.sale_cash"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.edit_sale_rub', { msg: currency })"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 v-if="sale">
                <v-text-field
                  v-model.number="booking.rental_cost_sale"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.edit_sale_rent_percent')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6 v-if="sale">
                <v-text-field
                  v-model.number="booking.rental_cost_sale_cash"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.edit_sale_rent_rub', { msg: currency })"
                ></v-text-field>
              </v-flex>
              <v-flex md12 v-if="extra && !booking.technical">
                <h3 class="mt-2">{{ $t("bookings.add_services_cost") }}</h3>
              </v-flex>
              <v-flex xs12 sm2 md2 v-if="extra && !booking.technical">
                <v-text-field
                  v-model.number="booking.delivery"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.delivery_cost')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md2 v-if="extra && !booking.technical">
                <v-text-field
                  v-model.number="booking.delivery_end"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.checkout_cost')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md2 v-if="extra && !booking.technical">
                <v-text-field
                  v-model.number="booking.equipment"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.equipment_cost')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md2 v-if="extra && !booking.technical">
                <v-text-field
                  v-model.number="booking.insurance"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.insurance_cost')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md2 v-if="extra && !booking.technical">
                <v-text-field
                  v-model.number="booking.clean_payment"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.clean')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md2 v-if="extra && !booking.technical">
                <v-text-field
                  v-model.number="booking.add_drivers_cost"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.drivers_cost')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md2 v-if="extra && !booking.technical">
                <v-text-field
                  v-model.number="booking.other"
                  @blur="calculateCostWithExtra()"
                  :label="$t('bookings.other')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12>
                <v-textarea
                  v-model="booking.description"
                  v-if="showDescription || booking.technical"
                  clearable
                  auto-grow
                  rows="1"
                  :label="$t('bookings.booking_desc')"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="booking.custom_field_1"
                  v-if="showDescription"
                  clearable
                  auto-grow
                  rows="1"
                  :label="$t('bookings.template_field_1')"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="booking.custom_field_2"
                  v-if="showDescription"
                  clearable
                  auto-grow
                  rows="1"
                  :label="$t('bookings.template_field_2')"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="booking.custom_field_3"
                  v-if="showDescription"
                  clearable
                  auto-grow
                  rows="1"
                  :label="$t('bookings.template_field_3')"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="!booking.technical">
              <v-flex md12>
                <p v-if="booking.total" class="font-weight-bold">
                  {{ $t("bookings.for_pay_2") }}: {{ $t("custom.rent_low") }}
                  {{ booking.rental_cost }}{{ currency }}
                  <span v-if="booking.delivery > 0"
                    >+ {{ $t("custom.delivery_low") }} {{ booking.delivery
                    }}{{ currency }}
                  </span>
                  <span v-if="booking.delivery_end > 0"
                    >+ {{ $t("custom.checkout_low") }} {{ booking.delivery_end
                    }}{{ currency }}
                  </span>
                  <span v-if="booking.hours_cost > 0"
                    >+ {{ $t("custom.add_hours_low") }} {{ booking.hours_cost
                    }}{{ currency }}
                  </span>
                  <span v-if="booking.equipment > 0"
                    >+ {{ $t("custom.equipment_low") }} {{ booking.equipment
                    }}{{ currency }}
                  </span>
                  <span v-if="booking.insurance > 0"
                    >+ {{ $t("custom.insurance_low") }} {{ booking.insurance
                    }}{{ currency }}
                  </span>
                  <span v-if="booking.clean_payment > 0"
                    >+ {{ $t("custom.clean_low") }} {{ booking.clean_payment
                    }}{{ currency }}
                  </span>
                  <span v-if="booking.add_drivers_cost > 0"
                    >+ {{ $t("custom.add_drivers_low") }}
                    {{ booking.add_drivers_cost }}{{ currency }}
                  </span>
                  <span v-if="booking.other > 0"
                    >+ {{ $t("custom.other_low") }} {{ booking.other
                    }}{{ currency }}
                  </span>
                  <span>
                    + {{ $t("custom.deposit_low") }} {{ booking.deposit
                    }}{{ currency }}
                  </span>
                  <span>
                    = {{ $t("bookings.total") }}
                    {{ booking.total + booking.deposit }}{{ currency }}
                  </span>
                </p>
              </v-flex>
            </v-layout>
            <v-btn
              id="registration-step-63"
              large
              color="success"
              @click.prevent="submit()"
              >{{ $t("custom.create") }}</v-btn
            >
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <h2>{{ $t("bookings.new_booking") }}</h2>
      <p>{{ $t("bookings.warn_balance") }}</p>
    </v-card>
    <v-dialog
      v-model="newClientDialog"
      :retain-focus="false"
      persistent
      max-width="1200px"
      :fullscreen="isMobile"
    >
      <v-card v-if="client && (!short_client_view || show_full_fields)">
        <v-card-title>
          <v-flex>
            <h2 v-if="!isMobile">{{ $t("custom.add_client_2") }}</h2>
            <h3 v-else>
              {{ $t("custom.add_client_2") }}
            </h3>
          </v-flex>
          <v-flex v-if="isMobile" class="ml-5">
            <v-icon @click="newClientDialog = false">mdi-close</v-icon>
          </v-flex>
          <v-spacer></v-spacer>
          <v-switch
            v-model="client.entity"
            :label="$t('custom.company_client')"
          ></v-switch>
          <v-switch
            v-if="short_client_view"
            v-model="show_full_fields"
            :label="$t('clients.show_full_fields')"
            class="ml-3"
          ></v-switch>
          <v-icon v-if="!isMobile" class="ml-3" @click="newClientDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-text v-if="!client.entity">
          <v-container grid-list-md>
            <v-layout row wrap id="registration-step-52">
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.lastname"
                  :label="$t('bookings.lastname')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.name"
                  :label="$t('bookings.name')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.middlename"
                  :label="$t('bookings.middlename')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-select
                  v-if="clients_sources"
                  v-model="client.source"
                  :items="clients_sources"
                  :label="$t('custom.source')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.phone"
                  @blur="checkUniqClientPhone"
                  :label="$t('bookings.main_phone')"
                  v-mask="'############'"
                  type="tel"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.email"
                  @blur="checkUniqClientEmail"
                  :label="$t('custom.email')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="birthday_non_format"
                  @blur="checkAge(birthday_non_format)"
                  v-mask="'##.##.####'"
                  :label="$t('bookings.birthdate')"
                  :placeholder="$t('bookings.birthdate_plc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-select
                  v-model="client.category"
                  :items="customer_category"
                  item-text="text"
                  item-value="value"
                  :label="$t('bookings.client_category')"
                  :value="$t('customer_category.new')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md2>
                <v-text-field
                  v-model="client.passport_series"
                  :label="$t('custom.passport_series')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.passport_number"
                  :label="$t('custom.passport_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.passport_issued"
                  :label="$t('custom.passport_issued')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.inn"
                  :label="$t('custom.tax_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-autocomplete
                  v-model="client.country"
                  flat
                  hide-no-data
                  hide-details
                  :label="$t('custom.country')"
                  :items="countries"
                  item-text="native"
                  item-value="name"
                  outlined
                >
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.city"
                  :label="$t('custom.city')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.address"
                  :label="$t('custom.address')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.driver_series"
                  :label="$t('custom.license_series')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.driver_number"
                  :label="$t('custom.license_number')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.driver_issued"
                  :label="$t('custom.license_issued')"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md6>
                <v-text-field
                  v-model="client.sale"
                  :label="$t('custom.sale_perc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md6>
                <v-text-field
                  v-model="client.sale_cash"
                  :label="$t('custom.sale_rub', { currency: currency })"
                  outlined
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex>
                <v-textarea
                  v-model="client.dop_info"
                  clearable
                  :label="$t('other.add_client_info_2')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <v-container grid-list-md>
            <v-container v-if="client">
              <v-layout row wrap>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.entity_name"
                    :label="$t('custom.company_name')"
                    :placeholder="$t('custom.company_name_placeholder')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.short_entity_name"
                    :label="$t('custom.company_name_short')"
                    :placeholder="$t('custom.company_name_short_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.reg_form"
                    :label="$t('custom.reg_form')"
                    :placeholder="$t('custom.reg_form_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.inn"
                    :label="$t('custom.tax_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.ogrn"
                    :label="$t('custom.entity_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.ceo"
                    :label="$t('custom.face')"
                    :placeholder="$t('custom.face_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    v-model.trim="client.entity_phone"
                    :label="$t('custom.entity_phone')"
                    type="tel"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-select
                    v-model="client.country"
                    :items="countries"
                    :label="$t('custom.country')"
                    item-text="native"
                    item-value="name"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.city"
                    :label="$t('custom.city')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md5>
                  <v-textarea
                    v-model.trim="client.entity_adress"
                    :label="$t('custom.entity_address')"
                    outlined
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    v-model.trim="client.acc_number"
                    :label="$t('custom.entity_bank_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.bank"
                    :label="$t('custom.bank_name')"
                    :placeholder="$t('custom.bank_name_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    v-model.trim="client.korr"
                    :label="$t('custom.bank_correspondent')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.bik"
                    :label="$t('custom.bank_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.doc_number"
                    :label="$t('custom.doc_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="registration-step-56"
            color="success"
            @click="submitClient()"
            >{{ $t("custom.save") }}</v-btn
          >
          <v-btn @click="newClientDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <v-flex>
            <h2 v-if="!isMobile">{{ $t("custom.add_client_2") }}</h2>
            <h3 v-else>
              {{ $t("custom.add_client_2") }}
            </h3>
          </v-flex>
          <v-flex class="ml-5" v-if="isMobile">
            <v-icon @click="newClientDialog = false">mdi-close</v-icon>
          </v-flex>
          <v-switch
            v-model="client.entity"
            :label="$t('custom.company_client')"
          ></v-switch>
          <v-switch
            v-if="short_client_view"
            v-model="show_full_fields"
            :label="$t('clients.show_full_fields')"
            class="ml-3"
          ></v-switch>
          <v-icon v-if="!isMobile" class="ml-3" @click="newClientDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>

        <v-card-text v-if="!client.entity">
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.lastname"
                  :label="$t('bookings.lastname')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.name"
                  :label="$t('bookings.name')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.middlename"
                  :label="$t('bookings.middlename')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-select
                  v-if="clients_sources"
                  v-model="client.source"
                  :items="clients_sources"
                  :label="$t('custom.source')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.phone"
                  @blur="checkUniqClientPhone"
                  :label="$t('bookings.main_phone')"
                  v-mask="'############'"
                  type="tel"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.email"
                  @blur="checkUniqClientEmail"
                  :label="$t('custom.email')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="birthday_non_format"
                  @blur="checkAge(birthday_non_format)"
                  v-mask="'##.##.####'"
                  :label="$t('bookings.birthdate')"
                  :placeholder="$t('bookings.birthdate_plc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-select
                  v-model="client.category"
                  :items="customer_category"
                  item-text="text"
                  item-value="value"
                  :label="$t('bookings.client_category')"
                  :value="$t('customer_category.new')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-row justify="center">
                <v-btn color="success" @click="submitClient()">{{
                  $t("custom.save")
                }}</v-btn>
              </v-row>
            </v-card-actions>
            <v-expansion-panels class="mb-3 mt-5">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("clients.extra_fields") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout row wrap>
                    <v-flex xs12 sm12 md2>
                      <v-text-field
                        v-model="client.passport_series"
                        :label="$t('custom.passport_series')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-text-field
                        v-model="client.passport_number"
                        :label="$t('custom.passport_number')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        v-model="client.passport_issued"
                        :label="$t('custom.passport_issued')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md3>
                      <v-text-field
                        v-model="client.inn"
                        :label="$t('custom.tax_number')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-autocomplete
                        v-model="client.country"
                        flat
                        hide-no-data
                        hide-details
                        :label="$t('custom.country')"
                        :items="countries"
                        item-text="native"
                        item-value="name"
                        outlined
                      >
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        v-model="client.city"
                        :label="$t('custom.city')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        v-model="client.address"
                        :label="$t('custom.address')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        v-model="client.driver_series"
                        :label="$t('custom.license_series')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        v-model="client.driver_number"
                        :label="$t('custom.license_number')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md4>
                      <v-text-field
                        v-model="client.driver_issued"
                        :label="$t('custom.license_issued')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex>
                      <v-textarea
                        v-model="client.dop_info"
                        clearable
                        :label="$t('other.add_client_info_2')"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  {{ $t("clients.sale_fields") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-layout row wrap>
                    <v-flex xs12 sm12 md6>
                      <v-text-field
                        v-model="client.sale"
                        :label="$t('custom.sale_perc')"
                        outlined
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 sm12 md6>
                      <v-text-field
                        v-model="client.sale_cash"
                        :label="$t('custom.sale_rub', { currency: currency })"
                        outlined
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-card-text v-else>
          <v-container grid-list-md>
            <v-container v-if="client">
              <v-layout row wrap>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.entity_name"
                    :label="$t('custom.company_name')"
                    :placeholder="$t('custom.company_name_placeholder')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.short_entity_name"
                    :label="$t('custom.company_name_short')"
                    :placeholder="$t('custom.company_name_short_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.reg_form"
                    :label="$t('custom.reg_form')"
                    :placeholder="$t('custom.reg_form_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.inn"
                    :label="$t('custom.tax_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.ogrn"
                    :label="$t('custom.entity_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-text-field
                    v-model.trim="client.ceo"
                    :label="$t('custom.face')"
                    :placeholder="$t('custom.face_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    v-model.trim="client.entity_phone"
                    :label="$t('custom.entity_phone')"
                    type="tel"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-select
                    v-model="client.country"
                    :items="countries"
                    :label="$t('custom.country')"
                    item-text="native"
                    item-value="name"
                    outlined
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.city"
                    :label="$t('custom.city')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md5>
                  <v-textarea
                    v-model.trim="client.entity_adress"
                    :label="$t('custom.entity_address')"
                    outlined
                    rows="1"
                    auto-grow
                  ></v-textarea>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    v-model.trim="client.acc_number"
                    :label="$t('custom.entity_bank_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.bank"
                    :label="$t('custom.bank_name')"
                    :placeholder="$t('custom.bank_name_plc')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md3>
                  <v-text-field
                    v-model.trim="client.korr"
                    :label="$t('custom.bank_correspondent')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.bik"
                    :label="$t('custom.bank_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md2>
                  <v-text-field
                    v-model.trim="client.doc_number"
                    :label="$t('custom.doc_number')"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="registration-step-56"
            color="success"
            @click="submitClient()"
            >{{ $t("custom.save") }}</v-btn
          >
          <v-btn @click="newClientDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
/* eslint-disable */
import { isMobile } from "mobile-device-detect";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { extendMoment } from "moment-range";

const moment_range = extendMoment(moment);
// moment.locale("ru");
import { countries } from "./../countries";
export default {
  data() {
    return {
      booking: {
        technical: false,
        repair: false,
        car_name: "",
        car_id: 0,
        car_code: "",
        start_date: null,
        end_date: null,
        client_id: null,
        user_id: null,
        sale: 0,
        sale_cash: 0,
        rental_cost_sale: 0,
        rental_cost_sale_cash: 0,
        days: null,
        additional_hours: 0,
        price_hour: 0,
        hours_cost: null,
        rental_cost: null,
        selected_price: null,
        delivery: 0,
        delivery_end: 0,
        clean_payment: 0,
        location_start: this.$t("custom.start_location"),
        location_end: this.$t("custom.start_location"),
        equipment: 0,
        add_drivers_cost: 0,
        insurance: 0,
        other: 0,
        total: null,
        deposit: null,
        description: "",
      },
      cars_bookings: [],
      car_bookings_dates: [],
      address: "",
      customer_category: [
        { text: this.$t("customer_category.new"), value: "Новый" },
        { text: this.$t("customer_category.loyal"), value: "Лояльный" },
        { text: this.$t("customer_category.statement"), value: "Постоянный" },
        { text: this.$t("customer_category.problem"), value: "Серый" },
        { text: this.$t("customer_category.bad"), value: "Чёрный" },
      ],
      start_date: null,
      start_date_with_time: null,
      hours: 0,
      days: 0,
      menu1: false,
      menu2: false,
      birthday_menu: false,
      birthday_date: null,
      birthday_non_format: null,
      end_date: null,
      periods: [],
      selected_prices: [],
      end_date_with_time: null,
      masks: {
        // dayPopover: "DD MMM, YYYY",
      },
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY H:mm", // Uses 'iso' if missing,
        timeAdjust: "12:00:00",
      },
      calendar_attributes: [
        {
          key: "today",
          highlight: {
            color: "red",
            fillMode: "outline",
          },
          dates: new Date(),
        },
      ],
      bookings_dates: [],
      menu3: false,
      menu4: false,
      cars: [],
      selected_car: null,
      selected_tariff: this.$t("custom.standart_tariff"),
      showTariff: false,
      showManualEditing: false,
      manual_editing: false,
      set_car: null,
      extra: false,
      sale: false,
      clients: [],
      check_clients: [],
      showDescription: false,
      show_payment: false,
      client: {
        name: "",
        lastname: "",
        middlename: "",
        phone: "",
        country: ["Russia"],
        city: "",
        address: "",
        sale: 0,
        sale_cash: 0,
        passport: "",
        driver: "",
        birthday: null,
      },
      showSelectCountry: false,
      seasons: [],
      selected_season: null,
      selected_seasons: [],
      selected_period: null,
      tariffs: [
        {
          name: this.$t("custom.standart_tariff"),
          fullname: this.$t("custom.standart_tariff"),
        },
      ],
      tariff: null,
      period_max_value: 31,
      add_drivers: [],
      clients_add_drivers: [],
      search_add_driver: null,
      countries: countries,
      newClientDialog: false,
      showClients: true,
      showAddDrivers: false,
      isLoading: true,
      error: "",
      descriptionLimit: 60,
      entries: [],
      isLoadingClients: false,
      isLoadingClientsAddDriver: false,
      model: null,
      search: null,
      startDate: null,
      endDate: null,
      dateStart: null,
      dateEnd: null,
      dateEndError: null,
      dateEndError: null,
      warningAddOneDay: false,
      warningLessOneDay: false,
      timeout: null,
      show_full_fields: false,
      isMobile: false,
      errors: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.axios
        .get("/api/v1/cars", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cars = [];
          response.data.data.forEach((element) => {
            if (element.attributes.active == true) {
              this.cars.push(element.attributes);
            }
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/company`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.periods = response.data.periods;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.company_load"));
          console.log(error);
        });
      this.axios
        .get(`api/v1/seasons`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.seasons = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.seasons_load"));
          console.log(error);
        });
      this.axios
        .get(`api/v1/tariffs`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          let tariff = response.data;
          tariff.forEach((item) => {
            let result;
            if (item.sum > 0 || item.sum < 0) {
              result = `${item.sum}${self.currency}`;
            } else {
              result = `${item.percent}%`;
            }
            return (item["fullname"] = `${item.name} | ${result}`);
          });
          this.tariffs = this.tariffs.concat(tariff);
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.tariffs_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
      this.$router.push({ path: `/signin` }).catch((err) => {});
    }
  },
  mounted: function () {
    if (this.$tours["registrationTour"]) {
      this.$tours["registrationTour"].nextStep();
    }
  },
  watch: {
    search() {
      if (this.client && this.client.id) {
        return;
      }
      if (this.search == "" || this.search == " ") {
        this.search = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadClients();
          }, 200); // delay
        }
        debounce();
      }
    },
    search_add_driver() {
      // if (this.booking.add_drivers && this.booking.add_drivers.) {
      //   return;
      // }
      if (this.search_add_driver == "" || this.search_add_driver == " ") {
        this.search_add_driver = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadClientsAddDrivers();
          }, 200); // delay
        }
        debounce();
      }
    },
    client() {
      // Нужно для того чтобы при выборе клиента из списка, не сбрасывался поиск
      if (this.client == null) {
        this.client = {
          name: "",
          lastname: "",
          middlename: "",
          phone: "",
          country: "",
          city: "",
          address: "",
          sale: 0,
          sale_cash: 0,
          passport: "",
          driver: "",
          birthday: null,
        };
      }
      if (this.client != "" && this.client != null) {
        this.booking.client_id = this.client.id;
        this.booking.first_name = this.client.name;
        this.booking.middle_name = this.client.middlename;
        this.booking.last_name = this.client.lastname;
        this.booking.sale = this.client.sale ? this.client.sale : 0;
        this.booking.sale_cash = this.client.sale_cash
          ? this.client.sale_cash
          : 0;
        this.booking.entity = this.client.entity;
        this.booking.entity_name = this.client.short_entity_name
          ? this.client.short_entity_name + ", " + this.client.reg_form
          : "";
        if (this.client && this.client.birthday) {
          this.checkAge(this.client.birthday);
        }
      }
    },
    dateEnd() {
      if (this.set_car) {
        // Обнуляем тариф и скидки
        this.booking.sale = 0;
        this.booking.sale_cash = 0;
        this.booking.rental_cost_sale = 0;
        this.booking.rental_cost_sale_cash = 0;
        this.booking.tariff_id = null;
        this.booking.tariff = null;
        this.selected_tariff = this.$t("custom.standart_tariff");
        if (
          this.dateStart &&
          moment
            .utc(this.dateStart, "DD-MM-YYYY H:mm")
            .isBefore(moment.utc(this.dateEnd, "DD-MM-YYYY H:mm"), "hour")
        ) {
          if (this.manual_editing == false) {
            let self = this;
            self.selected_period = null;
            let start_date = moment.utc(this.dateStart, "DD-MM-YYYY H:mm");
            let end_date = moment.utc(this.dateEnd, "DD-MM-YYYY H:mm");

            let hours = moment.duration(end_date.diff(start_date)).asHours();
            console.log("hours", hours);
            // this.checkFreeEndTime(end_date);
            let start_date_days = moment.utc(
              moment
                .utc(this.dateStart, "DD-MM-YYYY H:mm")
                .format("DD-MM-YYYY"),
              "DD-MM-YYYY"
            );
            let end_date_days = moment.utc(
              moment.utc(this.dateEnd, "DD-MM-YYYY H:mm").format("DD-MM-YYYY"),
              "DD-MM-YYYY"
            );

            this.booking.additional_hours = 0;
            console.log(
              "hours >moment.duration(end_date_days.diff(start_date_days)).asDays() * 24",
              hours,
              moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
            );
            if (
              hours >
              moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
            ) {
              this.hours = hours;
              let additionalHours = hours % 24;
              console.log("additionalHours", additionalHours);
              this.booking.additional_hours = Math.trunc(additionalHours);
            }
            if (
              hours <
              moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
            ) {
              this.hours = hours;
              let additionalHours = hours % 24;
              this.booking.additional_hours = Math.trunc(additionalHours);
            }
            let diff = Math.trunc(
              moment.duration(end_date.diff(start_date)).asDays()
            );

            if (!isNaN(diff)) {
              if (
                this.booking.additional_hours > 0 &&
                this.booking.additional_hours *
                  (this.booking.price_hour > 0
                    ? this.booking.price_hour
                    : this.set_car.price_hour) <
                  this.booking.selected_price
              ) {
                this.booking.hours_cost =
                  this.booking.additional_hours *
                  (this.booking.price_hour > 0
                    ? this.booking.price_hour
                    : this.set_car.price_hour);
              } else {
                this.booking.hours_cost = 0;
              }
              self.period_max_value = parseInt(
                self.periods[self.periods.length - 1].split(" - ")[1]
              )
                ? parseInt(
                    self.periods[self.periods.length - 1].split(" - ")[1]
                  )
                : 31;

              // Проверяем период

              function checkDiffInc() {
                self.periods.forEach((period) => {
                  let start = parseInt(period.split(" - ")[0]);
                  let end = parseInt(period.split(" - ")[1]);
                  if (diff >= start && diff <= end) {
                    self.selected_period = {
                      value: period,
                      index: self.periods.indexOf(period),
                    };
                  } else if (diff > self.period_max_value) {
                    // периодов на один меньше чем цен (+31)
                    self.selected_period = {
                      value: `${self.period_max_value}+`,
                      index: self.periods.length,
                    };
                  }
                });
              }

              checkDiffInc();
              if (this.dateStart) {
                // Находим сезон по стартовой дате либо если нет, но оставляем нулл
                self.selected_season = null;
                this.selected_seasons = [];
                function setSeason() {
                  self.seasons.forEach((season) => {
                    let check_start_date = start_date.format("DD.MM");
                    // Если есть дата окончания то проверяем на попадание в диапазон
                    let check_end_date = start_date.format("DD.MM");
                    if (end_date) {
                      check_end_date = end_date.format("DD.MM");
                    }
                    if (
                      self.$store.getters.getCompany.company
                        .season_start_calc &&
                      self.$store.getters.getCompany.company.season_end_calc
                    ) {
                      let start = moment
                        .utc(season.start_date, "DD.MM")
                        .set("year", start_date.format("YYYY"))
                        .startOf("day");
                      let end = moment
                        .utc(season.end_date, "DD.MM")
                        .set("year", start_date.format("YYYY"))
                        .endOf("day");
                      if (
                        end_date.format("YYYY") == start_date.format("YYYY")
                      ) {
                        let season_range = moment_range.range(start, end);
                        let booking_range = moment_range.range(
                          start_date,
                          end_date
                        );
                        if (
                          season_range.overlaps(booking_range, {
                            adjacent: true,
                          })
                        ) {
                          // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                          let days = Math.round(
                            season_range
                              .intersect(booking_range)
                              .diff("days", true)
                          );
                          // Поправка на то что входит в сезон меньше суток, мешает считать цену
                          // Считаем что сезон кончается не включая последнюю дату
                          // if (
                          //   days == 0 &&
                          //   season_range
                          //     .intersect(booking_range)
                          //     .diff("days", true) > 0
                          // ) {
                          //   days += 1;
                          // }
                          // Проверка есть ли цены на сезон
                          if (
                            self.set_car.prices &&
                            self.set_car.prices.length > 0
                          ) {
                            self.set_car.prices.forEach((price) => {
                              if (price.season_id == season.id) {
                                self.selected_seasons.push({
                                  season: season,
                                  days: days,
                                });
                              }
                            });
                          }
                        }
                      } else {
                        // Если годы разные
                        let start_second_year = moment(
                          season.start_date,
                          "DD.MM"
                        )
                          .set("year", end_date.format("YYYY"))
                          .startOf("day");
                        let end_second_year = moment(season.end_date, "DD.MM")
                          .set("year", end_date.format("YYYY"))
                          .endOf("day");
                        let season_range = moment_range.range(start, end);
                        let season_range_second_year = moment_range.range(
                          start_second_year,
                          end_second_year
                        );
                        let booking_range = moment_range.range(
                          start_date,
                          end_date
                        );
                        if (
                          season_range.overlaps(booking_range, {
                            adjacent: true,
                          })
                        ) {
                          // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                          let days = Math.round(
                            season_range
                              .intersect(booking_range)
                              .diff("days", true)
                          );
                          // Проверка есть ли цены на сезон
                          if (
                            self.set_car.prices &&
                            self.set_car.prices.length > 0
                          ) {
                            self.set_car.prices.forEach((price) => {
                              if (price.season_id == season.id) {
                                self.selected_seasons.push({
                                  season: season,
                                  days: days,
                                });
                              }
                            });
                          }
                        }
                        if (
                          season_range_second_year.overlaps(booking_range, {
                            adjacent: true,
                          })
                        ) {
                          // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                          let days = Math.round(
                            season_range_second_year
                              .intersect(booking_range)
                              .diff("days", true)
                          );
                          // Проверка есть ли цены на сезон
                          if (
                            self.set_car.prices &&
                            self.set_car.prices.length > 0
                          ) {
                            self.set_car.prices.forEach((price) => {
                              if (price.season_id == season.id) {
                                self.selected_seasons.push({
                                  season: season,
                                  days: days,
                                });
                              }
                            });
                          }
                        }
                      }
                    } else if (
                      self.$store.getters.getCompany.company
                        .season_start_calc &&
                      !self.$store.getters.getCompany.company.season_end_calc
                    ) {
                      // Если сезон считается только по дате начала
                      // Поменял endOf на startOf, при первой дате сезона брались цены по умолчанию
                      let start = moment
                        .utc(season.start_date, "DD.MM")
                        .set("year", start_date.format("YYYY"))
                        .startOf("day");
                      let end = moment
                        .utc(season.end_date, "DD.MM")
                        .set("year", start_date.format("YYYY"))
                        .endOf("day");
                      if (
                        moment(check_start_date, "DD.MM").isBetween(
                          start,
                          end,
                          undefined,
                          "[]"
                        )
                      ) {
                        let days = Math.trunc(
                          moment.duration(end_date.diff(start_date)).asDays()
                        );
                        if (days == 0) {
                          days += 1;
                        }
                        // Проверка есть ли цены на сезон
                        if (
                          self.set_car.prices &&
                          self.set_car.prices.length > 0
                        ) {
                          self.set_car.prices.forEach((price) => {
                            if (price.season_id == season.id) {
                              self.selected_seasons.push({
                                season: season,
                                days: days,
                              });
                            }
                          });
                        }
                      }
                    } else if (
                      !self.$store.getters.getCompany.company
                        .season_start_calc &&
                      self.$store.getters.getCompany.company.season_end_calc
                    ) {
                      let start = moment
                        .utc(season.start_date, "DD.MM")
                        .set("year", end.format("YYYY"))
                        .endOf("day");
                      let end = moment
                        .utc(season.end_date, "DD.MM")
                        .set("year", end.format("YYYY"))
                        .endOf("day");
                      // Если сезон считается только по дате окончания
                      if (
                        moment(check_end_date, "DD.MM").isBetween(
                          start,
                          end,
                          undefined,
                          "[]"
                        )
                      ) {
                        let days = Math.trunc(
                          moment.duration(end_date.diff(start_date)).asDays()
                        );
                        if (days == 0) {
                          days += 1;
                        }
                        // Проверка есть ли цены на сезон
                        if (
                          self.set_car.prices &&
                          self.set_car.prices.length > 0
                        ) {
                          self.set_car.prices.forEach((price) => {
                            if (price.season_id == season.id) {
                              self.selected_seasons.push({
                                season: season,
                                days: days,
                              });
                            }
                          });
                        }
                      }
                    }
                  });
                  // Поправка на доп часы плюс сутки если включен режим определения и старт и конец брони
                  // Только если сезон один, в остальных случаях вроде как не нужно
                  // Если время старта больше чем время окончания, то косяк и прибавляет лишний день, поэтому проверка end_date.hour() > start_date.hour()
                  if (
                    self.$store.getters.getCompany.company.season_start_calc &&
                    self.$store.getters.getCompany.company.season_end_calc &&
                    self.selected_seasons.length == 1 &&
                    end_date.hour() > start_date.hour()
                  ) {
                    if (
                      (self.booking.additional_hours > 0 &&
                        self.booking.additional_hours *
                          (self.booking.price_hour > 0
                            ? self.booking.price_hour
                            : self.set_car.price_hour) >=
                          self.booking.selected_price) ||
                      (self.$store.getters.getCompany.company.hours_limit > 0 &&
                        self.booking.additional_hours >
                          self.$store.getters.getCompany.company.hours_limit)
                    ) {
                      self.selected_seasons.forEach((season) => {
                        season.days += 1;
                      });
                    }
                  }
                }

                setSeason();
              }
              console.log(self.selected_seasons, self.seasons);

              // ищем цены согласно сезону
              function selectPrices() {
                let rental_cost = 0;
                let seasons_days = 0;
                // Выбираем период
                let selected_period_index = self.selected_period
                  ? self.selected_period.index
                  : 0;
                if (diff > self.period_max_value) {
                  selected_period_index = self.periods.length;
                }

                if (self.set_car.prices && self.set_car.prices.length > 0) {
                  self.selected_seasons.forEach((season) => {
                    self.set_car.prices.forEach((price) => {
                      if (price.season_id == season.season.id) {
                        // считаем количество дней входящих в сезон
                        seasons_days += Math.round(season.days);
                        rental_cost +=
                          parseInt(season.days) *
                          price.values[selected_period_index];
                      }
                    });
                  });
                  // Добавляем без сезона
                  // Если diff 0 то получается минус, заплатка
                  let days_without_season =
                    diff > 0 ? diff - seasons_days : 1 - seasons_days;
                  self.set_car.prices.forEach((price) => {
                    if (price.season_id == null) {
                      rental_cost +=
                        days_without_season *
                        price.values[selected_period_index];
                    }
                  });
                  self.booking.rental_cost = Math.round(rental_cost);
                }
              }

              selectPrices();

              if (diff == 0) {
                console.log("diff 0", diff);
                // Проверяю на техническую бронь, и если да, то считаю как есть, иначе плюс сутки
                if (!this.booking.technical) {
                  this.booking.days = 0;
                } else {
                  this.booking.days = 1;
                  this.booking.hours_cost = 0;
                }

                this.booking.selected_price = Math.round(
                  this.booking.rental_cost /
                    (this.booking.days > 0 ? this.booking.days : 1)
                );
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost /
                    (this.booking.days > 0 ? this.booking.days : 1)
                );
                // Проверяем доп часы на hours_limit
                if (
                  !this.booking.technical &&
                  this.$store.getters.getCompany.company.hours_limit > 0 &&
                  this.booking.additional_hours >
                    this.$store.getters.getCompany.company.hours_limit
                ) {
                  this.booking.days = 1;
                  this.booking.hours_cost = 0;
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );
                } else {
                  if (
                    this.booking.additional_hours > 0 &&
                    this.booking.additional_hours *
                      (this.booking.price_hour > 0
                        ? this.booking.price_hour
                        : this.set_car.price_hour) >=
                      this.booking.selected_price
                  ) {
                    this.booking.days = 1;
                    this.booking.hours_cost = 0;
                    this.booking.total = Math.round(
                      this.booking.days * this.booking.selected_price
                    );

                    this.warningAddOneDay = true;
                    this.warningLessOneDay = true;
                  } else {
                    this.booking.total =
                      this.booking.additional_hours * this.booking.price_hour;

                    this.warningAddOneDay = false;
                    this.warningLessOneDay = true;
                  }
                }
              } else if (diff > 0 && diff <= self.period_max_value) {
                if (
                  this.booking.additional_hours > 0 &&
                  (this.booking.additional_hours *
                    (this.booking.price_hour > 0
                      ? this.booking.price_hour
                      : this.set_car.price_hour) >=
                    this.booking.selected_price ||
                    (this.$store.getters.getCompany.company.hours_limit > 0 &&
                      this.booking.additional_hours >
                        this.$store.getters.getCompany.company.hours_limit))
                ) {
                  this.booking.hours_cost = 0;
                  diff += 1;
                  this.booking.days = diff;
                  checkDiffInc();
                  selectPrices();
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );
                  this.warningAddOneDay = true;
                  this.warningLessOneDay = false;
                } else {
                  this.booking.days = diff;
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price +
                      this.booking.additional_hours * this.booking.price_hour
                  );
                  this.warningAddOneDay = false;
                  this.warningLessOneDay = false;
                }
              } else if (diff > self.period_max_value) {
                if (
                  this.booking.additional_hours > 0 &&
                  (this.booking.additional_hours *
                    (this.booking.price_hour > 0
                      ? this.booking.price_hour
                      : this.set_car.price_hour) >=
                    this.booking.selected_price ||
                    (this.$store.getters.getCompany.company.hours_limit > 0 &&
                      this.booking.additional_hours >
                        this.$store.getters.getCompany.company.hours_limit))
                ) {
                  this.booking.hours_cost = 0;
                  diff += 1;
                  this.booking.days = diff;
                  checkDiffInc();
                  selectPrices();

                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );
                  this.warningAddOneDay = true;
                  this.warningLessOneDay = false;
                } else {
                  this.booking.days = diff;
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price +
                      this.booking.additional_hours * this.booking.price_hour
                  );
                  this.warningAddOneDay = false;
                  this.warningLessOneDay = false;
                }
              } else {
                if (
                  this.booking.additional_hours > 0 &&
                  (this.booking.additional_hours *
                    (this.booking.price_hour > 0
                      ? this.booking.price_hour
                      : this.set_car.price_hour) >=
                    this.booking.selected_price ||
                    (this.$store.getters.getCompany.company.hours_limit > 0 &&
                      this.booking.additional_hours >
                        this.$store.getters.getCompany.company.hours_limit))
                ) {
                  this.booking.hours_cost = 0;
                  diff += 1;
                  this.booking.days = diff;
                  checkDiffInc();
                  selectPrices();
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );
                  this.warningAddOneDay = true;
                  this.warningLessOneDay = false;
                } else {
                  this.booking.days = diff;
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price +
                      this.booking.additional_hours * this.booking.price_hour
                  );
                  this.warningAddOneDay = false;
                  this.warningLessOneDay = false;
                }
              }

              this.checkDaysMinLimit(this.booking.days);

              if (this.booking.sale > 0) {
                this.booking.rental_cost = Math.round(
                  this.booking.days *
                    (this.booking.selected_price -
                      this.booking.selected_price * (this.booking.sale / 100))
                );
              } else if (this.booking.sale_cash > 0) {
                this.booking.rental_cost = Math.round(
                  this.booking.days *
                    (this.booking.selected_price - this.booking.sale_cash)
                );
              } else if (this.booking.rental_cost_sale > 0) {
                this.booking.rental_cost -=
                  this.booking.rental_cost *
                  (this.booking.rental_cost_sale / 100);
              } else if (this.booking.rental_cost_sale_cash > 0) {
                this.booking.rental_cost -= this.booking.rental_cost_sale_cash;
              } else {
                this.booking.rental_cost = Math.round(
                  this.booking.days * this.booking.selected_price
                );
              }

              if (
                this.booking.delivery > 0 ||
                this.booking.delivery_end > 0 ||
                this.booking.equipment > 0 ||
                this.booking.other > 0 ||
                this.booking.hours_cost > 0
              ) {
                this.booking.total =
                  this.booking.rental_cost +
                  this.booking.hours_cost +
                  this.booking.delivery +
                  this.booking.delivery_end +
                  this.booking.equipment +
                  this.booking.other;
              } else {
                this.booking.total = Math.round(
                  this.booking.rental_cost + this.booking.hours_cost
                );
              }
            }
            this.calculateCostWithExtra();
            this.errors = [];
            this.dateEndError = false;
          }
          // Проверка на доступность
          if (this.dateStart && this.dateEnd) {
            this.checkBookingsDates(this.set_car, this.dateStart, this.dateEnd);
          }
        } else {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.incorrect_date_title"),
            text: this.$t("errors.incorrect_date_text"),
          });
        }
      } else {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("errors.select_car_title"),
          text: this.$t("errors.select_car_text"),
        });
      }
    },
    dateStart() {
      if (this.set_car) {
        // Обнуляем тариф и скидки
        this.booking.sale = 0;
        this.booking.sale_cash = 0;
        this.booking.rental_cost_sale = 0;
        this.booking.rental_cost_sale_cash = 0;
        this.booking.tariff_id = null;
        this.booking.tariff = null;
        this.selected_tariff = this.$t("custom.standart_tariff");
        if (this.manual_editing == false) {
          let self = this;
          let start_date = moment.utc(this.dateStart, "DD-MM-YYYY H:mm");
          let end_date = moment.utc(this.dateEnd, "DD-MM-YYYY H:mm");
          self.selected_season = null;
          this.selected_seasons = [];
          function setSeason() {
            self.seasons.forEach((season) => {
              let check_start_date = start_date.format("DD.MM");
              // Если есть дата окончания то проверяем на попадание в диапазон
              let check_end_date = start_date.format("DD.MM");
              if (end_date) {
                check_end_date = end_date.format("DD.MM");
              }
              if (
                self.$store.getters.getCompany.company.season_start_calc &&
                self.$store.getters.getCompany.company.season_end_calc
              ) {
                let start = moment
                  .utc(season.start_date, "DD.MM")
                  .set("year", start_date.format("YYYY"))
                  .startOf("day");
                let end = moment
                  .utc(season.end_date, "DD.MM")
                  .set("year", start_date.format("YYYY"))
                  .endOf("day");
                if (end_date.format("YYYY") == start_date.format("YYYY")) {
                  let season_range = moment_range.range(start, end);
                  let booking_range = moment_range.range(start_date, end_date);
                  if (
                    season_range.overlaps(booking_range, {
                      adjacent: true,
                    })
                  ) {
                    // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                    let days = Math.round(
                      season_range.intersect(booking_range).diff("days", true)
                    );
                    // Поправка на то что входит в сезон меньше суток, мешает считать цену
                    // Считаем что сезон кончается не включая последнюю дату
                    // if (
                    //   days == 0 &&
                    //   season_range
                    //     .intersect(booking_range)
                    //     .diff("days", true) > 0
                    // ) {
                    //   days += 1;
                    // }
                    // Проверка есть ли цены на сезон
                    if (self.set_car.prices && self.set_car.prices.length > 0) {
                      self.set_car.prices.forEach((price) => {
                        if (price.season_id == season.id) {
                          self.selected_seasons.push({
                            season: season,
                            days: days,
                          });
                        }
                      });
                    }
                  }
                } else {
                  // Если годы разные
                  let start_second_year = moment(season.start_date, "DD.MM")
                    .set("year", end_date.format("YYYY"))
                    .startOf("day");
                  let end_second_year = moment(season.end_date, "DD.MM")
                    .set("year", end_date.format("YYYY"))
                    .endOf("day");
                  let season_range = moment_range.range(start, end);
                  let season_range_second_year = moment_range.range(
                    start_second_year,
                    end_second_year
                  );
                  let booking_range = moment_range.range(start_date, end_date);
                  if (
                    season_range.overlaps(booking_range, {
                      adjacent: true,
                    })
                  ) {
                    // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                    let days = Math.round(
                      season_range.intersect(booking_range).diff("days", true)
                    );
                    // Проверка есть ли цены на сезон
                    if (self.set_car.prices && self.set_car.prices.length > 0) {
                      self.set_car.prices.forEach((price) => {
                        if (price.season_id == season.id) {
                          self.selected_seasons.push({
                            season: season,
                            days: days,
                          });
                        }
                      });
                    }
                  }
                  if (
                    season_range_second_year.overlaps(booking_range, {
                      adjacent: true,
                    })
                  ) {
                    // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                    let days = Math.round(
                      season_range_second_year
                        .intersect(booking_range)
                        .diff("days", true)
                    );
                    // Проверка есть ли цены на сезон
                    if (self.set_car.prices && self.set_car.prices.length > 0) {
                      self.set_car.prices.forEach((price) => {
                        if (price.season_id == season.id) {
                          self.selected_seasons.push({
                            season: season,
                            days: days,
                          });
                        }
                      });
                    }
                  }
                }
              } else if (
                self.$store.getters.getCompany.company.season_start_calc &&
                !self.$store.getters.getCompany.company.season_end_calc
              ) {
                // Если сезон считается только по дате начала
                let start = moment
                  .utc(season.start_date, "DD.MM")
                  .set("year", start_date.format("YYYY"))
                  .endOf("day");
                let end = moment
                  .utc(season.end_date, "DD.MM")
                  .set("year", start_date.format("YYYY"))
                  .endOf("day");
                if (
                  moment(check_start_date, "DD.MM").isBetween(
                    start,
                    end,
                    undefined,
                    "[]"
                  )
                ) {
                  let days = Math.trunc(
                    moment.duration(end_date.diff(start_date)).asDays()
                  );
                  if (days == 0) {
                    days += 1;
                  }
                  // Проверка есть ли цены на сезон
                  if (self.set_car.prices && self.set_car.prices.length > 0) {
                    self.set_car.prices.forEach((price) => {
                      if (price.season_id == season.id) {
                        self.selected_seasons.push({
                          season: season,
                          days: days,
                        });
                      }
                    });
                  }
                }
              } else if (
                !self.$store.getters.getCompany.company.season_start_calc &&
                self.$store.getters.getCompany.company.season_end_calc
              ) {
                let start = moment
                  .utc(season.start_date, "DD.MM")
                  .set("year", end.format("YYYY"))
                  .endOf("day");
                let end = moment
                  .utc(season.end_date, "DD.MM")
                  .set("year", end.format("YYYY"))
                  .endOf("day");
                // Если сезон считается только по дате окончания
                if (
                  moment(check_end_date, "DD.MM").isBetween(
                    start,
                    end,
                    undefined,
                    "[]"
                  )
                ) {
                  let days = Math.trunc(
                    moment.duration(end_date.diff(start_date)).asDays()
                  );
                  if (days == 0) {
                    days += 1;
                  }
                  // Проверка есть ли цены на сезон
                  if (self.set_car.prices && self.set_car.prices.length > 0) {
                    self.set_car.prices.forEach((price) => {
                      if (price.season_id == season.id) {
                        self.selected_seasons.push({
                          season: season,
                          days: days,
                        });
                      }
                    });
                  }
                }
              }
            });
            // Поправка на доп часы плюс сутки если включен режим определения и старт и конец брони
            // Только если сезон один, в остальных случаях вроде как не нужно
            if (
              self.$store.getters.getCompany.company.season_start_calc &&
              self.$store.getters.getCompany.company.season_end_calc &&
              self.selected_seasons.length == 1
            ) {
              if (
                (self.booking.additional_hours > 0 &&
                  self.booking.additional_hours *
                    (self.booking.price_hour > 0
                      ? self.booking.price_hour
                      : self.set_car.price_hour) >=
                    self.booking.selected_price) ||
                (self.$store.getters.getCompany.company.hours_limit > 0 &&
                  self.booking.additional_hours >
                    self.$store.getters.getCompany.company.hours_limit)
              ) {
                self.selected_seasons.forEach((season) => {
                  season.days += 1;
                });
              }
            }
          }

          setSeason();
          // let selected_prices, default_prices;

          self.period_max_value = parseInt(
            self.periods[self.periods.length - 1].split(" - ")[1]
          )
            ? parseInt(self.periods[self.periods.length - 1].split(" - ")[1])
            : 31;

          // ищем цены согласно сезону
          function selectPrices() {
            let rental_cost = 0;
            let seasons_days = 0;
            // Выбираем период
            let selected_period_index = self.selected_period
              ? self.selected_period.index
              : 0;
            if (diff > self.period_max_value) {
              selected_period_index = self.periods.length;
            }
            if (
              self.set_car &&
              self.set_car.prices &&
              self.set_car.prices.length > 0
            ) {
              self.selected_seasons.forEach((season) => {
                self.set_car.prices.forEach((price) => {
                  if (price.season_id == season.season.id) {
                    // считаем количество дней входящих в сезон
                    seasons_days += Math.round(season.days);
                    rental_cost +=
                      parseInt(season.days) *
                      price.values[selected_period_index];
                  }
                });
              });
              // Добавляем без сезона
              // Если diff 0 то получается минус, заплатка
              let days_without_season =
                diff > 0 ? diff - seasons_days : 1 - seasons_days;
              self.set_car.prices.forEach((price) => {
                if (price.season_id == null) {
                  rental_cost +=
                    days_without_season * price.values[selected_period_index];
                }
              });
              // self.booking.rental_cost = Math.round(rental_cost * 100) / 100;
              self.booking.rental_cost = Math.round(rental_cost);
            }
          }
          // selectPrices();

          // this.checkFreeStartTime(start_date);
          let hours = moment.duration(end_date.diff(start_date)).asHours();

          let start_date_days = moment.utc(
            moment.utc(this.dateStart, "DD-MM-YYYY H:mm").format("DD-MM-YYYY"),
            "DD-MM-YYYY"
          );
          let end_date_days = moment.utc(
            moment.utc(this.dateEnd, "DD-MM-YYYY H:mm").format("DD-MM-YYYY"),
            "DD-MM-YYYY"
          );
          this.booking.additional_hours = 0;
          if (
            hours >
            moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
          ) {
            this.hours = hours;
            let additionalHours = hours % 24;
            this.booking.additional_hours = Math.trunc(additionalHours);
          }
          if (
            hours <
            moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
          ) {
            this.hours = hours;
            let additionalHours = hours % 24;
            this.booking.additional_hours = Math.trunc(additionalHours);
            console.log("additional_hours", additionalHours);
          }
          let diff = Math.trunc(
            moment.duration(end_date.diff(start_date)).asDays()
          );
          console.log("diff", diff);

          // Проверяем период
          function checkDiffInc() {
            self.periods.forEach((period) => {
              let start = parseInt(period.split(" - ")[0]);
              let end = parseInt(period.split(" - ")[1]);
              if (diff >= start && diff <= end) {
                self.selected_period = {
                  value: period,
                  index: self.periods.indexOf(period),
                };
              } else if (diff > self.period_max_value) {
                // периодов на один меньше чем цен (+31)
                self.selected_period = {
                  value: "31+",
                  index: self.periods.length,
                };
              }
            });
          }

          checkDiffInc();
          selectPrices();

          if (!isNaN(diff)) {
            if (this.booking.additional_hours > 0) {
              this.booking.hours_cost =
                this.booking.additional_hours * this.booking.price_hour;
            } else {
              this.booking.hours_cost = 0;
            }

            if (diff == 0) {
              // Проверяю на техническую бронь, и если да, то считаю как есть, иначе плюс сутки
              if (!this.booking.technical) {
                this.booking.days = 0;
              } else {
                this.booking.days = 1;
                this.booking.hours_cost = 0;
              }
              this.booking.selected_price = Math.round(
                this.booking.rental_cost /
                  (this.booking.days > 0 ? this.booking.days : 1)
              );
              this.booking.price_no_sale = Math.round(
                this.booking.rental_cost /
                  (this.booking.days > 0 ? this.booking.days : 1)
              );
              // Проверяем доп часы на hours_limit
              if (
                !this.booking.technical &&
                this.$store.getters.getCompany.company.hours_limit > 0 &&
                this.booking.additional_hours >
                  this.$store.getters.getCompany.company.hours_limit
              ) {
                this.booking.days = 1;
                this.booking.hours_cost = 0;
                this.booking.total = Math.round(
                  this.booking.days * this.booking.selected_price
                );
              } else {
                if (
                  this.booking.additional_hours > 0 &&
                  this.booking.additional_hours *
                    (this.booking.price_hour > 0
                      ? this.booking.price_hour
                      : this.set_car.price_hour) >=
                    this.booking.selected_price
                ) {
                  this.booking.days = 1;
                  this.booking.hours_cost = 0;
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );

                  this.warningAddOneDay = true;
                  this.warningLessOneDay = true;
                } else {
                  this.booking.total =
                    this.booking.additional_hours * this.booking.price_hour;

                  this.warningAddOneDay = false;
                  this.warningLessOneDay = true;
                }
              }
            } else if (diff > 0 && diff <= self.period_max_value) {
              if (
                this.booking.additional_hours > 0 &&
                (this.booking.additional_hours *
                  (this.booking.price_hour > 0
                    ? this.booking.price_hour
                    : this.set_car.price_hour) >=
                  this.booking.selected_price ||
                  (this.$store.getters.getCompany.company.hours_limit > 0 &&
                    this.booking.additional_hours >
                      this.$store.getters.getCompany.company.hours_limit))
              ) {
                this.booking.hours_cost = 0;
                diff += 1;
                this.booking.days = diff;
                checkDiffInc();
                selectPrices();
                this.booking.selected_price = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.total =
                  this.booking.days * this.booking.selected_price;
                this.warningAddOneDay = true;
              } else {
                this.booking.days = diff;
                this.booking.selected_price = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.total = Math.round(
                  this.booking.days * this.booking.selected_price +
                    this.booking.additional_hours * this.booking.price_hour
                );
                this.warningAddOneDay = false;
              }
            } else if (diff > self.period_max_value) {
              if (
                this.booking.additional_hours > 0 &&
                (this.booking.additional_hours *
                  (this.booking.price_hour > 0
                    ? this.booking.price_hour
                    : this.set_car.price_hour) >=
                  this.booking.selected_price ||
                  (this.$store.getters.getCompany.company.hours_limit > 0 &&
                    this.booking.additional_hours >
                      this.$store.getters.getCompany.company.hours_limit))
              ) {
                this.booking.hours_cost = 0;
                diff += 1;
                this.booking.days = diff;
                checkDiffInc();
                selectPrices();
                this.booking.selected_price = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.total = Math.round(
                  this.booking.days * this.booking.selected_price
                );
                this.warningAddOneDay = true;
              } else {
                this.booking.days = diff;
                this.booking.selected_price = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.total = Math.round(
                  this.booking.days * this.booking.selected_price +
                    this.booking.additional_hours * this.booking.price_hour
                );
                this.warningAddOneDay = false;
              }
            } else {
              if (
                this.booking.additional_hours > 0 &&
                (this.booking.additional_hours *
                  (this.booking.price_hour > 0
                    ? this.booking.price_hour
                    : this.set_car.price_hour) >=
                  this.booking.selected_price ||
                  (this.$store.getters.getCompany.company.hours_limit > 0 &&
                    this.booking.additional_hours >
                      this.$store.getters.getCompany.company.hours_limit))
              ) {
                this.booking.hours_cost = 0;
                diff += 1;
                this.booking.days = diff;
                checkDiffInc();
                selectPrices();
                this.booking.selected_price = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.total = Math.round(
                  this.booking.days * this.booking.selected_price
                );
                this.warningAddOneDay = true;
              } else {
                this.booking.days = diff;
                this.booking.selected_price = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                this.booking.total = Math.round(
                  this.booking.days * this.booking.selected_price +
                    this.booking.additional_hours * this.booking.price_hour
                );
                this.warningAddOneDay = false;
              }
            }

            this.checkDaysMinLimit(this.booking.days);

            if (this.booking.sale > 0) {
              this.booking.rental_cost = Math.round(
                this.booking.days *
                  (this.booking.selected_price -
                    this.booking.selected_price * (this.booking.sale / 100))
              );
            } else if (this.booking.sale_cash > 0) {
              this.booking.rental_cost = Math.round(
                this.booking.days *
                  (this.booking.selected_price - this.booking.sale_cash)
              );
            } else if (this.booking.rental_cost_sale > 0) {
              this.booking.rental_cost -=
                this.booking.rental_cost *
                (this.booking.rental_cost_sale / 100);
            } else if (this.booking.rental_cost_sale_cash > 0) {
              this.booking.rental_cost -= this.booking.rental_cost_sale_cash;
            } else {
              this.booking.rental_cost = Math.round(
                this.booking.days * this.booking.selected_price
              );
            }

            if (
              this.booking.delivery > 0 ||
              this.booking.delivery_end > 0 ||
              this.booking.equipment > 0 ||
              this.booking.other > 0 ||
              this.booking.hours_cost > 0
            ) {
              this.booking.total = Math.round(
                this.booking.rental_cost +
                  this.booking.hours_cost +
                  this.booking.delivery +
                  this.booking.delivery_end +
                  this.booking.equipment +
                  this.booking.other
              );
            } else {
              this.booking.total = Math.round(
                this.booking.rental_cost + this.booking.hours_cost
              );
            }
          }
          this.calculateCostWithExtra();
          this.errors = [];
          this.dateEndError = false;
        }
        // Проверка на доступность
        if (this.dateStart && this.dateEnd) {
          this.checkBookingsDates(this.set_car, this.dateStart, this.dateEnd);
        }
      } else {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("errors.select_car_title"),
          text: this.$t("errors.select_car_text"),
        });
      }
    },
    selected_tariff() {
      if (this.selected_tariff) {
        this.tariff = this.tariffs.find((tariff) => {
          return tariff.id == this.selected_tariff;
        });
        this.calculateCostWithTariff();
      }
    },
    birthday_non_format: function (val) {
      if (val && val.length == 10) {
        this.client.birthday = moment(val, "DD.MM.YYYY").format("YYYY-MM-DD");
      }
    },
  },
  computed: {
    main_company() {
      return this.$store.getters.getMainCompany;
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    minDate() {
      if (this.dateStart) {
        return moment(this.dateStart, "DD-MM-YYYY H:mm").toDate();
      } else {
        return undefined;
      }
    },
    clients_sources() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.clients_sources
      ) {
        return this.$store.getters.getCompany.company.clients_sources;
      } else {
        return [];
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    short_client_view() {
      return this.$store.getters.getUserSettings.short_client_view;
    },
  },
  methods: {
    setSale() {
      // Обнуляем скидку
      this.booking.rental_cost = Math.round(
        this.booking.days * this.booking.selected_price
      );
      // this.booking.selected_price = this.booking.price_no_sale;
      this.booking.selected_price = Math.round(
        this.booking.rental_cost / this.booking.days
      );
      if (this.booking.sale > 0) {
        this.booking.rental_cost -= Math.round(
          this.booking.rental_cost * (this.booking.sale / 100)
        );
        console.log("sale", this.booking.rental_cost);
      }
      if (this.booking.sale_cash > 0) {
        this.booking.rental_cost -= Math.round(
          this.booking.days * this.booking.sale_cash
        );
        console.log("sale_cash", this.booking.rental_cost);
      }
      if (this.booking.rental_cost_sale > 0) {
        this.booking.rental_cost -=
          this.booking.rental_cost * (this.booking.rental_cost_sale / 100);
        console.log("rental_cost_sale", this.booking.rental_cost);
      }
      if (this.booking.rental_cost_sale_cash > 0) {
        this.booking.rental_cost -= this.booking.rental_cost_sale_cash;
        console.log("rental_cost_sale_cash", this.booking.rental_cost);
      }
      // Пересчитываем цену с учетом скидки
      this.booking.selected_price = Math.round(
        this.booking.rental_cost / this.booking.days
      );
    },
    calculateCostWithExtra() {
      if (this.manual_editing == false) {
        this.setSale();
        // Пересчитываем цену за сутки
        this.booking.selected_price = Math.round(
          this.booking.rental_cost / this.booking.days
        );
        this.calculateCostWithTariff();
      }
      let additional_services = Math.round(
        this.booking.hours_cost +
          this.booking.delivery +
          this.booking.delivery_end +
          this.booking.clean_payment +
          this.booking.equipment +
          this.booking.add_drivers_cost +
          this.booking.insurance +
          this.booking.other
      );

      if (additional_services > 0) {
        this.booking.total = this.booking.rental_cost + additional_services;
      } else {
        this.booking.total = this.booking.rental_cost + this.booking.hours_cost;
      }
    },
    calculateCostWithTariff() {
      if (this.manual_editing == false) {
        if (
          this.tariff &&
          (this.tariff.percent > 0 || this.tariff.percent < 0)
        ) {
          this.booking.selected_price = this.booking.price_no_sale;
          this.booking.selected_price = Math.round(
            this.booking.selected_price +
              this.booking.selected_price * (this.tariff.percent / 100)
          );
          this.setSale();
          // this.booking.rental_cost = Math.round(
          //   this.booking.days * this.booking.selected_price
          // );
          this.booking.tariff_id = this.tariff.id;
        } else if (
          this.tariff &&
          (this.tariff.sum > 0 || this.tariff.sum < 0)
        ) {
          this.booking.selected_price = this.booking.price_no_sale;
          this.booking.selected_price =
            this.booking.selected_price + this.tariff.sum;
          this.setSale();
          // this.booking.rental_cost = Math.round(
          //   this.booking.days * this.booking.selected_price
          // );
          this.booking.tariff_id = this.tariff.id;
        } else {
          this.booking.selected_price = this.booking.price_no_sale;
          this.setSale();
        }
      }

      console.log(this.booking.rental_cost, "rental_cost 2");

      let additional_services = Math.round(
        this.booking.hours_cost +
          this.booking.delivery +
          this.booking.delivery_end +
          this.booking.clean_payment +
          this.booking.equipment +
          this.booking.add_drivers_cost +
          this.booking.insurance +
          this.booking.other
      );

      if (additional_services > 0) {
        this.booking.total = this.booking.rental_cost + additional_services;
      } else {
        this.booking.total = this.booking.rental_cost + this.booking.hours_cost;
      }
    },
    setCar(event) {
      let self = this;
      this.isLoading = true;
      if (event) {
        this.set_car = this.cars.filter((x) => x.id === event)[0];
      }
      // # { id: 3, state: "Критическое состояние" },
      // # { id: 4, state: "В долгосрочной аренде" },
      // # { id: 5, state: "Не выдавать" },
      if (this.set_car.state == 3) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("states.car_critical"),
        });
      } else if (this.set_car.state == 4) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("states.car_long_rent"),
        });
      } else if (this.set_car.state == 5) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("states.car_no_rent"),
        });
      }
      this.booking.car_id = this.set_car.id;
      this.booking.car_code = this.set_car.code;
      this.booking.car_name = this.set_car.car_name;
      this.booking.price_hour = this.set_car.price_hour;
      this.booking.deposit = this.set_car.deposit;

      // Обнуляем тариф и скидки при смене авто и клиента
      this.booking.sale = 0;
      this.booking.sale_cash = 0;
      this.booking.rental_cost_sale = 0;
      this.booking.rental_cost_sale_cash = 0;
      this.booking.tariff_id = null;
      this.booking.tariff = null;
      this.selected_tariff = this.$t("custom.standart_tariff");
      this.client = {
        name: "",
        lastname: "",
        middlename: "",
        phone: "",
        country: ["Russia"],
        city: "",
        address: "",
        sale: 0,
        sale_cash: 0,
        passport: "",
        driver: "",
        birthday: null,
      };

      this.axios
        .get(`/api/v1/cars/${this.set_car.id}/bookings`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.calendar_attributes = [
            {
              key: "today",
              highlight: {
                color: "red",
                fillMode: "outline",
              },
              dates: new Date(),
            },
          ];
          response.data.bookings_dates.forEach((booking) => {
            self.calendar_attributes.push({
              highlight: {
                start: { fillMode: "outline" },
                base: { fillMode: "light" },
                end: { fillMode: "outline" },
              },
              content: "black",
              dates: {
                start: moment(booking.start, "DD-MM-YYYY H:mm").toDate(),
                end: moment(booking.end, "DD-MM-YYYY H:mm").toDate(),
              },
              popover: {
                label: `${booking.start} - ${booking.end}`,
                visibility: "hover",
                hideIndicator: true,
              },
            });
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.set_car"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/cars/${self.set_car.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          if (response.data.prices.length > 0) {
            if (response.data.prices.some((e) => e.season_id === null)) {
              self.set_car["prices"] = response.data.prices;
              if (this.dateStart) {
                // Обновляю вотчер методом дублем, а то при перевыборе авто цены не менялись
                this.dateStartUpdate();
              }
            } else {
              self.$swal({
                showConfirmButton: true,
                icon: "warning",
                title: self.$t("errors.no_prices_title"),
                text: self.$t("errors.no_prices_text"),
              });
            }
          } else {
            self.set_car["prices"] = [];
            self.$swal({
              showConfirmButton: true,
              icon: "warning",
              title: self.$t("errors.no_prices_title"),
              text: self.$t("errors.no_prices_text_3"),
            });
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.set_car"));
          console.log(error);
        });
      // Получаем активные брони
      this.axios
        .get(`/api/v1/cars_active_bookings/${this.set_car.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cars_bookings = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.cars_active_bookings"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    submit() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        if (
          this.booking.client_id == null ||
          this.booking.car_code == "" ||
          this.dateStart == null ||
          this.dateEnd == null
        ) {
          this.$swal({
            showConfirmButton: true,
            icon: "error",
            title: this.$t("errors.required_params_title"),
            text: this.$t("errors.required_params_text"),
          });
        } else {
          this.isLoading = true;
          var self = this;
          this.axios
            .post(
              "/api/v1/bookings",
              {
                bookings: {
                  manual_editing: this.manual_editing,
                  car_name: this.booking.car_name,
                  car_id: this.booking.car_id,
                  car_code: this.booking.car_code,
                  start_date: moment(this.dateStart, "DD-MM-YYYY H:mm").format(
                    "DD-MM-YYYY H:mm"
                  ),
                  end_date: moment(this.dateEnd, "DD-MM-YYYY H:mm").format(
                    "DD-MM-YYYY H:mm"
                  ),
                  client_id: this.booking.client_id,
                  first_name: this.booking.first_name,
                  middle_name: this.booking.middle_name,
                  last_name: this.booking.last_name,
                  company_id: this.set_car.company_id,
                  sale: !this.booking.technical ? this.booking.sale : 0,
                  sale_cash: !this.booking.technical
                    ? this.booking.sale_cash
                    : 0,
                  days: this.booking.days,
                  additional_hours: this.booking.additional_hours,
                  price: !this.booking.technical
                    ? this.booking.selected_price
                    : 0,
                  price_no_sale: !this.booking.technical
                    ? this.booking.price_no_sale
                    : 0,
                  price_id: this.booking.price_id,
                  rental_cost: !this.booking.technical
                    ? this.booking.rental_cost
                    : 0,
                  price_hour: !this.booking.technical
                    ? this.booking.price_hour
                    : 0,
                  hours_cost: !this.booking.technical
                    ? this.booking.hours_cost
                    : 0,
                  delivery: this.booking.delivery,
                  delivery_end: this.booking.delivery_end,
                  clean_payment: this.booking.clean_payment,
                  insurance: this.booking.insurance,
                  kasko: this.booking.kasko,
                  super_kasko: this.booking.super_kasko,
                  theft: this.booking.theft,
                  no_franchise: this.booking.no_franchise,
                  location_start: this.booking.location_start,
                  location_end: this.booking.location_end,
                  equipment: this.booking.equipment,
                  other: this.booking.other,
                  total: this.booking.total,
                  chair: this.booking.chair,
                  chair_less_1_year: this.booking.chair_less_1_year,
                  chairs_quantity: this.booking.chairs_quantity,
                  booster: this.booking.booster,
                  boosters_quantity: this.booking.boosters_quantity,
                  navigator: this.booking.navigator,
                  mp3: this.booking.mp3,
                  charger: this.booking.charger,
                  deposit: !this.booking.technical ? this.booking.deposit : 0,
                  description: this.booking.description,
                  entity: this.booking.entity,
                  entity_name: this.booking.entity_name,
                  add_drivers: this.booking.add_drivers,
                  add_drivers_cost: this.booking.add_drivers_cost,
                  state: "Активная",
                  custom_field_1: this.booking.custom_field_1,
                  custom_field_2: this.booking.custom_field_2,
                  custom_field_3: this.booking.custom_field_3,
                  technical: this.booking.technical,
                  repair: this.booking.repair,
                  tariff_id: this.booking.tariff_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$router.replace(`/bookings/${response.data.id}`);

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.add_booking_title"),
                text: this.$t("bookings.add_booking_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.add_booking_title"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.add_booking_title"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    loadClients() {
      this.isLoadingClients = true;
      this.axios
        .post(
          `/api/v1/index_new_booking_page`,
          {
            search: this.search,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          this.clients = response.data.query ? response.data.query : (response.data ? response.data : []);
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                (client.entity_phone
                  ? client.entity_phone.slice(client.entity_phone.length - 4)
                  : "");
            }
          });
        })
        .catch((error) => {
          this.isLoadingClients = false;
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
    loadClientsAddDrivers() {
      this.isLoadingClientsAddDriver = true;
      this.axios
        .post(
          `/api/v1/index_new_booking_page`,
          {
            search: this.search_add_driver,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.clients_add_drivers = response.data.query ? response.data.query : (response.data ? response.data : []);
          this.clients_add_drivers.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                (client.entity_phone
                  ? client.entity_phone.slice(client.entity_phone.length - 4)
                  : "");
            }
          });
        })
        .catch((error) => {
          this.isLoadingClientsAddDriver = false;
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClientsAddDriver = false));
    },
    checkDaysMinLimit(days) {
      if (days < this.$store.getters.getCompany.company.min_days) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("bookings.low_days_title"),
          text: this.$t("bookings.low_days_text", { days: days }),
        });
      }
    },
    checkAge(date) {
      console.log(date, "date")
      if (date) {
        let startDate = moment(date, ["DD.MM.YYYY", "YYYY-MM-DD"]);
        let endDate = moment();
        let years = moment.duration(endDate.diff(startDate)).years();

        if (years < this.$store.getters.getCompany.company.age_limit) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("bookings.low_age_title"),
            text: this.$t("bookings.low_age_text", { years: parseInt(years) }),
          });
        }
      }
    },
    checkUniqClientPhone() {
      if (
        this.check_clients.filter((client) => client.phone == this.client.phone)
          .length > 0
      ) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("bookings.phone_uniq_title"),
          text: this.$t("bookings.phone_uniq_text"),
        });
      }
    },
    checkUniqClientEmail() {
      if (
        this.client.email &&
        this.check_clients.filter((client) => client.email == this.client.email)
          .length > 0
      ) {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t("bookings.email_uniq_title"),
          text: this.$t("bookings.email_uniq_text"),
        });
      }
    },
    submitClient() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let client = this.client;
        let middlename, year, birthday;

        if (client.middlename) {
          middlename = this.capitalizeFirst(client.middlename);
        } else {
          middlename = "";
        }
        if ((client.name && client.lastname) || client.entity) {
          this.newClientDialog = false;
          this.showClients = false;

          let name = client.name ? this.capitalizeFirst(client.name) : "";
          let lastname = client.lastname
            ? this.capitalizeFirst(client.lastname)
            : "";
          if (client.birthday) {
            console.log(client.birthday);
            birthday = client.birthday;

            year = birthday.split("-")[0];
          }

          this.axios
            .post(
              "/api/v1/clients.json",
              {
                clients: {
                  name: name,
                  lastname: lastname,
                  middlename: middlename,
                  fio:
                    middlename && !client.entity
                      ? name[0] + ". " + middlename[0] + ". " + lastname
                      : name[0] + ". " + lastname,
                  email: client.email,
                  phone: client.phone,
                  country: client.country,
                  city: client.city,
                  address: client.address,
                  sale: client.sale,
                  sale_cash: client.sale_cash,
                  passport_series: client.passport_series,
                  passport_number: client.passport_number,
                  passport_issued: client.passport_issued,
                  driver_series: client.driver_series,
                  driver_number: client.driver_number,
                  driver_issued: client.driver_issued,
                  birthday: client.birthday ? client.birthday : null,
                  year: year,
                  category: client.category,
                  dop_info: client.dop_info,
                  entity: client.entity,
                  entity_name: client.entity_name,
                  short_entity_name: client.short_entity_name,
                  ceo: client.ceo,
                  reg_form: client.reg_form,
                  entity_phone: client.entity_phone,
                  entity_adress: client.entity_adress,
                  inn: client.inn,
                  ogrn: client.ogrn,
                  acc_number: client.acc_number,
                  bank: client.bank,
                  korr: client.korr,
                  bik: client.bik,
                  doc_number: client.doc_number,
                  source: client.source,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.axios
                .get(`api/v1/clients/${response.data.id}`, {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                })
                .then((response) => {
                  this.client = response.data.client;
                  this.booking.client_id = response.data.id;
                  if (this.client && !this.client.entity) {
                    this.booking.fio = response.data.fio;
                  } else {
                    this.booking.entity_name = response.data.entity_name;
                  }
                  this.birthday_date = null;
                })
                .catch((error) => {
                  this.setError(error, this.$t("errors.clients_load"));
                  console.log(error);
                });
            })
            .then(() => {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("custom.add_client_title"),
                text: this.$t("custom.add_client_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.add_client_title"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.add_client_title"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.all_fields_title"),
            text: this.error,
          });
          this.isLoading = false;
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.$set(this.configEnd, "minDate", dateStr);
    },
    capitalizeFirst(text) {
      return text[0].toUpperCase() + text.substring(1).toLowerCase();
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    dateStartUpdate() {
      let self = this;
      let start_date = moment.utc(this.dateStart, "DD-MM-YYYY H:mm");
      let end_date = moment.utc(this.dateEnd, "DD-MM-YYYY H:mm");

      // Находим сезон по стартовой дате либо если нет, но оставляем нулл
      function setSeason() {
        self.seasons.forEach((season) => {
          let start = moment.utc(season.start_date, "DD.MM");
          let end = moment.utc(season.end_date, "DD.MM");
          let check_date = moment
            .utc(self.dateStart, "DD-MM-YYYY H:mm")
            .format("DD.MM");
          if (moment(check_date, "DD.MM").isBetween(start, end)) {
            self.selected_season = season;
          }
        });
      }

      setSeason();

      // console.log(this.set_car.prices, self.selected_season, this.set_car.prices.find(price => price.season_id == self.selected_season.id))
      let selected_prices, default_prices;

      self.period_max_value = parseInt(
        self.periods[self.periods.length - 1].split(" - ")[1]
      )
        ? parseInt(self.periods[self.periods.length - 1].split(" - ")[1])
        : 31;

      // ищем цены согласно сезону
      function selectPrices() {
        if (self.selected_season && self.selected_season != null) {
          self.selected_prices = self.set_car.prices.find(
            (price) => price.season_id == self.selected_season.id
          )
            ? self.set_car.prices.find(
                (price) => price.season_id == self.selected_season.id
              ).values
            : self.set_car.prices.find((price) => price.season_id == null)
                .values;
          default_prices = self.set_car.prices.find(
            (price) => price.season_id == null
          ).values;
        } else {
          self.selected_prices = self.set_car.prices.find(
            (price) => price.season_id == null
          ).values;
          default_prices = self.set_car.prices.find(
            (price) => price.season_id == null
          ).values;
        }
      }
      // selectPrices();

      // this.checkFreeStartTime(start_date);
      let hours = moment.duration(end_date.diff(start_date)).asHours();
      console.log("hours", hours);

      let start_date_days = moment.utc(
        moment.utc(this.dateStart, "DD-MM-YYYY H:mm").format("DD-MM-YYYY"),
        "DD-MM-YYYY"
      );
      console.log();
      let end_date_days = moment.utc(
        moment.utc(this.dateEnd, "DD-MM-YYYY H:mm").format("DD-MM-YYYY"),
        "DD-MM-YYYY"
      );
      this.booking.additional_hours = 0;
      if (
        hours >
        moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
      ) {
        this.hours = hours;
        let additionalHours = hours % 24;
        this.booking.additional_hours = Math.trunc(additionalHours);
      }
      if (
        hours <
        moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
      ) {
        this.hours = hours;
        let additionalHours = hours % 24;
        this.booking.additional_hours = Math.trunc(additionalHours);
      }
      let diff = Math.trunc(
        moment.duration(end_date.diff(start_date)).asDays()
      );

      // Проверяем период
      function checkDiffInc() {
        self.periods.forEach((period) => {
          let start = parseInt(period.split(" - ")[0]);
          let end = parseInt(period.split(" - ")[1]);
          if (diff >= start && diff <= end) {
            self.selected_period = {
              value: period,
              index: self.periods.indexOf(period),
            };
          } else if (diff > self.period_max_value) {
            // периодов на один меньше чем цен (+31)
            self.selected_period = { value: "31+", index: self.periods.length };
          }
        });
      }

      checkDiffInc();
      selectPrices();

      if (!isNaN(diff)) {
        if (this.booking.additional_hours > 0) {
          this.booking.hours_cost =
            this.booking.additional_hours * this.booking.price_hour;
        } else {
          this.booking.hours_cost = 0;
        }

        if (diff == 0) {
          this.booking.days = 1;
          this.booking.hours_cost = 0;
          this.booking.selected_price = this.selected_prices[0];
          this.booking.price_no_sale = this.selected_prices[0];
          this.booking.total =
            this.booking.days * this.booking.selected_price +
            this.booking.additional_hours * this.booking.price_hour;
          this.warningAddOneDay = false;
          this.warningLessOneDay = true;
        } else if (diff > 0 && diff <= self.period_max_value) {
          if (
            this.booking.additional_hours > 0 &&
            this.booking.additional_hours *
              (this.booking.price_hour > 0
                ? this.booking.price_hour
                : this.set_car.price_hour) >=
              this.booking.selected_price
          ) {
            this.booking.hours_cost = 0;
            diff += 1;
            this.booking.days = diff;
            checkDiffInc();
            selectPrices();
            this.booking.selected_price =
              this.selected_prices[self.selected_period.index];
            this.booking.price_no_sale =
              this.selected_prices[self.selected_period.index];
            this.booking.total =
              this.booking.days * this.booking.selected_price;
            this.warningAddOneDay = true;
          } else {
            this.booking.days = diff;
            this.booking.selected_price =
              this.selected_prices[self.selected_period.index];
            this.booking.price_no_sale =
              this.selected_prices[self.selected_period.index];
            this.booking.total =
              this.booking.days * this.booking.selected_price +
              this.booking.additional_hours * this.booking.price_hour;
            this.warningAddOneDay = false;
          }
        } else if (diff > self.period_max_value) {
          if (
            this.booking.additional_hours > 0 &&
            this.booking.additional_hours *
              (this.booking.price_hour > 0
                ? this.booking.price_hour
                : this.set_car.price_hour) >=
              this.booking.selected_price
          ) {
            this.booking.hours_cost = 0;
            diff += 1;
            this.booking.days = diff;
            checkDiffInc();
            selectPrices();
            this.booking.selected_price =
              this.selected_prices[
                // this.selected_prices.length - 1
                // На случай если цен больше чем периодов
                this.selected_period.index == this.selected_prices.length - 1
                  ? this.selected_prices.length - 1
                  : this.selected_period.index
              ];
            this.booking.price_no_sale =
              this.selected_prices[
                // this.selected_prices.length - 1
                // На случай если цен больше чем периодов
                this.selected_period.index == this.selected_prices.length - 1
                  ? this.selected_prices.length - 1
                  : this.selected_period.index
              ];
            this.booking.total =
              this.booking.days * this.booking.selected_price;
            this.warningAddOneDay = true;
          } else {
            this.booking.days = diff;
            this.booking.selected_price =
              this.selected_prices[
                // this.selected_prices.length - 1
                // На случай если цен больше чем периодов
                this.selected_period.index == this.selected_prices.length - 1
                  ? this.selected_prices.length - 1
                  : this.selected_period.index
              ];
            this.booking.price_no_sale =
              this.selected_prices[
                // this.selected_prices.length - 1
                // На случай если цен больше чем периодов
                this.selected_period.index == this.selected_prices.length - 1
                  ? this.selected_prices.length - 1
                  : this.selected_period.index
              ];
            this.booking.total =
              this.booking.days * this.booking.selected_price +
              this.booking.additional_hours * this.booking.price_hour;
            this.warningAddOneDay = false;
          }
        } else {
          if (
            this.booking.additional_hours > 0 &&
            this.booking.additional_hours *
              (this.booking.price_hour > 0
                ? this.booking.price_hour
                : this.set_car.price_hour) >=
              this.booking.selected_price
          ) {
            this.booking.hours_cost = 0;
            diff += 1;
            this.booking.days = diff;
            checkDiffInc();
            selectPrices();
            this.booking.selected_price =
              default_prices[self.selected_period.index];
            this.booking.price_no_sale =
              default_prices[self.selected_period.index];
            this.booking.total =
              this.booking.days * this.booking.selected_price;
            this.warningAddOneDay = true;
          } else {
            this.booking.days = diff;
            this.booking.selected_price =
              default_prices[self.selected_period.index];
            this.booking.price_no_sale =
              default_prices[self.selected_period.index];
            this.booking.total =
              this.booking.days * this.booking.selected_price +
              this.booking.additional_hours * this.booking.price_hour;
            this.warningAddOneDay = false;
          }
        }

        this.checkDaysMinLimit(this.booking.days);

        if (this.booking.sale > 0) {
          this.booking.rental_cost = Math.round(
            this.booking.days *
              (this.booking.selected_price -
                this.booking.selected_price * (this.booking.sale / 100))
          );
        } else if (this.booking.sale_cash > 0) {
          this.booking.rental_cost = Math.round(
            this.booking.days *
              (this.booking.selected_price - this.booking.sale_cash)
          );
        } else if (this.booking.rental_cost_sale > 0) {
          this.booking.rental_cost -=
            this.booking.rental_cost * (this.booking.rental_cost_sale / 100);
        } else if (this.booking.rental_cost_sale_cash > 0) {
          this.booking.rental_cost -= this.booking.rental_cost_sale_cash;
        } else {
          this.booking.rental_cost = Math.round(
            this.booking.days * this.booking.selected_price
          );
        }

        if (
          this.booking.delivery > 0 ||
          this.booking.delivery_end > 0 ||
          this.booking.equipment > 0 ||
          this.booking.other > 0 ||
          this.booking.hours_cost > 0
        ) {
          this.booking.total =
            this.booking.rental_cost +
            this.booking.hours_cost +
            this.booking.delivery +
            this.booking.delivery_end +
            this.booking.equipment +
            this.booking.other;
        } else {
          this.booking.total =
            this.booking.rental_cost + this.booking.hours_cost;
        }
      }
      this.calculateCostWithExtra();
      this.errors = [];
      this.dateEndError = false;
    },
    checkBookingsDates(car, start_date, end_date) {
      let self = this;
      if (car && start_date && end_date) {
        // let start_date = checked_booking.start_date;
        // let end_date = checked_booking.end_date;
        // let cars_bookings = this.bookings.filter(booking => {
        //   return booking.car_id === car.id && booking.id != checked_booking.id;
        // });
        self.cars_bookings.forEach((booking) => {
          let checked_start_date = moment(start_date, "DD-MM-YYYY H:mm");
          let checked_end_date = moment(end_date, "DD-MM-YYYY H:mm");
          let booking_start_date = moment(
            booking.start_date,
            "DD-MM-YYYY H:mm"
          ).subtract(
            self.$store.getters.getCompany.company.interval_bookings,
            "hours"
          );
          let booking_end_date = moment(
            booking.end_date,
            "DD-MM-YYYY H:mm"
          ).add(
            self.$store.getters.getCompany.company.interval_bookings,
            "hours"
          );
          if (
            checked_start_date.isBetween(
              booking_start_date,
              booking_end_date,
              "hour"
            ) ||
            checked_end_date.isBetween(
              booking_start_date,
              booking_end_date,
              "hour"
            ) ||
            booking_start_date.isBetween(
              checked_start_date,
              checked_end_date,
              "hour"
            ) ||
            booking_end_date.isBetween(
              checked_start_date,
              checked_end_date,
              "hour"
            )
          ) {
            self.$swal({
              showConfirmButton: true,
              icon: "warning",
              title: self.$t("errors.booked_title"),
              text: self.$t("errors.booked_text"),
            });
          }
        });
      }
    },
    getClientInfo(event) {
      let client = this.clients.filter((x) => x.id === event)[0];
      this.booking.client_id = event;
      this.booking.fio = client.fio;
      this.client = client;
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  components: {
    // flatPickr
  },
};
</script>
