<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar v-if="!isMobile" text :color="$vuetify.theme.dark ? '' : 'white'" class="mb-2">
        <v-btn small text class="ml-2 mt-2" @click="goBack">{{
          $t("custom.goback")
        }}</v-btn>
        <v-btn
          small
          text
          class="mr-2 mt-2"
          color="green darken-1"
          to="/calendars/new"
          >{{ $t("custom.to_calendar") }}</v-btn
        >
        <v-btn
          small
          text
          class="mr-2 mt-2"
          color="blue darken-1"
          to="/bookings"
          >{{ $t("bookings.to_list") }}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="getBookingHistory()"
          v-tooltip="{
            content: $t('tooltips.booking_history'),
            placement: 'bottom-center',
            classes: ['black--text', 'white'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }"
        >
          <v-icon>mdi-clipboard-text-clock-outline</v-icon>
        </v-btn>
        <div
          :class="
            'pulse mt-3 mx-2 ' + (connected ? 'pulse_success' : 'pulse_error')
          "
          v-tooltip="{
            content: connected
              ? $t('custom.connected')
              : $t('custom.disconnected'),
            placement: 'bottom-center',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }"
        ></div>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/2`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title class="pt-0 pb-0 start-tour-booking-1" v-if="booking">
        <v-flex
          :class="
            !booking.technical
              ? 'd-flex align-center mt-2'
              : 'd-flex align-center blue-grey lighten-4 mt-2'
          "
        >
          <v-chip
            v-if="booking.vseprokaty_id"
            class="ma-2"
            :color="booking.active ? 'warning' : 'secondary'"
            text-color="white"
          >
            <v-avatar
              left
              color="secondary darken-2"
              v-tooltip="{
                content: $t('agregator.from_agregator'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              <v-icon>mdi-alpha-a-circle-outline</v-icon>
            </v-avatar>
            <v-avatar
              left
              v-if="booking.fast_booking"
              color="error darken-2"
              v-tooltip="{
                content: $t('agregator.fast_booking'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              <v-icon>mdi-lightning-bolt-circle</v-icon>
            </v-avatar>
            {{ $t("bookings.booking") }} {{ $t("custom.number")
            }}{{ booking.id }}
          </v-chip>
          <h3
            v-else
            :class="
              isMobile ? 'headline text-center' : 'headline text-center ml-3'
            "
            id="registration-step-64"
            nowrap
          >
            {{ $t("bookings.booking") }} {{ $t("custom.number")
            }}{{ booking.id }} -<span>&#160;</span>
          </h3>
          <v-menu
            offset-y
            v-if="booking.state == 'Новая' || booking.state == null"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined color="primary" small>
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                dense
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'В обработке'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="primary">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Отказ клиента'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="danger">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Нет машин'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="secondary">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Недозвон'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="warning">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Ожидает ответа клиента'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="pink">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Ожидает оплаты'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="lime">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Активная'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="light-blue">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Отмена'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="brown">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Отъездила'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined
                small
                color="light-green"
              >
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Вернуть залог'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="blue">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Подтверждена'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="success">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Не подтверждена'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="warning">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y v-if="booking.state == 'Другое'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" outlined small color="secondery">
                {{ translateState(booking.state) }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(state, index) in bookings_states"
                :key="index"
                @click="saveBookingState(state.value, booking.id)"
              >
                <v-list-item-title>
                  {{ state.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-flex>
      </v-card-title>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <!-- <v-container grid-list-md>
          <v-layout row wrap> -->
        <v-flex md12 :class="isMobile ? 'mx-3' : 'd-flex mx-3'">
          <v-flex xs12 md4 class="start-tour-booking-2">
            <div
              v-if="booking && booking.manual_editing"
              class="red--text"
              v-tooltip="{
                content: $t('tooltips.manual_editing'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("bookings.manual_editing") }}
            </div>
            <div v-if="booking.serial_number">
              {{ $t("bookings.serial_number") }}: {{ booking.serial_number }}
            </div>
            <div
              v-if="booking.source"
              v-tooltip="{
                content: $t('tooltips.booking_source'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("custom.created") }}:
              {{ formatCreatedAt(booking.created_at) }} |
              {{ booking.source ? booking.source : "?" }}
            </div>
            <div v-if="booking.vseprokaty_id">
              {{ $t("agregator.agregator_id") }}: {{ booking.vseprokaty_id }}
            </div>
            <div v-if="booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: +
            </div>
            <div v-if="booking.vseprokaty_id && !booking.fast_booking">
              {{ $t("agregator.fast_booking") }}: -
            </div>
            <!-- <div
              v-else
              v-tooltip="{
                content: $t('tooltips.creator'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("custom.created") }}:
              {{ formatCreatedAt(booking.created_at) }} |
              {{ booking.user_id ? findWorker(booking.user_id) : "сайт" }}
            </div> -->
            <div
              v-if="booking.updater"
              v-tooltip="{
                content: $t('tooltips.last_updator'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("custom.updator") }}:
              {{ formatCreatedAt(booking.updated_at) }} |
              {{ findWorker(booking.updater) }}
            </div>
            <div
              v-if="booking.state_updater"
              v-tooltip="{
                content: $t('tooltips.last_status'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("bookings.give_status") }}:
              {{ findWorker(booking.state_updater) }}
            </div>
            <div
              v-if="booking.start_worker_id"
              v-tooltip="{
                content: $t('tooltips.checkiner'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("bookings.checkiner") }}:
              {{ findWorker(booking.start_worker_id) }}
            </div>
            <div
              v-if="booking.end_worker_id"
              v-tooltip="{
                content: $t('tooltips.checkouter'),
                placement: 'bottom-center',
                classes: ['info'],
                targetClasses: ['it-has-a-tooltip'],
                delay: {
                  show: 500,
                  hide: 500,
                },
              }"
            >
              {{ $t("bookings.checkouter") }}:
              {{ findWorker(booking.end_worker_id) }}
            </div>
            <div
              v-if="!booking.entity && booking.client"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ $t("custom.client") }}:
              <router-link :to="{ path: `/clients/${booking.client_id}` }"
                >{{ booking.client.lastname }}
                {{ booking.client.name }}
                {{ booking.client.middlename }}</router-link
              >
            </div>
            <div
              v-else
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ $t("custom.client") }}:
              <router-link :to="{ path: `/clients/${booking.client_id}` }">{{
                booking.entity_name
              }}</router-link>
            </div>
            <div
              v-if="!booking.entity && booking.client && booking.client.phone"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ $t("custom.phone") }}:
              <a :href="'tel:' + booking.client.phone">{{
                booking.client.phone
              }}</a>
            </div>
            <div
              v-else
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ $t("custom.phone") }}:
              <a
                v-if="set_client.entity_phone"
                :href="'tel: ' + set_client.entity_phone"
                >{{ set_client.entity_phone }}</a
              >
              <span v-else> - </span>
            </div>
            <div>
              {{ $t("custom.car_long") }}:
              <router-link :to="{ path: `/cars/${booking.car_id}` }"
                >{{ booking.car_name }} - {{ booking.car_code }}</router-link
              >
            </div>
            <div>{{ $t("bookings.start_date") }}: {{ booking.start_date }}</div>
            <div>{{ $t("bookings.end_date") }}: {{ booking.end_date }}</div>
            <div>{{ $t("custom.days") }}: {{ booking.days }}</div>
            <div v-if="booking.additional_hours > 0">
              {{ $t("bookings.add_hours") }}: {{ booking.additional_hours }}
            </div>
            <div>
              {{ $t("bookings.checkin") }}: {{ booking.location_start }}
            </div>
            <div>{{ $t("bookings.checkout") }}: {{ booking.location_end }}</div>
            <div v-if="!booking.technical">
              {{ $t("bookings.current_price") }}: {{ booking.selected_price
              }}{{ currency }}
            </div>
            <div v-if="!booking.technical && booking.sale_cash">
              {{ $t("bookings.sale") }}: {{ booking.sale_cash }}{{ currency }}
            </div>
            <div v-if="!booking.technical && booking.sale">
              {{ $t("bookings.sale") }}: {{ booking.sale }}%
            </div>
            <div v-if="booking.tariff_id">
              {{ $t("bookings.tariff") }}: {{ findTariff(booking.tariff_id) }}
            </div>
            <div
              v-if="
                booking.selected_price != booking.start_price &&
                booking.start_price != 0
              "
            >
              {{ $t("bookings.start_price") }}: {{ booking.start_price
              }}{{ currency }}
            </div>
            <v-divider
              v-if="
                booking.chair ||
                booking.chair_less_1_year ||
                booking.booster ||
                booking.navigator ||
                booking.mp3 ||
                booking.charger ||
                booking.wifi_router
              "
              class="mt-2 mb-2"
            ></v-divider>
            <h3
              v-if="
                booking.chair ||
                booking.chair_less_1_year ||
                booking.booster ||
                booking.navigator ||
                booking.mp3 ||
                booking.charger ||
                booking.wifi_router
              "
            >
              {{ $t("bookings.add_equipment") }}
            </h3>
            <div v-if="booking.chair">
              {{ $t("bookings.baby_chair") }} - {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.chair_less_1_year">
              {{ $t("bookings.small_baby_chair") }} -
              {{ booking.chairs_quantity }}
            </div>
            <div v-if="booking.booster">
              {{ $t("bookings.booster") }} - {{ booking.boosters_quantity }}
            </div>
            <div v-if="booking.navigator">{{ $t("bookings.navigator") }}</div>
            <div v-if="booking.mp3">{{ $t("bookings.mp3") }}</div>
            <div v-if="booking.charger">{{ $t("bookings.charger") }}</div>
            <div v-if="booking.wifi_router">{{ $t("bookings.wifi") }}</div>
            <div v-if="booking.other_equipment">
              {{ booking.other_equipment }}
            </div>
            <h3
              v-if="
                booking.kasko ||
                booking.super_kasko ||
                booking.theft ||
                booking.no_franchise
              "
            >
              {{ $t("bookings.add_insurance") }}
            </h3>
            <div v-if="booking.kasko">{{ $t("bookings.kasko") }}</div>
            <div v-if="booking.super_kasko">
              {{ $t("bookings.super_kasko") }}
            </div>
            <div v-if="booking.theft">
              {{ $t("bookings.theft_insurance") }}
            </div>
            <div v-if="booking.no_franchise">
              {{ $t("bookings.no_franchise") }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <h3 v-if="!booking.technical">{{ $t("bookings.calculate") }}</h3>
            <div v-if="!booking.technical && booking.aggr_commission == 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ booking.rental_cost
              }}{{ currency }}
            </div>
            <div v-if="!booking.technical && booking.aggr_commission > 0" class="font-weight-bold">
              {{ $t("bookings.rental_cost") }}: {{ booking.rental_cost
              }} - {{booking.aggr_commission}} = {{(booking.rental_cost - booking.aggr_commission)}}{{ currency }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.hours_cost > 0 && !booking.technical"
            >
              {{ $t("bookings.hours_cost") }}: {{ booking.hours_cost
              }}{{ currency }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.hours_cost_end > 0 && !booking.technical"
            >
              {{ $t("bookings.hours_cost_end") }}: {{ booking.hours_cost_end
              }}{{ currency }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.delivery > 0 && !booking.technical"
            >
              {{ $t("bookings.delivery") }}: {{ booking.delivery
              }}{{ currency }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.delivery_end > 0 && !booking.technical"
            >
              {{ $t("bookings.checkout") }}: {{ booking.delivery_end
              }}{{ currency }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.equipment > 0 && !booking.technical"
            >
              {{ $t("bookings.equipment") }}: {{ booking.equipment
              }}{{ currency }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.clean_payment > 0 && !booking.technical"
            >
              {{ $t("bookings.clean") }}: {{ booking.clean_payment
              }}{{ currency }}
            </div>
            <div
              class="font-weight-bold"
              v-if="booking.damage > 0 && !booking.technical"
            >
              {{ $t("bookings.damages") }}: {{ booking.damage }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.gas > 0">
              {{ $t("bookings.for_gas") }}: {{ booking.gas }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.add_drivers_cost > 0">
              {{ $t("bookings.for_add_drivers") }}: {{ booking.add_drivers_cost
              }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.insurance > 0">
              {{ $t("bookings.for_add_insurance") }}: {{ booking.insurance
              }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.fine > 0">
              {{ $t("bookings.for_fines") }}: {{ booking.fine }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.other > 0">
              {{ $t("bookings.for_other") }}: {{ booking.other }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.other_end > 0">
              {{ $t("bookings.for_other_end") }}: {{ booking.other_end
              }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.mileage_cost > 0">
              {{ $t("bookings.mileage_cost") }}: {{ booking.mileage_cost
              }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="booking.aggr_commission > 0">
              <!-- <v-icon>mdi-alpha-a-circle-outline</v-icon> -->
              {{ $t("bookings.aggr_commission") }}: {{ booking.aggr_commission }}{{ currency }}
            </div>
            <div class="font-weight-bold" v-if="!booking.technical">
              {{ $t("bookings.deposit") }}: {{ booking.deposit }}{{ currency }}
            </div>
            <div class="subtitle-1 font-weight-bold" v-if="!booking.technical">
              {{ $t("custom.total") }}:
              {{
                booking.rental_cost +
                booking.delivery +
                booking.delivery_end +
                booking.equipment +
                booking.insurance +
                booking.clean_payment +
                booking.add_drivers_cost +
                booking.hours_cost +
                booking.mileage_cost +
                booking.damage +
                booking.gas +
                booking.fine +
                booking.other +
                booking.other_end +
                booking.hours_cost_end +
                booking.deposit
              }}{{ currency }}
            </div>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div
              :class="
                'subtitle-1 font-weight-bold ' +
                `${
                  calculatePayments(booking) == $t('bookings.paid')
                    ? 'success--text'
                    : ''
                }`
              "
              v-if="!booking.technical"
            >
              {{ $t("bookings.paid") }}: {{ calculatePaymentsSum(booking)
              }}{{ currency }}
            </div>
            <v-divider
              v-if="
                calculateRentPaymentsSum(booking) > 0 &&
                booking.rental_cost > 0 &&
                booking.days > 0
              "
              class="mt-2 mb-2"
            ></v-divider>
            <v-progress-linear
              v-if="
                calculateRentPaymentsSum(booking) > 0 &&
                booking.rental_cost > 0 &&
                booking.days > 0
              "
              :value="progressPercent"
              :color="progressPercent == 100 ? 'success' : 'warning'"
              rounded
              height="20"
            >
              <strong class="white--text"
                >{{ paidDays }} {{ $t("other.from") }} {{ booking.days }}
                {{ $t("bookings.days_low") }}</strong
              >
            </v-progress-linear>
            <v-divider class="mt-2 mb-2"></v-divider>
            <div
              v-if="
                booking.description &&
                booking.description != '' &&
                booking.description != ' '
              "
            >
              <h3>{{ $t("bookings.description") }}</h3>
              <p>{{ booking.description }}</p>
            </div>
            <div v-if="add_drivers.length > 0">
              <h3>{{ $t("bookings.add_drivers") }}</h3>
              <ul v-for="driver in add_drivers" :key="driver.id">
                <li>
                  <router-link :to="{ path: `/clients/${driver.id}` }"
                    >{{ driver.lastname }} {{ driver.name }}
                    {{ driver.middlename }}</router-link
                  >
                </li>
              </ul>
            </div>
            <div v-if="booking.in_rent || booking.ride">
              {{ $t("bookings.clean_start") }}:
              <v-icon color="green" v-if="booking.clean_start">
                mdi-plus
              </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div v-if="booking.ride">
              {{ $t("bookings.clean_end") }}:
              <v-icon color="green" v-if="booking.clean_end"> mdi-plus </v-icon>
              <v-icon color="red" v-else> mdi-minus </v-icon>
            </div>
            <div
              v-if="
                (booking.in_rent || booking.ride) && !booking.gas_start_full
              "
            >
              {{ $t("bookings.gas_start") }}: {{ booking.gas_start }}
            </div>
            <div
              v-if="(booking.in_rent || booking.ride) && booking.gas_start_full"
            >
              {{ $t("bookings.gas_start_full") }}
            </div>
            <div v-if="!booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_short") }}: {{ booking.gas_end }}
            </div>
            <div v-if="booking.gas_end_full && booking.ride">
              {{ $t("bookings.gas_end_full") }}
            </div>
            <div
              v-if="
                (booking.in_rent || booking.ride) && booking.start_mileage > 0
              "
            >
              {{ $t("bookings.mileage_start") }}: {{ booking.start_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.end_mileage > 0">
              {{ $t("bookings.mileage_end") }}: {{ booking.end_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.booking_mileage") }}:
              {{ booking.booking_mileage }}
              {{ $t("custom.km") }}
            </div>
            <div v-if="booking.booking_mileage > 0">
              {{ $t("bookings.average_mileage") }}:
              {{ Math.trunc(booking.booking_mileage / booking.days) }}
              {{ $t("bookings.km_day") }}
            </div>
          </v-flex>
          <div xs12 md1 class="vl"></div>
          <v-flex xs12 md4>
            <h2
              v-if="booking.counts && booking.counts.length == 0"
              class="text-center"
            >
              {{ $t("bookings.no_counts") }}
            </h2>
            <v-expansion-panels
              v-if="booking.counts && booking.counts.length != 0"
              v-model="paymentsPanel"
              class="start-tour-booking-3"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("bookings.counts") }}
                    <download-excel
                      :data="booking.counts"
                      v-if="
                        booking &&
                        booking.counts &&
                        booking.counts.length > 0 &&
                        user_role != 'guest' &&
                        user_role != 'partner'
                      "
                    >
                      <v-icon
                        class="ml-1"
                        style="cursor: pointer"
                        color="success"
                      >
                        mdi-file-excel-outline
                      </v-icon>
                    </download-excel>
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t("custom.number") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.date") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.sum") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.type") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.group") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="count in booking.counts"
                          :key="count.index"
                          :class="count.operation ? '' : 'red--text'"
                        >
                          <td class="text-center">
                            {{ count.id }}
                          </td>
                          <td class="text-center">
                            {{ formatCreatedAt(count.created_at) }}
                          </td>
                          <td class="text-center">
                            {{ count.operation ? "" : "-" }}{{ count.sum
                            }}{{ currency }}
                          </td>
                          <td class="text-center" v-if="count.cash">
                            <v-icon
                              class="text-center"
                              color="success"
                              v-tooltip="{
                                content: $t('money.cash'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-cash
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.cashless">
                            <v-icon
                              class="text-center"
                              color="warning"
                              v-tooltip="{
                                content: $t('money.terminal'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-contactless-payment-circle-outline
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.entity">
                            <v-icon
                              class="text-center"
                              color="secondery"
                              v-tooltip="{
                                content: $t('money.to_entity'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-bank
                            </v-icon>
                          </td>
                          <td class="text-center" v-if="count.cash_card">
                            <v-icon
                              class="text-center"
                              color="info"
                              v-tooltip="{
                                content: $t('money.card_to_card'),
                                placement: 'bottom-center',
                                classes: ['info'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-credit-card-check-outline
                            </v-icon>
                          </td>
                          <td class="text-center">
                            {{ translateCountGroup(count.group) }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- Продления -->
            <v-expansion-panels
              v-if="booking.prolongs && booking.prolongs.length != 0"
              v-model="prolongsPanel"
              class="mt-2"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("bookings.prolongs") }}
                    <download-excel
                      :data="booking.prolongs"
                      v-if="
                        booking &&
                        booking.prolongs &&
                        booking.prolongs.length > 0 &&
                        user_role != 'guest' &&
                        user_role != 'partner'
                      "
                    >
                      <v-icon
                        class="ml-1"
                        style="cursor: pointer"
                        color="success"
                      >
                        mdi-file-excel-outline
                      </v-icon>
                    </download-excel>
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ $t("bookings.date_prolong") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.days") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.price") }}
                          </th>
                          <th class="text-center">
                            {{ $t("custom.total") }}
                          </th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(prolong, index) in booking.prolongs"
                          :key="prolong.index"
                        >
                          <td class="text-center">
                            {{ formatPrologDate(prolong.last_date) }}
                          </td>
                          <td class="text-center">
                            {{ prolong.prolong_days }}
                          </td>
                          <td class="text-center">
                            {{ prolong.new_price }}{{ currency }}
                          </td>
                          <td class="text-center">
                            {{ prolong.new_price * prolong.prolong_days
                            }}{{ currency }}
                          </td>
                          <td class="text-center">
                            <v-icon
                              v-if="index == 0"
                              @click="deleteProlong(prolong)"
                              class="text-center"
                              color="warning"
                              v-tooltip="{
                                content: $t('bookings.annul_prolong'),
                                placement: 'bottom-center',
                                classes: ['warning'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-delete-outline
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <!-- Подарки -->
            <v-expansion-panels
              v-if="gifts && gifts.length != 0"
              v-model="giftsPanel"
              class="mt-2"
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2 class="mb-0 d-flex">
                    {{ $t("gifts.title") }}
                  </h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            {{ $t("gifts.created") }}
                          </th>
                          <th class="text-center">
                            {{ $t("gifts.type") }}
                          </th>
                          <th class="text-center">
                            {{ $t("gifts.count_short") }}
                          </th>
                          <th class="text-center">
                            {{ $t("bookings.price") }}
                          </th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="gift in gifts" :key="gift.index">
                          <td class="text-center">
                            {{ formatPrologDate(gift.created_at) }}
                          </td>
                          <td class="text-center">
                            {{
                              gift.gift_type == 0
                                ? $t("gifts.days")
                                : $t("gifts.hours")
                            }}
                          </td>
                          <td class="text-center">
                            {{ gift.count }}
                          </td>
                          <td class="text-center">
                            {{ gift.price }}{{ currency }}
                          </td>
                          <td class="text-center">
                            <v-icon
                              @click="deleteGift(gift)"
                              class="text-center"
                              color="warning"
                              v-tooltip="{
                                content: $t('gifts.delete'),
                                placement: 'bottom-center',
                                classes: ['warning'],
                                targetClasses: ['it-has-a-tooltip'],
                                delay: {
                                  show: 500,
                                  hide: 500,
                                },
                              }"
                            >
                              mdi-delete-outline
                            </v-icon>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
          <div xs12 md1 class="vl"></div>
          <v-flex xs12 md3 class="mx-auto start-tour-booking-4">
            <h3 class="headline text-center" nowrap>
              {{ $t("bookings.control") }}
            </h3>
            <v-row justify="space-around" class="mb-3 mt-2">
              <v-btn
                :small="isMobile ? true : false"
                outlined
                color="primary"
                v-if="
                  !booking.in_rent &&
                  booking.counts &&
                  booking.counts.length === 0 &&
                  !booking.technical
                "
                @click="paymentStart(booking)"
                >{{ $t("bookings.pay") }}</v-btn
              >
              <v-btn
                :small="isMobile ? true : false"
                :color="selectColorPaid(booking)"
                v-if="
                  (booking.counts && booking.counts.length > 0) ||
                  (booking.in_rent && !booking.technical)
                "
                @click="paymentStart(booking)"
                v-tooltip="{
                  content: `${
                    calculatePayments(booking) != $t('bookings.paid')
                      ? $t('bookings.part_paid')
                      : $t('bookings.full_paid')
                  }`,
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
                >{{ calculatePayments(booking) }}</v-btn
              >
            </v-row>
            <v-row justify="space-around" class="mb-3 mt-2">
              <v-icon
                color="green"
                v-if="booking.active && booking.in_rent"
                size="30"
                @click="prolongDialog = true"
                v-tooltip="{
                  content: $t('bookings.prolong'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-history
              </v-icon>
              <v-icon
                v-if="booking.in_rent"
                @click="arrivalDialog = true"
                size="30"
                v-tooltip="{
                  content: $t('bookings.checkout_car'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-home
              </v-icon>
              <v-icon
                v-if="booking.active && !booking.in_rent"
                size="30"
                @click="
                  (booking = booking),
                    checkPayments(),
                    getCurrentMileage(booking)
                "
                v-tooltip="{
                  content: $t('bookings.checkin_car'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-airplane-takeoff
              </v-icon>
              <v-icon
                color="grey darken-2"
                size="30"
                @click="editBooking"
                v-tooltip="{
                  content: $t('bookings.edit_booking'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
                >mdi-pencil-outline</v-icon
              >
              <v-icon
                color="green"
                size="30"
                v-if="!booking.active && !booking.failure && !booking.vseprokaty_id"
                @click="activateBooking(booking)"
                v-tooltip="{
                  content: $t('bookings.activate_booking'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-caps-lock
              </v-icon>
              <v-icon
                color="green"
                size="30"
                v-if="
                  $store.getters.getCompany &&
                  $store.getters.getCompany.company &&
                  $store.getters.getCompany.company.word_templates &&
                  booking.active &&
                  !booking.entity &&
                  user_role != 'guest' &&
                  user_role != 'partner'
                "
                @click="generateDoc(booking)"
                v-tooltip="{
                  content: $t('bookings.generate_docs'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-file-document-outline
              </v-icon>
              <v-icon
                color="green"
                size="30"
                v-if="
                  $store.getters.getCompany &&
                  $store.getters.getCompany.company &&
                  $store.getters.getCompany.company.word_templates &&
                  booking.active &&
                  booking.entity &&
                  user_role != 'guest' &&
                  user_role != 'partner'
                "
                @click="generateDocEntity(booking)"
                v-tooltip="{
                  content: $t('bookings.generate_docs'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-file-document-outline
              </v-icon>
              <v-icon
                color="red"
                size="30"
                v-if="(!booking.active && !booking.archive)"
                @click="archiveBooking(booking)"
                v-tooltip="{
                  content: $t('bookings.archivate_booking'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-archive-arrow-down
              </v-icon>
              <v-icon
                color="blue-grey"
                size="25"
                @click="show_gifts_dialog = true"
                v-tooltip="{
                  content: $t('gifts.tooltip'),
                  placement: 'bottom-center',
                  classes: ['black white--text'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-gift-outline
              </v-icon>
              <v-icon
                size="30"
                v-if="
                  $store.getters.getCompany &&
                  $store.getters.getCompany.company &&
                  $store.getters.getCompany.company.new_templates &&
                  user_role != 'guest' &&
                  user_role != 'partner'
                "
                color="primary"
                @click="showSignPad = true"
              >
                mdi-draw
              </v-icon>
              <v-menu
                offset-y
                v-if="
                  $store.getters.getCompany &&
                  $store.getters.getCompany.company &&
                  $store.getters.getCompany.company.new_templates
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon>
                    <v-icon
                      color="pink"
                      size="30"
                      @click="getTemplates()"
                      v-tooltip="{
                        content: $t('bookings.generate_docs'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }"
                    >
                      mdi-file-document-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(template, index) in templates"
                    :key="index"
                    @click="downloadSignedDoc(template)"
                  >
                    <v-list-item-title>
                      {{ template.name }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-icon
                color="grey"
                size="30"
                v-if="!booking.active && booking.archive"
                @click="unarchiveBooking(booking)"
                v-tooltip="{
                  content: $t('bookings.unarchivate_booking'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-archive-arrow-up
              </v-icon>
              <v-icon
                color="light-blue accent-3"
                size="28"
                v-if="booking.active"
                @click="uploadPhotosDialog = true"
                v-tooltip="{
                  content: $t('bookings.upload_photos'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-camera
              </v-icon>
              <v-icon
                v-if="checkAdmin() && !booking.active"
                size="30"
                color="red darken-2"
                @click="deleteBooking(booking)"
                v-tooltip="{
                  content: $t('bookings.delete_booking'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
              >
                mdi-delete-forever
              </v-icon>
            </v-row>
            <hr class="mb-2 mt-2" />
            <h3
              class="headline text-center"
              v-if="!booking.technical && !booking.vseprokaty_id"
            >
              {{ $t("bookings.notifications") }}
            </h3>
            <v-row
              class="mt-1"
              justify="space-around"
              v-if="!booking.technical && !booking.vseprokaty_id"
            >
              <v-btn
                class="mb-2"
                small
                :disabled="
                  sendEmail == false &&
                  sendWhatsapp == false &&
                  sendSms == false
                "
                :color="booking.send_accept_email ? 'grey' : 'success'"
                @click="acceptBooking()"
              >
                {{ $t("bookings.send_approve") }}
              </v-btn>
              <v-btn
                class="mb-2"
                small
                :disabled="
                  sendEmail == false &&
                  sendWhatsapp == false &&
                  sendSms == false
                "
                :color="booking.send_custom_email ? 'grey' : 'primary'"
                @click="
                  getEmailTemplates(),
                    getTemplates(),
                    (showEmailTemplates = true),
                    (showCustomEmailDialog = true)
                "
                v-tooltip="{
                  content: $t('bookings.send_client'),
                  placement: 'bottom-center',
                  classes: ['info'],
                  targetClasses: ['it-has-a-tooltip'],
                  delay: {
                    show: 500,
                    hide: 500,
                  },
                }"
                >{{ $t("bookings.from_template") }}</v-btn
              >
              <!-- <v-btn
                    class="mb-2"
                    small
                    :color="booking.send_cancel_email ? 'grey' : 'success'"
                    @click="cancelBookingFewDaysEmail()"
                    >Отказ меньше 2-х</v-btn
                  > -->
              <v-btn
                class="mb-2"
                small
                :disabled="
                  sendEmail == false &&
                  sendWhatsapp == false &&
                  sendSms == false
                "
                :color="booking.send_cancel_email ? 'grey' : 'warning'"
                @click="cancelBookingNotCarsEmail()"
                >{{ $t("bookings.decline_simple") }}</v-btn
              >
            </v-row>
            <v-row
              class="mb-3"
              justify="space-around"
              v-if="!booking.technical && !booking.vseprokaty_id"
            >
              <v-switch
                v-model="sendEmail"
                @change="checkEmail()"
                class="mt-0 mr-1 mr-1"
                label="Email"
                dense
                hide-details
              ></v-switch>
              <v-switch
                v-model="sendWhatsapp"
                @change="checkWhatsapp()"
                class="mt-0 mr-1 mr-1"
                label="WhatsApp"
                :disabled="
                  $store.getters.getCompany.company
                    ? !$store.getters.getCompany.company.whatsapp
                    : false
                "
                dense
                hide-details
              ></v-switch>
              <!-- <v-switch
                v-model="sendSms"
                @change="checkSms()"
                class="mt-0 mr-1 mr-1"
                :label="$t('custom.sms')"
                :disabled="true"
                dense
                hide-details
              ></v-switch> -->
            </v-row>
            <v-flex v-if="!booking.active && booking.vseprokaty_id && !booking.fast_booking && !booking.failure" class="text-center">
              <countdown :time="hour_timer">
                <template slot-scope="props">{{ $t('agregator.countdown_for_decision') }}：{{ props.minutes }} {{ $t('agregator.minutes') }}, {{ props.seconds }} {{ $t('agregator.seconds') }}</template>
              </countdown>
            </v-flex>
            <v-row
              class="mt-1"
              justify="space-around"
              v-if="booking.vseprokaty_id && !booking.active"
            >
              <v-btn
                class="mb-2"
                :disabled="
                  booking.fast_booking || booking.failure || booking.accepted
                "
                color="success"
                @click="acceptInAgregator()"
              >
                {{ $t("bookings.send_approve") }}
              </v-btn>
              <v-btn
                class="mb-2"
                :disabled="
                  booking.fast_booking || booking.failure || booking.accepted
                "
                color="warning"
                @click="cancelInAgregator()"
                >{{ $t("bookings.decline_simple") }}</v-btn
              >
            </v-row>
            <hr class="mb-2 mt-3" />
            <h3
              class="headline text-center my-2"
              v-if="!booking.technical && booking.vseprokaty_id"
            >
              {{ $t("custom.messages") }}
            </h3>
            <v-card
              v-if="
                !booking.technical &&
                booking.vseprokaty_id &&
                messages &&
                messages.length > 0
              "
              height="200"
              class="overflow-auto"
            >
              <v-flex
                :key="index"
                v-for="(item, index) in messages"
                class="my-2 mx-2"
              >
                <v-alert
                  class="mb-1"
                  border="left"
                  :color="item && item.sender == 'User' ? 'info' : 'blue-grey'"
                  dark
                  dense
                  :icon="selectIcon(item.sender)"
                >
                  <v-flex>
                    <div v-html="item.text"></div>
                  </v-flex>
                </v-alert>
                <v-flex class="caption text-right">{{
                  formatPrologDate(item.created_at)
                }}</v-flex>
              </v-flex>
            </v-card>
            <v-alert
              v-if="booking.vseprokaty_id && messages && messages.length == 0"
              class="my-2"
              border="top"
              colored-border
              type="info"
              elevation="2"
            >
              {{ $t("agregator.no_new_messages") }}
            </v-alert>
            <v-card
              v-if="
                !booking.technical &&
                booking.vseprokaty_id &&
                !booking.failure &&
                !booking.ride
              "
              class="mt-2 mb-4 py-2"
            >
              <v-flex class="d-flex">
                <v-textarea
                  v-model.trim="new_message"
                  @keypress="checkEnter($event, booking.id)"
                  class="mx-2"
                  :label="$t('messages.text')"
                  rows="1"
                  auto-grow
                  :counter="500"
                ></v-textarea>
                <v-btn class="ma-2" @click="sendMessage(booking.id)" icon>
                  <v-icon v-if="!send_message_loading" large color="secondary"
                    >mdi-send-circle-outline</v-icon
                  >
                  <v-progress-circular
                    class="my-auto"
                    v-else
                    indeterminate
                    color="secondary"
                    size="20"
                  ></v-progress-circular>
                </v-btn>
              </v-flex>
            </v-card>
            <hr
              class="mb-2 mt-3"
              v-if="
                booking.generated_documents &&
                booking.generated_documents.length
              "
            />
            <h3
              class="headline text-center"
              v-if="
                booking.generated_documents &&
                booking.generated_documents.length
              "
            >
              {{ $t("bookings.signed_documents") }}
            </h3>
            <v-row
              class="mt-1"
              justify="space-around"
              v-if="!booking.technical"
            >
              <v-simple-table
                dense
                v-if="
                  booking.generated_documents &&
                  booking.generated_documents.length
                "
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center">
                        {{ $t("custom.created") }}
                      </th>
                      <th class="text-center">
                        {{ $t("bookings.template") }}
                      </th>
                      <th class="text-center">
                        {{ $t("bookings.control") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="document in booking.generated_documents"
                      :key="document.index"
                    >
                      <td class="text-center">
                        {{ formatCreatedAt(document.created_at) }}
                      </td>
                      <td class="text-center">
                        {{ document.name }}
                      </td>
                      <td class="text-center">
                        <v-icon
                          color="secondery"
                          class="mr-2"
                          @click="downloadSignedDocBottom(document.link)"
                          >mdi-file-download</v-icon
                        >
                        <v-icon
                          color="success"
                          class="mr-2"
                          @click="sendSignedDocument(document.id)"
                          >mdi-email-send</v-icon
                        >
                        <v-icon
                          color="red darken-2"
                          @click="deleteSignedDoc(document.id)"
                          >mdi-delete-forever</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
          </v-flex>
        </v-flex>
        <!-- Долги -->
        <v-flex md12 v-if="debts.length > 0">
          <v-divider class="mt-3 mb-3"></v-divider>
          <h2>{{ $t("custom.debts") }}</h2>
          <v-data-table
            :headers="debts_headers"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :items="debts"
            :sort-by="['created_at']"
            :sort-desc="[true]"
            dense
            hide-default-footer
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td class="text-center">
                  <router-link :to="{ path: `/debts/${booking.id}` }">{{
                    props.item.id
                  }}</router-link>
                </td>
                <td class="text-center">
                  {{ returnDate(props.item.created_at) }}
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ path: `/clients/${props.item.client_id}` }"
                    >{{ props.item.client_name }}</router-link
                  >
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ path: `/bookings/${props.item.booking_id}` }"
                    >{{ props.item.booking_id }}</router-link
                  >
                </td>
                <td class="text-center">{{ props.item.group }}</td>
                <td class="text-center">{{ props.item.description }}</td>
                <td class="text-center">{{ props.item.sum }}{{ currency }}</td>
                <td class="text-center">{{ props.item.paid }}{{ currency }}</td>
                <!-- Состояние -->
                <td class="text-center">
                  <v-btn :color="stateDebtColor(props.item.state)" small block>
                    {{ props.item.state }}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <!-- Штрафы -->
        <v-flex md12 v-if="fines.length > 0">
          <v-divider class="mt-3 mb-3"></v-divider>
          <h2>{{ $t("custom.fines") }}</h2>
          <v-data-table
            :headers="fines_headers"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :items="fines"
            :search="search_fines"
            :sort-by="['created_at']"
            :sort-desc="[true]"
            dense
            hide-default-footer
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td class="text-center">
                  <router-link :to="{ path: `/fines/${props.item.number}` }">{{
                    props.item.number
                  }}</router-link>
                </td>
                <td class="text-center">
                  {{ returnDate(props.item.date) }}
                </td>
                <td class="text-center">
                  <router-link
                    v-if="!booking.entity"
                    :to="{ path: `/clients/${props.item.client_id}` }"
                    >{{ props.item.client_name }}</router-link
                  >
                  <router-link
                    v-else
                    :to="{ path: `/clients/${props.item.client_id}` }"
                    >{{ props.item.client_id }}</router-link
                  >
                </td>
                <td class="text-center">{{ props.item.sum }}{{ currency }}</td>
                <td class="text-center description-trunc">
                  <read-more
                    v-if="
                      props.item.description &&
                      props.item.description.length > 0
                    "
                    :more-str="$t('custom.read')"
                    :text="props.item.description"
                    link="#"
                    :less-str="$t('custom.less')"
                    :max-chars="20"
                  ></read-more>
                </td>
                <!-- Состояние -->
                <td class="text-center">
                  <v-btn :color="stateFineColor(props.item.state)" small>
                    {{ props.item.state }}
                  </v-btn>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{ $t("tables.no_search_result", { msg: search_fines }) }}
            </v-alert>
          </v-data-table>
        </v-flex>
        <!-- Счета -->
        <v-flex md12 v-if="invoices.length > 0">
          <v-divider class="mt-3 mb-3"></v-divider>
          <h2>{{ $t("custom.invoices") }}</h2>
          <v-data-table
            :headers="headers_invoices"
            :items="invoices"
            :search="search_invoices"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :items-per-page="20"
            :sort-by="['created_at']"
            :sort-desc="[true]"
            :class="!isMobile ? '' : 'is-mobile'"
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [20, 50, 100, -1],
            }"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td class="text-center">
                  <router-link
                    :to="{ path: `/invoices/${props.item.number}` }"
                    >{{ props.item.number }}</router-link
                  >
                </td>
                <td class="text-center">
                  {{ returnDate(props.item.date) }}
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ path: `/clients/${props.item.client_id}` }"
                    >{{ props.item.client_name }}</router-link
                  >
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ path: `/bookings/${props.item.booking_id}` }"
                    >{{ props.item.booking_id }}</router-link
                  >
                </td>
                <td class="text-center">
                  <read-more
                    v-if="
                      props.item.description &&
                      props.item.description.length > 0
                    "
                    :more-str="$t('custom.read')"
                    :text="props.item.description"
                    link="#"
                    :less-str="$t('custom.less')"
                    :max-chars="20"
                  ></read-more>
                </td>
                <td class="text-center">
                  {{
                    props.item.rent +
                    props.item.deposit +
                    props.item.delivery +
                    props.item.delivery_end +
                    props.item.clean +
                    props.item.gas +
                    props.item.damage +
                    props.item.fines +
                    props.item.mileage +
                    props.item.hours_cost +
                    props.item.insurance +
                    props.item.equipment +
                    props.item.other
                  }}{{ currency }}
                </td>
                <td class="text-center">{{ paidInvoice(props.item) }}</td>
                <td class="text-center">
                  {{ props.item.paid ? props.item.paid : 0 }}{{ currency }}
                </td>
                <!-- Состояние -->
                <td class="text-center">
                  <v-btn
                    :color="stateInvoiceColor(props.item.state)"
                    small
                    block
                  >
                    {{ props.item.state }}
                  </v-btn>
                </td>
              </tr>
            </template>
            <template slot="no-data">
              {{ $t("custom.no_data_in_table") }}
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="mdi-alert"
            >
              {{ $t("tables.no_search_result", { msg: search_invoices }) }}
            </v-alert>
          </v-data-table>
        </v-flex>
        <!-- </v-layout>
        </v-container> -->
      </v-card-text>
    </v-card>
    <!-- Выдача -->
    <v-dialog
      v-model="departureDialog"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="1000px"
      :style="isMobile ? 'padding: 5px !important;' : ''"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">{{
          $t("bookings.checkin_params")
        }}</v-card-title>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-layout row wrap>
              <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                <v-checkbox
                  v-model="booking.clean_start"
                  :label="$t('bookings.clean_car')"
                ></v-checkbox>
                <v-checkbox
                  v-model="booking.gas_start_full"
                  value
                  :label="$t('bookings.full_tank')"
                ></v-checkbox>
                <v-checkbox
                  v-model="booking.navigator"
                  :label="$t('bookings.navigator')"
                ></v-checkbox>
                <v-checkbox
                  v-model="booking.cheir"
                  :label="$t('bookings.baby_chair')"
                ></v-checkbox>
              </v-flex>
              <v-flex md12>
                <v-text-field
                  v-if="!booking.gas_start_full"
                  v-model="booking.gas_start"
                  type="text"
                  :label="$t('bookings.lost_gas')"
                ></v-text-field>
              </v-flex>
              <v-flex md12>
                <v-text-field
                  :label="$t('bookings.current_mileage')"
                  v-model.number="booking.start_mileage"
                ></v-text-field>
              </v-flex>
              <v-flex md12>
                <v-text-field
                  v-model="booking.other_equipment"
                  type="text"
                  :label="$t('bookings.other_equipment')"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darken-1" text @click="departureDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn
            color="success"
            @click="checkDepartureMileage(booking.start_mileage)"
            >{{ $t("bookings.checkin_car") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Редактирование -->
    <v-dialog
      v-model="editDialog"
      :retain-focus="false"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-card-title :class="isMobile ? 'pt-10' : ''">
              <span class="headline">{{ $t("custom.editing") }}</span>
              <v-icon class="ml-2 green--text" @click="editSave()">
                mdi-content-save-all-outline
              </v-icon>
              <v-spacer></v-spacer>
              <v-icon
                :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
                @click.stop="
                  getBooking(),
                  getCar(),
                  (editDialog = false),
                  (manualEditing = false),
                  (warningAddOneDay = false),
                  (warningLessThenDay = false),
                  (showEditClient = false),
                  (editCar = false),
                  (selected_tariff = null),
                  (tariff = null),
                  (editTariff = false),
                  (editDate = false),
                  (start_date = null),
                  (end_date = null),
                  (editExtra = false),
                  (manualEditing = false)
                "
              >
                mdi-close
              </v-icon>
            </v-card-title>
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex
                  xs12
                  sm12
                  md12
                  :class="
                    isMobile
                      ? 'align-center'
                      : 'd-flex d-inline-flex align-center'
                  "
                  justify="space-between"
                >
                  <h2 class="m-0 p-0 text-center align-center">
                    {{ $t("bookings.booking") }} {{ $t("custom.number")
                    }}{{ booking.id }}
                  </h2>
                  <v-spacer></v-spacer>
                  <v-switch
                    :disabled="
                      booking.in_rent ||
                      booking.archive ||
                      (booking.vseprokaty_id ? true : false)
                    "
                    :label="$t('bookings.active')"
                    v-model="booking.active"
                    dense
                    hide-details
                    @change="
                      booking.active
                        ? checkBookingsDates(set_car, booking)
                        : false
                    "
                  ></v-switch>
                  <v-switch
                    color="red"
                    :label="$t('bookings.calc_auto')"
                    :disabled="booking.archive"
                    dense
                    hide-details
                    v-model="auto_calculate"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <div>
                    {{ $t("custom.client") }}:
                    <router-link
                      v-if="!booking.entity"
                      :to="{ path: `/clients/${booking.client_id}` }"
                      :style="
                        user_role == 'guest' || user_role == 'partner'
                          ? 'display: none;'
                          : ''
                      "
                      >{{ booking.last_name }} {{ booking.first_name }}
                      {{ booking.middle_name }}</router-link
                    >
                    <router-link
                      v-else
                      :to="{ path: `/clients/${booking.client_id}` }"
                      :style="
                        user_role == 'guest' || user_role == 'partner'
                          ? 'display: none;'
                          : ''
                      "
                      >{{ booking.entity_name }}</router-link
                    >
                    , {{ $t("bookings.bookings_days") }}: {{ booking.days }},
                    {{ $t("bookings.price_bookings_days") }}:
                    {{ booking.selected_price }}{{ currency }},
                    <span v-if="booking.tariff_id">
                      {{ $t("bookings.tariff_small") }}:
                      {{ findTariff(booking.tariff_id) }},</span
                    >
                    {{ $t("bookings.total_for_rent") }}: {{ booking.rental_cost
                    }}{{ currency }}
                    <span v-if="booking.hours_cost > 0"
                      >, {{ $t("bookings.for_add_hours") }}:
                      {{ booking.hours_cost }}{{ currency }}</span
                    >
                    <span v-if="booking.insurance > 0"
                      >, {{ $t("bookings.for_insurance") }}:
                      {{ booking.insurance }}{{ currency }}</span
                    >
                    <span v-if="booking.add_drivers_cost > 0"
                      >, {{ $t("bookings.for_add_drivers_small") }}:
                      {{ booking.add_drivers_cost }}{{ currency }}</span
                    >
                    <span v-if="booking.equipment > 0"
                      >, {{ $t("bookings.for_equipment") }}:
                      {{ booking.equipment }}{{ currency }}</span
                    >
                    <span v-if="booking.delivery > 0"
                      >, {{ $t("bookings.for_delivery") }}: {{ booking.delivery
                      }}{{ currency }}</span
                    >
                    <span v-if="booking.delivery_end > 0"
                      >, {{ $t("bookings.for_checkout") }}:
                      {{ booking.delivery_end }}{{ currency }}</span
                    >
                    <span v-if="booking.clean_payment > 0"
                      >, {{ $t("custom.clean_low") }}: {{ booking.clean_payment
                      }}{{ currency }}</span
                    >
                    <span v-if="booking.fine > 0"
                      >, {{ $t("bookings.for_fines_small") }}: {{ booking.fine
                      }}{{ currency }}</span
                    >
                    <span v-if="booking.other > 0"
                      >, {{ $t("bookings.for_other_small") }}: {{ booking.other
                      }}{{ currency }}</span
                    >
                    . {{ $t("bookings.total") }}: {{ booking.total }} +
                    {{ booking.deposit }} = {{ booking.total + booking.deposit
                    }}{{ currency }}
                  </div>
                  <div
                    v-if="
                      warningAddOneDay ||
                      (booking.additional_hours > 0 &&
                        booking.additional_hours * set_car.price_hour >
                          booking.selected_price) ||
                      checkHoursLimit
                    "
                    class="red--text"
                  >
                    {{ $t("bookings.add_day") }}
                  </div>
                  <div v-if="warningLessThenDay" class="red--text">
                    {{ $t("bookings.less_then_day") }}
                  </div>
                </v-flex>
                <v-flex
                  xs12
                  sm12
                  md12
                  v-if="
                    !booking.technical &&
                    selected_seasons &&
                    selected_seasons.length > 0
                  "
                >
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        style="padding: 2px 0 !important"
                      >
                        {{ $t("companies.prices_seasons") }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="item in selected_seasons"
                                :key="item.index"
                              >
                                <th class="text-left">
                                  {{ item.season.start_date }} -
                                  {{ item.season.end_date }}
                                </th>
                                <td class="text-left">
                                  {{ item.days }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-switch
                    :label="$t('bookings.edit_client')"
                    v-model="showEditClient"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 sm12 md8>
                  <div class="d-flex" v-if="showEditClient">
                    <v-autocomplete
                      v-model="client"
                      item-text="fullname"
                      item-value="id"
                      :loading="isLoadingClients"
                      :search-input.sync="searchClient"
                      flat
                      hide-no-data
                      hide-details
                      clearable
                      :label="$t('bookings.select_client')"
                      :items="clients"
                      prepend-icon="mdi-account-box"
                      return-object
                      :style="
                        user_role == 'guest' || user_role == 'partner'
                          ? 'display: none;'
                          : ''
                      "
                    >
                    </v-autocomplete>
                    <!-- <v-icon class='mt-3' @click="newClientDialog = true">mdi-account-plus-outline</v-icon> -->
                  </div>
                  <v-text-field
                    slot="activator"
                    :value="`${client.lastname} ${client.name} ${client.middlename}`"
                    :label="$t('bookings.client')"
                    disabled
                    v-if="!showEditClient && !booking.entity"
                    :style="
                      user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                    "
                  ></v-text-field>
                  <v-text-field
                    slot="activator"
                    v-model="client.entity_name"
                    :label="$t('bookings.client')"
                    disabled
                    v-if="!showEditClient && booking.entity"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md4>
                  <v-switch
                    :label="$t('bookings.edit_car')"
                    v-model="editCar"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 sm12 md8 v-if="!editCar">
                  <v-text-field
                    :label="$t('bookings.car')"
                    :value="booking.car_code"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md8 v-if="editCar">
                  <v-autocomplete
                    v-model="set_car"
                    item-text="code"
                    item-value="id"
                    @change="
                      calculateCost(), checkBookingsDates(set_car, booking)
                    "
                    :loading="isLoadingCars"
                    :search-input.sync="searchCar"
                    text
                    hide-no-data
                    hide-details
                    :label="$t('bookings.select_car')"
                    :items="cars"
                    return-object
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 md4 v-if="!editDate">
                  <v-switch
                    :label="$t('bookings.edit_dates')"
                    v-model="editDate"
                    @change="setDataToEditDate()"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 sm4 md4 v-if="!editDate">
                  <v-text-field
                    :label="$t('bookings.start_date_big')"
                    :value="booking.start_date"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4 v-if="!editDate">
                  <v-text-field
                    :label="$t('bookings.end_date_big')"
                    :value="booking.end_date"
                    disabled
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap v-if="editDate">
                <v-flex xs12 sm4 md4>
                  <v-switch
                    :label="$t('bookings.edit_dates')"
                    v-model="editDate"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md8 class="p-0">
                  <v-flex md12 class="d-flex p-0">
                    <vc-date-picker
                      v-model="start_date_not_formated"
                      mode="dateTime"
                      :masks="masks"
                      is24hr
                      class="flex d-flex md12 p-0"
                      timezone="UTC"
                      :locale="$i18n.locale"
                    >
                      <template
                        v-slot="{ inputValue, inputEvents, isDragging }"
                      >
                        <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                          <v-text-field
                            :class="
                              isDragging
                                ? 'text-gray-600 mr-2 p-0'
                                : 'text-gray-900 mr-2 p-0'
                            "
                            :value="inputValue"
                            v-on="inputEvents"
                            :label="$t('bookings.start_date_big')"
                            readonly
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </template>
                    </vc-date-picker>
                    <vc-date-picker
                      v-model="end_date_not_formated"
                      mode="dateTime"
                      :masks="masks"
                      :min-date="minDate()"
                      is24hr
                      class="flex d-flex md12 p-0"
                      timezone="UTC"
                      :locale="$i18n.locale"
                    >
                      <template
                        v-slot="{ inputValue, inputEvents, isDragging }"
                      >
                        <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                          <v-text-field
                            :class="
                              isDragging
                                ? 'text-gray-600 p-0'
                                : 'text-gray-900 p-0'
                            "
                            :value="inputValue"
                            v-on="inputEvents"
                            :label="$t('bookings.end_date_big')"
                            readonly
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </template>
                    </vc-date-picker>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-row wrap class="mt-4">
                <v-flex xs12 sm2 md2>
                  <v-switch
                    :label="$t('bookings.edit_place')"
                    v-model="editPlace"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 sm5 md5>
                  <v-text-field
                    :disabled="!editPlace"
                    @input="editExtra = true"
                    v-model="booking.location_start"
                    :label="$t('bookings.start_place')"
                    prepend-icon="mdi-map-marker"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm5 md5>
                  <v-text-field
                    :disabled="!editPlace"
                    @input="editExtra = true"
                    v-model="booking.location_end"
                    :label="$t('bookings.end_place')"
                    prepend-icon="mdi-map-marker"
                  ></v-text-field>
                </v-flex>
              </v-row>
              <v-layout row wrap justify-space-between class="align-center">
                <v-flex
                  md12
                  :class="isMobile ? 'align-center' : 'd-flex align-center'"
                  justify-space-between
                >
                  <v-switch
                    :label="$t('bookings.edit_equipment')"
                    v-model="editEquipment"
                  ></v-switch>
                  <v-checkbox
                    :disabled="!editEquipment"
                    :label="$t('bookings.baby_chair')"
                    v-model="booking.chair"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editEquipment"
                    :label="$t('bookings.small_baby_chair')"
                    v-model="booking.chair_less_1_year"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editEquipment"
                    :label="$t('bookings.booster')"
                    v-model="booking.booster"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editEquipment"
                    :label="$t('bookings.navigator')"
                    v-model="booking.navigator"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editEquipment"
                    :label="$t('bookings.wifi')"
                    v-model="booking.wifi_router"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editEquipment"
                    :label="$t('bookings.mp3')"
                    v-model="booking.mp3"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editEquipment"
                    :label="$t('bookings.charger')"
                    v-model="booking.charger"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  md12
                  :class="isMobile ? 'align-center' : 'd-flex align-center'"
                  justify-space-between
                  v-if="editEquipment"
                >
                  <v-text-field
                    :disabled="!editEquipment"
                    v-model="booking.chairs_quantity"
                    :label="$t('bookings.baby_chair_qnt')"
                    prepend-icon="mdi-map-marker"
                  ></v-text-field>
                  <v-text-field
                    :disabled="!editEquipment"
                    v-model="booking.boosters_quantity"
                    :label="$t('bookings.booster_qnt')"
                    prepend-icon="mdi-map-marker"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  md12
                  :class="isMobile ? 'align-center' : 'd-flex align-center'"
                  justify-space-between
                >
                  <v-switch
                    :label="$t('bookings.edit_insurance')"
                    v-model="editInsurance"
                  ></v-switch>
                  <v-checkbox
                    :disabled="!editInsurance"
                    :label="$t('bookings.kasko')"
                    v-model="booking.kasko"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editInsurance"
                    :label="$t('bookings.super_kasko')"
                    v-model="booking.super_kasko"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editInsurance"
                    :label="$t('bookings.theft')"
                    v-model="booking.theft"
                  ></v-checkbox>
                  <v-checkbox
                    :disabled="!editInsurance"
                    :label="$t('bookings.no_franchise')"
                    v-model="booking.no_franchise"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 md2>
                  <v-switch
                    :label="$t('bookings.edit_service_prices')"
                    v-model="editExtra"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.delivery"
                    :value="booking.delivery"
                    @input="calculateCost()"
                    :disabled="!editExtra"
                    :label="$t('bookings.delivery')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.delivery_end"
                    :value="booking.delivery_end"
                    @input="calculateCost()"
                    :disabled="!editExtra"
                    :label="$t('bookings.checkout')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.equipment"
                    :value="booking.equipment"
                    :disabled="!editExtra"
                    @input="calculateCost()"
                    :label="$t('bookings.equipment')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.clean_payment"
                    :value="booking.clean_payment"
                    :disabled="!editExtra"
                    @input="calculateCost()"
                    :label="$t('bookings.clean')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.insurance"
                    :value="booking.insurance"
                    @input="calculateCost()"
                    :disabled="!editExtra"
                    :label="$t('bookings.insurance')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.fine"
                    :value="booking.fine"
                    @input="calculateCost()"
                    :disabled="!editExtra"
                    :label="$t('bookings.fines')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.add_drivers_cost"
                    :value="booking.add_drivers_cost"
                    @input="calculateCost()"
                    :disabled="!editExtra"
                    :label="$t('bookings.add_drivers_small')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 md1>
                  <v-text-field
                    v-model.number="booking.other"
                    :value="booking.other"
                    :disabled="!editExtra"
                    @input="calculateCost()"
                    :label="$t('bookings.other')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-switch
                    :label="$t('bookings.edit_sale')"
                    v-model="editSale"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    :disabled="!editSale"
                    v-model.number="booking.sale"
                    :value="booking.sale"
                    @blur="calculateCost()"
                    :label="$t('bookings.edit_sale_percent')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    :disabled="!editSale"
                    v-model.number="booking.sale_cash"
                    :value="booking.sale_cash"
                    @blur="calculateCost()"
                    :label="$t('bookings.edit_sale_rub', { msg: currency })"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    :disabled="!editSale"
                    v-model.number="booking.rental_cost_sale"
                    :value="booking.rental_cost_sale"
                    @blur="calculateCost()"
                    :label="$t('bookings.edit_sale_rent_percent')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    :disabled="!editSale"
                    v-model.number="booking.rental_cost_sale_cash"
                    :value="booking.rental_cost_sale_cash"
                    @blur="calculateCost()"
                    :label="
                      $t('bookings.edit_sale_rent_rub', { msg: currency })
                    "
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-switch
                    :label="$t('bookings.edit_tariff')"
                    v-model="editTariff"
                    @change="getTariffs()"
                  ></v-switch>
                </v-flex>
                <v-flex xs12 sm8 md8>
                  <v-select
                    :disabled="!editTariff"
                    v-model="selected_tariff"
                    :items="tariffs"
                    item-text="fullname"
                    item-value="id"
                    :label="
                      selected_tariff
                        ? $t('bookings.tariff')
                        : $t('bookings.no_tariff')
                    "
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex
                  md12
                  :class="isMobile ? 'align-center' : 'd-flex align-center'"
                  justify-space-between
                >
                  <v-switch
                    color="red"
                    :label="$t('bookings.edit_data_manual')"
                    v-model="manualEditing"
                  ></v-switch>
                  <v-switch
                    :label="$t('bookings.add_info')"
                    v-model="showDescription"
                  ></v-switch>
                  <v-switch
                    :label="$t('bookings.add_driver')"
                    v-model="showAddDrivers"
                  ></v-switch>
                </v-flex>
              </v-layout>
              <v-layout row wrap v-if="manualEditing" class="align-center">
                <v-flex md12>
                  <p>
                    <span class="red--text">{{
                      $t("bookings.attention")
                    }}</span>
                    {{ $t("bookings.warn_manual") }}
                  </p>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.days"
                    :value="booking.days"
                    :label="$t('bookings.days_count')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.selected_price"
                    :value="booking.selected_price"
                    :label="$t('bookings.day_price')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.rental_cost"
                    :value="booking.rental_cost"
                    :label="$t('bookings.cost_for_day')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.total"
                    :value="booking.total"
                    :label="$t('bookings.cost_without_deposit')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.deposit"
                    :value="booking.deposit"
                    :label="$t('bookings.deposit')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.additional_hours"
                    :value="booking.additional_hours"
                    :label="$t('bookings.add_hours_count')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.price_hour"
                    :value="booking.price_hour"
                    :label="$t('bookings.price_for_hour')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.hours_cost"
                    :value="booking.hours_cost"
                    :label="$t('bookings.total_for_hours')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.delivery"
                    :value="booking.delivery"
                    :label="$t('bookings.delivery')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.delivery_end"
                    :value="booking.delivery_end"
                    :label="$t('bookings.checkout')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.equipment"
                    :value="booking.equipment"
                    :label="$t('bookings.add_equipment')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.insurance"
                    :value="booking.insurance"
                    :label="$t('bookings.add_insurance')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.add_drivers_cost"
                    :value="booking.add_drivers_cost"
                    :label="$t('bookings.add_drivers_short')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.fine"
                    :value="booking.fine"
                    :label="$t('bookings.fines')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.clean_payment"
                    :value="booking.clean_payment"
                    :label="$t('bookings.clean')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.other"
                    :value="booking.other"
                    :label="$t('bookings.other')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    v-model.number="booking.mileage_cost"
                    :value="booking.mileage_cost"
                    :label="$t('bookings.mileage_cost')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap v-if="manualEditing" class="align-center">
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    v-model="booking.last_name"
                    :label="$t('bookings.client_lastname')"
                    :style="
                      user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                    "
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    v-model="booking.first_name"
                    :label="$t('bookings.client_name')"
                    :style="
                      user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                    "
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field
                    v-model="booking.middle_name"
                    :label="$t('bookings.client_middlename')"
                    :style="
                      user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                    "
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout
                row
                wrap
                v-if="manualEditing && checkAdmin()"
                class="align-center"
              >
                <v-flex xs12 sm3 md3>
                  <v-checkbox
                    v-model="booking.clean_start"
                    :disabled="booking.archive"
                    :label="$t('bookings.clean_start')"
                    class="ml-3 mt-0 pt-0"
                    hide-details
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-checkbox
                    v-model="booking.clean_end"
                    :disabled="booking.archive"
                    :label="$t('bookings.clean_end')"
                    class="ml-3 mt-0 pt-0"
                    hide-details
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-checkbox
                    v-model="booking.gas_start_full"
                    class="mt-0 mr-1"
                    :label="$t('bookings.gas_start_short')"
                    dense
                    hide-details
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="booking.gas_start"
                    :label="$t('bookings.gas_start_short')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-checkbox
                    v-model="booking.gas_end_full"
                    class="mt-0 mr-1"
                    :label="$t('bookings.gas_end_short')"
                    dense
                    hide-details
                  ></v-checkbox>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="booking.gas_end"
                    :label="$t('bookings.gas_end_short')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.start_mileage"
                    :label="$t('bookings.mileage_start')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.end_mileage"
                    :label="$t('bookings.mileage_end')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.booking_mileage"
                    :label="$t('bookings.booking_mileage')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.number="booking.start_price"
                    :label="$t('bookings.created_price')"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12>
                  <v-flex xs12>
                    <v-textarea
                      v-model="booking.description"
                      v-if="showDescription"
                      clearable
                      auto-grow
                      rows="1"
                      :label="$t('bookings.add_info_big')"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="booking.custom_field_1"
                      v-if="showDescription"
                      clearable
                      auto-grow
                      rows="1"
                      :label="$t('bookings.add_field_1')"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="booking.custom_field_2"
                      v-if="showDescription"
                      clearable
                      auto-grow
                      rows="1"
                      :label="$t('bookings.add_field_2')"
                    ></v-textarea>
                  </v-flex>
                  <v-flex xs12>
                    <v-textarea
                      v-model="booking.custom_field_3"
                      v-if="showDescription"
                      clearable
                      auto-grow
                      rows="1"
                      :label="$t('bookings.add_field_3')"
                    ></v-textarea>
                  </v-flex>
                </v-flex>
                <v-flex xs12 sm12 md12 v-if="showAddDrivers">
                  <h4 class="mt-2">{{ $t("bookings.add_driver_short") }}</h4>
                  <div class="d-flex">
                    <v-autocomplete
                      v-model="booking.add_drivers"
                      multiple
                      clearable
                      item-text="fullname"
                      item-value="id"
                      :loading="isLoadingClientsAddDriver"
                      :search-input.sync="searchClient"
                      text
                      hide-no-data
                      hide-details
                      :label="$t('bookings.select_client')"
                      :items="clients"
                      prepend-icon="mdi-account-box"
                      :style="
                        user_role == 'guest' || user_role == 'partner'
                          ? 'display: none;'
                          : ''
                      "
                    >
                    </v-autocomplete>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Убрал а потом обратно добавил (set_car = []), -->
          <!-- Опять убрал (set_car = []), но добавил getCar(), -->
          <v-btn
            text
            @click="
              getBooking(),
              getCar(),
                (editDialog = false),
                (manualEditing = false),
                (warningAddOneDay = false),
                (warningLessThenDay = false),
                (showEditClient = false),
                (editCar = false),
                (editDate = false),
                (start_date = null),
                (selected_tariff = null),
                (tariff = null),
                (editTariff = false),
                (end_date = null),
                (editExtra = false),
                (manualEditing = false)
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="success" @click="editSave()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Продление -->
    <v-dialog
      v-model="prolongDialog"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="1000px"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("bookings.prolong_short") }}</span>
          <v-icon class="ml-2 green--text" @click="saveProlong">
            mdi-content-save-all-outline
          </v-icon>
          <v-icon
            v-if="user_role != 'guest' && user_role != 'partner'"
            color="warning"
            @click="generateProlongDoc(booking)"
            v-tooltip="{
              content: $t('bookings.generate_docs'),
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500,
              },
            }"
          >
            mdi-file-document-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (prolongDialog = false),
                (showEntityPayment = false),
                (showCashCardPayment = false),
                (showCashlessPayment = false),
                (showCashPayment = false)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex md6>
                  <div>
                    {{ $t("bookings.booking") }} {{ $t("custom.number") }}:
                    {{ booking.id }}
                  </div>
                  <div>
                    {{ $t("bookings.client") }}:
                    <router-link :to="{ path: `/clients/${booking.client_id}` }"
                      >{{ booking.last_name }} {{ booking.first_name }}
                      {{ booking.middle_name }}</router-link
                    >
                  </div>
                  <div>
                    {{ $t("bookings.booking_end") }}: {{ booking.end_date }}
                  </div>
                  <div>
                    {{ $t("bookings.current_price") }}:
                    {{ booking.selected_price }}{{ currency }}
                  </div>
                  <div>
                    <p>{{ $t("bookings.counts") }}:</p>
                    <ul v-for="count in booking.counts" :key="count.index">
                      <li>
                        <span v-if="count.cash"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }} {{ $t("bookings.cash_small") }},
                        </span>
                        <span v-if="count.cashless"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }} {{ $t("bookings.terminal_small") }},
                        </span>
                        <span v-if="count.cash_card"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }}
                          {{ $t("bookings.card_to_card_small") }},
                        </span>
                        <span v-if="count.entity"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }} на р/с,
                        </span>
                        <span>{{ translateCountGroup(count.group) }},</span>
                        {{ formatCreatedAt(count.created_at) }}
                      </li>
                    </ul>
                  </div>
                </v-flex>
                <v-flex md6>
                  <v-text-field
                    v-model.number="prolongData.days"
                    :label="$t('prolongs.for_days')"
                    type="number"
                  ></v-text-field>
                  <v-text-field
                    v-model.number="prolongData.price"
                    :label="$t('prolongs.for_price')"
                    type="number"
                  ></v-text-field>
                  <!-- <v-text-field v-model.number="prolongData.additional_hours" label="Дополнительные часы?"></v-text-field> -->
                </v-flex>
                <v-flex md6 class="align-center justify-space-around">
                  <h3>
                    {{ $t("prolongs.total_rent") }}:
                    {{ Math.round(prolongData.days * prolongData.price) }}{{ currency
                    }}<span v-if="prolongData.additional_hours">
                      + {{ $t("prolongs.add_hours") }}:
                      {{ Math.round(prolongData.additional_hours * booking.price_hour)
                      }}{{ currency }}</span
                    >
                  </h3>
                </v-flex>
                <v-flex md6>
                  <!-- Аренда -->
                  <v-flex md12 class="mt-3">
                    <v-text-field
                      v-model.number="prolongData.sum"
                      type="number"
                      :label="$t('prolongs.get_for_rent')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12 class="d-inline-flex payment">
                    <v-checkbox
                      v-model="prolongData.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        prolongData.type == 'Терминал' ||
                        prolongData.type == 'На карту' ||
                        prolongData.type == 'Р/с' ||
                        prolongData.sum == null ||
                        prolongData.sum == '' ||
                        prolongData.sum == ' ' ||
                        prolongData.sum == 0
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="prolongData.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        prolongData.type == 'Наличные' ||
                        prolongData.type == 'На карту' ||
                        prolongData.type == 'Р/с' ||
                        prolongData.sum == null ||
                        prolongData.sum == '' ||
                        prolongData.sum == ' ' ||
                        prolongData.sum == 0
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="prolongData.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        prolongData.type == 'Наличные' ||
                        prolongData.type == 'Терминал' ||
                        prolongData.type == 'Р/с' ||
                        prolongData.sum == null ||
                        prolongData.sum == '' ||
                        prolongData.sum == ' ' ||
                        prolongData.sum == 0
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="prolongData.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        prolongData.type == 'Наличные' ||
                        prolongData.type == 'Терминал' ||
                        prolongData.type == 'На карту' ||
                        prolongData.sum == null ||
                        prolongData.sum == '' ||
                        prolongData.sum == ' ' ||
                        prolongData.sum == 0
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveProlong()">{{
            $t("custom.save")
          }}</v-btn>
          <v-btn
            text
            @click="
              (prolongDialog = false),
                (showEntityPayment = false),
                (showCashCardPayment = false),
                (showCashlessPayment = false),
                (showCashPayment = false)
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Приём -->
    <v-dialog
      v-model="arrivalDialog"
      :retain-focus="false"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :class="isMobile ? 'm-0 p-0' : ''"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("bookings.checkout_big") }}</span>
          <v-icon class="ml-2 green--text" @click="inHome()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="closeArrivalDialog()">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-layout row wrap>
              <v-flex md12 v-if="fines.length > 0">
                <h2>{{ $t("custom.fines") }}</h2>
                <v-data-table
                  :headers="fines_headers_small"
                  :loading="isLoading"
                  :loading-text="$t('custom.loading_table')"
                  :items="fines"
                  :search="search_fines"
                  dense
                  hide-default-footer
                  :sort-by="['created_at']"
                  :sort-desc="[true]"
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td
                        :class="
                          'text-center ' + stateFineColor(props.item.state)
                        "
                      >
                        {{ props.item.number }}
                      </td>
                      <td class="text-center" v-if="props.item.date">
                        {{ returnDate(props.item.date) }}
                      </td>
                      <td class="text-center" v-else>
                        {{ formatCreatedAtWithYear(props.item.violation_date) }}
                      </td>
                      <td class="text-center">
                        <router-link
                          v-if="!booking.entity"
                          :to="{ path: `/clients/${props.item.client_id}` }"
                          >{{ props.item.client_id }}</router-link
                        >
                        <router-link
                          v-else
                          :to="{ path: `/clients/${props.item.client_id}` }"
                          >{{ props.item.client_id }}</router-link
                        >
                      </td>
                      <td class="text-center">
                        {{ props.item.sum }}{{ currency }}
                      </td>
                      <td class="text-center description-trunc">
                        <read-more
                          v-if="
                            props.item.description &&
                            props.item.description.length > 0
                          "
                          :more-str="$t('custom.read')"
                          :text="props.item.description"
                          link="#"
                          :less-str="$t('custom.less')"
                          :max-chars="20"
                        ></read-more>
                      </td>
                      <!-- Состояние -->
                      <td class="text-center">
                        <v-menu absolute offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              :color="stateFineColor(props.item.state)"
                              small
                              block
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ props.item.state }}
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item
                              link
                              v-for="(state, index) in fines_state"
                              :key="index"
                              @click="updateState(props.item.id, state)"
                            >
                              <v-list-item-title>
                                {{ state }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider class="mt-3 mb-3"></v-divider>
              </v-flex>
              <v-flex xs12 md4>
                <v-flex md12>
                  <vc-date-picker
                    v-model="booking.end_date"
                    mode="dateTime"
                    :model-config="calendarConfig"
                    :attributes="calendar_attributes"
                    is24hr
                    title-position="left"
                    class="flex d-flex md12 p-0"
                  >
                    <template
                      v-slot="{
                        inputValue,
                        inputEvents,
                        isDragging,
                        showPopover,
                      }"
                    >
                      <v-flex md12 :class="isMobile ? '' : 'd-flex'">
                        <v-text-field
                          @click="showPopover"
                          :class="
                            isDragging
                              ? 'text-gray-600 mr-2 p-0'
                              : 'text-gray-900 mr-2 p-0'
                          "
                          :value="inputValue"
                          v-on="inputEvents"
                          :label="$t('bookings.end_date_big')"
                          prepend-icon="mdi-calendar-clock"
                          readonly
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </template>
                  </vc-date-picker>
                </v-flex>
                <v-flex md12 class="d-inline-flex">
                  <v-text-field
                    disabled
                    :label="$t('bookings.prev_mileage')"
                    :value="booking.start_mileage"
                    class="mr-3"
                  ></v-text-field>
                  <v-text-field
                    v-model.number="booking.end_mileage"
                    @input="checkMileageLimit(), calcExtraMileagePrice()"
                    :label="$t('bookings.new_mileage')"
                  ></v-text-field>
                </v-flex>
                <v-flex md12 v-if="set_car">
                  <v-text-field
                    :label="$t('home.car_location')"
                    v-model="set_car.store_place"
                    class="mx-auto"
                  ></v-text-field>
                </v-flex>
                <v-flex
                  md12
                  style="font-size: 16px"
                  v-if="
                    $store.getters.getCompany &&
                    $store.getters.getCompany.company &&
                    ($store.getters.getCompany.company.mileage_limit > 0 ||
                      (set_car && set_car.extra_mileage_km > 0))
                  "
                >
                  <p
                    v-if="booking.end_mileage - booking.start_mileage > 0"
                    :class="limitExept ? 'red--text' : ''"
                  >
                    {{ $t("bookings.mileage") }}:
                    {{
                      Math.floor(
                        (booking.end_mileage - booking.start_mileage) /
                          (booking.days > 0 ? booking.days : 1)
                      )
                    }}
                    {{ $t("bookings.km_day") }}
                    <span v-if="limitExept"
                      >, {{ $t("bookings.extra_mileage") }}:
                      {{ returnMileageExt() }} км</span
                    >
                  </p>
                  <p v-if="limitCost > 0">
                    {{ $t("bookings.extra_mileage_cost") }}:
                    <span class="font-weight-bold"
                      >{{ limitCost }}{{ currency }}</span
                    >
                  </p>
                  <p v-if="limitCost > 0">
                    {{ $t("cars.extra_mileage_km_info") }}:
                    <span class="font-weight-bold"
                      >{{ Math.floor(limitCost / returnMileageExt())
                      }}{{ currency }}</span
                    >
                  </p>
                </v-flex>
                <v-flex md12 class="d-inline-flex">
                  <v-checkbox
                    v-model="booking.clean_end"
                    :label="$t('bookings.clean_car')"
                    @change="calcClean()"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="booking.gas_end_full"
                    @click="calcGas()"
                    :label="$t('bookings.full_tank')"
                  ></v-checkbox>
                </v-flex>
                <v-flex v-if="showCleanPaid" md12>
                  <p class="font-weight-bold">
                    {{ $t("bookings.clean_paid") }}: {{ booking.clean_payment
                    }}{{ currency }}
                  </p>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-if="!booking.gas_end_full"
                    v-model="booking.gas_end"
                    type="text"
                    :label="$t('bookings.lost_gas')"
                  ></v-text-field>
                </v-flex>
              </v-flex>
              <div xs12 md1 class="vl" style="margin-left: 3%"></div>
              <v-flex xs12 md7 class="mt-auto mb-auto">
                <v-flex v-if="!isMobile" md12 class="d-flex align-center">
                  <v-flex md12>
                    <h3 style="font-weight: 800" v-if="returnDeposit() >= 0">
                      {{ $t("bookings.give") }}: {{ returnDeposit()
                      }}{{ currency }}
                    </h3>
                    <h3 style="font-weight: 800" v-else>
                      {{ $t("bookings.for_pay") }}: {{ returnDeposit() * -1
                      }}{{ currency }}
                    </h3>
                  </v-flex>
                  <v-flex md12 class="d-flex align-center">
                    <v-switch
                      v-model="giveNullDeposit"
                      :label="$t('bookings.not_give')"
                      color="error"
                      class="ml-2 mt-0 pt-0"
                      @change="giveFullDeposit = !giveNullDeposit"
                      hide-details
                    ></v-switch>
                  </v-flex>
                </v-flex>
                <v-flex v-else md12>
                  <v-flex md12 class="d-flex align-center">
                    <p style="font-weight: 800" v-if="returnDeposit() >= 0">
                      {{ $t("bookings.give") }}: {{ returnDeposit()
                      }}{{ currency }}
                    </p>
                    <p style="font-weight: 800" v-else>
                      {{ $t("bookings.for_pay") }}: {{ returnDeposit() * -1
                      }}{{ currency }}
                    </p>
                    <v-switch
                      v-model="giveNullDeposit"
                      :label="$t('bookings.not_give')"
                      color="error"
                      class="ml-2 mt-0 pt-0"
                      @change="giveFullDeposit = !giveNullDeposit"
                      hide-details
                      dense
                    ></v-switch>
                  </v-flex>
                </v-flex>
                <!-- Залог -->
                <v-flex md12>
                  <v-flex xs12 class="d-inline-flex payment">
                    <v-checkbox
                      v-model="arrival_payments.deposit.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        giveNullDeposit ||
                        arrival_payments.deposit.type == 'Терминал' ||
                        arrival_payments.deposit.type == 'На карту' ||
                        arrival_payments.deposit.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.deposit.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        giveNullDeposit ||
                        arrival_payments.deposit.type == 'Наличные' ||
                        arrival_payments.deposit.type == 'На карту' ||
                        arrival_payments.deposit.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <br v-if="isMobile" />
                    <v-checkbox
                      v-model="arrival_payments.deposit.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        giveNullDeposit ||
                        arrival_payments.deposit.type == 'Наличные' ||
                        arrival_payments.deposit.type == 'Терминал' ||
                        arrival_payments.deposit.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.deposit.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        giveNullDeposit ||
                        arrival_payments.deposit.type == 'Наличные' ||
                        arrival_payments.deposit.type == 'Терминал' ||
                        arrival_payments.deposit.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                </v-flex>
                <v-divider class="mt-3 mb-2"></v-divider>
                <h3 style="font-weight: 800">
                  {{ $t("bookings.extra_payments") }}
                </h3>
                <v-select
                  v-model="selected_add_services"
                  :items="add_services"
                  item-text="text"
                  item-value="value"
                  clearable
                  :label="$t('bookings.select_add_services')"
                  multiple
                ></v-select>
                <v-flex md12>
                  <!-- Мойка -->
                  <v-flex v-if="checkAddPayment('Мойка')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.clean.sum"
                      :label="$t('custom.clean')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Мойка')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.clean.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.clean.type == 'Терминал' ||
                        arrival_payments.clean.type == 'На карту' ||
                        arrival_payments.clean.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.clean.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.clean.type == 'Наличные' ||
                        arrival_payments.clean.type == 'На карту' ||
                        arrival_payments.clean.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.clean.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.clean.type == 'Наличные' ||
                        arrival_payments.clean.type == 'Терминал' ||
                        arrival_payments.clean.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.clean.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.clean.type == 'Наличные' ||
                        arrival_payments.clean.type == 'Терминал' ||
                        arrival_payments.clean.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Дополнительное время -->
                  <v-flex
                    v-if="checkAddPayment('Дополнительное время')"
                    xs12
                    class="mt-3"
                  >
                    <v-text-field
                      v-model.number="arrival_payments.additional_hours.sum"
                      :label="$t('bookings.extra_time')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Дополнительное время')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.additional_hours.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.additional_hours.type == 'Терминал' ||
                        arrival_payments.additional_hours.type == 'На карту' ||
                        arrival_payments.additional_hours.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.additional_hours.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.additional_hours.type == 'Наличные' ||
                        arrival_payments.additional_hours.type == 'На карту' ||
                        arrival_payments.additional_hours.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.additional_hours.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.additional_hours.type == 'Наличные' ||
                        arrival_payments.additional_hours.type == 'Терминал' ||
                        arrival_payments.additional_hours.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.additional_hours.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.additional_hours.type == 'Наличные' ||
                        arrival_payments.additional_hours.type == 'Терминал' ||
                        arrival_payments.additional_hours.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Превышение пробега -->
                  <v-flex
                    v-if="
                      checkAddPayment('Превышение пробега') &&
                      $store.getters.getCompany &&
                      $store.getters.getCompany.company &&
                      $store.getters.getCompany.company.mileage_limit_cost > 0
                    "
                    xs12
                    class="mt-3"
                  >
                    <v-text-field
                      v-model.number="arrival_payments.mileage.sum"
                      :label="$t('bookings.extra_mileage_big')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="
                      checkAddPayment('Превышение пробега') &&
                      $store.getters.getCompany &&
                      $store.getters.getCompany.company &&
                      $store.getters.getCompany.company.mileage_limit_cost > 0
                    "
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.mileage.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.mileage.type == 'Терминал' ||
                        arrival_payments.mileage.type == 'На карту' ||
                        arrival_payments.mileage.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.mileage.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.mileage.type == 'Наличные' ||
                        arrival_payments.mileage.type == 'На карту' ||
                        arrival_payments.mileage.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.mileage.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.mileage.type == 'Наличные' ||
                        arrival_payments.mileage.type == 'Терминал' ||
                        arrival_payments.mileage.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.mileage.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.mileage.type == 'Наличные' ||
                        arrival_payments.mileage.type == 'Терминал' ||
                        arrival_payments.mileage.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Топливо -->
                  <v-flex v-if="checkAddPayment('Топливо')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.gas.sum"
                      :label="$t('bookings.gas')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Топливо')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.gas.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.gas.type == 'Терминал' ||
                        arrival_payments.gas.type == 'На карту' ||
                        arrival_payments.gas.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.gas.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.gas.type == 'Наличные' ||
                        arrival_payments.gas.type == 'На карту' ||
                        arrival_payments.gas.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.gas.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.gas.type == 'Наличные' ||
                        arrival_payments.gas.type == 'Терминал' ||
                        arrival_payments.gas.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.gas.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.gas.type == 'Наличные' ||
                        arrival_payments.gas.type == 'Терминал' ||
                        arrival_payments.gas.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Доставка -->
                  <v-flex v-if="checkAddPayment('Доставка')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.delivery.sum"
                      :label="$t('bookings.delivery')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Доставка')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.delivery.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.delivery.type == 'Терминал' ||
                        arrival_payments.delivery.type == 'На карту' ||
                        arrival_payments.delivery.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.delivery.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.delivery.type == 'Наличные' ||
                        arrival_payments.delivery.type == 'На карту' ||
                        arrival_payments.delivery.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.delivery.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.delivery.type == 'Наличные' ||
                        arrival_payments.delivery.type == 'Терминал' ||
                        arrival_payments.delivery.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.delivery.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.delivery.type == 'Наличные' ||
                        arrival_payments.delivery.type == 'Терминал' ||
                        arrival_payments.delivery.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Приём -->
                  <v-flex v-if="checkAddPayment('Приём')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.delivery_end.sum"
                      :label="$t('bookings.checkout')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Приём')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.delivery_end.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.delivery_end.type == 'Терминал' ||
                        arrival_payments.delivery_end.type == 'На карту' ||
                        arrival_payments.delivery_end.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.delivery_end.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.delivery_end.type == 'Наличные' ||
                        arrival_payments.delivery_end.type == 'На карту' ||
                        arrival_payments.delivery_end.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.delivery_end.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.delivery_end.type == 'Наличные' ||
                        arrival_payments.delivery_end.type == 'Терминал' ||
                        arrival_payments.delivery_end.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.delivery_end.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.delivery_end.type == 'Наличные' ||
                        arrival_payments.delivery_end.type == 'Терминал' ||
                        arrival_payments.delivery_end.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Аренда -->
                  <v-flex v-if="checkAddPayment('Аренда')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.rent.sum"
                      :label="$t('bookings.rent')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Аренда')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.rent.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.rent.type == 'Терминал' ||
                        arrival_payments.rent.type == 'На карту' ||
                        arrival_payments.rent.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.rent.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.rent.type == 'Наличные' ||
                        arrival_payments.rent.type == 'На карту' ||
                        arrival_payments.rent.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.rent.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.rent.type == 'Наличные' ||
                        arrival_payments.rent.type == 'Терминал' ||
                        arrival_payments.rent.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.rent.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.rent.type == 'Наличные' ||
                        arrival_payments.rent.type == 'Терминал' ||
                        arrival_payments.rent.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Страховка -->
                  <v-flex v-if="checkAddPayment('Страховка')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.insurance.sum"
                      :label="$t('bookings.insurance')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Страховка')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.insurance.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.insurance.type == 'Терминал' ||
                        arrival_payments.insurance.type == 'На карту' ||
                        arrival_payments.insurance.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.insurance.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.insurance.type == 'Наличные' ||
                        arrival_payments.insurance.type == 'На карту' ||
                        arrival_payments.insurance.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.insurance.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.insurance.type == 'Наличные' ||
                        arrival_payments.insurance.type == 'Терминал' ||
                        arrival_payments.insurance.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.insurance.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.insurance.type == 'Наличные' ||
                        arrival_payments.insurance.type == 'Терминал' ||
                        arrival_payments.insurance.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Доп водители -->
                  <v-flex
                    v-if="checkAddPayment('Доп водители')"
                    xs12
                    class="mt-3"
                  >
                    <v-text-field
                      v-model.number="arrival_payments.add_drivers_cost.sum"
                      :label="$t('bookings.add_drivers_small')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Доп водители')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.add_drivers_cost.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.add_drivers_cost.type == 'Терминал' ||
                        arrival_payments.add_drivers_cost.type == 'На карту' ||
                        arrival_payments.add_drivers_cost.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.add_drivers_cost.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.add_drivers_cost.type == 'Наличные' ||
                        arrival_payments.add_drivers_cost.type == 'На карту' ||
                        arrival_payments.add_drivers_cost.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.add_drivers_cost.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.add_drivers_cost.type == 'Наличные' ||
                        arrival_payments.add_drivers_cost.type == 'Терминал' ||
                        arrival_payments.add_drivers_cost.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.add_drivers_cost.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.add_drivers_cost.type == 'Наличные' ||
                        arrival_payments.add_drivers_cost.type == 'Терминал' ||
                        arrival_payments.add_drivers_cost.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Оборудование -->
                  <v-flex
                    v-if="checkAddPayment('Оборудование')"
                    xs12
                    class="mt-3"
                  >
                    <v-text-field
                      v-model.number="arrival_payments.equipment.sum"
                      :label="$t('bookings.equipment')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Оборудование')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.equipment.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.equipment.type == 'Терминал' ||
                        arrival_payments.equipment.type == 'На карту' ||
                        arrival_payments.equipment.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.equipment.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.equipment.type == 'Наличные' ||
                        arrival_payments.equipment.type == 'На карту' ||
                        arrival_payments.equipment.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.equipment.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.equipment.type == 'Наличные' ||
                        arrival_payments.equipment.type == 'Терминал' ||
                        arrival_payments.equipment.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.equipment.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.equipment.type == 'Наличные' ||
                        arrival_payments.equipment.type == 'Терминал' ||
                        arrival_payments.equipment.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Штрафы -->
                  <v-flex v-if="checkAddPayment('Штрафы')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.fine.sum"
                      :label="$t('bookings.fines')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Штрафы')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.fine.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.fine.type == 'Терминал' ||
                        arrival_payments.fine.type == 'На карту' ||
                        arrival_payments.fine.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.fine.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.fine.type == 'Наличные' ||
                        arrival_payments.fine.type == 'На карту' ||
                        arrival_payments.fine.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.fine.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.fine.type == 'Наличные' ||
                        arrival_payments.fine.type == 'Терминал' ||
                        arrival_payments.fine.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.fine.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.fine.type == 'Наличные' ||
                        arrival_payments.fine.type == 'Терминал' ||
                        arrival_payments.fine.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Повреждения -->
                  <v-flex
                    v-if="checkAddPayment('Повреждения')"
                    xs12
                    class="mt-3"
                  >
                    <v-text-field
                      v-model.number="arrival_payments.damage.sum"
                      :label="$t('bookings.damages')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Повреждения')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.damage.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.damage.type == 'Терминал' ||
                        arrival_payments.damage.type == 'На карту' ||
                        arrival_payments.damage.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.damage.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.damage.type == 'Наличные' ||
                        arrival_payments.damage.type == 'На карту' ||
                        arrival_payments.damage.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.damage.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.damage.type == 'Наличные' ||
                        arrival_payments.damage.type == 'Терминал' ||
                        arrival_payments.damage.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.damage.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.damage.type == 'Наличные' ||
                        arrival_payments.damage.type == 'Терминал' ||
                        arrival_payments.damage.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                  <!-- Другое -->
                  <v-flex v-if="checkAddPayment('Другое')" xs12 class="mt-3">
                    <v-text-field
                      v-model.number="arrival_payments.other.sum"
                      :label="$t('bookings.other')"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="checkAddPayment('Другое')"
                    xs12
                    class="d-inline-flex payment"
                  >
                    <v-checkbox
                      v-model="arrival_payments.other.type"
                      style="font-size: 12px !important"
                      class="mt-0 mr-1"
                      :label="$t('money.cash')"
                      value="Наличные"
                      :disabled="
                        arrival_payments.other.type == 'Терминал' ||
                        arrival_payments.other.type == 'На карту' ||
                        arrival_payments.other.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.other.type"
                      class="mt-0 mr-1"
                      :label="$t('money.terminal_short')"
                      value="Терминал"
                      :disabled="
                        arrival_payments.other.type == 'Наличные' ||
                        arrival_payments.other.type == 'На карту' ||
                        arrival_payments.other.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.other.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_card')"
                      value="На карту"
                      :disabled="
                        arrival_payments.other.type == 'Наличные' ||
                        arrival_payments.other.type == 'Терминал' ||
                        arrival_payments.other.type == 'Р/с'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="arrival_payments.other.type"
                      class="mt-0 mr-1"
                      :label="$t('money.to_entity_short')"
                      value="Р/с"
                      :disabled="
                        arrival_payments.other.type == 'Наличные' ||
                        arrival_payments.other.type == 'Терминал' ||
                        arrival_payments.other.type == 'На карту'
                      "
                      dense
                      hide-details
                    ></v-checkbox>
                  </v-flex>
                </v-flex>
              </v-flex>
              <v-flex md12 :class="isMobile ? '' : 'd-flex d-inline-flex'">
                <v-switch
                  v-model="showProblems"
                  :label="$t('bookings.problems')"
                  hide-details
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="partReturnDeposit"
                  :disabled="giveNullDeposit"
                  :label="$t('bookings.part_deposit')"
                  hide-details
                ></v-switch>
                <v-spacer></v-spacer>
                <v-switch
                  v-model="dont_send_review_reminder"
                  :label="$t('bookings.dont_send_review')"
                  hide-details
                ></v-switch>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="showProblems">
              <v-flex md6>
                <v-textarea
                  v-model="client.problems_description"
                  clearable
                  auto-grow
                  rows="1"
                  :label="$t('bookings.problems_description')"
                ></v-textarea>
              </v-flex>
              <v-flex md6>
                <v-select
                  v-model="client.category"
                  :items="customer_category"
                  item-text="text"
                  item-value="value"
                  :label="$t('bookings.client_category')"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="partReturnDeposit">
              <v-flex md12>
                <v-text-field
                  v-model.number="arrival_payments.deposit.sum"
                  :label="$t('bookings.return_deposit_desc')"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn color="success" @click="inHome()">{{
            $t("bookings.checkout_car")
          }}</v-btn>
          <v-btn color="darken-1" text @click="closeArrivalDialog()">{{
            $t("custom.close")
          }}</v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Оплата -->
    <v-dialog
      v-model="payment_dialog"
      :retain-focus="false"
      fullscreen
      persistent
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("bookings.payment") }}</span>
          <v-icon class="ml-2 green--text" @click="savePayment(booking)">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="closePaymentDialog()">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex
                  xs12
                  md3
                  :class="isMobile ? 'd-flex justify-center align-top' : ''"
                >
                  <v-flex>
                    <h3 class="mb-0">{{ $t("custom.info") }}</h3>
                    <div>
                      {{ $t("bookings.booking") }} {{ $t("custom.number") }}:
                      {{ booking.id }}
                    </div>
                    <!-- <div>
                        Клиент:
                        <router-link
                          :to="{ path: `/clients/${booking.client_id}` }"
                          >{{ booking.last_name }} {{ booking.first_name }}
                          {{ booking.middle_name }}</router-link
                        >
                      </div> -->
                    <div>{{ $t("bookings.days") }}: {{ booking.days }}</div>
                    <div>
                      {{ $t("bookings.current_price") }}:
                      {{ booking.selected_price }}{{ currency }}
                    </div>
                    <div v-if="booking.sale > 0">
                      {{ $t("bookings.sale_percent") }}: {{ booking.sale }}%
                    </div>
                    <div v-if="booking.sale_cash > 0">
                      {{ $t("bookings.edit_sale_rub", { msg: currency }) }}:
                      {{ booking.sale_cash }}{{ currency }}
                    </div>
                    <div v-if="booking.additional_hours > 0">
                      <b
                        >{{ $t("bookings.add_hours_short") }}:
                        {{ booking.additional_hours }}</b
                      >
                    </div>
                  </v-flex>
                  <v-flex>
                    <h3>{{ $t("bookings.cost") }}</h3>
                    <div v-if="booking.aggr_commission == 0">
                      <b
                        >{{ $t("bookings.rent") }}: {{ booking.rental_cost
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-else>
                      <b>
                        {{ $t("bookings.rent") }}:
                        {{ booking.rental_cost - booking.aggr_commission}}{{ currency }}
                      </b>
                    </div>
                    <div v-if="booking.equipment > 0">
                      <b
                        >{{ $t("bookings.equipment") }}: {{ booking.equipment
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.delivery > 0">
                      <b
                        >{{ $t("bookings.delivery") }}: {{ booking.delivery
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.delivery_end > 0">
                      <b
                        >{{ $t("bookings.checkout") }}: {{ booking.delivery_end
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.clean_payment > 0">
                      <b
                        >{{ $t("bookings.clean") }}: {{ booking.clean_payment
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.hours_cost > 0">
                      <b
                        >{{ $t("bookings.add_hours_short") }}:
                        {{ booking.hours_cost }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.hours_cost_end > 0">
                      <b
                        >{{ $t("bookings.add_hours_checkout") }}:
                        {{ booking.hours_cost_end }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.gas > 0">
                      <b
                        >{{ $t("bookings.gas") }}: {{ booking.gas
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.insurance > 0">
                      <b
                        >{{ $t("bookings.insurance") }}: {{ booking.insurance
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.add_drivers_cost > 0">
                      <b
                        >{{ $t("bookings.add_drivers_small") }}:
                        {{ booking.add_drivers_cost }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.other > 0">
                      <b
                        >{{ $t("bookings.other") }}: {{ booking.other
                        }}{{ currency }}</b
                      >
                    </div>
                    <div v-if="booking.other_end > 0">
                      <b
                        >{{ $t("bookings.other_checkout") }}:
                        {{ booking.other_end }}{{ currency }}</b
                      >
                    </div>
                    <div>
                      <b
                        >{{ $t("bookings.deposit") }}: {{ booking.deposit
                        }}{{ currency }}</b
                      >
                    </div>
                    <div>
                      <b
                        >{{ $t("custom.total") }}:
                        {{
                          (booking.rental_cost - booking.aggr_commission) +
                          booking.deposit +
                          booking.equipment +
                          booking.clean_payment +
                          booking.delivery +
                          booking.delivery_end +
                          booking.hours_cost +
                          booking.insurance +
                          booking.other
                        }}{{ currency }}</b
                      >
                    </div>
                    <div
                      :class="
                        (booking.rental_cost - booking.aggr_commission) +
                          booking.deposit +
                          booking.equipment +
                          booking.clean_payment +
                          booking.delivery +
                          booking.delivery_end +
                          booking.hours_cost +
                          booking.insurance +
                          booking.other ==
                        departure_payments.rent.sum +
                          departure_payments.deposit.sum +
                          departure_payments.equipment.sum +
                          departure_payments.clean.sum +
                          departure_payments.delivery.sum +
                          departure_payments.delivery_end.sum +
                          departure_payments.hours_cost.sum +
                          departure_payments.insurance.sum +
                          departure_payments.other.sum
                          ? 'green--text'
                          : ''
                      "
                    >
                      <b
                        >{{ $t("bookings.accepted") }}:
                        {{
                          departure_payments.rent.sum +
                          departure_payments.deposit.sum +
                          departure_payments.equipment.sum +
                          departure_payments.clean.sum +
                          departure_payments.delivery.sum +
                          departure_payments.delivery_end.sum +
                          departure_payments.hours_cost.sum +
                          departure_payments.insurance.sum +
                          departure_payments.other.sum
                        }}{{ currency }}
                      </b>
                    </div>
                  </v-flex>
                </v-flex>
                <div xs12 md1 class="vl" style="margin-left: 3%"></div>
                <v-flex xs12 md3>
                  <h3
                    :class="
                      isMobile && booking.counts.length == 0
                        ? 'mb-0 d-none'
                        : 'mb-0'
                    "
                  >
                    {{ $t("bookings.counts") }}
                  </h3>
                  <div v-if="showPayments(booking)">
                    <ul v-for="count in booking.counts" :key="count.index">
                      <li>
                        <span v-if="count.cash"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }} {{ $t("bookings.cash_small") }},
                        </span>
                        <span v-if="count.cashless"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }} {{ $t("bookings.terminal_small") }},
                        </span>
                        <span v-if="count.cash_card"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }}
                          {{ $t("bookings.card_to_card_small") }},
                        </span>
                        <span v-if="count.entity"
                          >{{ count.operation ? "" : "-" }}{{ count.sum
                          }}{{ currency }} {{ $t("bookings.to_entity_small") }},
                        </span>
                        <span>{{ translateCountGroup(count.group) }},</span>
                        {{ formatCreatedAt(count.created_at) }}
                      </li>
                    </ul>
                  </div>
                </v-flex>
                <div xs12 md1 class="vl" style="margin-left: 3%"></div>
                <v-flex xs12 md4>
                  <h3 class="mb-0">{{ $t("bookings.payment") }}</h3>
                  <v-flex md12>
                    <v-flex xs12>
                      <v-text-field
                        v-model.number="departure_payments.rent.sum"
                        :label="$t('bookings.rent')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 class="d-inline-flex payment">
                      <v-checkbox
                        v-model="departure_payments.rent.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.rent.type == 'Терминал' ||
                          departure_payments.rent.type == 'На карту' ||
                          departure_payments.rent.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.rent.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.rent.type == 'Наличные' ||
                          departure_payments.rent.type == 'На карту' ||
                          departure_payments.rent.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.rent.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.rent.type == 'Наличные' ||
                          departure_payments.rent.type == 'Терминал' ||
                          departure_payments.rent.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.rent.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.rent.type == 'Наличные' ||
                          departure_payments.rent.type == 'Терминал' ||
                          departure_payments.rent.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex v-if="booking.deposit > 0" xs12>
                      <v-text-field
                        v-model.number="departure_payments.deposit.sum"
                        :label="$t('bookings.deposit')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="booking.deposit > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.deposit.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.deposit.type == 'Терминал' ||
                          departure_payments.deposit.type == 'На карту' ||
                          departure_payments.deposit.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.deposit.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.deposit.type == 'Наличные' ||
                          departure_payments.deposit.type == 'На карту' ||
                          departure_payments.deposit.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.deposit.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.deposit.type == 'Наличные' ||
                          departure_payments.deposit.type == 'Терминал' ||
                          departure_payments.deposit.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.deposit.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.deposit.type == 'Наличные' ||
                          departure_payments.deposit.type == 'Терминал' ||
                          departure_payments.deposit.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.delivery > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.delivery.sum"
                        :label="$t('bookings.delivery')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.delivery > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.delivery.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.delivery.type == 'Терминал' ||
                          departure_payments.delivery.type == 'На карту' ||
                          departure_payments.delivery.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.delivery.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.delivery.type == 'Наличные' ||
                          departure_payments.delivery.type == 'На карту' ||
                          departure_payments.delivery.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.delivery.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.delivery.type == 'Наличные' ||
                          departure_payments.delivery.type == 'Терминал' ||
                          departure_payments.delivery.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.delivery.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.delivery.type == 'Наличные' ||
                          departure_payments.delivery.type == 'Терминал' ||
                          departure_payments.delivery.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.delivery_end > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.delivery_end.sum"
                        :label="$t('bookings.checkout')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.delivery_end > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.delivery_end.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.delivery_end.type == 'Терминал' ||
                          departure_payments.delivery_end.type == 'На карту' ||
                          departure_payments.delivery_end.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.delivery_end.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.delivery_end.type == 'Наличные' ||
                          departure_payments.delivery_end.type == 'На карту' ||
                          departure_payments.delivery_end.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.delivery_end.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.delivery_end.type == 'Наличные' ||
                          departure_payments.delivery_end.type == 'Терминал' ||
                          departure_payments.delivery_end.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.delivery_end.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.delivery_end.type == 'Наличные' ||
                          departure_payments.delivery_end.type == 'Терминал' ||
                          departure_payments.delivery_end.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="
                        showAddPayment ||
                        booking.start_clean_payment > 0 ||
                        booking.clean_payment > 0
                      "
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.clean.sum"
                        :label="$t('bookings.clean')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="
                        showAddPayment ||
                        booking.start_clean_payment > 0 ||
                        booking.clean_payment > 0
                      "
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.clean.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.clean.type == 'Терминал' ||
                          departure_payments.clean.type == 'На карту' ||
                          departure_payments.clean.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.clean.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.clean.type == 'Наличные' ||
                          departure_payments.clean.type == 'На карту' ||
                          departure_payments.clean.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.clean.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.clean.type == 'Наличные' ||
                          departure_payments.clean.type == 'Терминал' ||
                          departure_payments.clean.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.clean.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.clean.type == 'Наличные' ||
                          departure_payments.clean.type == 'Терминал' ||
                          departure_payments.clean.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.hours_cost > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.hours_cost.sum"
                        :label="$t('bookings.add_hours_short')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.hours_cost > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.hours_cost.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.hours_cost.type == 'Терминал' ||
                          departure_payments.hours_cost.type == 'На карту' ||
                          departure_payments.hours_cost.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.hours_cost.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.hours_cost.type == 'Наличные' ||
                          departure_payments.hours_cost.type == 'На карту' ||
                          departure_payments.hours_cost.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.hours_cost.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.hours_cost.type == 'Наличные' ||
                          departure_payments.hours_cost.type == 'Терминал' ||
                          departure_payments.hours_cost.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.hours_cost.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.hours_cost.type == 'Наличные' ||
                          departure_payments.hours_cost.type == 'Терминал' ||
                          departure_payments.hours_cost.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.insurance > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.insurance.sum"
                        :label="$t('bookings.insurance')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.insurance > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.insurance.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.insurance.type == 'Терминал' ||
                          departure_payments.insurance.type == 'На карту' ||
                          departure_payments.insurance.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.insurance.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.insurance.type == 'Наличные' ||
                          departure_payments.insurance.type == 'На карту' ||
                          departure_payments.insurance.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.insurance.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.insurance.type == 'Наличные' ||
                          departure_payments.insurance.type == 'Терминал' ||
                          departure_payments.insurance.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.insurance.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.insurance.type == 'Наличные' ||
                          departure_payments.insurance.type == 'Терминал' ||
                          departure_payments.insurance.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.equipment > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.equipment.sum"
                        :label="$t('bookings.equipment')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.equipment > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.equipment.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.equipment.type == 'Терминал' ||
                          departure_payments.equipment.type == 'На карту' ||
                          departure_payments.equipment.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.equipment.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.equipment.type == 'Наличные' ||
                          departure_payments.equipment.type == 'На карту' ||
                          departure_payments.equipment.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.equipment.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.equipment.type == 'Наличные' ||
                          departure_payments.equipment.type == 'Терминал' ||
                          departure_payments.equipment.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.equipment.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.equipment.type == 'Наличные' ||
                          departure_payments.equipment.type == 'Терминал' ||
                          departure_payments.equipment.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.fine > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.fine.sum"
                        :label="$t('bookings.fines')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.fine > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.fine.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.fine.type == 'Терминал' ||
                          departure_payments.fine.type == 'На карту' ||
                          departure_payments.fine.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.fine.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.fine.type == 'Наличные' ||
                          departure_payments.fine.type == 'На карту' ||
                          departure_payments.fine.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.fine.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.fine.type == 'Наличные' ||
                          departure_payments.fine.type == 'Терминал' ||
                          departure_payments.fine.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.fine.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.fine.type == 'Наличные' ||
                          departure_payments.fine.type == 'Терминал' ||
                          departure_payments.fine.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.add_drivers_cost > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.add_drivers_cost.sum"
                        :label="$t('bookings.add_drivers_small')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.add_drivers_cost > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.add_drivers_cost.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.add_drivers_cost.type ==
                            'Терминал' ||
                          departure_payments.add_drivers_cost.type ==
                            'На карту' ||
                          departure_payments.add_drivers_cost.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.add_drivers_cost.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.add_drivers_cost.type ==
                            'Наличные' ||
                          departure_payments.add_drivers_cost.type ==
                            'На карту' ||
                          departure_payments.add_drivers_cost.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.add_drivers_cost.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.add_drivers_cost.type ==
                            'Наличные' ||
                          departure_payments.add_drivers_cost.type ==
                            'Терминал' ||
                          departure_payments.add_drivers_cost.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.add_drivers_cost.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.add_drivers_cost.type ==
                            'Наличные' ||
                          departure_payments.add_drivers_cost.type ==
                            'Терминал' ||
                          departure_payments.add_drivers_cost.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.other > 0"
                      xs12
                      class="mt-3"
                    >
                      <v-text-field
                        v-model.number="departure_payments.other.sum"
                        :label="$t('bookings.other')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      v-if="showAddPayment || booking.other > 0"
                      xs12
                      class="d-inline-flex payment"
                    >
                      <v-checkbox
                        v-model="departure_payments.other.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          departure_payments.other.type == 'Терминал' ||
                          departure_payments.other.type == 'На карту' ||
                          departure_payments.other.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.other.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          departure_payments.other.type == 'Наличные' ||
                          departure_payments.other.type == 'На карту' ||
                          departure_payments.other.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.other.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          departure_payments.other.type == 'Наличные' ||
                          departure_payments.other.type == 'Терминал' ||
                          departure_payments.other.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="departure_payments.other.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          departure_payments.other.type == 'Наличные' ||
                          departure_payments.other.type == 'Терминал' ||
                          departure_payments.other.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                  </v-flex>
                  <v-flex md12 v-if="!showAddPayment" class="text-center">
                    <v-switch
                      v-model="showAddPayment"
                      :label="$t('bookings.add_services_short')"
                    ></v-switch>
                    <v-switch
                      v-if="booking.part_return_deposit"
                      v-model="partReturnDeposit"
                      :label="$t('bookings.give_full_deposit')"
                    ></v-switch>
                    <!-- <v-btn text @click="savePayment(booking)" color="success">{{ $t('custom.save') }}</v-btn> -->
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center" class="mb-5">
            <v-btn text class="mr-3" @click="closePaymentDialog()">{{
              $t("custom.close")
            }}</v-btn>
            <v-btn @click="savePayment(booking)" color="success">{{
              $t("custom.save")
            }}</v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDebtDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex xs12 md2>
                  <v-text-field
                    v-model.number="debt.sum"
                    :label="$t('custom.amount')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-combobox
                    v-model="debt.group"
                    :items="debts_groups"
                    item-text="text"
                    item-value="value"
                    :label="$t('custom.select_group')"
                  ></v-combobox>
                </v-flex>
                <v-flex xs12 md2>
                  <v-text-field
                    v-model.number="debt.booking_id"
                    :label="$t('bookings.number_booking')"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm3 md3>
                  <v-autocomplete
                    v-model="debt.client_id"
                    item-text="fullname"
                    item-value="id"
                    :loading="isLoadingClients"
                    :search-input.sync="search_client"
                    flat
                    hide-no-data
                    hide-details
                    :label="$t('bookings.select_client')"
                    :items="clients"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea
                    v-model="debt.description"
                    :label="$t('custom.description')"
                    :placeholder="$t('bookings.debts_desc')"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDebtDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="saveDebt()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="paymentDebtDialog"
      :retain-focus="false"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("bookings.debts_payment") }}</span>
          <v-icon class="ml-2 green--text" @click="saveDebtPayment()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (paymentDebtDialog = false),
                (paymentData = { sum: null, icon: null, state: null })
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-form>
              <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
                <v-layout row wrap>
                  <v-flex md12 class="text-center align-center">
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-text-field
                        v-model.number="paymentData.sum"
                        :label="$t('bookings.get_payment')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 class="d-inline-flex payment">
                      <v-checkbox
                        v-model="paymentData.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          paymentData.type == 'Терминал' ||
                          paymentData.type == 'На карту' ||
                          paymentData.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="paymentData.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          paymentData.type == 'Наличные' ||
                          paymentData.type == 'На карту' ||
                          paymentData.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="paymentData.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          paymentData.type == 'Наличные' ||
                          paymentData.type == 'Терминал' ||
                          paymentData.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="paymentData.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          paymentData.type == 'Наличные' ||
                          paymentData.type == 'Терминал' ||
                          paymentData.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-select
                        v-model="paymentData.state"
                        clearable
                        :items="debts_state"
                        item-text="text"
                        item-value="value"
                        :label="$t('bookings.select_state')"
                      ></v-select>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (paymentDebtDialog = false),
                (paymentData = { sum: null, icon: null, state: null })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveDebtPayment()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCustomEmailDialog"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="1000px"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{
            $t("email_templates.letter_for_client")
          }}</span>
          <!-- <v-icon
            class="ml-2 secondary--text"
            @click=""
            v-tooltip="{
              content: 'Шаблоны ответов',
              placement: 'bottom-center',
              classes: ['info'],
              targetClasses: ['it-has-a-tooltip'],
              delay: {
                show: 500,
                hide: 500
              }
            }"
          >
            mdi-book-open-variant
          </v-icon> -->
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (showCustomEmailDialog = false),
                (editEmailTemplate = false),
                (customEmailData = {
                  id: null,
                  body: null,
                  name: null,
                  subject: null,
                }),
                (selected_email_templates = null),
                (save_email_template = false),
                (showEmailTemplates = false)
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-container :style="isMobile ? 'padding: 5px !important;' : ''">
              <v-layout row wrap>
                <v-flex md12>
                  <v-flex md12 v-if="showEmailTemplates">
                    <h4 v-if="editEmailTemplate">
                      {{ $t("email_templates.edit_template") }}
                    </h4>
                    <h4 v-if="!editEmailTemplate">
                      {{ $t("email_templates.templates") }}
                    </h4>
                    <v-flex md12 class="d-flex">
                      <v-select
                        v-model="selected_email_templates"
                        :items="email_templates"
                        item-text="name"
                        item-value="id"
                        clearable
                        :label="$t('email_templates.select_template')"
                      ></v-select>
                      <v-icon
                        v-if="selected_email_templates"
                        class="ml-1 mr-1"
                        @click="editEmailTemplate = !editEmailTemplate"
                      >
                        mdi-pencil-outline
                      </v-icon>
                      <v-icon
                        v-if="selected_email_templates"
                        @click="deleteEmailTemplate(selected_email_templates)"
                      >
                        mdi-delete-forever
                      </v-icon>
                    </v-flex>
                  </v-flex>
                  <v-flex md12 v-if="editEmailTemplate">
                    <v-text-field
                      v-model="customEmailData.name"
                      value=""
                      :label="$t('email_templates.template_name')"
                      :placeholder="$t('email_templates.placeholder_name')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-text-field
                      v-model="customEmailData.subject"
                      :label="$t('email_templates.subject')"
                      :placeholder="$t('email_templates.placeholder_subject')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-textarea
                      v-model="customEmailData.body"
                      :label="$t('email_templates.message')"
                      rows="2"
                      auto-grow
                    ></v-textarea>
                  </v-flex>
                  <v-flex md12>
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <p class="subheader-1">
                            {{ $t("email_templates.variables") }}
                          </p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <code>{{ "{" + "{" + "name" + "}" + "}" }}</code> -
                          {{ $t("email_templates.client_name") }},<br />
                          <code>{{ "{" + "{" + "lastname" + "}" + "}" }}</code>
                          - {{ $t("email_templates.lastname") }},<br />
                          <code>{{
                            "{" + "{" + "middlename" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.middlename") }},<br />
                          <code>{{
                            "{" + "{" + "booking_id" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.booking_id") }},<br />
                          <code>{{
                            "{" + "{" + "start_date" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.start_date") }},<br />
                          <code>{{ "{" + "{" + "end_date" + "}" + "}" }}</code>
                          - {{ $t("email_templates.end_date") }},<br />
                          <code>{{
                            "{" + "{" + "location_start" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.location_start") }},<br />
                          <code>{{
                            "{" + "{" + "location_end" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.location_end") }},<br />
                          <code>{{ "{" + "{" + "car_name" + "}" + "}" }}</code>
                          - {{ $t("email_templates.car_name") }},<br />
                          <code>{{ "{" + "{" + "days" + "}" + "}" }}</code> -
                          {{ $t("email_templates.days") }},<br />
                          <code>{{ "{" + "{" + "price" + "}" + "}" }}</code> -
                          {{ $t("email_templates.price") }},<br />
                          <code>{{ "{" + "{" + "total" + "}" + "}" }}</code> -
                          {{ $t("email_templates.total") }},<br />
                          <code>{{ "{" + "{" + "deposit" + "}" + "}" }}</code> -
                          {{ $t("email_templates.deposit") }},<br />
                          <code>{{
                            "{" + "{" + "company_name" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.company_name") }},<br />
                          <code>{{
                            "{" + "{" + "company_email" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.company_email") }},<br />
                          <code>{{
                            "{" + "{" + "company_phone" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.company_phone") }},<br />
                          <code>{{
                            "{" + "{" + "company_address" + "}" + "}"
                          }}</code>
                          - {{ $t("email_templates.company_address") }}
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                  <v-flex md12 class="d-flex justify-center">
                    <v-switch
                      :label="$t('email_templates.add_attachment')"
                      v-model="attachment_email_template"
                      class="mr-2"
                    ></v-switch>
                    <v-switch
                      :label="$t('email_templates.save_template')"
                      v-model="save_email_template"
                    ></v-switch>
                  </v-flex>
                  <v-flex md12 v-if="!editEmailTemplate && save_email_template">
                    <v-text-field
                      v-if="save_email_template"
                      v-model="customEmailData.name"
                      :label="$t('email_templates.template_name')"
                      :placeholder="$t('email_templates.placeholder_name')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12 class="d-flex justify-center">
                    <v-switch
                      v-model="sendEmail"
                      @change="checkEmail()"
                      class="mt-0 mr-1 mr-1"
                      label="Email"
                      dense
                      hide-details
                    ></v-switch>
                    <v-switch
                      v-model="sendWhatsapp"
                      @change="checkWhatsapp()"
                      class="mt-0 mr-1 mr-1"
                      label="WhatsApp"
                      :disabled="
                        $store.getters.getCompany.company
                          ? !$store.getters.getCompany.company.whatsapp
                          : false
                      "
                      dense
                      hide-details
                    ></v-switch>
                  </v-flex>
                  <v-flex
                    md12
                    v-if="
                      attachment_email_template || customEmailData.attachment_id
                    "
                  >
                    <v-autocomplete
                      v-if="attachment_email_template"
                      v-model="customEmailData.attachment_id"
                      item-text="name"
                      item-value="id"
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('email_templates.select_attachment')"
                      :items="templates"
                      clearable
                    >
                    </v-autocomplete>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (showCustomEmailDialog = false),
                (editEmailTemplate = false),
                (customEmailData = {
                  id: null,
                  body: null,
                  name: null,
                  subject: null,
                }),
                (selected_email_templates = null),
                (save_email_template = false),
                (showEmailTemplates = false)
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn
            v-if="!editEmailTemplate && !save_email_template"
            color="success"
            @click="sendCustomEmail()"
            >{{ $t("email_templates.send") }}</v-btn
          >
          <v-btn
            v-if="editEmailTemplate && !save_email_template"
            color="success"
            @click="updateEmailTemplate()"
            >{{ $t("email_templates.save_template") }}</v-btn
          >
          <v-btn
            v-if="save_email_template && !editEmailTemplate"
            color="success"
            @click="saveCustomEmail()"
            >{{ $t("email_templates.save_template") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showQrcodeWhatsapp"
      :retain-focus="false"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <h2>{{ $t("whatsapp.check") }}</h2>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="(whatsappQrcode = null), (showQrcodeWhatsapp = false)"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-container>
              <v-layout row wrap>
                <v-flex md12>
                  <h3>{{ $t("whatsapp.auth") }}</h3>
                  <p>
                    {{ $t("whatsapp.scan") }}
                  </p>
                  <v-img
                    v-if="whatsappQrcode"
                    :src="whatsappQrcode"
                    max-width="400px"
                  ></v-img>
                  <v-btn v-else @click="retryWhatsapp()">{{
                    $t("whatsapp.load_code")
                  }}</v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="(whatsappQrcode = null), (showQrcodeWhatsapp = false)"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSignPad"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="600px"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <h2>{{ $t("sign.sign") }}</h2>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="(showSignPad = false), undoSign()"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text
          class="text-center"
          :style="isMobile ? 'padding: 5px !important;' : ''"
        >
          <v-select
            v-model="signature_type"
            class="mb-2"
            item-text="name"
            item-value="value"
            :items="signature_types"
            hide-details
            :label="$t('sign.select_type')"
          ></v-select>
          <VueSignaturePad
            :options="{
              onBegin: () => {
                $refs.signaturePad.resizeCanvas();
              },
            }"
            :customStyle="{ border: 'black 1px dashed', margin: '0 auto' }"
            :width="isMobile ? '300px' : '500px'"
            height="250px"
            ref="signaturePad"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="saveSign()">{{
            $t("custom.save")
          }}</v-btn>
          <v-btn color="secondary" @click="undoSign()">{{
            $t("sign.back")
          }}</v-btn>
          <v-btn color="warning" @click="clearSign()">{{
            $t("sign.clear")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- История брони -->
    <v-dialog
      v-model="booking_histories_dialog"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="1000px"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("bookings.history") }}</span>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="booking_histories_dialog = false"
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-treeview
            v-if="booking_histories && booking_histories.length > 0"
            :items="booking_histories"
          ></v-treeview>
          <v-subheader v-else>{{ $t("bookings.empty_history") }}</v-subheader>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="booking_histories_dialog = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Подарки -->
    <v-dialog
      v-model="show_gifts_dialog"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="1000px"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("gifts.title") }}</span>
          <v-spacer></v-spacer>
          <v-icon :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'" @click="show_gifts_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex :class="!isMobile ? 'd-flex' : ''">
            <v-flex md4>
              <v-select
                v-model="gift.gift_type"
                item-text="name"
                item-value="value"
                class="mr-2"
                :items="gift_types"
                hide-details
                :label="$t('gifts.type')"
              ></v-select>
            </v-flex>
            <v-flex md4>
              <v-text-field
                v-model.number="gift.count"
                :label="$t('gifts.count')"
                :placeholder="$t('gifts.count_placeholder')"
                class="mr-2"
              ></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field
                v-model.number="gift.price"
                :label="$t('gifts.price')"
              ></v-text-field>
            </v-flex>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" class="mr-2" @click="saveGift()">{{
            $t("custom.save")
          }}</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="show_gifts_dialog = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Загрузка фото выдачи -->
    <v-dialog
      v-model="uploadPhotosDialog"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="800px"
      @click:outside="uploadPhotosDialog = false"
    >
      <v-card>
        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex xs12 sm12 md12 class="mb-5 mt-10 mx-5">
            <gallery :booking_id="booking.id"></gallery>
          </v-flex>
          <v-flex xs12 sm12 md12 class="my-2 d-flex justify-center">
            <uppy-checkin
              :max-file-size-in-bytes="10000000"
              :width="isMobile ? 300 : 750"
              :height="isMobile ? 250 : 250"
              :theme="$vuetify.theme.dark ? 'dark' : 'light'"
              :booking_id="booking.id"
            ></uppy-checkin>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="success" @click="uploadPhotos()">{{
            $t("custom.save")
          }}</v-btn> -->
          <v-btn
            text
            @click="uploadPhotosDialog = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { extendMoment } from "moment-range";
import { Capacitor } from "@capacitor/core";
import UppyCheckin from "../../../plugins/uppy_checkin";
import Gallery from "./Gallary";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
let uuid = require("uuid");
import { nanoid } from 'nanoid'

const moment_range = extendMoment(moment);
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";
import Docxtemplater from "docxtemplater";
import axios from "axios";
import PizZip from "pizzip";
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      booking: { payments: [], counts: [] },
      bookings: [],
      cars: [],
      clients: [],
      workers: [],
      set_car: [],
      set_client: [],
      client: {},
      auto_calculate: true,
      gas_start: "",
      fuel_price: 50,
      clean_price: 0,
      limitExept: false,
      selected_prices: 0,
      limitCost: 0,
      check_mileage: 0,
      selected_period: null,
      selected_season: null,
      selected_seasons: [],
      season_days_diff_first: 0,
      season_days_diff_second: 0,
      season_first: null,
      season_second: null,
      editTariff: false,
      selected_tariff: null,
      tariffs: [],
      tariff: null,
      gifts: [],
      gift: { gift_type: 0, price: 0, count: 1 },
      gift_types: [
        { name: this.$t("gifts.days"), value: 0 },
        { name: this.$t("gifts.hours"), value: 1 },
      ],
      giftsPanel: 0,
      gift_create: false,
      gift_delete: false,
      show_gifts_dialog: false,
      start_date_not_formated: null,
      end_date_not_formated: null,
      sendEmail: false,
      sendWhatsapp: false,
      sendSms: false,
      signature: null,
      booking_histories: [],
      booking_histories_dialog: false,
      new_message: null,
      send_message_loading: false,
      masks: {
        // dayPopover: "DD MMM, YYYY",
      },
      calendarConfig: {
        type: "string",
        mask: "DD-MM-YYYY H:mm", // Uses 'iso' if missing,
        timeAdjust: "12:00:00",
      },
      calendar_attributes: [
        {
          key: "today",
          highlight: {
            color: "red",
            fillMode: "outline",
          },
          dates: new Date(),
        },
      ],
      showArrivalPayments: false,
      prolongData: {
        days: null,
        price: null,
        additional_hours: null,
        sum: null,
        icon: null,
        add_time_sum: null,
        add_time_icon: null,
      },
      hours: 0,
      payment: {
        cash: 0,
        cashless: 0,
        cash_card: 0,
        entity: 0,
        booking_id: null,
      },
      paymentsPanel: 0,
      prolongsPanel: 0,
      showAddPayment: false,
      showArrivalPayments: false,
      showCashCardPayment: false,
      showCashPayment: false,
      showEntityPayment: false,
      showPartRejection: false,
      giveFullDeposit: true,
      partReturnDeposit: false,
      giveNullDeposit: false,
      part_payment: false,
      showCleanPaid: false,
      showSignPad: false,
      templates: [],
      departure_payments: {
        rent: { sum: 0, icon: null },
        deposit: { sum: 0, icon: null },
        delivery: { sum: 0, icon: null },
        delivery_end: { sum: 0, icon: null },
        clean: { sum: 0, icon: null },
        hours_cost: { sum: 0, icon: null },
        insurance: { sum: 0, icon: null },
        equipment: { sum: 0, icon: null },
        fine: { sum: 0, icon: null },
        add_drivers_cost: { sum: 0, icon: null },
        other: { sum: 0, icon: null },
      },
      arrival_payments: {
        rent: { sum: 0, icon: null },
        clean: { sum: 0, icon: null },
        delivery: { sum: 0, icon: null },
        delivery_end: { sum: 0, icon: null },
        additional_hours: { sum: 0, icon: null },
        mileage: { sum: 0, icon: null },
        insurance: { sum: 0, icon: null },
        gas: { sum: 0, icon: null },
        equipment: { sum: 0, icon: null },
        add_drivers_cost: { sum: 0, icon: null },
        fine: { sum: 0, icon: null },
        damage: { sum: 0, icon: null },
        other: { sum: 0, icon: null },
        deposit: { sum: 0, icon: null },
      },
      prolongData: {
        days: 0,
        price: 0,
        additional_hours: 0,
        cash: 0,
        cashless: 0,
        cash_card: 0,
        entity: 0,
      },
      depositData: {
        cash: 0,
        cashless: 0,
        cash_card: 0,
        entity: 0,
      },
      finePaymentData: {
        sum: null,
        icon: null,
        state: null,
        number: null,
      },
      editedFine: [],
      invoices: [],
      search_invoices: null,
      date: null,
      showClientSearch: false,
      finePaymentDialog: false,
      search_client: null,
      add_services: [
        { text: this.$t("add_services.clean"), value: "Мойка" },
        {
          text: this.$t("add_services.add_hours"),
          value: "Дополнительное время",
        },
        {
          text: this.$t("add_services.extra_mileage"),
          value: "Превышение пробега",
        },
        { text: this.$t("add_services.fuel"), value: "Топливо" },
        { text: this.$t("add_services.delivery"), value: "Доставка" },
        { text: this.$t("add_services.checkout"), value: "Приём" },
        { text: this.$t("add_services.rent"), value: "Аренда" },
        { text: this.$t("add_services.insurance"), value: "Страховка" },
        { text: this.$t("add_services.add_drivers"), value: "Доп водители" },
        { text: this.$t("add_services.equipment"), value: "Оборудование" },
        { text: this.$t("add_services.fines"), value: "Штрафы" },
        { text: this.$t("add_services.damages"), value: "Повреждения" },
        { text: this.$t("add_services.others"), value: "Другое" },
      ],
      periods: [],
      seasons: [],
      fines: [],
      fines_search: null,
      fines_state: [
        { text: this.$t("fines_state.created"), value: "Создан" },
        { text: this.$t("fines_state.notify"), value: "Оповещён" },
        { text: this.$t("fines_state.paid"), value: "Оплачен" },
        { text: this.$t("fines_state.refund"), value: "Возмещён" },
      ],
      editFineDialog: false,
      fines_headers: [
        { text: this.$t("fines.number"), align: "center", value: "number" },
        { text: this.$t("custom.date"), value: "date", align: "center" },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      fines_headers_small: [
        { text: this.$t("fines.number"), align: "center", value: "number" },
        { text: this.$t("custom.date"), value: "date", align: "center" },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      headers_invoices: [
        { text: this.$t("custom.number"), align: "center", value: "number" },
        { text: this.$t("invoices.date"), value: "date", align: "center" },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("invoices.control"), sortable: false, align: "center" },
        { text: this.$t("invoices.get"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      search_fines: null,
      period_max_value: 31,
      selected_add_services: [],
      add_drivers: [],
      customer_category: [
        { text: this.$t("customer_category.new"), value: "Новый" },
        { text: this.$t("customer_category.loyal"), value: "Лояльный" },
        { text: this.$t("customer_category.statement"), value: "Постоянный" },
        { text: this.$t("customer_category.problem"), value: "Серый" },
        { text: this.$t("customer_category.bad"), value: "Чёрный" },
      ],
      bookings_states: [
        { text: this.$t("bookings_states.new"), value: "Новая" },
        { text: this.$t("bookings_states.in_work"), value: "В обработке" },
        { text: this.$t("bookings_states.abandoned"), value: "Недозвон" },
        { text: this.$t("bookings_states.decline"), value: "Отказ клиента" },
        { text: this.$t("bookings_states.no_cars"), value: "Нет машин" },
        {
          text: this.$t("bookings_states.wait_answer"),
          value: "Ожидает ответа клиента",
        },
        { text: this.$t("bookings_states.wait_pay"), value: "Ожидает оплаты" },
        { text: this.$t("bookings_states.active"), value: "Активная" },
        { text: this.$t("bookings_states.cancel"), value: "Отмена" },
        { text: this.$t("bookings_states.rided"), value: "Отъездила" },
        {
          text: this.$t("bookings_states.return_deposit"),
          value: "Вернуть залог",
        },
        { text: this.$t("bookings_states.accepted"), value: "Подтверждена" },
        {
          text: this.$t("bookings_states.not_accepted"),
          value: "Не подтверждена",
        },
        { text: this.$t("bookings_states.other"), value: "Другое" },
      ],
      debts: [],
      debt: {
        sum: null,
        state: this.$t("debts_state.created"),
        description: null,
        group: this.$t("add_services.rent"),
        client_id: null,
        booking_id: null,
      },
      debts_groups: [
        { text: this.$t("add_services.rent"), value: "Аренда" },
        { text: this.$t("add_services.fines"), value: "Штрафы" },
        { text: this.$t("add_services.damages"), value: "Повреждения" },
        { text: this.$t("add_services.others"), value: "Другое" },
      ],
      debts_state: [
        { text: this.$t("debts_state.created"), value: "Создан" },
        { text: this.$t("debts_state.notify"), value: "Оповещён" },
        { text: this.$t("debts_state.paid"), value: "Оплачен" },
        { text: this.$t("debts_state.refund"), value: "Возмещён" },
      ],
      debts_headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("custom.number"),
          value: "booking_id",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("bookings.paid"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      paymentData: {
        sum: null,
        icon: null,
        state: "Оплачен",
        group: null,
        debt_id: null,
      },
      prolongs: [],
      customEmailData: {
        id: null,
        name: null,
        body: null,
        subject: null,
        attachment_id: null,
      },
      email_templates: [],
      signature_types: [
        { name: this.$t("sign.start_booking"), value: "booking_start" },
        { name: this.$t("sign.end_booking"), value: "booking_end" },
        { name: this.$t("sign.prolong"), value: "prolong" },
        { name: this.$t("custom.invoices"), value: "invoice" },
        { name: this.$t("custom.act"), value: "act" },
      ],
      signature_type: "booking_start",
      selected_email_templates: null,
      editEmailTemplate: false,
      save_email_template: false,
      attachment_email_template: false,
      showEmailTemplates: false,
      showCustomEmailDialog: false,
      editDebtDialog: false,
      paymentDebtDialog: false,
      editDialog: false,
      departureDialog: false,
      payment_dialog: false,
      arrivalDialog: false,
      showProblems: false,
      departure_yes: false,
      editPlace: false,
      editSale: false,
      manualEditing: false,
      showDescription: false,
      showAddDrivers: false,
      showCashlessPayment: false,
      showRejectionBooking: false,
      uploadPhotosDialog: false,
      editCar: false,
      editExtra: false,
      editEquipment: false,
      editInsurance: false,
      prolongDialog: false,
      warningAddOneDay: false,
      warningLessThenDay: false,
      showEditClient: false,
      isLoadingClients: false,
      isLoadingClientsAddDriver: false,
      isLoadingCars: false,
      searchCar: "",
      searchClient: null,
      search_add_driver: "",
      editDate: false,
      doc_template_url: "",
      entity_doc_template_url: "",
      dont_send_review_reminder: false,
      whatsappQrcode: null,
      showQrcodeWhatsapp: false,
      hour_timer: 0,
      connected: false,
      timeout: null,
      isMobile: false,
      isLoading: false,
    };
  },
  computed: {
    checkHoursLimit() {
      return (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.hours_limit &&
        this.$store.getters.getCompany.company.hours_limit > 0 &&
        this.booking.additional_hours >
          this.$store.getters.getCompany.company.hours_limit
      );
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    user_full_name() {
      if (this.$store.getters.getCurrentUser) {
        return `${this.$store.getters.getCurrentUser.last_name} ${this.$store.getters.getCurrentUser.first_name} ${this.$store.getters.getCurrentUser.middle_name}`;
      } else {
        return "";
      }
    },
    user_fio() {
      if (this.$store.getters.getCurrentUser) {
        return `${this.$store.getters.getCurrentUser.last_name} ${this.$store.getters.getCurrentUser.first_name ? this.$store.getters.getCurrentUser.first_name[0] : ''} ${this.$store.getters.getCurrentUser.middle_name ? this.$store.getters.getCurrentUser.middle_name[0] : ''}`;
      } else {
        return "";
      }
    },
    progressPercent() {
      return (
        (this.calculateRentPaymentsSum(this.booking) /
          ((this.booking.rental_cost - this.booking.aggr_commission) / this.paidDays) /
          this.paidDays) *
        100
      );
    },
    paidDays() {
      let days =
        Math.round(
          this.calculateRentPaymentsSum(this.booking) /
            (this.booking.rental_cost / this.booking.days)
        ) || this.booking.days;
      if (this.gifts && this.gifts.length > 0) {
        let gift_days = 0;
        this.gifts.forEach((gift) => {
          if (gift.gift_type == 0) {
            gift_days += gift.count;
          }
        });
        let fact_days = days - gift_days;
        days =
          Math.round(
            (this.calculateRentPaymentsSum(this.booking) /
              (this.booking.rental_cost / fact_days)) *
              100
          ) / 100;
      }
      return days;
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
    messages() {
      // Messages for booking
      if(this.booking && this.booking.messages) {
        return this.booking.messages;
      } else {
        return [];
      }
    },
    isNative() {
      return Capacitor.isNativePlatform();
    },
    checkWriteFile() {
      return Capacitor.isNativePlatform() && Filesystem.checkPermissions();
    },
    requestPermissionsWriteFile() {
      return Filesystem.requestPermissions();
    },
  },
  channels: {
    BookingChannel: {
      connected() {
        this.connected = "success";
      },
      disconnected() {
        this.connected = "error";
      },
      rejected() {
        console.log("rejected");
        this.connected = "error";
      },
      received(data) {
        // затирает данные в форме
        if (!(this.editDialog || this.arrivalDialog)) {
          this.booking = data.booking;
        }
        this.booking.counts = data.counts;
        this.booking.client = data.client;
        this.booking.prolongs = data.prolongs;
        this.booking.generated_documents = data.generated_documents;
        // Непонятно для чего???
        this.booking.selected_price = this.booking.price;
      },
    },
  },
  mounted() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$tours["registrationTour"] &&
        this.$store.getters.getTourNextStep
      ) {
        this.$tours["registrationTour"].nextStep();
      }
      this.isLoading = true;
      this.axios
        .get(`/api/v1/bookings/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.booking = response.data;
          if (this.booking.manual_editing) {
            this.auto_calculate = false;
          }
          this.$cable.subscribe({
            channel: "BookingChannel",
            room: this.booking.id,
            booking_id: this.booking.id,
          });
          // Непонятно для чего???
          this.booking.selected_price = this.booking.price;
        })
        .then((response) => {
          // Timer
          if (this.booking.created_at) {
            this.hour_timer = moment.duration(moment().diff(moment(this.booking.created_at).add(1, 'hours'))).asMilliseconds() * -1
          } else {
            this.hour_timer = 0;
          }
          if (this.booking.car_id && this.booking.car_id > 0) {
            this.axios
              .get(`/api/v1/cars/${self.booking.car_id}`, {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                self.set_car = response.data;
                self.booking.start_mileage =
                  self.booking.start_mileage > 0
                    ? self.booking.start_mileage
                    : self.set_car.mileage;
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.car_load"));
                console.log(error);
              });
          }
          this.axios
            .get(`/api/v1/company`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.periods = response.data.periods;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.company_load"));
              console.log(error);
            });

          this.axios
            .get(`api/v1/seasons`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.seasons = response.data;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.seasons_load"));
              console.log(error);
            });

          this.axios
            .get(`api/v1/bookings/${this.booking.id}/gifts`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.gifts = response.data;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.gifts_load"));
              console.log(error);
            });

          this.axios
            .get(`/api/v1/clients/${self.booking.client_id}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              self.set_client = response.data.client;
              self.client = response.data.client;
              // Проверяем подключение оповещений
              if (
                this.$store.getters.getCompany.company.whatsapp &&
                self.client.phone
              ) {
                this.sendWhatsapp = true;
              }
              if (self.client.email) {
                this.sendEmail = true;
              }
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.car_load"));
              console.log(error);
            });
          // Доп водители
          if (this.booking.add_drivers) {
            this.booking.add_drivers.forEach((driver) => {
              this.axios
                .get(`/api/v1/clients/${driver}`, {
                  headers: {
                    Authorization: self.$store.getters.getAuthToken,
                  },
                })
                .then((response) => {
                  self.add_drivers.push(response.data.client);
                })
                .catch((error) => {
                  this.setError(error, this.$t("errors.car_load"));
                  console.log(error);
                });
            });
          }

          this.axios
            .get(`api/v1/tariffs`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              let tariffs = response.data;
              tariffs.forEach((item) => {
                let result;
                if (item.sum > 0 || item.sum < 0) {
                  result = `${item.sum}${self.currency}`;
                } else {
                  result = `${item.percent}%`;
                }
                return (item["fullname"] = `${item.name} | ${result}`);
              });

              this.tariffs = this.tariffs.concat(tariffs);
            })
            .catch((error) => {
              this.isLoading = false;
              this.setError(error, this.$t("errors.tariffs_load"));
              console.log(error);
            });

          this.axios
            .get(`/api/v1/bookings_fines?booking_id=${this.$route.params.id}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.fines = response.data;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.fines_load"));
              console.log(error);
            });

          this.axios
            .get(`/api/v1/bookings_debts?booking_id=${this.$route.params.id}`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              this.debts = response.data;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.debts_load"));
              console.log(error);
            });

          this.axios
            .get(
              `/api/v1/bookings_invoices?booking_id=${this.$route.params.id}`,
              {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.invoices = response.data;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.invoices_load"));
              console.log(error);
            });

          this.axios
            .get("/admin/users", {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              let workers = [];
              response.data.forEach((item) =>
                workers.push({
                  id: item.id,
                  name: item.name ? item.name : item.email,
                })
              );
              self.workers = workers;
            })
            .catch((error) => {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.employers_load"),
              });
              this.setError(error, this.$t("errors.employers_load"));
            });
        })
        .catch((error) => {
          location.replace("/bookings");
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: self.$t("errors.booking_load"),
          });
          self.setError(error, self.$t("errors.booking_load"));
          console.log(error);
        });
      this.axios
        .get("/api/v1/bookings_for_card", {
          headers: {
            Authorization: self.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.bookings = [];
          // берем из сериалазера
          response.data.data.forEach((booking) => {
            this.bookings.push(booking.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.bookings_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
      // Удаляю из селекта перепробег
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.mileage_limit_cost == 0
      ) {
        this.add_services.splice(
          this.add_services.findIndex(
            (service) => service == "Превышение пробега"
          ),
          1
        );
      }
      // check every 30 seconds if action cable wss server is online
      function check_server(){
        self.check_server = setInterval(function(){
          if (self.$root.$cable._cable.connection.disconnected) {
            self.connected = false;
            console.log("please reconnect");
            // self.$root.$cable.connection.connect();
          } else {
            self.connected = true;
            // console.log("connected");
          }
        }.bind(self), 30000);
      }
      check_server();
    } else {
      this.$store.commit("unsetCurrentUser");
      this.$router.push({ path: `/signin` }).catch((err) => {});
    }
  },
  watch: {
    start_date_not_formated() {
      if (this.start_date_not_formated) {
        this.start_date = moment
          .utc(this.start_date_not_formated)
          .format("DD-MM-YYYY H:mm");
        if (this.end_date) {
          this.setDates();
          this.checkBookingsDates(this.set_car, this.booking);
        }
      }
    },
    end_date_not_formated() {
      if (this.end_date_not_formated) {
        this.end_date = moment
          .utc(this.end_date_not_formated)
          .format("DD-MM-YYYY H:mm");
        if (this.start_date) {
          this.setDates();
          this.checkBookingsDates(this.set_car, this.booking);
        }
      }
    },
    set_car() {
      if (this.editCar) {
        this.isLoading = true;
        if (this.set_car && this.set_car.car_name) {
          this.booking.car_name = this.set_car.car_name;
        } else {
          this.isLoading = false;
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.no_car_name_title"),
            text: this.$t("errors.no_car_name_text"),
          });
        }
        if (this.set_car.state == 3) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("states.car_critical"),
          });
        } else if (this.set_car.state == 4) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("states.car_long_rent"),
          });
        } else if (this.set_car.state == 5) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("states.car_no_rent"),
          });
        }
        // console.log(this.set_car);
        // if (this.set_car && this.set_car.prices.length > 0) {
        //   this.booking.car_name = this.set_car.car_name;
        // } else {
        //   this.$swal({
        //     showConfirmButton: true,
        //     icon: "warning",
        //     title: this.$t('errors.no_car_prices_title'),
        //     text: this.$t('errors.no_car_prices_text')
        //   });
        // }
        this.booking.car_code = this.set_car.code;
        this.booking.car_id = this.set_car.id;
        this.booking.start_mileage = this.set_car.mileage;
        this.booking.price_hour = this.set_car.price_hour;
        this.booking.deposit = this.set_car.deposit;

        this.axios
          .get(`/api/v1/cars/${this.set_car.id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            if (response.data.prices.length > 0) {
              this.set_car["prices"] = response.data.prices;
            } else {
              this.set_car["prices"] = [];
              this.$swal({
                showConfirmButton: true,
                icon: "warning",
                title: this.$t("errors.no_prices_title"),
                text: this.$t("errors.no_prices_text"),
              });
            }
            if (!this.arrivalDialog) {
              // Set_car включается и при приеме и идет ошибка расчета цены
              this.calculateCost();
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.set_car"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    searchCar(val) {
      // Items have already been loaded
      if (this.cars.length > 0) return;

      // Items have already been requested
      if (this.isLoadingCars) return;

      this.isLoadingCars = true;

      // Lazily load input items
      this.axios
        .get("/api/v1/cars", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.cars.push(element.attributes);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.cars_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingCars = false));
    },
    searchClient(val) {
      // if (this.client && this.client.id) {
      //   return;
      // }
      if (this.searchClient == "" || this.searchClient == " ") {
        this.searchClient = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadClients();
          }, 200); // delay
        }
        debounce();
      }
    },
    client() {
      if (this.showEditClient) {
        this.booking.client_id = this.client.id;
        this.booking.first_name = this.client.name;
        this.booking.middle_name = this.client.middlename;
        this.booking.last_name = this.client.lastname;
        this.booking.fio = this.client.fio;
        // Скидка не грузилась в редактировании брони
        this.booking.sale = this.booking.sale
          ? this.booking.sale
          : this.client.sale;
        this.booking.sale_cash = this.booking.sale_cash
          ? this.booking.sale_cash
          : this.client.sale_cash;
        this.booking.entity = this.client.entity;
        this.booking.entity_name = this.client.entity_name
          ? this.client.entity_name + ", " + this.client.reg_form
          : "";

        if (!this.arrivalDialog) {
          // Set_car включается и при приеме и идет ошибка расчета цены
          this.calculateCost();
        }
      }
    },
    selected_email_templates() {
      if (this.selected_email_templates) {
        let template = this.email_templates.find(
          (template) => template.id == this.selected_email_templates
        );
        this.customEmailData.name = template.name;
        this.customEmailData.subject = template.subject;
        this.customEmailData.body = template.body;
      }
    },
    selected_tariff() {
      if (this.selected_tariff) {
        this.tariff = this.tariffs.find((tariff) => {
          return tariff.id == this.selected_tariff;
        });
        this.calculateCostWithTariff();
      } else {
        this.tariff = null;
        this.calculateCost();
      }
    },
    // selected_seasons() {
    //   console.log(this.selected_seasons);
    // },
  },
  methods: {
    editBooking() {
      if (this.booking.car_id && this.booking.car_id > 0) {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`/api/v1/cars/${self.booking.car_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            self.set_car = response.data;
            // При смене авто сохранялся старый пробег
            // self.booking.start_mileage =
            //   self.booking.start_mileage > 0
            //     ? self.booking.start_mileage
            //     : self.set_car.mileage;
            // Поменял на старый вариант, при смене авто пробег записывается новый
            self.booking.start_mileage =
              self.booking.start_mileage > 0
                ? self.booking.start_mileage
                : self.set_car.mileage;
            this.start_date = this.booking.start_date;
            this.end_date = this.booking.end_date;
            this.selected_tariff = this.booking.tariff_id
              ? this.booking.tariff_id
              : null;
            // console.log(this.selected_tariff);
            this.setDates();
            this.editDialog = true;
            if (
              this.booking.add_drivers &&
              this.booking.add_drivers.length > 0
            ) {
              this.showAddDrivers = true;
            }
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.car_load"));
            console.log(error);
          });
        this.axios
          .get(`api/v1/seasons`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.seasons = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.seasons_load"));
            console.log(error);
          });
        this.axios
          .get(`api/v1/tariffs`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let tariff = response.data;
            tariff.forEach((item) => {
              let result;
              if (item.sum > 0 || item.sum < 0) {
                result = `${item.sum}${self.currency}`;
              } else {
                result = `${item.percent}%`;
              }
              return (item["fullname"] = `${item.name} | ${result}`);
            });
            this.tariffs = this.tariffs.concat(tariff);
            // Ищем тариф если есть
            if (this.booking.tariff_id) {
              let result = this.tariffs.find(
                (tariff) => tariff.id == this.booking.tariff_id
              );
              if (result) {
                this.tariff = result;
                this.selected_tariff = this.tariff.id;
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.tariffs_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.editDialog = true;
        if (this.booking.add_drivers && this.booking.add_drivers.length > 0) {
          this.showAddDrivers = true;
        }
      }
    },
    setDates() {
      if (this.set_car != null) {
        this.booking.start_date = moment
          .utc(this.start_date, "DD-MM-YYYY H:mm")
          .format("DD-MM-YYYY H:mm");
        this.booking.end_date = moment
          .utc(this.end_date, "DD-MM-YYYY H:mm")
          .format("DD-MM-YYYY H:mm");
        // проверка на дата конца раньше начала
        if (
          this.booking.start_date &&
          this.booking.end_date &&
          moment
            .utc(this.booking.start_date, "DD-MM-YYYY H:mm")
            .isAfter(
              moment.utc(this.booking.end_date, "DD-MM-YYYY H:mm"),
              "hour"
            )
        ) {
          this.$swal({
            showConfirmButton: true,
            icon: "warning",
            title: this.$t("errors.incorrect_date_title"),
            text: this.$t("errors.incorrect_date_text"),
          });
          return;
        }

        this.calculateDays();
        this.calculateCost();
      } else {
        this.$swal({
          icon: "warning",
          title: this.$t("errors.car_not_select_title"),
          text: this.$t("errors.car_not_select_text"),
        });
      }
    },
    minDate() {
      if (this.start_date) {
        console.log(moment(this.start_date, "DD-MM-YYYY H:mm").toDate());
        return moment(this.start_date, "DD-MM-YYYY H:mm").toDate();
      } else {
        return undefined;
      }
    },
    setDataToEditDate() {
      this.start_date = this.booking.start_date;
      this.end_date = this.booking.end_date;
      this.start_date_not_formated = moment
        .utc(this.booking.start_date, "DD-MM-YYYY H:mm")
        .toISOString();
      this.end_date_not_formated = moment
        .utc(this.booking.end_date, "DD-MM-YYYY H:mm")
        .toISOString();
    },
    getTariffs() {
      let self = this;
      this.axios
        .get(`api/v1/tariffs`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          let tariff = response.data;
          tariff.forEach((item) => {
            let result;
            if (item.sum > 0 || item.sum < 0) {
              result = `${item.sum}${self.currency}`;
            } else {
              result = `${item.percent}%`;
            }
            return (item["fullname"] = `${item.name} | ${result}`);
          });
          this.tariffs = this.tariffs.concat(tariff);
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.tariffs_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getTemplates() {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/small_templates?car_id=${this.booking.car_id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.templates = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.templates"));
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.templates"),
            text: this.error,
          });
        })
        .finally((this.isLoading = false));
    },
    findTariff(id) {
      let result;
      if (this.tariffs && this.tariffs.length > 0) {
        result = this.tariffs.find((tariff) => tariff.id == id);
        if (result) {
          return result.fullname;
        }
      }
    },
    getBooking() {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/bookings/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.booking = response.data;
          this.booking.selected_price = this.booking.price;

          if (this.booking.manual_editing) {
            this.auto_calculate = false;
          }
          this.getCar();
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.booking_load"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.booking_load"),
            text: this.error,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    getCar() {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/cars/${this.set_car.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          if (response.data.prices.length > 0) {
            this.set_car["prices"] = response.data.prices;
          } else {
            this.set_car["prices"] = [];
            this.$swal({
              showConfirmButton: true,
              icon: "warning",
              title: this.$t("errors.no_prices_title"),
              text: this.$t("errors.no_prices_text"),
            });
          }
          if (!this.arrivalDialog) {
            // Set_car включается и при приеме и идет ошибка расчета цены
            this.calculateCost();
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.set_car"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    calculateDays() {
      if (this.auto_calculate) {
        let self = this;
        let start_date = moment.utc(this.booking.start_date, "DD-MM-YYYY H:mm");
        let end_date = moment.utc(this.booking.end_date, "DD-MM-YYYY H:mm");

        this.selected_season = null;
        this.selected_seasons = [];
        function setSeason() {
          self.seasons.forEach((season) => {
            let check_start_date = start_date.format("DD.MM");
            // Если есть дата окончания то проверяем на попадание в диапазон
            let check_end_date = start_date.format("DD.MM");
            if (end_date) {
              check_end_date = end_date.format("DD.MM");
            }
            if (
              self.$store.getters.getCompany.company.season_start_calc &&
              self.$store.getters.getCompany.company.season_end_calc
            ) {
              let start = moment
                .utc(season.start_date, "DD.MM")
                .set("year", start_date.format("YYYY"))
                .startOf("day");
              let end = moment
                .utc(season.end_date, "DD.MM")
                .set("year", start_date.format("YYYY"))
                .endOf("day");
              if (end_date.format("YYYY") == start_date.format("YYYY")) {
                let season_range = moment_range.range(start, end);
                let booking_range = moment_range.range(start_date, end_date);
                if (
                  season_range.overlaps(booking_range, {
                    adjacent: true,
                  })
                ) {
                  // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                  let days = Math.round(
                    season_range.intersect(booking_range).diff("days", true)
                  );
                  // Поправка на то что входит в сезон меньше суток, мешает считать цену
                  // Считаем что сезон кончается не включая последнюю дату
                  // if (
                  //   days == 0 &&
                  //   season_range
                  //     .intersect(booking_range)
                  //     .diff("days", true) > 0
                  // ) {
                  //   days += 1;
                  // }

                  // Проверка есть ли цены на сезон
                  if (self.set_car.prices && self.set_car.prices.length > 0) {
                    self.set_car.prices.forEach((price) => {
                      if (price.season_id == season.id) {
                        self.selected_seasons.push({
                          season: season,
                          days: days,
                        });
                      }
                    });
                  }
                }
              } else {
                // Если годы разные
                let start_second_year = moment(season.start_date, "DD.MM")
                  .set("year", end_date.format("YYYY"))
                  .startOf("day");
                let end_second_year = moment(season.end_date, "DD.MM")
                  .set("year", end_date.format("YYYY"))
                  .endOf("day");
                let season_range = moment_range.range(start, end);
                let season_range_second_year = moment_range.range(
                  start_second_year,
                  end_second_year
                );
                let booking_range = moment_range.range(start_date, end_date);
                if (
                  season_range.overlaps(booking_range, {
                    adjacent: true,
                  })
                ) {
                  // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                  let days = Math.round(
                    season_range.intersect(booking_range).diff("days", true)
                  );
                  // Проверка есть ли цены на сезон
                  if (self.set_car.prices && self.set_car.prices.length > 0) {
                    self.set_car.prices.forEach((price) => {
                      if (price.season_id == season.id) {
                        self.selected_seasons.push({
                          season: season,
                          days: days,
                        });
                      }
                    });
                  }
                }
                if (
                  season_range_second_year.overlaps(booking_range, {
                    adjacent: true,
                  })
                ) {
                  // Сделать поправку на кол-во часов плюс сутки ??? надо ли
                  let days = Math.round(
                    season_range_second_year
                      .intersect(booking_range)
                      .diff("days", true)
                  );
                  // Проверка есть ли цены на сезон
                  if (self.set_car.prices && self.set_car.prices.length > 0) {
                    self.set_car.prices.forEach((price) => {
                      if (price.season_id == season.id) {
                        self.selected_seasons.push({
                          season: season,
                          days: days,
                        });
                      }
                    });
                  }
                }
              }
            } else if (
              self.$store.getters.getCompany.company.season_start_calc &&
              !self.$store.getters.getCompany.company.season_end_calc
            ) {
              // Если сезон считается только по дате начала
              // Поменял endOf на startOf, при первой дате сезона брались цены по умолчанию
              let start = moment
                .utc(season.start_date, "DD.MM")
                .set("year", start_date.format("YYYY"))
                .startOf("day");
              let end = moment
                .utc(season.end_date, "DD.MM")
                .set("year", start_date.format("YYYY"))
                .endOf("day");

              if (
                moment(check_start_date, "DD.MM")
                  .set("year", start_date.format("YYYY"))
                  .isBetween(start, end, undefined, "[]")
              ) {
                let days = Math.trunc(
                  moment.duration(end_date.diff(start_date)).asDays()
                );
                if (days == 0) {
                  days += 1;
                }
                // Поправка на доп часы плюс сутки
                if (
                  (self.booking.additional_hours > 0 &&
                    self.booking.additional_hours *
                      (self.booking.price_hour > 0
                        ? self.booking.price_hour
                        : self.set_car.price_hour) >=
                      self.booking.selected_price) ||
                  (self.$store.getters.getCompany.company.hours_limit > 0 &&
                    self.booking.additional_hours >
                      self.$store.getters.getCompany.company.hours_limit)
                ) {
                  days += 1;
                }
                // Проверка есть ли цены на сезон
                if (self.set_car.prices && self.set_car.prices.length > 0) {
                  self.set_car.prices.forEach((price) => {
                    if (price.season_id == season.id) {
                      self.selected_seasons.push({
                        season: season,
                        days: days,
                      });
                    }
                  });
                }
              }
            } else if (
              !self.$store.getters.getCompany.company.season_start_calc &&
              self.$store.getters.getCompany.company.season_end_calc
            ) {
              let start = moment
                .utc(season.start_date, "DD.MM")
                .set("year", end.format("YYYY"))
                .startOf("day");
              let end = moment
                .utc(season.end_date, "DD.MM")
                .set("year", end.format("YYYY"))
                .endOf("day");
              // Если сезон считается только по дате окончания
              if (
                moment(check_end_date, "DD.MM").isBetween(
                  start,
                  end,
                  undefined,
                  "[]"
                )
              ) {
                let days = Math.trunc(
                  moment.duration(end_date.diff(start_date)).asDays()
                );
                if (days == 0) {
                  days += 1;
                }
                // Поправка на доп часы плюс сутки
                if (
                  (this.booking.additional_hours > 0 &&
                    this.booking.additional_hours *
                      (this.booking.price_hour > 0
                        ? this.booking.price_hour
                        : this.set_car.price_hour) >=
                      this.booking.selected_price) ||
                  (this.$store.getters.getCompany.company.hours_limit > 0 &&
                    this.booking.additional_hours >
                      this.$store.getters.getCompany.company.hours_limit)
                ) {
                  days += 1;
                }
                // Проверка есть ли цены на сезон
                if (self.set_car.prices && self.set_car.prices.length > 0) {
                  self.set_car.prices.forEach((price) => {
                    if (price.season_id == season.id) {
                      self.selected_seasons.push({
                        season: season,
                        days: days,
                      });
                    }
                  });
                }
              }
            }
          });
          // Поправка на доп часы плюс сутки если включен режим определения и старт и конец брони
          // Только если сезон один, в остальных случаях вроде как не нужно
          // Если время старта больше чем время окончания, то косяк и прибавляет лишний день, поэтому проверка end_date.hour() > start_date.hour()
          if (
            self.$store.getters.getCompany.company.season_start_calc &&
            self.$store.getters.getCompany.company.season_end_calc &&
            self.selected_seasons.length == 1 &&
            end_date.hour() > start_date.hour()
          ) {
            if (
              (self.booking.additional_hours > 0 &&
                self.booking.additional_hours *
                  (self.booking.price_hour > 0
                    ? self.booking.price_hour
                    : self.set_car.price_hour) >=
                  self.booking.selected_price) ||
              (self.$store.getters.getCompany.company.hours_limit > 0 &&
                self.booking.additional_hours >
                  self.$store.getters.getCompany.company.hours_limit)
            ) {
              self.selected_seasons.forEach((season) => {
                season.days += 1;
              });
            }
          }
        }

        setSeason();
        let hours = moment.duration(end_date.diff(start_date)).asHours();
        if (hours > 24) {
          let start_date_days = moment.utc(
            moment.utc(start_date, "DD-MM-YYYY H:mm").format("DD-MM-YYYY"),
            "DD-MM-YYYY"
          );
          let end_date_days = moment.utc(
            moment.utc(end_date, "DD-MM-YYYY H:mm").format("DD-MM-YYYY"),
            "DD-MM-YYYY"
          );
          this.booking.additional_hours = 0;
          let additionalHours = 0;
          if (
            hours >
            moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
          ) {
            this.hours = hours;
            additionalHours = hours % 24;
            this.booking.additional_hours = Math.trunc(additionalHours);
          }
          if (
            hours <
            moment.duration(end_date_days.diff(start_date_days)).asDays() * 24
          ) {
            this.hours = hours;
            additionalHours = hours % 24;
            this.booking.additional_hours = Math.trunc(additionalHours);
          }
        } else {
          this.booking.additional_hours = 0;
        }
      }
    },
    calculateCost() {
      if (this.auto_calculate) {
        let self = this;
        if (this.set_car) {
          this.calculateDays();

          let diff = Math.trunc(
            moment
              .duration(
                moment
                  .utc(this.booking.end_date, "DD-MM-YYYY H:mm")
                  .diff(moment.utc(this.booking.start_date, "DD-MM-YYYY H:mm"))
              )
              .asDays()
          );
          if (!isNaN(diff)) {
            function setSale() {
              if (self.booking.sale > 0) {
                self.booking.selected_price = Math.trunc(
                  self.booking.selected_price -
                    self.booking.price_no_sale * (self.booking.sale / 100)
                );
                self.booking.rental_cost = Math.round(
                  self.booking.days * self.booking.selected_price
                );
              }
              if (self.booking.sale_cash > 0) {
                self.booking.selected_price = Math.trunc(
                  self.booking.selected_price - self.booking.sale_cash
                );
                self.booking.rental_cost = Math.round(
                  self.booking.days * self.booking.selected_price
                );
              }
              if (self.booking.rental_cost_sale > 0) {
                self.booking.rental_cost -=
                  self.booking.rental_cost *
                  (self.booking.rental_cost_sale / 100);
                self.booking.selected_price = Math.trunc(
                  self.booking.rental_cost / self.booking.days
                );
              }
              if (self.booking.rental_cost_sale_cash > 0) {
                self.booking.rental_cost -= self.booking.rental_cost_sale_cash;
                self.booking.selected_price = Math.trunc(
                  self.booking.rental_cost / self.booking.days
                );
              }
              if (self.booking.sale > 0 && self.booking.sale_cash > 0) {
                self.booking.rental_cost = Math.round(
                  self.booking.days * self.booking.selected_price
                );
              }
              if (
                (self.booking.additional_hours > 0 &&
                  self.booking.additional_hours *
                    (self.booking.price_hour > 0
                      ? self.booking.price_hour
                      : self.set_car.price_hour) >=
                    self.booking.selected_price) ||
                (self.$store.getters.getCompany.company.hours_limit > 0 &&
                  self.booking.additional_hours >
                    self.$store.getters.getCompany.company.hours_limit)
              ) {
                self.booking.hours_cost = 0;
              } else {
                self.booking.hours_cost =
                  self.booking.additional_hours *
                  (self.booking.price_hour > 0
                    ? self.booking.price_hour
                    : self.set_car.price_hour);
                setGiftHours();
              }
            }
            function setGiftDays() {
              if (
                self.gifts &&
                self.gifts.length > 0 &&
                self.gift_delete == false
              ) {
                self.gifts.forEach(function (gift) {
                  if (gift.gift_type == 0) {
                    self.booking.rental_cost = Math.trunc(
                      self.booking.rental_cost - gift.price * gift.count
                    );
                  }
                });
              }
            }
            function setGiftHours() {
              if (self.gifts && self.gifts.length > 0) {
                self.gifts.forEach(function (gift) {
                  if (gift.gift_type == 1) {
                    self.booking.hours_cost = Math.trunc(
                      self.booking.hours_cost - gift.price * gift.count
                    );
                  }
                });
              }
            }
            if (
              (this.booking.additional_hours > 0 &&
                this.booking.additional_hours *
                  (this.booking.price_hour > 0
                    ? this.booking.price_hour
                    : this.set_car.price_hour) >=
                  this.booking.selected_price) ||
              (this.$store.getters.getCompany.company.hours_limit > 0 &&
                this.booking.additional_hours >
                  this.$store.getters.getCompany.company.hours_limit)
            ) {
              this.booking.hours_cost = 0;
            } else {
              this.booking.hours_cost =
                this.booking.additional_hours *
                (this.booking.price_hour > 0
                  ? this.booking.price_hour
                  : this.set_car.price_hour);
              setGiftHours();
            }
            // Если есть продление, то считаем по нему
            if (this.booking.prolongs && this.booking.prolongs.length > 0) {
              // Ищем цену аренды
              let first_prolong = this.booking.prolongs.sort((a, b) => {
                return a.id - b.id;
              })[0];
              let last_prolong = this.booking.prolongs.sort((a, b) => {
                return b.id - a.id;
              })[0];
              // Берем цену последнего продления
              this.booking.selected_price = last_prolong.new_price;

              // считаем цену аренды за все время с учетом продлений и до них
              let prolongs_cost = 0;
              let all_days = first_prolong.start_days;
              this.booking.prolongs.forEach((prolong) => {
                prolongs_cost += prolong.new_price * prolong.prolong_days;
                all_days += prolong.prolong_days;
              });
              // за доп время если есть
              if (
                (this.booking.additional_hours > 0 &&
                  this.booking.additional_hours *
                    (this.booking.price_hour > 0
                      ? this.booking.price_hour
                      : this.set_car.price_hour) >=
                    this.booking.selected_price) ||
                (this.$store.getters.getCompany.company.hours_limit > 0 &&
                  this.booking.additional_hours >
                    this.$store.getters.getCompany.company.hours_limit)
              ) {
                this.booking.hours_cost = 0;
                diff += 1;
                this.booking.days = diff;
                this.booking.price_no_sale = this.booking.selected_price;
                // // setSale();
                this.booking.total = this.booking.rental_cost;
                this.warningAddOneDay = true;
                this.warningLessOneDay = false;
              } else {
                this.booking.days = diff;

                // setSale();
                this.booking.price_no_sale = this.booking.selected_price;
                this.booking.total =
                  this.booking.rental_cost +
                  this.booking.additional_hours * this.booking.price_hour;
                this.warningAddOneDay = false;
                this.warningLessOneDay = false;
              }
              if (diff != all_days) {
                // считаем стоимость если поменяли дату, считаем разницу
                let diff_days = diff - all_days;
                let diff_cost = last_prolong.new_price * diff_days;
                this.booking.rental_cost =
                  first_prolong.old_price * first_prolong.start_days +
                  prolongs_cost +
                  diff_cost;
              } else {
                this.booking.rental_cost =
                  first_prolong.old_price * first_prolong.start_days +
                  prolongs_cost;
              }
            } else {
              // Если нет продлений
              self.selected_period = null;
              self.period_max_value = parseInt(
                self.periods[self.periods.length - 1].split(" - ")[1]
              )
                ? parseInt(
                    self.periods[self.periods.length - 1].split(" - ")[1]
                  )
                : 31;

              // Проверяем период
              function checkDiffInc() {
                self.periods.forEach((period) => {
                  let start = parseInt(period.split(" - ")[0]);
                  let end = parseInt(period.split(" - ")[1]);
                  if (diff >= start && diff <= end) {
                    self.selected_period = {
                      value: period,
                      index: self.periods.indexOf(period),
                    };
                  } else if (diff > self.period_max_value) {
                    // периодов на один меньше чем цен (+31)
                    self.selected_period = {
                      value: "31+",
                      index: self.periods.length,
                    };
                  }
                });
              }

              checkDiffInc();

              // ищем цены согласно сезону
              function selectPrices() {
                let rental_cost = 0;
                let seasons_days = 0;
                // Выбираем период
                let selected_period_index = self.selected_period
                  ? self.selected_period.index
                  : 0;
                if (diff > self.period_max_value) {
                  selected_period_index = self.periods.length;
                }

                if (self.set_car.prices && self.set_car.prices.length > 0) {
                  self.selected_seasons.forEach((season) => {
                    // считаем количество дней входящих в сезон
                    seasons_days += Math.round(season.days);
                    self.set_car.prices.forEach((price) => {
                      if (price.season_id == season.season.id) {
                        console.log(season.days);
                        rental_cost +=
                          parseInt(season.days) *
                          price.values[selected_period_index];
                      }
                    });
                  });
                  // Добавляем без сезона
                  // Если diff 0 то получается минус, заплатка
                  let days_without_season =
                    diff > 0 ? diff - seasons_days : 1 - seasons_days;

                  self.set_car.prices.forEach((price) => {
                    if (price.season_id == null) {
                      rental_cost +=
                        days_without_season *
                        price.values[selected_period_index];
                    }
                  });
                  self.booking.rental_cost = Math.round(rental_cost);
                }
              }

              selectPrices();

              if (diff == 0) {
                this.booking.days = 1;
                this.booking.hours_cost = 0;

                this.booking.selected_price = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                setSale();
                this.booking.price_no_sale = Math.round(
                  this.booking.rental_cost / this.booking.days
                );
                // Проверяем доп часы на hours_limit
                if (
                  !this.booking.technical &&
                  this.$store.getters.getCompany.company.hours_limit > 0 &&
                  this.booking.additional_hours >
                    this.$store.getters.getCompany.company.hours_limit
                ) {
                  this.booking.days = 1;
                  this.booking.hours_cost = 0;
                  this.booking.total = this.booking.rental_cost;
                } else {
                  if (
                    this.booking.additional_hours > 0 &&
                    (this.booking.additional_hours *
                      (this.booking.price_hour > 0
                        ? this.booking.price_hour
                        : this.set_car.price_hour) >=
                      this.booking.selected_price ||
                      (this.$store.getters.getCompany.company.hours_limit > 0 &&
                        this.booking.additional_hours >
                          this.$store.getters.getCompany.company.hours_limit))
                  ) {
                    this.booking.days = 1;
                    this.booking.hours_cost = 0;
                    this.booking.total = Math.round(
                      this.booking.days * this.booking.selected_price
                    );

                    this.warningAddOneDay = true;
                    this.warningLessOneDay = true;
                  } else {
                    this.booking.total = Math.round(
                      this.booking.additional_hours * this.booking.price_hour
                    );

                    this.warningAddOneDay = false;
                    this.warningLessOneDay = true;
                  }
                }
              } else if (diff > 0 && diff <= self.period_max_value) {
                if (
                  (this.booking.additional_hours > 0 &&
                    this.booking.additional_hours *
                      (this.booking.price_hour > 0
                        ? this.booking.price_hour
                        : this.set_car.price_hour) >=
                      this.booking.selected_price) ||
                  (this.$store.getters.getCompany.company.hours_limit > 0 &&
                    this.booking.additional_hours >
                      this.$store.getters.getCompany.company.hours_limit)
                ) {
                  this.booking.hours_cost = 0;
                  diff += 1;
                  this.booking.days = diff;
                  checkDiffInc();
                  selectPrices();
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  setSale();
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );
                  this.warningAddOneDay = true;
                  this.warningLessOneDay = false;
                } else {
                  this.booking.days = diff;
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );

                  setSale();
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price +
                      this.booking.additional_hours * this.booking.price_hour
                  );
                  this.warningAddOneDay = false;
                  this.warningLessOneDay = false;
                }
              } else if (diff > self.period_max_value) {
                if (
                  (this.booking.additional_hours > 0 &&
                    this.booking.additional_hours *
                      (this.booking.price_hour > 0
                        ? this.booking.price_hour
                        : this.set_car.price_hour) >=
                      this.booking.selected_price) ||
                  (this.$store.getters.getCompany.company.hours_limit > 0 &&
                    this.booking.additional_hours >
                      this.$store.getters.getCompany.company.hours_limit)
                ) {
                  this.booking.hours_cost = 0;
                  diff += 1;
                  this.booking.days = diff;
                  checkDiffInc();
                  selectPrices();
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  setSale();
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );
                  this.warningAddOneDay = true;
                  this.warningLessOneDay = false;
                } else {
                  this.booking.days = diff;
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  setSale();
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price +
                      this.booking.additional_hours * this.booking.price_hour
                  );
                  this.warningAddOneDay = false;
                  this.warningLessOneDay = false;
                }
              } else {
                if (
                  (this.booking.additional_hours > 0 &&
                    this.booking.additional_hours *
                      (this.booking.price_hour > 0
                        ? this.booking.price_hour
                        : this.set_car.price_hour) >=
                      this.booking.selected_price) ||
                  (this.$store.getters.getCompany.company.hours_limit > 0 &&
                    this.booking.additional_hours >
                      this.$store.getters.getCompany.company.hours_limit)
                ) {
                  this.booking.hours_cost = 0;
                  diff += 1;
                  this.booking.days = diff;
                  checkDiffInc();
                  selectPrices();
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  setSale();
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price
                  );
                  this.warningAddOneDay = true;
                  this.warningLessOneDay = false;
                } else {
                  this.booking.days = diff;
                  this.booking.selected_price = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  setSale();
                  this.booking.price_no_sale = Math.round(
                    this.booking.rental_cost / this.booking.days
                  );
                  this.booking.total = Math.round(
                    this.booking.days * this.booking.selected_price +
                      this.booking.additional_hours * this.booking.price_hour
                  );
                  this.warningAddOneDay = false;
                  this.warningLessOneDay = false;
                }
              }
            }

            setGiftDays();

            if (
              self.booking.delivery > 0 ||
              self.booking.delivery_end > 0 ||
              self.booking.equipment > 0 ||
              self.booking.insurance > 0 ||
              self.booking.clean_payment > 0 ||
              self.booking.fine > 0 ||
              self.booking.add_drivers_cost > 0 ||
              self.booking.other > 0
            ) {
              self.booking.total =
                self.booking.rental_cost +
                self.booking.hours_cost +
                self.booking.delivery +
                self.booking.delivery_end +
                self.booking.equipment +
                self.booking.insurance +
                self.booking.clean_payment +
                self.booking.fine +
                self.booking.add_drivers_cost +
                self.booking.other;
            } else {
              self.booking.total = Math.round(
                self.booking.rental_cost + self.booking.hours_cost
              );
            }
            this.booking.price = this.booking.selected_price;
          }
          this.checkDaysMinLimit(this.booking.days);
          // C тарифом
          if (this.tariff && this.booking.prolongs.length == 0) {
            this.calculateCostWithTariff();
          }
        }
      }
    },
    calculateCostWithTariff() {
      let self = this;
      function setSale() {
        if (self.gift_create == false) {
          if (self.booking.sale > 0) {
            self.booking.selected_price = Math.trunc(
              self.booking.selected_price -
                self.booking.price_no_sale * (self.booking.sale / 100)
            );
          } else if (self.booking.sale_cash > 0) {
            self.booking.selected_price = Math.trunc(
              self.booking.selected_price - self.booking.sale_cash
            );
          }
        }
      }
      function setGiftDays() {
        if (self.gifts && self.gifts.length > 0) {
          self.gifts.forEach(function (gift) {
            if (gift.gift_type == 0) {
              self.booking.rental_cost = Math.trunc(
                self.booking.rental_cost - gift.price * gift.count
              );
            }
          });
        }
      }

      if (this.tariff && (this.tariff.percent > 0 || this.tariff.percent < 0)) {
        this.booking.selected_price = this.booking.price_no_sale;
        setSale();
        this.booking.selected_price =
          this.booking.selected_price +
          this.booking.selected_price * (this.tariff.percent / 100);
        this.booking.rental_cost =
          this.booking.days * this.booking.selected_price;
        setGiftDays();
        this.booking.tariff_id = this.tariff.id;
      } else if (this.tariff && (this.tariff.sum > 0 || this.tariff.sum < 0)) {
        this.booking.selected_price = this.booking.price_no_sale;
        setSale();
        this.booking.selected_price =
          this.booking.selected_price + this.tariff.sum;
        this.booking.rental_cost =
          this.booking.days * this.booking.selected_price;
        setGiftDays();
        this.booking.tariff_id = this.tariff.id;
      } else {
        this.booking.selected_price = this.booking.price_no_sale;
        setSale();
        this.booking.rental_cost =
          this.booking.days * this.booking.selected_price;
        setGiftDays();
      }

      if (
        this.booking.delivery > 0 ||
        this.booking.delivery_end > 0 ||
        this.booking.equipment > 0 ||
        this.booking.other > 0 ||
        this.booking.hours_cost > 0 ||
        this.booking.add_drivers_cost > 0 ||
        this.booking.insurance > 0 ||
        this.booking.clean_payment > 0
      ) {
        this.booking.total = Math.round(
          this.booking.rental_cost +
            this.booking.hours_cost +
            this.booking.delivery +
            this.booking.delivery_end +
            this.booking.equipment +
            this.booking.add_drivers_cost +
            this.booking.insurance +
            this.booking.clean_payment +
            this.booking.other
        );
      } else {
        this.booking.total = Math.round(
          this.booking.rental_cost + this.booking.hours_cost
        );
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.$set(this.configEnd, "minDate", dateStr);
    },
    editSave() {
      if (this.user_role != "guest") {
        let booking = this.booking;
        let self = this;
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/bookings/${booking.id}`,
            {
              bookings: {
                active: booking.active,
                car_name: booking.car_name,
                car_id: booking.car_id,
                car_code: booking.car_code,
                start_date: booking.start_date,
                end_date: booking.end_date,
                client_id: booking.client_id,
                first_name: booking.first_name,
                middle_name: booking.middle_name,
                last_name: booking.last_name,
                sale: booking.sale,
                sale_cash: booking.sale_cash,
                days: booking.days,
                additional_hours: booking.additional_hours,
                rental_cost: booking.rental_cost,
                // Было просто price
                price: booking.selected_price,
                price_id: booking.price_id,
                price_hour: booking.price_hour,
                tariff_id: self.selected_tariff ? self.selected_tariff : null,
                hours_cost: booking.hours_cost,
                hours_cost_end: booking.hours_cost_end,
                delivery: booking.delivery,
                delivery_end: booking.delivery_end,
                insurance: booking.insurance,
                kasko: booking.kasko,
                super_kasko: booking.super_kasko,
                theft: booking.theft,
                no_franchise: booking.no_franchise,
                location_start: booking.location_start,
                location_end: booking.location_end,
                equipment: booking.equipment,
                chair: booking.chair,
                booster: booking.booster,
                chairs_quantity: booking.chairs_quantity,
                boosters_quantity: booking.boosters_quantity,
                navigator: booking.navigator,
                mp3: booking.mp3,
                charger: booking.charger,
                fine: booking.fine,
                other: booking.other,
                total: booking.total,
                deposit: booking.deposit,
                description: booking.description,
                entity: self.client.entity,
                entity_name: self.client.entity_name,
                add_drivers: booking.add_drivers,
                add_drivers_cost: booking.add_drivers_cost,
                manual_editing: !self.auto_calculate,
                clean_start: booking.clean_start,
                clean_end: booking.clean_end,
                clean_payment: booking.clean_payment,
                mileage_cost: booking.mileage_cost,
                gas_start_full: booking.gas_start_full,
                gas_start: booking.gas_start,
                gas_end: booking.gas_end,
                gas_end_full: booking.gas_end_full,
                start_mileage: booking.start_mileage,
                end_mileage: booking.end_mileage,
                booking_mileage: booking.booking_mileage,
                start_price: booking.start_price,
                fio: booking.fio,
                custom_field_1: booking.custom_field_1,
                custom_field_2: booking.custom_field_2,
                custom_field_3: booking.custom_field_3,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editDialog = false;
            // this.booking.price = booking.selected_price
            self.booking.manual_editing = !self.auto_calculate;
            // this.set_car = [];
            this.getCar();
            this.manualEditing = false;
            this.warningAddOneDay = false;
            this.warningLessThenDay = false;
            this.showEditClient = false;
            this.editCar = false;
            this.editDate = false;
            this.start_date = null;
            this.end_date = null;
            this.editExtra = false;
            this.booking.tariff_id = this.selected_tariff
              ? this.selected_tariff
              : null;
            this.selected_tariff = null;
            this.editTariff = false;

            this.manualEditing = false;
            if (booking.add_drivers) {
              booking.add_drivers.forEach((driver) => {
                this.axios
                  .get(`/api/v1/clients/${driver}`, {
                    headers: {
                      Authorization: this.$store.getters.getAuthToken,
                    },
                  })
                  .then((response) => {
                    // console.log(self.add_drivers.filter(driver => driver.id == Number(response.data.client.id)))
                    if (
                      self.add_drivers.filter(
                        (driver) => driver.id == Number(response.data.client.id)
                      ).length == 0
                    ) {
                      self.add_drivers.push(response.data.client);
                    }
                  })
                  .catch((error) => {
                    this.setError(error, this.$t("errors.car_load"));
                    console.log(error);
                  });
              });
              if (booking.add_drivers.length == 0) {
                self.add_drivers = [];
              }
            }
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("bookings.success_update_title"),
              text: this.$t("bookings.success_update_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.update_booking"));
            this.isLoading = false;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.update_booking"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteBooking(booking) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        // Проверяем откуда бронь, если из агрегатора, то не удаляем
        if (booking.vseprokaty_id) {
          this.$swal({
            position: "center",
            showConfirmButton: true,
            confirmButtonText: this.$t("custom.ok"),
            icon: "warning",
            title: this.$t("agregator.no_delete_aggregator"),
            text: this.$t("agregator.no_delete_aggregator_text"),
          });
          return;
        }
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("bookings.remove_booking_warn"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/bookings/${booking.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.$router.push({ name: "Bookings" });
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 2000,
                  icon: "success",
                  title: this.$t("custom.deleted"),
                  text: this.$t("custom.deleted_ok"),
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.remove_booking"))
              );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkFreeCar(car, checked_booking) {
      let result = true;
      if (car && checked_booking) {
        let start_date = checked_booking.start_date;
        let end_date = checked_booking.end_date;
        let cars_bookings = this.bookings.filter((booking) => {
          return booking.car_id === car.id && booking.id != checked_booking.id;
        });
        cars_bookings.forEach((booking) => {
          let checked_start_date = moment(start_date, "DD-MM-YYYY H:mm");
          let checked_end_date = moment(end_date, "DD-MM-YYYY H:mm");
          let booking_start_date = moment(
            booking.start_date,
            "DD-MM-YYYY H:mm"
          ).subtract(
            this.$store.getters.getCompany.company.interval_bookings,
            "hours"
          );
          let booking_end_date = moment(
            booking.end_date,
            "DD-MM-YYYY H:mm"
          ).add(
            this.$store.getters.getCompany.company.interval_bookings,
            "hours"
          );
          if (
            checked_start_date.isBetween(
              booking_start_date,
              booking_end_date,
              "hour"
            ) ||
            checked_end_date.isBetween(
              booking_start_date,
              booking_end_date,
              "hour"
            ) ||
            booking_start_date.isBetween(
              checked_start_date,
              checked_end_date,
              "hour"
            ) ||
            booking_end_date.isBetween(
              checked_start_date,
              checked_end_date,
              "hour"
            )
          ) {
            result = false;
          }
        });
        return result;
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: this.$t("errors.check_car"),
        });
      }
    },
    activateBooking(booking) {
      if (this.user_role != "guest") {
        let self = this;
        function activateBooking() {
          self.isLoading = true;
          self.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  active: true,
                  state: "Активная",
                },
              },
              {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              self.booking.active = true;
              self.booking.state = "Активная";
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: self.$t("bookings.activated_booking"),
              });
            })
            .catch((error) => {
              self.setError(error, self.$t("errors.activate_booking"));
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: self.$t("errors.activate_booking_title"),
                text: self.error,
              });
            })
            .finally(() => (self.isLoading = false));
        }
        if (booking && booking.archive == false) {
          if (this.checkFreeCar(this.set_car, booking)) {
            activateBooking();
          } else {
            this.$swal({
              title: this.$t("errors.booked_title"),
              text: this.$t("errors.booked_text"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: this.$t("bookings.activated_booking_warn"),
              cancelButtonText: this.$t("custom.decline"),
              showCloseButton: true,
            }).then((result) => {
              if (result.value) {
                activateBooking();
              }
            });
          }
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.not_activate_title"),
            text: this.$t("errors.not_activate_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    activeRent() {
      if (this.user_role != "guest") {
        let self = this;
        let booking = this.booking;

        function departure() {
          self.isLoading = true;
          self.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  in_rent: true,
                  navigator: booking.navigator,
                  chair: booking.chair,
                  clean_start: booking.clean_start,
                  clean_end: booking.clean_start,
                  other_equipment: booking.other_equipment,
                  gas_start: booking.gas_start,
                  gas_end: booking.gas_start,
                  gas_start_full: booking.gas_start_full,
                  gas_end_full: booking.gas_start_full,
                  start_worker_id: self.$store.getters.getCurrentUser.id,
                  departure: true,
                  start_mileage: booking.start_mileage,
                  responsible: null,
                  serial_number: nanoid(10),
                },
              },
              {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              }
            )
            .catch((error) => {
              self.setError(error, self.$t("errors.checkout"));
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: self.$t("errors.checkout"),
                text: self.error,
              });
            })
            .finally(() => {
              self.isLoading = false;
              self.booking.in_rent = true;
              self.departureDialog = false;
              self.booking.gas_start = self.gas_start;
              self.booking.gas_end = self.gas_start;
              self.booking.clean_end = self.booking.clean_start;
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: self.$t("bookings.success_checkin"),
              });
            });
        }
        if (this.departure_yes) {
          if (booking.start_mileage == 0) {
            this.$swal({
              title: this.$t("bookings.mileage_warn_title"),
              text: this.$t("bookings.mileage_warn_text"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: this.$t("custom.yes"),
              cancelButtonText: this.$t("custom.no_big"),
              showCloseButton: true,
            }).then((result) => {
              if (result.value) {
                departure();
              }
            });
          } else {
            departure();
          }
        }
        this.departure = false;
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteProlong(prolong) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("prolongs.delete_prolong_warn"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/prolongs/${prolong.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                // let index = self.booking.prolongs.indexOf(prolong => prolong.id == id)
                this.booking.prolongs.splice(0, 1);
                this.booking.price = response.data.price;
                this.booking.selected_price = response.data.price;
                this.booking.days = response.data.days;
                this.booking.rental_cost = response.data.rental_cost;
                this.booking.total = response.data.total;
                this.booking.end_date = response.data.end_date;

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "success",
                  title: this.$t("custom.deleted"),
                  text: this.$t("prolongs.delete_prolong_success"),
                });
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.remove_prolong"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveProlong() {
      console.log("save prolong", this.prolongData)
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        if (
          (this.prolongData.sum > 0 && this.prolongData.type == null) ||
          (this.prolongData.add_time_sum > 0 &&
            this.prolongData.add_time_type == null)
        ) {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.money_type_title"),
            text: this.$t("errors.money_type_text"),
          });
        } else {
          function checkBookingsDates(car_id, checked_booking) {
            let result = false;
            if (car_id && checked_booking) {
              let start_date = checked_booking.start_date;
              let end_date = checked_booking.end_date;
              let cars_bookings = self.bookings.filter((booking) => {
                return (
                  booking.car_id === car_id && booking.id != checked_booking.id
                );
              });

              cars_bookings.forEach((booking) => {
                let checked_start_date = moment(start_date, "DD-MM-YYYY H:mm");
                let checked_end_date = moment(end_date, "DD-MM-YYYY H:mm").add(
                  self.prolongData.days,
                  "days"
                );
                let booking_start_date = moment(
                  booking.start_date,
                  "DD-MM-YYYY H:mm"
                ).subtract(
                  self.$store.getters.getCompany.company.interval_bookings,
                  "hours"
                );
                let booking_end_date = moment(
                  booking.end_date,
                  "DD-MM-YYYY H:mm"
                ).add(
                  self.$store.getters.getCompany.company.interval_bookings,
                  "hours"
                );
                if (
                  checked_start_date.isBetween(
                    booking_start_date,
                    booking_end_date,
                    "hours"
                  ) ||
                  checked_end_date.isBetween(
                    booking_start_date,
                    booking_end_date,
                    "hours"
                  ) ||
                  booking_start_date.isBetween(
                    checked_start_date,
                    checked_end_date,
                    "hours"
                  ) ||
                  booking_end_date.isBetween(
                    checked_start_date,
                    checked_end_date,
                    "hours"
                  )
                ) {
                  result = true;
                }
              });
              return result;
            }
          }

          // Для создания продления
          let old_price = this.booking.price;
          let last_date = this.booking.end_date;
          if (
            this.prolongData.sum > 0 &&
            this.prolongData.price >= 0 && this.prolongData.price >= 0 && this.prolongData.price != null && this.prolongData.price != "" &&
            this.prolongData.days > 0
          ) {
            if (checkBookingsDates(this.set_car.id, this.booking)) {
              self
                .$swal({
                  title: self.$t("bookings.check_bookings_days"),
                  text: self.$t("bookings.check_bookings_days_text"),
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: self.$t("custom.yes"),
                  cancelButtonText: self.$t("custom.no"),
                  showCloseButton: true,
                })
                .then((result) => {
                  if (result.value) {
                    this.isLoading = true;
                    let booking = this.booking;
                    this.axios
                      .post(
                        `/api/v1/save_prolong`,
                        {
                          booking_id: booking.id,
                          car_id: booking.car_id,
                          price: self.prolongData.price,
                          days: self.booking.days + self.prolongData.days,
                          start_days: self.booking.days,
                          rental_cost:
                            self.prolongData.days * self.prolongData.price,
                          total: self.prolongData.days * self.prolongData.price,
                          end_date: moment(
                            self.booking.end_date,
                            "DD-MM-YYYY H:mm"
                          )
                            .add(self.prolongData.days, "days")
                            .format("DD-MM-YYYY H:mm"),
                          last_date: last_date,
                          old_price: old_price,
                          prolong_data: self.prolongData,
                        },
                        {
                          headers: {
                            Authorization: self.$store.getters.getAuthToken,
                          },
                        }
                      )
                      .then((response) => {
                        // self.editSave();
                        self.isLoading = true;
                        this.booking.price = response.data.price;
                        this.booking.selected_price = response.data.price;
                        this.booking.days = response.data.days;
                        this.booking.rental_cost = response.data.rental_cost;
                        this.booking.total = response.data.total;
                        this.booking.end_date = response.data.end_date;
                        this.booking.counts = response.data.counts;
                        this.booking.prolongs = response.data.prolongs;
                        // response.data.counts.forEach(count => {
                        //   self.booking.counts.push(count);
                        // });
                        this.prolongData = {
                          days: null,
                          price: null,
                          additional_hours: null,
                          sum: null,
                          icon: null,
                          add_time_sum: null,
                          add_time_icon: null,
                        };

                        this.prolongDialog = false;
                        this.$swal({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 5000,
                          icon: "success",
                          title: this.$t("bookings.prolong_title"),
                          text: this.$t("bookings.prolong_text"),
                        });
                      })
                      .catch((error) => {
                        self.setError(error, self.$t("errors.prolong_title"));
                        self.$swal({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 5000,
                          icon: "error",
                          title: self.$t("errors.prolong_title"),
                          text: self.error,
                        });
                      })
                      .finally(() => (self.isLoading = false));
                  }
                });
            } else {
              this.isLoading = true;
              let booking = this.booking;
              this.axios
                .post(
                  `/api/v1/save_prolong`,
                  {
                    booking_id: booking.id,
                    car_id: booking.car_id,
                    price: self.prolongData.price,
                    days: self.booking.days + self.prolongData.days,
                    start_days: self.booking.days,
                    rental_cost: self.prolongData.days * self.prolongData.price,
                    total: self.prolongData.days * self.prolongData.price,
                    end_date: moment(self.booking.end_date, "DD-MM-YYYY H:mm")
                      .add(self.prolongData.days, "days")
                      .format("DD-MM-YYYY H:mm"),
                    last_date: last_date,
                    old_price: old_price,
                    prolong_data: self.prolongData,
                  },
                  {
                    headers: {
                      Authorization: self.$store.getters.getAuthToken,
                    },
                  }
                )
                .then((response) => {
                  // self.editSave();
                  // response.data.forEach(count => {
                  //   self.booking.counts.push(count);
                  // });
                  this.booking.price = response.data.price;
                  this.booking.selected_price = response.data.price;
                  this.booking.days = response.data.days;
                  this.booking.rental_cost = response.data.rental_cost;
                  this.booking.total = response.data.total;
                  this.booking.end_date = response.data.end_date;
                  this.booking.counts = response.data.counts;
                  this.booking.prolongs = response.data.prolongs;

                  this.prolongData = {
                    days: null,
                    price: null,
                    additional_hours: null,
                    sum: null,
                    icon: null,
                    add_time_sum: null,
                    add_time_icon: null,
                  };

                  this.prolongDialog = false;
                  this.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 5000,
                    icon: "success",
                    title: this.$t("bookings.prolong_title"),
                    text: this.$t("bookings.prolong_text"),
                  });
                })
                .catch((error) => {
                  self.setError(error, self.$t("errors.prolong_title"));
                  self.$swal({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 5000,
                    icon: "error",
                    title: self.$t("errors.prolong_title"),
                    text: self.error,
                  });
                })
                .finally(() => (self.isLoading = false));
            }
          } else {
            if (this.prolongData.price >= 0 && this.prolongData.price >= 0 && this.prolongData.price != null && this.prolongData.price != "" && this.prolongData.days > 0) {
              this.$swal({
                title: this.$t("bookings.prolong_zero_title"),
                text: this.$t("bookings.prolong_zero_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: self.$t("custom.yes"),
                cancelButtonText: this.$t("custom.no"),
                showCloseButton: true,
              }).then((result) => {
                if (result.value) {
                  if (checkBookingsDates(self.set_car.id, self.booking)) {
                    self
                      .$swal({
                        title: self.$t("bookings.prolong_reserved_title"),
                        text: self.$t("bookings.prolong_reserved_text"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: self.$t("custom.yes"),
                        cancelButtonText: self.$t("custom.no"),
                        showCloseButton: true,
                      })
                      .then((result) => {
                        if (result.value) {
                          let booking = self.booking;
                          self.axios
                            .post(
                              `/api/v1/save_prolong`,
                              {
                                booking_id: booking.id,
                                car_id: booking.car_id,
                                price: self.prolongData.price,
                                days: self.booking.days + self.prolongData.days,
                                start_days: self.booking.days,
                                rental_cost:
                                  self.prolongData.days *
                                  self.prolongData.price,
                                total:
                                  self.prolongData.days *
                                  self.prolongData.price,
                                end_date: moment(
                                  self.booking.end_date,
                                  "DD-MM-YYYY H:mm"
                                )
                                  .add(self.prolongData.days, "days")
                                  .format("DD-MM-YYYY H:mm"),
                                last_date: last_date,
                                old_price: old_price,
                                prolong_data: self.prolongData,
                              },
                              {
                                headers: {
                                  Authorization:
                                    self.$store.getters.getAuthToken,
                                },
                              }
                            )
                            .then((response) => {
                              // self.editSave();
                              this.booking.price = response.data.price;
                              this.booking.selected_price = response.data.price;
                              this.booking.days = response.data.days;
                              this.booking.rental_cost =
                                response.data.rental_cost;
                              this.booking.total = response.data.total;
                              this.booking.end_date = response.data.end_date;
                              this.booking.counts = response.data.counts;
                              this.booking.prolongs = response.data.prolongs;

                              self.prolongData = {
                                days: null,
                                price: null,
                                additional_hours: null,
                                sum: null,
                                icon: null,
                                add_time_sum: null,
                                add_time_icon: null,
                              };

                              self.prolongDialog = false;
                              self.$swal({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 5000,
                                icon: "success",
                                title: self.$t("bookings.prolong_title"),
                                text: self.$t("bookings.prolong_text_short"),
                              });
                            })
                            .catch((error) => {
                              self.setError(
                                error,
                                self.$t("errors.prolong_title")
                              );
                              self.$swal({
                                toast: true,
                                position: "top-end",
                                showConfirmButton: false,
                                timer: 5000,
                                icon: "error",
                                title: self.$t("errors.prolong_title"),
                                text: self.error,
                              });
                            })
                            .finally(() => (self.isLoading = false));
                        }
                      });
                  } else {
                    let booking = self.booking;

                    self.axios
                      .post(
                        `/api/v1/save_prolong`,
                        {
                          booking_id: booking.id,
                          car_id: booking.car_id,
                          price: self.prolongData.price,
                          days: self.booking.days + self.prolongData.days,
                          start_days: self.booking.days,
                          rental_cost:
                            self.prolongData.days * self.prolongData.price,
                          total: self.prolongData.days * self.prolongData.price,
                          end_date: moment(
                            self.booking.end_date,
                            "DD-MM-YYYY H:mm"
                          )
                            .add(self.prolongData.days, "days")
                            .format("DD-MM-YYYY H:mm"),
                          last_date: last_date,
                          old_price: old_price,
                          prolong_data: self.prolongData,
                        },
                        {
                          headers: {
                            Authorization: self.$store.getters.getAuthToken,
                          },
                        }
                      )
                      .then((response) => {
                        // self.editSave();
                        this.booking.price = response.data.price;
                        this.booking.selected_price = response.data.price;
                        this.booking.days = response.data.days;
                        this.booking.rental_cost = response.data.rental_cost;
                        this.booking.total = response.data.total;
                        this.booking.end_date = response.data.end_date;
                        this.booking.counts = response.data.counts;
                        this.booking.prolongs = response.data.prolongs;
                        self.prolongData = {
                          days: null,
                          price: null,
                          additional_hours: null,
                          sum: null,
                          icon: null,
                          add_time_sum: null,
                          add_time_icon: null,
                        };

                        self.prolongDialog = false;
                        self.$swal({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 5000,
                          icon: "success",
                          title: self.$t("bookings.prolong_title"),
                          text: self.$t("bookings.prolong_no_money_text"),
                        });
                      })
                      .catch((error) => {
                        self.setError(error, self.$t("errors.prolong_title"));
                        self.$swal({
                          toast: true,
                          position: "top-end",
                          showConfirmButton: false,
                          timer: 5000,
                          icon: "error",
                          title: self.$t("errors.prolong_title"),
                          text: self.error,
                        });
                      })
                      .finally(() => (self.isLoading = false));
                  }
                }
              });
            } else {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                icon: "warning",
                title: this.$t("custom.no_data"),
                text: this.$t("bookings.prolong_no_data_text"),
              });
            }
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkDaysMinLimit(days) {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        !this.booking.technical &&
        this.$store.getters.getCompany.company.min_days &&
        days < this.$store.getters.getCompany.company.min_days
      ) {
        this.$swal({
          showConfirmButton: true,
          showCloseButton: true,
          timer: 4000,
          timerProgressBar: true,
          icon: "warning",
          title: this.$t("bookings.booking_very_short_title"),
          text: this.$t("bookings.booking_very_short_text", { days: days }),
        });
      }
    },
    checkMileage() {
      return this.set_car ? this.set_car.mileage : 0;
    },
    loadClients() {
      this.isLoadingClients = true;
      this.axios
        .post(
          `/api/v1/index_new_booking_page`,
          {
            search: this.searchClient,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          this.clients = response.data.query ? response.data.query : (response.data ? response.data : []);
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                (client.entity_phone
                  ? client.entity_phone.slice(client.entity_phone.length - 4)
                  : "");
            }
          });
        })
        .catch((error) => {
          this.isLoadingClients = false;
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
    checkAddPayment(type) {
      if (this.selected_add_services.find((item) => item == type)) {
        return true;
      } else {
        return false;
      }
    },
    inHome() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        let booking = this.booking;
        // this.arrival_payments.deposit.sum = this.returnDeposit()
        // Поменял залог на ноль, если не возвращать
        if (!self.giveNullDeposit) {
          // Если частичный возврат залога, то берем проставленную сумму, если нет, то из брони
          if (!this.partReturnDeposit) {
            this.arrival_payments.deposit.sum = this.booking.deposit;
          }
        } else {
          this.arrival_payments.deposit.sum = 0;
        }
        if (
          self.arrival_payments.mileage.sum === "" ||
          self.arrival_payments.damage.sum === "" ||
          self.arrival_payments.fine.sum === "" ||
          self.arrival_payments.insurance.sum === "" ||
          self.arrival_payments.add_drivers_cost.sum === "" ||
          self.arrival_payments.gas.sum === "" ||
          self.arrival_payments.clean.sum === "" ||
          self.arrival_payments.rent.sum === "" ||
          self.arrival_payments.deposit.sum === "" ||
          self.arrival_payments.delivery.sum === "" ||
          self.arrival_payments.delivery_end.sum === "" ||
          self.arrival_payments.additional_hours.sum === "" ||
          self.arrival_payments.equipment.sum === "" ||
          self.arrival_payments.other.sum === ""
        ) {
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: self.$t("errors.empty_value_title"),
            text: self.$t("errors.empty_value_text"),
          });
        } else if (
          (self.arrival_payments.mileage.sum != 0 &&
            self.arrival_payments.mileage.type == null) ||
          (self.arrival_payments.damage.sum != 0 &&
            self.arrival_payments.damage.type == null) ||
          (self.arrival_payments.fine.sum != 0 &&
            self.arrival_payments.fine.type == null) ||
          (self.arrival_payments.insurance.sum != 0 &&
            self.arrival_payments.insurance.type == null) ||
          (self.arrival_payments.add_drivers_cost.sum != 0 &&
            self.arrival_payments.add_drivers_cost.type == null) ||
          (self.arrival_payments.gas.sum != 0 &&
            self.arrival_payments.gas.type == null) ||
          (self.arrival_payments.clean.sum != 0 &&
            self.arrival_payments.clean.type == null) ||
          (self.arrival_payments.rent.sum != 0 &&
            self.arrival_payments.rent.type == null) ||
          (self.arrival_payments.deposit.sum != 0 &&
            self.arrival_payments.deposit.type == null) ||
          (self.arrival_payments.delivery.sum != 0 &&
            self.arrival_payments.delivery.type == null) ||
          (self.arrival_payments.delivery_end.sum != 0 &&
            self.arrival_payments.delivery_end.type == null) ||
          (self.arrival_payments.additional_hours.sum != 0 &&
            self.arrival_payments.additional_hours.type == null) ||
          (self.arrival_payments.equipment.sum != 0 &&
            self.arrival_payments.equipment.type == null) ||
          (self.arrival_payments.other.sum != 0 &&
            self.arrival_payments.other.type == null)
        ) {
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: self.$t("errors.type_payment_title"),
            text: self.$t("errors.type_payment_text"),
          });
        } else {
          function arrival(giveNullDeposit) {
            self.isLoading = true;

            let booking_data = {
              end_date: booking.end_date,
              clean_end: booking.clean_end,
              gas_end_full: booking.gas_end_full,
              gas_end: booking.gas_end,
              in_rent: false,
              active: false,
              ride: true,
              accepted: true,
              delivery:
                booking.delivery > 0
                  ? booking.delivery + self.arrival_payments.delivery.sum
                  : self.arrival_payments.delivery.sum,
              delivery_end:
                booking.delivery_end > 0
                  ? booking.delivery_end +
                    self.arrival_payments.delivery_end.sum
                  : self.arrival_payments.delivery_end.sum,
              hours_cost_end: self.arrival_payments.additional_hours.sum,
              mileage_cost:
                booking.mileage > 0
                  ? booking.mileage + self.arrival_payments.mileage.sum
                  : self.arrival_payments.mileage.sum,
              clean_payment:
                booking.clean_payment > 0
                  ? booking.clean_payment + self.arrival_payments.clean.sum
                  : self.arrival_payments.clean.sum,
              gas:
                booking.gas > 0
                  ? booking.gas + self.arrival_payments.gas.sum
                  : self.arrival_payments.gas.sum,
              fine:
                booking.fine > 0
                  ? booking.fine + self.arrival_payments.fine.sum
                  : self.arrival_payments.fine.sum,
              damage:
                booking.damage > 0
                  ? booking.damage + self.arrival_payments.damage.sum
                  : self.arrival_payments.damage.sum,
              insurance:
                booking.insurance > 0
                  ? booking.insurance + self.arrival_payments.insurance.sum
                  : self.arrival_payments.insurance.sum,
              add_drivers_cost:
                booking.add_drivers_cost > 0
                  ? booking.add_drivers_cost +
                    self.arrival_payments.add_drivers_cost.sum
                  : self.arrival_payments.add_drivers_cost.sum,
              other_end: self.arrival_payments.other.sum,
              end_worker_id: self.$store.getters.getCurrentUser.id,
              end_mileage: booking.end_mileage,
              booking_mileage:
                booking.start_mileage > 0 && booking.end_mileage > 0
                  ? booking.end_mileage - booking.start_mileage
                  : 0,
              responsible: null,
              part_return_deposit: self.partReturnDeposit,
            };

            // Проверка клиента
            let client_data = null;
            if (self.showProblems) {
              client_data = {
                category: self.client.category,
                problems: true,
                problems_description: self.client.problems_description,
                send_review_reminder: false,
              };
            } else {
              client_data = {
                category: "Постоянный",
                problems: false,
                problems_description: null,
                send_review_reminder: true,
              };

              // send reminder review email
              if (
                self.client.email &&
                self.isValidEmail(self.client.email) &&
                !self.dont_send_review_reminder &&
                !self.client.send_review_email &&
                self.client.category != "Серый" &&
                self.client.category != "Чёрный"
              ) {
                client_data.send_review_reminder = true;
              } else {
                client_data.send_review_reminder = false;
              }
            }

            // Состояние машины
            let car_data = {};
            if (booking.end_mileage > 0) {
              // check state
              let finded_car = self.set_car;
              let check_state = false;
              if (
                (finded_car &&
                  booking.end_mileage - finded_car.oil_engine >
                    finded_car.oil_engine_km - 1000) ||
                booking.end_mileage - finded_car.grm > finded_car.grm_km - 5000
              ) {
                check_state = true;
              }

              car_data = {
                mileage: booking.end_mileage,
                state: check_state ? 6 : finded_car.state,
                store_place: finded_car.store_place,
              };
            }

            // Мойка и бак в автомобиле
            car_data["tank_state"] = booking.gas_end_full;
            car_data["clean_state"] = booking.clean_end;

            let all_counts = [];

            // Мойка
            let clean_count = null;
            if (self.arrival_payments.clean.sum != 0) {
              clean_count = {
                group: "Доплата за мойку",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_clean_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.clean.sum,
                cash:
                  self.arrival_payments.clean.type == "Наличные" ? true : false,
                cashless:
                  self.arrival_payments.clean.type == "Терминал" ? true : false,
                cash_card:
                  self.arrival_payments.clean.type == "На карту" ? true : false,
                entity:
                  self.arrival_payments.clean.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(clean_count);
            }
            // Доставка
            let delivery_count = null;
            if (self.arrival_payments.delivery.sum != 0) {
              delivery_count = {
                group: "Доплата за доставку",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_delivery_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.delivery.sum,
                cash:
                  self.arrival_payments.delivery.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.delivery.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.delivery.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.delivery.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(delivery_count);
            }
            // Приём
            let delivery_end_count = null;
            if (self.arrival_payments.delivery_end.sum != 0) {
              delivery_end_count = {
                group: "Доплата за приём",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_checkout_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.delivery_end.sum,
                cash:
                  self.arrival_payments.delivery_end.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.delivery_end.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.delivery_end.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.delivery_end.type == "Р/с"
                    ? true
                    : false,
                booking_id: self.booking.id,
              };
              all_counts.push(delivery_end_count);
            }
            // Дополнительное время
            let additional_hours_count = null;
            if (self.arrival_payments.additional_hours.sum != 0) {
              additional_hours_count = {
                group: "Доплата за дополнительное время",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_addhours_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.additional_hours.sum,
                cash:
                  self.arrival_payments.additional_hours.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.additional_hours.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.additional_hours.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.additional_hours.type == "Р/с"
                    ? true
                    : false,
                booking_id: self.booking.id,
              };
              all_counts.push(additional_hours_count);
            }
            // Превышение пробега
            let mileage_count = null;
            if (self.arrival_payments.mileage.sum != 0) {
              mileage_count = {
                group: "Доплата за превышение пробега",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_mileage_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.mileage.sum,
                cash:
                  self.arrival_payments.mileage.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.mileage.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.mileage.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.mileage.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(mileage_count);
            }
            // Аренда
            let rent_count = null;
            if (self.arrival_payments.rent.sum != 0) {
              rent_count = {
                group: "Оплата аренды",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_rent_checkout_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.rent.sum,
                cash:
                  self.arrival_payments.rent.type == "Наличные" ? true : false,
                cashless:
                  self.arrival_payments.rent.type == "Терминал" ? true : false,
                cash_card:
                  self.arrival_payments.rent.type == "На карту" ? true : false,
                entity: self.arrival_payments.rent.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(rent_count);
            }
            // Топливо
            let gas_count = null;
            if (self.arrival_payments.gas.sum != 0) {
              gas_count = {
                group: "Доплата за топливо",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_gas_checkout_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.gas.sum,
                cash:
                  self.arrival_payments.gas.type == "Наличные" ? true : false,
                cashless:
                  self.arrival_payments.gas.type == "Терминал" ? true : false,
                cash_card:
                  self.arrival_payments.gas.type == "На карту" ? true : false,
                entity: self.arrival_payments.gas.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(gas_count);
            }
            // Оборудование
            let equipment_count = null;
            if (self.arrival_payments.equipment.sum != 0) {
              equipment_count = {
                group: "Доплата за оборудование",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t(
                  "bookings.desc_equipment_checkout_payment",
                  { id: self.booking.id }
                ),
                sum: self.arrival_payments.equipment.sum,
                cash:
                  self.arrival_payments.equipment.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.equipment.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.equipment.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.equipment.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(equipment_count);
            }
            // Страховка
            let insurance_count = null;
            if (self.arrival_payments.insurance.sum != 0) {
              insurance_count = {
                group: "Доплата за страховку",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t(
                  "bookings.desc_insuranse_checkout_payment",
                  { id: self.booking.id }
                ),
                sum: self.arrival_payments.insurance.sum,
                cash:
                  self.arrival_payments.insurance.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.insurance.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.insurance.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.insurance.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(insurance_count);
            }
            // Доп водители
            let add_drivers_cost_count = null;
            if (self.arrival_payments.add_drivers_cost.sum != 0) {
              add_drivers_cost_count = {
                group: "Доплата за доп водителей",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_drivers_checkout_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.add_drivers_cost.sum,
                cash:
                  self.arrival_payments.add_drivers_cost.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.add_drivers_cost.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.add_drivers_cost.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.add_drivers_cost.type == "Р/с"
                    ? true
                    : false,
                booking_id: self.booking.id,
              };
              all_counts.push(add_drivers_cost_count);
            }
            // Штрафы
            let fine_count = null;
            if (self.arrival_payments.fine.sum != 0) {
              fine_count = {
                group: "Доплата за штрафы",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_fines_checkout_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.fine.sum,
                cash:
                  self.arrival_payments.fine.type == "Наличные" ? true : false,
                cashless:
                  self.arrival_payments.fine.type == "Терминал" ? true : false,
                cash_card:
                  self.arrival_payments.fine.type == "На карту" ? true : false,
                entity: self.arrival_payments.fine.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(fine_count);
            }
            // Повреждения
            let damage_count = null;
            if (self.arrival_payments.damage.sum != 0) {
              damage_count = {
                group: "Доплата за повреждения",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_damages_checkout_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.damage.sum,
                cash:
                  self.arrival_payments.damage.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.damage.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.damage.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.damage.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(damage_count);
            }
            // Другое
            let other_count = null;
            if (self.arrival_payments.other.sum != 0) {
              other_count = {
                group: "Доплата за другое",
                car_id: self.booking.car_id,
                operation: true,
                description: self.$t("bookings.desc_other_checkout_payment", {
                  id: self.booking.id,
                }),
                sum: self.arrival_payments.other.sum,
                cash:
                  self.arrival_payments.other.type == "Наличные" ? true : false,
                cashless:
                  self.arrival_payments.other.type == "Терминал" ? true : false,
                cash_card:
                  self.arrival_payments.other.type == "На карту" ? true : false,
                entity:
                  self.arrival_payments.other.type == "Р/с" ? true : false,
                booking_id: self.booking.id,
              };
              all_counts.push(other_count);
            }

            // Платеж за доп услуги
            function calcAddPayment() {
              let cash = 0;
              let cashless = 0;
              let cash_card = 0;
              let entity = 0;
              // Наличные
              if (self.arrival_payments.rent.type == "Наличные") {
                cash += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "Наличные") {
                cash += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "Наличные") {
                cash += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.additional_hours.type == "Наличные") {
                cash += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "Наличные") {
                cash += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "Наличные") {
                cash += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "Наличные") {
                cash += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "Наличные") {
                cash += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "Наличные") {
                cash += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "Наличные") {
                cash += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "Наличные") {
                cash += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "Наличные") {
                cash += self.arrival_payments.other.sum;
              }
              // 'Терминал'
              if (self.arrival_payments.rent.type == "Терминал") {
                cashless += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "Терминал") {
                cashless += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "Терминал") {
                cashless += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.additional_hours.type == "Терминал") {
                cashless += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "Терминал") {
                cashless += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "Терминал") {
                cashless += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "Терминал") {
                cashless += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "Терминал") {
                cashless += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "Терминал") {
                cashless += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "Терминал") {
                cashless += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "Терминал") {
                cashless += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "Терминал") {
                cashless += self.arrival_payments.other.sum;
              }
              // На карту
              if (self.arrival_payments.rent.type == "На карту") {
                cash_card += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "На карту") {
                cash_card += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "На карту") {
                cash_card += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.additional_hours.type == "На карту") {
                cash_card += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "На карту") {
                cash_card += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "На карту") {
                cash_card += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "На карту") {
                cash_card += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "На карту") {
                cash_card += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "На карту") {
                cash_card += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "На карту") {
                cash_card += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "На карту") {
                cash_card += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "На карту") {
                cash_card += self.arrival_payments.other.sum;
              }
              // 'Р/с'
              if (self.arrival_payments.rent.type == "Р/с") {
                entity += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "Р/с") {
                entity += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "Р/с") {
                entity += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.additional_hours.type == "Р/с") {
                entity += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "Р/с") {
                entity += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "Р/с") {
                entity += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "Р/с") {
                entity += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "Р/с") {
                entity += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "Р/с") {
                entity += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "Р/с") {
                entity += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "Р/с") {
                entity += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "Р/с") {
                entity += self.arrival_payments.other.sum;
              }

              return {
                cash: cash,
                cashless: cashless,
                cash_card: cash_card,
                entity: entity,
              };
            }
            let add_payment = null;
            if (
              calcAddPayment().cash != 0 ||
              calcAddPayment().cashless != 0 ||
              calcAddPayment().cash_card != 0 ||
              calcAddPayment().entity != 0
            ) {
              add_payment = {
                cash: calcAddPayment().cash != 0 ? calcAddPayment().cash : 0,
                cashless:
                  calcAddPayment().cashless != 0
                    ? calcAddPayment().cashless
                    : 0,
                cash_card:
                  calcAddPayment().cash_card != 0
                    ? calcAddPayment().cash_card
                    : 0,
                entity:
                  calcAddPayment().entity != 0 ? calcAddPayment().entity : 0,
              };
            }

            // Возврат залога
            let deposit_count = null;
            let deposit_payment = null;
            if (!giveNullDeposit && self.arrival_payments.deposit.sum != 0) {
              deposit_count = {
                group: "Залог",
                operation: false,
                description: self.$t("bookings.desc_other_checkout_payment", {
                  id: booking.id,
                }),
                sum: self.arrival_payments.deposit.sum,
                cash:
                  self.arrival_payments.deposit.type == "Наличные"
                    ? true
                    : false,
                cashless:
                  self.arrival_payments.deposit.type == "Терминал"
                    ? true
                    : false,
                cash_card:
                  self.arrival_payments.deposit.type == "На карту"
                    ? true
                    : false,
                entity:
                  self.arrival_payments.deposit.type == "Р/с" ? true : false,
                car_id: booking.car_id,
                booking_id: self.booking.id,
              };
              // self.booking.counts = self.booking.counts.concat(deposit_count)
              all_counts.push(deposit_count);
            }

            // Считаем на сколько изменять кассы
            function calcKassChanges() {
              let cash = 0;
              let cashless = 0;
              let cash_card = 0;
              let entity = 0;

              if (giveNullDeposit) {
                self.arrival_payments.deposit.sum = 0;
              }
              // Наличные
              if (self.arrival_payments.rent.type == "Наличные") {
                cash += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "Наличные") {
                cash += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "Наличные") {
                cash += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.delivery_end.type == "Наличные") {
                cash += self.arrival_payments.delivery_end.sum;
              }
              if (self.arrival_payments.additional_hours.type == "Наличные") {
                cash += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "Наличные") {
                cash += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "Наличные") {
                cash += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "Наличные") {
                cash += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "Наличные") {
                cash += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "Наличные") {
                cash += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "Наличные") {
                cash += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "Наличные") {
                cash += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "Наличные") {
                cash += self.arrival_payments.other.sum;
              }
              // залог с минусом
              if (self.arrival_payments.deposit.type == "Наличные") {
                cash -= self.arrival_payments.deposit.sum;
              }
              // 'Терминал'
              if (self.arrival_payments.rent.type == "Терминал") {
                cashless += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "Терминал") {
                cashless += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "Терминал") {
                cashless += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.delivery_end.type == "Терминал") {
                cashless += self.arrival_payments.delivery_end.sum;
              }
              if (self.arrival_payments.additional_hours.type == "Терминал") {
                cashless += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "Терминал") {
                cashless += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "Терминал") {
                cashless += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "Терминал") {
                cashless += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "Терминал") {
                cashless += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "Терминал") {
                cashless += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "Терминал") {
                cashless += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "Терминал") {
                cashless += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "Терминал") {
                cashless += self.arrival_payments.other.sum;
              }
              // залог с минусом
              if (self.arrival_payments.deposit.type == "Терминал") {
                cashless -= self.arrival_payments.deposit.sum;
              }
              // На карту
              if (self.arrival_payments.rent.type == "На карту") {
                cash_card += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "На карту") {
                cash_card += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "На карту") {
                cash_card += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.delivery_end.type == "На карту") {
                cash_card += self.arrival_payments.delivery_end.sum;
              }
              if (self.arrival_payments.additional_hours.type == "На карту") {
                cash_card += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "На карту") {
                cash_card += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "На карту") {
                cash_card += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "На карту") {
                cash_card += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "На карту") {
                cash_card += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "На карту") {
                cash_card += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "На карту") {
                cash_card += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "На карту") {
                cash_card += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "На карту") {
                cash_card += self.arrival_payments.other.sum;
              }
              // залог с минусом
              if (self.arrival_payments.deposit.type == "На карту") {
                cash_card -= self.arrival_payments.deposit.sum;
              }
              // 'Р/с'
              if (self.arrival_payments.rent.type == "Р/с") {
                entity += self.arrival_payments.rent.sum;
              }
              if (self.arrival_payments.clean.type == "Р/с") {
                entity += self.arrival_payments.clean.sum;
              }
              if (self.arrival_payments.delivery.type == "Р/с") {
                entity += self.arrival_payments.delivery.sum;
              }
              if (self.arrival_payments.delivery_end.type == "Р/с") {
                entity += self.arrival_payments.delivery_end.sum;
              }
              if (self.arrival_payments.additional_hours.type == "Р/с") {
                entity += self.arrival_payments.additional_hours.sum;
              }
              if (self.arrival_payments.mileage.type == "Р/с") {
                entity += self.arrival_payments.mileage.sum;
              }
              if (self.arrival_payments.insurance.type == "Р/с") {
                entity += self.arrival_payments.insurance.sum;
              }
              if (self.arrival_payments.add_drivers_cost.type == "Р/с") {
                entity += self.arrival_payments.add_drivers_cost.sum;
              }
              if (self.arrival_payments.gas.type == "Р/с") {
                entity += self.arrival_payments.gas.sum;
              }
              if (self.arrival_payments.equipment.type == "Р/с") {
                entity += self.arrival_payments.equipment.sum;
              }
              if (self.arrival_payments.fine.type == "Р/с") {
                entity += self.arrival_payments.fine.sum;
              }
              if (self.arrival_payments.damage.type == "Р/с") {
                entity += self.arrival_payments.damage.sum;
              }
              if (self.arrival_payments.other.type == "Р/с") {
                entity += self.arrival_payments.other.sum;
              }
              // залог с минусом
              if (self.arrival_payments.deposit.type == "Р/с") {
                entity -= self.arrival_payments.deposit.sum;
              }

              return {
                cash: cash,
                cashless: cashless,
                cash_card: cash_card,
                entity: entity,
              };
            }
            let company_cashless = null;
            let company_cash = null;
            // изменяем кассу компании
            // Безнал
            if (
              calcKassChanges().cashless != 0 ||
              calcKassChanges().entity != 0
            ) {
              company_cashless =
                calcKassChanges().cashless + calcKassChanges().entity;
            }
            // С карты на карту
            if (calcKassChanges().cash_card != 0) {
              company_cash = calcKassChanges().cash_card;
            }

            // Изменяем кассу сотрудника
            let workers_cashbox = null;
            if (calcKassChanges().cash != 0) {
              workers_cashbox = {
                workers_id: self.$store.getters.currentUserId,
                cash: calcKassChanges().cash,
              };
            }

            // Отправляем на сервер
            self.axios
              .post(
                "/api/v1/in_home",
                {
                  client_id: self.client.id,
                  booking_id: booking.id,
                  car_id: booking.car_id,
                  booking_data: booking_data,
                  client_data: client_data,
                  car_data: car_data,
                  clean_count: clean_count,
                  delivery_count: delivery_count,
                  delivery_end_count: delivery_end_count,
                  additional_hours_count: additional_hours_count,
                  mileage_count: mileage_count,
                  rent_count: rent_count,
                  gas_count: gas_count,
                  equipment_count: equipment_count,
                  insurance_count: insurance_count,
                  add_drivers_cost_count: add_drivers_cost_count,
                  fine_count: fine_count,
                  damage_count: damage_count,
                  other_count: other_count,
                  add_payment: add_payment,
                  deposit_count: deposit_count,
                  deposit_payment: deposit_payment,
                  company_cashless: company_cashless,
                  company_cash: company_cash,
                  workers_cashbox: workers_cashbox,
                },
                {
                  headers: {
                    Authorization: self.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                // Обновляем фронт
                (self.booking.end_date = booking.end_date),
                  (self.booking.clean_end = booking.clean_end),
                  (self.booking.gas_start = booking.gas_start),
                  (self.booking.gas_end_full = booking.gas_end_full),
                  (self.booking.gas_end = booking.gas_end),
                  (self.booking.in_rent = false),
                  (self.booking.active = false),
                  (self.booking.ride = true),
                  (self.booking.accepted = true),
                  (self.booking.delivery += self.arrival_payments.delivery.sum),
                  (self.booking.delivery_end +=
                    self.arrival_payments.delivery_end.sum),
                  (self.booking.hours_cost_end +=
                    self.arrival_payments.additional_hours.sum),
                  (self.booking.mileage_cost +=
                    self.arrival_payments.mileage.sum),
                  (self.booking.clean_payment +=
                    self.arrival_payments.clean.sum),
                  (self.booking.gas = self.arrival_payments.gas.sum),
                  (self.booking.fine += self.arrival_payments.fine.sum),
                  (self.booking.damage = self.arrival_payments.damage.sum),
                  (self.booking.insurance +=
                    self.arrival_payments.insurance.sum),
                  (self.booking.add_drivers_cost +=
                    self.arrival_payments.add_drivers_cost.sum),
                  (self.booking.other_end = self.arrival_payments.other.sum),
                  (self.booking.end_worker_id =
                    self.$store.getters.getCurrentUser.id),
                  (self.booking.end_mileage = booking.end_mileage),
                  (self.booking.booking_mileage =
                    booking.start_mileage > 0 && booking.end_mileage > 0
                      ? booking.end_mileage - booking.start_mileage
                      : 0);
                self.booking.responsible = null;
                self.booking.state = "Отъездила";
                self.showArrivalPayments = false;
                self.showProblems = false;
                self.dont_send_review_reminder = false;
                self.showPartRejection = false;
                self.showCleanPaid = false;
                self.giveFullDeposit = true;
                self.giveNullDeposit = false;
                self.arrivalDialog = false;
                self.selected_add_services = [];
                // Вставляем счета в существующую бронь
                self.booking.counts = self.booking.counts.concat(all_counts);
                // self.booking.counts = response.data.counts;

                self.isLoading = false;
              })
              .catch((error) => {
                self.isLoading = false;
                self.setError(error, self.$t("errors.return_car"));
                self.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "error",
                  title: self.$t("errors.return_car"),
                  text: self.error,
                });
              });
          }

          let giveNullDeposit = false;
          if (this.giveNullDeposit || this.arrival_payments.deposit.sum == 0) {
            this.$swal({
              title: this.$t("bookings.return_deposit_title"),
              text: this.$t("bookings.return_deposit_zero"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: this.$t("custom.yes"),
              cancelButtonText: this.$t("custom.no"),
              showCloseButton: true,
            }).then((result) => {
              if (result.value) {
                giveNullDeposit = true;
                if (booking.end_mileage == 0) {
                  self
                    .$swal({
                      title: self.$t("bookings.return_mileage_title"),
                      text: self.$t("bookings.return_mileage_no"),
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: self.$t("custom.yes"),
                      cancelButtonText: self.$t("custom.no"),
                      showCloseButton: true,
                    })
                    .then((result) => {
                      if (result.value) {
                        arrival(giveNullDeposit);
                      }
                    });
                } else if (booking.start_mileage > booking.end_mileage) {
                  self
                    .$swal({
                      title: self.$t("bookings.mileage_bad_title"),
                      text: self.$t("bookings.mileage_bad_text"),
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: self.$t("custom.yes"),
                      cancelButtonText: self.$t("custom.no"),
                      showCloseButton: true,
                    })
                    .then((result) => {
                      if (result.value) {
                        arrival(giveNullDeposit);
                      }
                    });
                } else if (
                  (booking.end_mileage - booking.start_mileage) /
                    (booking.days > 0 ? booking.days : 1) >
                  300
                ) {
                  self
                    .$swal({
                      title: self.$t("bookings.mileage_bad_big"),
                      text: self.$t("bookings.mileage_bad_text"),
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonText: self.$t("custom.yes"),
                      cancelButtonText: self.$t("custom.no"),
                      showCloseButton: true,
                    })
                    .then((result) => {
                      if (result.value) {
                        arrival(giveNullDeposit);
                      }
                    });
                } else {
                  arrival(giveNullDeposit);
                }
              }
            });
          } else {
            if (
              (self.arrival_payments.clean.sum != 0 &&
                self.arrival_payments.clean.type) ||
              (self.arrival_payments.delivery.sum != 0 &&
                self.arrival_payments.delivery.type) ||
              (self.arrival_payments.delivery_end.sum != 0 &&
                self.arrival_payments.delivery_end.type) ||
              (self.arrival_payments.additional_hours.sum != 0 &&
                self.arrival_payments.additional_hours.type) ||
              (self.arrival_payments.rent.sum != 0 &&
                self.arrival_payments.rent.type) ||
              (self.arrival_payments.gas.sum != 0 &&
                self.arrival_payments.gas.type) ||
              (self.arrival_payments.equipment.sum != 0 &&
                self.arrival_payments.equipment.type) ||
              (self.arrival_payments.insurance.sum != 0 &&
                self.arrival_payments.insurance.type) ||
              (self.arrival_payments.fine.sum != 0 &&
                self.arrival_payments.fine.type) ||
              (self.arrival_payments.damage.sum != 0 &&
                self.arrival_payments.damage.type) ||
              (self.arrival_payments.other.sum != 0 &&
                self.arrival_payments.other.type) ||
              (self.arrival_payments.deposit.sum != 0 &&
                self.arrival_payments.deposit.type)
            ) {
              if (booking.end_mileage == 0) {
                self
                  .$swal({
                    title: self.$t("bookings.return_mileage_title"),
                    text: self.$t("bookings.mileage_bad_zero"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: self.$t("custom.yes"),
                    cancelButtonText: self.$t("custom.no"),
                    showCloseButton: true,
                  })
                  .then((result) => {
                    if (result.value) {
                      arrival(giveNullDeposit);
                    }
                  });
              } else if (booking.start_mileage > booking.end_mileage) {
                self
                  .$swal({
                    title: self.$t("bookings.return_mileage_title"),
                    text: self.$t("bookings.mileage_bad_text"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: self.$t("custom.yes"),
                    cancelButtonText: self.$t("custom.no"),
                    showCloseButton: true,
                  })
                  .then((result) => {
                    if (result.value) {
                      arrival(giveNullDeposit);
                    }
                  });
              } else if (
                (booking.end_mileage - booking.start_mileage) /
                  (booking.days > 0 ? booking.days : 1) >
                300
              ) {
                self
                  .$swal({
                    title: self.$t("bookings.mileage_bad_big"),
                    text: self.$t("bookings.mileage_bad_text"),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: self.$t("custom.yes"),
                    cancelButtonText: self.$t("custom.no"),
                    showCloseButton: true,
                  })
                  .then((result) => {
                    if (result.value) {
                      arrival(giveNullDeposit);
                    }
                  });
              } else {
                arrival(giveNullDeposit);
              }
            } else {
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                icon: "warning",
                title: self.$t("errors.money_type_title"),
              });
            }
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getBookingHistory() {
      let self = this;
      this.axios
        .get(`/api/v1/booking_histories/${this.booking.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.booking_histories = [];
          response.data.forEach((item, index) => {
            this.booking_histories.push({
              id: item.id,
              name: `${moment(item.created_at).format(
                "DD-MM-YYYY H:mm:ss"
              )} ${self.findWorker(item.user_id)}`,
              children: Object.keys(item.updated_attributes).map(
                (item2, index) => {
                  return {
                    id: index,
                    name: `${self.$t("bookings.param")}: ${item2}, ${self.$t(
                      "bookings.was"
                    )}: ${item.updated_attributes[item2][0]}, ${self.$t(
                      "bookings.became"
                    )}: ${item.updated_attributes[item2][1]}`,
                  };
                }
              ),
            });
          });
          this.booking_histories_dialog = true;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.error"));
          console.log(error.response.data);
        })
        .finally(() => (this.isLoading = false));
    },
    saveGift() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.axios
          .post(
            `/api/v1/bookings/${this.booking.id}/gifts`,
            {
              gifts: {
                gift_type: this.gift.gift_type,
                price: this.gift.price,
                count: this.gift.count,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            if (this.gift.gift_type == 0) {
              this.booking.rental_cost = Math.trunc(
                this.booking.rental_cost - this.gift.price * this.gift.count
              );
            }
            if (this.gift.gift_type == 1) {
              this.booking.hours_cost = Math.trunc(
                this.booking.hours_cost - this.gift.price * this.gift.count
              );
            }
            this.editSave();
            this.gifts.unshift(response.data);
            this.show_gifts_dialog = false;
            this.gift = { gift_type: 0, price: 0, count: 1 };
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.gifts_load"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.sign"),
              text: this.error,
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteGift(gift) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("gifts.delete_warn"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/bookings/${self.booking.id}/gifts/${gift.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                let index = self.gifts.indexOf((item) => item.id == gift.id);
                this.gifts.splice(index, 1);
                // непонятно почему, но не работает
                // if (
                //   !(this.booking.sale || this.booking.sale_cash) &&
                //   gift.gift_type == 0
                // ) {
                //   this.booking.rental_cost = Math.trunc(
                //     this.booking.rental_cost + gift.price * gift.count
                //   );
                // }
                if (gift.gift_type == 0) {
                  this.booking.rental_cost = Math.trunc(
                    this.booking.rental_cost + gift.price * gift.count
                  );
                }
                if (gift.gift_type == 1) {
                  this.booking.hours_cost = Math.trunc(
                    this.booking.hours_cost + gift.price * gift.count
                  );
                }
                this.editSave();

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "success",
                  title: this.$t("custom.deleted"),
                });
              })
              .catch((error) => this.setError(error, this.$t("errors.error")))
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    clearSign() {
      this.$refs.signaturePad.clearSignature();
    },
    undoSign() {
      this.$refs.signaturePad.undoSignature();
    },
    saveSign() {
      if (this.user_role != "guest" && this.user_role != "partner") {
        let self = this;
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

        if (!isEmpty) {
          this.axios
            .post(
              `/api/v1/signature`,
              {
                signature: {
                  sign: data,
                  booking_id: this.booking.id,
                  signature_type: this.signature_type,
                  client_id: null,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.sign_id = response.data;
            })
            .catch((error) => {
              self.setError(error, self.$t("errors.sign"));
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: self.$t("errors.sign"),
                text: self.error,
              });
            })
            .finally(() => {
              this.clearSign();
              this.showSignPad = false;
              self.isLoading = false;
              self.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: self.$t("bookings.success_sign"),
              });
            });
        } else {
          this.$swal({
            icon: "error",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            title: this.$t("errors.sign"),
            text: this.$t("errors.sign_empty"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    downloadSignedDoc(template) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        this.isLoading = true;
        let self = this;
        this.axios
          .post(
            `/api/v1/get_signed_document?template_id=${template.id}`,
            {
              booking_id: this.booking.id,
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            if (response.data.id) {
              this.booking.generated_documents.unshift(response.data);
            } else {
              console.log("response.data", response.data)
              if(this.isNative) {
                console.log("self.checkWriteFile()", Filesystem.checkPermissions())
                if(Filesystem.checkPermissions()) {
                    const getBase64FromUrl = async (url) => {
                      const data = await fetch(url);
                      const blob = await data.blob();
                      return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                          const base64data = reader.result;
                          resolve(base64data);
                        }
                      });
                    }
                    getBase64FromUrl(response.data).then(
                      (base64) => {
                        // save file
                        async function writeFile() {
                          return await Filesystem.writeFile({
                            path: `${uuid.v1()}.pdf`,
                            data: base64,
                            directory: Directory.Documents,
                          });
                        };
                        let file_uri = null
                        let writedFile = writeFile().then((getUriResult) => {
                          // share file opened file
                          let shareFile = async () => {
                            await Share.share({
                              title: 'Download document',
                              files: [getUriResult.uri],
                            });
                          };
                          let sharedFile = shareFile();
                        });
                      }
                    );
                } else {
                  Filesystem.requestPermissions();
                }
              } else {
                window.open(response.data, "_blank");
              }
            }
          })
          .catch((error) => {
            self.setError(error, self.$t("errors.file_upload"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    downloadSignedDocBottom(link) {
      if(this.isNative) {
        const getBase64FromUrl = async (url) => {
          const data = await fetch(url);
          const blob = await data.blob();
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              const base64data = reader.result;
              resolve(base64data);
            }
          });
        }
        getBase64FromUrl(`https://storage.yandexcloud.net/rentprog/${link}`).then(
          (base64) => {
            // save file
            async function writeFile() {
              return await Filesystem.writeFile({
                path: `${uuid.v1()}.pdf`,
                data: base64,
                directory: Directory.Documents,
              });
            };
            let file_uri = null
            let writedFile = writeFile().then((getUriResult) => {
              // share file opened file
              let shareFile = async () => {
                await Share.share({
                  title: 'Download document',
                  files: [getUriResult.uri],
                });
              };
              let sharedFile = shareFile();
            });
          }
        );
      } else {
        saveAs(`https://storage.yandexcloud.net/rentprog/${link}`, "document.pdf");
      }
    },
    deleteSignedDoc(id) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            let self = this;
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/generated_documents/${id}`, {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                let index = this.booking.generated_documents.indexOf(
                  (template) => template.id == id
                );
                this.booking.generated_documents.splice(index, 1);
                this.$swal(
                  this.$t("bookings.deleted_title"),
                  this.$t("bookings.deleted_text"),
                  "success"
                );
              })
              .catch((error) => this.setError(error, this.$t("errors.deleted")))
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    sendSignedDocument(signed_document_id) {
      if (this.user_role != "guest" || this.user_role != "partner") {
        if (this.client && this.client.email) {
          let self = this;
          this.axios
            .post(
              `/api/v1/send_signed_document`,
              {
                booking_id: this.booking.id,
                signed_document_id: signed_document_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.letter_send_title"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.bad_email"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.bad_email"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    cancelInAgregator() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.$swal({
          title: this.$t("agregator.cancel_title"),
          text: this.$t("agregator.cancel_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            let self = this;
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/cancel_in_vseprokaty`,
                {
                  booking_id: this.booking.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                this.booking.failure = true;
                this.booking.state = "Нет машин";

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "success",
                  title: this.$t("agregator.cancel_success"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("agregator.cancel_text_error"));
                console.log(error);
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    acceptInAgregator() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.$swal({
          title: this.$t("agregator.accept_title"),
          text: this.$t("agregator.accept_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            let self = this;
            this.isLoading = true;
            this.axios
              .post(
                `/api/v1/accept_in_vseprokaty`,
                {
                  booking_id: this.booking.id,
                },
                {
                  headers: {
                    Authorization: this.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                this.booking.active = true;
                this.booking.state = "Подтверждена";
                this.booking.accepted = true;
                this.booking.failure = false;

                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "success",
                  title: this.$t("agregator.accept_success"),
                });
              })
              .catch((error) => {
                this.setError(error, this.$t("agregator.accept_text_error"));
                console.log(error);
              })
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    isValidEmail(email) {
      let reg =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (reg.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    returnDeposit() {
      let deposit;
      if (!this.giveNullDeposit) {
        // Если возвращаем залог
        deposit = this.booking.deposit;

        if (
          this.arrival_payments.rent.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.rent.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.rent.sum;
        }
        if (
          this.arrival_payments.clean.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.clean.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.clean.sum;
        }
        if (
          this.arrival_payments.delivery.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.delivery.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.delivery.sum;
        }
        if (
          this.arrival_payments.delivery_end.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.delivery_end.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.delivery_end.sum;
        }
        if (
          this.arrival_payments.additional_hours.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.additional_hours.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.additional_hours.sum;
        }
        if (
          this.arrival_payments.mileage &&
          this.arrival_payments.mileage.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.mileage.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.mileage.sum;
        }
        if (
          this.arrival_payments.insurance.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.insurance.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.insurance.sum;
        }
        if (
          this.arrival_payments.add_drivers_cost.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.add_drivers_cost.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.add_drivers_cost.sum;
        }
        if (
          this.arrival_payments.gas.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.gas.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.gas.sum;
        }
        if (
          this.arrival_payments.equipment.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.equipment.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.equipment.sum;
        }
        if (
          this.arrival_payments.fine.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.fine.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.fine.sum;
        }
        if (
          this.arrival_payments.damage.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.damage.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.damage.sum;
        }
        if (
          this.arrival_payments.other.type ==
            this.arrival_payments.deposit.type &&
          this.arrival_payments.other.type != null &&
          this.arrival_payments.deposit.type != null
        ) {
          deposit -= this.arrival_payments.other.sum;
        }
        // Частичный возврат
        if (this.partReturnDeposit) {
          let add_payments = this.booking.deposit - deposit;
          deposit = this.arrival_payments.deposit.sum - add_payments;
        }
      } else {
        // Если не возвращаем, то минусуем от нуля без учета вида оплаты
        deposit = 0;
        deposit -= this.arrival_payments.rent.sum;
        deposit -= this.arrival_payments.clean.sum;
        deposit -= this.arrival_payments.delivery.sum;
        deposit -= this.arrival_payments.delivery_end.sum;
        deposit -= this.arrival_payments.additional_hours.sum;
        deposit -= this.arrival_payments.mileage.sum;
        deposit -= this.arrival_payments.insurance.sum;
        deposit -= this.arrival_payments.add_drivers_cost.sum;
        deposit -= this.arrival_payments.gas.sum;
        deposit -= this.arrival_payments.equipment.sum;
        deposit -= this.arrival_payments.fine.sum;
        deposit -= this.arrival_payments.damage.sum;
        deposit -= this.arrival_payments.other.sum;
      }
      return deposit;
    },
    checkMileageLimit() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.booking.end_mileage > this.booking.start_mileage
      ) {
        let mileage = Math.floor(
          (this.booking.end_mileage - this.booking.start_mileage) /
            this.booking.days
        );
        let limit = this.$store.getters.getCompany.company.mileage_limit;

        if (
          this.set_car.extra_mileage_km > 0 &&
          this.set_car.extra_mileage_price > 0
        ) {
          limit = this.set_car.extra_mileage_km;
        }
        if (mileage > limit && limit > 0) {
          this.limitExept = true;
          this.giveFullDeposit = false;
          if (
            this.selected_add_services.findIndex((item) => item == "Превышение пробега") < 0
          ) {
            this.selected_add_services.push("Превышение пробега");
          }
          return true;
        } else {
          this.limitExept = false;
          return false;
        }
      }
    },
    calcExtraMileagePrice() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company
      ) {
        let price = this.$store.getters.getCompany.company.mileage_limit_cost;

        if (
          this.set_car.extra_mileage_km > 0 &&
          this.set_car.extra_mileage_price > 0
        ) {
          price = this.set_car.extra_mileage_price;
        }

        this.limitCost = this.returnMileageExt() > 0 ? Math.round(this.returnMileageExt() * price) : 0;
      } else {
        this.limitCost = 0;
      }
    },
    returnMileageExt() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company
      ) {
        let limit = this.$store.getters.getCompany.company.mileage_limit;

        if (
          this.set_car.extra_mileage_km > 0 &&
          this.set_car.extra_mileage_price > 0
        ) {
          limit = this.set_car.extra_mileage_km;
        }

        return Math.round(
          ((this.booking.end_mileage - this.booking.start_mileage) /
            this.booking.days -
            limit) *
            this.booking.days
        );
      }
    },
    calcClean() {
      if (this.booking && this.booking.clean_payment == 0) {
        this.arrival_payments.clean.sum = this.clean_price;
        if (
          this.selected_add_services.findIndex((item) => item == "Мойка") < 0
        ) {
          this.selected_add_services.push("Мойка");
        }
        this.giveFullDeposit = false;
      } else if (this.booking && this.booking.clean_payment > 0) {
        this.showCleanPaid = true;
      }
    },
    calcGas() {
      this.giveFullDeposit = false;
      if (
        this.selected_add_services.findIndex((item) => item == "Топливо") < 0
      ) {
        this.selected_add_services.push("Топливо");
      }
    },
    checkDepartureMileage(mileage) {
      if (mileage < this.check_mileage) {
        let self = this;
        this.$swal({
          title: this.$t("bookings.bad_mileage_text"),
          text: this.$t("bookings.mileage_bad_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            self.departure_yes = true;
            self.activeRent();
          }
        });
      } else {
        this.departure_yes = true;
        this.activeRent();
      }
    },
    archiveBooking(booking) {
      if (this.user_role != "guest" && this.user_role != "partner") {
        // Проверяем если бронь из агрегатора то не даем архивировать если не принято решение
        if (booking.vseprokaty_id) {
          if (!booking.active && !booking.failure && !booking.ride && !booking.decision_date) {
            this.$swal({
              showConfirmButton: true,
              icon: "error",
              title: this.$t("agregator.have_not_decision"),
              text: this.$t("agregator.have_not_decision_text"),
            });
            return;
          }
        }
        let self = this;
        if (booking) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  archive: true,
                  archived_date: new Date(),
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.archive = true;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.archivate"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.bad_archivate"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.bad_archivate_text"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    unarchiveBooking(booking) {
      if (this.user_role != "guest") {
        let self = this;
        if (booking) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${booking.id}`,
              {
                bookings: {
                  archive: false,
                  archived_date: null,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.archive = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.archivate_return"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.bad_unarchivate_text"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.bad_unarchivate_text"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkEmail() {
      if (this.client.email) {
      } else {
        this.sendEmail = false;
        this.$swal({
          icon: "warning",
          title: this.$t("errors.bad_email_title"),
          text: this.$t("errors.bad_email_text"),
        });
      }
    },
    checkWhatsapp() {
      if (
        this.client.phone &&
        this.$store.getters.getCompany.company.whatsapp
      ) {
      } else {
        this.sendWhatsapp = false;
        this.$swal({
          icon: "warning",
          title: this.$t("errors.bad_whatsapp_title"),
          text: this.$t("errors.bad_whatsapp_text"),
        });
      }
    },
    checkSms() {
      if (this.client.phone) {
      } else {
        this.sendSms = false;
        this.$swal({
          icon: "warning",
          title: this.$t("errors.bad_sms_title"),
          text: this.$t("errors.bad_sms_text"),
        });
      }
    },
    acceptBooking() {
      if (this.user_role != "guest") {
        if (this.client && (this.client.email || this.client.phone)) {
          this.isLoading = true;
          let self = this;
          this.axios
            .get(
              `/api/v1/accept_booking/${this.booking.id}?email=${this.sendEmail}&whatsapp=${this.sendWhatsapp}&sms=${this.sendSms}`,
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.send_accept_email = true;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.send_success"),
              });
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response.status == 409) {
                if (error.response.data.qrCode) {
                  this.whatsappQrcode = error.response.data.qrCode;
                }

                this.showQrcodeWhatsapp = true;
              } else {
                this.setError(error, this.$t("errors.bad_message_title"));
              }
              console.log(error.response.data);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.bad_message_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    cancelBookingFewDaysEmail() {
      if (this.user_role != "guest") {
        if (this.client && (this.client.email || this.client.phone)) {
          this.isLoading = true;
          let self = this;
          this.axios
            .get(
              `/api/v1/cancel_booking_few_days/${this.booking.id}?email=${this.sendEmail}&whatsapp=${this.sendWhatsapp}&sms=${this.sendSms}`,
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.send_cancel_email = true;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.send_decline_success"),
              });
            })
            .catch((error) => {
              this.setError(
                error,
                "Something went wrong in cancelBookingFewDaysEmail"
              );
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.bad_decline_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    cancelBookingNotCarsEmail() {
      if (this.user_role != "guest") {
        if (this.client && (this.client.email || this.client.phone)) {
          let self = this;
          this.axios
            .get(
              `/api/v1/cancel_booking_not_cars/${this.booking.id}?email=${this.sendEmail}&whatsapp=${this.sendWhatsapp}&sms=${this.sendSms}`,
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.send_cancel_email = true;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.send_decline_success"),
              });
            })
            .catch((error) => {
              this.setError(
                error,
                "Something went wrong in cancelBookingNotCarsEmail"
              );
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.bad_decline_text"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    retryWhatsapp() {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/retry_whatsapp`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.whatsappQrcode = response.data.qrCode;
        })
        .catch((error) => {
          this.isLoading = false;
          this.setError(error, this.$t("errors.bad_qr_text"));
          console.log(error.response.data);
        })
        .finally(() => (this.isLoading = false));
    },
    getEmailTemplates() {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/email_templates`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.email_templates = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.email_template_text"));
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.email_template_text"),
            text: this.error,
          });
        })
        .finally(() => (this.isLoading = false));
    },
    sendCustomEmail() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        if (
          this.client &&
          (this.client.email || this.client.phone) &&
          this.customEmailData.body
        ) {
          let self = this;
          this.axios
            .post(
              `/api/v1/send_custom_email?email=${this.sendEmail}&whatsapp=${this.sendWhatsapp}&sms=${this.sendSms}`,
              {
                id: this.$route.params.id,
                name: this.customEmailData.name,
                subject: this.customEmailData.subject,
                body: this.customEmailData.body,
                save_new: this.save_email_template,
                attachment_id: this.customEmailData.attachment_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.send_custom_email = true;
              this.selected_email_templates = null;
              // this.customEmailData = {
              //   id: this.$route.params.id,
              //   name: this.customEmailData.name,
              //   body: this.customEmailData.subject,
              //   subject: this.save_email_template,
              //   attachment_id: this.customEmailData.attachment_id,
              // };
              this.showCustomEmailDialog = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.send_email_success"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.email_bad_send"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.email_bad_send_2"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    saveCustomEmail() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        if (
          this.customEmailData.name &&
          this.customEmailData.subject &&
          this.customEmailData.body
        ) {
          let self = this;
          this.axios
            .post(
              `/api/v1/save_custom_email_template`,
              {
                name: this.customEmailData.name,
                subject: this.customEmailData.subject,
                body: this.customEmailData.body,
                save_new: this.save_email_template,
                attachment_id: this.customEmailData.attachment_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.email_templates.push(response.data);
              this.selected_email_templates = response.data.id;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("custom.saved"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.email_bad_send"));
              console.log(error);
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("errors.email_bad_send_2"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateEmailTemplate() {
      if (this.user_role != "guest" || this.user_role != "partner") {
        if (
          this.selected_email_templates &&
          this.customEmailData.name &&
          this.customEmailData.body
        ) {
          let self = this;
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/email_templates/${this.selected_email_templates}`,
              {
                email_templates: {
                  name: this.customEmailData.name,
                  subject: this.customEmailData.subject,
                  body: this.customEmailData.body,
                  attachment_id: this.customEmailData.attachment_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let template = this.email_templates.find(
                (template) => template.id == this.selected_email_templates
              );
              template.subject = this.customEmailData.subject;
              template.body = this.customEmailData.body;
              let foundIndex = this.email_templates.findIndex(
                (element) => element.id === this.selected_email_templates
              );
              this.email_templates.splice(foundIndex, 1, response.data);

              this.editEmailTemplate = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("bookings.update_templates_success"),
              });
            })
            .catch((error) => {
              this.isLoading = false;
              this.setError(error, this.$t("errors.email_bad_template"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.email_bad_template"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteEmailTemplate(id) {
      if (this.user_role != "guest" || this.user_role != "partner") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            let self = this;
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/email_templates/${id}`, {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.email_templates.splice(
                  this.email_templates.indexOf((template) => template.id == id),
                  1
                );
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("bookings.deletes_template"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.remove_bad_template"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateInvoiceState(invoice, state) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/invoices/${invoice.id}`,
            {
              invoices: {
                state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.invoices.findIndex(
              (element) => element.id === invoice.id
            );
            // Добавляю paid
            let invoice_data = response.data;
            invoice_data["paid"] = invoice.paid;
            this.invoices.splice(foundIndex, 1, invoice_data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("invoices.edited_title"),
              text: this.$t("invoices.edited_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edited_count"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.edited_count"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    paidInvoice(invoice) {
      let invoice_sum =
        invoice.rent +
        invoice.deposit +
        invoice.delivery +
        invoice.delivery_end +
        invoice.clean +
        invoice.gas +
        invoice.damage +
        invoice.fines +
        invoice.mileage +
        invoice.hours_cost +
        invoice.insurance +
        invoice.add_drivers_cost +
        invoice.equipment +
        invoice.other;
      if (invoice_sum == invoice.paid && invoice_sum > 0 && invoice.paid > 0) {
        return this.$t("invoices.paid");
      } else if (invoice_sum < invoice.paid) {
        return this.$t("invoices.extra_paid");
      } else if (invoice_sum > 0 && invoice_sum > invoice.paid) {
        return this.$t("invoices.low_paid");
      } else if (invoice_sum > 0 && invoice.paid == 0) {
        return this.$t("invoices.no_income");
      } else {
        return `-`;
      }
    },
    generateDoc(booking) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        let content, error, url;

        // console.log(booking, this.client, this.set_car, this.set_car.prices);
        this.axios
          .get(`api/v1/doc_templates/${booking.car_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((doc_templates_response) => {
            if (
              doc_templates_response.data &&
              doc_templates_response.data.url
            ) {
              url = doc_templates_response.data.url;
            } else {
              if (self.$i18n.locale == "ru") {
                url = "https://web.rentprog.ru/doc_example.docx";
              } else {
                url = "https://web.rentprog.com/doc_example_en.docx";
              }
            }
          })
          .then(() => {
            axios
              .get(url, {
                responseType: "arraybuffer",
                headers: {
                  "Content-Type": "text/plain;charset=utf-8",
                },
              })
              .then((response) => {
                content = response.data;
                var zip = new PizZip(content);
                let client = self.client;
                let car = self.set_car;
                let add_drivers_info = "";
                if (self.add_drivers && self.add_drivers.length > 0) {
                  self.add_drivers.forEach((driver) => {
                    add_drivers_info += `${
                      driver.lastname ? driver.lastname : ""
                    } ${driver.name ? driver.name : ""} ${
                      driver.middlename ? driver.middlename : ""
                    }, ${self.$t("custom.passport")}: ${
                      driver.passport_series ? driver.passport_series : ""
                    } ${
                      driver.passport_number ? driver.passport_number : ""
                    }, ${self.$t("custom.driver_license")}: ${
                      driver.driver_series ? driver.driver_series : ""
                    } ${driver.driver_number ? driver.driver_number : ""}${
                      self.add_drivers.length > 1 ? ", " : ""
                    }`;
                  });
                }
                // Больше информации о доп клиенте в документе
                let add_drivers_info_full = "";
                if (self.add_drivers && self.add_drivers.length > 0) {
                  self.add_drivers.forEach((driver) => {
                    add_drivers_info_full += `${
                      driver.lastname ? driver.lastname : ""
                    } ${driver.name ? driver.name : ""} ${
                      driver.middlename ? driver.middlename : ""
                    }, ${self.$t("bookings.birthdate_small")}: ${
                      driver.birthday
                        ? moment(driver.birthday, "YYYY-MM-DD").format("L")
                        : ""
                    }, ${self.$t("custom.passport")}: ${
                      driver.passport_series ? driver.passport_series : ""
                    } ${
                      driver.passport_number ? driver.passport_number : ""
                    }, ${self.$t("custom.issued")}: ${
                      driver.passport_issued ? driver.passport_issued : ""
                    }, ${self.$t("custom.address").toLowerCase()}: ${
                      driver.country ? driver.country : ""
                    }, ${driver.city ? driver.city : ""}, ${
                      driver.address ? driver.address : ""
                    }, ${self.$t("custom.driver_license")}: ${
                      driver.driver_series ? driver.driver_series : ""
                    } ${
                      driver.driver_number ? driver.driver_number : ""
                    }, ${self.$t("custom.issued")}: ${
                      driver.driver_issued ? driver.driver_issued : ""
                    } ${self.add_drivers.length > 1 ? ", " : ""}`;
                  });
                }

                // Комплексные доп водители в масссиве
                let add_drivers_array = {};
                add_drivers_array['has_add_drivers'] = self.add_drivers.length > 0 ? true : false;
                add_drivers_array['drivers'] = [];
                self.add_drivers.forEach((driver) => {
                  add_drivers_array['drivers'].push(
                    {
                      driver_name: driver.name ? driver.name : "       ",
                      driver_lastname: driver.lastname ? driver.lastname : "       ",
                      driver_middlename: driver.middlename ? driver.middlename : "       ",
                      driver_birthday: driver.birthday ? moment(driver.birthday, "YYYY-MM-DD").format("L") : "       ",
                      driver_passport_series: driver.passport_series ? driver.passport_series : "       ",
                      driver_passport_number: driver.passport_number ? driver.passport_number : "       ",
                      driver_passport_issued: driver.passport_issued ? driver.passport_issued : "       ",
                      driver_country: driver.country ? driver.country : "       ",
                      driver_city: driver.city ? driver.city : "       ",
                      driver_address: driver.address ? driver.address : "       ",
                      driver_driver_series: driver.driver_series ? driver.driver_series : "       ",
                      driver_driver_number: driver.driver_number ? driver.driver_number : "       ",
                      driver_driver_issued: driver.driver_issued ? driver.driver_issued : "       ",
                    },
                  )
                })
                // Считаю предоплату
                let prepaid = 0;
                if (booking.counts && booking.counts.length > 0) {
                  booking.counts.forEach((count) => {
                    prepaid += count.sum;
                  });
                }

                // Считаю доп сервисы
                let additional_services =
                  (booking.hours_cost > 0
                    ? booking.additional_hours * booking.price_hour
                    : 0) +
                  booking.hours_cost_end +
                  booking.delivery +
                  booking.delivery_end +
                  booking.equipment +
                  booking.clean_payment +
                  booking.damage +
                  booking.gas +
                  booking.add_drivers_cost +
                  booking.insurance +
                  booking.fine +
                  booking.other +
                  booking.other_end;

                // Считаю комиссию в сутки
                let commission = booking.aggr_commission;
                let commission_day = commission / (booking.days > 0 ? booking.days : 1);

                var doc = new Docxtemplater().loadZip(zip);
                doc.setOptions({paragraphLoop:true});
                doc.setData({
                  date: moment().format("DD/MM/YY"),
                  date_short: moment().format("DD/MM"),
                  serial_number: booking.serial_number ? booking.serial_number : "      ",
                  employee_full_name: self.user_full_name,
                  employee_short_name: self.user_fio,
                  first_name: client.name ? client.name : "      ",
                  middle_name: client.middlename ? client.middlename : "      ",
                  last_name: client.lastname ? client.lastname : "      ",
                  short_name: client.fio ? client.fio : "      ",
                  client_id: client.id ? client.id : "      ",
                  booking_id: booking.id ? booking.id : "      ",
                  car_id: car.id ? car.id : "      ",
                  date_birth: client.birthday
                    ? moment(client.birthday, "YYYY-MM-DD").format("DD-MM-YYYY")
                    : "      ",
                  passport_series: client.passport_series
                    ? client.passport_series
                    : "      ",
                  passport_number: client.passport_number
                    ? client.passport_number
                    : "      ",
                  passport_issued: client.passport_issued
                    ? client.passport_issued
                    : "      ",
                  driver_series: client.driver_series
                    ? client.driver_series
                    : "      ",
                  driver_number: client.driver_number
                    ? client.driver_number
                    : "      ",
                  driver_issued: client.driver_issued
                    ? client.driver_issued
                    : "      ",
                  inn: client.inn ? client.inn : "      ",
                  country: client.country ? client.country : "      ",
                  city: client.city ? client.city : "      ",
                  address: client.address ? client.address : "      ",
                  phone: client.phone ? client.phone : "      ",
                  email: client.email ? client.email : "      ",
                  start_date: booking.start_date
                    ? booking.start_date
                    : "      ",
                  end_date: booking.end_date ? booking.end_date : "      ",
                  start_date_short: booking.start_date
                    ? moment(booking.start_date, "DD-MM-YYYY H:mm").format(
                        "DD.MM.YYYY"
                      )
                    : "     ",
                  end_date_short: booking.end_date
                    ? moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                        "DD.MM.YYYY"
                      )
                    : "     ",
                  day_cost: car.prices && car.prices.find((price) => price.season_id === null)
                    ? car.prices.find((price) => price.season_id === null)
                        .values[0]
                    : booking.start_price,
                  price: booking.price ? booking.price : "      ",
                  day_cost_without_commission: car.prices && car.prices.find((price) => price.season_id === null)
                    ? (car.prices.find((price) => price.season_id === null)
                        .values[0] - commission_day).toFixed(2)
                    : booking.start_price,
                  price_without_commission: booking.price
                    ? (booking.price - commission_day).toFixed(2)
                    : "      ",
                  cost_without_commission: booking.rental_cost
                    ? (booking.rental_cost - commission).toFixed(2)
                    : "      ",
                  selected_price: booking.selected_price
                    ? booking.selected_price
                    : "      ",
                  cost: booking.rental_cost ? booking.rental_cost : "      ",
                  days: booking.days ? booking.days : "      ",
                  location_start: booking.location_start
                    ? booking.location_start
                    : "      ",
                  location_end: booking.location_end
                    ? booking.location_end
                    : "      ",
                  delivery_start: booking.delivery
                    ? booking.delivery
                    : "      ",
                  delivery_end: booking.delivery_end
                    ? booking.delivery_end
                    : "      ",
                  equipment: booking.equipment ? booking.equipment : "      ",
                  hours_cost: booking.hours_cost
                    ? booking.hours_cost
                    : "      ",
                  additional_hours: booking.additional_hours
                    ? booking.additional_hours
                    : "      ",
                  add_drivers_cost: booking.add_drivers_cost
                    ? booking.add_drivers_cost
                    : "      ",
                  insurance: booking.insurance ? booking.insurance : "      ",
                  clean: booking.clean_payment
                    ? booking.clean_payment
                    : "      ",
                  other: booking.other ? booking.other : "      ",
                  has_delivery_start: booking.delivery ? true : false,
                  has_delivery_end: booking.delivery_end ? true : false,
                  has_equipment: booking.equipment ? true : false,
                  has_additional_hours: booking.additional_hours ? true : false,
                  has_hours_cost: booking.hours_cost ? true : false,
                  has_add_drivers_cost: booking.add_drivers_cost ? true : false,
                  has_insurance: booking.insurance ? true : false,
                  has_clean: booking.clean_payment ? true : false,
                  has_other: booking.other ? true : false,
                  has_sale: booking.sale ? true : false,
                  has_sale_cash: booking.sale_cash ? true : false,
                  sale: booking.sale ? booking.sale : "",
                  sale_cash: booking.sale_cash ? booking.sale_cash : "",
                  start_mileage: booking.start_mileage
                    ? booking.start_mileage
                    : "      ",
                  end_mileage: booking.end_mileage
                    ? booking.end_mileage
                    : "      ",
                  car_name: booking.car_name ? booking.car_name : "      ",
                  car_code: booking.car_code ? booking.car_code : "      ",
                  car_number: car.number ? car.number : "      ",
                  car_vin: car.vin ? car.vin : "      ",
                  car_body: car.body_number ? car.body_number : "      ",
                  car_pts: car.pts ? car.pts : "      ",
                  car_reg: car.registration_certificate
                    ? car.registration_certificate
                    : "      ",
                  car_color: car.color ? car.color : "      ",
                  car_year: car.year ? car.year : "      ",
                  car_fuel: car.fuel ? car.fuel : "      ",
                  price_hour: car.price_hour ? car.price_hour : "      ",
                  additional_services:
                    additional_services > 0 ? additional_services : "      ",
                  has_additional_services:
                    additional_services > 0 ? true : false,
                  deposit: booking.deposit ? booking.deposit : "      ",
                  total: booking.total ? booking.total : "      ",
                  total_without_commission:
                    booking.total
                      ? booking.total - commission
                      : "      ",
                  total_with_deposit:
                    booking.total && booking.deposit
                      ? booking.total + booking.deposit
                      : "      ",
                  total_with_deposit_without_commission:
                    booking.total && booking.deposit
                      ? booking.total + booking.deposit - commission
                      : "      ",
                  prepaid: prepaid,
                  add_drivers_info: add_drivers_info,
                  add_drivers_info_full: add_drivers_info_full,
                  has_add_drivers: self.add_drivers > 0 ? true : false,
                  add_drivers_array: add_drivers_array,
                  repair_cost: car.repair_cost ? car.repair_cost : "      ",
                  franchise: car.franchise ? car.franchise : "      ",
                  max_fine: car.max_fine ? car.max_fine : "      ",
                  custom_field_1: booking.custom_field_1
                    ? booking.custom_field_1
                    : "      ",
                  custom_field_2: booking.custom_field_2
                    ? booking.custom_field_2
                    : "      ",
                  custom_field_3: booking.custom_field_3
                    ? booking.custom_field_3
                    : "      ",
                  cars_custom_field_1: car.custom_field_1
                    ? car.custom_field_1
                    : "      ",
                  cars_custom_field_2: car.custom_field_2
                    ? car.custom_field_2
                    : "      ",
                  cars_custom_field_3: car.custom_field_3
                    ? car.custom_field_3
                    : "      ",
                });

                doc.render();

                var out = doc.getZip().generate({
                  type: "blob",
                  mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                let date = moment().format("Y_MM_DD");
                // console.log(client)
                if(!self.isNative) {
                  saveAs(out, date + "_" + client.lastname + ".docx");
                } else {
                  if(Filesystem.checkPermissions()) {
                    const getBase64FromBlob = async (blob) => {
                      return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = () => {
                          const base64data = reader.result;
                          resolve(base64data);
                        }
                      });
                    }
                    getBase64FromBlob(out).then(
                      (base64) => {
                        // save file
                        async function writeFile() {
                          return await Filesystem.writeFile({
                            path: date + "_" + client.lastname + ".docx",
                            data: base64,
                            directory: Directory.Documents,
                          });
                        };
                        let file_uri = null
                        let writedFile = writeFile().then((getUriResult) => {
                          // share file opened file
                          let shareFile = async () => {
                            await Share.share({
                              title: 'Download document',
                              files: [getUriResult.uri],
                            });
                          };
                          let sharedFile = shareFile();
                        });
                      }
                    );
                  } else {
                    Filesystem.requestPermissions();
                  }
                }
              })
              .catch(function (error) {
                console.log("2", error);
                self.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "error",
                  title: self.$t("errors.gen_template_title"),
                  text: self.$t("errors.gen_template_text"),
                });
              });
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.upload_template"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    generateDocEntity(booking) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/doc_entity_templates/${booking.car_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let content, error, url;
            if (response.data && response.data.url) {
              url = response.data.url;
            } else {
              if (self.$i18n.locale == "ru") {
                url = "https://web.rentprog.ru/entity_doc_example.docx";
              } else {
                url = "https://web.rentprog.com/entity_doc_example_en.docx";
              }
            }
            axios
              .get(url, {
                responseType: "arraybuffer",
                headers: {
                  "Content-Type": "text/plain;charset=utf-8",
                },
              })
              .then((response) => {
                content = response.data;
                var zip = new PizZip(content);
                this.axios
                  .get(`/api/v1/clients/${booking.client_id}`, {
                    headers: {
                      Authorization: self.$store.getters.getAuthToken,
                    },
                  })
                  .then((response) => {
                    let client = response.data.client;
                    // let car = self.cars.find(x => x.id === booking.car_id);
                    let car = self.set_car;
                    let add_drivers_info = "";
                    if (self.add_drivers && self.add_drivers.length > 0) {
                      self.add_drivers.forEach((driver) => {
                        add_drivers_info += `${
                          driver.lastname ? driver.lastname : ""
                        } ${driver.name ? driver.name : ""} ${
                          driver.middlename ? driver.middlename : ""
                        }, ${self.$t("custom.passport")}: ${
                          driver.passport_series ? driver.passport_series : "-"
                        } ${
                          driver.passport_number ? driver.passport_number : ""
                        }, ${self.$t("custom.driver_license")}: ${
                          driver.driver_series ? driver.driver_series : "-"
                        } ${driver.driver_number ? driver.driver_number : ""}${
                          self.add_drivers.length > 1 ? ", " : ""
                        }`;
                      });
                    }
                    // Больше информации о доп клиенте в документе
                    let add_drivers_info_full = "";
                    if (self.add_drivers && self.add_drivers.length > 0) {
                      self.add_drivers.forEach((driver) => {
                        add_drivers_info_full += `${
                          driver.lastname ? driver.lastname : ""
                        } ${driver.name ? driver.name : ""} ${
                          driver.middlename ? driver.middlename : ""
                        }, ${self.$t("bookings.birthdate_small")}: ${
                          driver.birthday
                            ? moment(driver.birthday, "YYYY-MM-DD").format("L")
                            : ""
                        }, ${self.$t("custom.passport")}: ${
                          driver.passport_series ? driver.passport_series : ""
                        } ${
                          driver.passport_number ? driver.passport_number : ""
                        }, ${self.$t("custom.issued")}: ${
                          driver.passport_issued ? driver.passport_issued : ""
                        }, ${self.$t("custom.address").toLowerCase()}: ${
                          driver.country ? driver.country : ""
                        }, ${driver.city ? driver.city : ""}, ${
                          driver.address ? driver.address : ""
                        }, ${self.$t("custom.driver_license")}: ${
                          driver.driver_series ? driver.driver_series : ""
                        } ${
                          driver.driver_number ? driver.driver_number : ""
                        }, ${self.$t("custom.issued")}: ${
                          driver.driver_issued ? driver.driver_issued : ""
                        } ${self.add_drivers.length > 1 ? ", " : ""}`;
                      });
                    }
                    // Комплексные доп водители в масссиве
                    let add_drivers_array = {};
                    add_drivers_array['has_add_drivers'] = self.add_drivers.length > 0 ? true : false;
                    add_drivers_array['drivers'] = [];
                    self.add_drivers.forEach((driver) => {
                      add_drivers_array['drivers'].push(
                        {
                          driver_name: driver.name ? driver.name : "       ",
                          driver_lastname: driver.lastname ? driver.lastname : "       ",
                          driver_middlename: driver.middlename ? driver.middlename : "       ",
                          driver_birthday: driver.birthday ? moment(driver.birthday, "YYYY-MM-DD").format("L") : "       ",
                          driver_passport_series: driver.passport_series ? driver.passport_series : "       ",
                          driver_passport_number: driver.passport_number ? driver.passport_number : "       ",
                          driver_passport_issued: driver.passport_issued ? driver.passport_issued : "       ",
                          driver_country: driver.country ? driver.country : "       ",
                          driver_city: driver.city ? driver.city : "       ",
                          driver_address: driver.address ? driver.address : "       ",
                          driver_driver_series: driver.driver_series ? driver.driver_series : "       ",
                          driver_driver_number: driver.driver_number ? driver.driver_number : "       ",
                          driver_driver_issued: driver.driver_issued ? driver.driver_issued : "       ",
                        },
                      )
                    })
                    // Считаю предоплату
                    let prepaid = 0;
                    if (booking.counts && booking.counts.length > 0) {
                      booking.counts.forEach((count) => {
                        prepaid += count.sum;
                      });
                    }

                    // Считаю доп сервисы
                    let additional_services =
                      (booking.hours_cost > 0
                        ? booking.additional_hours * booking.price_hour
                        : 0) +
                      booking.hours_cost_end +
                      booking.delivery +
                      booking.delivery_end +
                      booking.equipment +
                      booking.clean_payment +
                      booking.damage +
                      booking.gas +
                      booking.add_drivers_cost +
                      booking.insurance +
                      booking.fine +
                      booking.other +
                      booking.other_end;

                    // Считаю комиссию в сутки
                    let commission = booking.aggr_commission;
                    let commission_day = commission / (booking.days > 0 ? booking.days : 1);

                    var doc = new Docxtemplater().loadZip(zip);
                    doc.setData({
                      date: moment().format("DD/MM/YY"),
                      date_short: moment().format("DD/MM"),
                      serial_number: booking.serial_number ? booking.serial_number : "      ",
                      employee_full_name: self.user_full_name,
                      employee_short_name: self.user_fio,
                      client_id: client.id ? client.id : "      ",
                      booking_id: booking.id ? booking.id : "      ",
                      car_id: car.id ? car.id : "      ",
                      start_date: booking.start_date
                        ? booking.start_date
                        : "     ",
                      end_date: booking.end_date ? booking.end_date : "     ",
                      start_date_short: booking.start_date
                        ? moment(booking.start_date, "DD-MM-YYYY H:mm").format(
                            "DD.MM.YYYY"
                          )
                        : "     ",
                      end_date_short: booking.end_date
                        ? moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                            "DD.MM.YYYY"
                          )
                        : "     ",
                      location_start: booking.location_start
                        ? booking.location_start
                        : "      ",
                      location_end: booking.location_end
                        ? booking.location_end
                        : "      ",
                      delivery_start: booking.delivery
                        ? booking.delivery
                        : "      ",
                      delivery_end: booking.delivery_end
                        ? booking.delivery_end
                        : "      ",
                      equipment: booking.equipment
                        ? booking.equipment
                        : "      ",
                      hours_cost: booking.hours_cost
                        ? booking.hours_cost
                        : "      ",
                      additional_hours: booking.additional_hours
                        ? booking.additional_hours
                        : "      ",
                      add_drivers_cost: booking.add_drivers_cost
                        ? booking.add_drivers_cost
                        : "      ",
                      insurance: booking.insurance
                        ? booking.insurance
                        : "      ",
                      clean: booking.clean_payment
                        ? booking.clean_payment
                        : "      ",
                      other: booking.other ? booking.other : "      ",
                      has_delivery_start: booking.delivery ? true : false,
                      has_delivery_end: booking.delivery_end ? true : false,
                      has_equipment: booking.equipment ? true : false,
                      has_additional_hours: booking.additional_hours
                        ? true
                        : false,
                      has_hours_cost: booking.hours_cost ? true : false,
                      has_add_drivers_cost: booking.add_drivers_cost
                        ? true
                        : false,
                      has_insurance: booking.insurance ? true : false,
                      has_clean: booking.clean_payment ? true : false,
                      has_other: booking.other ? true : false,
                      has_sale: booking.sale ? true : false,
                      has_sale_cash: booking.sale_cash ? true : false,
                      sale: booking.sale ? booking.sale : "",
                      sale_cash: booking.sale_cash ? booking.sale_cash : "",
                      start_mileage: booking.start_mileage
                        ? booking.start_mileage
                        : "      ",
                      end_mileage: booking.end_mileage
                        ? booking.end_mileage
                        : "      ",
                      day_cost: car.prices.find(
                        (price) => price.season_id === null
                      )
                        ? car.prices.find((price) => price.season_id === null)
                            .values[0]
                        : booking.start_price,
                      price: booking.price ? booking.price : "      ",
                      day_cost_without_commission: car.prices && car.prices.find((price) => price.season_id === null)
                        ? (car.prices.find((price) => price.season_id === null)
                            .values[0] - commission_day).toFixed(2)
                        : booking.start_price,
                      price_without_commission: booking.price
                        ? (booking.price - commission_day).toFixed(2)
                        : "      ",
                      cost_without_commission: booking.rental_cost
                        ? (booking.rental_cost - commission).toFixed(2)
                        : "      ",
                      selected_price: booking.selected_price
                        ? booking.selected_price
                        : "      ",
                      cost: booking.rental_cost ? booking.rental_cost : "     ",
                      days: booking.days ? booking.days : "     ",
                      car_name: booking.car_name ? booking.car_name : "     ",
                      car_code: booking.car_code ? booking.car_code : "     ",
                      car_number: car.number ? car.number : "     ",
                      car_vin: car.vin ? car.vin : "     ",
                      car_body: car.body_number ? car.body_number : "     ",
                      car_pts: car.pts ? car.pts : "     ",
                      car_reg: car.registration_certificate
                        ? car.registration_certificate
                        : "     ",
                      car_color: car.color ? car.color : "     ",
                      car_year: car.year ? car.year : "      ",
                      car_fuel: car.fuel ? car.fuel : "     ",
                      price_hour: car.price_hour ? car.price_hour : "      ",
                      additional_services:
                        additional_services > 0
                          ? additional_services
                          : "      ",
                      has_additional_services:
                        additional_services > 0 ? true : false,
                      deposit: booking.deposit ? booking.deposit : "     ",
                      total: booking.total ? booking.total : "      ",
                      total_without_commission:
                        booking.total
                          ? booking.total - commission
                          : "      ",
                      total_with_deposit:
                        booking.total && booking.deposit
                          ? booking.total + booking.deposit
                          : "      ",
                      total_with_deposit_without_commission:
                        booking.total && booking.deposit
                          ? booking.total + booking.deposit - commission
                          : "      ",
                      prepaid: prepaid,
                      entity_name: client.entity_name
                        ? client.entity_name
                        : "     ",
                      ceo: client.ceo ? client.ceo : "     ",
                      reg_form: client.reg_form ? client.reg_form : "     ",
                      entity_phone: client.entity_phone
                        ? client.entity_phone
                        : "     ",
                      country: client.country ? client.country : "      ",
                      city: client.city ? client.city : "      ",
                      entity_adress: client.entity_adress
                        ? client.entity_adress
                        : "     ",
                      address: client.address ? client.address : "     ",
                      inn: client.inn ? client.inn : "     ",
                      ogrn: client.ogrn ? client.ogrn : "     ",
                      acc_number: client.acc_number
                        ? client.acc_number
                        : "     ",
                      bank: client.bank ? client.bank : "     ",
                      korr: client.korr ? client.korr : "     ",
                      bik: client.bik ? client.bik : "     ",
                      email: client.email ? client.email : "     ",
                      doc_number: client.doc_number
                        ? client.doc_number
                        : "     ",
                      add_drivers_info: add_drivers_info,
                      add_drivers_info_full: add_drivers_info_full,
                      has_add_drivers: self.add_drivers > 0 ? true : false,
                      add_drivers_array: add_drivers_array,
                      repair_cost: car.repair_cost ? car.repair_cost : "      ",
                      franchise: car.franchise ? car.franchise : "      ",
                      max_fine: car.max_fine ? car.max_fine : "      ",
                      custom_field_1: booking.custom_field_1
                        ? booking.custom_field_1
                        : "      ",
                      custom_field_2: booking.custom_field_2
                        ? booking.custom_field_2
                        : "      ",
                      custom_field_3: booking.custom_field_3
                        ? booking.custom_field_3
                        : "      ",
                      cars_custom_field_1: car.custom_field_1
                        ? car.custom_field_1
                        : "      ",
                      cars_custom_field_2: car.custom_field_2
                        ? car.custom_field_2
                        : "      ",
                      cars_custom_field_3: car.custom_field_3
                        ? car.custom_field_3
                        : "      ",
                    });

                    doc.render();

                    var out = doc.getZip().generate({
                      type: "blob",
                      mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    }); //Output the document using Data-URI
                    let date = moment().format("Y_MM_DD");
                    // console.log(client)
                    saveAs(out, date + "_" + client.entity_name + ".docx");
                  })
                  .catch((error) => {
                    this.$swal({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 5000,
                      icon: "error",
                      title: this.$t("errors.gen_template_title"),
                      text: this.$t("errors.gen_template_text"),
                    });
                    console.log(error);
                  });
              })
              .catch(function (error) {
                console.log(error);
              })
              .finally(() => (this.isLoading = false));
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.upload_template"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    generateProlongDoc(booking) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .get(`api/v1/prolong_templates`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let content, error, url;
            if (response.data && response.data.url) {
              url = response.data.url;
            } else {
              if (self.$i18n.locale == "ru") {
                url = "https://web.rentprog.ru/prolong_example.docx";
              } else {
                url = "https://web.rentprog.com/prolong_example_en.docx";
              }
            }
            axios
              .get(url, {
                responseType: "arraybuffer",
                headers: {
                  "Content-Type": "text/plain;charset=utf-8",
                },
              })
              .then((response) => {
                content = response.data;
                var zip = new PizZip(content);
                let client = self.client;
                let car = self.set_car;
                let add_drivers_info = "";
                let prolong_data = self.prolongData;
                if (self.add_drivers && self.add_drivers.length > 0) {
                  self.add_drivers.forEach((driver) => {
                    add_drivers_info += `${driver.lastname} ${driver.name} ${
                      driver.middlename
                    }, ${self.$t("custom.passport")}: ${
                      driver.passport_series
                    } ${driver.passport_number}, ${self.$t(
                      "custom.driver_license"
                    )}: ${driver.driver_series} ${driver.driver_number}${
                      self.add_drivers.length > 1 ? ", " : ""
                    }`;
                  });
                }
                // Считаю предоплату
                let prepaid = 0;
                if (booking.counts && booking.counts.length > 0) {
                  booking.counts.forEach((count) => {
                    prepaid += count.sum;
                  });
                }
                // Считаю доп сервисы
                let additional_services =
                  (booking.hours_cost > 0
                    ? booking.additional_hours * booking.price_hour
                    : 0) +
                  booking.hours_cost_end +
                  booking.delivery +
                  booking.delivery_end +
                  booking.equipment +
                  booking.clean_payment +
                  booking.damage +
                  booking.gas +
                  booking.add_drivers_cost +
                  booking.insurance +
                  booking.fine +
                  booking.other +
                  booking.other_end;

                // Считаю комиссию в сутки
                let commission = booking.aggr_commission;
                let commission_day = commission / (booking.days > 0 ? booking.days : 1);

                var doc = new Docxtemplater().loadZip(zip);
                doc.setData({
                  prolong_sum: prolong_data.price * prolong_data.days,
                  prolong_price: prolong_data.price,
                  prolong_days: prolong_data.days,
                  prolong_end_date: moment(booking.end_date, "DD-MM-YYYY H:mm")
                    .add(prolong_data.days, "days")
                    .format("DD.MM.YYYY"),
                  date: moment().format("DD/MM/YY"),
                  date_short: moment().format("DD/MM"),
                  serial_number: booking.serial_number ? booking.serial_number : "      ",
                  employee_full_name: self.user_full_name,
                  employee_short_name: self.user_fio,
                  first_name: client.name ? client.name : "      ",
                  middle_name: client.middlename ? client.middlename : "      ",
                  last_name: client.lastname ? client.lastname : "      ",
                  short_name: client.fio ? client.fio : "      ",
                  client_id: client.id ? client.id : "      ",
                  booking_id: booking.id ? booking.id : "      ",
                  car_id: car.id ? car.id : "      ",
                  date_birth: client.birthday
                    ? moment(client.birthday, "YYYY-MM-DD").format("DD-MM-YYYY")
                    : "      ",
                  passport_series: client.passport_series
                    ? client.passport_series
                    : "      ",
                  passport_number: client.passport_number
                    ? client.passport_number
                    : "      ",
                  passport_issued: client.passport_issued
                    ? client.passport_issued
                    : "      ",
                  driver_series: client.driver_series
                    ? client.driver_series
                    : "      ",
                  driver_number: client.driver_number
                    ? client.driver_number
                    : "      ",
                  driver_issued: client.driver_issued
                    ? client.driver_issued
                    : "      ",
                  inn: client.inn ? client.inn : "      ",
                  country: client.country ? client.country : "      ",
                  city: client.city ? client.city : "      ",
                  address: client.address ? client.address : "      ",
                  phone: client.phone ? client.phone : "      ",
                  email: client.email ? client.email : "      ",
                  entity_adress: client.entity_adress
                    ? client.entity_adress
                    : "     ",
                  entity_name: client.entity_name
                    ? client.entity_name
                    : "     ",
                  ceo: client.ceo ? client.ceo : "     ",
                  reg_form: client.reg_form ? client.reg_form : "     ",
                  entity_phone: client.entity_phone
                    ? client.entity_phone
                    : "     ",
                  ogrn: client.ogrn ? client.ogrn : "     ",
                  acc_number: client.acc_number ? client.acc_number : "     ",
                  bank: client.bank ? client.bank : "     ",
                  korr: client.korr ? client.korr : "     ",
                  bik: client.bik ? client.bik : "     ",
                  email: client.email ? client.email : "     ",
                  doc_number: client.doc_number ? client.doc_number : "     ",
                  start_date: booking.start_date
                    ? booking.start_date
                    : "      ",
                  end_date: booking.end_date ? booking.end_date : "      ",
                  start_date_short: booking.start_date
                    ? moment(booking.start_date, "DD-MM-YYYY H:mm").format(
                        "DD.MM.YYYY"
                      )
                    : "     ",
                  end_date_short: booking.end_date
                    ? moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                        "DD.MM.YYYY"
                      )
                    : "     ",
                  day_cost: car.prices.find((price) => price.season_id === null)
                    ? car.prices.find((price) => price.season_id === null)
                        .values[0]
                    : booking.start_price,
                  price: booking.price ? booking.price : "      ",
                  day_cost_without_commission: car.prices && car.prices.find((price) => price.season_id === null)
                    ? (car.prices.find((price) => price.season_id === null)
                        .values[0] - commission_day).toFixed(2)
                    : booking.start_price,
                  price_without_commission: booking.price
                    ? (booking.price - commission_day).toFixed(2)
                    : "      ",
                  cost_without_commission: booking.rental_cost
                    ? (booking.rental_cost - commission).toFixed(2)
                    : "      ",
                  selected_price: booking.selected_price
                    ? booking.selected_price
                    : "      ",
                  cost: booking.rental_cost ? booking.rental_cost : "      ",
                  days: booking.days ? booking.days : "      ",
                  location_start: booking.location_start
                    ? booking.location_start
                    : "      ",
                  location_end: booking.location_end
                    ? booking.location_end
                    : "      ",
                  delivery_start: booking.delivery
                    ? booking.delivery
                    : "      ",
                  delivery_end: booking.delivery_end
                    ? booking.delivery_end
                    : "      ",
                  equipment: booking.equipment ? booking.equipment : "      ",
                  hours_cost: booking.hours_cost
                    ? booking.hours_cost
                    : "      ",
                  additional_hours: booking.additional_hours
                    ? booking.additional_hours
                    : "      ",
                  add_drivers_cost: booking.add_drivers_cost
                    ? booking.add_drivers_cost
                    : "      ",
                  insurance: booking.insurance ? booking.insurance : "      ",
                  clean: booking.clean_payment
                    ? booking.clean_payment
                    : "      ",
                  other: booking.other ? booking.other : "      ",
                  has_delivery_start: booking.delivery ? true : false,
                  has_delivery_end: booking.delivery_end ? true : false,
                  has_equipment: booking.equipment ? true : false,
                  has_additional_hours: booking.additional_hours ? true : false,
                  has_hours_cost: booking.hours_cost ? true : false,
                  has_add_drivers_cost: booking.add_drivers_cost ? true : false,
                  has_insurance: booking.insurance ? true : false,
                  has_clean: booking.clean_payment ? true : false,
                  has_other: booking.other ? true : false,
                  has_sale: booking.sale ? true : false,
                  has_sale_cash: booking.sale_cash ? true : false,
                  sale: booking.sale ? booking.sale : "",
                  sale_cash: booking.sale_cash ? booking.sale_cash : "",
                  start_mileage: booking.start_mileage
                    ? booking.start_mileage
                    : "      ",
                  end_mileage: booking.end_mileage
                    ? booking.end_mileage
                    : "      ",
                  car_name: booking.car_name ? booking.car_name : "      ",
                  car_code: booking.car_code ? booking.car_code : "      ",
                  car_number: car.number ? car.number : "      ",
                  car_vin: car.vin ? car.vin : "      ",
                  car_body: car.body_number ? car.body_number : "      ",
                  car_pts: car.pts ? car.pts : "      ",
                  car_reg: car.registration_certificate
                    ? car.registration_certificate
                    : "      ",
                  car_color: car.color ? car.color : "      ",
                  car_year: car.year ? car.year : "      ",
                  car_fuel: car.fuel ? car.fuel : "      ",
                  price_hour: car.price_hour ? car.price_hour : "      ",
                  additional_services:
                    additional_services > 0 ? additional_services : "      ",
                  has_additional_services:
                    additional_services > 0 ? true : false,
                  deposit: booking.deposit ? booking.deposit : "      ",
                  total: booking.total ? booking.total : "      ",
                  total_without_commission:
                    booking.total
                      ? booking.total - commission
                      : "      ",
                  total_with_deposit:
                    booking.total && booking.deposit
                      ? booking.total + booking.deposit
                      : "      ",
                  total_with_deposit_without_commission:
                    booking.total && booking.deposit
                      ? booking.total + booking.deposit - commission
                      : "      ",
                  prepaid: prepaid,
                  add_drivers_info: add_drivers_info,
                  repair_cost: car.repair_cost ? car.repair_cost : "      ",
                  franchise: car.franchise ? car.franchise : "      ",
                  max_fine: car.max_fine ? car.max_fine : "      ",
                  custom_field_1: booking.custom_field_1
                    ? booking.custom_field_1
                    : "      ",
                  custom_field_2: booking.custom_field_2
                    ? booking.custom_field_2
                    : "      ",
                  custom_field_3: booking.custom_field_3
                    ? booking.custom_field_3
                    : "      ",
                  cars_custom_field_1: car.custom_field_1
                    ? car.custom_field_1
                    : "      ",
                  cars_custom_field_2: car.custom_field_2
                    ? car.custom_field_2
                    : "      ",
                  cars_custom_field_3: car.custom_field_3
                    ? car.custom_field_3
                    : "      ",
                });

                doc.render();

                var out = doc.getZip().generate({
                  type: "blob",
                  mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                let date = moment().format("Y_MM_DD");
                // console.log(client)
                saveAs(
                  out,
                  `${self.$t("custom.prolong_short_low")}_` +
                    date +
                    "_" +
                    booking.id +
                    ".docx"
                );
              })
              .catch(function (error) {
                console.log(error);
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "error",
                  title: this.$t("errors.gen_template_title"),
                  text: this.$t("errors.gen_template_text"),
                });
              });
          })
          .catch((error) => {
            this.isLoading = false;
            this.setError(error, this.$t("errors.upload_template"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    paymentStart() {
      this.departure_payments = {
        rent: { sum: 0, icon: null },
        deposit: { sum: 0, icon: null },
        delivery: { sum: 0, icon: null },
        delivery_end: { sum: 0, icon: null },
        clean: { sum: 0, icon: null },
        hours_cost: { sum: 0, icon: null },
        insurance: { sum: 0, icon: null },
        add_drivers_cost: { sum: 0, icon: null },
        equipment: { sum: 0, icon: null },
        fine: { sum: 0, icon: null },
        other: { sum: 0, icon: null },
      };
      this.payment_dialog = true;
    },
    showPayments(booking) {
      if (booking.counts) {
        if (booking.counts.length > 0) {
          return true;
        }
      }
    },
    calculatePayments(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          if (count.operation) {
            paid += count.sum;
          } else {
            paid -= count.sum;
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return self.$t("bookings.paid");
        } else {
          return paid - booking_sum >= 0
            ? `+ ${Math.round(paid - booking_sum)}`
            : ` ${Math.round(paid - booking_sum)}`;
        }
      }
    },
    calculatePaymentsSum(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          if (count.operation) {
            paid += count.sum;
          } else {
            paid -= count.sum;
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        return Math.round(paid);
      }
    },
    calculateRentPaymentsSum(booking) {
      if (booking != undefined) {
        let self = this;
        let paid = 0;
        booking.counts.forEach((count) => {
          if (count.group == "Оплата аренды") {
            if (count.operation) {
              paid += count.sum;
            } else {
              paid -= count.sum;
            }
          }
        });
        return Math.round(paid);
      }
    },
    selectColorPaid(booking) {
      if (booking != undefined) {
        let paid = 0;
        let booking_sum =
          booking.rental_cost +
          booking.delivery +
          booking.delivery_end +
          booking.equipment +
          booking.insurance +
          booking.clean_payment +
          booking.add_drivers_cost +
          booking.hours_cost +
          booking.mileage_cost +
          booking.damage +
          booking.gas +
          booking.fine +
          booking.other +
          booking.other_end +
          booking.hours_cost_end;
        booking.counts.forEach((count) => {
          if (count.operation) {
            paid += count.sum;
          } else {
            paid -= count.sum;
          }
        });
        // Отнимаем залог у непринятых броней
        if (!booking.ride) {
          booking_sum += booking.deposit;
        }
        if (paid == booking_sum) {
          return "success";
        } else if (paid - booking_sum > 0) {
          return "warning";
        } else if (paid - booking_sum < 0) {
          return "error";
        }
      }
    },
    savePayment(booking) {
      if (this.user_role != "guest") {
        let self = this;
        if (
          self.departure_payments.rent.sum === "" &&
          self.departure_payments.deposit.sum === "" &&
          self.departure_payments.delivery.sum === "" &&
          self.departure_payments.delivery_end.sum === "" &&
          self.departure_payments.clean.sum === "" &&
          self.departure_payments.hours_cost.sum === "" &&
          self.departure_payments.equipment.sum === "" &&
          self.departure_payments.insurance.sum === "" &&
          self.departure_payments.fine.sum === "" &&
          self.departure_payments.add_drivers_cost.sum === "" &&
          self.departure_payments.other.sum === ""
        ) {
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: self.$t("errors.empty_value_title"),
            text: self.$t("errors.empty_value_text"),
          });
        } else if (
          self.departure_payments.rent.sum == 0 &&
          self.departure_payments.deposit.sum == 0 &&
          self.departure_payments.delivery.sum == 0 &&
          self.departure_payments.delivery_end.sum == 0 &&
          self.departure_payments.clean.sum == 0 &&
          self.departure_payments.hours_cost.sum == 0 &&
          self.departure_payments.equipment.sum == 0 &&
          self.departure_payments.insurance.sum == 0 &&
          self.departure_payments.fine.sum == 0 &&
          self.departure_payments.add_drivers_cost.sum == 0 &&
          self.departure_payments.other.sum == 0
        ) {
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "error",
            title: self.$t("errors.zero_value_title"),
            text: self.$t("errors.zero_value_text"),
          });
        } else {
          if (
            (self.departure_payments.rent.sum != 0 &&
              self.departure_payments.rent.type == null) ||
            (self.departure_payments.deposit.sum != 0 &&
              self.departure_payments.deposit.type == null) ||
            (self.departure_payments.delivery.sum != 0 &&
              self.departure_payments.delivery.type == null) ||
            (self.departure_payments.delivery_end.sum != 0 &&
              self.departure_payments.delivery_end.type == null) ||
            (self.departure_payments.clean.sum != 0 &&
              self.departure_payments.clean.type == null) ||
            (self.departure_payments.hours_cost.sum != 0 &&
              self.departure_payments.hours_cost.type == null) ||
            (self.departure_payments.equipment.sum != 0 &&
              self.departure_payments.equipment.type == null) ||
            (self.departure_payments.insurance.sum != 0 &&
              self.departure_payments.insurance.type == null) ||
            (self.departure_payments.fine.sum != 0 &&
              self.departure_payments.fine.type == null) ||
            (self.departure_payments.add_drivers_cost.sum != 0 &&
              self.departure_payments.add_drivers_cost.type == null) ||
            (self.departure_payments.other.sum != 0 &&
              self.departure_payments.other.type == null)
          ) {
            self.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "warning",
              title: self.$t("errors.type_payment_title"),
              text: self.$t("errors.type_payment_text"),
            });
          } else {
            this.isLoading = true;
            function calcTypePayment() {
              let cash = 0;
              let cashless = 0;
              let card_to_card = 0;
              let entity = 0;
              if (self.departure_payments.rent.type == "Наличные") {
                cash += self.departure_payments.rent.sum;
              }
              if (self.departure_payments.deposit.type == "Наличные") {
                cash += self.departure_payments.deposit.sum;
              }
              if (self.departure_payments.delivery.type == "Наличные") {
                cash += self.departure_payments.delivery.sum;
              }
              if (self.departure_payments.delivery_end.type == "Наличные") {
                cash += self.departure_payments.delivery_end.sum;
              }
              if (self.departure_payments.clean.type == "Наличные") {
                cash += self.departure_payments.clean.sum;
              }
              if (self.departure_payments.hours_cost.type == "Наличные") {
                cash += self.departure_payments.hours_cost.sum;
              }
              if (self.departure_payments.insurance.type == "Наличные") {
                cash += self.departure_payments.insurance.sum;
              }
              if (self.departure_payments.fine.type == "Наличные") {
                cash += self.departure_payments.fine.sum;
              }
              if (self.departure_payments.equipment.type == "Наличные") {
                cash += self.departure_payments.equipment.sum;
              }
              if (self.departure_payments.add_drivers_cost.type == "Наличные") {
                cash += self.departure_payments.add_drivers_cost.sum;
              }
              if (self.departure_payments.other.type == "Наличные") {
                cash += self.departure_payments.other.sum;
              }

              if (self.departure_payments.rent.type == "Терминал") {
                cashless += self.departure_payments.rent.sum;
              }
              if (self.departure_payments.deposit.type == "Терминал") {
                cashless += self.departure_payments.deposit.sum;
              }
              if (self.departure_payments.delivery.type == "Терминал") {
                cashless += self.departure_payments.delivery.sum;
              }
              if (self.departure_payments.delivery_end.type == "Терминал") {
                cashless += self.departure_payments.delivery_end.sum;
              }
              if (self.departure_payments.clean.type == "Терминал") {
                cashless += self.departure_payments.clean.sum;
              }
              if (self.departure_payments.hours_cost.type == "Терминал") {
                cashless += self.departure_payments.hours_cost.sum;
              }
              if (self.departure_payments.insurance.type == "Терминал") {
                cashless += self.departure_payments.insurance.sum;
              }
              if (self.departure_payments.fine.type == "Терминал") {
                cashless += self.departure_payments.fine.sum;
              }
              if (self.departure_payments.equipment.type == "Терминал") {
                cashless += self.departure_payments.equipment.sum;
              }
              if (self.departure_payments.add_drivers_cost.type == "Терминал") {
                cashless += self.departure_payments.add_drivers_cost.sum;
              }
              if (self.departure_payments.other.type == "Терминал") {
                cashless += self.departure_payments.other.sum;
              }

              if (self.departure_payments.rent.type == "На карту") {
                card_to_card += self.departure_payments.rent.sum;
              }
              if (self.departure_payments.deposit.type == "На карту") {
                card_to_card += self.departure_payments.deposit.sum;
              }
              if (self.departure_payments.delivery.type == "На карту") {
                card_to_card += self.departure_payments.delivery.sum;
              }
              if (self.departure_payments.delivery_end.type == "На карту") {
                card_to_card += self.departure_payments.delivery_end.sum;
              }
              if (self.departure_payments.clean.type == "На карту") {
                card_to_card += self.departure_payments.clean.sum;
              }
              if (self.departure_payments.hours_cost.type == "На карту") {
                card_to_card += self.departure_payments.hours_cost.sum;
              }
              if (self.departure_payments.insurance.type == "На карту") {
                card_to_card += self.departure_payments.insurance.sum;
              }
              if (self.departure_payments.fine.type == "На карту") {
                card_to_card += self.departure_payments.fine.sum;
              }
              if (self.departure_payments.equipment.type == "На карту") {
                card_to_card += self.departure_payments.equipment.sum;
              }
              if (self.departure_payments.add_drivers_cost.type == "На карту") {
                card_to_card += self.departure_payments.add_drivers_cost.sum;
              }
              if (self.departure_payments.other.type == "На карту") {
                card_to_card += self.departure_payments.other.sum;
              }

              if (self.departure_payments.rent.type == "Р/с") {
                entity += self.departure_payments.rent.sum;
              }
              if (self.departure_payments.deposit.type == "Р/с") {
                entity += self.departure_payments.deposit.sum;
              }
              if (self.departure_payments.delivery.type == "Р/с") {
                entity += self.departure_payments.delivery.sum;
              }
              if (self.departure_payments.delivery_end.type == "Р/с") {
                entity += self.departure_payments.delivery_end.sum;
              }
              if (self.departure_payments.clean.type == "Р/с") {
                entity += self.departure_payments.clean.sum;
              }
              if (self.departure_payments.hours_cost.type == "Р/с") {
                entity += self.departure_payments.hours_cost.sum;
              }
              if (self.departure_payments.insurance.type == "Р/с") {
                entity += self.departure_payments.insurance.sum;
              }
              if (self.departure_payments.fine.type == "Р/с") {
                entity += self.departure_payments.fine.sum;
              }
              if (self.departure_payments.equipment.type == "Р/с") {
                entity += self.departure_payments.equipment.sum;
              }
              if (self.departure_payments.add_drivers_cost.type == "Р/с") {
                entity += self.departure_payments.add_drivers_cost.sum;
              }
              if (self.departure_payments.other.type == "Р/с") {
                entity += self.departure_payments.other.sum;
              }

              return {
                cash: cash,
                cashless: cashless,
                card_to_card: card_to_card,
                entity: entity,
              };
            }
            this.axios
              .post(
                `/api/v1/save_count`,
                {
                  booking_id: booking.id,
                  car_id: booking.car_id,
                  calc: calcTypePayment(),
                  departure_payments: self.departure_payments,
                },
                {
                  headers: {
                    Authorization: self.$store.getters.getAuthToken,
                  },
                }
              )
              .then((response) => {
                // добавляем счета в бронь
                response.data.forEach((count) =>
                  self.booking.counts.push(count)
                );
              })
              .catch((error) => {
                this.setError(error, this.$t("errors.add_payment"));
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "error",
                  title: this.$t("errors.add_payment"),
                  text: this.error,
                });
              })
              .finally(() => {
                // self.closePaymentDialog()
                this.showAddPayment = false;
                this.payment_dialog = false;
                self.isLoading = false;
                self.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 5000,
                  icon: "success",
                  title: self.$t("bookings.payment_save"),
                });
              });
          }
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    getCurrentMileage(booking) {
      this.isLoading = true;
      this.axios
        .get(`/api/v1/cars/${booking.car_id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.booking.start_mileage = response.data.mileage;
          this.check_mileage = this.booking.start_mileage;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.get_mileage"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    checkPayments() {
      if (!this.booking.counts.length > 0 && !this.booking.technical) {
        this.$swal({
          title: this.$t("bookings.checkin_not_pay_title"),
          text: this.$t("bookings.checkin_not_pay_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.yes"),
          confirmButtonText: this.$t("custom.yes"),
          cancelButtonText: this.$t("custom.no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.departureDialog = true;
          }
        });
      } else {
        this.departureDialog = true;
      }
    },
    checkBookingsDates(car, checked_booking) {
      let self = this;
      if (car && checked_booking) {
        let start_date = checked_booking.start_date;
        let end_date = checked_booking.end_date;
        let cars_bookings = this.bookings.filter((booking) => {
          return booking.car_id === car.id && booking.id != checked_booking.id;
        });
        cars_bookings.forEach((booking) => {
          let checked_start_date = moment(start_date, "DD-MM-YYYY H:mm");
          let checked_end_date = moment(end_date, "DD-MM-YYYY H:mm");
          let booking_start_date = moment(
            booking.start_date,
            "DD-MM-YYYY H:mm"
          ).subtract(
            this.$store.getters.getCompany.company.interval_bookings,
            "hours"
          );
          let booking_end_date = moment(
            booking.end_date,
            "DD-MM-YYYY H:mm"
          ).add(
            this.$store.getters.getCompany.company.interval_bookings,
            "hours"
          );
          if (
            checked_start_date.isBetween(
              booking_start_date,
              booking_end_date,
              "hour"
            ) ||
            checked_end_date.isBetween(
              booking_start_date,
              booking_end_date,
              "hour"
            ) ||
            booking_start_date.isBetween(
              checked_start_date,
              checked_end_date,
              "hour"
            ) ||
            booking_end_date.isBetween(
              checked_start_date,
              checked_end_date,
              "hour"
            )
          ) {
            this.$swal({
              showConfirmButton: true,
              icon: "warning",
              title: this.$t("errors.booked_title"),
              text: this.$t("errors.booked_text"),
            });
          }
        });
      }
    },
    editFine(fine) {
      this.isLoading = true;
      let self = this;
      if (fine.client_id) {
        this.axios
          .get(`/api/v1/clients/${fine.client_id}`, {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            let client = response.data.client;

            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                client.ogrn;
            }
            this.client = client;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.get_client"));
            console.log(error);
          });
      }

      this.axios
        .get(`/api/v1/fines/${fine.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedFine = response.data;
          this.date = response.data.date;
          this.editFineDialog = true;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.get_fines"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    saveFine() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let fine = this.editedFine;
        let self = this;
        this.axios
          .patch(
            `/api/v1/fines/${fine.id}`,
            {
              fines: {
                sum: fine.sum,
                date: this.date,
                state: fine.state,
                description: fine.description,
                number: fine.number,
                client_id: this.client ? this.client.id : null,
                booking_id: fine.booking_id,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.editFineDialog = false;
            let foundIndex = this.fines.findIndex(
              (element) => element.id === fine.id
            );
            this.fines.splice(foundIndex, 1, response.data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("bookings.fine_update_title"),
              text: this.$t("bookings.fine_update_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_fines"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.edit_fines"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateState(id, state) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/fines/${id}`,
            {
              fines: {
                state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.fines.findIndex(
              (element) => element.id === id
            );
            this.fines.splice(foundIndex, 1, response.data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("bookings.fine_update_title"),
              text: this.$t("bookings.fine_update_title"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_fines"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.edit_fines"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteFine(fine) {
      if (this.user_role != "guest") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/fines/${fine.id}`, {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.fines.splice(this.fines.indexOf(fine), 1);
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("fines.deleted_text"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_fine"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    finePaymentStart(number) {
      this.finePaymentData = {
        sum: null,
        icon: null,
        state: null,
        number: number,
      };
      this.finePaymentDialog = true;
    },
    findWorker(id) {
      let name;
      if (this.workers.find((worker) => worker.id == id)) {
        name = this.workers.find((worker) => worker.id == id).name
          ? this.workers.find((worker) => worker.id == id).name
          : this.workers.find((worker) => worker.id == id).email;
      }
      if (name) {
        return name;
      } else {
        return id;
      }
    },
    stateFineColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "red";
      }
      if (state == "Возмещён") {
        return "success";
      }
    },
    stateInvoiceColor(state) {
      if (state == "Выставлен") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Частично оплачен") {
        return "pink";
      }
    },
    saveBookingState(state, id) {
      if (this.user_role != "guest") {
        let self = this;
        if (state && id) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/bookings/${id}`,
              {
                bookings: {
                  state: state,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.booking.state = state;
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.update_state"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.update_state_title"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.select_state"),
            text: this.error,
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkEnter(event, booking_id) {
      // Проверяем что нажат только Enter и отправляем сообщение
      // Если нажат Shift + Enter, то переносим строку
      if (event.keyCode == 13 && !event.shiftKey) {
        event.preventDefault();
        this.sendMessage(booking_id);
      }
    },
    sendMessage(booking_id) {
      if (
        this.new_message &&
        this.new_message.length > 0 &&
        this.new_message != " " &&
        this.new_message.length <= 500 &&
        !this.send_message_loading &&
        booking_id
      ) {
        if (booking_id) {
          this.send_message_loading = true;
          this.axios
            .post("/api/v1/vseprokaty_send_message", {
              booking_id: booking_id,
              body: this.new_message,
            })
            .then((response) => {
              this.new_message = null;
              let oldMessages = this.messages;
              oldMessages.push(response.data);
              this.$store.commit("setMessages", oldMessages);
              this.send_message_loading = false;
            })
            .catch((error) => {
              this.send_message_loading = false;
              this.setError(error, this.$t("messages.error_send_message"));
              console.log(error);
            });
        } else {
          this.setError(null, this.$t("messages.error_send_message"));
        }
      }
    },
    selectIcon(sender) {
      if (sender == "User") {
        return "mdi-account-alert";
      } else if (sender == "Host") {
        return "mdi-home-account";
      } else if (sender == "Admin") {
        return "mdi-account-cog-outline";
      } else {
        return "mdi-robot-outline";
      }
    },
    returnDate(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD H:mm").format("DD MMM H:mm");
      } else {
        return "-";
      }
    },
    formatCreatedAtWithYear(date) {
      if (date) {
        return moment.parseZone(date).format("DD-MM-YY H:mm");
      } else {
        return "-";
      }
    },
    // durationNowAndCteatedAt(date) {
    //   if (date) {
    //     return moment.duration(moment().diff(moment(date).add(1, 'hours'))).asMilliseconds() * -1
    //   } else {
    //     return 0;
    //   }
    // },
    loadFile(url, callback) {
      JSZipUtils.getBinaryContent(url, callback);
    },
    checkCashPayment() {
      if (this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company.cash_payment;
      }
    },
    checkCardToCardPayment() {
      if (this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company.card_to_card_payment;
      }
    },
    checkCashlessPayment() {
      if (this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company.cashless_payment;
      }
    },
    checkEntityPayment() {
      if (this.$store.getters.getCompany.company) {
        return this.$store.getters.getCompany.company.entity_payment;
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    formatPrologDate(date) {
      return moment.utc(date).format("DD MMM H:mm");
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
        this.paymentsPanel = [];
        this.prolongsPanel = [];
        this.giftsPanel = [];
      } else {
        this.paymentsPanel = 0;
        this.prolongsPanel = 0;
        this.giftsPanel = 0;
        this.isMobile = false;
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    closePaymentDialog() {
      this.showAddPayment = false;
      this.payment_dialog = false;
    },
    closeArrivalDialog() {
      this.showArrivalPayments = false;
      this.showProblems = false;
      this.dont_send_review_reminder = false;
      this.showPartRejection = false;
      this.partReturnDeposit = false;
      this.giveFullDeposit = true;
      this.giveNullDeposit = false;
      this.showCleanPaid = false;
      // !!!!
      // this.client = "";
      this.arrival_payments = {
        rent: { sum: 0, icon: null },
        clean: { sum: 0, icon: null },
        delivery: { sum: 0, icon: null },
        delivery_end: { sum: 0, icon: null },
        additional_hours: { sum: 0, icon: null },
        insurance: { sum: 0, icon: null },
        add_drivers_cost: { sum: 0, icon: null },
        gas: { sum: 0, icon: null },
        equipment: { sum: 0, icon: null },
        mileage: { sum: 0, icon: null },
        fine: { sum: 0, icon: null },
        damage: { sum: 0, icon: null },
        other: { sum: 0, icon: null },
        deposit: { sum: 0, icon: null },
      };
      this.arrivalDialog = false;
    },
    editDebt(id) {
      this.debt = this.debts.find((debt) => debt.id == id);
      this.editDebtDialog = true;
    },
    saveDebt() {
      if (this.user_role != "guest") {
        let debt = this.debt;
        let self = this;
        if (debt.sum && debt.client_id && debt.group) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/debts/${debt.id}`,
              {
                debts: {
                  sum: debt.sum,
                  state: debt.state,
                  group: debt.group,
                  description: debt.description,
                  client_id: debt.client_id,
                  booking_id: debt.booking_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.debts.findIndex(
                (element) => element.id === debt.id
              );
              this.debts.splice(foundIndex, 1, response.data);

              this.debt = {
                sum: null,
                state: "Создан",
                description: null,
                group: "Аренда",
                client_id: null,
                booking_id: null,
              };
              this.editDebtDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: "Долг обновлен!",
                text: "Вы успешно обновили долг!",
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.update_state_deb"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.update_state_deb"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
            text: this.$t("errors.most_field_debt"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    paymentDebtStart(debt) {
      this.paymentData = {
        sum: debt.sum,
        icon: null,
        group: debt.group,
        state: "Оплачен",
        debt_id: debt.id,
      };
      this.paymentDebtDialog = true;
    },
    saveDebtPayment() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        if (this.paymentData.sum && this.paymentData.type) {
          this.axios
            .post(
              `/api/v1/debt_payment`,
              {
                sum: this.paymentData.sum,
                icon: this.paymentData.type,
                state: this.paymentData.state,
                group: this.paymentData.group,
                debt_id: this.paymentData.debt_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.debts.findIndex(
                (element) => element.id === self.paymentData.debt_id
              );
              this.debts[foundIndex].state = this.paymentData.state;

              this.paymentDebtDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("debts.debt_paid_title"),
                text: this.$t("debts.debt_paid_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.pay_debt"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.pay_debt"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("custom.no_data"),
            text: this.$t("errors.pay_debt_fields"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateDebtState(id, state) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/debts/${id}`,
            {
              debts: {
                state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.debts.findIndex(
              (element) => element.id === id
            );
            this.debts.splice(foundIndex, 1, response.data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("debts.updated_debt"),
              text: this.$t("debts.updated_debt_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_debt"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.edit_debt"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    stateDebtColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Не оплачен") {
        return "red";
      }
    },
    deleteDebt(debt) {
      if (this.user_role != "guest") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/debts/${debt.id}`, {
                headers: {
                  Authorization: self.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.debts.splice(this.debts.indexOf(debt), 1);
                this.$swal(
                  this.$t("custom.deleted"),
                  this.$t("debts.delete_debt_text"),
                  "success"
                );
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_debt"))
              )
              .finally(() => (this.isLoading = false));
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    translateCountGroup(category) {
      let group_text = "";
      switch (category) {
        case "Оплата аренды":
          group_text = this.$t("groups_counts.rent");
          break;
        case "Доплата за доставку":
          group_text = this.$t("groups_counts.delivery");
          break;
        case "Доплата за приём":
          group_text = this.$t("groups_counts.checkout");
          break;
        case "Доплата за топливо":
          group_text = this.$t("groups_counts.fuel");
          break;
        case "Доплата за мойку":
          group_text = this.$t("groups_counts.clean");
          break;
        case "Доплата за оборудование":
          group_text = this.$t("groups_counts.equipment");
          break;
        case "Доплата за дополнительное время":
          group_text = this.$t("groups_counts.add_time");
          break;
        case "Доплата за повреждения":
          group_text = this.$t("groups_counts.damages");
          break;
        case "Доплата за штрафы":
          group_text = this.$t("groups_counts.fines");
          break;
        case "Доплата за другое":
          group_text = this.$t("groups_counts.other");
          break;
        case "Доплата за превышение пробега":
          group_text = this.$t("groups_counts.mileage");
          break;
        case "Доплата за страховку":
          group_text = this.$t("groups_counts.insurance");
          break;
        case "Доплата за доп водителей":
          group_text = this.$t("groups_counts.add_drivers");
          break;
        case "Ремонт":
          group_text = this.$t("groups_counts.repair");
          break;
        case "Обслуживание":
          group_text = this.$t("groups_counts.service");
          break;
        case "Запчасти":
          group_text = this.$t("groups_counts.parts");
          break;
        case "Страховка":
          group_text = this.$t("groups_counts.insurance_main");
          break;
        case "Накладные расходы":
          group_text = this.$t("groups_counts.common_expences");
          break;
        case "Транспортные расходы":
          group_text = this.$t("groups_counts.transport_expences");
          break;
        case "Налоги":
          group_text = this.$t("groups_counts.tax");
          break;
        case "Штрафы":
          group_text = this.$t("groups_counts.fines_main");
          break;
        case "Аренда имущества":
          group_text = this.$t("groups_counts.rent_property");
          break;
        case "Мойка автомобилей":
          group_text = this.$t("groups_counts.clean_main");
          break;
        case "Топливо":
          group_text = this.$t("groups_counts.fuel_main");
          break;
        case "Реклама":
          group_text = this.$t("groups_counts.ads");
          break;
        case "Зарплата":
          group_text = this.$t("groups_counts.salary");
          break;
        case "Залог":
          group_text = this.$t("groups_counts.deposit");
          break;
        case "Внутренние переводы":
          group_text = this.$t("groups_counts.internal");
          break;
        case "Продажа имущества":
          group_text = this.$t("groups_counts.sell_property");
          break;
        case "Покупка имущества":
          group_text = this.$t("groups_counts.buy_property");
          break;
        case "Прочие расходы":
          group_text = this.$t("groups_counts.other_expences");
          break;
        case "Прочие доходы":
          group_text = this.$t("groups_counts.other_incomes");
          break;
        case "Выплаты партнёрам":
          group_text = this.$t("groups_counts.partners_payments");
          break;
        case "Сервисные сборы":
          group_text = this.$t("groups_counts.service_fees");
          break;
      }
      return group_text;
    },
    translateState(state) {
      let group_text = "";
      switch (state) {
        case "Новая":
          group_text = this.$t("bookings_states.new");
          break;
        case "В обработке":
          group_text = this.$t("bookings_states.in_work");
          break;
        case "Отказ клиента":
          group_text = this.$t("bookings_states.decline");
          break;
        case "Нет машин":
          group_text = this.$t("bookings_states.no_cars");
          break;
        case "Недозвон":
          group_text = this.$t("bookings_states.abandoned");
          break;
        case "Ожидает ответа клиента":
          group_text = this.$t("bookings_states.wait_answer");
          break;
        case "Ожидает оплаты":
          group_text = this.$t("bookings_states.wait_pay");
          break;
        case "Активная":
          group_text = this.$t("bookings_states.active");
          break;
        case "Отмена":
          group_text = this.$t("bookings_states.cancel");
          break;
        case "Отъездила":
          group_text = this.$t("bookings_states.rided");
          break;
        case "Вернуть залог":
          group_text = this.$t("bookings_states.return_deposit");
          break;
        case "Подтверждена":
          group_text = this.$t("bookings_states.accepted");
          break;
        case "Не подтверждена":
          group_text = this.$t("bookings_states.not_accepted");
          break;
        case "Другое":
          group_text = this.$t("bookings_states.other");
          break;
      }
      return group_text;
    },
    unsubscribe() {
      this.$cable.unsubscribe("BookingChannel");
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.isLoading = false;
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    UppyCheckin,
    Gallery,
  },
};
</script>
<style>
.pulse {
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
}
.pulse.pulse_success {
  background: rgba(0, 179, 0, 1);
  box-shadow: 0 0 0 0 rgba(0, 179, 0, 1);
  animation: pulse-green 2s infinite;
}
.pulse.pulse_error {
  background: rgb(239, 24, 24);
  box-shadow: 0 0 0 0 rgb(239, 24, 24);
  animation: pulse-error 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
@keyframes pulse-error {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(217, 51, 51, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(217, 51, 51, 0);
  }
}
.vl {
  border-left: 1px solid black;
  height: auto;
  margin-right: 1% !important;
  margin-left: 1% !important;
}
</style>
