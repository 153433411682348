<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title class="start-tour-repairs-1">
        <v-spacer></v-spacer>
        <h2 v-if="!isMobile">{{ $t("services.tech") }}</h2>
        <h4 v-else>{{ $t("services.tech") }}</h4>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/7/guides/23`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-data-table
          :headers="headers"
          :items="cars"
          :loading="isLoading"
          :loading-text="$t('custom.loading_table')"
          disable-pagination
          hide-default-footer
          mobile-breakpoint="100"
          dense
        >
          <template slot="item" slot-scope="props">
            <tr>
              <td class="text-center">
                <router-link :to="{ path: `/cars/${props.item.id}` }">{{
                  props.item.car_name
                }}</router-link>
              </td>
              <td class="text-center">{{ props.item.code }}</td>
              <td class="text-center">{{ props.item.number }}</td>
              <td class="text-center">{{ props.item.mileage }}</td>
              <td class="text-center">
                <p
                  :class="
                    repair && repair.warning
                      ? 'black--text red accent-2'
                      : 'black--text amber accent-2'
                  "
                  style="cursor: pointer"
                  v-for="repair in currentRepair(props.item)"
                  :key="repair.id"
                  @click="repairEnd(repair)"
                >
                  {{ repair.description }}
                </p>
                <p
                  v-if="checkOilEngine(props.item)"
                  :class="checkOilEngineColor(props.item)"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_oil_engine = true)"
                >
                  {{ $t("services.oil") }}
                </p>
                <p
                  v-if="checkOilTransmission(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_oil_transmission = true)"
                >
                  {{ $t("services.kpp") }}
                </p>
                <p
                  v-if="checkGrm(props.item)"
                  :class="checkGrmColor(props.item)"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_grm = true)"
                >
                  {{ $t("services.grm") }}
                </p>
                <p
                  v-if="checkAntifreeze(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_antifreeze = true)"
                >
                  {{ $t("services.antifreeze") }}
                </p>
                <p
                  v-if="checkBrake(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_brake = true)"
                >
                  {{ $t("services.brake_fluid") }}
                </p>
                <p
                  v-if="checkCandle(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_candle = true)"
                >
                  {{ $t("services.candles") }}
                </p>
                <p
                  v-if="checkPowerSteering(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_power_steering = true)"
                >
                  {{ $t("services.gur") }}
                </p>
                <p
                  v-if="checkSalonFilter(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_salon_filter = true)"
                >
                  {{ $t("services.cabin_filter") }}
                </p>
                <p
                  v-if="checkAkb(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_akb = true)"
                >
                  {{ $t("services.battery") }}
                </p>
                <p
                  v-if="checkFuelFilter(props.item)"
                  class="black--text amber accent-2"
                  style="cursor: pointer"
                  @click="getCar(props.item), (dialog_fuel_filter = true)"
                >
                  {{ $t("services.gas_filter") }}
                </p>
              </td>
            </tr>
          </template>
          <template slot="no-data">
            {{ $t("custom.no_data_in_table") }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog_oil_engine"
      :retain-focus="false"
      persistent
      :fullscreen="isMobile ? true : false"
      max-width="500px"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.oil_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.oil_engine_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.oil_engine"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_oil_engine = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_oil_transmission"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.kpp_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.oil_transmission_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.oil_transmission"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_oil_transmission = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_grm"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.grm_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.grm_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.grm"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_grm = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_antifreeze"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.antifreeze_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.antifreeze_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.antifreeze"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_antifreeze = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_brake"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.brake_fluid_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.brake_fluid_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.brake_fluid"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_brake = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_candle"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.candles_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.candle_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.candle"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_candle = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_salon_filter"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.cabin_filter_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.salon_filter_renew_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.salon_filter_renew"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_salon_filter = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_fuel_filter"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.gas_filter_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.fuel_filter_renew_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.fuel_filter_renew"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog_fuel_filter = false"
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_akb"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.battery_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.akb_renew_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.akb_renew"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_akb = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_power_steering"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.gur_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex md12>
            <v-text-field
              v-model="editedCar.power_steering_date"
              :label="$t('services.renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
          <v-flex md12>
            <v-text-field
              v-model="editedCar.power_steering"
              :label="$t('services.renew_km')"
              type="number"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_power_steering = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
export default {
  data() {
    return {
      cars: [],
      editedCar: [],
      headers: [
        { text: this.$t("cars.name"), value: "car_name", align: "center" },
        { text: this.$t("cars.code"), value: "car_code", align: "center" },
        { text: this.$t("cars.gov_number"), value: "number", align: "center" },
        {
          text: this.$t("bookings.mileage"),
          value: "mileage",
          align: "center",
        },
        { text: this.$t("custom.current"), sortable: false, align: "center" },
      ],
      dialog_oil_engine: false,
      dialog_oil_transmission: false,
      dialog_grm: false,
      dialog_antifreeze: false,
      dialog_brake: false,
      dialog_candle: false,
      dialog_power_steering: false,
      dialog_salon_filter: false,
      dialog_akb: false,
      dialog_fuel_filter: false,
      checkWarning: false,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/services.json", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cars = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.services_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted() {
    if (
      this.$tours["registrationTour"] &&
      this.$store.getters.getTourNextStep
    ) {
      this.$tours["registrationTour"].nextStep();
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    currentRepair(car) {
      return car.repairs;
    },
    repairEnd(repair) {
      if (this.user_role != "guest") {
        let self = this;
        this.$swal({
          title: this.$t("services.repair_ended"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("services.repair_ended_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/cars/${repair.car_id}/repairs/${repair.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.axios
                  .get("/api/v1/services", {
                    headers: {
                      Authorization: this.$store.getters.getAuthToken,
                    },
                  })
                  .then((response) => {
                    this.cars = response.data;
                  })
                  .catch((error) => {
                    this.setError(error, this.$t("errors.services_load"));
                    console.log(error);
                  });
                //    this.cars.find(car => car.id === repair.car_id).repairs.remove(item => item.id == repair.id )

                // console.log(car.indexOf(repair))
                // console.log(car.repairs.splice(car.repairs.indexOf(repair), 1))
                // car.repairs.splice(car.repairs.indexOf(repair), 1)
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.repair_delete"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("services.repair_ended_title"),
              text: this.$t("services.repair_ended_text"),
            });
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkOilEngine(car) {
      if (car.mileage - car.oil_engine > car.oil_engine_km - 1000) {
        return true;
      }
    },
    checkOilEngineColor(car) {
      let diff = car.mileage - car.oil_engine;
      if (diff >= car.oil_engine_km - 1000 && diff < car.oil_engine_km) {
        return "black--text yellow accent-2";
      }
      if (diff >= car.oil_engine_km) {
        this.checkWarning = true;
        return "black--text red accent-2";
      }
    },
    checkOilTransmission(car) {
      if (car.mileage - car.oil_transmission > car.oil_transmission_km) {
        return true;
      }
    },
    checkGrm(car) {
      if (car.mileage - car.grm > car.grm_km - 5000) {
        return true;
      }
    },
    checkGrmColor(car) {
      let diff = car.mileage - car.grm;
      if (diff >= car.grm_km - 5000 && diff < car.grm_km) {
        return "black--text yellow accent-2";
      }
      if (diff >= car.grm_km) {
        this.checkWarning = true;
        return "black--text red accent-2";
      }
    },
    checkAntifreeze(car) {
      if (car.mileage - car.antifreeze > car.antifreeze_km) {
        return true;
      }
    },
    checkBrake(car) {
      if (car.mileage - car.brake_fluid > car.brake_fluid_km) {
        return true;
      }
    },
    checkCandle(car) {
      if (car.mileage - car.candle > car.candle_km) {
        return true;
      }
    },
    checkPowerSteering(car) {
      if (car.mileage - car.power_steering > car.power_steering_km) {
        return true;
      }
    },
    checkSalonFilter(car) {
      if (car.mileage - car.salon_filter_renew > car.salon_filter_renew_km) {
        return true;
      }
    },
    checkAkb(car) {
      if (car.mileage - car.akb_renew > car.akb_renew_km) {
        return true;
      }
    },
    checkFuelFilter(car) {
      if (car.mileage - car.fuel_filter_renew > car.fuel_filter_renew_km) {
        return true;
      }
    },
    formatDate(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD").format("LL");
      } else {
        return "-";
      }
    },
    getCar(car) {
      this.axios
        .get(`/api/v1/cars/${car.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedCar = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.set_car"));
          console.log(error);
        });
    },
    saveCar() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        let car = this.editedCar;
        this.axios
          .patch(
            `/api/v1/cars/${car.id}`,
            {
              cars: {
                oil_engine: car.oil_engine,
                oil_engine_date: car.oil_engine_date,
                oil_transmission: car.oil_transmission,
                oil_transmission_date: car.oil_transmission_date,
                grm: car.grm,
                grm_km: car.grm_km,
                grm_date: car.grm_date,
                antifreeze: car.antifreeze,
                antifreeze_km: car.antifreeze_km,
                antifreeze_date: car.antifreeze_date,
                brake_fluid: car.brake_fluid,
                brake_fluid_km: car.brake_fluid_km,
                brake_fluid_date: car.brake_fluid_date,
                candle: car.candle,
                candle_km: car.candle_km,
                candle_date: car.candle_date,
                power_steering: car.power_steering,
                power_steering_km: car.power_steering_km,
                power_steering_date: car.power_steering_date,
                salon_filter_renew: car.salon_filter_renew,
                salon_filter_renew_km: car.salon_filter_renew_km,
                salon_filter_renew_date: car.salon_filter_renew_date,
                akb_renew: car.akb_renew,
                akb_renew_km: car.akb_renew_km,
                akb_renew_date: car.akb_renew_date,
                fuel_filter_renew: car.fuel_filter_renew,
                fuel_filter_renew_km: car.fuel_filter_renew_km,
                fuel_filter_renew_date: car.fuel_filter_renew_date,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.dialog_oil_engine = false;
            this.dialog_oil_transmission = false;
            this.dialog_grm = false;
            this.dialog_antifreeze = false;
            this.dialog_brake = false;
            this.dialog_candle = false;
            this.dialog_power_steering = false;
            this.dialog_salon_filter = false;
            this.dialog_akb = false;
            this.dialog_fuel_filter = false;

            let foundIndex = this.cars.findIndex(
              (element) => element.id === car.id
            );
            this.cars.splice(foundIndex, 1, car);

            this.editedCar = "";

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("services.maked"),
              text: this.$t("services.maked_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.update_car")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("LL");
    },
    setServicesWarning: function () {
      return this.$store.commit("servicesCheckTrue");
    },
    unsetServicesWarning: function () {
      return this.$store.commit("servicesCheckFalse");
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
<style></style>
