<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <h2>{{ $t("custom.cars_archive") }}</h2>
    <v-card>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.econom')"
          v-model="showEconom"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.middle')"
          v-model="showMiddle"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.business')"
          v-model="showBusiness"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-5"
          :label="$t('cars.commercial')"
          v-model="showCommercial"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :class="!isMobile ? '' : 'is-mobile'"
        :items="cars"
        :search="search"
        sort-by="sort"
        :sort-desc="true"
        item-key="id"
        :items-per-page="50"
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} {1}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100, -1],
        }"
      >
        <template slot="header" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : '',
              ]"
              @click="changeSort(header.value)"
            >
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="item" slot-scope="props">
          <tr @dblclick="replaceToCar(props.item)">
            <td class="text-center" :key="props.item.id">
              <router-link :to="{ path: `/cars/${props.item.id}` }">{{
                props.item.car_name
              }}</router-link>
            </td>
            <td class="text-center">{{ props.item.code }}</td>
            <td class="justify-center layout px-0">
              <v-icon color="error" @click="deleteItem(props.item)">
                mdi-delete-forever
              </v-icon>
            </td>
            <td class="text-center">{{ props.item.number }}</td>
            <td class="text-center">{{ props.item.color }}</td>
            <td class="text-center">{{ props.item.year }}</td>
            <td class="text-center">{{ props.item.price_hour }}</td>
            <td class="text-center">{{ props.item.deposit }}</td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
    </v-card>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
// moment.locale("ru");
export default {
  data() {
    return {
      cars: [],
      all_cars: [],
      editedCar: "",
      bookings: [],
      filtred_bookings_car_ids: [],
      error: "",
      snack_text: "",
      snack_mode: "",
      snack_color: "",
      search: "",
      sort_date_range: "",
      showEconom: false,
      showMiddle: false,
      showBusiness: false,
      showCommercial: false,
      dialog: false,
      options: {
        sortBy: "car_name",
      },
      selected: [],
      headers: [
        { text: this.$t("custom.car"), value: "car_name", align: "center" },
        { text: this.$t("custom.code"), value: "code", align: "center" },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("custom.number_word"),
          sortable: false,
          value: "number",
          align: "center",
        },
        { text: this.$t("custom.color"), sortable: false, align: "center" },
        { text: this.$t("custom.year"), sortable: false, align: "center" },
        {
          text: this.$t("custom.price_for_hour"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("bookings.deposit"), sortable: false, align: "center" },
      ],
      isMobile: false,
      isLoading: false,
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/archive_cars.json", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.forEach((element) => {
            this.cars.push(element);
            this.all_cars.push(element);
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.load_archive_cars"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    showEconom() {
      // Усложнить и при выборе совместно другого класса показывать оба
      if (this.showEconom) {
        this.cars = this.cars.filter((car) => car.car_class == "Эконом");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showMiddle() {
      if (this.showMiddle) {
        this.cars = this.cars.filter((car) => car.car_class == "Средний");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showBusiness() {
      if (this.showBusiness) {
        this.cars = this.cars.filter((car) => car.car_class == "Бизнес");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showCommercial() {
      if (this.showCommercial) {
        this.cars = this.cars.filter((car) => car.car_class == "Коммерческий");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function (arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach((id) =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    replaceToCar(car) {
      this.$router.push({ path: `/cars/${car.id}` });
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    deleteItem(car) {
      if (this.user_role == "superadmin" || this.user_role == "admin") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.isLoading = true;
            this.axios
              .delete(`/api/v1/cars/${car.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.cars.splice(this.cars.indexOf(car), 1);
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.remove_archive_cars"))
              );
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("cars.delete"),
              "success"
            );
            this.isLoading = false;
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
  },
  components: {
    // flatPickr
  },
};
</script>
