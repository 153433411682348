<template>
  <v-container grid-list-md text-xs-center>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title>
        <h2>{{ $t('partners.new') }}</h2>
      </v-card-title>

      <v-card-text>
        <v-container grid-list-md>
          <v-container>
            <v-layout row wrap>
              <v-flex xs12 sm3 md3>
                <v-text-field
                  v-model.trim="investor.name"
                  :label="$t('users.name')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm3 md3>
                <v-text-field
                  v-model.number="investor.percent"
                  :label="$t('partners.percent')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm2 md2>
                <v-select
                  v-model="investor.mode"
                  :items="investor_types"
                  item-text="text"
                  item-value="value"
                  :label="$t('partners.mode')"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm4 md4>
                <v-textarea
                  v-model="investor.description"
                  auto-grow
                  clearable
                  rows="1"
                  :label="$t('custom.description_2')"
                  outlined
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
          <v-flex md12 class="text-center">
            <v-btn color="success" @click="submit()">{{ $t('custom.save') }}</v-btn>
          </v-flex>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      investor: {
        name: null,
        percent: 10,
        mode: "Доходы",
        description: null
      },
      investor_types: [
        {text: this.$t('investor_types.income'), value: "Доходы"},
        {text: this.$t('investor_types.income_expences'), value: "Доходы - Расходы"}
      ],
      isMobile: false,
      isLoading: false,
      error: ""
    }
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role
      } else {
        return ""
      }
    },
  },
  methods: {
    submit() {
      if (this.user_role != "guest") {
        let self = this;
        if (this.investor.name) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/investors",
              {
                investors: {
                  name: self.investor.name,
                  percent: self.investor.percent,
                  mode: self.investor.mode,
                  description: self.investor.description
                }
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken
                }
              }
            )
            .then(() => {
              this.$router.replace("/investors");

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t('partners.added'),
                text: this.$t('partners.added_text')
              });
            })
            .catch(error => {
              this.setError(error, this.$t('errors.partners_add'));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t('errors.partners_add'),
                text: this.error
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t('errors.all_fields_title')
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t('errors.no_access')
        });
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (this.PageData && this.PageData.backCrumb && this.PageData.backCrumb.url) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  },
  components: {}
};
</script>
