<template>
  <v-tour
    name="registrationTour"
    :steps="steps"
    :options="tourOptions"
    :callbacks="myCallbacks"
  ></v-tour>
</template>

<script>
export default {
  data() {
    return {
      tourOptions: {
        debug: false,
        highlight: false,
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: this.$t("start-tour.skip"),
          buttonPrevious: this.$t("start-tour.prev"),
          buttonNext: this.$t("start-tour.next"),
          buttonStop: this.$t("start-tour.finish"),
        },
      },
      steps: [
        {
          target: ".start-tour-0",
          content: this.$t("start-tour.1"),
          params: {
            highlight: false,
            enableScrolling: false,
            // placement: "top",
          },
        },
        {
          target: ".start-tour-0",
          content: this.$t("start-tour.2"),
          params: {
            highlight: false,
            enableScrolling: false,
          },
        },
        {
          target: ".start-tour-1",
          content: this.$t("start-tour.3"),
          params: {
            highlight: true,
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: ".start-tour-1",
          content: this.$t("start-tour.4"),
          params: {
            highlight: true,
            enableScrolling: false,
            placement: "top",
          },
        },
        {
          target: ".start-tour-car-1",
          content: this.$t("start-tour.5"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-car-2",
          content: this.$t("start-tour.6"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-car-3",
          content: this.$t("start-tour.7"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-cars-1",
          content: this.$t("start-tour.8"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-cars-1",
          content: this.$t("start-tour.9"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-repairs-1",
          content: this.$t("start-tour.10"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "auto",
          },
        },
        {
          target: ".start-tour-bookings-1",
          content: this.$t("start-tour.11"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "auto",
          },
        },
        {
          target: ".start-tour-booking-1",
          content: this.$t("start-tour.12"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "auto",
          },
        },
        {
          target: ".start-tour-booking-2",
          content: this.$t("start-tour.13"),
          params: {
            highlight: false,
            enableScrolling: true,
            placement: "auto",
          },
        },
        {
          target: ".start-tour-booking-3",
          content: this.$t("start-tour.14"),
          params: {
            highlight: false,
            enableScrolling: true,
            placement: "auto",
          },
        },
        {
          target: ".start-tour-booking-4",
          content: this.$t("start-tour.15"),
          params: {
            highlight: false,
            enableScrolling: true,
            placement: "auto",
          },
        },
        {
          target: ".start-tour-calendar-1",
          content: this.$t("start-tour.16"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-timeline-1",
          content: this.$t("start-tour.17"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-counts-1",
          content: this.$t("start-tour.18"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-company-counts-1",
          content: this.$t("start-tour.19"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-analitics-1",
          content: this.$t("start-tour.20"),
          params: {
            highlight: false,
            enableScrolling: true,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-analitic-1",
          content: this.$t("start-tour.21"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
        {
          target: ".start-tour-analitic-1",
          content: this.$t("start-tour.22"),
          params: {
            highlight: false,
            enableScrolling: false,
            placement: "bottom",
          },
        },
      ],
      myCallbacks: {
        onPreviousStep: this.myCustomPreviousStepCallback,
        onNextStep: this.myCustomNextStepCallback,
        onSkip: this.registrationTourFalse,
        onFinish: this.registrationTourFalse,
      },
    };
  },
  methods: {
    registrationTourFalse() {
      let self = this;
      this.axios
        .patch(
          `/api/v1/users/${self.$store.getters.getCurrentUser.id}`,
          {
            users: {
              registration_tour: true,
            },
          },
          {
            headers: {
              Authorization: self.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          this.$store.commit("setCurrentUser", response.data);
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "success",
            title: self.$t("start-tour.decline"),
          });
        })
        .catch((error) => {
          self.setError(error, "");
          self.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "error",
            title: this.$t("start-tour.error"),
            text: self.error,
          });
        });
    },
    myCustomPreviousStepCallback(currentStep) {
      console.log("Prev Step " + currentStep);
      this.$store.commit("setTourNextStep", false);
      if (currentStep === 4) {
        this.$router.push({ name: "Home" }).catch((err) => {});
      }
      if (currentStep === 7) {
        document.getElementById("car_id").click();
      }
      if (currentStep === 9) {
        this.$router.push({ name: "Cars" }).catch((err) => {});
      }
      if (currentStep === 10) {
        this.$router.push({ path: `/service_tech` }).catch((err) => {});
      }
      if (currentStep === 11) {
        this.$router.push({ name: "Bookings" }).catch((err) => {});
      }
      if (currentStep === 15) {
        // Косяк с запуском следующего, поэтому переводим на тех обслуживание
        // this.$router.push({ name: "Bookings" }).catch((err) => {});
        this.$router.push({ name: "Home" }).catch((err) => {});
      }
      if (currentStep === 16) {
        this.$router.push({ path: `/calendars/new` }).catch((err) => {});
      }
      if (currentStep === 17) {
        this.$router.push({ path: `/calendars/timeline` }).catch((err) => {});
      }
      if (currentStep === 18) {
        this.$router.push({ path: `/counts` }).catch((err) => {});
      }
      if (currentStep === 19) {
        this.$router.push({ path: `/company_counts` }).catch((err) => {});
      }
      if (currentStep === 20) {
        this.$router.push({ path: `/analitics` }).catch((err) => {});
      }
    },
    myCustomNextStepCallback(currentStep) {
      console.log("Next Step", currentStep);
      // Ставим в стор чтобы можно было возвращаться назад
      this.$store.commit("setTourNextStep", true);
      if (currentStep === 3) {
        document.getElementById("car_id").click();
      }
      if (currentStep === 6) {
        this.$router.push({ name: "Cars" }).catch((err) => {});
      }
      if (currentStep === 8) {
        this.$router.push({ path: `/service_tech` }).catch((err) => {});
      }
      if (currentStep === 9) {
        this.$router.push({ name: "Bookings" }).catch((err) => {});
      }
      if (currentStep === 10) {
        document.getElementById("booking_item").click();
      }
      if (currentStep === 14) {
        this.$router.push({ path: `/calendars/new` }).catch((err) => {});
      }
      if (currentStep === 15) {
        this.$router.push({ path: `/calendars/timeline` }).catch((err) => {});
      }
      if (currentStep === 16) {
        this.$router.push({ path: `/counts` }).catch((err) => {});
      }
      if (currentStep === 17) {
        this.$router.push({ path: `/company_counts` }).catch((err) => {});
      }
      if (currentStep === 18) {
        this.$router.push({ path: `/analitics` }).catch((err) => {});
      }
      if (currentStep === 19) {
        document.getElementById("report_for_start_tour").click();
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
  },
};
</script>

<style>
.v-step {
  max-width: 600px !important;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
}
</style>
