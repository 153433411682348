<template>
  <div>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-container>
      <v-row justify="center">
        <h2>{{ $t('custom.calendar') }}</h2>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-1"
          :label="$t('cars.econom')"
          v-model="showEconom"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-1"
          :label="$t('cars.middle')"
          v-model="showMiddle"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-1"
          :label="$t('cars.business')"
          v-model="showBusiness"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mt-1"
          :label="$t('cars.commercial')"
          v-model="showCommercial"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="mb-2 mr-4"
          color="success"
          :to="{ name: 'NewBooking' }"
          >{{ $t('bookings.new_booking') }}</v-btn
        >
        <v-btn class="mb-2" text color="success" @click="days_count += 1"
          >+1</v-btn
        >
        <v-btn class="mb-2" text color="success" @click="days_count += 7"
          >+7</v-btn
        >
        <v-btn class="mb-2" text color="success" @click="days_count += 30"
          >+30</v-btn
        >
        <v-btn class="mb-2" text color="success" @click="days_count -= 1"
          >-1</v-btn
        >
        <v-btn class="mb-2" text color="success" @click="days_count -= 7"
          >-7</v-btn
        >
        <v-btn class="mb-2" text color="success" @click="days_count -= 30"
          >-30</v-btn
        >
      </v-row>
      <v-row justify="space-around">
        <v-flex md12 class="text-center align-center">
          <table class="calendarTable">
            <thead>
              <tr class="sticky-th">
                <th style="border: 1px solid grey;"></th>
                <th style="border: 1px solid grey;"></th>
                <th
                  style="border: 1px solid grey;"
                  v-for="n in number_days"
                  :key="n.index"
                >
                  {{ th_color }}
                  {{ month(n + days_count) }}
                </th>
              </tr>
              <tr class="sticky-th">
                <th style="border: 1px solid grey;">Код</th>
                <th style="border: 1px solid grey;">Сост.</th>
                <th
                  style="border: 1px solid grey;"
                  v-for="n in number_days"
                  :key="n.index"
                >
                  {{ dates(n + days_count) }}
                </th>
              </tr>
            </thead>
            <tr
              v-for="car in cars"
              :key="car.id"
              :class="car.active ? '' : 'grey--text'"
            >
              <td style="border: 1px solid grey;">
                <router-link
                  :to="{ name: 'CarCard', params: { id: car.id } }"
                  class="black--text"
                  style="text-decoration: none;"
                  >{{ car.code }}</router-link
                >
              </td>
              <!-- Состояние -->
              <td style="border: 1px solid grey;">
                <v-menu offset-y v-if="car.state == 1 || car.state == null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-tooltip="{
                        content: $t('states.normal'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500
                        }
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="success"
                    >
                      mdi-thumb-up
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(car.id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="car.state == 2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-tooltip="{
                        content: $t('states.in_service'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500
                        }
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="secondary"
                    >
                      mdi-tools
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(car.id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="car.state == 3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-tooltip="{
                        content: $t('states.critical'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500
                        }
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="error"
                    >
                      mdi-alert-box
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(car.id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="car.state == 4">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-tooltip="{
                        content: $t('states.long_rent'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500
                        }
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="pink"
                    >
                      mdi-all-inclusive
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(car.id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="car.state == 5">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-tooltip="{
                        content: $t('states.no_rent'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500
                        }
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="info"
                    >
                      mdi-airplane-off
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(car.id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y v-if="car.state == 6">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-2"
                      v-tooltip="{
                        content: $t('states.service_needed_2'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500
                        }
                      }"
                      v-bind="attrs"
                      v-on="on"
                      color="orange"
                    >
                      mdi-alarm-light
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(state, index) in states"
                      :key="index"
                      @click="updateCarState(car.id, state.id)"
                    >
                      <v-list-item-title>
                        {{ state.state }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td
                style="border: 1px solid grey;"
                v-for="n in number_days"
                :key="n.index"
                @click="
                  datesCheck(n - 1 + days_count, car.bookings)
                    ? showBooking(n - 1 + days_count, car.bookings)
                    : ''
                "
                :class="
                  datesCheck(n - 1 + days_count, car.bookings) ? 'redCell' : ''
                "
              ></td>
            </tr>
          </table>
        </v-flex>
      </v-row>
    </v-container>
    <v-dialog v-model="dialog" :retain-focus="false" max-width="800px" persistent>
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-flex md12 class="text-right">
                  <v-btn
                    text
                    @click="(checked_bookings = []), (dialog = false)"
                  >
                    {{ $t('custom.close') }}
                  </v-btn>
                </v-flex>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="12"
                    :md="
                      checked_bookings && checked_bookings.length > 1 ? 6 : 12
                    "
                    v-for="item in checked_bookings"
                    :key="item.id"
                  >
                    <h2 class="m-0 p-0 text-center align-center">
                      {{ $t('bookings.booking') }} {{ $t('custom.number') }}{{ item.id }}
                    </h2>
                    <div v-if="!item.entity">
                      {{ $t('custom.client') }}:
                      <router-link :to="{ path: `/clients/${item.client_id}` }"
                        >{{ item.last_name }} {{ item.first_name }}
                        {{ item.middle_name }}</router-link
                      >
                    </div>
                    <div v-else>
                      {{ $t('custom.client') }}:
                      <router-link
                        :to="{ path: `/clients/${item.client_id}` }"
                        >{{ item.entity_name }}</router-link
                      >
                    </div>
                    <div>
                      {{ $t('custom.car') }}:
                      <router-link :to="{ path: `/cars/${item.car_id}` }"
                        >{{ item.car_name }} - {{ item.car_code }}</router-link
                      >
                    </div>
                    <div>{{ $t('bookings.start_date') }}: {{ item.start_date }}</div>
                    <div>{{ $t('bookings.end_date') }}: {{ item.end_date }}</div>
                    <div>{{ $t('custom.days') }}: {{ item.days }}</div>
                    <div>{{ $t('custom.checkin') }}: {{ item.location_start }}</div>
                    <div>{{ $t('custom.checkout') }}: {{ item.location_end }}</div>
                    <div>{{ $t('bookings.day_price') }}: {{ item.price }}</div>
                    <div>{{ $t('bookings.deposit') }}: {{ item.deposit }}</div>
                    <div>{{ $t('bookings.rental_cost') }}: {{ item.rental_cost }}</div>
                    <div>{{ $t('bookings.total') }}: {{ item.total }}</div>
                    <div v-if="item.additional_hours > 0">
                      {{ $t('bookings.add_hours') }}: {{ item.additional_hours }}
                    </div>
                    <div v-if="item.additional_hours > 0">
                      {{ $t('bookings.hours_cost') }}: {{ item.hours_cost }}
                    </div>
                    <div v-if="item.delivery > 0">
                      {{ $t('bookings.delivery_return') }}: {{ item.delivery }}
                    </div>
                    <div v-if="item.equipment > 0">
                      {{ $t('bookings.equipment') }}: {{ item.equipment }}
                    </div>
                    <div v-if="item.other > 0">За другое: {{ item.other }}</div>
                    <div v-if="item.description">
                      <h3>{{ $t('bookings.description') }}</h3>
                      <p>{{ item.description }}</p>
                    </div>
                    <v-flex md12 class="text-center">
                      <v-btn class="mt-2" @click="replaceToBooking(item)"
                        >{{ $t('bookings.description_2') }}
                      </v-btn>
                    </v-flex>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import firebase from "firebase/app";
// import "firebase/auth";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
/* eslint-disable */

export default {
  data() {
    return {
      bookings: [],
      review_booking: {},
      checked_bookings: [],
      events: [],
      payments: [],
      cars: [],
      all_cars: [],
      filtred_bookings_car_ids: [],
      states: [
        { id: 1, state: this.$t('states.normal') },
        { id: 2, state: this.$t('states.in_service') },
        { id: 3, state: this.$t('states.critical') },
        { id: 4, state: this.$t('states.long_rent') },
        { id: 5, state: this.$t('states.no_rent') },
        { id: 6, state: this.$t('states.service_needed') }
      ],
      th_color: "",
      showEconom: false,
      showMiddle: false,
      showBusiness: false,
      showCommercial: false,
      days: "",
      dialog: false,
      booking: [],
      days_count: -2,
      number_days: 30,
      headers: [],
      items: [],
      dialog: false,
      isLoading: false,
      error: "",
      sort_class: null,
      sort_cars: null,
      select: [],
      filtered_bookings: []
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/index_for_calendar", {
          headers: {
            Authorization: this.$store.getters.getAuthToken
          }
        })
        .then(response => {
          response.data.cars.data.forEach((car) => {
            self.cars.push(car.attributes);
            self.all_cars.push(car.attributes);
            self.cars.sort(function(a, b) {
              if (a.sort > b.sort) {
                return 1;
              }
              if (a.sort < b.sort) {
                return -1;
              }
              // a должно быть равным b
              return 0;
            });
            self.all_cars.sort(function(a, b) {
              if (a.sort > b.sort) {
                return 1;
              }
              if (a.sort < b.sort) {
                return -1;
              }
              // a должно быть равным b
              return 0;
            });
          });
          self.cars.forEach(car => this.$set(car,"bookings", []))
          response.data.bookings.data.forEach(booking => {
            let finded_index = self.cars.findIndex(car => car.id == booking.attributes.car_id)
            if (finded_index >= 0) {
              self.cars[finded_index].bookings.push(booking.attributes)
            }
          })
        })
        .catch(error => {
          this.setError(error, this.$t('errors.bookings_load'));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    showEconom() {
      // Усложнить и при выборе совместно другого класса показывать оба
      if (this.showEconom) {
        this.cars = this.cars.filter(car => car.car_class == "Эконом");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
        console.log(this.cars, this.filtred_bookings_car_ids);
      }
    },
    showMiddle() {
      if (this.showMiddle) {
        this.cars = this.cars.filter(car => car.car_class == "Средний");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showBusiness() {
      if (this.showBusiness) {
        this.cars = this.cars.filter(car => car.car_class == "Бизнес");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
      }
    },
    showCommercial() {
      if (this.showCommercial) {
        this.cars = this.cars.filter(car => car.car_class == "Коммерческий");
      } else {
        this.cars = this.all_cars;
        // Удаление из массива объектов по значению ключа
        var removeByAttr = function(arr, attr, value) {
          var i = arr.length;
          while (i--) {
            if (
              arr[i] &&
              arr[i].hasOwnProperty(attr) &&
              arguments.length > 2 &&
              arr[i][attr] === value
            ) {
              arr.splice(i, 1);
            }
          }
          return arr;
        };
        this.filtred_bookings_car_ids.forEach(id =>
          removeByAttr(this.cars, "id", id)
        );
      }
    }
  },
  methods: {
    dates(day) {
      return moment()
        .add(day, "days")
        .format("DD dd");
    },
    month(day) {
      return moment()
        .add(day, "days")
        .format("MM");
      // Показывать только в начале месяца
      // if (
      //   moment()
      //     .add(day, "days")
      //     .isSame(
      //       moment()
      //         .add(day, "days")
      //         .startOf("month"),
      //       "day"
      //     )
      // ) {
      //   return moment()
      //     .add(day, "days")
      //     .format("MMM");
      // }
    },
    datesCheck(day, bookings) {
      let result = false;
      bookings.forEach(booking => {
        if (booking.active) {
          let now = moment().format("DD-MM-YYYY");
          if (
            moment(now, "DD-MM-YYYY")
              .add(day, "days")
              .isBetween(
                moment(booking.start_date, "DD-MM-YYYY H:mm").subtract(
                  2,
                  "days"
                ),
                moment(booking.end_date, "DD-MM-YYYY H:mm"),
                "day"
              )
          ) {
            result = true;
          }
        }
      });
      return result;
    },
    showBooking(day, bookings) {
      let finded_bookings = [];
      bookings.forEach(booking => {
        if (booking.active) {
          if (
            moment()
              .add(day, "days")
              .isBetween(
                moment(booking.start_date, "DD-MM-YYYY").subtract(2, "days"),
                moment(booking.end_date, "DD-MM-YYYY"),
                "days"
              )
          ) {
            finded_bookings.push(booking);
            this.checked_bookings = finded_bookings;
            this.dialog = true;
          }
        }
      });
    },
    updateCarState(id, state) {
      if ((id, state)) {
        this.isLoading = true;
        this.axios
          .patch(
            `/api/v1/cars/${id}`,
            {
              cars: {
                state: state
              }
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken
              }
            }
          )
          .then(() => {
            this.cars.find(car => car.id == id).state = state;
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t('cars.change_state')
            });
          })
          .catch(error => {
            this.isLoading = false;
            this.setError(error, this.$t('errors.change_car_state'));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    setCarStateClass(state) {
      if (state == 1) {
        return "green";
      } else if (state == 2) {
        return "black";
      } else if (state == 3) {
        return "red";
      } else if (state == 4) {
        return "pink";
      } else if (state == 5) {
        return "blue";
      } else if (state == 6) {
        return "orange";
      }
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("LL H:mm");
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  },
  components: {
    // VueCal
  }
};
</script>
<style>
.calendarTable {
  border-collapse: collapse; /*убираем пустые промежутки между ячейками*/
  position: relative;
  border: 1px solid grey; /*устанавливаем для таблицы внешнюю границу серого цвета толщиной 1px*/
}

.calendarTable tr:hover {
  background-color: lightgray;
}
/* td, th {
  position: relative;
}
td:hover::after,
th:hover::after {
  content: "";
  position: absolute;
  background-color: lightgray;
  left: 0;
  top: -1000px;
  height: 2000px;
  width: 100%;
  /* z-index: -2; */
/* }  */

.sticky-th th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.redCell {
  background-color: #4caf50;
  justify-content: center;
  align-items: center;
}
.vuecal__event.booking .vuecal__event-time {
  display: none;
  align-items: center;
}
.vuecal__no-event {
  display: none;
}
</style>
