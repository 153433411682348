<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <h2 v-if="!isMobile">{{ $t("custom.insurance") }}</h2>
        <h4 v-else>{{ $t("custom.insurance") }}</h4>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/7/guides/23`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        :headers="headers_insurance"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :items="cars"
        disable-pagination
        hide-default-footer
        mobile-breakpoint="100"
        dense
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td class="text-center">
              <router-link :to="{ path: `/cars/${props.item.id}` }">{{
                props.item.car_name
              }}</router-link>
            </td>
            <td class="text-center">{{ props.item.code }}</td>
            <td class="text-center">{{ props.item.number }}</td>
            <td class="text-center" nowrap>
              {{ formatDate(props.item.insurance) }}
            </td>
            <td class="text-center" nowrap>
              {{ formatDate(props.item.diagnostic_card) }}
            </td>
            <td class="text-center" nowrap>
              <p
                v-if="checkInsurance(props.item)"
                :class="checkInsuranceColor(props.item)"
                style="cursor: pointer"
                @click="getCar(props.item), (dialog_insurance = true)"
              >
                {{ $t("cars.insurance") }}
                {{ formatDate(props.item.insurance) }}
              </p>
              <p
                v-if="checkDiagnosticCard(props.item)"
                class="black--text amber accent-2"
                style="cursor: pointer"
                @click="getCar(props.item), (dialog_diagnostic_card = true)"
              >
                {{ $t("cars.diagnostic_card") }}
                {{ formatDate(props.item.diagnostic_card) }}
              </p>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialog_insurance"
      :retain-focus="false"
      persistent
      max-width="1000px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          <span class="headline">{{ $t("services.insurance_renew") }}</span>
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-container
            grid-list-md
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model="editedCar.insurance"
                    :label="$t('services.insurance_renew_date')"
                    type="date"
                    outlined
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_insurance = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_diagnostic_card"
      :retain-focus="false"
      persistent
      max-width="500px"
      :fullscreen="isMobile ? true : false"
    >
      <v-card>
        <v-card-title :class="isMobile ? 'pt-10' : 'headline'">
          {{ $t("services.to_renew") }}
        </v-card-title>

        <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
          <v-flex xs12 sm12 md12>
            <v-text-field
              v-model="editedCar.diagnostic_card"
              :label="$t('services.insurance_renew_date')"
              type="date"
              outlined
            ></v-text-field>
          </v-flex>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_diagnostic_card = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="success" @click="saveCar">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
export default {
  data() {
    return {
      cars: [],
      editedCar: [],
      headers_insurance: [
        { text: this.$t("cars.name"), value: "car_name", align: "center" },
        { text: this.$t("cars.code"), value: "car_code", align: "center" },
        { text: this.$t("cars.gov_number"), value: "number", align: "center" },
        {
          text: this.$t("services.insurance"),
          value: "mileage",
          align: "center",
        },
        {
          text: this.$t("services.diagnistic_card"),
          sortable: false,
          align: "center",
        },
        { text: this.$t("custom.current"), sortable: false, align: "center" },
      ],
      dialog_insurance: false,
      dialog_diagnostic_card: false,
      checkWarning: false,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/services", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.cars = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.services_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    checkInsurance(car) {
      let start_date = moment();
      let end_insurance_date = moment(car.insurance, "YYYY-MM-DD");
      let days = moment.duration(end_insurance_date.diff(start_date, "days"));

      return days <= 3 || (days <= 10 && days > 3);
    },
    checkInsuranceColor(car) {
      let start_date = moment();
      let end_insurance_date = moment(car.insurance, "YYYY-MM-DD");
      let days = moment.duration(end_insurance_date.diff(start_date, "days"));
      if (days <= 10 && days >= 3) {
        return "black--text pink accent-2";
      }
      if (days < 3) {
        this.checkWarning = true;
        return "black--text red accent-2";
      }
    },
    checkDiagnosticCard(car) {
      let start_date = moment();
      let end_diagnostic_card_date = moment(car.diagnostic_card, "YYYY-MM-DD");
      let days = moment.duration(
        end_diagnostic_card_date.diff(start_date, "days")
      );

      return days <= 3 || (days <= 7 && days > 3);
    },
    formatDate(date) {
      if (date) {
        return moment(date, "YYYY-MM-DD").format("LL");
      } else {
        return "-";
      }
    },
    getCar(car) {
      this.axios
        .get(`/api/v1/cars/${car.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.editedCar = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.car_load"));
          console.log(error);
        });
    },
    saveCar() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        let car = this.editedCar;
        this.axios
          .patch(
            `/api/v1/cars/${car.id}`,
            {
              cars: {
                insurance: car.insurance,
                diagnostic_card: car.diagnostic_card,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.dialog_insurance = false;
            this.dialog_diagnostic_card = false;

            let foundIndex = this.cars.findIndex(
              (element) => element.id === car.id
            );
            this.cars.splice(foundIndex, 1, car);

            this.editedCar = "";

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("services.maked"),
              text: this.$t("services.maked_text"),
            });
          })
          .catch((error) => this.setError(error, this.$t("errors.update_car")))
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    setServicesWarning: function () {
      return this.$store.commit("servicesCheckTrue");
    },
    unsetServicesWarning: function () {
      return this.$store.commit("servicesCheckFalse");
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
<style></style>
