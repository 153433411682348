<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          small
          color="success"
          class="ml-2"
          @click="
            (newDebtDialog = true),
              (debt = {
                sum: null,
                state: $t('debts_state.created'),
                description: null,
                group: $t('add_services.rent'),
                client_id: null,
                booking_id: null,
              })
          "
          >{{ $t("debts.new") }}</v-btn
        >
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/4/guides/15`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="debts"
        :search="search"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :items-per-page="20"
        :sort-by="['created_at']"
        :sort-desc="[true]"
        mobile-breakpoint="100"
        :class="!isMobile ? '' : 'is-mobile'"
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} {1}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100, -1],
        }"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td class="text-center">{{ props.item.id }}</td>
            <td class="text-center" nowrap>
              <v-icon
                class="green--text mr-2"
                v-if="props.item.state != 'Оплачен'"
                @click="paymentStart(props.item)"
              >
                mdi-cash-multiple
              </v-icon>
              <v-icon class="mr-2" @click="editDebt(props.item.id)">
                mdi-pencil-outline
              </v-icon>
              <v-icon @click="deleteItem(props.item)" v-if="checkAdmin()">
                mdi-delete-forever
              </v-icon>
            </td>
            <td class="text-center">
              {{ formatCreatedAt(props.item.created_at) }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              <router-link :to="{ path: `/clients/${props.item.client_id}` }">{{
                props.item.client_name
              }}</router-link>
            </td>
            <td class="text-center">
              <router-link
                :to="{ path: `/bookings/${props.item.booking_id}` }"
                >{{ props.item.booking_id }}</router-link
              >
            </td>
            <td class="text-center">{{ props.item.group }}</td>
            <td class="text-center">{{ props.item.description }}</td>
            <td class="text-center">{{ props.item.sum }}{{ currency }}</td>
            <td class="text-center">{{ props.item.paid }}{{ currency }}</td>
            <!-- Состояние -->
            <td class="text-center">
              <v-menu absolute offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="stateColor(props.item.state)"
                    small
                    block
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ props.item.state }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    v-for="(state, index) in debts_state"
                    :key="index"
                    @click="updateState(props.item.id, state.value)"
                  >
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="newDebtDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("debts.create") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model.number="debt.sum"
                      :label="$t('custom.amount')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-autocomplete
                      v-model="debt.group"
                      :items="debts_groups"
                      item-text="text"
                      item-value="value"
                      :label="$t('custom.select_group')"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model.number="debt.booking_id"
                      :label="$t('bookings.number_booking')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-autocomplete
                      v-model="debt.client_id"
                      item-text="fullname"
                      item-value="id"
                      :loading="isLoadingClients"
                      :search-input.sync="search_client"
                      clearable
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('bookings.select_client')"
                      :items="clients"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="debt.description"
                      :label="$t('custom.description')"
                      :placeholder="$t('debts.description_placeholder')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="newDebtDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="submit()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model.number="debt.sum"
                      :label="$t('custom.amount')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-autocomplete
                      v-model="debt.group"
                      :items="debts_groups"
                      item-text="text"
                      item-value="value"
                      :label="$t('custom.select_group')"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model.number="debt.booking_id"
                      :label="$t('bookings.number_booking')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-autocomplete
                      v-model="debt.client_id"
                      item-text="fullname"
                      item-value="id"
                      :loading="isLoadingClients"
                      :search-input.sync="search_client"
                      clearable
                      flat
                      hide-no-data
                      hide-details
                      :label="$t('bookings.select_client')"
                      :items="clients"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="debt.description"
                      :label="$t('custom.description')"
                      :placeholder="$t('debts.description_placeholder')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editDialog = false">{{
            $t("custom.close")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="updateDebt()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="paymentDialog"
      :retain-focus="false"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("debts.pay") }}</span>
          <v-icon class="ml-2 green--text" @click="savePayment()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (paymentDialog = false),
                (paymentData = { sum: null, type: null, state: null })
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex md12 class="text-center align-center">
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-text-field
                        v-model.number="paymentData.sum"
                        :label="$t('bookings.get_payment')"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex md12 class="d-inline-flex payment">
                      <v-checkbox
                        v-model="paymentData.type"
                        style="font-size: 12px !important"
                        class="mt-0 mr-1"
                        :label="$t('money.cash')"
                        value="Наличные"
                        :disabled="
                          paymentData.type == 'Терминал' ||
                          paymentData.type == 'На карту' ||
                          paymentData.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="paymentData.type"
                        class="mt-0 mr-1"
                        :label="$t('money.terminal_short')"
                        value="Терминал"
                        :disabled="
                          paymentData.type == 'Наличные' ||
                          paymentData.type == 'На карту' ||
                          paymentData.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="paymentData.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_card')"
                        value="На карту"
                        :disabled="
                          paymentData.type == 'Наличные' ||
                          paymentData.type == 'Терминал' ||
                          paymentData.type == 'Р/с'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                      <v-checkbox
                        v-model="paymentData.type"
                        class="mt-0 mr-1"
                        :label="$t('money.to_entity_short')"
                        value="Р/с"
                        :disabled="
                          paymentData.type == 'Наличные' ||
                          paymentData.type == 'Терминал' ||
                          paymentData.type == 'На карту'
                        "
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-flex>
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-select
                        v-model="paymentData.state"
                        clearable
                        :items="debts_state"
                        item-text="text"
                        item-value="value"
                        :label="$t('bookings.select_state')"
                      ></v-select>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (paymentDialog = false),
                (paymentData = { sum: null, type: null, state: null })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="savePayment()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { Capacitor } from "@capacitor/core";
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      debts: [],
      debt: {
        sum: null,
        state: this.$t("debts_state.created"),
        description: null,
        group: this.$t("add_services.rent"),
        client_id: null,
        booking_id: null,
      },
      debts_groups: [
        { text: this.$t("add_services.rent"), value: "Аренда" },
        { text: this.$t("add_services.fines"), value: "Штрафы" },
        { text: this.$t("add_services.damages"), value: "Повреждения" },
        { text: this.$t("add_services.others"), value: "Другое" },
      ],
      debts_state: [
        { text: this.$t("debts_state.created"), value: "Создан" },
        { text: this.$t("debts_state.notify"), value: "Оповещён" },
        { text: this.$t("debts_state.paid"), value: "Оплачен" },
        { text: this.$t("debts_state.refund"), value: "Возмещён" },
      ],
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("bookings.paid"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      paymentData: {
        sum: null,
        type: null,
        state: "Не оплачен",
        group: null,
        debt_id: null,
      },
      clients: [],
      client: null,
      isLoadingClients: false,
      showClientSearch: false,
      search_client: null,
      search: null,
      paymentDialog: false,
      pagination: { sortBy: "created_at", descending: true },
      editDialog: false,
      newDebtDialog: false,
      timeout: null,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/all_debts", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.debts = response.data;
          if (this.$route.params != {} && this.$route.params.query) {
            this.search = this.$route.params.query;
          }
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.debts_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    search_client(val) {
      // if (this.debt && this.debt.client_id) {
      //   return;
      // }
      if (this.search_client == "" || this.search_client == " ") {
        this.search_client = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadClients();
          }, 200); // delay
        }
        debounce();
      }
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    submit() {
      if (this.user_role != "guest") {
        let debt = this.debt;
        let self = this;
        if (debt.sum && debt.client_id && debt.group) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/debts",
              {
                debts: {
                  sum: debt.sum,
                  state: debt.state,
                  group: debt.group,
                  description: debt.description,
                  client_id: debt.client_id,
                  booking_id: debt.booking_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let data = response.data;
              data.paid = 0;
              data.client_name = this.clients.find(
                (client) => client.id == data.client_id
              ).fullname;
              this.debts.push(data);
              this.newDebtDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("debts.added_title"),
                text: this.$t("debts.added_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.add_debt"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.add_debt"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
            text: this.$t("errors.add_debt_fields"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editDebt(id) {
      this.debt = this.debts.find((debt) => debt.id == id);
      this.editDialog = true;
    },
    updateDebt() {
      if (this.user_role != "guest") {
        let debt = this.debt;
        let self = this;
        if (debt.sum && debt.client_id && debt.group) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/debts/${debt.id}`,
              {
                debts: {
                  sum: debt.sum,
                  state: debt.state,
                  group: debt.group,
                  description: debt.description,
                  client_id: debt.client_id,
                  booking_id: debt.booking_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.debts.findIndex(
                (element) => element.id === debt.id
              );
              this.debts.splice(foundIndex, 1, debt);
              this.editDialog = false;
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("debts.added_title"),
                text: this.$t("debts.update_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.select_state_debt"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.select_state_debt"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
            text: this.$t("errors.add_debt_fields"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    paymentStart(debt) {
      this.paymentData = {
        sum: debt.sum,
        type: null,
        group: debt.group,
        state: "Не оплачен",
        debt_id: debt.id,
      };
      this.paymentDialog = true;
    },
    savePayment() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        if (this.paymentData.sum && this.paymentData.type) {
          this.axios
            .post(
              `/api/v1/debt_payment`,
              {
                sum: this.paymentData.sum,
                type: this.paymentData.type,
                state: this.paymentData.state,
                group: this.paymentData.group,
                debt_id: this.paymentData.debt_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.debts.findIndex(
                (element) => element.id === self.paymentData.debt_id
              );
              this.debts[foundIndex].state = this.paymentData.state;

              this.paymentDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("debts.debt_paid_title"),
                text: this.$t("debts.debt_paid_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.pay_debt"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.pay_debt"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("errors.no_data"),
            text: this.$t("errors.pay_debt_fields"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateState(id, state) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/debts/${id}`,
            {
              debts: {
                state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.debts.findIndex(
              (element) => element.id === id
            );
            let debt = this.debts[foundIndex];
            debt.state = state;
            this.debts.splice(foundIndex, 1, debt);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("debts.updated_debt"),
              text: this.$t("debts.updated_debt_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edit_debt"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.edit_debt"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    loadClients() {
      this.isLoadingClients = true;
      this.axios
        .post(
          `/api/v1/index_new_booking_page`,
          {
            search: this.search_client,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          this.clients = response.data.query ? response.data.query : (response.data ? response.data : []);
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                (client.entity_phone
                  ? client.entity_phone.slice(client.entity_phone.length - 4)
                  : "");
            }
          });
        })
        .catch((error) => {
          this.isLoadingClients = false;
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
    stateColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Не оплачен") {
        return "red";
      }
    },
    deleteItem(debt) {
      if (this.user_role != "guest") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/debts/${debt.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.debts.splice(this.debts.indexOf(debt), 1);
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.delete_debt"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("debts.delete_debt_text"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("LL");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
