<template>
  <v-card>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
    ></loading>
    <vue-gmap
      :center="markerCenter"
      :zoom="10"
      style="width: 100%; height: 800px;"
    >
      <!-- <gmap-custom-marker
          alignment="bottomright"
          key="supermarker"
          :marker="markerCenter"
        >
          <div class="card" @click="e => e.stopPropagation()">
            <img :class="animation" class="icon" :src="src" height="30" />
          </div>
        </gmap-custom-marker> -->
      <gmap-custom-marker
        v-for="marker in mayaks"
        :key="marker.id"
        alignment="center"
        :marker="{ lat: marker.lat, lng: marker.lng }"
      >
        <div class="live-html black--text">
          <v-icon small :color="returnStateColor(marker)">mdi-car</v-icon>
          {{ findCar(marker) }} - {{ marker.v }} км/ч
        </div>
      </gmap-custom-marker>
    </vue-gmap>
  </v-card>
</template>

<script>
import { Map } from "gmap-vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";
export default {
  created() {
    let self = this;
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.autofon_token &&
        this.$store.getters.getCompany.company.autofon_pwd
      ) {
        this.isLoading = true;
        this.axios({
          method: "post",
          url: `https://gprs.autofon.ru:9443/jsonapi/objects/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            this.imeis = response.data;
            this.axios({
              method: "post",
              url: `https://gprs.autofon.ru:9443/jsonapi/laststates/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
              headers: {
                "Content-Type": "application/json"
              }
            })
              .then(response => {
                self.mayaks = response.data;
              })
              .catch(error => {
                self.setError(error, self.$t('errors.beacon_load'));
                console.log(error);
              });
          })
          .catch(error => {
            this.setError(error, this.$t('errors.beacon_load'));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          showConfirmButton: true,
          icon: "warning",
          title: this.$t('errors.beacon_load'),
          text: this.$t('errors.beacon_load_text')
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  mounted() {
    let self = this;
    if (
      this.$store.getters.getCompany.company &&
      this.$store.getters.getCompany.company.autofon_token &&
      this.$store.getters.getCompany.company.autofon_pwd
    ) {
      this.pollData();
    } else {
      this.$swal({
        showConfirmButton: true,
        icon: "warning",
        title: this.$t('errors.beacon_load'),
        text: this.$t('errors.beacon_load_text')
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  data() {
    return {
      mayaks: [],
      imeis: [],
      marker: {
        lat: 50.60229509638775,
        lng: 3.0247059387528408
      },
      batchCount: 10,
      html: "<center><h2>This is <u>live</u> html marker 🔥</h2><center>",
      infinite: true,
      selectedAnimation: "",
      zA: 50,
      zB: 51,
      testText: null,
      markerCenter: {
        lat: 54.6272265,
        lng: 20.0571581
      },
      addWeather: false,
      addMode: false,
      markers: [],
      ids: 0,
      alignment: "top",
      batchMarkers: [],
      tools: true,
      polling: null,
      isLoading: false
    };
  },
  computed: {
    animation() {
      const infinite = this.infinite ? "infinite" : "";
      return `animated ${infinite} ${this.selectedAnimation}`;
    },
    src() {
      return this.testText
        ? this.testText
        : "https://vuejs.org/images/logo.png";
    }
  },
  methods: {
    pollData() {
      let self = this;
      this.polling = setInterval(() => {
        self.updateMayak();
      }, 5000);
    },
    updateMayak() {
      let self = this
      this.axios({
        method: "post",
        url: `https://gprs.autofon.ru:9443/jsonapi/objects/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          console.log('objects', response.data)
          this.imeis = response.data;
          this.axios({
            method: "post",
            url: `https://gprs.autofon.ru:9443/jsonapi/laststates/?key=${self.$store.getters.getCompany.company.autofon_token}&pwd=${self.$store.getters.getCompany.company.autofon_pwd}`,
            headers: {
              "Content-Type": "application/json"
            }
          })
            .then(response => {
              console.log('laststates', response.data)
              self.mayaks = response.data;
            })
            .catch(error => {
              self.setError(error, self.$t('errors.beacon_load'));
              console.log(error);
            });
        })
        .catch(error => {
          this.setError(error, this.$t('errors.beacon_load'));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    findCar(marker) {
      let result = this.imeis.find(imei => imei.id == marker.id);
      if (result) {
        return result.desc;
      } else {
        return marker.id;
      }
    },
    returnStateColor(marker) {
      if (marker.on == 1) {
        return "success";
      } else if (marker.on == 0) {
        return "warning";
      } else {
        return "secondery";
      }
    },
    // displayTools(value) {
    //   this.tools = value;
    // },
    // deleteMarker(i) {
    //   this.markers.splice(i, 1);
    // },
    // onMapClick(event) {
    //   if (this.addMode) {
    //     this.markers.push({
    //       _id: this.ids++,
    //       latitude: event.latLng.lat(),
    //       longitude: event.latLng.lng(),
    //       weather: this.addWeather,
    //       alignment: this.alignment
    //     });
    //     this.addMode = false;
    //   }
    // },
    addbatch() {
      this.batchMarkers = [];
      for (let i = 0; i < this.batchCount; i++) {
        this.batchMarkers.push({
          lat: `48.1${String(parseInt(Math.random() * 100000))}`,
          lng: `-1.7${String(parseInt(Math.random() * 100000))}`
        });
      }
      this.markerCenter = {
        lat: 48.1030572,
        lng: -1.7065389
      };
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    }
  },
  components: {
    GmapCustomMarker,
    "vue-gmap": Map
  }
};
</script>

<style>
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css";
.vue-map-container {
  height: 100%;
  padding: 0px;
  margin: 0px;
}
.live-html {
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 3px 3px 3px grey;
  /* min-height: 200px;
  min-width: 200px; */
  background-color: #fafafa;
}
.zindex {
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-shadow: 3px 3px 3px grey;
  min-height: 100px;
  min-width: 100px;
  font-size: 1.3em;
}
.zindex-a {
  background-color: #eb7bff;
}
.zindex-b {
  background-color: #17e2b8;
}
.alignment {
  right: 10px;
  z-index: 1000;
  padding: 4px;
  top: 15px;
  border-radius: 5px;
}
.weather {
  top: 70px;
}
.icon-sm {
  padding: 3px;
  border-radius: 4px;
}
.animate-panel {
  padding: 7px;
  right: 10px;
  top: 150px;
  height: 100px;
  width: 250px;
  z-index: 100;
}
.live-editor {
  right: 10px;
  top: 270px;
  z-index: 1000;
  height: 100px;
  width: 250px;
  border: 1px solid #ccc;
}
.live-editor textarea {
  width: 100%;
  height: 100%;
}
.add-marker {
  padding: 8px;
  font-size: 1.3em;
}
.batch-cluster {
  right: 0px;
  top: 400px;
  width: 270px;
  height: 40px;
  border-radius: 5px;
  z-index: 100;
}
.card-tools {
  right: 5px;
  top: 5px;
  padding: 8px;
  background-color: #fafafa;
  border: 1px solid #bbb;
  width: 270px;
  z-index: 100;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}
.batch-cluster > input,
.batch-cluster > button {
  margin-left: 10px;
  margin-right: 10px;
}
.hide-tool {
  background-color: #fafafa;
  border: 1px solid #bbb;
  font-size: 1.3em;
}
.show-tool {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #fafafa;
  border: 1px solid #bbb;
  font-size: 1.3em;
  z-index: 100;
}
</style>
