<template>
  <span v-resize="checkMobile">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-toolbar text :color="$vuetify.theme.dark ? '' : 'white'">
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('custom.search')"
          single-line
          clearable
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn small color="success" @click="newInvoiceDialog = true">{{
          $t("counts.add_count")
        }}</v-btn>
        <v-btn
          v-if="!isIos"
          icon
          :href="`https://rentprog.${
            $root.$i18n.locale == 'ru' ? 'ru' : 'com'
          }/categories/4/guides/17`"
          target="_blank"
        >
          <v-icon>mdi-help-circle</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="invoices"
        :search="search"
        :items-per-page="20"
        :sort-by="['created_at']"
        :loading="isLoading"
        :loading-text="$t('custom.loading_table')"
        :sort-desc="[true]"
        mobile-breakpoint="100"
        :class="!isMobile ? '' : 'is-mobile'"
        :footer-props="{
          pageText: `{0} ${$t('custom.of')} {1}`,
          itemsPerPageText: $t('custom.elements_table'),
          showFirstLastPage: true,
          itemsPerPageOptions: [20, 50, 100, -1],
        }"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td class="text-center">{{ props.item.number }}</td>
            <td class="text-center" nowrap>
              <v-icon
                class="green--text mr-2"
                @click="paymentStart(props.item)"
              >
                mdi-cash-multiple
              </v-icon>
              <v-icon class="mr-2 mt-1" @click="generateInvoice(props.item)">
                mdi-file-account-outline
              </v-icon>
              <v-icon class="mr-2" @click="editInvoice(props.item.id)">
                mdi-pencil-outline
              </v-icon>
              <v-icon @click="deleteItem(props.item)" v-if="checkAdmin()">
                mdi-delete-forever
              </v-icon>
            </td>
            <td class="text-center">
              {{ returnDate(props.item.date) }}
            </td>
            <td
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              <router-link :to="{ path: `/clients/${props.item.client_id}` }">
                {{ props.item.client_name }}
              </router-link>
            </td>
            <td class="text-center">
              <router-link
                :to="{ path: `/bookings/${props.item.booking_id}` }"
                >{{ props.item.booking_id }}</router-link
              >
            </td>
            <td class="text-center">
              <read-more
                v-if="
                  props.item.description && props.item.description.length > 0
                "
                :more-str="$t('custom.read')"
                :text="props.item.description"
                link="#"
                :less-str="$t('custom.less')"
                :max-chars="20"
              ></read-more>
            </td>
            <td class="text-center">
              {{
                props.item.rent +
                props.item.deposit +
                props.item.delivery +
                props.item.delivery_end +
                props.item.clean +
                props.item.gas +
                props.item.damage +
                props.item.fines +
                props.item.mileage +
                props.item.hours_cost +
                props.item.insurance +
                props.item.add_drivers_cost +
                props.item.equipment +
                props.item.other
              }}{{ currency }}
            </td>
            <td class="text-center">{{ paidInvoice(props.item) }}</td>
            <td class="text-center">
              {{ props.item.paid ? props.item.paid : 0 }}{{ currency }}
            </td>
            <!-- Состояние -->
            <td class="text-center">
              <v-menu absolute offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="stateColor(props.item.state)"
                    small
                    block
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ selectStateLocale(props.item.state) }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    link
                    v-for="(state, index) in invoice_state"
                    :key="index"
                    @click="updateState(props.item, state.value)"
                  >
                    <v-list-item-title>
                      {{ state.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ $t("custom.no_data_in_table") }}
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="mdi-alert">
          {{ $t("tables.no_search_result", { msg: search }) }}
        </v-alert>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="newInvoiceDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("counts.creating") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap class="align-center">
                  <v-flex xs12 md3>
                    <v-menu
                      v-model="date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_format"
                          :label="$t('counts.date_initial')"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        :locale="$i18n.locale"
                        @input="date_menu = false"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model="invoice.number"
                      :label="$t('counts.number')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3>
                    <v-text-field
                      v-model.number="invoice.booking_id"
                      :label="$t('bookings.number_booking')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <v-autocomplete
                      v-model="invoice.client_id"
                      item-text="fullname"
                      item-value="id"
                      :loading="isLoadingClients"
                      :search-input.sync="search_client"
                      clearable
                      flat
                      hide-no-data
                      :label="$t('bookings.select_client')"
                      :items="clients"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex md12 class="d-inline-flex justify-center">
                    <v-text-field
                      v-model.number="invoice.rent"
                      :label="$t('bookings.rent')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.deposit"
                      :label="$t('bookings.deposit')"
                      placeholder="0"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-expansion-panels v-model="showAddPaymentsToInvoice">
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <h4 class="mt-3 mb-2">
                          {{ $t("custom.add_services") }}
                        </h4>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-flex md12 class="mt-3 d-inline-flex">
                          <v-text-field
                            v-model.number="invoice.delivery"
                            :label="$t('bookings.delivery')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.delivery_end"
                            :label="$t('bookings.checkout')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.clean"
                            :label="$t('bookings.clean')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.gas"
                            :label="$t('bookings.gas')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.hours_cost"
                            :label="$t('bookings.add_hours_short')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.damage"
                            :label="$t('bookings.damages')"
                            placeholder="0"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        <v-flex md12 class="mt-3 d-inline-flex">
                          <v-text-field
                            v-model.number="invoice.insurance"
                            :label="$t('bookings.insurance')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.add_drivers_cost"
                            :label="$t('bookings.add_drivers_small')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.fines"
                            :label="$t('bookings.fines')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.equipment"
                            :label="$t('bookings.equipment')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.mileage"
                            :label="$t('bookings.many_mileage')"
                            placeholder="0"
                            class="mr-2"
                            hide-details
                          ></v-text-field>
                          <v-text-field
                            v-model.number="invoice.other"
                            :label="$t('bookings.other')"
                            placeholder="0"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="invoice.description"
                      :label="$t('custom.description')"
                      rows="1"
                      auto-grow
                      :placeholder="$t('custom.add_info')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (newInvoiceDialog = false),
                (date = null),
                (invoice = {
                  sum: null,
                  state: 'Создан',
                  date: null,
                  description: null,
                  number: null,
                  client_id: null,
                  booking_id: null,
                })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="submit()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("custom.editing") }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 md3>
                    <v-menu
                      v-model="date_menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date_format"
                          :label="$t('invoices.date')"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mr-2"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        :locale="$i18n.locale"
                        @input="date_menu1 = false"
                        first-day-of-week="1"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model="invoice.number"
                      :label="$t('counts.number')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md2>
                    <v-text-field
                      v-model.number="invoice.booking_id"
                      :label="$t('bookings.number_booking')"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <v-autocomplete
                      v-model="invoice.client_id"
                      item-text="fullname"
                      item-value="id"
                      :loading="isLoadingClients"
                      :search-input.sync="search_client"
                      clearable
                      flat
                      hide-no-data
                      :label="$t('bookings.select_client')"
                      :items="clients"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex md12 class="d-inline-flex justify-center">
                    <v-text-field
                      v-model.number="invoice.rent"
                      :label="$t('bookings.rent')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.deposit"
                      :label="$t('bookings.deposit')"
                      placeholder="0"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12 class="mt-3 d-inline-flex">
                    <v-text-field
                      v-model.number="invoice.delivery"
                      :label="$t('bookings.delivery')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.delivery_end"
                      :label="$t('bookings.checkout')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.clean"
                      :label="$t('bookings.clean')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.gas"
                      :label="$t('bookings.gas')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.hours_cost"
                      :label="$t('bookings.add_hours_short')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.damage"
                      :label="$t('bookings.damages')"
                      placeholder="0"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex md12 class="mt-3 d-inline-flex">
                    <v-text-field
                      v-model.number="invoice.insurance"
                      :label="$t('bookings.insurance')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.add_drivers_cost"
                      :label="$t('bookings.add_drivers_small')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.fines"
                      :label="$t('bookings.fines')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.equipment"
                      :label="$t('bookings.equipment')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.mileage"
                      :label="$t('bookings.many_mileage')"
                      placeholder="0"
                      class="mr-2"
                      hide-details
                    ></v-text-field>
                    <v-text-field
                      v-model.number="invoice.other"
                      :label="$t('bookings.other')"
                      placeholder="0"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-textarea
                      v-model="invoice.description"
                      :label="$t('custom.description')"
                      rows="1"
                      auto-grow
                      :placeholder="$t('custom.add_info')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (editDialog = false),
                (date = null),
                (invoice = {
                  rent: null,
                  deposit: null,
                  delivery: null,
                  delivery_end: null,
                  clean: null,
                  gas: null,
                  damage: null,
                  fines: null,
                  mileage: null,
                  hours_cost: null,
                  insurance: null,
                  equipment: null,
                  other: null,
                  state: 'Создан',
                  date: null,
                  description: null,
                  number: null,
                  client_id: null,
                  booking_id: null,
                })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveInvoice()">{{
            $t("custom.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="paymentDialog"
      :retain-focus="false"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t("invoices.pay") }}</span>
          <v-icon class="ml-2 green--text" @click="savePayment()">
            mdi-content-save-all-outline
          </v-icon>
          <v-spacer></v-spacer>
          <v-icon
            :class="$vuetify.theme.dark ? 'ml-2 white--text' : 'ml-2 black--text'"
            @click="
              (paymentDialog = false),
                (paymentData = {
                  rent: null,
                  deposit: null,
                  delivery: null,
                  delivery_end: null,
                  clean: null,
                  gas: null,
                  damage: null,
                  fines: null,
                  mileage: null,
                  hours_cost: null,
                  insurance: null,
                  equipment: null,
                  other: null,
                  state: 'Оплачен',
                  invoice_id: null,
                })
            "
          >
            mdi-close
          </v-icon>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 md12 class="text-center">
                    <v-flex md12 class="d-inline-flex justify-center">
                      <v-text-field
                        v-model.number="paymentData.rent"
                        :label="$t('bookings.rent')"
                        placeholder="0"
                        class="mr-2"
                        hide-details
                      ></v-text-field>
                      <v-text-field
                        v-model.number="paymentData.deposit"
                        :label="$t('bookings.deposit')"
                        placeholder="0"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-expansion-panels v-model="showAddPayments">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h4 class="mt-3 mb-2">
                            {{ $t("custom.add_services") }}
                          </h4>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-flex md12 class="mt-3 d-inline-flex">
                            <v-text-field
                              v-model.number="paymentData.delivery"
                              :label="$t('bookings.delivery')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.delivery_end"
                              :label="$t('bookings.checkout')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.clean"
                              :label="$t('bookings.clean')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.gas"
                              :label="$t('bookings.gas')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.hours_cost"
                              :label="$t('bookings.add_hours_short')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.damage"
                              :label="$t('bookings.damages')"
                              placeholder="0"
                              hide-details
                            ></v-text-field>
                          </v-flex>
                          <v-flex md12 class="mt-3 d-inline-flex">
                            <v-text-field
                              v-model.number="paymentData.insurance"
                              :label="$t('bookings.insurance')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.add_drivers_cost"
                              :label="$t('bookings.add_drivers_small')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.fines"
                              :label="$t('bookings.fines')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.equipment"
                              :label="$t('bookings.equipment')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.mileage"
                              :label="$t('bookings.many_mileage')"
                              placeholder="0"
                              class="mr-2"
                              hide-details
                            ></v-text-field>
                            <v-text-field
                              v-model.number="paymentData.other"
                              :label="$t('bookings.other')"
                              placeholder="0"
                              hide-details
                            ></v-text-field>
                          </v-flex>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-flex md12 class="mt-3 text-center align-center">
                      <v-select
                        v-model="paymentData.state"
                        clearable
                        :items="invoice_state"
                        item-text="text"
                        item-value="value"
                        :label="$t('bookings.select_state')"
                      ></v-select>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="
              (paymentDialog = false),
                (paymentData = {
                  rent: null,
                  deposit: null,
                  delivery: null,
                  delivery_end: null,
                  clean: null,
                  gas: null,
                  damage: null,
                  fines: null,
                  mileage: null,
                  hours_cost: null,
                  insurance: null,
                  equipment: null,
                  other: null,
                  state: 'Оплачен',
                  invoice_id: null,
                })
            "
            >{{ $t("custom.close") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="savePayment()">{{
            $t("custom.save")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
import { isMobile } from "mobile-device-detect";
// moment.locale("ru");
import Docxtemplater from "docxtemplater";
import axios from "axios";
// import VueAxios from 'vue-axios'
import PizZip from "pizzip";
// import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { rubles } from "rubles";
// var rubles = require('rubles').rubles;
import { Capacitor } from "@capacitor/core";
export default {
  data() {
    return {
      invoices: [],
      invoice: {
        rent: null,
        deposit: null,
        delivery: null,
        delivery_end: null,
        clean: null,
        gas: null,
        damage: null,
        fines: null,
        mileage: null,
        hours_cost: null,
        insurance: null,
        add_drivers_cost: null,
        equipment: null,
        other: null,
        state: "Создан",
        date: null,
        description: null,
        number: null,
        client_id: null,
        booking_id: null,
      },
      invoice_doc_template_url: null,
      invoice_state: [
        { text: this.$t("invoice_state.created"), value: "Создан" },
        { text: this.$t("invoice_state.send"), value: "Выставлен" },
        { text: this.$t("invoice_state.paid_part"), value: "Частично оплачен" },
        { text: this.$t("invoice_state.paid"), value: "Оплачен" },
      ],
      date: null,
      date_format: null,
      date_menu: false,
      date_menu1: false,
      showAddPayments: false,
      showAddPaymentsToInvoice: false,
      headers: [
        { text: this.$t("custom.number"), align: "center", value: "number" },
        { text: this.$t("custom.editor"), sortable: false, align: "center" },
        {
          text: this.$t("counts.date_initial"),
          value: "date",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("invoices.control"), sortable: false, align: "center" },
        { text: this.$t("invoices.get"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      paymentData: {
        rent: null,
        deposit: null,
        delivery: null,
        delivery_end: null,
        clean: null,
        gas: null,
        damage: null,
        fines: null,
        mileage: null,
        hours_cost: null,
        insurance: null,
        add_drivers_cost: null,
        equipment: null,
        other: null,
        state: "Оплачен",
        invoice_id: null,
      },
      clients: [],
      client: null,
      isLoadingClients: false,
      showClientSearch: false,
      search_client: null,
      search: null,
      paymentDialog: false,
      pagination: { sortBy: "created_at", descending: true },
      editDialog: false,
      newInvoiceDialog: false,
      timeout: null,
      isMobile: false,
      isLoading: true,
      error: "",
    };
  },
  created() {
    let self = this;
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      this.axios
        .get("/api/v1/all_invoices", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.invoices = response.data;
          this.axios
            .get(`api/v1/invoice_templates`, {
              headers: {
                Authorization: self.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              if (response.data) {
                self.invoice_doc_template_url = response.data.url;
              } else {
                if (self.$i18n.locale == "ru") {
                  self.invoice_doc_template_url =
                    "https://web.rentprog.ru/invoice_example.docx";
                } else {
                  self.invoice_doc_template_url =
                    "https://web.rentprog.com/invoice_example_en.docx";
                }
              }
              if (self.$route.params != {} && self.$route.params.query) {
                self.search = self.$route.params.query;
              }
            })
            .catch((error) => {
              self.setError(error, self.$t("errors.invoices_templates_load"));
              console.log(error);
            });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.invoices_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    search_client(val) {
      if (this.invoice && this.invoice.client_id) {
        return;
      }
      if (this.search_client == "" || this.search_client == " ") {
        this.search_client = null;
      } else {
        let self = this;
        function debounce() {
          if (self.timeout) clearTimeout(self.timeout);

          self.timeout = setTimeout(() => {
            // your action
            self.loadClients();
          }, 200); // delay
        }
        debounce();
      }
    },
    date() {
      if (this.date) {
        this.date_format = moment(this.date, "YYYY-MM-DD").format("DD-MM-YYYY");
      }
    },
  },
  computed: {
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    isIos() {
      return Capacitor.getPlatform().toLowerCase() == "ios" ? true : false;
    },
  },
  methods: {
    submit() {
      if (this.user_role != "guest") {
        let invoice = this.invoice;
        let self = this;
        let invoice_sum =
          invoice.rent +
          invoice.deposit +
          invoice.delivery +
          invoice.delivery_end +
          invoice.clean +
          invoice.gas +
          invoice.damage +
          invoice.fines +
          invoice.mileage +
          invoice.hours_cost +
          invoice.insurance +
          invoice.equipment +
          invoice.other;
        if (
          invoice_sum > 0 &&
          invoice.client_id &&
          invoice.number &&
          this.date
        ) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/invoices",
              {
                invoices: {
                  rent: invoice.rent > 0 ? invoice.rent : 0,
                  deposit: invoice.deposit > 0 ? invoice.deposit : 0,
                  delivery: invoice.delivery > 0 ? invoice.delivery : 0,
                  delivery_end:
                    invoice.delivery_end > 0 ? invoice.delivery_end : 0,
                  clean: invoice.clean > 0 ? invoice.clean : 0,
                  gas: invoice.gas > 0 ? invoice.gas : 0,
                  damage: invoice.damage > 0 ? invoice.damage : 0,
                  fines: invoice.fines > 0 ? invoice.fines : 0,
                  mileage: invoice.mileage > 0 ? invoice.mileage : 0,
                  hours_cost: invoice.hours_cost > 0 ? invoice.hours_cost : 0,
                  insurance: invoice.insurance > 0 ? invoice.insurance : 0,
                  add_drivers_cost:
                    invoice.add_drivers_cost > 0 ? invoice.add_drivers_cost : 0,
                  equipment: invoice.equipment > 0 ? invoice.equipment : 0,
                  other: invoice.other > 0 ? invoice.other : 0,
                  date: self.date,
                  state: invoice.state,
                  number: invoice.number,
                  description: invoice.description,
                  client_id: invoice.client_id,
                  booking_id: invoice.booking_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.invoices.unshift(response.data);

              this.invoice = {
                sum: null,
                rent: null,
                deposit: null,
                delivery: null,
                delivery_end: null,
                clean: null,
                gas: null,
                damage: null,
                fines: null,
                mileage: null,
                hours_cost: null,
                insurance: null,
                equipment: null,
                other: null,
                state: "Создан",
                date: null,
                description: null,
                number: null,
                client_id: null,
                booking_id: null,
              };
              this.newInvoiceDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("invoices.added"),
                text: this.$t("invoices.added_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.add_invoice"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.add_invoice"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
            text: this.$t("invoices.required_fields"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    editInvoice(id) {
      this.invoice = this.invoices.find((invoice) => invoice.id == id);
      this.date = moment(this.invoice.date).format("YYYY-MM-DD");
      this.editDialog = true;
    },
    saveInvoice() {
      if (this.user_role != "guest") {
        let invoice = this.invoice;
        let self = this;
        let invoice_sum =
          invoice.rent +
          invoice.deposit +
          invoice.delivery +
          invoice.delivery_end +
          invoice.clean +
          invoice.gas +
          invoice.damage +
          invoice.fines +
          invoice.mileage +
          invoice.hours_cost +
          invoice.insurance +
          invoice.equipment +
          invoice.other;
        if ((invoice_sum > 0, invoice.client_id, invoice.number, this.date)) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/invoices/${invoice.id}`,
              {
                invoices: {
                  rent: invoice.rent > 0 ? invoice.rent : 0,
                  deposit: invoice.deposit > 0 ? invoice.deposit : 0,
                  delivery: invoice.delivery > 0 ? invoice.delivery : 0,
                  delivery_end:
                    invoice.delivery_end > 0 ? invoice.delivery_end : 0,
                  clean: invoice.clean > 0 ? invoice.clean : 0,
                  gas: invoice.gas > 0 ? invoice.gas : 0,
                  damage: invoice.damage > 0 ? invoice.damage : 0,
                  fines: invoice.fines > 0 ? invoice.fines : 0,
                  mileage: invoice.mileage > 0 ? invoice.mileage : 0,
                  hours_cost: invoice.hours_cost > 0 ? invoice.hours_cost : 0,
                  insurance: invoice.insurance > 0 ? invoice.insurance : 0,
                  add_drivers_cost:
                    invoice.add_drivers_cost > 0 ? invoice.add_drivers_cost : 0,
                  equipment: invoice.equipment > 0 ? invoice.equipment : 0,
                  other: invoice.other > 0 ? invoice.other : 0,
                  date: self.date,
                  state: invoice.state,
                  number: invoice.number,
                  description: invoice.description,
                  client_id: invoice.client_id,
                  booking_id: invoice.booking_id,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let foundIndex = this.invoices.findIndex(
                (element) => element.id === invoice.id
              );
              // Добавляю paid
              let invoice_data = response.data;
              invoice_data["paid"] = invoice.paid;
              invoice_data["paid"] = invoice.client_name;
              this.invoices.splice(foundIndex, 1, invoice_data);

              this.invoice = {
                rent: null,
                deposit: null,
                delivery: null,
                delivery_end: null,
                clean: null,
                gas: null,
                damage: null,
                fines: null,
                mileage: null,
                hours_cost: null,
                insurance: null,
                add_drivers_cost: null,
                equipment: null,
                other: null,
                state: "Создан",
                date: null,
                description: null,
                number: null,
                client_id: null,
                booking_id: null,
              };
              this.date = null;
              this.editDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("invoices.updated"),
                text: this.$t("invoices.updated_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.update_invoice"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.update_invoice"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
            text: this.$t("invoices.required_fields"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    paymentStart(invoce) {
      this.paymentData = {
        rent: null,
        deposit: null,
        delivery: null,
        delivery_end: null,
        clean: null,
        gas: null,
        damage: null,
        fines: null,
        mileage: null,
        hours_cost: null,
        insurance: null,
        add_drivers_cost: null,
        equipment: null,
        other: null,
        state: "Оплачен",
        invoice_id: invoce.id,
      };
      this.paymentDialog = true;
    },
    savePayment() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        if (
          (this.paymentData.rent ||
            this.paymentData.deposit ||
            this.paymentData.delivery ||
            this.paymentData.delivery_end ||
            this.paymentData.clean ||
            this.paymentData.gas ||
            this.paymentData.damage ||
            this.paymentData.fines ||
            this.paymentData.mileage ||
            this.paymentData.hours_cost ||
            this.paymentData.insurance ||
            this.paymentData.add_drivers_cost ||
            this.paymentData.equipment ||
            this.paymentData.other) &&
          this.paymentData.state &&
          this.paymentData.invoice_id
        ) {
          this.axios
            .post(
              `/api/v1/invoice_payment`,
              {
                rent: this.paymentData.rent ? this.paymentData.rent : 0,
                deposit: this.paymentData.deposit
                  ? this.paymentData.deposit
                  : 0,
                delivery: this.paymentData.delivery
                  ? this.paymentData.delivery
                  : 0,
                delivery_end: this.paymentData.delivery_end
                  ? this.paymentData.delivery_end
                  : 0,
                clean: this.paymentData.clean ? this.paymentData.clean : 0,
                gas: this.paymentData.gas ? this.paymentData.gas : 0,
                damage: this.paymentData.damage ? this.paymentData.damage : 0,
                fines: this.paymentData.fines ? this.paymentData.fines : 0,
                mileage: this.paymentData.mileage
                  ? this.paymentData.mileage
                  : 0,
                hours_cost: this.paymentData.hours_cost
                  ? this.paymentData.hours_cost
                  : 0,
                insurance: this.paymentData.insurance
                  ? this.paymentData.insurance
                  : 0,
                add_drivers_cost: this.paymentData.add_drivers_cost
                  ? this.paymentData.add_drivers_cost
                  : 0,
                equipment: this.paymentData.equipment
                  ? this.paymentData.equipment
                  : 0,
                other: this.paymentData.other ? this.paymentData.other : 0,
                state: this.paymentData.state,
                invoice_id: this.paymentData.invoice_id,
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then(() => {
              let payment_sum =
                this.paymentData.rent +
                this.paymentData.deposit +
                this.paymentData.delivery +
                this.paymentData.delivery_end +
                this.paymentData.clean +
                this.paymentData.gas +
                this.paymentData.damage +
                this.paymentData.fines +
                this.paymentData.mileage +
                this.paymentData.hours_cost +
                this.paymentData.insurance +
                this.paymentData.add_drivers_cost +
                this.paymentData.equipment +
                this.paymentData.other;
              let foundIndex = this.invoices.findIndex(
                (element) => element.id === self.paymentData.invoice_id
              );
              this.invoices[foundIndex].state = this.paymentData.state;
              this.invoices[foundIndex].paid += payment_sum;
              this.paymentData = {
                rent: null,
                deposit: null,
                delivery: null,
                delivery_end: null,
                clean: null,
                gas: null,
                damage: null,
                fines: null,
                mileage: null,
                hours_cost: null,
                insurance: null,
                add_drivers_cost: null,
                equipment: null,
                other: null,
                state: "Оплачен",
                invoice_id: null,
              };

              this.paymentDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("invoices.payment_added"),
                text: this.$t("invoices.payment_added_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.paid_invoice"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.paid_invoice"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.isLoading = false;
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            icon: "warning",
            title: this.$t("custom.no_data"),
            text: this.$t("errors.most_input_data"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    loadClients() {
      this.isLoadingClients = true;
      this.axios
        .post(
          `/api/v1/index_new_booking_page`,
          {
            search: this.search_client,
            meilisearch: true,
          },
          {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          this.clients = response.data.query ? response.data.query : (response.data ? response.data : []);
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                (client.entity_phone
                  ? client.entity_phone.slice(client.entity_phone.length - 4)
                  : "");
            }
          });
        })
        .catch((error) => {
          this.isLoadingClients = false;
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
    updateState(invoice, state) {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let self = this;
        this.axios
          .patch(
            `/api/v1/invoices/${invoice.id}`,
            {
              invoices: {
                state: state,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            let foundIndex = this.invoices.findIndex(
              (element) => element.id === invoice.id
            );
            // Добавляю paid
            let invoice_data = response.data;
            invoice_data["paid"] = invoice.paid;
            this.invoices.splice(foundIndex, 1, invoice_data);

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("invoices.edited_title"),
              text: this.$t("invoices.edited_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.edited_count"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.edited_count"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    selectStateLocale(state) {
      if (state == "Создан") {
        return this.$t("invoice_state.created");
      } else if (state == "Выставлен") {
        return this.$t("invoice_state.send");
      } else if (state == "Частично оплачен") {
        return this.$t("invoice_state.paid_part");
      } else if (state == "Оплачен") {
        return this.$t("invoice_state.paid");
      } else {
        return "?";
      }
    },
    stateColor(state) {
      if (state == "Выставлен") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Частично оплачен") {
        return "pink";
      }
    },
    deleteItem(invoice) {
      if (this.user_role != "guest") {
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("custom.you_sure_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/invoices/${invoice.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then((response) => {
                this.invoices.splice(this.invoices.indexOf(invoice), 1);
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.remove_invoice"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("invoices.remove_success"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    generateInvoice(invoice) {
      this.isLoading = true;
      let self = this;
      let content, error, url;
      url = self.invoice_doc_template_url;
      axios
        .get(url, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        })
        .then((response) => {
          content = response.data;
          var zip = new PizZip(content);
          this.isLoading = true;
          this.axios
            .get(`/api/v1/clients/${invoice.client_id}`, {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            })
            .then((response) => {
              let client = response.data.client;
              let booking = null;

              if (invoice.booking_id) {
                this.axios
                  .get(`/api/v1/bookings/${invoice.booking_id}`, {
                    headers: {
                      Authorization: this.$store.getters.getAuthToken,
                    },
                  })
                  .then((response) => {
                    booking = response.data;

                    var doc = new Docxtemplater().loadZip(zip);

                    let invoice_sum =
                      invoice.rent +
                      invoice.deposit +
                      invoice.delivery +
                      invoice.delivery_end +
                      invoice.clean +
                      invoice.gas +
                      invoice.damage +
                      invoice.fines +
                      invoice.mileage +
                      invoice.hours_cost +
                      invoice.insurance +
                      invoice.equipment +
                      invoice.add_drivers_cost +
                      invoice.other;
                    let services = [];
                    // if (invoice.rent > 0) {
                    //   services.push({
                    //     service: "Аренда автомобиля",
                    //     sum: invoice.rent
                    //   });
                    // }
                    if (invoice.deposit > 0) {
                      services.push({
                        service: self.$t("invoices.deposit"),
                        sum: invoice.deposit,
                      });
                    }
                    if (invoice.delivery > 0) {
                      services.push({
                        service: self.$t("invoices.delivery"),
                        sum: invoice.delivery,
                      });
                    }
                    if (invoice.delivery_end > 0) {
                      services.push({
                        service: self.$t("invoices.delivery_end"),
                        sum: invoice.delivery_end,
                      });
                    }
                    if (invoice.clean > 0) {
                      services.push({
                        service: self.$t("invoices.clean"),
                        sum: invoice.clean,
                      });
                    }
                    if (invoice.gas > 0) {
                      services.push({
                        service: self.$t("invoices.fuel"),
                        sum: invoice.gas,
                      });
                    }
                    if (invoice.damage > 0) {
                      services.push({
                        service: self.$t("invoices.damages"),
                        sum: invoice.damage,
                      });
                    }
                    if (invoice.fines > 0) {
                      services.push({
                        service: self.$t("invoices.fines"),
                        sum: invoice.fines,
                      });
                    }
                    if (invoice.mileage > 0) {
                      services.push({
                        service: self.$t("invoices.mileage"),
                        sum: invoice.mileage,
                      });
                    }
                    if (invoice.hours_cost > 0) {
                      services.push({
                        service: self.$t("invoices.add_hours"),
                        sum: invoice.hours_cost,
                      });
                    }
                    if (invoice.insurance > 0) {
                      services.push({
                        service: self.$t("invoices.insurance"),
                        sum: invoice.insurance,
                      });
                    }
                    if (invoice.equipment > 0) {
                      services.push({
                        service: self.$t("invoices.equipment"),
                        sum: invoice.equipment,
                      });
                    }
                    if (invoice.add_drivers_cost > 0) {
                      services.push({
                        service: self.$t("invoices.add_drivers_cost"),
                        sum: invoice.add_drivers_cost,
                      });
                    }
                    if (invoice.other > 0) {
                      services.push({
                        service: self.$t("invoices.other"),
                        sum: invoice.other,
                      });
                    }

                    // Для нумерации в цикле
                    services.forEach((s) => {
                      s.number = services.indexOf(s) + 2;
                    });

                    doc.setData({
                      date: moment().format("DD.MM.YYYY"),
                      date_short: moment().format("DD/MM"),
                      start_date:
                        booking && booking.start_date
                          ? booking.start_date
                          : "      ",
                      end_date:
                        booking && booking.end_date
                          ? booking.end_date
                          : "      ",
                      start_date_short: booking.start_date
                        ? moment(booking.start_date, "DD-MM-YYYY H:mm").format(
                            "DD.MM.YYYY"
                          )
                        : "     ",
                      end_date_short: booking.end_date
                        ? moment(booking.end_date, "DD-MM-YYYY H:mm").format(
                            "DD.MM.YY"
                          )
                        : "     ",
                      day_cost:
                        booking && booking.price ? booking.price : "     ",
                      cost:
                        booking && booking.rental_cost
                          ? booking.rental_cost
                          : "      ",
                      days: booking && booking.days ? booking.days : "      ",
                      car_name:
                        booking && booking.car_name
                          ? booking.car_name
                          : "      ",
                      number: invoice.number,
                      rent: invoice.rent > 0 ? invoice.rent : 0,
                      deposit: invoice.deposit > 0 ? invoice.deposit : 0,
                      delivery: invoice.delivery > 0 ? invoice.delivery : 0,
                      delivery_end:
                        invoice.delivery_end > 0 ? invoice.delivery_end : 0,
                      clean: invoice.clean > 0 ? invoice.clean : 0,
                      gas: invoice.gas > 0 ? invoice.gas : 0,
                      damage: invoice.damage > 0 ? invoice.damage : 0,
                      fines: invoice.fines > 0 ? invoice.fines : 0,
                      mileage: invoice.mileage > 0 ? invoice.mileage : 0,
                      hours_cost:
                        invoice.hours_cost > 0 ? invoice.hours_cost : 0,
                      insurance: invoice.insurance > 0 ? invoice.insurance : 0,
                      add_drivers_cost:
                        invoice.add_drivers_cost > 0
                          ? invoice.add_drivers_cost
                          : 0,
                      equipment: invoice.equipment > 0 ? invoice.equipment : 0,
                      other: invoice.other > 0 ? invoice.other : 0,
                      sum: invoice_sum,
                      sum_words: rubles(invoice_sum),
                      entity_name: client.entity_name,
                      ceo: client.ceo ? client.ceo : "                 ",
                      reg_form: client.reg_form ? client.reg_form : "    ",
                      entity_phone: client.entity_phone
                        ? client.entity_phone
                        : "                ",
                      entity_adress: client.entity_adress
                        ? client.entity_adress
                        : "                              ",
                      city: client.city ? client.city : "      ",
                      country: client.country ? client.country : "      ",
                      inn: client.inn,
                      ogrn: client.ogrn,
                      acc_number: client.acc_number,
                      bank: client.bank,
                      korr: client.korr,
                      bik: client.bik,
                      doc_number: client.doc_number
                        ? client.doc_number
                        : "    ",
                      services: services.length > 0 ? services : null,
                    });
                    doc.render();
                    var out = doc.getZip().generate({
                      type: "blob",
                      mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    }); //Output the document using Data-URI
                    let date = moment().format("Y_MM_DD");
                    // console.log(client)
                    saveAs(out, date + "_" + client.entity_name + ".docx");
                  })
                  .catch((error) => {
                    this.setError(error, this.$t("errors.booking_load"));
                    console.log(error);
                  });
              } else {
                var doc = new Docxtemplater().loadZip(zip);
                let invoice_sum =
                  invoice.rent +
                  invoice.deposit +
                  invoice.delivery +
                  invoice.delivery_end +
                  invoice.clean +
                  invoice.gas +
                  invoice.damage +
                  invoice.fines +
                  invoice.mileage +
                  invoice.hours_cost +
                  invoice.insurance +
                  invoice.equipment +
                  invoice.other;
                let services = [];
                // if (invoice.rent > 0) {
                //   services.push({
                //     service: "Аренда автомобиля",
                //     sum: invoice.rent
                //   });
                // }
                if (invoice.deposit > 0) {
                  services.push({
                    service: self.$t("invoices.deposit"),
                    sum: invoice.deposit,
                  });
                }
                if (invoice.delivery > 0) {
                  services.push({
                    service: self.$t("invoices.delivery"),
                    sum: invoice.delivery,
                  });
                }
                if (invoice.delivery_end > 0) {
                  services.push({
                    service: self.$t("invoices.delivery_end"),
                    sum: invoice.delivery_end,
                  });
                }
                if (invoice.clean > 0) {
                  services.push({
                    service: self.$t("invoices.clean"),
                    sum: invoice.clean,
                  });
                }
                if (invoice.gas > 0) {
                  services.push({
                    service: self.$t("invoices.fuel"),
                    sum: invoice.gas,
                  });
                }
                if (invoice.damage > 0) {
                  services.push({
                    service: self.$t("invoices.damages"),
                    sum: invoice.damage,
                  });
                }
                if (invoice.fines > 0) {
                  services.push({
                    service: self.$t("invoices.fines"),
                    sum: invoice.fines,
                  });
                }
                if (invoice.mileage > 0) {
                  services.push({
                    service: self.$t("invoices.mileage"),
                    sum: invoice.mileage,
                  });
                }
                if (invoice.hours_cost > 0) {
                  services.push({
                    service: self.$t("invoices.add_hours"),
                    sum: invoice.hours_cost,
                  });
                }
                if (invoice.insurance > 0) {
                  services.push({
                    service: self.$t("invoices.insurance"),
                    sum: invoice.insurance,
                  });
                }
                if (invoice.equipment > 0) {
                  services.push({
                    service: self.$t("invoices.equipment"),
                    sum: invoice.equipment,
                  });
                }
                if (invoice.add_drivers_cost > 0) {
                  services.push({
                    service: self.$t("invoices.add_drivers_cost"),
                    sum: invoice.add_drivers_cost,
                  });
                }
                if (invoice.other > 0) {
                  services.push({
                    service: self.$t("invoices.other"),
                    sum: invoice.other,
                  });
                }
                // Для нумерации в цикле
                services.forEach((s) => {
                  s.number = services.indexOf(s) + 2;
                });
                doc.setData({
                  date: moment().format("DD.MM.YYYY"),
                  date_short: moment().format("DD/MM"),
                  start_date:
                    booking && booking.start_date
                      ? booking.start_date
                      : "      ",
                  end_date:
                    booking && booking.end_date ? booking.end_date : "      ",
                  day_cost: booking && booking.price ? booking.price : "     ",
                  cost:
                    booking && booking.rental_cost
                      ? booking.rental_cost
                      : "      ",
                  days: booking && booking.days ? booking.days : "      ",
                  car_name:
                    booking && booking.car_name ? booking.car_name : "      ",
                  number: invoice.number,
                  rent: invoice.rent > 0 ? invoice.rent : 0,
                  deposit: invoice.deposit > 0 ? invoice.deposit : 0,
                  delivery: invoice.delivery > 0 ? invoice.delivery : 0,
                  delivery_end:
                    invoice.delivery_end > 0 ? invoice.delivery_end : 0,
                  clean: invoice.clean > 0 ? invoice.clean : 0,
                  gas: invoice.gas > 0 ? invoice.gas : 0,
                  damage: invoice.damage > 0 ? invoice.damage : 0,
                  fines: invoice.fines > 0 ? invoice.fines : 0,
                  mileage: invoice.mileage > 0 ? invoice.mileage : 0,
                  hours_cost: invoice.hours_cost > 0 ? invoice.hours_cost : 0,
                  insurance: invoice.insurance > 0 ? invoice.insurance : 0,
                  add_drivers_cost:
                    invoice.add_drivers_cost > 0 ? invoice.add_drivers_cost : 0,
                  equipment: invoice.equipment > 0 ? invoice.equipment : 0,
                  other: invoice.other > 0 ? invoice.other : 0,
                  sum: invoice_sum,
                  sum_words: rubles(invoice_sum),
                  entity_name: client.entity_name,
                  short_entity_name: client.short_entity_name,
                  ceo: client.ceo,
                  reg_form: client.reg_form,
                  entity_phone: client.entity_phone,
                  city: client.city ? client.city : "      ",
                  country: client.country ? client.country : "      ",
                  entity_adress: client.entity_adress,
                  inn: client.inn,
                  ogrn: client.ogrn,
                  acc_number: client.acc_number,
                  bank: client.bank,
                  korr: client.korr,
                  bik: client.bik,
                  doc_number: client.doc_number ? client.doc_number : "    ",
                  services: services.length > 0 ? services : null,
                });

                doc.render();

                var out = doc.getZip().generate({
                  type: "blob",
                  mimeType:
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }); //Output the document using Data-URI
                let date = moment().format("Y_MM_DD");
                // console.log(client)
                saveAs(out, date + "_" + client.entity_name + ".docx");
              }
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.client_load"));
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
    paidInvoice(invoice) {
      let invoice_sum =
        invoice.rent +
        invoice.deposit +
        invoice.delivery +
        invoice.delivery_end +
        invoice.clean +
        invoice.gas +
        invoice.damage +
        invoice.fines +
        invoice.mileage +
        invoice.hours_cost +
        invoice.insurance +
        invoice.add_drivers_cost +
        invoice.equipment +
        invoice.other;
      if (invoice_sum == invoice.paid && invoice_sum > 0 && invoice.paid > 0) {
        return this.$t("invoices.paid");
      } else if (invoice_sum < invoice.paid) {
        return this.$t("invoices.extra_paid");
      } else if (invoice_sum > 0 && invoice_sum > invoice.paid) {
        return this.$t("invoices.low_paid");
      } else if (invoice_sum > 0 && invoice.paid == 0) {
        return this.$t("invoices.no_income");
      } else {
        return `-`;
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    returnDate(item) {
      return moment(item, "YYYY-MM-DD").format("LL");
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser.id,
            username: this.$store.getters.getCurrentUser.name,
            email: this.$store.getters.getCurrentUser.email,
          },
          custom: {
            company: this.$store.getters.getCompany.company.name,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
};
</script>
