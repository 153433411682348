import { render, staticRenderFns } from "./uppy_checkin.vue?vue&type=template&id=21bd69be&scoped=true&"
import script from "./uppy_checkin.vue?vue&type=script&lang=js&"
export * from "./uppy_checkin.vue?vue&type=script&lang=js&"
import style0 from "./uppy_checkin.vue?vue&type=style&index=0&id=21bd69be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21bd69be",
  null
  
)

export default component.exports