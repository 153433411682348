<template>
  <v-navigation-drawer v-if="!isMobile" app expand-on-hover>
    <!-- <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar>
           <v-gravatar :email="currentUser().email" />
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Sandra Adams
          </v-list-item-title>
          <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider> -->
    <v-list
        v-if="checkAdmin()"
        dense
      >
        <v-list-item link to="/dashboard" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Главная</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/bookings" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-book-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Брони</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/calendars/timeline"
          @click="closeOnContentClick = true"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-cursor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>План на день</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/calendars/new" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-calendar-month-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Календарь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            @click="closeOnContentClick = true"
            style="padding-left: 40px !important;"
            :to="subItem.to"
          >
            <v-list-item-action>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item link to="/booking/new" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-plus-box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Добавить бронь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/help/quick_start" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Помощь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        v-else-if="checkManager()"
        dense
      >
        <v-list-item to="/dashboard" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Главная</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/bookings" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-book-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Брони</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/calendars/timeline"
          @click="closeOnContentClick = true"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-cursor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>План на день</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-calendar-month-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Календарь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-for="item in manager_items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            @click="closeOnContentClick = true"
            style="padding-left: 40px !important;"
            :to="subItem.to"
          >
            <v-list-item-action>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item to="/todos_user" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-calendar-multiple-check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ToDo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/schedule_calendar"
          @click="closeOnContentClick = true"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-multiselect</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Расписание</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/salary" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-account-cash-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Зарплата</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/admin/users" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-account-hard-hat</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Сотрудники</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/booking/new" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-plus-box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Добавить бронь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/help/quick_start" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Помощь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        v-else
        dense
      >
        <v-list-item to="/dashboard" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Главная</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/bookings" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-book-multiple</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Брони</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/calendars/timeline"
          @click="closeOnContentClick = true"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-cursor</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>План на день</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/calendars/new" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-calendar-month-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Календарь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-for="item in worker_items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.icon"
          no-action
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.title"
            @click="closeOnContentClick = true"
            style="padding-left: 40px !important;"
            :to="subItem.to"
          >
            <v-list-item-action>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item to="/todos_user" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-calendar-multiple-check</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ToDo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/schedule_calendar"
          @click="closeOnContentClick = true"
        >
          <v-list-item-action>
            <v-icon>mdi-calendar-multiselect</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Расписание</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/salary" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-account-cash-outline</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Зарплата</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/booking/new" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-plus-box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Добавить бронь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item to="/help/quick_start" @click="closeOnContentClick = true">
          <v-list-item-action>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Помощь</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    <!-- <v-list nav dense>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-folder</v-icon>
        </v-list-item-icon>
        <v-list-item-title>My Files</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Shared with me</v-list-item-title>
      </v-list-item>
      <v-list-item link>
        <v-list-item-icon>
          <v-icon>mdi-star</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Starred</v-list-item-title>
      </v-list-item>
    </v-list> -->
  </v-navigation-drawer>
</template>
<script>
import Gravatar from "vue-gravatar";
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      items: [
        {
          icon: "mdi-car-multiple",
          title: "Автомобили",
          active: false,
          items: [
            { title: "Автомобили", icon: "mdi-car-multiple", to: "/cars" },
            { title: "Добавить авто", icon: "mdi-plus-box", to: "/cars/new" }
          ]
        },
        {
          icon: "mdi-calendar-account-outline",
          title: "Клиенты",
          active: false,
          items: [
            {
              title: "Клиенты",
              icon: "mdi-calendar-account-outline",
              to: "/clients"
            },
            { title: "Штрафы", icon: "mdi-car-emergency", to: "/fines" },
            {
              title: "Долги",
              icon: "mdi-credit-card-remove-outline",
              to: "/debts"
            },
            { title: "Счета", icon: "mdi-receipt", to: "/invoices" },
            {
              title: "Добавить клиента",
              icon: "mdi-plus-box",
              to: "/client/new"
            }
          ]
        },
        {
          icon: "mdi-cash-register",
          title: "Касса",
          active: false,
          items: [
            { title: "Касса", icon: "mdi-cash-register", to: "/counts" },
            {
              title: "Касса компании",
              icon: "mdi-cash-lock",
              to: "/company_counts"
            },
            {
              title: "Новый платёж",
              icon: "mdi-book-plus-multiple",
              to: "/counts/new"
            }
          ]
        },
        {
          icon: "mdi-book-plus-multiple",
          title: "Обслуживание",
          active: false,
          items: [
            {
              title: "Тех. обслуживание",
              icon: "mdi-car-cog",
              to: "/service_tech"
            },
            {
              title: "Страховки",
              icon: "mdi-shield-car",
              to: "/service_insurance"
            }
          ]
        },
        {
          icon: "mdi-calendar-multiple-check",
          title: "ToDo",
          active: false,
          items: [
            {
              title: "Все ToDo",
              icon: "mdi-calendar-multiple-check",
              to: "/todos_all"
            },
            {
              title: "Личные ToDo",
              icon: "mdi-calendar-account-outline",
              to: "/todos_user"
            }
          ]
        },
        {
          icon: "mdi-domain",
          title: "Компания",
          active: false,
          items: [
            {
              title: "Аналитика",
              icon: "mdi-chart-areaspline",
              to: "/analitics"
            },
            {
              title: "Сотрудники",
              icon: "mdi-account-hard-hat",
              to: "/admin/users"
            },
            {
              title: "Расписание",
              icon: "mdi-calendar-multiselect",
              to: "/schedule_calendar"
            },
            {
              title: "Зарплата",
              icon: "mdi-account-cash-outline",
              to: "/salary"
            },
            {
              title: "Партнёры",
              icon: "mdi-handshake-outline",
              to: "/investors"
            }
          ]
        }
      ],
      selected_items: [],
      worker_items: [
        {
          icon: "mdi-car-multiple",
          title: "Автомобили",
          active: false,
          items: [
            { title: "Автомобили", icon: "mdi-car-multiple", to: "/cars" },
            { title: "Добавить авто", icon: "mdi-plus-box", to: "/cars/new" }
          ]
        },
        {
          icon: "mdi-calendar-account-outline",
          title: "Клиенты",
          active: false,
          items: [
            {
              title: "Клиенты",
              icon: "mdi-calendar-account-outline",
              to: "/clients"
            },
            { title: "Штрафы", icon: "mdi-car-emergency", to: "/fines" },
            {
              title: "Долги",
              icon: "mdi-credit-card-remove-outline",
              to: "/debts"
            },
            { title: "Счета", icon: "mdi-receipt", to: "/invoices" },
            {
              title: "Добавить клиента",
              icon: "mdi-plus-box",
              to: "/client/new"
            }
          ]
        },
        {
          icon: "mdi-cash-register",
          title: "Касса",
          active: false,
          items: [
            { title: "Касса", icon: "mdi-cash-register", to: "/counts" },
            {
              title: "Новый платёж",
              icon: "mdi-book-plus-multiple",
              to: "/counts/new"
            }
          ]
        },
        {
          icon: "mdi-book-plus-multiple",
          title: "Обслуживание",
          active: false,
          items: [
            {
              title: "Тех. обслуживание",
              icon: "mdi-car-cog",
              to: "/service_tech"
            },
            {
              title: "Страховки",
              icon: "mdi-shield-car",
              to: "/service_insurance"
            }
          ]
        }
      ],
      manager_items: [
        {
          icon: "mdi-car-multiple",
          title: "Автомобили",
          active: false,
          items: [
            { title: "Автомобили", icon: "mdi-car-multiple", to: "/cars" },
            { title: "Добавить авто", icon: "mdi-plus-box", to: "/cars/new" }
          ]
        },
        {
          icon: "mdi-calendar-account-outline",
          title: "Клиенты",
          active: false,
          items: [
            {
              title: "Клиенты",
              icon: "mdi-calendar-account-outline",
              to: "/clients"
            },
            { title: "Штрафы", icon: "mdi-car-emergency", to: "/fines" },
            {
              title: "Долги",
              icon: "mdi-credit-card-remove-outline",
              to: "/debts"
            },
            { title: "Счета", icon: "mdi-receipt", to: "/invoices" },
            {
              title: "Добавить клиента",
              icon: "mdi-plus-box",
              to: "/client/new"
            }
          ]
        },
        {
          icon: "mdi-cash-register",
          title: "Касса",
          active: false,
          items: [
            { title: "Касса", icon: "mdi-cash-register", to: "/counts" },
            {
              title: "Новый платёж",
              icon: "mdi-book-plus-multiple",
              to: "/counts/new"
            }
          ]
        },
        {
          icon: "mdi-book-plus-multiple",
          title: "Обслуживание",
          active: false,
          items: [
            {
              title: "Тех. обслуживание",
              icon: "mdi-car-cog",
              to: "/service_tech"
            },
            {
              title: "Страховки",
              icon: "mdi-shield-car",
              to: "/service_insurance"
            }
          ]
        }
      ],
    };
  },
  computed: {
    drawer: function() {
      return this.$store.getters.getDrawer;
    }
  },
  methods: {
    replaceToProfile() {
      this.$router.push({ name: "Profile" }).catch(err => {});
    },
    replaceToSalary() {
      this.$router.push({ name: "Salary" }).catch(err => {});
    },
    replaceToCheckLists() {
      this.$router.push({ name: "CheckList" }).catch(err => {});
    },
    replaceToSchedule() {
      this.$router.push({ name: "ScheduleCalendar" }).catch(err => {});
    },
    replaceToCompanyProfile() {
      this.$router.push({ name: "CompanyProfile" }).catch(err => {});
    },
    replaceToCompanyCounts() {
      this.$router.push({ name: "CompanyCounts" }).catch(err => {});
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.id : null,
            username: this.$store.getters.getCurrentUser.name ? this.$store.getters.getCurrentUser.name : null,
            email: this.$store.getters.getCurrentUser ? this.$store.getters.getCurrentUser.email : null,
          },
          custom: {
            company: this.$store.getters.getCompany && this.$store.getters.getCompany.company && this.$store.getters.getCompany.company.name ? this.$store.getters.getCompany.company.name : null,
          }
        }
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t('errors.error'),
        text: `${error}. ${text}`
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
    signOut() {
      this.$store.commit("unsetCurrentUser");
    },
    checkAdmin() {
      return this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    checkAdmin() {
      return this.$store.getters.isAdmin;
    },
    checkManager() {
      return this.$store.getters.isManager;
    },
    signOut() {
      this.$http.secured
        .delete("/signin")
        .then(response => {
          this.$store.commit("unsetCurrentUser");
          this.$router.push({ path: `/signin` }).catch(err => {});
        })
        .catch(error => this.setError(error, "Cannot sign out"));
    },
    closeDrawer: function() {
      return this.$store.commit("drawer");
    }
  },
  components: {
    "v-gravatar": Gravatar
  }
};
</script>
