<template>
  <v-container
    v-resize="checkMobile"
    :style="isMobile ? 'padding: 5px !important;' : ''"
  >
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title v-if="!isMobile" class="d-flex align-middle">
        <h2
          v-if="!client.entity"
          :style="
            user_role == 'guest' || user_role == 'partner'
              ? 'display: none;'
              : ''
          "
        >
          {{ client.lastname }} {{ client.name }} {{ client.middlename }}
          {{ client.phone }}
        </h2>
        <h2
          v-if="client.entity"
          :style="
            user_role == 'guest' || user_role == 'partner'
              ? 'display: none;'
              : ''
          "
        >
          {{ client.reg_form }} {{ client.entity_name }}
          {{ client.entity_phone }}
        </h2>
        <v-icon class="ml-2 green--text" @click="editSave">
          mdi-content-save-all-outline
        </v-icon>
        <v-icon v-if="company && company.whatsapp && client.phone && client.phone.length > 6" class="ml-2 green--text" @click="replaceToWhatsAppPage()">
          mdi-whatsapp
        </v-icon>
        <v-spacer></v-spacer>
        <v-switch
          v-model="client.problems"
          :label="$t('clients.bad')"
          hide-details
          class="mt-0 mx-2"
        ></v-switch>
      </v-card-title>
      <v-card-title v-else>
        <v-flex class="">
          <v-flex>
            <h4
              v-if="!client.entity"
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ client.lastname }} {{ client.name }} {{ client.middlename }}
              <br />
              {{ client.phone }}
            </h4>
            <h4
              v-if="client.entity"
              class="text-center"
              :style="
                user_role == 'guest' || user_role == 'partner'
                  ? 'display: none;'
                  : ''
              "
            >
              {{ client.reg_form }} {{ client.entity_name }}<br />
              {{ client.entity_phone }}
            </h4>
          </v-flex>
          <v-flex class="text-center">
            <v-icon class="ml-2 green--text" size="35" @click="editSave">
              mdi-content-save-all-outline
            </v-icon>
            <v-icon v-if="company && company.whatsapp && client.phone && client.phone.length > 6" class="ml-2 green--text" @click="replaceToWhatsAppPage()">
              mdi-whatsapp
            </v-icon>
            <v-switch
              v-model="client.problems"
              :label="$t('clients.bad')"
              hide-details
            ></v-switch>
          </v-flex>
        </v-flex>
      </v-card-title>
      <v-card-text :style="isMobile ? 'padding: 5px !important;' : ''">
        <v-container
          grid-list-md
          :style="isMobile ? 'padding: 5px !important;' : ''"
        >
          <v-container
            v-if="!client.entity"
            :style="isMobile ? 'padding: 5px !important;' : ''"
          >
            <v-layout row wrap>
              <v-flex md12 v-if="client.debtor">
                <h2 class="orange darken-4">{{ $t("clients.have_debt") }}</h2>
              </v-flex>
              <v-flex md12 v-if="client.problems">
                <h2 class="red lighten-1">{{ $t("clients.bad_warn") }}</h2>
              </v-flex>
              <v-flex md12>
                <v-textarea
                  v-if="client.problems"
                  v-model="client.problems_description"
                  outlined
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.lastname"
                  :label="$t('bookings.lastname')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.name"
                  :label="$t('bookings.name')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.middlename"
                  :label="$t('bookings.middlename')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.fio"
                  :label="$t('clients.fio')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.phone"
                  :label="$t('custom.phone')"
                  type="tel"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.email"
                  :label="$t('custom.email')"
                  type="email"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-if="showEditBirthday"
                  v-model="client.birthday"
                  @click="showEditBirthday = true"
                  :label="$t('bookings.birthdate')"
                  :placeholder="$t('bookings.birthdate_plc')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="birthday_non_format"
                  :label="$t('bookings.birthdate')"
                  v-mask="'##.##.####'"
                  :placeholder="$t('bookings.birthdate_plc')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-select
                  v-model="client.category"
                  :items="customer_category"
                  item-text="text"
                  item-value="value"
                  :label="$t('bookings.client_category')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md2>
                <v-text-field
                  v-model="client.passport_series"
                  :label="$t('custom.passport_series')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.passport_number"
                  :label="$t('custom.passport_number')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.passport_issued"
                  :label="$t('custom.passport_issued')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.inn"
                  :label="$t('custom.tax_number')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.country"
                  :label="$t('custom.country')"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.city"
                  :label="$t('custom.city')"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.address"
                  :label="$t('custom.address')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.driver_series"
                  :label="$t('custom.license_series')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.driver_number"
                  :label="$t('custom.license_number')"
                  outlined
                  hide-details
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.driver_issued"
                  :label="$t('custom.license_issued')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.sale"
                  :label="$t('custom.sale_perc')"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.sale_cash"
                  :label="$t('custom.sale_rub', { currency: currency })"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-select
                  v-if="clients_sources"
                  v-model="client.source"
                  :items="clients_sources"
                  :label="$t('custom.source')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex>
                <v-textarea
                  v-model="client.dop_info"
                  :label="$t('clients.add_info')"
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex class="text-center">
                <v-btn color="success" class="mr-2" @click="editSave"
                  >{{ $t("custom.save")
                  }}<v-icon class="ml-1">
                    mdi-content-save-check-outline
                  </v-icon></v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
          <v-container v-else>
            <v-layout row wrap>
              <v-flex md12 v-if="client.debtor">
                <h2 class="orange darken-4">{{ $t("clients.have_debt") }}</h2>
              </v-flex>
              <v-flex md12 v-if="client.problems">
                <h2 class="red lighten-1 white--text">
                  {{ $t("clients.bad_warn") }}
                </h2>
              </v-flex>
              <v-flex md12 v-if="client.problems">
                <v-textarea
                  v-if="client.problems"
                  v-model="client.problems_description"
                  outlined
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.entity_name"
                  :label="$t('custom.company_name')"
                  :placeholder="$t('custom.company_name_placeholder')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.short_entity_name"
                  :label="$t('custom.company_name_short')"
                  :placeholder="$t('custom.company_name_short_plc')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.reg_form"
                  :label="$t('custom.reg_form')"
                  :placeholder="$t('custom.reg_form_plc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.inn"
                  :label="$t('custom.tax_number')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.ogrn"
                  :label="$t('custom.entity_number')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.ceo"
                  :label="$t('custom.face')"
                  :placeholder="$t('custom.face_plc')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.entity_phone"
                  :label="$t('custom.entity_phone')"
                  type="tel"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.email"
                  :label="$t('custom.email')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-select
                  v-model="client.category"
                  :items="customer_category"
                  :label="$t('bookings.client_category')"
                  outlined
                ></v-select>
              </v-flex>
              <v-flex xs12 sm12 md2>
                <v-text-field
                  v-model="client.country"
                  :label="$t('custom.country')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md2>
                <v-text-field
                  v-model="client.city"
                  :label="$t('custom.city')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-textarea
                  v-model="client.entity_adress"
                  :label="$t('custom.entity_address_2')"
                  outlined
                  rows="1"
                  auto-grow
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-textarea>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-textarea
                  v-model="client.address"
                  :label="$t('custom.entity_address_3')"
                  outlined
                  rows="1"
                  auto-grow
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.acc_number"
                  :label="$t('custom.entity_bank_number')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.bank"
                  :label="$t('custom.bank_name')"
                  :placeholder="$t('custom.bank_name_plc')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.korr"
                  :label="$t('custom.bank_correspondent')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md3>
                <v-text-field
                  v-model="client.bik"
                  :label="$t('custom.bank_number')"
                  outlined
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.sale"
                  :label="$t('custom.sale_perc')"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.sale_cash"
                  :label="$t('custom.sale_rub', { currency: currency })"
                  outlined
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-text-field
                  v-model="client.doc_number"
                  :label="$t('custom.doc_number')"
                  outlined
                  :placeholder="$t('custom.doc_number_plc')"
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md4 class="mx-auto">
                <v-select
                  v-if="clients_sources"
                  v-model="client.source"
                  :items="clients_sources"
                  :label="$t('custom.source')"
                  outlined
                ></v-select>
              </v-flex>
            </v-layout>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-layout row wrap>
              <v-flex>
                <v-textarea
                  v-model="client.dop_info"
                  :label="$t('clients.add_info')"
                  :style="
                    user_role == 'guest' || user_role == 'partner'
                      ? 'display: none;'
                      : ''
                  "
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex class="text-center">
                <v-btn color="success" @click="editSave"
                  >{{ $t("custom.save")
                  }}<v-icon class="ml-1">
                    mdi-content-save-check-outline
                  </v-icon></v-btn
                >
              </v-flex>
            </v-layout>
          </v-container>
          <!-- Долги -->
          <v-card-text v-if="debts.length > 0">
            <h1>Долги</h1>
            <v-data-table
              :headers="debts_headers"
              :items="debts"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :search="search"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              dense
              hide-default-footer
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">
                    <router-link :to="{ path: `/debts/${props.item.id}` }">{{
                      props.item.id
                    }}</router-link>
                  </td>
                  <td class="text-center">
                    {{ returnDate(props.item.created_at) }}
                  </td>
                  <td
                    class="text-center"
                    :style="
                      user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                    "
                  >
                    <router-link
                      :to="{ path: `/clients/${props.item.client_id}` }"
                      >{{ props.item.client_name }}</router-link
                    >
                  </td>
                  <td class="text-center">
                    <router-link
                      :to="{ path: `/bookings/${props.item.booking_id}` }"
                      >{{ props.item.booking_id }}</router-link
                    >
                  </td>
                  <td class="text-center">{{ props.item.group }}</td>
                  <td class="text-center">{{ props.item.description }}</td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center">
                    {{ props.item.paid }}{{ currency }}
                  </td>
                  <!-- Состояние -->
                  <td class="text-center">
                    <v-btn
                      :color="stateDebtColor(props.item.state)"
                      small
                      block
                    >
                      {{ props.item.state }}
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search }) }}
              </v-alert>
            </v-data-table>
          </v-card-text>
          <!-- Брони -->
          <v-card-text v-if="bookings.length > 0">
            <h1>{{ $t("custom.bookings") }}</h1>
            <v-data-table
              :headers="headers"
              :items="bookings"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              item-key="id"
              class="elevation-1"
              dense
              hide-default-footer
            >
              <template slot="header" slot-scope="props">
                <tr>
                  <th v-for="header in props.headers" :key="header.text">
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template slot="item" slot-scope="props">
                <tr
                  :class="{
                    'grey lighten-1': !props.item.ride,
                    'green lighten-4': props.item.ride,
                  }"
                >
                  <td class="text-center" :key="props.item.id">
                    {{ props.item.id }}
                  </td>
                  <td class="text-center">
                    {{ formatCreatedAt(props.item.created_at) }}
                  </td>
                  <td class="text-center">
                    <v-icon
                      @click="replaceToBooking(props.item)"
                      :title="$t('archive.booking_info')"
                    >
                      mdi-eye-outline
                    </v-icon>
                  </td>
                  <td class="text-center">{{ props.item.car_name }}</td>
                  <td class="text-center">{{ props.item.start_date }}</td>
                  <td class="text-center">{{ props.item.end_date }}</td>
                  <td class="text-center">{{ props.item.days }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text v-if="!bookings.length > 0" class="text-md-center">
            <h2>{{ $t("bookings.no_bookings") }}</h2>
          </v-card-text>
          <!-- Штрафы -->
          <v-card-text v-if="fines.length > 0">
            <h1>{{ $t("custom.fines") }}</h1>
            <v-data-table
              :headers="fines_headers"
              :items="fines"
              :search="search_fines"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :items-per-page="10"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :class="!isMobile ? '' : 'is-mobile'"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">
                    <router-link
                      :to="{ path: `/fines/${props.item.number}` }"
                      >{{ props.item.number }}</router-link
                    >
                  </td>
                  <td class="text-center" v-if="props.item.date">
                    {{ returnDate(props.item.date) }}
                  </td>
                  <td class="text-center" v-else>
                    {{ formatCreatedAtWithYear(props.item.violation_date) }}
                  </td>
                  <td class="text-center">
                    <router-link
                      :to="{ path: `/bookings/${props.item.booking_id}` }"
                      >{{ props.item.booking_id }}</router-link
                    >
                  </td>
                  <td class="text-center">
                    {{ props.item.sum }}{{ currency }}
                  </td>
                  <td class="text-center description-trunc">
                    <read-more
                      v-if="
                        props.item.description &&
                        props.item.description.length > 0
                      "
                      :more-str="$t('custom.read')"
                      :text="props.item.description"
                      link="#"
                      :less-str="$t('custom.less')"
                      :max-chars="20"
                    ></read-more>
                  </td>
                  <td class="text-center" v-if="props.item.paid">
                    <v-icon class="text-center" style="color: green">
                      mdi-plus-circle-outline
                    </v-icon>
                  </td>
                  <td class="text-center" v-if="!props.item.paid">
                    <v-icon class="text-center" style="color: tomato">
                      mdi-minus-circle-outline
                    </v-icon>
                  </td>
                  <!-- Состояние -->
                  <td class="text-center">
                    <v-btn
                      :color="stateFineColor(props.item.state)"
                      small
                      block
                    >
                      {{ props.item.state }}
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search_fines }) }}
              </v-alert>
            </v-data-table>
          </v-card-text>
          <!-- Счета -->
          <v-card-text md12 v-if="invoices.length > 0">
            <v-divider class="mt-3 mb-3"></v-divider>
            <h2>{{ $t("custom.invoices") }}</h2>
            <v-data-table
              :headers="headers_invoices"
              :items="invoices"
              :search="search_invoices"
              :loading="isLoading"
              :loading-text="$t('custom.loading_table')"
              :items-per-page="20"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :class="!isMobile ? '' : 'is-mobile'"
              :footer-props="{
                pageText: `{0} ${$t('custom.of')} {1}`,
                itemsPerPageText: $t('custom.elements_table'),
                showFirstLastPage: true,
                itemsPerPageOptions: [20, 50, 100, -1],
              }"
            >
              <template slot="item" slot-scope="props">
                <tr>
                  <td class="text-center">
                    <router-link
                      :to="{ path: `/invoices/${props.item.number}` }"
                      >{{ props.item.number }}</router-link
                    >
                  </td>
                  <td class="text-center">{{ returnDate(props.item.date) }}</td>
                  <td
                    class="text-center"
                    :style="
                      user_role == 'guest' || user_role == 'partner'
                        ? 'display: none;'
                        : ''
                    "
                  >
                    <router-link
                      :to="{ path: `/clients/${props.item.client_id}` }"
                      >{{ props.item.client_name }}</router-link
                    >
                  </td>
                  <td class="text-center">
                    <router-link
                      :to="{ path: `/bookings/${props.item.booking_id}` }"
                      >{{ props.item.booking_id }}</router-link
                    >
                  </td>
                  <td class="text-center">
                    <read-more
                      v-if="
                        props.item.description &&
                        props.item.description.length > 0
                      "
                      :more-str="$t('custom.read')"
                      :text="props.item.description"
                      link="#"
                      :less-str="$t('custom.less')"
                      :max-chars="20"
                    ></read-more>
                  </td>
                  <td class="text-center">
                    {{
                      props.item.rent +
                      props.item.deposit +
                      props.item.delivery +
                      props.item.delivery_end +
                      props.item.clean +
                      props.item.gas +
                      props.item.damage +
                      props.item.fines +
                      props.item.mileage +
                      props.item.hours_cost +
                      props.item.insurance +
                      props.item.equipment +
                      props.item.other
                    }}{{ currency }}
                  </td>
                  <td class="text-center">{{ paidInvoice(props.item) }}</td>
                  <td class="text-center">
                    {{ props.item.paid ? props.item.paid : 0 }}{{ currency }}
                  </td>
                  <!-- Состояние -->
                  <td class="text-center">
                    <v-btn
                      :color="stateInvoiceColor(props.item.state)"
                      small
                      block
                    >
                      {{ props.item.state }}
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template slot="no-data">
                {{ $t("custom.no_data_in_table") }}
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="mdi-alert"
              >
                {{ $t("tables.no_search_result", { msg: search_invoices }) }}
              </v-alert>
            </v-data-table>
          </v-card-text>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12>
                <gallery></gallery>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text v-if="!uploadDocuments">
            <v-layout
              row
              wrap
              centered
              class="d-flex text-center justify-center"
            >
              <v-flex xs12 sm12 md12 class="d-flex justify-center">
                <v-checkbox
                  :label="$t('custom.upload_docs')"
                  v-model="uploadDocuments"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-text v-if="uploadDocuments" class="text-center">
            <h3 mb-3>{{ $t("custom.uploading_files") }}</h3>
            <v-flex xs12 sm12 md12 class="d-flex text-center justify-center">
              <uppy
                :max-file-size-in-bytes="10000000"
                :width="isMobile ? 300 : 750"
                :height="isMobile ? 250 : 250"
                :theme="$vuetify.theme.dark ? 'dark' : 'light'"
              ></uppy>
            </v-flex>
          </v-card-text>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
/* eslint-disable */
import Gallery from "./Gallary";
import Uppy from "../../../plugins/uppy";
let uuid = require("uuid");
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/en-gb";
// moment.locale("ru");
import { isMobile } from "mobile-device-detect";

export default {
  data() {
    return {
      client: [],
      documents: [],
      files: [],
      filepond_server: {
        // created
      },
      bookings: [],
      fines: [],
      debts: [],
      debt: {
        sum: null,
        state: this.$t("debts_state.created"),
        description: null,
        group: this.$t("add_services.rent"),
        client_id: null,
        booking_id: null,
      },
      debts_headers: [
        { text: this.$t("custom.number"), align: "center", value: "id" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        { text: this.$t("custom.group"), value: "group", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("bookings.paid"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      paymentData: {
        sum: null,
        type: null,
        state: "Оплачен",
        group: null,
        debt_id: null,
      },
      paymentDebtDialog: false,
      search: null,
      gallery_items: [],
      editedFine: [],
      uploadDocuments: false,
      fines_state: [
        { text: this.$t("fines_state.created"), value: "Создан" },
        { text: this.$t("fines_state.notify"), value: "Оповещён" },
        { text: this.$t("fines_state.paid"), value: "Оплачен" },
        { text: this.$t("fines_state.refund"), value: "Возмещён" },
      ],
      headers: [
        { text: this.$t("custom.number"), value: "id", align: "center" },
        {
          text: this.$t("custom.created_at"),
          value: "created_at",
          align: "center",
        },
        { text: this.$t("custom.editor"), align: "center" },
        { text: this.$t("custom.car"), value: "car_name", align: "center" },
        { text: this.$t("custom.start"), value: "start_date", align: "center" },
        { text: this.$t("custom.end"), value: "end_date", align: "center" },
        { text: this.$t("custom.days"), value: "days", align: "center" },
      ],
      fines_headers: [
        { text: this.$t("fines.number"), align: "center", value: "number" },
        { text: this.$t("custom.date"), value: "date", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("bookings.payment"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      invoices: [],
      search_invoices: null,
      headers_invoices: [
        { text: this.$t("custom.number"), align: "center", value: "number" },
        {
          text: this.$t("counts.date_initial"),
          value: "date",
          align: "center",
        },
        { text: this.$t("custom.client"), value: "client_id", align: "center" },
        {
          text: this.$t("bookings.booking"),
          value: "booking_id",
          align: "center",
        },
        {
          text: this.$t("custom.description"),
          value: "description",
          align: "center",
        },
        { text: this.$t("custom.amount"), value: "sum", align: "center" },
        { text: this.$t("invoices.control"), sortable: false, align: "center" },
        { text: this.$t("invoices.get"), value: "paid", align: "center" },
        { text: this.$t("custom.state"), value: "state", align: "center" },
      ],
      // configPicker: {
      //   altFormat: "d-m-Y H:i",
      //   enableTime: true,
      //   altInput: true,
      //   time_24hr: true,
      //   plugins: [new ConfirmDatePlugin({ confirmText: "Ok" })]
      // },
      date: null,
      clients: [],
      isLoadingClients: false,
      showClientSearch: false,
      birthday_non_format: null,
      showEditBirthday: false,
      search_client: null,
      sortingBookings: { sortBy: "created_at", descending: true },
      showEdit: false,
      paymentData: {
        sum: null,
        type: null,
        state: null,
        number: null,
      },
      paymentDialog: false,
      editDialog: false,
      editDebtDialog: false,
      search_fines: null,
      customer_category: [
        { text: this.$t("customer_category.new"), value: "Новый" },
        { text: this.$t("customer_category.loyal"), value: "Лояльный" },
        { text: this.$t("customer_category.statement"), value: "Постоянный" },
        { text: this.$t("customer_category.problem"), value: "Серый" },
        { text: this.$t("customer_category.bad"), value: "Чёрный" },
      ],
      birthday_menu: false,
      birthday: null,
      isMobile: false,
      isLoading: false,
    };
  },
  created() {
    // moment Locale
    if (this.$i18n.locale == "ru") {
      moment.locale("ru");
    } else {
      moment.locale("en-gb");
    }
    if (this.$store.getters.isLoggedIn) {
      this.isLoading = true;
      let self = this;
      this.axios
        .get(`/api/v1/clients_fines?client_id=${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.fines = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.fines_load"));
          console.log(error);
        });

      this.axios
        .get(`/api/v1/clients_debts?client_id=${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.debts = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.debts_load"));
          console.log(error);
        });

      this.axios
        .get(`/api/v1/clients_invoices?client_id=${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.invoices = response.data;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.invoices_load"));
          console.log(error);
        });
      this.axios
        .get(`/api/v1/clients/${this.$route.params.id}`, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          this.client = response.data.client;
          this.birthday_non_format = moment(
            this.client.birthday,
            "YYYY-MM-DD"
          ).format("DD.MM.YYYY");
          console.log(this.client.birthday);
          this.birthday = moment(this.client.birthday, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          console.log(this.birthday);
          this.bookings = response.data.bookings;
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.client_load"));
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  watch: {
    birthday() {
      // this.client.birthday = moment(this.birthday, "YYYY-MM-DD").format(
      //   "DD-MM-YYYY"
      // );
      console.log(this.birthday);
    },
    search_client(val) {
      // Items have already been loaded
      if (this.clients.length > 0) return;

      // Items have already been requested
      if (this.isLoadingClients) return;

      this.isLoadingClients = true;

      // Lazily load input items
      this.axios
        .get("/api/v1/clients", {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
        })
        .then((response) => {
          response.data.data.forEach((element) => {
            this.clients.push(element.attributes);
          });
          this.clients.forEach((client) => {
            if (!client.entity) {
              client["fullname"] =
                client.lastname +
                " " +
                client.name +
                " " +
                client.middlename +
                " " +
                (client.phone
                  ? client.phone.slice(client.phone.length - 4)
                  : "");
            } else {
              client["fullname"] =
                client.short_entity_name +
                ", " +
                client.reg_form +
                " " +
                " - " +
                client.ogrn;
            }
          });
        })
        .catch((error) => {
          this.setError(error, this.$t("errors.clients_load"));
          console.log(error);
        })
        .finally(() => (this.isLoadingClients = false));
    },
  },
  computed: {
    clients_sources() {
      if (this.$store.getters.getCompany.company.clients_sources) {
        return this.$store.getters.getCompany.company.clients_sources;
      } else {
        return [];
      }
    },
    currency() {
      if (
        this.$store.getters.getCompany &&
        this.$store.getters.getCompany.company &&
        this.$store.getters.getCompany.company.currency
      ) {
        return this.$store.getters.getCompany.company.currency;
      } else {
        return "";
      }
    },
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
    company() {
      if (this.$store.getters.getCompany) {
        return this.$store.getters.getCompany.company;
      } else {
        return null;
      }
    },
  },
  methods: {
    editSave() {
      if (this.user_role != "guest") {
        this.isLoading = true;
        let client = this.client;
        let self = this;
        let name = this.capitalizeFirst(client.name);
        let middlename = this.capitalizeFirst(client.middlename);
        let lastname = this.capitalizeFirst(client.lastname);
        this.axios
          .patch(
            `/api/v1/clients/${client.id}`,
            {
              clients: {
                name: name,
                lastname: lastname,
                middlename: middlename,
                fio: client.fio,
                phone: client.phone,
                email: client.email,
                country: client.country,
                city: client.city,
                address: client.address,
                sale: client.sale,
                sale_cash: client.sale_cash,
                passport_series: client.passport_series,
                passport_number: client.passport_number,
                passport_issued: client.passport_issued,
                driver_series: client.driver_series,
                driver_number: client.driver_number,
                driver_issued: client.driver_issued,
                birthday:
                  this.birthday_non_format &&
                  moment(this.birthday_non_format, "DD.MM.YYYY").isValid
                    ? moment(this.birthday_non_format, "DD.MM.YYYY").format(
                        "YYYY-MM-DD"
                      )
                    : this.birthday_non_format,
                category: client.category,
                dop_info: client.dop_info,
                debt: client.debt,
                debt_description: client.debt_description,
                problems: client.problems,
                problems_description: client.problems_description,
                entity_name: client.entity_name,
                short_entity_name: client.short_entity_name,
                ceo: client.ceo,
                reg_form: client.reg_form,
                entity_phone: client.entity_phone,
                entity_adress: client.entity_adress,
                inn: client.inn,
                ogrn: client.ogrn,
                acc_number: client.acc_number,
                bank: client.bank,
                korr: client.korr,
                bik: client.bik,
                doc_number: client.doc_number,
                source: client.source,
              },
            },
            {
              headers: {
                Authorization: this.$store.getters.getAuthToken,
              },
            }
          )
          .then((response) => {
            this.showEdit = false;
            client.birthday = client.birthday;

            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "success",
              title: this.$t("clients.edited_title"),
              text: this.$t("clients.edited_text"),
            });
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.client_edit"));
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 5000,
              icon: "error",
              title: this.$t("errors.client_edit"),
              text: this.error,
            });
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    stateFineColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "error";
      }
      if (state == "Возмещён") {
        return "success";
      }
      if (state == "Не возмещён") {
        return "error";
      }
    },
    stateDebtColor(state) {
      if (state == "Оповещён") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Не оплачен") {
        return "red";
      }
    },
    stateInvoiceColor(state) {
      if (state == "Выставлен") {
        return "warning";
      }
      if (state == "Оплачен") {
        return "success";
      }
      if (state == "Частично оплачен") {
        return "pink";
      }
    },
    paidInvoice(invoice) {
      let invoice_sum =
        invoice.rent +
        invoice.deposit +
        invoice.delivery +
        invoice.clean +
        invoice.gas +
        invoice.damage +
        invoice.fines +
        invoice.mileage +
        invoice.hours_cost +
        invoice.insurance +
        invoice.equipment +
        invoice.other;
      if (invoice_sum == invoice.paid && invoice_sum > 0 && invoice.paid > 0) {
        return this.$t("invoices.paid");
      } else if (invoice_sum < invoice.paid) {
        return this.$t("invoices.extra_paid");
      } else if (invoice_sum > 0 && invoice_sum > invoice.paid) {
        return this.$t("invoices.low_paid");
      } else if (invoice_sum > 0 && invoice.paid == 0) {
        return this.$t("invoices.no_income");
      } else {
        return `-`;
      }
    },
    replaceToWhatsAppPage() {
      if (this.client && this.client.id) {
        this.$router.push({ path: `/whatsapp_client/${this.client.id}` });
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    capitalizeFirst(text) {
      if (text) {
        return text[0].toUpperCase() + text.substring(1);
      }
    },
    formatCreatedAt(date) {
      return moment.parseZone(date).format("DD MMM H:mm");
    },
    formatCreatedAtWithYear(date) {
      if (date) {
        return moment.parseZone(date).format("DD-MM-YY H:mm");
      } else {
        return "-";
      }
    },
    returnDate(item) {
      return moment.parseZone(item, "YYYY-MM-DD H:mm").format("DD MMM H:mm");
    },
    replaceToBooking(booking) {
      this.$router.push({ path: `/bookings/${booking.id}` });
    },
    checkMobile() {
      if (isMobile) {
        this.isMobile = true;
        this.paymentsPanel = [];
      } else {
        this.paymentsPanel = 0;
        this.isMobile = false;
      }
    },
    goBack() {
      var numberOfEntries = window.history.length;
      if (numberOfEntries > 2) {
        this.$router.go(-1);
      } else {
        if (
          this.PageData &&
          this.PageData.backCrumb &&
          this.PageData.backCrumb.url
        ) {
          var fpath = this.PageData.backCrumb.url;
          this.$router.push({
            path: fpath,
          });
        } else {
          this.$router.push({ path: "/dashboard" });
        }
      }
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {
    Gallery,
    Uppy,
    // flatPickr
  },
};
</script>
