<template>
  <v-container v-resize="checkMobile" grid-list-md text-xs-center>
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#1976d2"
      :background-color="$vuetify.theme.dark ? '#000' : ''"
    ></loading>
    <v-card>
      <v-card-title>
        <h2>{{ $t("tariffs.tariffs") }}</h2>
        <v-spacer></v-spacer>
        <v-btn small color="success" @click="newTariffDialog = true">{{
          $t("tariffs.add")
        }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-data-table
            :headers="headers"
            :items="tariffs"
            :search="search"
            :loading="isLoading"
            :loading-text="$t('custom.loading_table')"
            :sort-by="['id']"
            :sort-desc="[true]"
            :items-per-page="20"
            mobile-breakpoint="100"
            :class="!isMobile ? '' : 'is-mobile'"
            :footer-props="{
              pageText: `{0} ${$t('custom.of')} {1}`,
              itemsPerPageText: $t('custom.elements_table'),
              showFirstLastPage: true,
              itemsPerPageOptions: [20, 50, 100, -1],
            }"
          >
            <template slot="header" slot-scope="props">
              <tr id="registration-step-64">
                <th v-for="header in props.headers" :key="header.text" nowrap>
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template slot="item" slot-scope="props">
              <tr>
                <td class="text-center" :key="props.item.id">
                  {{ props.item.id }}
                </td>
                <td class="text-center">
                  <v-icon
                    class="ml-2"
                    @click="
                      (tariff = props.item),
                        (newTariffDialog = true),
                        (editTariff = true)
                    "
                  >
                    mdi-pencil-outline
                  </v-icon>
                  <v-icon
                    v-if="checkAdmin()"
                    color="red darken-2"
                    @click="deleteTariff(props.item)"
                    v-tooltip="{
                      content: $t('tariffs.remove_tooltip'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }"
                    >mdi-delete-forever</v-icon
                  >
                </td>
                <td class="text-center">
                  {{ props.item.name }}
                </td>
                <td class="text-center">
                  {{ props.item.sum }}
                </td>
                <td class="text-center">
                  {{ props.item.percent }}
                </td>
                <td class="text-center">
                  {{ props.item.description }}
                </td>
              </tr>
            </template>
            <!-- <template slot="footer" class="text-center"> </template> -->
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="newTariffDialog"
      :retain-focus="false"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <h2 v-if="!editTariff">{{ $t("tariffs.creating") }}</h2>
          <h2 v-else>{{ $t("tariffs.editing") }}</h2>
        </v-card-title>
        <v-card-subtitle class="mt-2">
          {{ $t("tariffs.editing_subtitle") }}
        </v-card-subtitle>
        <v-card-text>
          <v-container grid-list-md>
            <v-container>
              <v-layout row wrap>
                <v-flex xs12 sm3 md3>
                  <v-text-field
                    v-model.trim="tariff.name"
                    :label="$t('tariffs.name')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    :disabled="tariff.percent > 0 || tariff.percent < 0"
                    v-model.number="tariff.sum"
                    :label="$t('custom.amount')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm2 md2>
                  <v-text-field
                    :disabled="tariff.sum > 0 || tariff.sum < 0"
                    v-model.number="tariff.percent"
                    :label="$t('tariffs.percent')"
                    outlined
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm5 md5>
                  <v-textarea
                    v-model="tariff.description"
                    auto-grow
                    clearable
                    rows="1"
                    :label="$t('custom.description_2')"
                    outlined
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
            <v-flex md12 class="text-center">
              <v-btn v-if="!editTariff" color="success" @click="saveTariff()">{{
                $t("custom.save")
              }}</v-btn>
              <v-btn v-else color="success" @click="updateTariff()">{{
                $t("custom.update")
              }}</v-btn>
              <v-btn
                class="ml-2"
                @click="
                  (newTariffDialog = false),
                    (tariff = {
                      name: null,
                      sum: 0,
                      percent: 0,
                      description: null,
                    })
                "
                >{{ $t("custom.close") }}</v-btn
              >
            </v-flex>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { isMobile } from "mobile-device-detect";
export default {
  data() {
    return {
      tariffs: [],
      tariff: {
        name: null,
        sum: 0,
        percent: 0,
        description: null,
      },
      headers: [
        { text: this.$t("custom.id"), align: "center", value: "id" },
        {
          text: this.$t("custom.edit_short"),
          align: "center",
          sortable: false,
        },
        { text: this.$t("tariffs.name"), align: "center", value: "name" },
        { text: this.$t("custom.amount"), align: "center", value: "sum" },
        { text: this.$t("custom.percent"), align: "center", value: "percent" },
        {
          text: this.$t("custom.description_2"),
          align: "center",
          value: "description",
        },
      ],
      editTariff: false,
      newTariffDialog: false,
      search: null,
      isMobile: false,
      isLoading: false,
      error: "",
    };
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      if (
        this.$store.getters.isSuperAdmin ||
        this.$store.getters.isAdmin ||
        this.$store.getters.isManager
      ) {
        this.isLoading = true;
        this.axios
          .get("/api/v1/tariffs", {
            headers: {
              Authorization: this.$store.getters.getAuthToken,
            },
          })
          .then((response) => {
            this.tariffs = response.data;
          })
          .catch((error) => {
            this.setError(error, this.$t("errors.tariff_load"));
            console.log(error);
          })
          .finally(() => (this.isLoading = false));
      } else {
        this.goBack();
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "error",
          title: this.$t("errors.not_permitted_title"),
          text: this.$t("errors.only_admins_2"),
        });
      }
    } else {
      this.$store.commit("unsetCurrentUser");
    }
  },
  computed: {
    user_role() {
      if (this.$store.getters.getCurrentUser) {
        return this.$store.getters.getCurrentUser.role;
      } else {
        return "";
      }
    },
  },
  methods: {
    saveTariff() {
      if (this.user_role != "guest") {
        let self = this;
        if (
          (this.tariff.sum || (this.tariff.percent && this.tariff.name)) &&
          (this.tariff.sum > 0 ||
            this.tariff.percent > 0 ||
            this.tariff.sum < 0 ||
            this.tariff.percent < 0)
        ) {
          this.isLoading = true;
          this.axios
            .post(
              "/api/v1/tariffs",
              {
                tariffs: {
                  name: self.tariff.name,
                  sum: self.tariff.sum,
                  percent: self.tariff.percent,
                  description: self.tariff.description,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              this.tariffs.push(response.data);
              this.tariff = {
                name: null,
                sum: 0,
                percent: 0,
                description: null,
              };
              this.newTariffDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("tariffs.added"),
                text: this.$t("tariffs.added_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.tariff_add"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.tariff_add"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    updateTariff() {
      if (this.user_role != "guest") {
        let self = this;
        if (
          (this.tariff.sum || (this.tariff.percent && this.tariff.name)) &&
          (this.tariff.sum > 0 ||
            this.tariff.percent > 0 ||
            this.tariff.sum < 0 ||
            this.tariff.percent < 0)
        ) {
          this.isLoading = true;
          this.axios
            .patch(
              `/api/v1/tariffs/${this.tariff.id}`,
              {
                tariffs: {
                  name: self.tariff.name,
                  sum: self.tariff.sum,
                  percent: self.tariff.percent,
                  description: self.tariff.description,
                },
              },
              {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              }
            )
            .then((response) => {
              let indexTariff = this.tariffs.findIndex(
                (tariff) => tariff.id == response.data.id
              );
              this.tariffs.slice(indexTariff, 1, response.data);
              this.tariff = {
                name: null,
                sum: 0,
                percent: 0,
                description: null,
              };
              this.editTariff = false;
              this.newTariffDialog = false;

              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "success",
                title: this.$t("tariffs.updated_title"),
                text: this.$t("tariffs.updated_text"),
              });
            })
            .catch((error) => {
              this.setError(error, this.$t("errors.tariff_update"));
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                icon: "error",
                title: this.$t("errors.tariff_update"),
                text: this.error,
              });
            })
            .finally(() => (this.isLoading = false));
        } else {
          this.$swal({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            icon: "warning",
            title: this.$t("errors.all_fields_title"),
          });
        }
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    deleteTariff(tariff) {
      if (this.user_role != "guest") {
        let self = this;
        this.$swal({
          title: this.$t("custom.you_sure_title"),
          text: this.$t("tariffs.delete_text"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("custom.you_sure_yes"),
          cancelButtonText: this.$t("custom.you_sure_no"),
          showCloseButton: true,
        }).then((result) => {
          if (result.value) {
            this.axios
              .delete(`/api/v1/tariffs/${tariff.id}`, {
                headers: {
                  Authorization: this.$store.getters.getAuthToken,
                },
              })
              .then(() => {
                this.tariffs.splice(self.tariffs.indexOf(tariff), 1);
              })
              .catch((error) =>
                this.setError(error, this.$t("errors.tariff_delete"))
              )
              .finally(() => (this.isLoading = false));
            this.$swal(
              this.$t("custom.deleted"),
              this.$t("tariffs.delete_success"),
              "success"
            );
          }
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 5000,
          icon: "warning",
          title: this.$t("errors.no_access"),
        });
      }
    },
    checkAdmin() {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    checkMobile() {
      if (isMobile) this.isMobile = true;
      else this.isMobile = false;
    },
    setError(error, text) {
      this.$rollbar.configure({
        payload: {
          person: {
            id: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.id
              : null,
            username: this.$store.getters.getCurrentUser.name
              ? this.$store.getters.getCurrentUser.name
              : null,
            email: this.$store.getters.getCurrentUser
              ? this.$store.getters.getCurrentUser.email
              : null,
          },
          custom: {
            company:
              this.$store.getters.getCompany &&
              this.$store.getters.getCompany.company &&
              this.$store.getters.getCompany.company.name
                ? this.$store.getters.getCompany.company.name
                : null,
          },
        },
      });
      this.$rollbar.error(text, error);
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 5000,
        icon: "error",
        title: this.$t("errors.error"),
        text: `${error}. ${text}`,
      });
      console.log(error);
      this.error =
        (error.response && error.response.data && error.response.data.error) ||
        text;
    },
  },
  components: {},
};
</script>
