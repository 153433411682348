var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.checkMobile),expression:"checkMobile"}]},[_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"color":"#1976d2","background-color":_vm.$vuetify.theme.dark ? '#000' : ''},on:{"update:active":function($event){_vm.isLoading=$event}}}),(!_vm.isMobile)?_c('v-toolbar',{staticClass:"start-tour-timeline-1",attrs:{"text":"","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('h2',[_vm._v(_vm._s(_vm.$t("custom.day_plan")))]),_c('v-row',{staticClass:"ml-2 d-flex align-start",attrs:{"align":"center"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"max-width":"240px"},attrs:{"readonly":"","dense":_vm.isMobile,"prepend-icon":"mdi-calendar-clock","label":_vm.$t('custom.bookings_for_day'),"single-line":"","hide-details":""},model:{value:(_vm.sort_date_for_input),callback:function ($$v) {_vm.sort_date_for_input=$$v},expression:"sort_date_for_input"}},'v-text-field',attrs,false),on))]}}],null,false,1575585657)},[_c('vc-date-picker',{attrs:{"mode":"date","min-date":new Date(),"attributes":_vm.attrs_calendar,"masks":{ input: 'DD-MM-YYYY' },"locale":_vm.$i18n.locale},model:{value:(_vm.sort_date),callback:function ($$v) {_vm.sort_date=$$v},expression:"sort_date"}})],1),(_vm.sort_date)?_c('v-icon',{staticClass:"mr-2 mt-2 text-left",on:{"click":function($event){return _vm.clearDate()}}},[_vm._v(" mdi-close ")]):_vm._e()],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('timeline.deposits_tooltip'),
        placement: 'bottom-center',
        delay: {
          show: 500,
          hide: 500,
        },
      }),expression:"{\n        content: $t('timeline.deposits_tooltip'),\n        placement: 'bottom-center',\n        delay: {\n          show: 500,\n          hide: 500,\n        },\n      }"}],attrs:{"text":""}},[_vm._v(" "+_vm._s(_vm.$t('timeline.deposits'))+": "+_vm._s(_vm.deposits)+" ")]),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('timeline.incomes_tooltip'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }),expression:"{\n        content: $t('timeline.incomes_tooltip'),\n        placement: 'bottom-center',\n        targetClasses: ['it-has-a-tooltip'],\n        delay: {\n          show: 500,\n          hide: 500,\n        },\n      }"}],attrs:{"text":""}},[_vm._v(" "+_vm._s(_vm.$t('timeline.incomes'))+": "+_vm._s(_vm.incomes)+" ")]),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('timeline.only_checkins'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }),expression:"{\n        content: $t('timeline.only_checkins'),\n        placement: 'bottom-center',\n        targetClasses: ['it-has-a-tooltip'],\n        delay: {\n          show: 500,\n          hide: 500,\n        },\n      }"}],attrs:{"icon":""},on:{"click":function($event){_vm.show_checkins = !_vm.show_checkins;}}},[_c('v-icon',{attrs:{"color":_vm.show_checkins ? 'warning' : ''}},[_vm._v("mdi-airplane-takeoff")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('timeline.only_checkouts'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }),expression:"{\n        content: $t('timeline.only_checkouts'),\n        placement: 'bottom-center',\n        targetClasses: ['it-has-a-tooltip'],\n        delay: {\n          show: 500,\n          hide: 500,\n        },\n      }"}],attrs:{"icon":""},on:{"click":function($event){_vm.show_checkouts = !_vm.show_checkouts;}}},[_c('v-icon',{attrs:{"color":_vm.show_checkouts ? 'warning' : ''}},[_vm._v("mdi-home")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('timeline.all_bookings'),
        placement: 'bottom-center',
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }),expression:"{\n        content: $t('timeline.all_bookings'),\n        placement: 'bottom-center',\n        targetClasses: ['it-has-a-tooltip'],\n        delay: {\n          show: 500,\n          hide: 500,\n        },\n      }"}],attrs:{"icon":""},on:{"click":function($event){_vm.show_all_bookings = !_vm.show_all_bookings;}}},[_c('v-icon',{attrs:{"color":_vm.show_all_bookings ? 'warning' : ''}},[_vm._v("mdi-all-inclusive-box-outline")])],1),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.connected
          ? _vm.$t('custom.connected')
          : _vm.$t('custom.disconnected'),
        placement: 'bottom-center',
        classes: ['info'],
        targetClasses: ['it-has-a-tooltip'],
        delay: {
          show: 500,
          hide: 500,
        },
      }),expression:"{\n        content: connected\n          ? $t('custom.connected')\n          : $t('custom.disconnected'),\n        placement: 'bottom-center',\n        classes: ['info'],\n        targetClasses: ['it-has-a-tooltip'],\n        delay: {\n          show: 500,\n          hide: 500,\n        },\n      }"}],class:'pulse mt-3 mx-2 ' + (_vm.connected ? 'pulse_success' : 'pulse_error')}),(!_vm.isIos)?_c('v-btn',{attrs:{"icon":"","href":("https://rentprog." + (_vm.$root.$i18n.locale == 'ru' ? 'ru' : 'com') + "/categories/5/guides/18"),"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-help-circle")])],1):_vm._e()],1):_vm._e(),(_vm.isMobile)?_c('v-toolbar',{staticClass:"mb-2 start-tour-timeline-1",attrs:{"height":"150px","text":"","color":_vm.$vuetify.theme.dark ? '' : 'white'}},[_c('v-flex',{staticClass:"text-center",attrs:{"md12":"","justify-center":""}},[_c('v-flex',{staticClass:"mt-2 d-flex",attrs:{"md12":""}},[_c('v-menu',{attrs:{"offset-y":"","close-on-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"readonly":"","dense":_vm.isMobile,"attributes":_vm.attrs_calendar,"prepend-icon":"mdi-calendar-clock","label":_vm.$t('custom.bookings_on_day'),"single-line":"","hide-details":""},model:{value:(_vm.sort_date_for_input),callback:function ($$v) {_vm.sort_date_for_input=$$v},expression:"sort_date_for_input"}},'v-text-field',attrs,false),on))]}}],null,false,4039336019)},[_c('vc-date-picker',{attrs:{"mode":"date","masks":{ input: 'DD-MM-YYYY' },"locale":_vm.$i18n.locale},model:{value:(_vm.sort_date),callback:function ($$v) {_vm.sort_date=$$v},expression:"sort_date"}})],1),_c('v-spacer'),(_vm.sort_date)?_c('v-icon',{staticClass:"mr-2 mt-2 text-left",on:{"click":function($event){return _vm.clearDate()}}},[_vm._v(" mdi-close ")]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.connected
              ? _vm.$t('custom.connected')
              : _vm.$t('custom.disconnected'),
            placement: 'bottom-center',
            classes: ['info'],
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }),expression:"{\n            content: connected\n              ? $t('custom.connected')\n              : $t('custom.disconnected'),\n            placement: 'bottom-center',\n            classes: ['info'],\n            targetClasses: ['it-has-a-tooltip'],\n            delay: {\n              show: 500,\n              hide: 500,\n            },\n          }"}],class:'pulse mt-3 mx-2 ' + (_vm.connected ? 'pulse_success' : 'pulse_error')})],1),_c('v-flex',{staticClass:"d-flex text-center justify-center align-center",attrs:{"md12":""}},[_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('timeline.only_checkins'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }),expression:"{\n            content: $t('timeline.only_checkins'),\n            placement: 'bottom-center',\n            targetClasses: ['it-has-a-tooltip'],\n            delay: {\n              show: 500,\n              hide: 500,\n            },\n          }"}],attrs:{"icon":""},on:{"click":function($event){_vm.show_checkins = !_vm.show_checkins;}}},[_c('v-icon',{attrs:{"color":_vm.show_checkins ? 'warning' : ''}},[_vm._v("mdi-airplane-takeoff")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('timeline.only_checkouts'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }),expression:"{\n            content: $t('timeline.only_checkouts'),\n            placement: 'bottom-center',\n            targetClasses: ['it-has-a-tooltip'],\n            delay: {\n              show: 500,\n              hide: 500,\n            },\n          }"}],attrs:{"icon":""},on:{"click":function($event){_vm.show_checkouts = !_vm.show_checkouts;}}},[_c('v-icon',{attrs:{"color":_vm.show_checkouts ? 'warning' : ''}},[_vm._v("mdi-home")])],1),_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('timeline.all_bookings'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }),expression:"{\n            content: $t('timeline.all_bookings'),\n            placement: 'bottom-center',\n            targetClasses: ['it-has-a-tooltip'],\n            delay: {\n              show: 500,\n              hide: 500,\n            },\n          }"}],attrs:{"icon":""},on:{"click":function($event){_vm.show_all_bookings = !_vm.show_all_bookings;}}},[_c('v-icon',{attrs:{"color":_vm.show_all_bookings ? 'warning' : ''}},[_vm._v("mdi-all-inclusive-box-outline")])],1)],1),_c('v-flex',{staticClass:"d-flex text-center justify-center align-center",attrs:{"md12":""}},[(_vm.show_deposits)?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('timeline.deposits_tooltip'),
            placement: 'bottom-center',
            delay: {
              show: 500,
              hide: 500,
            },
          }),expression:"{\n            content: $t('timeline.deposits_tooltip'),\n            placement: 'bottom-center',\n            delay: {\n              show: 500,\n              hide: 500,\n            },\n          }"}],attrs:{"text":""},on:{"click":function($event){_vm.show_deposits = !_vm.show_deposits;}}},[_vm._v(" "+_vm._s(_vm.$t('timeline.deposits'))+": "+_vm._s(_vm.deposits)+" ")]):_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('timeline.incomes_tooltip'),
            placement: 'bottom-center',
            targetClasses: ['it-has-a-tooltip'],
            delay: {
              show: 500,
              hide: 500,
            },
          }),expression:"{\n            content: $t('timeline.incomes_tooltip'),\n            placement: 'bottom-center',\n            targetClasses: ['it-has-a-tooltip'],\n            delay: {\n              show: 500,\n              hide: 500,\n            },\n          }"}],attrs:{"text":""},on:{"click":function($event){_vm.show_deposits = !_vm.show_deposits;}}},[_vm._v(" "+_vm._s(_vm.$t('timeline.incomes'))+": "+_vm._s(_vm.incomes)+" ")])],1)],1)],1):_vm._e(),(_vm.bookings && _vm.bookings.length > 0)?_c('v-timeline',{attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l(((_vm.show_all_bookings ? _vm.all_bookings : _vm.bookings)),function(booking){return _c('v-timeline-item',{key:booking.id,attrs:{"right":_vm.selectBookingState(booking),"left":!_vm.selectBookingState(booking),"color":!_vm.selectBookingState(booking) ? 'success' : 'primary',"icon":!_vm.selectBookingState(booking) ? 'mdi-home' : 'mdi-airplane-takeoff'}},[_c('span',{attrs:{"slot":"opposite"},slot:"opposite"},[_vm._v(_vm._s(!_vm.selectBookingState(booking) ? _vm.$t("custom.checkout") : _vm.$t("custom.checkin")))]),_c('v-card',{staticClass:"elevation-2",on:{"dblclick":function($event){return _vm.replaceToBooking(booking)}}},[_c('v-expansion-panels',[_c('v-expansion-panel',{class:!booking.technical ? '' : 'blue-grey lighten-4'},[_c('v-expansion-panel-header',{class:!_vm.selectBookingState(booking) ? 'text-left' : 'text-right',style:(_vm.isMobile ? 'padding: 8px 6px;' : '')},[_c('span',[_vm._v(" "+_vm._s(_vm.returnTime(booking))+"  "+_vm._s(!_vm.selectBookingState(booking) ? booking.location_end : booking.location_start)+" "+_vm._s(booking.car_code)+" "),(booking.state == 'Новая')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.new'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.new'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"primary"}},[_vm._v(" mdi-new-box ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Подтверждена')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.approved'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.approved'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"success"}},[_vm._v(" mdi-check-all ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Не подтверждена')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.not_approved'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.not_approved'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"warning"}},[_vm._v(" mdi-cellphone-basic ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'В обработке')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.in_work'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.in_work'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"primary"}},[_vm._v(" mdi-autorenew ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Отказ клиента')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.declined'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.declined'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"danger"}},[_vm._v(" mdi-account-remove-outline ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Нет машин')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.declined_free_cars'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.declined_free_cars'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"secondary"}},[_vm._v(" mdi-car-off ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Недозвон')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"warning--text"},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.not_called'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.not_called'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"warning"}},[_vm._v(" mdi-phone-off-outline ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Ожидает ответа клиента')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.waiting_for_answer'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.waiting_for_answer'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"pink"}},[_vm._v(" mdi-account-question-outline ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Ожидает оплаты')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.waiting_for_pay'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.waiting_for_pay'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"lime"}},[_vm._v(" mdi-currency-usd-off ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Активная')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.active_state'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.active_state'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"light-blue"}},[_vm._v(" mdi-alpha-a-circle-outline ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Отмена')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.canceled_state'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.canceled_state'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"brown"}},[_vm._v(" mdi-cancel ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Отъездила')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.ride_true'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.ride_true'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"light-green"}},[_vm._v(" mdi-garage-variant ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Вернуть залог')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.waiting_for_deposit'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.waiting_for_deposit'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"blue"}},[_vm._v(" mdi-keyboard-return ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(booking.state == 'Другое' || booking.state == null)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.other_state'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.other_state'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"secondary"}},[_vm._v(" mdi-head-question-outline ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.bookings_states),function(state,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.saveBookingState(state.value, booking.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(state.text)+" ")])],1)}),1)],1):_vm._e(),(
                    booking.chair ||
                    booking.chair_less_1_year ||
                    booking.booster ||
                    booking.navigator ||
                    booking.mp3 ||
                    booking.charger ||
                    booking.wifi_router ||
                    booking.description
                  )?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content: _vm.$t('bookings.need_view'),
                    placement: 'bottom-center',
                    classes: ['info'],
                    targetClasses: ['it-has-a-tooltip'],
                    delay: {
                      show: 500,
                      hide: 500,
                    },
                  }),expression:"{\n                    content: $t('bookings.need_view'),\n                    placement: 'bottom-center',\n                    classes: ['info'],\n                    targetClasses: ['it-has-a-tooltip'],\n                    delay: {\n                      show: 500,\n                      hide: 500,\n                    },\n                  }"}],staticClass:"mx-1",attrs:{"color":"warning"}},[_vm._v(" mdi-eye-circle-outline ")]):_vm._e(),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(!booking.responsible)?_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                          content: _vm.$t('bookings.responsible'),
                          placement: 'bottom-center',
                          classes: ['info'],
                          targetClasses: ['it-has-a-tooltip'],
                          delay: {
                            show: 500,
                            hide: 500,
                          },
                        }),expression:"{\n                          content: $t('bookings.responsible'),\n                          placement: 'bottom-center',\n                          classes: ['info'],\n                          targetClasses: ['it-has-a-tooltip'],\n                          delay: {\n                            show: 500,\n                            hide: 500,\n                          },\n                        }"}],staticClass:"mx-1",attrs:{"color":"secondary"}},[_vm._v(" mdi-account-question ")]):_c('span',{staticClass:"mx-1"},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                            content: _vm.$t('bookings.responsible'),
                            placement: 'bottom-center',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            delay: {
                              show: 500,
                              hide: 500,
                            },
                          }),expression:"{\n                            content: $t('bookings.responsible'),\n                            placement: 'bottom-center',\n                            classes: ['info'],\n                            targetClasses: ['it-has-a-tooltip'],\n                            delay: {\n                              show: 500,\n                              hide: 500,\n                            },\n                          }"}],staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v(" mdi-account-check ")]),_vm._v(" "+_vm._s(booking.responsible)+" ")],1)],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.responsibles),function(responsible,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateResponsible(booking.id, responsible.name)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(responsible.name)+" ")])],1)}),1)],1)],1)]),_c('v-expansion-panel-content',[_c('v-card-text',[_c('p',[_vm._v(" "+_vm._s(_vm.$t("custom.number"))+" "),_c('router-link',{attrs:{"to":{ path: ("/bookings/" + (booking.id)) }}},[_vm._v(" "+_vm._s(booking.id)+" ")]),_c('v-flex',[(
                        (booking.counts && booking.counts.length > 0) ||
                        (booking.in_rent && !booking.technical)
                      )?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: ("" + (_vm.calculatePayments(booking) != _vm.$t('bookings.paid')
                            ? _vm.$t('bookings.part_paid')
                            : _vm.$t('bookings.full_paid'))),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }),expression:"{\n                        content: `${\n                          calculatePayments(booking) != $t('bookings.paid')\n                            ? $t('bookings.part_paid')\n                            : $t('bookings.full_paid')\n                        }`,\n                        placement: 'bottom-center',\n                        classes: ['info'],\n                        targetClasses: ['it-has-a-tooltip'],\n                        delay: {\n                          show: 500,\n                          hide: 500,\n                        },\n                      }"}],attrs:{"x-small":"","color":_vm.selectColorPaid(booking)}},[_vm._v(_vm._s(_vm.calculatePayments(booking)))]):_vm._e(),(
                        !booking.in_rent &&
                        booking.counts &&
                        booking.counts.length === 0 &&
                        !booking.technical
                      )?_c('v-btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                        content: _vm.$t('bookings.not_paid'),
                        placement: 'bottom-center',
                        classes: ['info'],
                        targetClasses: ['it-has-a-tooltip'],
                        delay: {
                          show: 500,
                          hide: 500,
                        },
                      }),expression:"{\n                        content: $t('bookings.not_paid'),\n                        placement: 'bottom-center',\n                        classes: ['info'],\n                        targetClasses: ['it-has-a-tooltip'],\n                        delay: {\n                          show: 500,\n                          hide: 500,\n                        },\n                      }"}],staticStyle:{"cursor":"default"},attrs:{"x-small":"","outlined":"","to":{
                        name: 'BookingCard',
                        params: { id: booking.id },
                      },"color":"primary","disabled":!booking.active || booking.in_rent}},[_vm._v(_vm._s(_vm.$t("bookings.not_paid_2")))]):_vm._e()],1)],1),(booking.responsible)?_c('p',[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      content: _vm.$t('bookings.responsible'),
                      placement: 'bottom-center',
                      classes: ['info'],
                      targetClasses: ['it-has-a-tooltip'],
                      delay: {
                        show: 500,
                        hide: 500,
                      },
                    }),expression:"{\n                      content: $t('bookings.responsible'),\n                      placement: 'bottom-center',\n                      classes: ['info'],\n                      targetClasses: ['it-has-a-tooltip'],\n                      delay: {\n                        show: 500,\n                        hide: 500,\n                      },\n                    }"}],staticClass:"mr-1",attrs:{"color":"success"}},[_vm._v(" mdi-account-check ")]),_vm._v(_vm._s(booking.responsible)+" ")],1):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.translateBookingState(booking.state)))]),(!booking.entity)?_c('p',[_c('router-link',{attrs:{"to":{ path: ("/clients/" + (booking.client_id)) }}},[_vm._v(_vm._s(booking.last_name)+" "+_vm._s(booking.first_name)+" "+_vm._s(booking.middle_name))])],1):_c('p',[_vm._v(" "+_vm._s(_vm.$t("custom.company"))+": "),_c('router-link',{attrs:{"to":{ path: ("/clients/" + (booking.client_id)) }}},[_vm._v(_vm._s(booking.entity_name))])],1),_c('p',[(booking.client && booking.client.phone)?_c('a',{attrs:{"href":'tel: ' + booking.client.phone}},[_vm._v(_vm._s(booking.client.phone))]):_vm._e()]),_c('p',[_c('router-link',{attrs:{"to":{ path: ("/cars/" + (booking.car_id)) }}},[_vm._v(_vm._s(booking.car_name)+" - "+_vm._s(booking.car_code))])],1),_c('p',[_c('span',{class:!_vm.selectBookingState(booking) ? '' : 'font-weight-bold'},[_vm._v(_vm._s(booking.start_date)+" - ")]),_c('span',{class:!_vm.selectBookingState(booking) ? 'font-weight-bold' : ''},[_vm._v(" "+_vm._s(booking.end_date))])]),(
                    booking.chair ||
                    booking.chair_less_1_year ||
                    booking.booster ||
                    booking.navigator ||
                    booking.mp3 ||
                    booking.charger ||
                    booking.wifi_router
                  )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bookings.add_equipment"))+" "),(booking.chair)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bookings.baby_chair"))+" - "+_vm._s(booking.chairs_quantity)+" ")]):_vm._e(),(booking.chair_less_1_year)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bookings.small_baby_chair"))+" - "+_vm._s(booking.chairs_quantity)+" ")]):_vm._e(),(booking.booster)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bookings.booster"))+" - "+_vm._s(booking.boosters_quantity)+" ")]):_vm._e(),(booking.navigator)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bookings.navigator"))+" ")]):_vm._e(),(booking.mp3)?_c('div',[_vm._v(_vm._s(_vm.$t("bookings.mp3")))]):_vm._e(),(booking.charger)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bookings.charger"))+" ")]):_vm._e(),(booking.wifi_router)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("bookings.wifi"))+" ")]):_vm._e(),(booking.other_equipment)?_c('div',[_vm._v(" "+_vm._s(booking.other_equipment)+" ")]):_vm._e()]):_vm._e(),(booking.description)?_c('p',[_vm._v(" "+_vm._s(booking.description)+" ")]):_vm._e()])],1)],1)],1)],1)],1)}),1):_c('v-card',{staticClass:"mt-3"},[_c('v-card-title',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("custom.on"))+" "+_vm._s(_vm.sort_date_for_input)+" "+_vm._s(_vm.$t("custom.no_business"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }